import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const CRMFunnelReportIframePage = Loadable(lazy(() => import('views/crms/crm-funnel-report')));

const CRMFunnelReportRoutes = {
    path: '/crm-funnel-report',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <CRMFunnelReportIframePage />
        }
    ]
};

export default CRMFunnelReportRoutes;
