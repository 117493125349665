import { Autocomplete, TextField } from '@mui/material';
import useId from '@mui/utils/useId';
import { useIntl } from 'react-intl';
import { OrganizationRole } from 'store/slices/brokers/interfaces';

export interface MutliOrganizationRoleFilterProps {
    defaultValue?: OrganizationRole[];
    onChange?: (event: React.SyntheticEvent<Element, Event>, value: OrganizationRole[]) => void;
    onInputChange?: (event: React.SyntheticEvent<Element, Event>, value: string, reason: string) => void;
}

const MutliOrganizationRoleFilter: React.FC<MutliOrganizationRoleFilterProps> = ({ defaultValue, onChange, onInputChange }) => {
    const intl = useIntl();
    const id = useId();

    return (
        <Autocomplete
            multiple
            id={`organization-role-${id}`}
            options={Object.values(OrganizationRole)}
            getOptionLabel={(role) => role}
            value={defaultValue}
            onChange={(e, value) => onChange?.(e, value)}
            onInputChange={(e, value, reason) => onInputChange?.(e, value, reason)}
            renderInput={(params) => (
                <TextField
                    label={intl.formatMessage({ id: 'organization-roles' })}
                    placeholder={`${intl.formatMessage({ id: 'organization-roles' })}*`}
                    {...params}
                />
            )}
        />
    );
};

export default MutliOrganizationRoleFilter;
