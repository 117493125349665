import Subject from '@mui/icons-material/Subject';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const DevelopersContacts: NavItemType = {
    id: 'developers-item',
    title: <FormattedMessage id="developers" />,
    type: 'item',
    url: '/developers',
    icon: Subject,
    allowedAudience: [{ name: InvitationTypeEnum.developerRelationsManagers }]
};
const DevelopersItem: NavItemType = {
    id: 'developers-item',
    type: 'group',
    children: [DevelopersContacts]
};

export default DevelopersItem;
