// types
import { createSlice } from '@reduxjs/toolkit';
import { SalesInitialState, BaseRankInterface } from './interfaces';
import querySales, {
    addInvoice,
    querySalesSummary,
    queryTopAgents,
    queryTopCompounds,
    queryTopDevelopers,
    queryTopOrganizations,
    queryTopPropertyTypes
} from './actions';

// ==============================|| SLICE - SALES  ||============================== //

// initial state

const initialState: SalesInitialState = {
    sales: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    loading: false,
    error: false,
    refresh: false,
    listLoading: false,
    listError: false,
    listRefresh: false,
    topDevelopers: [],
    topCompounds: [],
    topAgents: [],
    topOrganizations: [],
    topPropertyTypes: [],
    salesSummary: { count: 0, totalUnitValue: 0 },
    summaryLoading: false
};
const sales = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        refreshSales: (state) => {
            state.listRefresh = !state.listRefresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(querySales.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(querySales.fulfilled, (state, action) => {
                state.listLoading = false;
                state.sales = action.payload;
            })
            .addCase(querySales.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(querySalesSummary.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(querySalesSummary.fulfilled, (state, action) => {
                state.listLoading = false;
                state.salesSummary = action.payload;
            })
            .addCase(querySalesSummary.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryTopDevelopers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTopDevelopers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topDevelopers = action.payload.sort((a: BaseRankInterface, b: BaseRankInterface) => a.rank - b.rank);
            })
            .addCase(queryTopDevelopers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryTopCompounds.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTopCompounds.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topCompounds = action.payload.sort((a: BaseRankInterface, b: BaseRankInterface) => a.rank - b.rank);
            })
            .addCase(queryTopCompounds.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryTopPropertyTypes.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTopPropertyTypes.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topPropertyTypes = action.payload.sort((a: BaseRankInterface, b: BaseRankInterface) => a.rank - b.rank);
            })
            .addCase(queryTopPropertyTypes.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryTopAgents.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTopAgents.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topAgents = action.payload.sort((a: BaseRankInterface, b: BaseRankInterface) => a.rank - b.rank);
            })
            .addCase(queryTopAgents.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryTopOrganizations.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTopOrganizations.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topOrganizations = action.payload.sort((a: BaseRankInterface, b: BaseRankInterface) => a.rank - b.rank);
            })
            .addCase(queryTopOrganizations.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(addInvoice.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(addInvoice.fulfilled, (state) => {
                state.listLoading = false;
            })
            .addCase(addInvoice.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            });
    }
});

export default sales.reducer;
export const { refreshSales } = sales.actions;
