import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import primaryInventoryAPIs from './apis';

import {
    IInventoryControlCompound,
    IInventoryControlDeveloper,
    IUpdateInventoryControl,
    InventoryControlQueryInterface
} from './interfaces';

export const queryInventoryControl = createAsyncThunk(
    'inventoryControl/query',
    async (
        requestData: {
            query: InventoryControlQueryInterface;
        },
        { rejectWithValue }
    ): Promise<IInventoryControlDeveloper[] | any> => {
        const { query } = requestData;
        try {
            const successRes = await axios.get(primaryInventoryAPIs.query(query));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateDeveloperConfigs = createAsyncThunk(
    'inventoryControl/updateDeveloperConfigs',
    async (
        requestData: {
            updateData: IUpdateInventoryControl[];
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IInventoryControlDeveloper | any> => {
        const { updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(primaryInventoryAPIs.updateDeveloperConfigs(), { developers: updateData });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateCompound = createAsyncThunk(
    'inventoryControl/updateCompound',
    async (
        requestData: {
            updateData: IUpdateInventoryControl[];
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IInventoryControlCompound | any> => {
        const { updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(primaryInventoryAPIs.updateCompound(), { compounds: updateData });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
