import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, IconButton, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface ImagesPreviewProps {
    images: { src: string; name: string; error: boolean }[];
    handleRemoveImage?: (name: string) => void;
    showDeleteButton?: boolean;
}

const ImagesPreview = ({ images, handleRemoveImage, showDeleteButton = true }: ImagesPreviewProps) => (
    <Grid container spacing={4}>
        {images.map((image, index) => (
            <Grid item xs={12} md={4} key={image.name}>
                <ImageListItem>
                    <img alt={`img-${index}-${image.name}`} src={image.src} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    <ImageListItemBar
                        title={image.name}
                        actionIcon={
                            showDeleteButton && (
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${image.name}`}
                                    onClick={() => handleRemoveImage?.(image.name)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )
                        }
                    />
                    {image.error && (
                        <Typography variant="subtitle2" color="error">
                            <FormattedMessage id="max-image-size-exceeded" />
                        </Typography>
                    )}
                </ImageListItem>
            </Grid>
        ))}
    </Grid>
);

export default ImagesPreview;
