// hooks
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

// constants
import { gridSpacing } from 'store/constant';

// action
import { openSnackbar } from 'store/slices/snackbar';

// third party libraries
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { useTheme } from '@mui/styles';
import * as csc from 'country-state-city';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvitationsLimitsInterface } from 'store/slices/invitationsLimits/interfaces';
import { createPartnersInvitations, queryPartnerInvitationLimits } from 'store/slices/partnerInvitations/actions';
import SubCard from 'ui-component/cards/SubCard';
import * as yup from 'yup';
import { InvitePartnerDialogProps } from '..';
import { createContactInfoSchema, createLocationInfoSchema } from '../utils/createSchemas';
import useOverlay from 'hooks/useOverlay';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function CreatePartnerInvitationDialog({ showTrigger = false, fullWidth, variant }: InvitePartnerDialogProps) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const theme = useTheme();
    const { error, limits } = useSelector((state) => state.partnerInvitations);
    const [selectedLimit, setSelectedLimit] = useState<InvitationsLimitsInterface | null>(null);

    const { isCurrentOpened, close } = useOverlay('InvitePartner');

    const [open, setOpen] = useState(false);

    const CreatePartnerInvitationSchema = yup.object().shape({
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'phone-invalid' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        officialNameRequired: yup.boolean().nullable(),
        officialName: yup.string().when('officialNameRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'official-name-required' })),
            otherwise: yup.string().nullable()
        }),
        invitationTypeId: yup.string().required(intl.formatMessage({ id: 'invitation-type-required' })),
        contactInfoRequired: yup.boolean().nullable(),
        locationRequired: yup.boolean().nullable(),
        taxIdRequired: yup.boolean().nullable(),
        commercialCardIdRequired: yup.boolean().nullable(),
        personalIdRequired: yup.boolean().nullable(),
        taxId: yup.string().when('taxIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'tax-id-no-required' })),
            otherwise: yup.string().nullable()
        }),
        commercialCardId: yup.string().when('commercialCardIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'commercial-register-no-required' })),
            otherwise: yup.string().nullable()
        }),
        personalId: yup.string().when('personalIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'national-id-no-required' })),
            otherwise: yup.string().nullable()
        }),
        contactInfo: yup.object().when('contactInfoRequired', {
            is: true,
            then: createContactInfoSchema(intl, true),
            otherwise: createContactInfoSchema(intl, false)
        }),
        location: yup.object().when('locationRequired', {
            is: true,
            then: createLocationInfoSchema(intl, true),
            otherwise: createLocationInfoSchema(intl, false)
        })
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
        setSelectedLimit(null);
        close();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);
    const handleGetCountryCode = () => {
        const countryCode = csc.Country.getAllCountries().find((country) => country.name === formik.values.location.country)?.isoCode;
        if (countryCode) return countryCode;
        return '';
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            phone: '',
            invitationTypeId: selectedLimit?.id || '',

            officialName: undefined,
            officialNameRequired: selectedLimit?.invitationType.officialName || false,
            contactInfoRequired: selectedLimit?.invitationType.contactInfo || false,
            locationRequired: selectedLimit?.invitationType.locationInfo || false,
            taxIdRequired: selectedLimit?.invitationType.taxId || null,
            commercialCardIdRequired: selectedLimit?.invitationType.commercialCardId || null,
            personalIdRequired: selectedLimit?.invitationType.personalId || null,
            contactInfo: {
                title: '',
                name: '',
                phone: undefined,
                email: undefined
            },
            location: {
                type: '',
                country: '',
                area: '',
                city: '',
                address: ''
            },
            taxId: '',
            commercialCardId: '',
            personalId: ''
        },
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            const isPhoneValid = parsePhoneNumber(values.phone, 'EG').isValid();
            if (!isPhoneValid) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }
            if (values?.contactInfo?.phone) {
                const isContactInfoValid = parsePhoneNumber(values?.contactInfo?.phone, 'EG').isValid();
                if (!isContactInfoValid) {
                    formikHelpers.setFieldError('contactInfo.phone', intl.formatMessage({ id: 'phone-invalid' }));
                    formikHelpers.setSubmitting(false);
                    return;
                }
            }

            dispatch(
                createPartnersInvitations({
                    createBody: {
                        phone: values.phone,
                        contactInfo: values.contactInfo.title ? values.contactInfo : undefined,
                        invitationLimitsId: selectedLimit?.id,
                        locationInfo: values.location.country ? values.location : undefined,
                        officialName: values.officialName,
                        taxId: values.taxId,
                        commercialCardId: values.commercialCardId,
                        personalId: values.personalId
                    },
                    runSuccess
                })
            );
        },
        validationSchema: CreatePartnerInvitationSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        dispatch(queryPartnerInvitationLimits({}));
    }, [dispatch]);

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (limits.data.length === 1) {
            setSelectedLimit(limits.data[0]);
            formik.setFieldValue('invitationTypeId', limits.data[0]?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limits, open]);

    const showBusinessInfo =
        selectedLimit?.invitationType?.personalId !== null ||
        selectedLimit?.invitationType?.commercialCardId !== null ||
        selectedLimit?.invitationType?.taxId !== null;
    return (
        <>
            {showTrigger && (
                <Button
                    fullWidth={fullWidth}
                    variant={variant || 'outlined'}
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={handleClickOpen}
                >
                    <FormattedMessage id="invite" /> <FormattedMessage id="partner" />
                </Button>
            )}
            <Dialog
                open={isCurrentOpened}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="invite-partner"
                aria-describedby="invite-partner"
            >
                <DialogTitle id="invite-partner">
                    <FormattedMessage id="create" /> <FormattedMessage id="invitation" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        {limits.data.length > 1 ? (
                            <Grid item xs={12}>
                                <Select
                                    name="invitationTypeId"
                                    fullWidth
                                    displayEmpty
                                    id="invitationTypeId"
                                    value={formik.values.invitationTypeId}
                                    onChange={(event) => {
                                        const cLimit = limits.data.find((limit) => limit.id.toString() === event.target.value.toString());
                                        if (cLimit) setSelectedLimit(cLimit);
                                        else setSelectedLimit(null);
                                    }}
                                    onBlur={formik.handleBlur}
                                    onReset={formik.handleReset}
                                    error={Boolean(formik.touched.invitationTypeId && formik.errors.invitationTypeId)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem disabled value="">
                                        Select Invitation Type
                                    </MenuItem>
                                    {limits.data.map((limit) => (
                                        <MenuItem key={limit.id} value={limit.id}>
                                            {limit.invitationType.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.location?.country && (
                                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                                        {formik.errors.invitationTypeId}
                                    </FormHelperText>
                                )}
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Typography>
                                    <FormattedMessage id="invite" /> <FormattedMessage id="partner" />
                                </Typography>
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            <MuiTelInput
                                dir="ltr"
                                disabled={!selectedLimit}
                                id="invite-partner-phone"
                                name="phone"
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                excludedCountries={['IL']}
                                defaultCountry="EG"
                                label={`${intl.formatMessage({ id: 'phone' })} *`}
                                value={formik.values.phone}
                                onChange={(v) => formik.setFieldValue('phone', v)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        {!!selectedLimit && selectedLimit.invitationType.officialName !== null && (
                            <Grid item xs={12}>
                                <TextField
                                    label={`${intl.formatMessage({ id: 'official-name' })} *`}
                                    fullWidth
                                    type="text"
                                    name="officialName"
                                    id="create-organization-officialName"
                                    value={formik.values.officialName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.officialName && formik.errors.officialName)}
                                    helperText={formik.touched.officialName && formik.errors.officialName}
                                />
                            </Grid>
                        )}
                        {!!selectedLimit && selectedLimit.invitationType.contactInfo !== null && (
                            <Grid item xs={12}>
                                <SubCard title="Contact Information">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'title' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.title"
                                                id="create-organization-contactInfo-title"
                                                value={formik.values.contactInfo?.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.title && formik.errors?.contactInfo?.title)}
                                                helperText={formik.touched?.contactInfo?.title && formik.errors?.contactInfo?.title}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'name' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.name"
                                                id="create-organization-contactInfo.name"
                                                value={formik.values.contactInfo?.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.name && formik.errors.contactInfo?.name)}
                                                helperText={formik.touched.contactInfo?.name && formik.errors.contactInfo?.name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MuiTelInput
                                                dir="ltr"
                                                fullWidth
                                                defaultCountry="EG"
                                                forceCallingCode
                                                focusOnSelectCountry
                                                disableFormatting
                                                excludedCountries={['IL']}
                                                name="contactInfo.phone"
                                                id="create-organization-contactInfo.phone"
                                                value={formik.values.contactInfo?.phone || ''}
                                                onChange={(v) => formik.setFieldValue('contactInfo.phone', v)}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone)}
                                                helperText={formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'email' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.email"
                                                id="create-organization-contactInfo.email"
                                                value={formik.values.contactInfo?.email || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.email && formik.errors.contactInfo?.email)}
                                                helperText={formik.touched.contactInfo?.email && formik.errors.contactInfo?.email}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                        {!!selectedLimit && selectedLimit.invitationType.locationInfo !== null && (
                            <Grid item xs={12}>
                                <SubCard title="Location">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'type' })} *`}
                                                name="location.type"
                                                fullWidth
                                                id="contact-info-type"
                                                value={formik.values.location.type || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                error={Boolean(formik.touched.location?.type && formik.errors.location?.type)}
                                                helperText={formik.touched.location?.type && formik.errors.location?.type}
                                                select
                                            >
                                                <MenuItem value="Head Quartes">Head Quartes</MenuItem>
                                                <MenuItem value="Secondary">Secondary</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'area' })} *`}
                                                fullWidth
                                                type="text"
                                                name="location.area"
                                                id="create-organization-location.area"
                                                value={formik.values.location?.area}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.location?.area && formik.errors.location?.area)}
                                                helperText={formik.touched.location?.area && formik.errors.location?.area}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'address' })} *`}
                                                fullWidth
                                                type="text"
                                                name="location.address"
                                                id="create-organization-location.address"
                                                value={formik.values.location?.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.location?.address && formik.errors.location?.address)}
                                                helperText={formik.touched.location?.address && formik.errors.location?.address}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                name="location.country"
                                                fullWidth
                                                displayEmpty
                                                id="create-organization-location-country"
                                                value={formik.values.location.country}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                error={Boolean(formik.touched.location?.country && formik.errors.location?.country)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled value="">
                                                    Select Country
                                                </MenuItem>
                                                {csc.Country.getAllCountries().map((country) => (
                                                    <MenuItem key={country.isoCode} value={country.name}>
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.location?.country && (
                                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                    {formik.errors.location?.country}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                name="location.city"
                                                fullWidth
                                                displayEmpty
                                                id="create-organization-location-city"
                                                value={formik.values.location.city}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                disabled={!handleGetCountryCode()}
                                                error={Boolean(formik.touched.location?.city && formik.errors.location?.city)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled value="">
                                                    <FormattedMessage id="select-city" />
                                                </MenuItem>
                                                {csc.City.getCitiesOfCountry(handleGetCountryCode())?.map((city) => (
                                                    <MenuItem key={city.name} value={city.name}>
                                                        {city.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.location?.city && (
                                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                    {formik.errors.location?.city}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                        {!!selectedLimit && showBusinessInfo && (
                            <Grid item xs={12}>
                                <SubCard title="Business Information">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        {selectedLimit.invitationType.taxId !== null && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'tax-card-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="taxId"
                                                    id="create-organization-businessInfo-taxId"
                                                    value={formik.values.taxId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.taxId && formik.errors?.taxId)}
                                                    helperText={formik.touched?.taxId && formik.errors?.taxId}
                                                />
                                            </Grid>
                                        )}

                                        {selectedLimit.invitationType.commercialCardId != null && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'commercial-register-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="commercialCardId"
                                                    id="create-organization-businessInfo.commericalCardId"
                                                    value={formik.values.commercialCardId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.commercialCardId && formik.errors.commercialCardId)}
                                                    helperText={formik.touched.commercialCardId && formik.errors.commercialCardId}
                                                />
                                            </Grid>
                                        )}

                                        {selectedLimit.invitationType.personalId !== null && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'national-id-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="personalId"
                                                    id="create-organization-businessInfo.personalId"
                                                    value={formik.values.personalId || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.personalId && formik.errors.personalId)}
                                                    helperText={formik.touched.personalId && formik.errors.personalId}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
