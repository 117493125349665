import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryActivityStatusInterface } from '../activityCategory/interfaces';

const res = 'activity-status';

const ACTIVITY_STATUS_APIS = {
    /**
     * @GET
     * @returns statusList
     */
    query: (query: QueryActivityStatusInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ACTIVITY_STATUS_APIS;
