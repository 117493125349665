import { Button, DialogActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CreateSaleClaimSectionProps } from '.';

const StepperDialogActions = ({ handleNext, handleBack, handleClose, disableNext }: CreateSaleClaimSectionProps) => (
    <DialogActions>
        {handleClose && (
            <div style={{ paddingRight: 16 }}>
                <Button size="small" onClick={handleClose}>
                    <FormattedMessage id="cancel" />
                </Button>
            </div>
        )}
        {handleBack && (
            <Button disabled={!handleBack} size="small" onClick={handleBack}>
                <FormattedMessage id="back" />
            </Button>
        )}
        <Button disabled={disableNext} variant="outlined" size="small" onClick={handleNext}>
            <FormattedMessage id="next" />
        </Button>
    </DialogActions>
);

export default StepperDialogActions;
