import {
    Button,
    Dialog,
    DialogActions,
    DialogActionsProps,
    DialogContent,
    DialogContentProps,
    DialogProps,
    DialogTitle,
    DialogTitleProps,
    Grid,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { BannerFormInterface, BannerInterface } from 'store/slices/banners/interfaces';
import MultiTierFilter from 'ui-component/Filters/MultiTierFilter';
import MutliOrganizationRoleFilter from 'ui-component/Filters/MutliOrganizationRoleFilter';
import ImageUploadDragAndDropV3 from 'ui-component/ImageUpload/V3';
import * as Yup from 'yup';

type ExcludeProps<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface BannerDialogProps extends ExcludeProps<DialogProps, 'title' | 'onSubmit'> {
    open: boolean;
    initialData?: BannerInterface;
    title?: React.ReactNode;
    content?: React.ReactNode;
    footer?: React.ReactNode;
    componentProps?: {
        dialogActions?: DialogActionsProps;
        dialogContent?: DialogContentProps;
        dialogTitle?: DialogTitleProps;
    };
    onSubmit?: (values: BannerFormInterface, formikHelpers: any) => void;
    onClose?: () => void;
}

const getInitialValues = (bannerToUpdate?: BannerInterface) => ({
    tierIds: bannerToUpdate?.tiers.map((t) => t.id) ?? [],
    organizationRoles: bannerToUpdate?.organizationRoles.map((t) => t) ?? [],
    title: bannerToUpdate?.title.en ?? '',
    titleAr: bannerToUpdate?.title.ar ?? '',
    content: bannerToUpdate?.content.en ?? '',
    contentAr: bannerToUpdate?.content.ar ?? '',
    redirectTo: bannerToUpdate?.redirectTo ?? '',
    banners: [],
    startDate: bannerToUpdate?.startDate ?? new Date().toISOString(),
    endDate: bannerToUpdate?.endDate ?? ''
});

const CONTENT_MAX_LENGTH = 130;

const BannerDialog = ({
    open = false,
    title,
    content,
    initialData,
    footer,
    componentProps = {},
    onSubmit,
    onClose,
    ...rest
}: BannerDialogProps) => {
    const intl = useIntl();

    const [contentLength, setContentLength] = useState(initialData?.content.en.length || 0);
    const [contentArLength, setContentArLength] = useState(initialData?.content.en.length || 0);

    const schema = Yup.object().shape({
        tierIds: Yup.array()
            .of(Yup.string().required())
            .test('tiers', intl.formatMessage({ id: 'required' }), (value) => (value ?? []).length > 0),
        organizationRoles: Yup.array().of(Yup.string()),
        title: Yup.string().max(50),
        titleAr: Yup.string().max(50),
        content: Yup.string().max(CONTENT_MAX_LENGTH),
        contentAr: Yup.string().max(CONTENT_MAX_LENGTH),
        redirectTo: Yup.string().url(),
        banners: Yup.mixed<File[] | undefined>().test(
            'banners',
            intl.formatMessage({ id: 'required' }),
            (value) => !!initialData || (Array.isArray(value) && value.length > 0)
        ),
        startDate: Yup.date().required(intl.formatMessage({ id: 'required' })),
        endDate: Yup.date()
    });

    const handleAddImages = (files: File[]) => {
        formik.setFieldValue('banners', files);
    };

    const formik = useFormik({
        initialValues: getInitialValues(initialData),
        validationSchema: schema,
        onSubmit: (values, formikHelpers) => {
            const resolvedValues = {
                ...values,
                title: values.title?.trim(),
                titleAr: values.titleAr?.trim(),
                content: values.content?.trim(),
                contentAr: values.contentAr?.trim()
            };

            onSubmit?.(resolvedValues, formikHelpers);
        }
    });

    useEffect(() => {
        if (initialData) {
            formik.setValues(getInitialValues(initialData));
            setContentLength(initialData.content.en.length);
            setContentArLength(initialData.content.ar.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData?.content.en.length, initialData?.content.ar.length]);

    useEffect(() => {
        if (initialData) {
            formik.setFieldValue(
                'tierIds',
                initialData.tiers.map((t) => t.id)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData?.tiers]);

    const newBannerTitle = `${intl.formatMessage({ id: 'new' })} ${intl.formatMessage({ id: 'banner' })}`;
    const editBannerTitle = `${intl.formatMessage({ id: 'edit' })} ${intl.formatMessage({ id: 'banner' })}`;

    return (
        <Dialog open={open} onClose={onClose} scroll="paper" {...rest}>
            <DialogTitle {...componentProps.dialogTitle}>{initialData ? editBannerTitle : newBannerTitle}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent {...componentProps.dialogContent}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MultiTierFilter
                                tierIds={formik.values.tierIds}
                                onSelectedTiersChange={(tiers) =>
                                    formik.setValues({ ...formik.values, tierIds: tiers.map((tier) => tier.id) })
                                }
                                props={{
                                    textFieldProps: {
                                        onBlur: formik.handleBlur,
                                        error: formik.touched.tierIds && Boolean(formik.errors.tierIds),
                                        helperText: formik.touched.tierIds && formik.errors.tierIds
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MutliOrganizationRoleFilter
                                defaultValue={formik.values.organizationRoles}
                                onChange={(_, roles) => formik.setValues({ ...formik.values, organizationRoles: roles })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="title"
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                placeholder={`${intl.formatMessage({ id: 'title' })}*`}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="titleAr"
                                value={formik.values.titleAr}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.titleAr && Boolean(formik.errors.titleAr)}
                                helperText={formik.touched.titleAr && formik.errors.titleAr}
                                placeholder={`${intl.formatMessage({ id: 'title-ar' })}`}
                                sx={{ direction: 'rtl' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6} width="100%">
                            <TextField
                                name="content"
                                value={formik.values.content}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContentLength(e.target.value.length);
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.content && Boolean(formik.errors.content)}
                                helperText={formik.touched.content && formik.errors.content}
                                placeholder={`${intl.formatMessage({ id: 'body' })}*`}
                                inputProps={{ maxLength: CONTENT_MAX_LENGTH }}
                                rows={4}
                                fullWidth
                                multiline
                            />
                            <Stack direction="row" justifyContent="end">
                                <Typography variant="caption">
                                    {contentLength}/{CONTENT_MAX_LENGTH}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                name="contentAr"
                                value={formik.values.contentAr}
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setContentArLength(e.target.value.length);
                                }}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contentAr && Boolean(formik.errors.contentAr)}
                                helperText={formik.touched.contentAr && formik.errors.contentAr}
                                placeholder={`${intl.formatMessage({ id: 'body-ar' })}`}
                                sx={{ direction: 'rtl' }}
                                inputProps={{ maxLength: CONTENT_MAX_LENGTH }}
                                rows={4}
                                fullWidth
                                multiline
                            />
                            <Stack direction="row" justifyContent="end">
                                <Typography variant="caption">
                                    {contentArLength}/{CONTENT_MAX_LENGTH}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="redirectTo"
                                value={formik.values.redirectTo}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.redirectTo && Boolean(formik.errors.redirectTo)}
                                helperText={formik.touched.redirectTo && formik.errors.redirectTo}
                                placeholder={`${intl.formatMessage({ id: 'link' })}`}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploadDragAndDropV3
                                handleAddImages={handleAddImages}
                                props={{
                                    uploadProps: { md: 12 },
                                    errorProps: {
                                        sx: {
                                            borderColor: formik.touched.banners && formik.errors.banners ? 'error.main' : 'primary.light'
                                        }
                                    }
                                }}
                                handleDeleteImage={() => {
                                    formik.setValues({
                                        ...formik.values,
                                        banners: []
                                    });
                                }}
                            />
                            <Typography m={0} mx="14px" color="error" variant="caption">
                                {formik.touched.banners && formik.errors.banners}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={formik.values.startDate}
                                    maxDate={new Date(formik.values.endDate)}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        const startDate = date ? new Date(date?.toString()).toISOString() : '';
                                        formik.setFieldValue('startDate', startDate, true);
                                    }}
                                    renderInput={(props) => (
                                        <TextField
                                            name="startDate"
                                            {...props}
                                            error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                                            helperText={formik.touched.startDate && formik.errors.startDate}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    inputFormat="dd/MM/yyyy"
                                    value={formik.values.endDate}
                                    minDate={formik.values.startDate}
                                    onChange={(date) => {
                                        const endDate = date ? new Date(date?.toString()).toISOString() : '';
                                        formik.setFieldValue('endDate', endDate, true);
                                    }}
                                    renderInput={(props) => (
                                        <TextField
                                            name="endDate"
                                            {...props}
                                            error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                                            helperText={formik.touched.endDate && formik.errors.endDate}
                                            fullWidth
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ px: 0 }} {...componentProps.dialogActions}>
                    {footer ?? <Button onClick={onClose}>Cancel</Button>}
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default BannerDialog;
