import { Container, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/system';
import image1 from 'assets/images/image1.png';
import image2 from 'assets/images/image2.png';
import image3 from 'assets/images/image3.png';
import image4 from 'assets/images/image4.png';
import React, { useEffect, useMemo, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage, useIntl } from 'react-intl';
import JoinNowButton from './JoinNowButton';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

function FeaturesSection({ scrollToTarget }: { scrollToTarget: () => void }) {
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const intl = useIntl();
    const theme = useTheme();
    const { locale } = useIntl();

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setSelectedIndex(newValue);
    };
    const imageItems = [
        {
            src: image1,
            alt: 'feature 1',
            description: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="browse-all-available-units-from-the-largest-developers-in-one-place" />{' '}
                </Typography>
            ),
            title: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.primary,
                        fontSize: '16px',
                        fontWeight: 700,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="all-availability" />
                </Typography>
            )
        },
        {
            src: image2,
            alt: 'feature 2',
            description: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="get-your-client-to-move-now-and-finance-their-home-with-nawy-now" />
                </Typography>
            ),
            title: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.primary,
                        fontSize: '16px',
                        fontWeight: 700,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="nawy-now" />
                </Typography>
            )
        },
        {
            src: image3,
            alt: 'feature 3',
            description: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="control-all-stages-of-sales-process-through-the-platform" />{' '}
                </Typography>
            ),
            title: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.primary,
                        fontSize: '16px',
                        fontWeight: 700,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="sales-tracking" />
                </Typography>
            )
        },
        {
            src: image4,
            alt: 'feature 4',
            description: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.grey[600],
                        fontSize: '14px',
                        fontWeight: 400,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="gain-access-to-the-top-developers-and-get-the-help-you-need-in-the-sales-process" />{' '}
                </Typography>
            ),
            title: (
                <Typography
                    variant="h5"
                    p={{ xs: 2, lg: 0 }}
                    sx={{
                        color: theme.palette.primary,
                        fontSize: '16px',
                        fontWeight: 700,
                        marginBottom: '14px'
                    }}
                    align="center"
                >
                    <FormattedMessage id="nawy-network" />
                </Typography>
            )
        }
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageItems.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + imageItems.length) % imageItems.length);
    };

    function Content(imageName: string, messageId: string) {
        return (
            <div>
                <Grid container spacing={6} justifyContent="center" alignContent="center">
                    <Grid item marginLeft={2}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {isSmallScreen ? (
                                <Box
                                    component="img"
                                    src={imageName}
                                    alt="Content"
                                    style={{
                                        width: '333px',
                                        height: '221px',
                                        flexShrink: '0',
                                        borderRadius: '16px'
                                    }}
                                />
                            ) : (
                                <Box
                                    component="img"
                                    src={imageName}
                                    alt="Content"
                                    style={{
                                        width: '368px',
                                        height: '238px',
                                        borderRadius: '16px'
                                    }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        alignItems={isSmallScreen ? 'center' : 'right'}
                        justifyContent="center"
                        alignContent="center"
                        maxWidth="340px"
                    >
                        <Stack alignItems={{ xs: 'center', lg: 'left' }}>
                            <Typography
                                variant="h5"
                                p={{ xs: 2, lg: 0 }}
                                sx={{
                                    color: theme.palette.grey[600],
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    marginBottom: '14px'
                                }}
                                align={isSmallScreen ? 'center' : 'left'}
                            >
                                <FormattedMessage id={messageId} />
                            </Typography>

                            <JoinNowButton
                                scrollToTarget={scrollToTarget}
                                btnWidth="272px"
                                btnHeight="48px"
                                btnBackgroundColor={theme.palette.primary['400']}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        );
    }

    function AllAvailabilityContent() {
        return Content(image1, 'browse-all-available-units-from-the-largest-developers-in-one-place');
    }

    function NawyNowContent() {
        return Content(image2, 'get-your-client-to-move-now-and-finance-their-home-with-nawy-now');
    }

    function SalesPipelineContent() {
        return Content(image3, 'control-all-stages-of-sales-process-through-the-platform');
    }

    function NawyNetworkContent() {
        return Content(image4, 'gain-access-to-the-top-developers-and-get-the-help-you-need-in-the-sales-process');
    }

    const { inView, ref } = useInView({
        threshold: 0
    });

    const DURATION = 6000;

    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        if (inView) {
            const startTime = Date.now();
            const interval = 0.5;

            const timer = setInterval(() => {
                const elapsedTime = Date.now() - startTime;
                const newProgress = (elapsedTime / DURATION) * 80;

                if (newProgress >= 80) {
                    setProgress(80);
                    setSelectedIndex(selectedIndex === 3 ? 0 : selectedIndex + 1);
                    clearInterval(timer);
                } else {
                    setProgress(newProgress);
                }
            }, interval);

            return () => clearInterval(timer);
        }
        return undefined;
    }, [inView, selectedIndex]);

    const tabs = [
        { label: 'all-availability', element: <AllAvailabilityContent /> },
        { label: 'nawy-now', element: <NawyNowContent /> },
        { label: 'sales-tracking', element: <SalesPipelineContent /> },
        { label: 'nawy-network', element: <NawyNetworkContent /> }
    ];

    const AllAvailabilityContentV1 = useMemo(() => <AllAvailabilityContent />, []);
    const NawyNowContentV1 = useMemo(() => <NawyNowContent />, []);
    const SalesPipelineContentV1 = useMemo(() => <SalesPipelineContent />, []);
    const NawyNetworkContentV1 = useMemo(() => <NawyNetworkContent />, []);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (inView) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imageItems.length);
            }, 6000);

            return () => clearInterval(interval);
        }
        return undefined;
    }, [inView]);

    return (
        <div>
            {isSmallScreen ? (
                <Grid container sx={{ paddingTop: '440px', paddingBottom: '24px', backgroundColor: theme.palette.grey[50] }}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            width: `calc(100% - 80px)`
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '150px' }}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                color="primary.600"
                                fontSize={isSmallScreen ? '24px' : '32px'}
                                fontWeight={700}
                                paddingBottom="24px"
                                align="center"
                            >
                                <FormattedMessage id="nawy-Partners-Features" />
                            </Typography>
                        </Box>
                    </Grid>
                    <div ref={ref} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Grid container marginBottom={7} maxWidth="385px">
                            <Grid item xs={12} justifyItems="center">
                                <Container sx={{ textAlign: 'center' }}>
                                    <Box sx={{ position: 'relative', marginBottom: 2 }}>
                                        <img
                                            src={imageItems[currentIndex].src}
                                            alt={imageItems[currentIndex].alt}
                                            style={{
                                                width: '100%',
                                                borderRadius: '12px',
                                                objectFit: 'cover',
                                                maxHeight: '400px'
                                            }}
                                        />
                                        <Box sx={{ position: 'absolute', top: '130%', left: 0, transform: 'translateY(-80%)' }}>
                                            <IconButton
                                                color="primary"
                                                sx={{
                                                    bgcolor: 'white',
                                                    boxShadow: '0px 1px 8px -1px rgba(16, 24, 40, 0.06)',
                                                    '&:focus': { bgcolor: 'white' },
                                                    marginLeft: '10px'
                                                }}
                                                onClick={handlePrev}
                                            >
                                                <ChevronLeft
                                                    sx={{
                                                        transform: locale === 'ar' ? 'scaleX(-1)' : 'none'
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ position: 'absolute', top: '130%', right: 0, transform: 'translateY(-80%) ' }}>
                                            <IconButton
                                                color="primary"
                                                sx={{
                                                    bgcolor: 'white',
                                                    boxShadow: '0px 1px 8px -1px rgba(16, 24, 40, 0.06)',
                                                    '&:focus': { bgcolor: 'white' },
                                                    marginRight: '10px'
                                                }}
                                                onClick={handleNext}
                                            >
                                                <ChevronRight
                                                    sx={{
                                                        transform: locale === 'ar' ? 'scaleX(-1)' : 'none'
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Grid container justifyContent="center" spacing={1}>
                                        {imageItems.map((item, index) => (
                                            <Grid item key={index}>
                                                <Box
                                                    sx={{
                                                        width: '8px',
                                                        height: '8px',
                                                        borderRadius: '50%',
                                                        backgroundColor: currentIndex === index ? 'primary.main' : 'grey.400',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => setCurrentIndex(index)}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <Typography variant="h5" sx={{ marginTop: 2 }}>
                                        {imageItems[currentIndex].title}
                                    </Typography>
                                    <Typography variant="body1" sx={{ marginTop: 1, color: 'text.secondary' }}>
                                        {imageItems[currentIndex].description}
                                    </Typography>
                                    <Stack alignItems={{ xs: 'center', lg: 'left' }}>
                                        <JoinNowButton
                                            scrollToTarget={scrollToTarget}
                                            btnWidth="350px"
                                            btnHeight="48px"
                                            btnBackgroundColor={theme.palette.primary['400']}
                                        />
                                    </Stack>
                                </Container>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            ) : (
                <Grid container sx={{ backgroundColor: theme.palette.grey[50] }} justifyContent="center" alignContent="center">
                    <Grid
                        item
                        xs={12}
                        sx={{ marginTop: '360px', marginBottom: '24px', paddingBottom: '24px' }}
                        style={{
                            width: `calc(100% - 80px)`,
                            paddingInline: '20px'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography
                                variant="h4"
                                gutterBottom
                                color="primary.600"
                                fontSize={isSmallScreen ? '24px' : '32px'}
                                fontWeight={700}
                                paddingBottom="24px"
                            >
                                <FormattedMessage id="nawy-Partners-Features" />
                            </Typography>
                        </Box>
                    </Grid>
                    <div ref={ref}>
                        <Grid container marginBottom={7} justifyContent="center" alignContent="center" width="1100px">
                            <Grid item xs={3}>
                                <Box sx={{ width: '100%' }}>
                                    <Tabs
                                        value={selectedIndex}
                                        onChange={handleChange}
                                        orientation="vertical"
                                        TabIndicatorProps={{
                                            style: {
                                                display: 'none'
                                            }
                                        }}
                                        style={{ transform: 'translateX(0)', transition: 'transform 0.2s ease-in' }}
                                        sx={{
                                            '& .MuiTabs-flexContainer ': {
                                                border: 'none',
                                                paddingBottom: '16px'
                                            }
                                        }}
                                    >
                                        {tabs.map((tab, index) => (
                                            <Tab
                                                key={tab.label}
                                                label={intl.formatMessage({ id: tab.label })}
                                                sx={{
                                                    marginRight: '16px',
                                                    marginLeft: '16px',
                                                    width: selectedIndex === index ? '240px' : '177px',
                                                    height: selectedIndex === index ? '80px' : '24px',
                                                    color: theme.palette.primary.main,
                                                    fontWeight: selectedIndex === index ? '700' : '500',
                                                    borderRadius: selectedIndex === index ? '8px' : '0px',
                                                    background: selectedIndex === index ? `rgba(255, 255, 255, 0.20)` : 'transparent',
                                                    boxShadow: selectedIndex === index ? `0px 4px 6px 0px rgba(16, 24, 40, 0.12)` : 'none',
                                                    fontSize: selectedIndex === index ? '20px' : '14px',
                                                    alignItems: 'start',
                                                    ...(selectedIndex === index
                                                        ? {
                                                              '&::after': {
                                                                  content: '""',
                                                                  position: 'absolute',
                                                                  width: `${progress}%`,
                                                                  height: '4px',
                                                                  borderRadius: '8px',
                                                                  bgcolor: 'primary.main',
                                                                  bottom: '16px',
                                                                  zIndex: -1,
                                                                  transition: 'all linear'
                                                              }
                                                          }
                                                        : {})
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </Box>
                            </Grid>
                            <Grid item>
                                {selectedIndex === 0 && AllAvailabilityContentV1}
                                {selectedIndex === 1 && NawyNowContentV1}
                                {selectedIndex === 2 && SalesPipelineContentV1}
                                {selectedIndex === 3 && NawyNetworkContentV1}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            )}
        </div>
    );
}
export default FeaturesSection;
