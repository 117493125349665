import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { getPropertyTypesFilter } from 'store/slices/filters/actions';
import { PerfectFilterValueInterface } from 'store/slices/filters/interfaces';

interface PropertyTypeFilterProps {
    defaultValueId?: number;
    value?: number;
    handleSearch: (newValue?: number) => void;
    handleSelected?: (propertyType?: PerfectFilterValueInterface | null | undefined) => void;
}

const PropertyTypeFilter = ({ defaultValueId, value, handleSearch, handleSelected }: PropertyTypeFilterProps) => (
    <Filter defaultValueId={defaultValueId} value={value} handleSearch={handleSearch} handleSelected={handleSelected} />
);

const Filter = ({ defaultValueId, value, handleSearch, handleSelected }: PropertyTypeFilterProps) => {
    const intl = useIntl();
    const { propertyTypes, propertyTypesLoading } = useSelector((state) => state.filters);

    const [newValue, setNewValue] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<PerfectFilterValueInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(getPropertyTypesFilter({ query: { name } }));
        setNewValue(name);
    };

    const handleOnChange = (propertyType: PerfectFilterValueInterface | null | undefined) => {
        setSelectedValue(propertyType);
        handleSearch(propertyType?.id);
        if (handleSelected) handleSelected(propertyType);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(getPropertyTypesFilter({ query: { id: defaultValueId } }));
        } else {
            dispatch(getPropertyTypesFilter({ query: { name: newValue } }));
        }
    }, [defaultValueId, newValue]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && propertyTypes.count > 0) {
            firstRender.current = false;
            const propertyType = propertyTypes.data.find((c) => c.id === defaultValueId) ?? null;
            setSelectedValue(propertyType);
        }
    }, [propertyTypes, defaultValueId]);

    return (
        <Autocomplete
            id="autocomplete-generic-filter-property-types"
            fullWidth
            size="small"
            options={propertyTypes.data}
            loading={propertyTypesLoading}
            value={selectedValue}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option) => option?.id === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {
                        option.name
                            .split(/(?=[A-Z])/) // Split the string at each uppercase letter
                            .join('-') // Join the parts with a hyphen
                            .toLowerCase() // Convert the entire string to lowercase
                    }
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-property-type"
                    label={intl.formatMessage({ id: 'property-type' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default PropertyTypeFilter;
