import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const InventoryControlPage = Loadable(lazy(() => import('views/settings/InventoryControl')));
const NawyInventoryPage = Loadable(lazy(() => import('views/common/NawyInventory')));

// ==============================|| ALLOWED GROUPS ROUTING ||============================== //

// const allowedGroups = [InvitationTypeEnum.brokers, InvitationTypeEnum.inventoryManagers];

// ==============================|| CILs ROUTING ||============================== //

const InventoryRoutes = {
    path: '/inventory',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.nawyInventory }, { name: InvitationTypeEnum.admins }]}
                >
                    <NawyInventoryPage />
                </RoleGuard>
            )
        },
        {
            path: 'control',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.brokers },
                        { name: InvitationTypeEnum.inventoryManagers },
                        { name: InvitationTypeEnum.admins }
                    ]}
                >
                    <InventoryControlPage />
                </RoleGuard>
            )
        }
    ]
};

export default InventoryRoutes;
