import { createSlice } from '@reduxjs/toolkit';
import { getFlowHistory } from './actions';
import { FlowsHistoryInitialStateInterface } from './interfaces';

const initialState: FlowsHistoryInitialStateInterface = {
    loading: false,
    error: false,
    listError: false,
    listLoading: false,
    refresh: false,
    listRefresh: false,
    flowHistory: []
};

// ==============================|| SLICE - FLOWS ||============================== //

const flowHistory = createSlice({
    name: 'flowHistory',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder

            .addCase(getFlowHistory.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getFlowHistory.fulfilled, (state, action) => {
                state.loading = false;
                state.flowHistory = action.payload;
            })
            .addCase(getFlowHistory.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default flowHistory.reducer;
