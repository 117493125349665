import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import CustomizedMenus from './ChannelsButton';
import { useState } from 'react';
import { Channel } from 'store/slices/notifications/interfaces';
import { useSelector } from 'store';
import EmailConfig from './EmailConfig';
import SmsConfig from './SmsConfig';
import SystemConfig from './SystemConfig';
import WhatsAppConfig from './WhatsappConfig';

const ChannelConfiguration = () => {
    const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>(undefined);
    const { notificationType } = useSelector((state) => state.notificationTypes);

    return (
        <MainCard
            title={<FormattedMessage id="configs" />}
            secondary={
                Object.values(Channel).filter((channel) => !notificationType?.configurations.some((c) => c.channel === channel)).length >
                    0 && <CustomizedMenus setChannel={setSelectedChannel} />
            }
            content={(notificationType?.configurations && notificationType?.configurations.length > 0) || selectedChannel !== undefined}
        >
            {((notificationType?.configurations && notificationType?.configurations.length > 0) || selectedChannel !== undefined) && (
                <Grid container spacing={gridSpacing}>
                    {selectedChannel === Channel.whatsapp && (
                        <Grid item xs={12}>
                            <WhatsAppConfig addNew onCancel={setSelectedChannel} />
                        </Grid>
                    )}
                    {selectedChannel === Channel.email && (
                        <Grid item xs={12}>
                            <EmailConfig addNew onCancel={setSelectedChannel} />
                        </Grid>
                    )}
                    {selectedChannel === Channel.sms && (
                        <Grid item xs={12}>
                            <SmsConfig addNew onCancel={setSelectedChannel} />
                        </Grid>
                    )}
                    {selectedChannel === Channel.system && (
                        <Grid item xs={12}>
                            <SystemConfig addNew onCancel={setSelectedChannel} />
                        </Grid>
                    )}
                    {notificationType?.configurations.map((config) => (
                        <>
                            {config.channel === Channel.whatsapp && (
                                <Grid item xs={12}>
                                    <WhatsAppConfig addNew={false} />
                                </Grid>
                            )}
                            {config.channel === Channel.sms && (
                                <Grid item xs={12}>
                                    <SmsConfig addNew={false} />
                                </Grid>
                            )}
                            {config.channel === Channel.email && (
                                <Grid item xs={12}>
                                    <EmailConfig addNew={false} />
                                </Grid>
                            )}
                            {config.channel === Channel.system && (
                                <Grid item xs={12}>
                                    <SystemConfig addNew={false} />
                                </Grid>
                            )}
                        </>
                    ))}
                </Grid>
            )}
        </MainCard>
    );
};

export default ChannelConfiguration;
