import { Props } from 'react-apexcharts';

export interface ChartDataInterface {
    seriesData: { data: number[]; name: string }[];
    categories: string[];
    title: string;
    id: string;
    limit?: number;
}

const verticalBarConfig = ({ categories, seriesData, id = 'bar-chart' }: ChartDataInterface): Props => {
    const chartData: Props = {
        height: 60 * seriesData[0].data.length >= 400 ? 60 * seriesData[0].data.length : 400,
        type: 'bar',
        options: {
            noData: {
                text: 'No Data Available'
            },
            colors: ['#165db2'],
            chart: {
                id,
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    dataLabels: {
                        total: {
                            enabled: true,
                            formatter(val, opts) {
                                return `${Intl.NumberFormat('en', { notation: 'compact' }).format(parseInt(val || '', 10))}`;
                            },

                            offsetY: -2,
                            style: {
                                fontSize: '14px',
                                fontWeight: 900,
                                color: '#212121'
                            }
                        }
                    }
                }
            },
            xaxis: {
                type: 'category',
                categories,
                position: 'bottom',
                axisTicks: {
                    show: false
                },

                labels: {
                    hideOverlappingLabels: false,
                    trim: true,
                    // show: false,
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        fontFamily: `'Poppins', sans-serif`,
                        colors: '#212121'
                    }
                }
            },
            yaxis: {
                max: Math.max(...seriesData[0].data) * 1.1,
                forceNiceScale: true,
                labels: {
                    formatter(val, opts) {
                        return Intl.NumberFormat('en', { notation: 'compact' }).format(val);
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: `'Poppins', sans-serif`,
                        colors: '#9E9E9E'
                    },

                    offsetX: 10
                }
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            grid: {
                show: false
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: [
                    {
                        formatter(val, opts) {
                            return `${Intl.NumberFormat('en', { notation: 'compact' }).format(val)}`;
                        }
                    },
                    {
                        formatter(val, opts) {
                            return `${val} units`;
                        }
                    }
                ]
            }
        },
        series: seriesData
    };
    return chartData;
};
export default verticalBarConfig;
