// types
import { createSlice } from '@reduxjs/toolkit';

import {
    financingTopPartners,
    primaryCilsTopDevelopers,
    reservationsTopCompounds,
    reservationsTopDevelopers,
    saleClaimsTopCompounds,
    saleClaimsTopDevelopers
} from './actions';
import { PartnersDashboardInterface } from './interfaces';

// initial state
const initialState: PartnersDashboardInterface = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    topCompounds: [],
    topDevelopers: [],
    primaryCilsTopDevelopers: [],
    financingCilsTopPartners: []
};

// ==============================|| SLICE - PARTNERS DASHBOARD ||============================== //

const partnersDashboard = createSlice({
    name: 'partnersDashboard',
    initialState,
    reducers: {
        refreshPartnersDashboardList: (state) => {
            state.listRefresh = !state.listRefresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(financingTopPartners.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(financingTopPartners.fulfilled, (state, action) => {
                state.listLoading = false;
                state.financingCilsTopPartners = action.payload;
            })
            .addCase(financingTopPartners.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(primaryCilsTopDevelopers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(primaryCilsTopDevelopers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.primaryCilsTopDevelopers = action.payload;
            })
            .addCase(primaryCilsTopDevelopers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(saleClaimsTopDevelopers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(saleClaimsTopDevelopers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topDevelopers = action.payload;
            })
            .addCase(saleClaimsTopDevelopers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(saleClaimsTopCompounds.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(saleClaimsTopCompounds.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topCompounds = action.payload;
            })
            .addCase(saleClaimsTopCompounds.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(reservationsTopCompounds.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
                state.topCompounds = [];
            })
            .addCase(reservationsTopCompounds.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topCompounds = action.payload;
            })
            .addCase(reservationsTopCompounds.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(reservationsTopDevelopers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
                state.topDevelopers = [];
            })
            .addCase(reservationsTopDevelopers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.topDevelopers = action.payload;
            })
            .addCase(reservationsTopDevelopers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            });
    }
});

export default partnersDashboard.reducer;

export const { refreshPartnersDashboardList } = partnersDashboard.actions;
