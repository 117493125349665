// hooks
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

// constants
import { gridSpacing } from 'store/constant';

// third party
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import * as yup from 'yup';

// icons
import useOverlay from 'hooks/useOverlay';
import { MuiTelInput } from 'mui-tel-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { inviteOrganizationBroker } from 'store/slices/organizations/actions';
import { openSnackbar } from 'store/slices/snackbar';

export default function InvitationBrokerDialog({ organizationId }: { organizationId: string }) {
    const intl = useIntl();
    const { isCurrentOpened, close } = useOverlay('InvitationBrokerDialog');
    const InviteOrganizationBrokerSchema = yup.object().shape({
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' }))
    });
    const dispatch = useDispatch();
    const { brokersListError: error } = useSelector((state) => state.organizations);

    const handleClose = () => {
        formik.resetForm();
        close();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (isCurrentOpened) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [isCurrentOpened]);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'sales-agent-invited-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);

        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            phone: ''
        },
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            const isPhoneValid = parsePhoneNumber(values?.phone, 'EG');

            if (!isPhoneValid?.isValid()) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }

            if (isPhoneValid.country) {
                dispatch(
                    inviteOrganizationBroker({
                        organizationId,
                        invitationData: { phone: values.phone, countryCode: isPhoneValid.country },
                        runSuccess
                    })
                );
            } else {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'invalid-phone' }));
                formikHelpers.setSubmitting(false);
            }
        },
        validationSchema: InviteOrganizationBrokerSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            <Dialog
                open={isCurrentOpened}
                onClose={handleClose}
                scroll="paper"
                maxWidth="xs"
                fullWidth
                aria-labelledby="Invite-sales-agent-to-organization"
                aria-describedby="Invite-sales-agent-to-organization"
            >
                <DialogTitle id="Invite-sales-agent-to-organization">
                    <FormattedMessage id="invite" /> <FormattedMessage id="team" /> <FormattedMessage id="member" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Typography>
                                <FormattedMessage id="invite-sales-agent-message" />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiTelInput
                                dir="ltr"
                                fullWidth
                                disabled={formik.isSubmitting}
                                id="partner-contact-info-phone"
                                name="phone"
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                excludedCountries={['IL']}
                                defaultCountry="EG"
                                label={`${intl.formatMessage({ id: 'phone' })} *`}
                                value={formik.values.phone}
                                onChange={(v) => formik.setFieldValue('phone', v)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton
                        loading={formik.isSubmitting}
                        onClick={formik.submitForm}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >
                        <FormattedMessage id="invite" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
