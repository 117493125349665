import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useMixPanel from 'hooks/useMixPanel';

interface JoinNowButtonProps {
    scrollToTarget: () => void;
    btnColor?: string;
    btnWidth?: string;
    btnHeight?: string;
    btnBackgroundColor?: string;
    btnFontColor?: string;
}

const JoinNowButton: React.FC<JoinNowButtonProps> = ({
    scrollToTarget,
    btnColor,
    btnWidth,
    btnHeight,
    btnBackgroundColor,
    btnFontColor
}) => {
    const { emitEvent } = useMixPanel();
    const handleClick = () => {
        scrollToTarget();
        emitEvent('Click Join Now Button');
    };

    return (
        <div>
            <Button
                variant="contained"
                color={btnBackgroundColor === 'white' ? 'inherit' : 'primary'}
                size="large"
                onClick={handleClick}
                sx={{
                    backgroundColor: btnBackgroundColor,
                    width: btnWidth,
                    height: btnHeight,
                    fontStyle: { color: btnFontColor },
                    borderRadius: '8px'
                }}
            >
                <FormattedMessage id="join-now" />
            </Button>
        </div>
    );
};

export default JoinNowButton;
