import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from 'utils/axios';
import DEVELOPERS_APIS from './apis';
import {
    CreateDeveloperInterface,
    PrimaryCilConfigInterface,
    QueryDevelopersInterface,
    QueryPartnerCompoundsInterface,
    UpdateDeveloperInterface
} from './interfaces';

export const queryDevelopers = createAsyncThunk(
    'developers/query',
    async (
        requestData: {
            query: QueryDevelopersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data } = await axiosInstance.get(DEVELOPERS_APIS.query(query));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const queryCompounds = createAsyncThunk(
    'developers/query-compounds',
    async (
        requestData: {
            query: QueryPartnerCompoundsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data } = await axiosInstance.get(DEVELOPERS_APIS.queryCompounds(query));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const createDevelopers = createAsyncThunk(
    'developers/create',
    async (
        requestData: {
            data: CreateDeveloperInterface;
            runSuccess?: (developerId: number) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { data, runSuccess } = requestData;
        try {
            const res = await axiosInstance.post(DEVELOPERS_APIS.create(), data);
            if (runSuccess) runSuccess(res.data?.developer?.id);
            return res.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateDeveloper = createAsyncThunk(
    'developers/update',
    async (
        requestData: {
            id: number;
            data: UpdateDeveloperInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, data, runSuccess } = requestData;
        try {
            const res = await axiosInstance.patch(DEVELOPERS_APIS.update(id), data);
            if (runSuccess) runSuccess();
            return res.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateDeveloperConfig = createAsyncThunk(
    'developers/update-cil-config',
    async (
        requestData: {
            id: number;
            data: Partial<PrimaryCilConfigInterface>;
            runSuccess?: (developerId: number) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, data, runSuccess } = requestData;
        try {
            const res = await axiosInstance.patch(DEVELOPERS_APIS.updateCilConfiguration(id), data);
            if (runSuccess) runSuccess(res.data?.developer?.id);
            return res.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const createBatchDeveloper = createAsyncThunk(
    'developers/create-batch',
    async (
        requestData: {
            data: { developers: CreateDeveloperInterface[] };
            runSuccess?: (count: number) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { data, runSuccess } = requestData;
        try {
            const res = await axiosInstance.post(DEVELOPERS_APIS.createBatch(), data);
            if (runSuccess) runSuccess(res?.data?.count);
            return res.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getDeveloperById = createAsyncThunk(
    'developers/get-by-id',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axiosInstance.get(DEVELOPERS_APIS.getById(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getDeveloperByIdWithAxios = createAsyncThunk(
    'developers/get-by-id',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;

        const serviceToken = localStorage.getItem('serviceToken');
        try {
            const { data } = await axios.get(DEVELOPERS_APIS.getById(id), {
                headers: { content: 'application/json', Authorization: `Bearer ${serviceToken}` }
            });
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const renewDeveloper = createAsyncThunk(
    'developers/renew',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axiosInstance.patch(DEVELOPERS_APIS.renew(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateDeveloperConfigStatus = createAsyncThunk(
    'developers/update-status',
    async (
        requestData: {
            id: number;
            status: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, status, runSuccess } = requestData;
        try {
            const { data } = await axiosInstance.patch(DEVELOPERS_APIS.updateStatus(id), { status });
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
