import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router';

// Admin routing
const CilsPage = Loadable(lazy(() => import('views/cils')));
const CilDetailsPage = Loadable(lazy(() => import('views/cils/financingCils/Cil')));
const PrimaryCILDetailsPage = Loadable(lazy(() => import('views/cils/primaryCils/Flow')));

// ==============================|| ALLOWED GROUPS ROUTING ||============================== //

// const allowedGroups = [InvitationTypeEnum.brokers, InvitationTypeEnum.accountManagers];

// ==============================|| CILs ROUTING ||============================== //

const CilsRoutes = {
    path: '/cils',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.brokers },
                    { name: InvitationTypeEnum.accountManagers },
                    { name: InvitationTypeEnum.admins }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <CilsPage />
        },
        {
            path: ':id',
            element: <CilDetailsPage />
        },
        {
            path: 'primary-cils/:id',
            element: <PrimaryCILDetailsPage />
        }
    ]
};

export default CilsRoutes;
