export interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    sort: boolean;
    align: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
    minWidth?: string;
}

export const compoundHeadCells: readonly HeadCell[] = [
    {
        id: 'name',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'compound-name'
    },
    {
        id: 'city-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'area'
    },
    {
        id: 'developer',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'developer'
    },
    {
        id: 'property-types',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'property-type',
        minWidth: '205px'
    },
    {
        id: 'sale_type',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'sale-type',
        minWidth: '100px'
    },
    {
        id: 'compound-build-up-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bua-m2'
    },
    {
        id: 'price',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'price-egp'
    },
    {
        id: 'down-payment',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'down-payment-egp'
    },
    {
        id: 'compound-installments',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'monthly-installments-egp'
    },
    {
        id: 'finishing',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'finishing',
        minWidth: '200px'
    },
    {
        id: 'last_update',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'last-updated'
    }
];

export const propertyTypesHeadCells: readonly HeadCell[] = [
    {
        id: 'property-type',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'property-type',
        minWidth: '200px'
    },
    {
        id: 'property-type-business-type',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'sale-type'
    },
    {
        id: 'property-type-build-up-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bua-m2'
    },
    {
        id: 'price',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'price-egp'
    },
    {
        id: 'down-payment',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'down-payment-per'
    },
    {
        id: 'property-types-installments',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'monthly-installments-egp'
    },
    {
        id: 'delivery-date',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'delivery-date'
    },
    {
        id: 'bedrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bedrooms'
    },
    {
        id: 'bathrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bathrooms'
    },
    {
        id: 'property-types-finishing',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'finishing',
        minWidth: '200px'
    },
    {
        id: 'property-types-last-update',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'last-updated'
    }
];

export const propertyHeaders: readonly HeadCell[] = [
    {
        id: 'property-unit-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'unit-area-m2'
    },
    {
        id: 'property-price',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'price-egp'
    },
    {
        id: 'property-down-payment',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'down-payment-per'
    },
    {
        id: 'property-installments',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'monthly-installments-egp'
    },
    {
        id: 'property-business-type',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'sale-type'
    },
    {
        id: 'property-delivery-date',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'delivery-date'
    },
    {
        id: 'property-bedrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bedrooms'
    },
    {
        id: 'property-bathrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bathrooms'
    },
    {
        id: 'property-finishing',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'finishing',
        minWidth: '200px'
    },
    {
        id: 'property-garden',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'garden-area-m2'
    },
    {
        id: 'property-land-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'land-area-m2'
    },
    {
        id: 'property-last-update',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'last-updated'
    },
    {
        id: 'website-view',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'visit-website'
    }
];

export const detailedPropertyHeaders: readonly HeadCell[] = [
    {
        id: 'detailed-property-unit-code',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'unit-code'
    },
    {
        id: 'detailed-property-unit-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'unit-area-m2'
    },
    {
        id: 'detailed-property-price',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'price-egp'
    },
    {
        id: 'detailed-property-payment-plans',
        align: 'center',
        disablePadding: false,
        sort: false,
        // label: 'Payment Plans(% - EGP - Years)'
        label: 'd-i-y'
    },
    {
        id: 'detailed-property-business-type',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'sale-type'
    },
    {
        id: 'detailed-property-delivery-date',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'delivery-date'
    },
    {
        id: 'detailed-property-bedrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bedrooms'
    },
    {
        id: 'detailed-property-bathrooms',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'bathrooms'
    },
    {
        id: 'detailed-property-finishing',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'finishing'
    },
    {
        id: 'detailed-property-garden-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'garden-area-m2'
    },
    {
        id: 'detailed-property-land-area',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'land-area-m2'
    },
    {
        id: 'detailed-property-last-update',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'last-updated'
    }
];
