import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Stack,
    TablePagination,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { appDrawerWidth, gridSpacing, requestDelay } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import MainComponent from 'ui-component/MainComponent';
import { NotificationTypeMinimalResponse, QueryNotificationTypes } from 'store/slices/notifications/interfaces';
import { queryNotificationTypes } from 'store/slices/notifications/actions';
import NotificationTypesList from './NotificationTypesList';
import NotificationTypesFilterView from './NotificationTypesFilterView';
import CreateNotificationTypeDialog from './CreateNotificationType';
import NOTIFICATION_TYPES_APIS from 'store/slices/notifications/apis';
import axios from 'utils/axios';
import NotificationTypesFilter from './NotificationTypesFilter';

const NotificationsPage = () => {
    const intl = useIntl();
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { notificationTypes } = useSelector((state) => state.notificationTypes);

    // pagination
    const [page, setPage] = useState(1);
    const [pageStateArray, setPageStateArray] = useState<(string | undefined)[]>([undefined]);
    const [pageSize, setPageSize] = useState(notificationTypes.pageSize);
    const [typesNames, setTypesNames] = useState<NotificationTypeMinimalResponse[]>([]);
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage + 1);
        filterData(filter, pageStateArray[newPage], pageSize);
    };
    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        setPage(1);
        setPageStateArray([undefined]);
        filterData(filter, pageStateArray[0], newPageSize);
    };

    // drawer
    const [open, setOpen] = useState(false);
    const handleDrawerOpen = () => {
        setOpen((prevState) => !prevState);
    };

    // mediaQueries
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    const handleSearch = async (typeId: number | undefined) => {
        handelFilter('typeId', { typeId });
    };
    // filter
    const initialState: QueryNotificationTypes = {
        portal: '',
        typeId: undefined
    };
    const [filter, setFilter] = useState(initialState);

    const filterIsEqual = (a1: QueryNotificationTypes, a2: QueryNotificationTypes) =>
        a1 === a2 || (a1.name === a2.name && a1.portal === a2.portal && a1.typeId === a2.typeId);

    const handelFilter = (type: string, params: QueryNotificationTypes) => {
        let newFilter = {};
        switch (type) {
            case 'portal':
                setFilter({ ...filter, portal: params.portal });
                newFilter = { ...filter, portal: params.portal };
                break;
            case 'typeId':
                setFilter({ ...filter, typeId: params.typeId });
                newFilter = { ...filter, typeId: params.typeId };
                break;
            case 'reset':
                setFilter(initialState);
                newFilter = initialState;
                break;
            default:
            // no options
        }
        setPage(1);
        filterData(newFilter, pageStateArray[0], pageSize);
    };

    const runSuccess = (pageState: string) => {
        const newPageStateArray = [...pageStateArray, pageState];
        setPageStateArray(newPageStateArray);
    };
    const filterData = useRef(
        debounce(async (data, currentPageState, currentPageSize) => {
            dispatch(queryNotificationTypes({ queryData: { ...data, page: currentPageState, pageSize: currentPageSize }, runSuccess }));
        }, requestDelay)
    ).current;

    useEffect(() => {
        filterData(filter, pageStateArray[page - 1], pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getTypesNames = async () => {
        const typesNamesRes = await axios.get(NOTIFICATION_TYPES_APIS.getTypesNames());
        setTypesNames(typesNamesRes.data);
    };

    useEffect(() => {
        getTypesNames();
    }, []);

    const spacingMD = matchDownMD ? 1 : 1.5;
    const dispatch = useDispatch();

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard
                    bgcolor={theme.palette.background.default}
                    title={
                        <Grid container spacing={gridSpacing} alignItems="center">
                            <Grid item>
                                <FormattedMessage id="notification-types" />
                            </Grid>
                            <Grid item>
                                <CreateNotificationTypeDialog />
                            </Grid>
                        </Grid>
                    }
                    secondary={
                        <>
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={matchDownSM ? 0.5 : spacingMD}
                                >
                                    <Autocomplete
                                        id="name"
                                        options={typesNames}
                                        sx={{ width: { xs: 140, md: 200 } }}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) handleSearch(newValue.typeId);
                                            else handleSearch(undefined);
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.typeId}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={intl.formatMessage({ id: 'name' })}
                                                name="name"
                                                id="name"
                                                placeholder={intl.formatMessage({ id: 'name' })}
                                            />
                                        )}
                                    />

                                    <Typography sx={{ pl: 1.5, fontSize: '1rem', color: 'grey.500', fontWeight: 400 }}>|</Typography>
                                    <Button
                                        disableRipple
                                        onClick={handleDrawerOpen}
                                        startIcon={<FilterAltIcon sx={{ fontWeight: 500, color: 'primary.main' }} />}
                                    >
                                        <FormattedMessage id="filter" />
                                    </Button>
                                </Stack>
                            </Box>
                            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                                <IconButton onClick={handleDrawerOpen} color="secondary">
                                    <FilterAltIcon sx={{ fontWeight: 500, color: 'secondary.200' }} />
                                </IconButton>
                            </Box>
                        </>
                    }
                    content={false}
                />
            </Grid>

            <Grid item xs={12}>
                <Divider sx={{ borderColor: 'grey.400' }} />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Grid container>
                        <MainComponent open={open}>
                            <NotificationTypesFilterView
                                filter={filter}
                                filterIsEqual={filterIsEqual}
                                handelFilter={handelFilter}
                                initialState={initialState}
                            />
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <SubCard content={false}>
                                    <NotificationTypesList />
                                    <TablePagination
                                        component="div"
                                        count={notificationTypes.count}
                                        page={page - 1}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={pageSize}
                                        onRowsPerPageChange={handleChangePageSize}
                                    />
                                </SubCard>
                            </Box>
                        </MainComponent>

                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <SubCard content={false}>
                                <NotificationTypesList />
                                <TablePagination
                                    component="div"
                                    count={notificationTypes.count}
                                    page={page - 1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    onRowsPerPageChange={handleChangePageSize}
                                />
                            </SubCard>
                        </Grid>
                    </Grid>
                    <Drawer
                        sx={{
                            ml: open ? 3 : 0,
                            height: matchDownLG ? '100vh' : 'auto',
                            flexShrink: 0,
                            zIndex: { xs: 1200, lg: open ? 0 : -1 },
                            overflowX: 'hidden',
                            width: appDrawerWidth,
                            '& .MuiDrawer-paper': {
                                height: 'auto',
                                width: appDrawerWidth,
                                position: matchDownLG ? 'fixed' : 'relative',
                                border: 'none',
                                borderRadius: matchDownLG ? 0 : `${borderRadius}px`,
                                marginTop: matchDownLG ? (matchDownMD ? 0 : 10) : 0
                            }
                        }}
                        variant={matchDownLG ? 'temporary' : 'persistent'}
                        anchor="right"
                        open={open}
                        ModalProps={{ keepMounted: true }}
                        onClose={handleDrawerOpen}
                    >
                        {open && (
                            <PerfectScrollbar component="div">
                                <NotificationTypesFilter filter={filter} handelFilter={handelFilter} typesNames={typesNames} />
                            </PerfectScrollbar>
                        )}
                    </Drawer>
                </Box>
            </Grid>
        </Grid>
    );
};

export default NotificationsPage;
