import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryCRMControlInterface } from './interfaces';

const CRM_CONTROL_API = {
    create: () => `${FAUNA_API}crms`,

    query: (query: QueryCRMControlInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'crms', '?'];
        return formattedQuery(formatQuery, query);
    },

    find: (id: number) => `${FAUNA_API}crms/${id}`,

    update: (id: number) => `${FAUNA_API}crms/${id}`
};

export default CRM_CONTROL_API;
