// assets
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
// material-ui
import { Box, Button, Collapse, IconButton, Link, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WEBSITE_FRONTEND } from 'store/apis';
import { businessTypesObject, propertyFinishing } from 'store/slices/inventoryTable/interfaces';
// project imports
import { IProperty } from 'store/slices/nawyInventory/interfaces';

import useMixPanel from 'hooks/useMixPanel';
import { FormattedMessage, useIntl } from 'react-intl';
import DetailedPropertiesList from './DetailedPropertiesList';
import { propertyHeaders } from './InventoryHeaders';
import removeDecimalPoint from './helpers';

export default function PropertyRow({ property }: { property: IProperty }) {
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { emitEvent } = useMixPanel();
    const intl = useIntl();
    useEffect(() => {
        const selected_property_id = searchParams.get('selected_property_id');
        if (selected_property_id && +selected_property_id === property.property_id) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const handlePropertyChange = () => {
        searchParams.delete('selected_property_type');
        const selected_property_id = searchParams.get('selected_property_id');
        searchParams.delete('selected_property_id');
        if (!selected_property_id || (selected_property_id && +selected_property_id !== property.property_id))
            searchParams.set('selected_property_id', property.property_id.toString());
        emitEvent('Click View Property Inventory', { ...property });
        setSearchParams(searchParams);
    };

    const getSelectedCompoundId = () => {
        const selected_compound_id = searchParams.get('selected_compound_id');

        return selected_compound_id;
    };

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={handlePropertyChange}>
                <TableCell sx={{ pl: 3 }}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    {property.max_area !== property.min_area ? `${property.min_area} - ${property.max_area}` : property.max_area}
                </TableCell>
                <TableCell align="center">
                    {property.min_price !== property.max_price ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{property.min_price ? removeDecimalPoint(property.min_price) : '-'}-</Typography>
                            <Typography variant="inherit">{property.max_price ? removeDecimalPoint(property.max_price) : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {property.max_price ? (+property.max_price).toLocaleString(undefined, { maximumFractionDigits: 2 }) : '-'}
                        </Typography>
                    )}
                </TableCell>
                {/* <TableCell align="center">
                    {property.min_down_payment && parseInt(property.min_down_payment, 10)
                        ? ((+property.min_down_payment / 100) * +property.min_price).toLocaleString(undefined, { maximumFractionDigits: 2 })
                        : '-'}
                </TableCell> */}
                <TableCell align="center">
                    {property.min_down_payment !== property.max_down_payment ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {property.min_down_payment ? removeDecimalPoint(property.min_down_payment) : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {property.max_down_payment ? removeDecimalPoint(property.max_down_payment) : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {property.min_down_payment ? removeDecimalPoint(property.min_down_payment) : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {property.min_installments !== property.max_installments ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {property.min_installments ? removeDecimalPoint(property.min_installments) : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {property.max_installments ? removeDecimalPoint(property.max_installments) : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {property.min_installments ? removeDecimalPoint(property.min_installments) : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {property.business_type ? intl.formatMessage({ id: businessTypesObject[property.business_type] }) : '-'}
                </TableCell>
                <TableCell align="center">
                    {property.min_delivery_date !== property.max_delivery_date ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {property.min_delivery_date ? format(new Date(property.min_delivery_date), 'dd-MM-yyyy') : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {property.max_delivery_date ? format(new Date(property.max_delivery_date), 'dd-MM-yyyy') : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {property.min_delivery_date ? (
                                new Date(property.min_delivery_date) < new Date() ? (
                                    <FormattedMessage id="ready_to_move" />
                                ) : (
                                    format(new Date(property.min_delivery_date), 'dd-MM-yyyy')
                                )
                            ) : (
                                '-'
                            )}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {property.min_bedrooms !== property.max_bedrooms ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{property.min_bedrooms ? property.min_bedrooms : '-'} -</Typography>
                            <Typography variant="inherit">{property.max_bedrooms ? property.max_bedrooms : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{property.min_bedrooms ? property.min_bedrooms : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {property.min_bathrooms !== property.max_bathrooms ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{property.min_bathrooms ? property.min_bathrooms : '-'} -</Typography>
                            <Typography variant="inherit">{property.max_bathrooms ? property.max_bathrooms : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{property.min_bathrooms ? property.min_bathrooms : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
                    {property.finishing ? <FormattedMessage id={propertyFinishing[property.finishing]} /> : '-'}
                </TableCell>
                <TableCell align="center">
                    {property.min_garden_area !== property.max_garden_area ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{property.min_garden_area ? property.min_garden_area : '-'} -</Typography>
                            <Typography variant="inherit">{property.max_garden_area ? property.max_garden_area : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{property.min_garden_area ? property.min_garden_area : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {property.min_land_area !== property.max_land_area ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{property.min_land_area ? property.min_land_area : '-'} -</Typography>
                            <Typography variant="inherit">{property.max_land_area ? property.max_land_area : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{property.min_land_area ? property.min_land_area : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">{property.last_update ? format(new Date(property.last_update), 'dd-MM-yyyy') : '-'}</TableCell>
                <TableCell align="center">
                    <Button
                        LinkComponent={Link}
                        size="small"
                        href={`${WEBSITE_FRONTEND}compound/${getSelectedCompoundId()}/property/${property.property_id}`}
                        target="_blank"
                    >
                        <FormattedMessage id="view" />
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell padding="none" colSpan={propertyHeaders.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{ margin: 1 }}>
                                <DetailedPropertiesList />
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
