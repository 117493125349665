import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import NotificationTypeDetailsPage from 'views/notifications/notification';
import NotificationsPage from 'views/notifications/notifications';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router';

// Admin routing
const InvitationsPage = Loadable(lazy(() => import('views/invitations/UserInvitations')));
const EventsPage = Loadable(lazy(() => import('views/settings/Events')));
const EventDetailsPage = Loadable(lazy(() => import('views/settings/Event')));
const LeadsRequestPage = Loadable(lazy(() => import('views/leads/LeadRequests')));
const UsersPage = Loadable(lazy(() => import('views/users/Users')));
const UserPage = Loadable(lazy(() => import('views/users/User')));
const Settings = Loadable(lazy(() => import('views/settings/Groups')));
const NawyInventoryPage = Loadable(lazy(() => import('views/common/NawyInventory')));
const AccountSettings = Loadable(lazy(() => import('views/common/AccountSettings')));
const Organizations = Loadable(lazy(() => import('views/organizations/Organizations')));
const OrganizationDetails = Loadable(lazy(() => import('views/organizations/Organization')));
const AccountManagersPage = Loadable(lazy(() => import('views/users/AccountManagers')));
const OperationManagersPage = Loadable(lazy(() => import('views/users/OperationManagers')));
const TiersPage = Loadable(lazy(() => import('views/settings/Tiers')));
const TierDetailsPage = Loadable(lazy(() => import('views/settings/Tier')));
const BrokersPage = Loadable(lazy(() => import('views/users/Brokers')));
const SaleClaimsPage = Loadable(lazy(() => import('views/sale-claims/SaleClaims')));
const SaleClaimPage = Loadable(lazy(() => import('views/sale-claims/SaleClaim')));
const InventoryControlPage = Loadable(lazy(() => import('views/settings/InventoryControl')));
const RejectionReasonsPage = Loadable(lazy(() => import('views/settings/ListingsRejectionReasons')));

// ==============================|| ADMINS ROUTING ||============================== //

const AdminRoutes = {
    path: '/admin',
    element: (
        <AuthGuard>
            <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '/admin',
            element: <InvitationsPage />
        },
        {
            path: '/admin/invitations',
            element: <InvitationsPage />
        },
        {
            path: '/admin/events',
            element: <EventsPage />
        },
        {
            path: '/admin/users',
            element: <UsersPage />
        },
        {
            path: '/admin/users/:id',
            element: <UserPage />
        },
        {
            path: '/admin/leads-request',
            element: <LeadsRequestPage />
        },
        {
            path: '/admin/nawy-inventory',
            element: <NawyInventoryPage />
        },
        {
            path: '/admin/accountSettings',
            element: <AccountSettings />
        },
        {
            path: '/admin/events/:id',
            element: <EventDetailsPage />
        },
        {
            path: '/admin/settings',
            element: <Settings />
        },
        {
            path: '/admin/organizations',
            element: <Organizations />
        },
        {
            path: '/admin/sales-agents',
            element: <BrokersPage />
        },
        {
            path: '/admin/organizations/:id/:tab',
            element: <OrganizationDetails />
        },
        {
            path: '/admin/tiers',
            element: <TiersPage />
        },
        {
            path: '/admin/tiers/:id/details',
            element: <TierDetailsPage />
        },
        {
            path: '/admin/account-managers',
            element: <AccountManagersPage />
        },
        {
            path: '/admin/operation-managers',
            element: <OperationManagersPage />
        },
        {
            path: '/admin/sale-claims',
            element: <SaleClaimsPage />
        },
        {
            path: '/admin/sale-claims/:id',
            element: <SaleClaimPage />
        },
        {
            path: '/admin/pic',
            element: <InventoryControlPage />
        },
        {
            path: '/admin/rejection-reasons',
            element: <RejectionReasonsPage />
        },
        {
            path: '/admin/notification-types',
            element: <NotificationsPage />
        },
        {
            path: '/admin/notification-types/:id/details',
            element: <NotificationTypeDetailsPage />
        }
    ]
};

export default AdminRoutes;
