import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { InventoryQueryInterface } from '../inventoryTable/interfaces';
import NawyInventoryAPIs from './apis';
import { ICompoundDetailsResult, IProperty, IPropertyDetails, IPropertyTypeDetails } from './interfaces';

export const getCompoundDetails = createAsyncThunk(
    'nawyInventory/getCompoundDetails',
    async (
        requestData: {
            query: InventoryQueryInterface;
        },
        { rejectWithValue }
    ): Promise<ICompoundDetailsResult | any> => {
        const sirensConfig = JSON.parse(localStorage.getItem('sirens-config') || '{}');
        // eslint-disable-next-line
        const locale = sirensConfig.locale || 'en' ;
        const { query } = requestData;
        try {
            const successRes = await axios.get(NawyInventoryAPIs.getCompoundDetails({ ...query, language: locale }));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getPropertyTypesDetails = createAsyncThunk(
    'nawyInventory/getPropertyTypesDetails',
    async (
        requestData: { compound_id: number; query: InventoryQueryInterface },
        { rejectWithValue }
    ): Promise<IPropertyTypeDetails[] | any> => {
        const { compound_id, query } = requestData;
        const sirensConfig = JSON.parse(localStorage.getItem('sirens-config') || '{}');
        // eslint-disable-next-line
        const locale = sirensConfig.locale || 'en' ;
        try {
            const successRes = await axios.get(NawyInventoryAPIs.getPropertyTypesDetails(compound_id, { ...query, language: locale }));
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getProperties = createAsyncThunk(
    'nawyInventory/getProperties',
    async (
        requestData: { compound_id: number; property_type_id: number; query: InventoryQueryInterface },
        { rejectWithValue }
    ): Promise<IProperty[] | any> => {
        const { compound_id, property_type_id, query } = requestData;
        const sirensConfig = JSON.parse(localStorage.getItem('sirens-config') || '{}');
        // eslint-disable-next-line
        const locale = sirensConfig.locale || 'en' ;
        try {
            const successRes = await axios.get(
                NawyInventoryAPIs.getProperties(compound_id, property_type_id, { ...query, language: locale })
            );
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getPropertyDetails = createAsyncThunk(
    'nawyInventory/getPropertyDetails',
    async (
        requestData: { property_id: number; query: InventoryQueryInterface },
        { rejectWithValue }
    ): Promise<IPropertyDetails[] | any> => {
        const { property_id, query } = requestData;
        const sirensConfig = JSON.parse(localStorage.getItem('sirens-config') || '{}');
        // eslint-disable-next-line
        const locale = sirensConfig.locale || 'en' ;
        try {
            const successRes = await axios.get(NawyInventoryAPIs.getPropertyDetails(property_id, { ...query, language: locale }));
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
