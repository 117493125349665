import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';

import { Button, Card, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { gridSpacing, gridSpacingMedium } from 'store/constant';

import { DeveloperInterface } from 'store/slices/developers/interfaces';
import DeveloperTitle from './DeveloperTitle';
import DevelopersContactsDetails from './DevelopersContactsDetails';
import useMixPanel from 'hooks/useMixPanel';

const DevelopersContactsCards = ({ developers }: { developers: DeveloperInterface[] }) => {
    const intl = useIntl();
    const theme = useTheme();
    const { emitEvent } = useMixPanel();

    const [open, setOpen] = useState<number | null>(null);

    const handleClickOpen = (index: number, developerName: string) => {
        emitEvent('Click on Contact Developer', { 'Developer name': developerName });
        setOpen(index);
    };

    const handleCloseOpen = () => {
        setOpen(null);
    };

    return (
        <Grid container spacing={gridSpacingMedium}>
            {developers.map((developer, index) => (
                <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Card sx={{ boxShadow: '0px 10px 16px -3px rgba(16, 24, 40, 0.06), 0px 4px 6px -4px rgba(16, 24, 40, 0.06)' }}>
                        <Grid container padding={gridSpacing}>
                            <Grid item xs={12}>
                                <DeveloperTitle
                                    developerName={(intl.locale === 'ar' ? developer.arabicName : developer.name) || developer.name}
                                    developerLogoUrl={developer.logoUrl}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                                    <Button
                                        variant="text"
                                        endIcon={<ContactPageOutlinedIcon />}
                                        onClick={() => handleClickOpen(index, developer?.name)}
                                        sx={{ color: theme.palette.primary.main, backgroundColor: '#E8EAF6' }}
                                    >
                                        {intl.formatMessage({ id: 'view-contacts' })}
                                    </Button>
                                    <DevelopersContactsDetails
                                        location="developers contacts page"
                                        developer={developer}
                                        open={open === index}
                                        onClose={handleCloseOpen}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default DevelopersContactsCards;
