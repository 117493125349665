/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import FILTER_APIS from './apis';
import { PerfectFilterQueryInterface } from './interfaces';

export const getPropertyTypesFilter = createAsyncThunk(
    'filters/get/property-types',
    async (
        requestData: {
            query: PerfectFilterQueryInterface;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query } = requestData;
        try {
            const successRes = await axiosServices.get(FILTER_APIS.propertyTypesFilter(query));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDevelopersFilter = createAsyncThunk(
    'filters/get/developers',
    async (
        requestData: {
            query: any;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query } = requestData;
        try {
            const successRes = await axiosServices.get(FILTER_APIS.developers(query));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getCompoundsFilter = createAsyncThunk(
    'filters/get/compounds',
    async (
        requestData: {
            query: PerfectFilterQueryInterface;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query } = requestData;
        try {
            const successRes = await axiosServices.get(FILTER_APIS.compounds(query));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
