import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { Button, Divider, Grid, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import SalesBarChartDialog from './salesBarChartDialog';
import verticalBarConfig, { ChartDataInterface } from './Charts/Bar/vertical-config';
import horizontalBarConfig from './Charts/Bar/horizontal-config';
import BarChart from './Charts/Bar';

interface SalesBarChartProps {
    title: string;
    isLoading: boolean;
    mainCard: boolean;
    showAll: boolean;
    type?: 'vertical' | 'horizontal' | 'dynamic';
    data: ChartDataInterface;
    dialogData?: ChartDataInterface;
}

const SalesBarChart = ({ title, isLoading, mainCard, showAll, data, type = 'dynamic', dialogData }: SalesBarChartProps) => {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    const dynamicChartData =
        type === 'dynamic'
            ? isMediumScreen
                ? verticalBarConfig(data)
                : horizontalBarConfig(data)
            : type === 'horizontal'
            ? horizontalBarConfig(data)
            : verticalBarConfig(data);

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleShowAllClick = () => {
        setIsDialogOpen(true);
    };

    return (
        <>
            {isMediumScreen || !mainCard ? (
                <BarChart isLoading={isLoading} chartData={dynamicChartData} title={data.title} />
            ) : (
                <MainCard content={false} sx={{ width: '100%' }} title={title}>
                    <Grid container>
                        <Grid container item xs={12}>
                            <BarChart isLoading={isLoading} chartData={dynamicChartData} />
                        </Grid>
                        {showAll && (
                            <>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>

                                <Grid item margin={2} xs={12} container justifyContent="center">
                                    <Button endIcon={<FullscreenIcon color="secondary" fontSize="large" />} onClick={handleShowAllClick}>
                                        <FormattedMessage id="show-all" />
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </MainCard>
            )}
            {!!dialogData && (
                <SalesBarChartDialog
                    isOpen={isDialogOpen}
                    handleClose={() => setIsDialogOpen(false)}
                    chartData={verticalBarConfig(dialogData)}
                    isLoading={isLoading}
                    title={title}
                />
            )}
        </>
    );
};

export default SalesBarChart;
