import { Typography, Grid, Container } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import contactus from 'assets/images/contactus.png';
import { useState } from 'react';
import PagesEnum from 'components/Auth/Utils/pagesEnum';
import UserCrmFormInfo from 'components/Auth/Utils/UserCrmFormInfo';
import BecomePartnerCardV2 from 'components/Auth/Actions/BecomePartnerV2';

function BecomePartnerForm({ targetRef }: { targetRef: React.RefObject<HTMLDivElement> }) {
    const theme = useTheme();
    const [userInfo, setUserInfo] = useState<UserCrmFormInfo>({ phone: '', fullName: '', companyName: '' });
    const [currentPage, setCurrentPage] = useState(PagesEnum.LOGIN);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div ref={targetRef}>
            {isSmallScreen ? (
                <Grid container justifyContent="center">
                    <Box
                        sx={{
                            backgroundSize: 'cover',
                            display: 'flex',
                            width: '100%',
                            height: '800px',
                            flexShrink: '0',
                            background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${contactus}) lightgray 50% / cover no-repeat`
                        }}
                    >
                        <Grid container alignContent="flex-top" justifyContent="center" marginTop={6}>
                            <Grid item>
                                <Typography
                                    variant="h2"
                                    fontSize="24px"
                                    fontWeight={700}
                                    color="white"
                                    align="left"
                                    marginBottom={6}
                                    marginLeft={4}
                                >
                                    <FormattedMessage id="contact-us" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginLeft={4} marginRight={4}>
                                    <FormattedMessage id="we-help-you-find-the-perfect-property-for-your-needs" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <span
                        style={{
                            position: 'absolute',
                            color: 'white',
                            zIndex: 10,
                            transform: 'translate(-0%, 40%)'
                        }}
                    >
                        <Grid
                            container
                            height="600px"
                            maxWidth="363px"
                            borderRadius="16px"
                            style={{
                                backgroundImage: `linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                boxShadow: `0px 8px 10px -6px rgba(16, 24, 40, 0.06),0px 20px 25px -5px rgba(16, 24, 40, 0.06)`,
                                margin: '0 auto',
                                width: `calc(100% - 30px)`,
                                paddingInline: '20px'
                            }}
                        >
                            <BecomePartnerCardV2
                                key={PagesEnum.BECOME_PARTNER}
                                setUserInfo={setUserInfo}
                                setCurrentPage={setCurrentPage}
                                mixPanelEventName="Submit form landing page"
                            />
                        </Grid>
                    </span>
                </Grid>
            ) : (
                <Container
                    maxWidth={false}
                    sx={{
                        maxWidth: '1100px'
                    }}
                >
                    <Grid
                        container
                        gridColumn={2}
                        columnSpacing={20}
                        sx={{
                            backgroundColor: 'white',
                            minHeight: '660px'
                        }}
                    >
                        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                            <Box
                                sx={{
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    maxHeight: '550px',
                                    borderRadius: '16px',
                                    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), url(${contactus}) lightgray 50% / cover no-repeat`
                                }}
                            >
                                <Grid container spacing={2} justifyContent="center" marginLeft={12} marginRight={12}>
                                    <Grid container alignContent="flex-end">
                                        <Grid item paddingBottom={2}>
                                            <Typography
                                                variant="h2"
                                                fontSize={isSmallScreen ? '16px' : '24px'}
                                                fontWeight={700}
                                                color="white"
                                                align="left"
                                            >
                                                <FormattedMessage id="contact-us" />
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid container>
                                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" align="left">
                                            <FormattedMessage id="we-help-you-find-the-perfect-property-for-your-needs" />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                            <BecomePartnerCardV2
                                key={PagesEnum.BECOME_PARTNER}
                                setUserInfo={setUserInfo}
                                setCurrentPage={setCurrentPage}
                                mixPanelEventName="Submit form landing page"
                            />
                        </Grid>
                    </Grid>
                </Container>
            )}
        </div>
    );
}
export default BecomePartnerForm;
