import { TableCell, TableSortLabel } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { HeadProps } from '../NawyTable/Headers';
import { OrderByDirection } from 'store/interfaces';
import { Box } from '@mui/system';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { CommonHandleFilterType } from 'ui-component/Filters/types';

export interface SortOptionsInterface {
    by: string;
    direction: OrderByDirection;
}

const HeaderWithSort = ({
    header,
    sort,
    handleFilter
}: {
    header: HeadProps;
    sort: SortOptionsInterface;
    handleFilter: CommonHandleFilterType;
}) => {
    const intl = useIntl();
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        const isAsc = sort.by === property && sort.direction === OrderByDirection.asc;
        handleFilter({ orderBy: property, orderByDirection: isAsc ? OrderByDirection.desc : OrderByDirection.asc });
    };
    return (
        <TableCell
            key={header.id}
            sx={{ minWidth: header.minWidth || '150px' }}
            align={header.align}
            padding={header.disablePadding ? 'none' : 'normal'}
            sortDirection={sort.by === header.id ? sort.direction : false}
        >
            <TableSortLabel
                active={sort.by === header.id}
                direction={sort.by === header.id ? sort.direction : 'asc'}
                onClick={createSortHandler(header.id)}
            >
                <FormattedMessage id={header.label} />
                {sort.by === header.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {sort.direction === 'desc'
                            ? intl.formatMessage({ id: 'sorted-descending' })
                            : intl.formatMessage({ id: 'sorted-ascending' })}
                    </Box>
                ) : null}
            </TableSortLabel>
        </TableCell>
    );
};
export default HeaderWithSort;
