import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { List, ListItem, ListItemButton, Paper, useTheme } from '@mui/material';

export type MentionSuggestion = {
    id: string;
    label: string;
};

type SuggestionListProps = {
    items: MentionSuggestion[];
    command: (suggestion: MentionSuggestion) => void;
};

export interface SuggestionListRef {
    onKeyDown: (props: { event: KeyboardEvent }) => boolean;
}

const SuggestionList = forwardRef<SuggestionListRef, SuggestionListProps>((props, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const theme = useTheme();
    const selectItem = (index: number) => {
        const item = props.items[index];
        if (item) {
            console.log(item);
            props.command(item);
        }
    };

    const upHandler = () => {
        setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
    };

    const downHandler = () => {
        setSelectedIndex((selectedIndex + 1) % props.items.length);
    };

    const enterHandler = () => {
        selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [props.items]);

    useImperativeHandle(ref, () => ({
        onKeyDown: ({ event }) => {
            if (event.key === 'ArrowUp') {
                upHandler();
                return true;
            }
            if (event.key === 'ArrowDown') {
                downHandler();
                return true;
            }
            if (event.key === 'Enter') {
                enterHandler();
                return true;
            }
            return false;
        }
    }));

    if (props.items.length === 0) {
        return null;
    }

    return (
        <Paper elevation={3} sx={{ maxHeight: '200px', overflow: 'auto', minWidth: '150px', zIndex: theme.zIndex.drawer }}>
            <List dense>
                {props.items.map((item: MentionSuggestion, index: number) => (
                    <ListItem key={item.id} disablePadding>
                        <ListItemButton selected={index === selectedIndex} onClick={() => selectItem(index)} sx={{ py: 1 }}>
                            {item.label}
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
});

SuggestionList.displayName = 'SuggestionList';

export default SuggestionList;
