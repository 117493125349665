// types
import { createSlice } from '@reduxjs/toolkit';
import {
    createAccountManagersTeam,
    deleteAccountManagersTeam,
    getAccountManagerTeamById,
    queryAccountManagersTeams,
    updateAccountManagersTeam
} from './actions';
import { AccountManagersTeamsInitialState } from './interfaces';

// initial state
const initialState: AccountManagersTeamsInitialState = {
    loading: false,
    error: false,
    refresh: false,
    teams: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - ACCOUNT MANAGERS Teams ||============================== //

const accountManagersTeams = createSlice({
    name: 'accountManagersTeams',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryAccountManagersTeams.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryAccountManagersTeams.fulfilled, (state, action) => {
                state.loading = false;

                state.teams = action.payload;
            })
            .addCase(queryAccountManagersTeams.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getAccountManagerTeamById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagerTeamById.fulfilled, (state, action) => {
                state.loading = false;
                state.team = action.payload;
            })
            .addCase(getAccountManagerTeamById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createAccountManagersTeam.pending, (state) => {
                state.error = false;
            })
            .addCase(createAccountManagersTeam.fulfilled, (state, action) => {
                state.team = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(createAccountManagersTeam.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateAccountManagersTeam.pending, (state) => {
                state.error = false;
            })
            .addCase(updateAccountManagersTeam.fulfilled, (state, action) => {
                state.team = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(updateAccountManagersTeam.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteAccountManagersTeam.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteAccountManagersTeam.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteAccountManagersTeam.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default accountManagersTeams.reducer;
