import EditIcon from '@mui/icons-material/Edit';
import { Button, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from 'store';
import { queryCRMs } from 'store/slices/crmControl/actions';
import { moveSalesAgent } from 'store/slices/salesAgents/actions';
import { SalesAgentsInterface } from 'store/slices/salesAgents/interfaces';
import { openSnackbar } from 'store/slices/snackbar';

interface MoveSalesAgentCellProps {
    salesAgent: SalesAgentsInterface;
}

// DONE: Actions cell does not indicate what this component is used for
const MoveSalesAgentCell = ({ salesAgent }: MoveSalesAgentCellProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
    const [moveToCRM, setMoveToCRM] = useState(false);

    const { crms, refresh } = useSelector((state) => state.crmControl);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'agent-move-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    useEffect(() => {
        dispatch(queryCRMs({ query: {} }));
    }, [dispatch, refresh]);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchor(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setMenuAnchor(null);
        setMoveToCRM(false);
    };

    const handleSelectCRM = (crmId: number) => {
        dispatch(
            moveSalesAgent({
                data: {
                    crmId,
                    salesAgentId: salesAgent.cognitoId
                },
                runSuccess
            })
        );
        handleCloseMenu();
    };

    return (
        <>
            <Button color="inherit" onClick={handleOpenMenu} startIcon={<EditIcon />}>
                <FormattedMessage id="edit" />
            </Button>
            <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleCloseMenu}>
                {moveToCRM ? (
                    <>
                        {crms.data.map((crm) => (
                            <MenuItem key={crm.id} onClick={() => handleSelectCRM(crm.id)}>
                                {crm.name}
                            </MenuItem>
                        ))}
                        <MenuItem onClick={() => {}}>{intl.formatMessage({ id: 'cancel' })}</MenuItem>
                    </>
                ) : (
                    <MenuItem onClick={() => setMoveToCRM(true)}>{intl.formatMessage({ id: 'move-to-crm' })}</MenuItem>
                )}
            </Menu>
        </>
    );
};

export default MoveSalesAgentCell;
