import { MIDAS_API, formattedQuery } from 'store/apis';
import { QueryAccountManagerTarget, QueryAccountManagersInterface } from './interfaces';

const ACCOUNT_MANAGERS_APIS = {
    /**
     * @POST
     * @returns account manager
     */
    setAccountManagerTarget: () => `${MIDAS_API}account-manager-targets/upsert-bulk-account-manager-target`,

    /**
     * @GET
     * @description get AM targets
     * @returns targets
     */
    getAccountManagerTarget: (query: QueryAccountManagerTarget) => {
        const formatQuery: string[] = [MIDAS_API, 'account-manager-targets/get-account-manager-targets', '?'];
        return formattedQuery(formatQuery, query);
    },
    /**
     * @GET
     * @description get AM history targets
     * @returns targets
     */
    getAccountManagerTargets: (query: QueryAccountManagerTarget) => {
        const formatQuery: string[] = [MIDAS_API, 'account-manager-targets/query-account-manager-targets', '?'];
        return formattedQuery(formatQuery, query);
    },

    getAccountManagers: (query: QueryAccountManagersInterface) => {
        const formatQuery: string[] = [MIDAS_API, 'account-manager/get-account-manager', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ACCOUNT_MANAGERS_APIS;
