// types
import { createSlice } from '@reduxjs/toolkit';

import {
    createActivityCategory,
    deleteActivityCategory,
    getActivityCategoryById,
    queryActivityCategories,
    updateActivityCategory
} from './actions';
import { ActivityCategoryInitialState } from './interfaces';

const initialState: ActivityCategoryInitialState = {
    loading: false,
    error: false,
    refresh: false,
    categories: []
};

// ==============================|| SLICE - ACTIVITY CATEGORIES ||============================== //
const activityCategory = createSlice({
    name: 'activityCategory',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryActivityCategories.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryActivityCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload;
            })
            .addCase(queryActivityCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getActivityCategoryById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getActivityCategoryById.fulfilled, (state, action) => {
                state.loading = false;
                state.category = action.payload;
            })
            .addCase(getActivityCategoryById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createActivityCategory.pending, (state) => {
                state.error = false;
            })
            .addCase(createActivityCategory.fulfilled, (state, action) => {
                state.category = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(createActivityCategory.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateActivityCategory.pending, (state) => {
                state.error = false;
            })
            .addCase(updateActivityCategory.fulfilled, (state, action) => {
                state.category = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(updateActivityCategory.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteActivityCategory.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteActivityCategory.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteActivityCategory.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default activityCategory.reducer;
