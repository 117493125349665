import { FAUNA_API } from 'store/apis';

const INVITATIONS_LIMIT_COLLECTION = 'invitations-limits';

const INVITATION_LIMITS = {
    /**
     * @Post
     * @Body CreateTierInvitationLimitInterface
     * @return TierInvitationLimitsInterface
     */
    create: () => `${FAUNA_API}${INVITATIONS_LIMIT_COLLECTION}`,
    /**
     * @Get
     * query invitation Limits
     * @param query
     * @returns InvitationLimitsListInterface
     */
    query: () => `${FAUNA_API}${INVITATIONS_LIMIT_COLLECTION}`,
    /**
     * @Patch
     * @Body UpdateTierInvitationLimitInterface
     * @returns TierInvitationLimitsInterface
     */
    update: (id: number) => `${FAUNA_API}${INVITATIONS_LIMIT_COLLECTION}/${id}`,
    /**
     * @Delete
     * @param id
     * @returns
     */
    delete: (id: number) => `${FAUNA_API}${INVITATIONS_LIMIT_COLLECTION}/${id}`
};
export default INVITATION_LIMITS;
