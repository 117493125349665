// material-ui
import { Avatar, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
import LogoSection from '../LogoSection';
import LocalizationSection from './LocalizationSection';
import ProfileSection from './ProfileSection';

// assets
import { IconMenu2 } from '@tabler/icons';
import { gridSpacing } from 'store/constant';
import NotificationSection from './NotificationSection';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);

    return (
        <Grid container alignItems="center" spacing={gridSpacing}>
            <Grid item>
                <Box
                    sx={{
                        width: 228,
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <LogoSection />
                    </Box>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.main,
                                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Box>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />

            <Grid item>
                <NotificationSection />
            </Grid>
            <Grid item>
                <LocalizationSection />
            </Grid>
            {/* <ThemeSection /> */}
            <Grid item>
                <ProfileSection />
            </Grid>
        </Grid>
    );
};

export default Header;
