import { useRef } from 'react';
import BecomePartnerForm from './content/BecomePartnerForm';
import FAQSection from './content/FAQ';
import FeaturesSection from './content/Features';
import GrowSection from './content/Grow';
import NetworkSection from './content/Network';
import Footer from './footer';
import LandingHeader from './header';

function LandingPage() {
    const targetRef = useRef<HTMLDivElement>(null);

    const scrollToTarget = () => {
        if (targetRef.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <LandingHeader />
            <GrowSection scrollToTarget={scrollToTarget} />
            <FeaturesSection scrollToTarget={scrollToTarget} />
            <NetworkSection />
            <FAQSection />
            <BecomePartnerForm targetRef={targetRef} />
            <Footer />
        </div>
    );
}

export default LandingPage;
