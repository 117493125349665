import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import useId from '@mui/utils/useId';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { queryTiers } from 'store/slices/tiers/actions';
import { TierInterface } from 'store/slices/tiers/interfaces';

export interface MultiTierFilterProps {
    tierIds: string[];
    props?: {
        textFieldProps?: TextFieldProps;
    };
    onSelectedTiersChange?: (value: TierInterface[]) => void;
}

const MultiTierFilter = ({ tierIds = [], props = {}, onSelectedTiersChange }: MultiTierFilterProps) => {
    const id = useId();
    const intl = useIntl();

    const [tiers, setTiers] = useState<TierInterface[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [newTierValues, setNewTierValues] = useState<TierInterface[]>([]);

    const debouncedSearchTerm = useDebouncedValue(searchTerm);

    const handleOnchange = (selectedTiers: TierInterface[]) => {
        setNewTierValues(selectedTiers);
        onSelectedTiersChange?.(selectedTiers);
    };

    useEffect(() => {
        dispatch(queryTiers({ queryData: { pageSize: 100 } })).then((res) => {
            setTiers(res.payload.data);
            setNewTierValues((res.payload.data as TierInterface[]).filter((tier) => tierIds.includes(tier.id)));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (searchTerm) {
            dispatch(queryTiers({ queryData: { name: searchTerm } }));
        }
    }, [debouncedSearchTerm, searchTerm]);

    return (
        <Autocomplete
            multiple
            id={`tier-${id}`}
            options={tiers}
            getOptionLabel={(tier) => tier!.name}
            onChange={(_, value) => handleOnchange(value)}
            onInputChange={(_, value) => setSearchTerm(value)}
            value={newTierValues}
            renderInput={(params) => (
                <TextField
                    label={intl.formatMessage({ id: 'tiers' })}
                    placeholder={`${intl.formatMessage({ id: 'tiers' })}*`}
                    {...params}
                    {...props?.textFieldProps}
                />
            )}
        />
    );
};

export default MultiTierFilter;
