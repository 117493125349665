import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Grid, IconButton, IconButtonTypeMap, Skeleton, Tooltip, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import Chip from './extended/Chip';

interface InfoItemProps {
    label: string;
    value?: ReactNode | string | number | Date | undefined | null;
    chip?: {
        value: string;
        color?: string;
        variant?: 'outlined' | 'filled';
    };
    loading?: boolean;
    copyable?: boolean;
    info?: string;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    color?: IconButtonTypeMap['props']['color'];
    trigger?: React.ReactNode;
    open?: boolean;
    renderElement?: JSX.Element | null;
}

const InfoItem = ({
    label,
    startIcon,
    endIcon,
    value,
    chip,
    loading,
    copyable,
    info,
    color = 'inherit',
    trigger,
    open = false,
    renderElement
}: InfoItemProps) => {
    const intl = useIntl();
    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${value}`);
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'copy-message' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };
    return (
        <Grid container>
            <Grid item xs={12} container spacing={1}>
                {!!startIcon && <Grid item>{startIcon}</Grid>}
                <Grid item gap={1}>
                    <Typography variant="caption" textTransform="capitalize">
                        {label}
                    </Typography>
                    {trigger}
                </Grid>
                {!!info && (
                    <Grid item>
                        <Tooltip title={info}>
                            <IconButton sx={{ height: '12px', width: '12px' }} color={color}>
                                <QuestionMarkIcon sx={{ fontSize: '12px' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                )}
            </Grid>
            {loading && (
                <Grid item xs={12}>
                    <Skeleton sx={{ borderRadius: 1, maxWidth: 150 }} variant="rectangular" width="100%" height={18} />
                </Grid>
            )}

            {!!value && !loading && (
                <Grid item xs={12} container spacing={1} alignItems="center" justifyContent="space-between">
                    {copyable && (
                        <Grid item>
                            <Tooltip title={`${intl.formatMessage({ id: 'copy' })} ${intl.formatMessage({ id: 'value' })}`}>
                                <IconButton onClick={handleCopyToClipboard} sx={{ height: '16px', width: '16px' }} color="inherit">
                                    <ContentCopyTwoToneIcon sx={{ fontSize: '16px' }} />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    )}
                    <Grid item>
                        {open && renderElement ? (
                            <Grid item xs={12}>
                                {renderElement}
                            </Grid>
                        ) : (
                            <Typography variant="body1" textTransform="capitalize">
                                {value || intl.formatMessage({ id: 'not-available' })}
                            </Typography>
                        )}
                    </Grid>
                    {!!endIcon && <Grid item>{endIcon}</Grid>}
                </Grid>
            )}
            {!!chip && !loading && (
                <Grid item xs={12}>
                    <Chip size="small" variant={chip.variant || 'filled'} chipcolor={chip.color} label={chip.value} />
                </Grid>
            )}
        </Grid>
    );
};

export default InfoItem;
