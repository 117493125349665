// assets
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GroupsIcon from '@mui/icons-material/Groups';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { NavItemType } from 'types';

const inviteUserItem: NavItemType = {
    id: 'invitations',
    title: (
        <>
            <FormattedMessage id="invite" /> <FormattedMessage id="users" />
        </>
    ),
    type: 'item',
    url: '/invitations',
    icon: GroupsIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Invite User'
    }
};

const invitePartner: NavItemType = {
    id: 'partners',
    title: (
        <>
            <FormattedMessage id="invite" /> <FormattedMessage id="partners" />
        </>
    ),
    type: 'item',
    url: '/invitations/partners',
    icon: CorporateFareIcon,
    breadcrumbs: false,
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.brokers, organizationFeature: 'invitationsLimit' },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.internalAgents }
    ],
    mixpanelEvent: {
        tab_name: 'Invite Partners'
    }
};

const invitationItemsGroup: NavItemType = {
    id: 'admin-side-menu-items-invitations',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }],
    children: [invitePartner]
};

const invitePartnerFreelancer: NavItemType = {
    id: 'partners',
    title: (
        <>
            <FormattedMessage id="invite" /> <FormattedMessage id="partners" />
        </>
    ),
    type: 'item',
    url: '/invitations/partners',
    icon: CorporateFareIcon,
    breadcrumbs: false,
    allowedAudience: [
        {
            name: InvitationTypeEnum.brokers,
            organizationFeature: 'saleClaimsLimit',
            roles: [OrganizationRole.owner, OrganizationRole.employee],
            tiers: ['96af4348-4dcb-4d98-b8a6-33bac4cd831b']
        },
        { name: InvitationTypeEnum.internalAgents }
    ],
    mixpanelEvent: {
        tab_name: 'Invite Partners'
    }
};

const invitationItemsFreelancerGroup: NavItemType = {
    id: 'admin-side-menu-items-invitations',
    type: 'group',
    children: [invitePartnerFreelancer]
};

const invitationItemsCollapse: NavItemType = {
    id: 'invitations-collapse-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'invitations-collapse',
            type: 'collapse',
            title: <FormattedMessage id="invitations" />,
            icon: MarkAsUnreadTwoToneIcon,
            breadcrumbs: false,
            children: [inviteUserItem, invitePartner]
        }
    ]
};
export { invitationItemsCollapse, invitationItemsFreelancerGroup };
export default invitationItemsGroup;
