import useAuth from 'hooks/useAuth';
import mixpanel from 'mixpanel-browser';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { deviceDetect, mobileModel } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { Moengage } from './moengage/moengage';

declare let fbq: (...args: any[]) => void;

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

enum Platforms {
    CRM = 'CRM',
    Partners = 'Partners'
}

export type EventType = 'Mixpanel' | 'Pixel' | 'Moengage' | 'Mixpanel and Moengage' | 'Pixel and Moengage' | 'Pixel and Mixpanel';

export interface EmitEventParams {
    eventType?: EventType;
    [key: string]: unknown;
}

interface initialStateInterface {
    userId?: string;
    crmId?: string;
    partnerOrganizationName?: string;
    partnerTier?: string;
    Username?: string;
    UserPhoneNumber?: string;
    UserEmail?: string;
    UserGroup?: string;
    Role?: string;
    platform: Platforms;
    emitEvent: (eventName: string, eventParams?: EmitEventParams) => void;
    handleSetUser: (user: CRMUserInterface) => void;
}

// initial state
const initialState: initialStateInterface = {
    userId: '',
    partnerOrganizationName: '',
    partnerTier: '',
    Username: '',
    UserPhoneNumber: '',
    UserEmail: '',
    UserGroup: '',
    crmId: '',
    Role: '',
    platform: Platforms.Partners,
    emitEvent(eventName: string, eventParams?: {}): void {
        throw new Error('Function not implemented.');
    },
    handleSetUser(user: CRMUserInterface): void {
        throw new Error('Function not implemented.');
    }
};

interface CRMUserInterface {
    'cognito:username': string;
    name: string;
    email: string;
    crmId: string;
}
// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const MixPanelContext = createContext(initialState);

type MixPanelProviderProps = {
    children: ReactNode;
};

function MixPanelProvider({ children }: MixPanelProviderProps) {
    const { user } = useAuth();
    const { locale } = useIntl();

    const [config, setConfig] = useState({
        userId: user?.cognitoId || '',
        partnerOrganizationName: user?.brokerAuth?.organization?.name || 'Internal Nawy',
        partnerTier: user?.brokerAuth?.organization?.tier?.name || 'Nawy',
        Username: user?.name || '',
        UserPhoneNumber: user?.phone || '',
        UserEmail: user?.email || '',
        UserGroup: user?.groups?.join() || '',
        Role: user?.brokerAuth?.role || user?.accountManagerRole || '',
        platform: Platforms.Partners,
        'Language Used': locale,
        'Page Name': window.location.pathname.split('/')[1],
        'Page Name Sub': window.location.pathname.split('/')[2],
        'Organization Id': user?.brokerAuth?.organization.id || 'Nawy',
        'User Type': user?.groups?.includes(InvitationTypeEnum.admins)
            ? InvitationTypeEnum.admins
            : user?.groups?.includes(InvitationTypeEnum.brokers)
            ? 'Partners'
            : user?.groups?.includes(InvitationTypeEnum.nawyInventory) && user.groups.length === 1
            ? 'View Inventory'
            : 'Internal User',
        crmId: ''
    });

    const configureMixpanel = (userId?: string) => {
        mixpanel.init(`${MIXPANEL_TOKEN}`, {
            debug: process.env.NODE_ENV !== 'production',
            track_pageview: true,
            persistence: 'localStorage'
        });
        if (userId) mixpanel.identify(userId);
        if (userId) Moengage.identify(userId || '');
        mixpanel.track_pageview({
            ...config,
            ...deviceDetect(window?.navigator?.userAgent),
            model: mobileModel
        });

        mixpanel.register({
            ...config,
            ...deviceDetect(window?.navigator?.userAgent),
            model: mobileModel
        });

        Moengage.registerUser(
            user?.name || '',
            user?.phone || '',
            user?.brokerAuth?.organization?.tier?.name || 'Nawy',
            user?.brokerAuth?.role || user?.accountManagerRole || '',
            user?.groups?.join() || '',
            user?.brokerAuth?.organization?.name || 'Nawy'
        );
    };

    const emitEvent = (eventName: string, eventParams?: {}, eventType?: EventType) => {
        try {
            if (eventType === 'Mixpanel') {
                mixpanel?.track(eventName, {
                    ...eventParams,
                    ...config,
                    ...deviceDetect(window?.navigator?.userAgent),
                    model: mobileModel
                });
            } else if (eventType === 'Moengage') {
                Moengage.track({ name: eventName, params: { ...eventParams } });
            } else if (eventType === 'Pixel') {
                fbq('track', eventName, { ...eventParams });
            } else if (eventType === 'Mixpanel and Moengage') {
                mixpanel?.track(eventName, {
                    ...eventParams,
                    ...config,
                    ...deviceDetect(window?.navigator?.userAgent),
                    model: mobileModel
                });
                Moengage.track({ name: eventName, params: { ...eventParams } });
            } else if (eventType === 'Pixel and Moengage') {
                fbq('track', eventName, { ...eventParams });
                Moengage.track({ name: eventName, params: { ...eventParams } });
            } else if (eventType === 'Pixel and Mixpanel') {
                fbq('track', eventName, { ...eventParams });
                mixpanel?.track(eventName, {
                    ...eventParams,
                    ...config,
                    ...deviceDetect(window?.navigator?.userAgent),
                    model: mobileModel
                });
            } else {
                mixpanel?.track(eventName, {
                    ...eventParams,
                    ...config,
                    ...deviceDetect(window?.navigator?.userAgent),
                    model: mobileModel
                });
                Moengage.track({ name: eventName, params: { ...eventParams } });
                // fbq('track', eventName, { ...eventParams });
            }
        } catch (e) {
            // TODO Handle when tracking fails
        }
    };

    useEffect(() => {
        configureMixpanel(user?.cognitoId);
        if (user) {
            setConfig({
                userId: user?.cognitoId || '',
                partnerOrganizationName: user?.brokerAuth?.organization?.name || '',
                partnerTier: user?.brokerAuth?.organization?.tier?.name || '',
                Username: user?.name || '',
                UserPhoneNumber: user?.phone || '',
                UserEmail: user?.email || '',
                UserGroup: user?.groups?.join() || '',
                Role: user?.brokerAuth?.role || user?.accountManagerRole || '',
                platform: Platforms.Partners,
                'Organization Id': user?.brokerAuth?.organization.id || 'Nawy',
                'Language Used': locale,
                'Page Name': window.location.pathname.split('/')[1],
                'Page Name Sub': window.location.pathname.split('/')[2],
                'User Type': user?.groups?.includes(InvitationTypeEnum.admins)
                    ? InvitationTypeEnum.admins
                    : user?.groups?.includes(InvitationTypeEnum.brokers)
                    ? 'Partners'
                    : user?.groups?.includes(InvitationTypeEnum.nawyInventory) && user.groups.length === 1
                    ? 'View Inventory'
                    : 'Internal User',
                crmId: ''
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locale, user]);

    const handleSetUser = (crmUser: CRMUserInterface) => {
        setConfig({
            ...config,
            userId: crmUser?.['cognito:username'] || '',
            crmId: crmUser?.crmId || '',
            Username: crmUser?.name || '',
            UserEmail: crmUser?.email || '',
            platform: Platforms.CRM
        });
    };

    return (
        <MixPanelContext.Provider
            value={{
                ...config,
                handleSetUser,
                emitEvent
            }}
        >
            {children}
        </MixPanelContext.Provider>
    );
}

export { MixPanelContext, MixPanelProvider };
