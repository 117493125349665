const selectableAreas = [
    { id: 1, name: 'New Cairo' },
    { id: 2, name: '6th October' },
    { id: 3, name: 'Mostakbal City' },
    { id: 4, name: 'Ain Al Sokhna' },
    { id: 5, name: 'North Coast' },
    { id: 5, name: 'New Capital' }
];

export default selectableAreas;
