// filtersSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderByDirection } from 'store/interfaces';
import { FlowStagesEnum } from '../flows/interfaces';
import { CilsInterface } from './interfaces';

const initialState: CilsInterface = {
    primaryCils: {
        organizationId: undefined,
        brokerId: undefined,
        accountManagerId: '',
        reviewerId: '',
        page: undefined,
        pageSize: undefined,
        saleType: undefined,
        commissionType: undefined,
        unitId: '',
        buyerName: '',
        deleted: undefined,
        buyerPhone: '',
        createdAtMax: '',
        createdAtMin: '',
        updatedAtMax: '',
        updatedAtMin: '',
        unitValueMin: undefined,
        unitValueMax: undefined,
        stage: FlowStagesEnum.PrimaryCil,
        commissionPercentageMin: undefined,
        commissionPercentageMax: undefined,
        orderBy: 'id',
        orderByDirection: OrderByDirection.desc,
        downPaymentMin: undefined,
        downPaymentMax: undefined,
        contractionDateMin: undefined,
        contractionDateMax: undefined,
        installmentsYearsMin: undefined,
        installmentsYearsMax: undefined,
        leadName: '',
        leadPhone: '',
        status: undefined
    },
    financingCils: {
        status: undefined,
        phone: '',
        leadId: '',
        brokerName: '',
        brokerId: '',
        id: '',
        orderBy: 'id',
        orderByDirection: OrderByDirection.desc,
        accountManagerId: '',
        createdById: '',
        organizationId: undefined,
        createdAtMax: '',
        createdAtMin: ''
    },
    activeTab: 0
};
const cilsFilter = createSlice({
    name: 'cilsFilter',
    initialState,
    reducers: {
        setPrimaryCilsFilters(state, action: PayloadAction<any>) {
            state.primaryCils = { ...action.payload };
        },
        setFinancingCilsFilters(state, action: PayloadAction<any>) {
            state.financingCils = { ...action.payload };
        },
        setActiveTab(state, action: PayloadAction<number>) {
            state.activeTab = action.payload;
        },
        clearPrimaryCilsFilters(state) {
            state.primaryCils = initialState.primaryCils;
        },
        clearFinancingCilsFilters(state) {
            state.financingCils = initialState.financingCils;
        }
    }
});

export const { setPrimaryCilsFilters, setFinancingCilsFilters, setActiveTab, clearFinancingCilsFilters, clearPrimaryCilsFilters } =
    cilsFilter.actions;
export default cilsFilter.reducer;
