import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { getDevelopersFilter } from 'store/slices/filters/actions';
import { PerfectFilterValueInterface } from 'store/slices/filters/interfaces';

interface DeveloperFilterV2Props {
    defaultValueId?: number | '';
    value?: number | '';
    location: string;
    excludeContactLess?: boolean;
    excludePCILless?: boolean;
    handleSearch: (newValue?: number) => void;
    handleSelected?: (propertyType?: PerfectFilterValueInterface | null | undefined) => void;
    clear?: boolean;
}

const DeveloperFilterV2 = ({
    defaultValueId,
    location,
    value,
    excludeContactLess,
    excludePCILless,
    handleSearch,
    handleSelected,
    clear = false
}: DeveloperFilterV2Props) => (
    <Filter
        location={location}
        defaultValueId={defaultValueId}
        value={value}
        excludeContactLess={excludeContactLess}
        excludePCILless={excludePCILless}
        handleSearch={handleSearch}
        handleSelected={handleSelected}
        clear={clear}
    />
);

const Filter = ({ defaultValueId, value, location, excludeContactLess, handleSearch, handleSelected, clear }: DeveloperFilterV2Props) => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const { developers, developersLoading } = useSelector((state) => state.filters);
    const [searchInput, setSearchInput] = useState<string>('');
    const [selectedDeveloper, setSelectedDeveloper] = useState<PerfectFilterValueInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(getDevelopersFilter({ query: { name, excludeNullContacts: excludeContactLess } }));
        setSearchInput(name);
    };

    const handleOnChange = (propertyType: PerfectFilterValueInterface | null | undefined) => {
        setSelectedDeveloper(propertyType);
        handleSearch(propertyType?.id);
        if (handleSelected) handleSelected(propertyType);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(getDevelopersFilter({ query: { id: defaultValueId, excludeNullContacts: excludeContactLess } }));
        } else {
            dispatch(getDevelopersFilter({ query: { name: searchInput, excludeNullContacts: excludeContactLess } }));
        }
    }, [defaultValueId, excludeContactLess, searchInput]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && developers.count > 0) {
            firstRender.current = false;
            const developer = developers.data.find((c) => c.id === defaultValueId) ?? null;
            setSelectedDeveloper(developer);
            if (handleSelected) handleSelected(developer);
            emitEvent('Filter by developer', { location, 'Developer name': developer?.name });
        }
    }, [developers, defaultValueId, handleSelected, emitEvent, location]);

    useEffect(() => {
        if (clear) {
            setSearchInput('');
            setSelectedDeveloper(null);
        }
    }, [clear]);

    const handleFilterClick = () => {
        emitEvent('Click developer filter', { location });
    };

    return (
        <Autocomplete
            id="autocomplete-generic-filter-developers"
            fullWidth
            size="small"
            onOpen={handleFilterClick}
            options={developers.data}
            loading={developersLoading}
            value={selectedDeveloper}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={searchInput}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            filterOptions={(option) => option}
            isOptionEqualToValue={(option) => option?.id.toString() === value?.toString()}
            getOptionLabel={(option) => `${(intl.locale === 'ar' ? option.arabicName : option.name) || option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {(intl.locale === 'ar' ? option.arabicName : option.name) || option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-property-type"
                    label={intl.formatMessage({ id: 'developers' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default DeveloperFilterV2;
