import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import TIER_INVITATION_LIMITS from '../invitationsLimits/apis';
import { CreateInvitationLimitInterface, UpdateInvitationLimitInterface } from '../invitationsLimits/interfaces';
import { DocumentTypes } from '../organizations/interfaces';
import TIERS_API from './apis';
import { CreateTierInterface, QueryTiersInterface, TierInterface, TiersListInterface, UpdateTierInterface } from './interfaces';

export const queryTiers = createAsyncThunk(
    'tiers/query',
    async (
        requestData: {
            queryData: QueryTiersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TiersListInterface | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(TIERS_API.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getTierById = createAsyncThunk(
    'tiers/get-by-id',
    async (
        requestData: {
            tierId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(TIERS_API.getById(tierId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getBrokerTier = createAsyncThunk(
    'tiers/get-for-broker',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { runSuccess } = requestData;
        try {
            const successRes = await axios.get(TIERS_API.getBrokerTier());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createTier = createAsyncThunk(
    'tiers/create',
    async (
        requestData: {
            tierData: CreateTierInterface;
            runSuccess?: (tierId: string) => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(TIERS_API.create(), tierData);
            if (runSuccess) runSuccess(successRes.data.id);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateTier = createAsyncThunk(
    'tiers/update',
    async (
        requestData: {
            tierId: string;
            updateTierData: UpdateTierInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierId, updateTierData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(TIERS_API.update(tierId), updateTierData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addTierRequirement = createAsyncThunk(
    'tiers/add-requirement',
    async (
        requestData: {
            tierId: string;
            requirementData: {
                documentType: DocumentTypes;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierId, requirementData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(TIERS_API.addRequirement(tierId), requirementData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const removeTierRequirement = createAsyncThunk(
    'tiers/remove-requirement',
    async (
        requestData: {
            tierId: string;
            requirementId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierId, requirementId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(TIERS_API.removeRequirement(tierId, requirementId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteTier = createAsyncThunk(
    'tiers/delete-tier',
    async (
        requestData: {
            tierId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { tierId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(TIERS_API.delete(tierId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createTierInvitationLimits = createAsyncThunk(
    'tiers/invitation-limits/create',
    async (
        requestData: {
            createData: CreateInvitationLimitInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(TIER_INVITATION_LIMITS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateTierInvitationLimits = createAsyncThunk(
    'tiers/invitation-limits/update',
    async (
        requestData: {
            id: number;
            updateData: UpdateInvitationLimitInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const { data } = await axios.patch(TIER_INVITATION_LIMITS.update(id), updateData);
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteTierInvitationLimits = createAsyncThunk(
    'tiers/invitation-limits/delete',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<TierInterface | any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.delete(TIER_INVITATION_LIMITS.delete(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
