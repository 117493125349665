import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { InvitationTypeEnum } from 'store/interfaces';

// nawy-inventory pages
const NawyInventoryPage = Loadable(lazy(() => import('views/common/NawyInventory')));

// ==============================|| BROKERS GUARD ROUTING ||============================== //

const NawyInventoryRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/nawy-inventory',
            element: (
                <RoleGuard
                    groups={[
                        { name: InvitationTypeEnum.admins },
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.nawyInventory },
                        { name: InvitationTypeEnum.internalAgents },
                        { name: InvitationTypeEnum.developerRelationsManagers }
                    ]}
                >
                    <NawyInventoryPage />
                </RoleGuard>
            )
        }
    ]
};

export default NawyInventoryRoutes;
