import { Dialog, DialogContent } from '@mui/material';

import { Props } from 'react-apexcharts';
import BarChart from './Charts/Bar';

interface SalesBarChartDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    chartData: Props;
    isLoading: boolean;
    title: string;
}
const SalesBarChartDialog = ({ isOpen, handleClose, chartData, isLoading, title }: SalesBarChartDialogProps) => (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
            <BarChart isLoading={isLoading} chartData={chartData} title={title} />
        </DialogContent>
    </Dialog>
);

export default SalesBarChartDialog;
