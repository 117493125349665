import { CommonInitialState, CommonQueryInterface } from 'store/interfaces';

export interface PartnersDashboardInterface extends CommonInitialState {
    topCompounds: TopRanking[];
    topDevelopers: TopRanking[];
    primaryCilsTopDevelopers: TopCount[];
    financingCilsTopPartners: TopCount[];
}

export interface TopCount {
    id: number | string;
    name: string;
    totalCount: number;
}
export interface TopRanking extends TopCount {
    totalUnitValue: number;
}

export enum FlowStagesEnum {
    eoi = 'eoi',
    reservation = 'reservation',
    in_progress_sale_claim = 'in_progress_sale_claim',
    approved_sale_claim = 'approved_sale_claim'
}

export interface QueryPartnersDashboardInterface extends CommonQueryInterface {
    createdAtMax?: string;
    createdAtMin?: string;
    organizationId?: string;
    developerId?: number;
    compoundId?: number;
    propertyTypeId?: number;
    accountManagerId?: string;
    [key: string]: any;
}

export interface DashboardFilterInterface extends QueryPartnersDashboardInterface {
    flowType?: FlowStagesEnum;
    tierId?: string;
}
