import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryAccountManagersTeamInterface } from './interfaces';

const res = 'account-managers-teams';

const ACCOUNT_MANAGERS_TEAMS_APIS = {
    /**
     * @GET
     * @returns teamList
     */
    query: (query: QueryAccountManagersTeamInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '?'];
        return formattedQuery(formatQuery, query);
    },
    /**
     * @GET
     * @returns team
     */
    getById: (teamId: number) => `${FAUNA_API}${res}/${teamId}`,
    /**
     * @POST
     * @returns team
     */
    create: () => `${FAUNA_API}${res}`,
    /**
     * @PATCH
     * @returns team
     */
    update: (teamId: number) => `${FAUNA_API}${res}/${teamId}`,

    /**
     * @DELETE
     * @returns String
     */
    delete: (teamId: number) => `${FAUNA_API}${res}/${teamId}`
};

export default ACCOUNT_MANAGERS_TEAMS_APIS;
