import { Checkbox, SxProps, TableCell, TableRow, Theme } from '@mui/material';
import { styled } from '@mui/styles';
import { ChangeEvent, ReactNode } from 'react';
import { useNavigate } from 'react-router';

interface StyleTableRowProps {
    backgroundcolor?: string;
    theme?: any;
}

const StyleTableRow = styled(TableRow)(({ theme, backgroundcolor }: StyleTableRowProps) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: backgroundcolor ?? theme.palette.action.hover
    }
}));

interface StyledTableRowProps {
    children: ReactNode;
    navigationLink?: string;
    entity?: any;
    selectValue?: string;
    checked?: boolean;
    backgroundColor?: string;
    sx?: SxProps<Theme>;
    onClick?: (event?: any) => void;
    handleSelect?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const StyledTableRow = ({
    children,
    navigationLink,
    entity,
    onClick,
    selectValue,
    checked,
    sx,
    handleSelect,
    backgroundColor
}: StyledTableRowProps) => {
    const navigate = useNavigate();
    const handleNavigation = onClick ?? (() => (navigationLink ? navigate(navigationLink, { replace: false }) : undefined));
    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
    };

    return (
        <StyleTableRow
            hover={!!navigationLink}
            sx={{ '& > *': { borderBottom: 'unset', cursor: navigationLink ? 'pointer' : 'default' }, ...sx }}
            onClick={handleNavigation}
            backgroundcolor={backgroundColor}
        >
            {selectValue && entity && (
                <TableCell>
                    <Checkbox checked={checked} value={entity[selectValue]} onChange={handleSelect} onClick={handleOnClick} />
                </TableCell>
            )}
            {children}
        </StyleTableRow>
    );
};

export default StyledTableRow;
