import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Button, CardContent, Grid, InputAdornment, Stack, TextField, Theme, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import Accordion from 'ui-component/extended/Accordion';
import { QueryNotificationTypes, Portal, NotificationTypeMinimalResponse } from 'store/slices/notifications/interfaces';
import { Box } from '@mui/system';

const StringSearch = ({
    name,
    placeHolder,
    filterName,
    handleFilter
}: {
    name?: string;
    placeHolder: string;
    filterName: string;
    handleFilter: (type: string, params: QueryNotificationTypes) => void;
}) => {
    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        let newString = event?.target.value;
        if (newString === '') newString = undefined;
        handleFilter(`${filterName}`, { [`${filterName}`]: newString! });
    };
    return (
        <TextField
            fullWidth
            value={name}
            onChange={handleSearch}
            placeholder={placeHolder}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                    </InputAdornment>
                )
            }}
        />
    );
};

const NameSearch = ({
    value,
    handleFilter,
    typesNames
}: {
    value?: string;
    handleFilter: (type: string, params: QueryNotificationTypes) => void;
    typesNames: NotificationTypeMinimalResponse[];
}) => {
    const [autocompleteKey, setAutocompleteKey] = useState<number>(0);
    const intl = useIntl();

    const handleSearch = (targetType: number | undefined) => {
        handleFilter('typeId', { typeId: targetType });
    };

    useEffect(() => {
        if (value === '') {
            setAutocompleteKey(autocompleteKey + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Autocomplete
            id="name"
            options={typesNames}
            fullWidth
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                if (newValue) handleSearch(newValue.typeId);
                else handleSearch(undefined);
            }}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.typeId}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'name' })}
                    name="name"
                    id="name"
                    placeholder={intl.formatMessage({ id: 'name' })}
                />
            )}
        />
    );
};

const PortalSearch = ({
    value,
    handleFilter
}: {
    value?: string;
    handleFilter: (type: string, params: QueryNotificationTypes) => void;
}) => {
    const [autocompleteKey, setAutocompleteKey] = useState<number>(0);

    const handleSearch = (targetPortal: Portal | '') => {
        handleFilter('portal', { portal: targetPortal });
    };

    useEffect(() => {
        if (value === '') {
            setAutocompleteKey(autocompleteKey + 1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <Autocomplete
            id="select-portal"
            key={autocompleteKey}
            fullWidth
            options={Object.values(Portal)}
            onChange={(event, targetPortal) => {
                handleSearch(targetPortal ?? '');
            }}
            isOptionEqualToValue={(option) => option === value}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => <TextField {...params} name="portal" label="Portal *" />}
        />
    );
};
const NotificationTypesFilter = ({
    typesNames,
    filter,
    handelFilter
}: {
    typesNames: NotificationTypeMinimalResponse[];
    filter: QueryNotificationTypes;
    handelFilter: (type: string, params: QueryNotificationTypes) => void;
}) => {
    const matchDownLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const matchDownXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const intl = useIntl();
    const filterData = [
        {
            id: 'portal',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'portal' }),
            content: <PortalSearch value={filter.portal} handleFilter={handelFilter} />
        },
        {
            id: 'type-id',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'type-id' }),
            content: <StringSearch name={filter.typeId?.toString()} filterName="typeId" placeHolder="Type Id" handleFilter={handelFilter} />
        }
    ];

    if (matchDownXS)
        filterData.unshift({
            id: 'name-search',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'name' }),
            content: <NameSearch handleFilter={handelFilter} value={filter.name} typesNames={typesNames} />
        });

    return (
        <MainCard border={!matchDownLG} content={false} sx={{ overflow: 'visible' }}>
            <CardContent sx={{ p: 1, height: matchDownLG ? '100vh' : 'auto' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Accordion data={filterData} />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center">
                            <Button variant="contained" fullWidth color="error" onClick={() => handelFilter('reset', {})}>
                                <FormattedMessage id="clear-all" />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
};

export default NotificationTypesFilter;
