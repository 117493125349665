import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Badge, Box, ClickAwayListener, Grid, List, Paper, Popper, Typography, useMediaQuery } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Transitions from 'ui-component/extended/Transitions';
import NotificationListItem from './NotificationListItem';
import { IconBell, IconBellX } from '@tabler/icons';
import useAuth from 'hooks/useAuth';
import axios from 'utils/axios';
import { FormattedMessage } from 'react-intl';
import { YMIR_API } from 'store/apis';
import useMixPanel from 'hooks/useMixPanel';

export interface UserNotificationsResponse {
    isRead: boolean;
    typeId: number;
    body: string;
    link: string;
    createdAt: Date;
}

export interface UserNotificationsList {
    page: number;
    pageSize: number;
    count: number;
    pages: number;
    data: UserNotificationsResponse[];
}

export const USER_NOTIFICATIONS_APIS = {
    // get
    query: (userId: string, pageSize: number, page: string | null) =>
        `${YMIR_API}user-notification/${userId}?page=${page}&pageSize=${pageSize}`,
    // get
    resetNotifications: (userId: string) => `${YMIR_API}user-notification/${userId}/reset`,
    // patch
    readNotification: (userId: string) => `${YMIR_API}user-notification/${userId}`
};

const NotificationSection = () => {
    const { user } = useAuth();
    const theme = useTheme();

    const { emitEvent } = useMixPanel();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [page, setPage] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [notifications, setNotifications] = useState<UserNotificationsResponse[]>([]);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const handleToggle = () => {
        if (open === false) {
            emitEvent('Click View Notifications');
            resetNotifications();
        }

        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const getNotifications = async () => {
        try {
            setError(false);
            const res = await axios.get(USER_NOTIFICATIONS_APIS.query(user?.cognitoId as string, 10, page));
            const temp = [...notifications, ...res.data.data];
            setNotifications(temp);
            setPage(res.data.page ?? null);
            setNotificationsCount(parseInt(res.data.count, 10));
        } catch (err) {
            emitEvent('Errors - notification', { title: 'getNotifications', location: 'notification bell' });
            setError(true);
        }
    };

    const resetNotifications = async () => {
        try {
            await axios.get(USER_NOTIFICATIONS_APIS.resetNotifications(user?.cognitoId as string));
        } catch (err) {
            emitEvent('Errors - notification', { title: 'resetNotifications', location: 'notification bell' });
            setError(true);
        }
    };

    useEffect(() => {
        getNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Box>
                <Badge badgeContent={notificationsCount} color="primary">
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.dark,
                            '&[aria-controls="menu-list-grow"],&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.main,
                                color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.primary.light
                            }
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        color="inherit"
                    >
                        <IconBell stroke={1.5} size="1.3rem" />
                    </Avatar>
                </Badge>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom-start' : 'bottom'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top-left' : 'top'} in={open} {...TransitionProps}>
                            <Paper elevation={16}>
                                {open && (
                                    <>
                                        <Grid container sx={{ p: '16px' }}>
                                            <Typography variant="h4">
                                                <FormattedMessage id="notifications" />
                                            </Typography>
                                        </Grid>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: 300,
                                                height: 400,
                                                bgcolor: theme.palette.background.paper,
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                },
                                                padding: 0,
                                                overflowY: 'hidden'
                                            }}
                                        >
                                            <PerfectScrollbar
                                                onYReachEnd={() => {
                                                    if (page != null) getNotifications();
                                                }}
                                            >
                                                {(error && (
                                                    <Grid
                                                        container
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        direction="column"
                                                        spacing={2}
                                                        sx={{ height: '100%' }}
                                                    >
                                                        <Grid item>
                                                            {' '}
                                                            <IconBellX color={theme.palette.error.main} size={46} />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography variant="h5">
                                                                <FormattedMessage id="could-not-fetch-notifications" />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )) ||
                                                    (notifications.length > 0 &&
                                                        notifications.map((notification, i) => (
                                                            <span key={i}>
                                                                <NotificationListItem
                                                                    notification={notification}
                                                                    handleClose={handleClose}
                                                                    setError={setError}
                                                                    key={notification.createdAt.toString()}
                                                                />
                                                            </span>
                                                        ))) || (
                                                        <Grid
                                                            container
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            spacing={2}
                                                            sx={{ height: '100%' }}
                                                        >
                                                            <Typography variant="h4">
                                                                <FormattedMessage id="no-notifications-available" />
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                            </PerfectScrollbar>
                                        </List>
                                    </>
                                )}
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
