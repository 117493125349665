import CloseIcon from '@mui/icons-material/Close';
import { Button, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';
import { QueryNotificationTypes } from 'store/slices/notifications/interfaces';

interface NotificationTypesFilterViewProps {
    filter: QueryNotificationTypes;
    initialState: QueryNotificationTypes;
    filterIsEqual: (initialState: QueryNotificationTypes, filter: QueryNotificationTypes) => boolean;
    handelFilter: (type: string, params: QueryNotificationTypes) => void;
}

const NotificationTypesFilterView = ({ filter, filterIsEqual, handelFilter, initialState }: NotificationTypesFilterViewProps) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {!filterIsEqual(initialState, filter) && (
                <Grid container spacing={gridSpacing} sx={{ pb: gridSpacing }} alignItems="center">
                    {!(initialState.name === filter.name) && matchDownXS && (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="name" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={filter.name}
                                                chipcolor="secondary"
                                                onDelete={() => handelFilter('name', { name: '' })}
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    )}
                    {!(initialState.typeId === filter.typeId) && (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="type-id" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={filter.typeId?.toString()}
                                                chipcolor="secondary"
                                                onDelete={() => handelFilter('typeId', { typeId: undefined })}
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    )}
                    {initialState.portal !== filter.portal && (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="portal" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={filter.portal}
                                                chipcolor="secondary"
                                                onDelete={() => handelFilter('portal', { portal: '' })}
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    )}
                    {(matchDownXS || initialState.portal !== filter.portal || initialState.typeId !== filter.typeId) && (
                        <Grid item>
                            <Button variant="outlined" startIcon={<CloseIcon />} color="error" onClick={() => handelFilter('reset', {})}>
                                <FormattedMessage id="clear-all" />
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default NotificationTypesFilterView;
