import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { CommonHandleFilterType } from 'ui-component/Filters/types';
import StickyCell from '../Cells/StickyCell';
import DefaultHeader from '../Cells/DefaultHeader';
import HeaderWithSort from '../Cells/HeaderWithSort';
import { OrderByDirection } from 'store/interfaces';
import RoleGuard, { RoleGuardGroupProps } from 'utils/RoleGuard';
import { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

export interface HeadProps {
    disablePadding: boolean;
    id: string;
    label: string;
    secondaryLabel?: string;
    secondaryLabelRight?: string;
    sort: boolean;
    minWidth?: string;
    groups?: RoleGuardGroupProps[];
    disableTranslate?: boolean;
    align: 'left' | 'center' | 'right';
}

interface HeadersPropsInterface {
    sort: {
        by: string;
        direction: OrderByDirection;
    };
    handleFilter: CommonHandleFilterType;
    headers: HeadProps[];
    select?: boolean;
    indeterminate?: boolean;
    allSelected?: boolean;
    handleSelectAll?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}
const Headers = ({ handleFilter, headers, sort, select, indeterminate, allSelected, handleSelectAll }: HeadersPropsInterface) => (
    <TableHead>
        <TableRow>
            {select && (
                <TableCell>
                    <Checkbox checked={allSelected} indeterminate={indeterminate} onChange={handleSelectAll} />
                </TableCell>
            )}
            {headers.map((headCell) =>
                headCell.groups ? (
                    <RoleGuard key={headCell.id} groups={headCell.groups}>
                        {headCell.sort ? (
                            <HeaderWithSort key={headCell.id} sort={sort} handleFilter={handleFilter} header={headCell} />
                        ) : headCell.id === 'action' ? (
                            <StickyCell key={headCell.id}> </StickyCell>
                        ) : (
                            <DefaultHeader key={headCell.id} headCell={headCell} />
                        )}
                    </RoleGuard>
                ) : headCell.sort ? (
                    <HeaderWithSort key={headCell.id} sort={sort} handleFilter={handleFilter} header={headCell} />
                ) : headCell.id === 'action' ? (
                    <StickyCell sx={{ minWidth: headCell.minWidth || '170px' }} key={headCell?.id}>
                        <FormattedMessage id={headCell.label} />
                    </StickyCell>
                ) : (
                    <DefaultHeader key={headCell.id} headCell={headCell} />
                )
            )}
        </TableRow>
    </TableHead>
);

export default Headers;
