import { ClickAwayListener, Stack, Fade, IconButton, InputAdornment, Paper, Popper, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import MainCard from 'ui-component/cards/MainCard';
import useMixPanel from 'hooks/useMixPanel';

const DateRangePickerFilter = ({
    label,
    DateMin,
    DateMax,
    handleSearch,
    location
}: {
    label: string;
    location: string;
    DateMin?: string;
    DateMax?: string;
    handleSearch: (DateMin?: string, DateMax?: string) => void;
}) => {
    function handleFromDateOnChange(newValue: Date | null) {
        if (newValue !== null) {
            formik.setFieldValue('fromDate', newValue.toISOString());
            if (formik.values.toDate && formik.values.toDate !== '') {
                handleSubmit(newValue.toISOString(), formik.values.toDate);
            }
        } else formik.setFieldValue('fromDate', '');
    }

    function handleToDateOnChange(newValue: Date | null) {
        if (newValue !== null) {
            formik.setFieldValue('toDate', new Date(newValue.setHours(23, 59, 59)).toISOString());
            if (formik.values.fromDate && formik.values.fromDate !== '') {
                handleSubmit(formik.values.fromDate, new Date(newValue.setHours(23, 59, 59)).toISOString());
            }
        } else formik.setFieldValue('toDate', '');
    }

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (!anchorEl) setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const formik = useFormik({
        initialValues: {
            toDate: DateMax,
            fromDate: DateMin
        },
        onSubmit: () => {},
        enableReinitialize: true
    });

    const handleSubmit = (from?: string, to?: string) => {
        handleSearch(from, to);
        setOpen(false);
    };

    const handleClear = () => {
        handleSubmit(undefined, undefined);

        handleFromDateOnChange(null);
        handleToDateOnChange(null);
    };
    const { emitEvent } = useMixPanel();
    const handleDataRangeClick = () => {
        emitEvent('Click date range filters', { location, label });
    };

    return (
        <>
            <TextField
                size="small"
                fullWidth
                value={
                    DateMin && DateMax
                        ? `${new Date(DateMin).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'short'
                          })} - ${new Date(DateMax).toLocaleDateString('en-US', {
                              day: 'numeric',
                              month: 'short'
                          })}`
                        : ''
                }
                label={label}
                onClick={handleDataRangeClick}
                placeholder={label}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {DateMin && DateMax ? (
                                <IconButton onClick={handleClear}>
                                    <ClearIcon
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>
                            ) : (
                                <IconButton onClick={handleClick}>
                                    <CalendarTodayIcon
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )
                }}
            />
            <Popper placement="bottom-start" id="tableCalendar" open={open} anchorEl={anchorEl} transition sx={{ zIndex: 1200 }}>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper>
                                <MainCard border={false} elevation={16} boxShadow>
                                    <Stack spacing={1}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="From"
                                                inputFormat="dd/MM/yyyy"
                                                value={formik.values.fromDate ? new Date(formik.values.fromDate) : null}
                                                onChange={(newValue) => handleFromDateOnChange(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        name="fromDate"
                                                        onBlur={formik.handleBlur}
                                                        error={Boolean(formik.touched.fromDate && formik.errors.fromDate)}
                                                        helperText={formik.touched.fromDate && formik.errors.fromDate}
                                                        fullWidth
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="To"
                                                inputFormat="dd/MM/yyyy"
                                                value={formik.values.toDate ? new Date(formik.values.toDate) : null}
                                                onChange={(newValue) => handleToDateOnChange(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        size="small"
                                                        name="toDate"
                                                        onBlur={formik.handleBlur}
                                                        error={Boolean(formik.touched.toDate && formik.errors.toDate)}
                                                        helperText={formik.touched.toDate && formik.errors.toDate}
                                                        fullWidth
                                                        {...params}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Stack>
                                </MainCard>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default DateRangePickerFilter;
