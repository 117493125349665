import { LoadingButton } from '@mui/lab';
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import MainCard from 'ui-component/cards/MainCard';
import AlertDialog from 'ui-component/DeleteDialog';
import { Portal, ReservedVariables, UpdateNotificationTypeV1Dto, UsersInterface } from 'store/slices/notifications/interfaces';
import { deleteNotificationType, getNotificationTypeById, updateNotificationType } from 'store/slices/notifications/actions';
import { format } from 'date-fns';
import Link from './Link';
import ChannelConfiguration from './configurations/ChannelConfiguration';
import DefaultUsers from './DefaultUsers';

const NotificationTypeDetailsPage = () => {
    const intl = useIntl();

    const UpdateNotificationTypeDataSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        portal: yup.mixed<Portal>().required(intl.formatMessage({ id: 'portal-required' })),
        requiredData: yup.array().of(yup.string()),
        defaultUsers: yup.mixed<UsersInterface>()
    });
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: typeId } = useParams();
    const { notificationType, loading, error } = useSelector((state) => state.notificationTypes);
    const [edit, setEdit] = useState(false);
    const [typedInRequiredData, setTypedInRequiredData] = useState('');

    const handleToggleEdit = () => {
        setEdit(!edit);
        formik.resetForm();
        setTypedInRequiredData('');
    };

    const handleDelete = () => {
        if (typeId && notificationType)
            dispatch(
                deleteNotificationType({
                    typeId: parseInt(typeId, 10),
                    deleteData: {
                        portal: notificationType.portal,
                        typeConfigs: notificationType.configurations.map((config) => config.configId)
                    },
                    runSuccess: runDeleteSuccess
                })
            );

        navigate('/admin/notification-types', { replace: true });
    };

    const runDeleteSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: `${intl.formatMessage({ id: 'notification-type' })} ${intl.formatMessage({ id: 'deleted' })}`,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };
    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);
        handleToggleEdit();
    };

    const checkReuiredDataChange = (array1: string[], array2: string[]) => {
        array1.sort();
        array2.sort();
        return !(array1.length === array2.length && array1.every((value, index) => value === array2[index]));
    };

    const checkDefaultUsersChange = (array1: UsersInterface[], array2: UsersInterface[]) => {
        array1.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        array2.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        return !(array1.length === array2.length && array1.every((value, index) => value.cognitoId === array2[index].cognitoId));
    };

    const formik = useFormik({
        initialValues: {
            name: notificationType?.name || '',
            portal: notificationType?.portal || undefined,
            requiredData: notificationType?.requiredData || [],
            defaultUsers: notificationType?.defaultUsersInfo || []
        },
        onSubmit(values, formikHelpers) {
            const filteredData: UpdateNotificationTypeV1Dto = {};
            if (values.name !== formik.initialValues.name) filteredData.name = values.name;
            if (values.portal !== formik.initialValues.portal) filteredData.portal = values.portal;
            if (checkReuiredDataChange([...values.requiredData], [...formik.initialValues.requiredData]))
                filteredData.requiredData = values.requiredData;
            if (checkDefaultUsersChange([...values.defaultUsers], [...formik.initialValues.defaultUsers]))
                filteredData.defaultUsers = values.defaultUsers.map((user) => user.cognitoId);

            if (Object.keys(filteredData).length === 0) {
                formikHelpers.setSubmitting(false);
                return;
            }

            formikHelpers.setSubmitting(true);

            if (typeId)
                dispatch(
                    updateNotificationType({
                        typeId: parseInt(typeId, 10),
                        updateData: { ...filteredData },
                        runSuccess
                    })
                );
        },
        validationSchema: UpdateNotificationTypeDataSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (typeId) dispatch(getNotificationTypeById({ typeId: parseInt(typeId, 10) }));
    }, [typeId, dispatch]);

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleAddRequiredData = () => {
        if (typedInRequiredData) {
            formik.setFieldValue('requiredData', [...formik.values.requiredData, typedInRequiredData.replace(/\s/g, '')]);
            setTypedInRequiredData('');
        }
    };

    const handleRemoveRequiredData = (variableIndex: number) => {
        if (notificationType) {
            const temp = [...formik.values.requiredData];
            temp.splice(variableIndex, 1);

            const reqVariableRegex = /\(([^)]+)\)/g;

            for (const config of notificationType.configurations) {
                let variablesInTemplate = config.body.match(reqVariableRegex)?.map((match) => match.slice(1, -1));
                variablesInTemplate = [...new Set(variablesInTemplate)];
                variablesInTemplate = variablesInTemplate.filter((variable) => !Object.keys(ReservedVariables).includes(variable));
                const variablesExistInReqData = variablesInTemplate.every((variable) => temp.includes(variable));

                if (!variablesExistInReqData) {
                    formik.setFieldError('requiredData', intl.formatMessage({ id: 'used-required-variable' }));
                    return;
                }
            }
            formik.setFieldValue('requiredData', [...temp]);
        }
    };

    if (loading || !notificationType)
        return (
            <Grid container justifyContent="center" alignItems="center" height="70vh">
                <CircularProgress />
            </Grid>
        );

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard
                    bgcolor={theme.palette.background.default}
                    title={
                        <Grid container spacing={gridSpacing} justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <FormattedMessage id={intl.formatMessage({ id: 'notification-type' })} />
                            </Grid>
                        </Grid>
                    }
                    content={false}
                />
            </Grid>

            <Grid item xs={12}>
                <MainCard
                    title={intl.formatMessage({ id: 'info' })}
                    secondary={
                        !edit ? (
                            <Grid container spacing={gridSpacing}>
                                <Grid item>
                                    <AlertDialog type="button" title={intl.formatMessage({ id: 'remove' })} onConfirm={handleDelete} />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" size="small" onClick={handleToggleEdit}>
                                        <FormattedMessage id="edit" />
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid container spacing={gridSpacing}>
                                <Grid item>
                                    <Button disabled={formik.isSubmitting} color="error" size="small" onClick={handleToggleEdit}>
                                        <FormattedMessage id="cancel" />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        loading={formik.isSubmitting}
                                        variant="contained"
                                        size="small"
                                        onClick={formik.submitForm}
                                    >
                                        <FormattedMessage id="confirm" />
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        )
                    }
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <>
                                <Typography>
                                    <FormattedMessage id="type-id" />:
                                </Typography>
                                <Typography variant="h3">#{notificationType.typeId}</Typography>
                            </>
                        </Grid>
                        <Grid item xs={6}>
                            {edit ? (
                                <TextField
                                    fullWidth
                                    label={intl.formatMessage({ id: 'name' })}
                                    id="update-type-name"
                                    name="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.name && formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            ) : (
                                <>
                                    <Typography>
                                        <FormattedMessage id="name" />:
                                    </Typography>
                                    <Typography variant="h3">{notificationType.name}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {edit ? (
                                <FormControl fullWidth>
                                    <>
                                        <InputLabel id="update-portal">
                                            <FormattedMessage id="portal" />
                                        </InputLabel>
                                        <Select
                                            labelId="update-portal"
                                            id="update-portal"
                                            value={formik.values.portal}
                                            label={intl.formatMessage({ id: 'portal' })}
                                            name="portal"
                                            onChange={formik.handleChange}
                                        >
                                            {Object.values(Portal).map((value) => (
                                                <MenuItem value={value} key={notificationType.typeId}>
                                                    <FormattedMessage id={value} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </>
                                </FormControl>
                            ) : (
                                <>
                                    <Typography>
                                        <FormattedMessage id="portal" />:
                                    </Typography>
                                    <Typography variant="h3">{notificationType.portal}</Typography>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {edit ? (
                                <FormControl fullWidth>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} container alignItems="center" spacing={2} justifyContent="flex-start">
                                            <Grid item xs={10}>
                                                <TextField
                                                    fullWidth
                                                    label={intl.formatMessage({ id: 'required-data' })}
                                                    id="update-required-data"
                                                    name="requiredData"
                                                    onChange={(event) => {
                                                        setTypedInRequiredData(event.target.value);
                                                    }}
                                                    value={typedInRequiredData}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.errors.requiredData)}
                                                    helperText={formik.errors.requiredData}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" onClick={handleAddRequiredData}>
                                                    <FormattedMessage id="add" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item container spacing={1}>
                                            {formik.values.requiredData &&
                                                formik.values.requiredData.map((variable, i) => (
                                                    <Grid item key={variable}>
                                                        <Chip size="small" label={variable} onDelete={() => handleRemoveRequiredData(i)} />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            ) : (
                                <>
                                    <Grid container alignItems="center" spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography>
                                                <FormattedMessage id="required-data" />:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} container spacing={1}>
                                            {(notificationType.requiredData &&
                                                notificationType.requiredData.map((variable) => (
                                                    <Grid item key={variable}>
                                                        <Chip size="small" label={variable} />
                                                    </Grid>
                                                ))) || (
                                                <Grid item>
                                                    <Typography>
                                                        <FormattedMessage id="no-required-data" />
                                                    </Typography>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <DefaultUsers edit={edit} formik={formik} />{' '}
                        </Grid>
                        <Grid item xs={6}>
                            <>
                                <Typography>
                                    <FormattedMessage id="created-at" />:
                                </Typography>
                                <Typography variant="h3">{format(new Date(notificationType.createdAt), 'dd MMM yyyy')}</Typography>
                            </>
                        </Grid>
                        <Grid item xs={6}>
                            <>
                                <Typography>
                                    <FormattedMessage id="created-by" />:
                                </Typography>
                                <Typography variant="h3">{notificationType.createdBy}</Typography>
                            </>
                        </Grid>
                    </Grid>
                </MainCard>
            </Grid>
            <Grid sx={{ position: 'relative' }} item xs={12}>
                <Link />
            </Grid>
            <Grid sx={{ position: 'relative' }} item xs={12}>
                <ChannelConfiguration />
            </Grid>
        </Grid>
    );
};

export default NotificationTypeDetailsPage;
