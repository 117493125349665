import { ReactNode } from 'react';

import useAuth from 'hooks/useAuth';
import { isEmpty } from 'lodash';
import { InvitationTypeEnum } from 'store/interfaces';
import { TierViewsEnum } from 'store/slices/tiers/interfaces';
import { UserProfile } from 'types/user-profile';
import isOrganizationFeatureAllowed from './isOrganizationFeatureAllowed';

interface RoleGuardProps {
    children: ReactNode;
    groups: RoleGuardGroupProps[];
    ifNotAllowed?: ReactNode;
    allowAdminByDefault?: boolean;
    redirectToHome?: boolean;
}
export interface RoleGuardGroupProps {
    name?: string;
    roles?: string[];
    organizationFeature?:
        | 'cilsLimit'
        | 'primaryCilsLimit'
        | 'brokersLimit'
        | 'invitationsLimit'
        | 'leadsLimit'
        | 'listingLimit'
        | 'saleClaimsLimit'
        | 'reservationLimit';
    organizationTierView?: TierViewsEnum;
    tiers?: string[];
}

export function isManager(groups: RoleGuardGroupProps[], user?: UserProfile | null) {
    const userGroup = groups.find((group) => user?.groups?.includes(group?.name || ''));
    if (userGroup?.roles) return !!userGroup?.roles.includes(user?.accountManagerRole ?? '');
    return !!userGroup;
}

export function isInventoryManager(groups: RoleGuardGroupProps[], user?: UserProfile | null) {
    const userGroup = groups.find((group) => user?.groups?.includes(group?.name || ''));
    if (userGroup?.roles) return !!userGroup?.roles.includes(user?.inventoryManagerRole ?? '');
    return !!userGroup;
}

export default function RoleGuard({ children, groups, ifNotAllowed, redirectToHome, allowAdminByDefault = true }: RoleGuardProps) {
    const { user } = useAuth();

    if (isEmpty(groups)) return <>{children}</>;

    const userGroup = groups.find((group) => user?.groups?.includes(group?.name || '')) || groups.find((g) => g.tiers?.length);

    if (userGroup?.tiers?.length && !userGroup?.roles) {
        if (userGroup?.tiers && user?.brokerAuth?.organization.tier && userGroup?.tiers.includes(user.brokerAuth.organization.tier.id)) {
            return <>{children}</>;
        }
    }

    if (userGroup) {
        if (!userGroup.roles && !userGroup.organizationFeature && !userGroup.tiers) {
            return <>{children}</>;
        }

        if (userGroup.name === InvitationTypeEnum.admins && allowAdminByDefault) {
            return <>{children}</>;
        }

        switch (userGroup.name) {
            case InvitationTypeEnum.accountManagers:
                if (isManager(groups, user)) {
                    return <>{children}</>;
                }
                break;
            case InvitationTypeEnum.operationManager:
                if (isManager(groups, user)) {
                    return <>{children}</>;
                }
                break;
            case InvitationTypeEnum.inventoryManagers:
                if (isInventoryManager(groups, user)) {
                    return <>{children}</>;
                }
                break;
            case InvitationTypeEnum.brokers:
                if (!isOrganizationFeatureAllowed(user, userGroup)) {
                    return <>{ifNotAllowed}</>;
                }
                if (userGroup?.organizationTierView && user?.brokerAuth?.organization.tier?.view !== userGroup.organizationTierView) {
                    return <>{ifNotAllowed}</>;
                }
                if (userGroup?.roles && !userGroup?.roles.includes(user?.brokerAuth?.role || '')) {
                    return <>{ifNotAllowed}</>;
                }
                if (
                    userGroup?.tiers &&
                    user?.brokerAuth?.organization.tier &&
                    !userGroup?.tiers.includes(user.brokerAuth.organization.tier.id)
                ) {
                    return <>{ifNotAllowed}</>;
                }
                if (userGroup?.tiers && !user?.brokerAuth?.organization?.tier?.id) {
                    return <>{ifNotAllowed}</>;
                }

                return <>{children}</>;
            default:
        }
    }

    return <>{ifNotAllowed}</>;
}
