// statuses
import { createSlice } from '@reduxjs/toolkit';

import { queryActivityStatuses } from './actions';
import { ActivityStatusInitialState } from './interfaces';

const initialState: ActivityStatusInitialState = {
    loading: false,
    error: false,
    refresh: false,
    statuses: []
};

// ==============================|| SLICE - ACTIVITY TYPES ||============================== //
const activityCategory = createSlice({
    name: 'activityStatus',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryActivityStatuses.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryActivityStatuses.fulfilled, (state, action) => {
                state.loading = false;
                state.statuses = action.payload;
            })
            .addCase(queryActivityStatuses.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default activityCategory.reducer;
