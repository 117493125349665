import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import ReceiptIcon from '@mui/icons-material/Receipt';

const flows: NavItemType = {
    id: 'flows',
    type: 'item',
    url: '/flows',
    breadcrumbs: false,
    icon: ReceiptIcon,
    title: <FormattedMessage id="sales-pipeline" />,

    allowedAudience: [
        { name: InvitationTypeEnum.operationManager },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' },
        { name: InvitationTypeEnum.admins }
    ],
    mixpanelEvent: {
        tab_name: 'Sales Pipeline'
    }
};

const flowsItem: NavItemType = {
    id: 'flows',
    type: 'group',
    children: [flows]
};
export default flowsItem;
