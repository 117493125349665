import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import ACTIVITY_STATUS_APIS from './api';
import { QueryActivityStatusInterface } from '../activityCategory/interfaces';

// eslint-disable-next-line import/prefer-default-export
export const queryActivityStatuses = createAsyncThunk(
    'activity-status/query',
    async (
        requestData: {
            query: QueryActivityStatusInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess, query } = requestData;

        try {
            const successRes = await axios.get(ACTIVITY_STATUS_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
