import { Grid, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface FabItemBtnProps {
    title: string;
    icon: ReactNode;
    onClick: () => void;
}

const FabItemBtn = ({ title, icon, onClick }: FabItemBtnProps) => (
    <Grid container gap={2} alignItems="center">
        <Grid item>
            <Typography variant="h4" fontWeight="500" color="primary">
                {title}
            </Typography>
        </Grid>
        <Grid item bgcolor="#E8EAF6" borderRadius={2}>
            <IconButton onClick={onClick} color="secondary" size="small">
                {icon}
            </IconButton>
        </Grid>
    </Grid>
);

export default FabItemBtn;
