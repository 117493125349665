import { createAsyncThunk } from '@reduxjs/toolkit';
import { BannerFormInterface, BannerInterface, QueryBannerInterface } from './interfaces';
import axios from 'utils/axios';
import BANNERS_APIS from './apis';

function appendValuesToFormData<T>(formData: FormData, updateData: T) {
    for (const key in updateData) {
        if (Array.isArray(updateData[key as keyof T])) {
            (updateData[key] as any[]).forEach((item) => {
                formData.append(key, item);
            });
        } else if (updateData[key as keyof T] !== undefined) {
            formData.append(key, updateData[key as keyof T] as string | Blob);
        }
    }

    return formData;
}

const queryBanners = createAsyncThunk(
    'banners/query',
    async (
        requestData: {
            queryData: QueryBannerInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BannerInterface[] | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get<BannerInterface[]>(BANNERS_APIS.query(queryData));
            runSuccess?.();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createBanner = createAsyncThunk(
    'banners/create',
    async (
        requestData: {
            createData: BannerFormInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BannerInterface | any> => {
        const { createData, runSuccess } = requestData;

        const formData = appendValuesToFormData(new FormData(), createData);

        try {
            const successRes = await axios.post(BANNERS_APIS.createBanner(), formData);
            runSuccess?.();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getRecentBanners = createAsyncThunk(
    'banners/getRecent',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BannerInterface[] | any> => {
        const { runSuccess } = requestData;
        try {
            const successRes = await axios.get<BannerInterface[]>(BANNERS_APIS.getRecentBanners());
            runSuccess?.();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateBanner = createAsyncThunk(
    'banners/update',
    async (
        requestData: {
            bannerId: string;
            updateData: BannerFormInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BannerInterface | any> => {
        const { bannerId, updateData, runSuccess } = requestData;

        const formData = appendValuesToFormData(new FormData(), updateData);

        try {
            const successRes = await axios.patch(BANNERS_APIS.updateBanner(bannerId), formData);
            runSuccess?.();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteBanner = createAsyncThunk(
    'banners/delete',
    async (
        requestData: {
            bannerId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BannerInterface | any> => {
        const { bannerId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(BANNERS_APIS.deleteBanner(bannerId));
            runSuccess?.();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default queryBanners;
