import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import EVENTS_APIS from './apis';
import { CreateEventData, EventInterface, EventsInterface, QueryEventsInterface } from './interfaces';

export const createEvent = createAsyncThunk(
    'events/create',
    async (
        requestData: {
            createData: CreateEventData;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(EVENTS_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateEvent = createAsyncThunk(
    'events/update',
    async (
        requestData: {
            eventId: string;
            active: boolean;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { eventId, active, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(EVENTS_APIS.update(eventId), { active });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteEvent = createAsyncThunk(
    'events/delete',
    async (
        requestData: {
            eventId: string;

            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { eventId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(EVENTS_APIS.delete(eventId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getEventById = createAsyncThunk(
    'events/getById',
    async (
        requestData: {
            eventId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { eventId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(EVENTS_APIS.getById(eventId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryEvents = createAsyncThunk(
    'events/query',
    async (
        requestData: {
            query: QueryEventsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventsInterface | any> => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(EVENTS_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addConditionToEvent = createAsyncThunk(
    'events/addCondition',
    async (
        requestData: {
            eventId: string;
            conditionData: any;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { eventId, conditionData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(EVENTS_APIS.addCondition(eventId), conditionData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const removeConditionFromEvent = createAsyncThunk(
    'events/removeCondition',
    async (
        requestData: {
            eventId: string;
            conditionId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<EventInterface | any> => {
        const { eventId, conditionId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(EVENTS_APIS.removeCondition(eventId, conditionId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
