import { createSlice } from '@reduxjs/toolkit';
import { BannerInitialState } from './interfaces';
import queryBanners, { createBanner, deleteBanner, getRecentBanners, updateBanner } from './actions';

const initialState: BannerInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    listRefresh: false,
    refresh: false,
    banners: []
};

const banners = createSlice({
    name: 'banners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(queryBanners.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryBanners.fulfilled, (state, action) => {
                state.listLoading = false;
                state.banners = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(queryBanners.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getRecentBanners.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(getRecentBanners.fulfilled, (state, action) => {
                state.listLoading = false;
                state.banners = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(getRecentBanners.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(createBanner.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createBanner.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.error = false;
            })
            .addCase(createBanner.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateBanner.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateBanner.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.error = false;
            })
            .addCase(updateBanner.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteBanner.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteBanner.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.error = false;
            })
            .addCase(deleteBanner.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default banners.reducer;
