import { createSlice } from '@reduxjs/toolkit';
import { createLeadRequest, queryLeadRequests } from './actions';
import { InitialLeadRequestsInterface } from './interfaces';

// initial state
const initialState: InitialLeadRequestsInterface = {
    createLeadRequestLoading: false,
    createLeadRequestError: false,
    errorList: false,
    loadingList: false,
    refresh: false,
    leadRequests: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE - Lead Requests ||============================== //

const leadRequests = createSlice({
    name: 'leadRequests',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryLeadRequests.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryLeadRequests.fulfilled, (state, action) => {
                state.loadingList = false;
                state.leadRequests = action.payload;
            })
            .addCase(queryLeadRequests.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(createLeadRequest.pending, (state) => {
                state.createLeadRequestLoading = true;
                state.createLeadRequestError = false;
            })
            .addCase(createLeadRequest.fulfilled, (state) => {
                state.createLeadRequestLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createLeadRequest.rejected, (state, action) => {
                state.createLeadRequestLoading = false;
                state.createLeadRequestError = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default leadRequests.reducer;

// export const {  } = leadRequests.actions;
