import { Collapse, IconButton, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Chip from 'ui-component/extended/Chip';

import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import { InvitationTypeEnum } from 'store/interfaces';
import { SalesAgentsInterface, SalesAgentsListInterface } from 'store/slices/salesAgents/interfaces';
import SubCard from 'ui-component/cards/SubCard';
import MoveSalesAgentCell from 'components/SalesAgents/Actions/MoveSalesAgentActionBtn';
import { format } from 'date-fns';

const SalesAgentsTableBody = ({ salesAgents, headersLength }: { salesAgents: SalesAgentsListInterface; headersLength: number }) => {
    const { user } = useAuth();

    if (!user) return <></>;
    return (
        <TableBody>
            {salesAgents.data.map((salesAgent) => (
                <Row key={salesAgent.cognitoId} salesAgent={salesAgent} headersLength={headersLength} />
            ))}
        </TableBody>
    );
};

const Row = ({ salesAgent, headersLength }: { salesAgent: SalesAgentsInterface; headersLength: number }) => {
    const intl = useIntl();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        sx={{
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.light,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.light,
                                color: theme.palette.primary.main
                            }
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="left">{salesAgent.user.name || '-'}</TableCell>
                <TableCell align="left">{salesAgent.phone || '-'}</TableCell>
                <TableCell align="center">
                    <Chip label={InvitationTypeEnum.internalAgents} variant="outlined" />
                </TableCell>
                <TableCell align="center">
                    <Chip
                        label={salesAgent.user.banned ? intl.formatMessage({ id: 'banned' }) : intl.formatMessage({ id: 'active' }) || '-'}
                        chipcolor={salesAgent.user.banned ? 'error' : 'success'}
                    />
                </TableCell>
                <TableCell align="left">{salesAgent.salesAgentMoves?.[0]?.addedBy?.name || '-'}</TableCell>
                <TableCell align="left">{salesAgent.createdAt ? format(new Date(salesAgent.createdAt), 'dd-MM-yyyy') : '-'}</TableCell>
                <TableCell align="left">{salesAgent.updatedAt ? format(new Date(salesAgent.updatedAt), 'dd-MM-yyyy') : '-'}</TableCell>
                <TableCell align="right">
                    <MoveSalesAgentCell salesAgent={salesAgent} />
                </TableCell>
            </TableRow>
            <TableRow key={salesAgent.cognitoId}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={headersLength + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ padding: 10 }}>
                        <SubCard sx={{ my: 1 }}>
                            <Typography sx={{ marginRight: 1 }} fontWeight={400}>
                                <FormattedMessage id="user-history" />:
                            </Typography>
                            {salesAgent.salesAgentMoves?.map((move) => (
                                <Box key={move.salesAgentId} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ marginRight: 1 }} variant="h6">
                                        {format(new Date(move.createdAt), 'dd-MM-yyyy')}
                                    </Typography>
                                    <Typography sx={{ marginRight: 1 }}>{intl.formatMessage({ id: 'moved-to' })}</Typography>
                                    <Typography sx={{ marginRight: 1 }} variant="h6">
                                        {move.crm.name}
                                    </Typography>
                                    <Typography sx={{ marginRight: 1 }}>{intl.formatMessage({ id: 'by' })}</Typography>
                                    <Typography sx={{ marginRight: 1 }} variant="h6">
                                        {move.addedBy.name}
                                    </Typography>
                                </Box>
                            ))}
                        </SubCard>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};
export default SalesAgentsTableBody;
