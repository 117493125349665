import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { dispatch, useSelector } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import { queryMinimalBrokers } from 'store/slices/brokers/actions';
import { MinimalBrokersInterface, OrganizationRole } from 'store/slices/brokers/interfaces';
import RoleGuard from 'utils/RoleGuard';

interface BrokerFilterProps {
    defaultValueId?: string;
    defaultValueCognitoId?: string;
    handleSearch: (newValue: MinimalBrokersInterface | null) => void;
    size?: 'small' | 'medium';
    isRequired?: boolean;
    limitOrganization?: string;
    isDeleted?: boolean;
    ifNotAllowed?: ReactNode;
}

const BrokerFilter = ({
    defaultValueId,
    defaultValueCognitoId,
    handleSearch,
    size,
    isRequired,
    limitOrganization,
    isDeleted,
    ifNotAllowed
}: BrokerFilterProps) => (
    <RoleGuard
        groups={[
            { name: InvitationTypeEnum.admins },
            { name: InvitationTypeEnum.accountManagers },
            { name: InvitationTypeEnum.operationManager },
            { name: InvitationTypeEnum.inventoryManagers },
            {
                name: InvitationTypeEnum.brokers,
                roles: [OrganizationRole.owner]
            }
        ]}
        ifNotAllowed={ifNotAllowed}
    >
        <Filter
            defaultValueId={defaultValueId}
            defaultValueCognitoId={defaultValueCognitoId}
            handleSearch={handleSearch}
            size={size}
            isRequired={isRequired}
            isDeleted={isDeleted}
            limitOrganization={limitOrganization}
        />
    </RoleGuard>
);

const Filter = ({
    defaultValueId,
    defaultValueCognitoId,
    handleSearch,
    size = 'small',
    isRequired = false,
    isDeleted = false,
    limitOrganization
}: BrokerFilterProps) => {
    const intl = useIntl();

    const { brokersMinimal, listLoading } = useSelector((state) => state.brokers);
    const [selectedBroker, setSelectedBroker] = useState<MinimalBrokersInterface | null>(null);
    const [newValue, setNewValue] = useState<string>('');

    const handleInputChange = (v: string) => {
        setNewValue(v);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(queryMinimalBrokers({ queryData: { id: defaultValueId } }));
        } else if (defaultValueCognitoId) {
            dispatch(queryMinimalBrokers({ queryData: { cognitoId: defaultValueCognitoId } }));
        } else {
            setSelectedBroker(null);
            dispatch(
                queryMinimalBrokers({ queryData: { name: newValue, isRegistered: true, isDeleted, organizationId: limitOrganization } })
            );
        }
    }, [defaultValueId, defaultValueCognitoId, newValue, isDeleted, limitOrganization]);

    useEffect(() => {
        if (brokersMinimal.data.length > 0 && (defaultValueId || defaultValueCognitoId)) {
            const broker = brokersMinimal.data.find((b) => b.id === defaultValueId || b.cognitoId === defaultValueCognitoId);
            setSelectedBroker(broker || null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brokersMinimal, defaultValueId, defaultValueCognitoId]);

    return (
        <Autocomplete
            id="autocomplete-generic-filter-brokers"
            fullWidth
            value={selectedBroker}
            size={size}
            options={brokersMinimal.data}
            loading={listLoading}
            onChange={(event, option) => {
                setSelectedBroker(option || null);
                handleSearch(option || null);
            }}
            loadingText={intl.formatMessage({ id: 'loading-brokers' })}
            inputValue={newValue}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option, v) => (defaultValueCognitoId ? option.id === v.id : option.cognitoId === v.cognitoId)}
            getOptionLabel={(option) => option?.user?.name || ''}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid item xs={12} pl={1}>
                        <Typography>{option?.user?.name}</Typography>
                        <Typography variant="subtitle2">{option?.organization?.name}</Typography>
                    </Grid>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-brokers"
                    label={`${intl.formatMessage({ id: 'partner' })} ${intl.formatMessage({ id: 'agent' })} ${isRequired ? '*' : ''}`}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};

export default BrokerFilter;
