import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryFlowsInterface } from './interfaces';

const FLOWS_APIS = {
    query: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '?stages=Reservation&stages=SaleClaim&stages=EOI&stages=Canceled&'];
        return formattedQuery(formatQuery, query);
    },

    queryPrimaryCilsFlows: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '?'];
        return formattedQuery(formatQuery, query);
    },
    queryAnalyticaMin: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '/analytica-min', '?'];
        return formattedQuery(formatQuery, query);
    },
    queryAnalyticaFull: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '/analytica-full', '?'];
        return formattedQuery(formatQuery, query);
    },
    topDevelopers: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '/top-developer', '?'];
        return formattedQuery(formatQuery, query);
    },
    topCompounds: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '/top-compounds', '?'];
        return formattedQuery(formatQuery, query);
    },
    topPartners: (query: QueryFlowsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '/top-partners', '?'];
        return formattedQuery(formatQuery, query);
    },
    updateStatus: (stageId: number) => `${FAUNA_API}stage/${stageId}/status`,
    createPrimaryCil: () => `${FAUNA_API}flows/primary-cil`,
    createEOI: () => `${FAUNA_API}flows/eoi`,
    createReservation: () => `${FAUNA_API}flows/reservation`,
    createSaleClaim: () => `${FAUNA_API}flows/sale-claim`,
    update: (id: number) => `${FAUNA_API}flows/${id}`,
    delete: (id: number) => `${FAUNA_API}flows/${id}`,
    getById: (id: number) => `${FAUNA_API}flows/${id}`,
    createComment: (id: number) => `${FAUNA_API}flows/${id}/comments`,
    updateComment: (id: number, commentId: string) => `${FAUNA_API}flows/${id}/comments/${commentId}`,
    deleteComment: (id: number, commentId: string) => `${FAUNA_API}flows/${id}/comments/${commentId}`,
    uploadDocument: (id: number) => `${FAUNA_API}flows-documents/${id}`,
    deleteDocument: (id: number, documentId: number) => `${FAUNA_API}flows-documents/${id}/document/${documentId}`
};

export default FLOWS_APIS;
