import { FiberNew } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const DevelopersContacts: NavItemType = {
    id: 'developers-contacts',
    title: <FormattedMessage id="developers-contacts" />,
    type: 'item',
    url: '/contacts',
    // icon: ContactPageOutlinedIcon,
    icon: FiberNew,
    breadcrumbs: false,
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' },
        { name: InvitationTypeEnum.developerRelationsManagers }
    ],
    mixpanelEvent: {
        tab_name: 'Developers Contacts'
    }
};
const DevelopersContactsItem: NavItemType = {
    id: 'developers-contacts',
    type: 'group',
    children: [DevelopersContacts]
};

export default DevelopersContactsItem;
