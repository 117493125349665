// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';

const commonItemsTop: NavItemType = {
    id: 'common-side-menu-items-top',
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            url: '/home',
            icon: HomeTwoToneIcon,
            breadcrumbs: false,
            mixpanelEvent: {
                tab_name: 'Home'
            }
        }
    ]
};

export default commonItemsTop;
