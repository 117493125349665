// hooks
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';

// material ui
import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// third party
import * as yup from 'yup';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';

// interfaces
import { InvitationTypeEnum } from 'store/interfaces';

// actions
import { openSnackbar } from 'store/slices/snackbar';
import { createInvitation } from 'store/slices/invitations/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { MuiTelInput } from 'mui-tel-input';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';

export default function CreateInvitation({ crm }: { crm: CRMControlInterface }) {
    const intl = useIntl();

    const CreateInvitationSchema = yup.object().shape({
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        type: yup.string().equals([InvitationTypeEnum.internalAgents])
    });
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.invitations);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'invitation-sent-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            phone: '',
            type: InvitationTypeEnum.internalAgents
        },
        onSubmit(values, formikHelpers) {
            const isPhoneValid = parsePhoneNumber(values.phone, 'EG');
            if (!isPhoneValid?.isValid()) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }
            if (isPhoneValid.country)
                dispatch(
                    createInvitation({
                        createData: {
                            phone: values.phone,
                            countryCode: isPhoneValid.country,
                            type: InvitationTypeEnum.internalAgents,
                            crmId: crm.id
                        },
                        runSuccess
                    })
                );
        },
        validationSchema: CreateInvitationSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            <Button fullWidth variant="contained" color="secondary" size="medium" onClick={handleClickOpen}>
                <FormattedMessage id="invite-user" />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                aria-labelledby="Invite User"
                aria-describedby="Invite other users to join this"
            >
                <DialogTitle id="invite-user-dialog">
                    <FormattedMessage id="invite-user" />
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight={400}>
                                    <FormattedMessage id="CRM" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{crm.name}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight={400}>
                                    <FormattedMessage id="user-group" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormattedMessage id="internalAgent" />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogContent>
                    <MuiTelInput
                        dir="ltr"
                        fullWidth
                        disabled={formik.isSubmitting}
                        id="invite-user-phone"
                        name="phone"
                        forceCallingCode
                        focusOnSelectCountry
                        excludedCountries={['IL']}
                        disableFormatting
                        defaultCountry="EG"
                        label={`${intl.formatMessage({ id: 'phone' })} *`}
                        value={formik.values.phone}
                        onChange={(v) => formik.setFieldValue('phone', v)}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton
                        loading={formik.isSubmitting}
                        onClick={formik.submitForm}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >
                        <FormattedMessage id="invite" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
