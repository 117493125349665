import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import { useIntl } from 'react-intl';
import { Grid, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface UsersSelectFilterProps {
    value: string | undefined;
    label?: string;
    handleSearch: (newValue: string | null) => void;
}

const UsersPhoneFilter = ({ value, label, handleSearch }: UsersSelectFilterProps) => (
    <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
        <Filter label={label} value={value} handleSearch={handleSearch} />
    </RoleGuard>
);

const Filter = ({ value, label, handleSearch }: UsersSelectFilterProps) => {
    const intl = useIntl();

    const handleChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newString = event?.target?.value;
        handleSearch(newString!);
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={value}
                    placeholder={intl.formatMessage({ id: 'phone' })}
                    size="small"
                    fullWidth
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    );
};

export default UsersPhoneFilter;
