import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import DashboardWidgetSection from './WidgetSection';
import { FlowStagesEnum } from 'store/slices/partnersDashboard/interfaces';
import BarChart from '../Charts/Bar';
import verticalBarConfig, { ChartDataInterface } from '../Charts/Bar/vertical-config';
import { Props } from 'react-apexcharts';
import horizontalBarConfig from '../Charts/Bar/horizontal-config';

export interface SalesPipelineSectionInterface {
    topDevelopers: ChartDataInterface;
    developersLoading: boolean;
    compoundLoading: boolean;
    topCompounds: ChartDataInterface;
    flowStage: FlowStagesEnum;
    handleFlowStageChange: (flowStage: FlowStagesEnum) => void;
    showMore: boolean;
    handleShow: (pageSize: number) => void;
}

const SalesPipelineSection = ({
    topDevelopers,
    developersLoading,
    compoundLoading,
    topCompounds,
    showMore,
    flowStage,
    handleFlowStageChange,
    handleShow
}: SalesPipelineSectionInterface) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    if (isMediumScreen)
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h3">
                        {intl.formatMessage({ id: 'sales-pipeline' })}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <SalesPipelineSectionContent
                        developersData={verticalBarConfig(topDevelopers)}
                        developersLoading={developersLoading}
                        compoundLoading={compoundLoading}
                        compoundData={verticalBarConfig(topCompounds)}
                        showMore={showMore}
                        flowStage={flowStage}
                        handleFlowStageChange={handleFlowStageChange}
                        handleShow={handleShow}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            </Grid>
        );
    return (
        <MainCard title={intl.formatMessage({ id: 'sales-pipeline' })} content={false} titleBorder={false}>
            <SalesPipelineSectionContent
                developersData={horizontalBarConfig(topDevelopers)}
                developersLoading={developersLoading}
                compoundLoading={compoundLoading}
                compoundData={horizontalBarConfig(topCompounds)}
                showMore={showMore}
                flowStage={flowStage}
                handleFlowStageChange={handleFlowStageChange}
                handleShow={handleShow}
            />
        </MainCard>
    );
};

export interface SalesPipelineSectionContentInterface {
    developersData: Props;
    developersLoading: boolean;
    compoundLoading: boolean;
    compoundData: Props;
    flowStage: FlowStagesEnum;
    handleFlowStageChange: (flowStage: FlowStagesEnum) => void;
    showMore: boolean;
    handleShow: (pageSize: number) => void;
}

const SalesPipelineSectionContent = ({
    developersData,
    developersLoading,
    compoundLoading,
    compoundData,
    showMore,
    flowStage,
    handleFlowStageChange,
    handleShow
}: SalesPipelineSectionContentInterface) => {
    const intl = useIntl();
    const theme = useTheme();
    return (
        <Grid container>
            <Grid item px={{ xs: 0, sm: 2, md: 0 }} xs={12}>
                <DashboardWidgetSection flowStage={flowStage} handleFlowStageChange={handleFlowStageChange} />
            </Grid>
            <Grid item px={{ xs: 0, md: 2 }} pt={2} xs={12} md={6}>
                <BarChart isLoading={developersLoading} title={intl.formatMessage({ id: 'developers' })} chartData={developersData} />
            </Grid>
            <Grid item px={{ xs: 0, md: 2 }} pt={2} xs={12} md={6}>
                <BarChart isLoading={compoundLoading} title={intl.formatMessage({ id: 'compounds' })} chartData={compoundData} />
            </Grid>
            <Grid item display={{ xs: 'none', md: 'block' }} xs={12}>
                <Divider style={{ height: '2px' }} color={theme.palette.primary.main} />
            </Grid>
            <Grid item display={{ xs: 'none', md: 'flex' }} xs={12} p={2} container justifyContent="center" alignItems="center">
                <Button endIcon={!showMore ? <ExpandLessIcon /> : <ExpandMoreIcon />} onClick={() => handleShow(showMore ? 10 : 5)}>
                    {showMore ? <FormattedMessage id="show-more" /> : <FormattedMessage id="show-less" />}
                </Button>
            </Grid>
        </Grid>
    );
};
export default SalesPipelineSection;
