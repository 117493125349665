import { FormattedMessage } from 'react-intl';
import { NavItemType } from 'types';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';

const GrowWithPartners: NavItemType = {
    id: 'grow-with-partners',
    title: <FormattedMessage id="grow-with-partners" />,
    type: 'item',
    url: '/grow-with-partners',
    icon: TrendingUpIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.brokers, roles: [OrganizationRole.owner] }]
};

const GrowWithPartnersItem: NavItemType = {
    id: 'grow-with-partners',
    type: 'group',
    children: [GrowWithPartners]
};

export default GrowWithPartnersItem;
