import { SIRENS_API, formattedQuery, FAUNA_API } from 'store/apis';
import { SystemUserQueryInterface, UserQueryInterface } from './interfaces';

const COLLECTION_TITLE = 'users';

const USERS_APIS = {
    query: (query: UserQueryInterface) => {
        const formatQuery: string[] = [SIRENS_API, COLLECTION_TITLE, '?'];
        return formattedQuery(formatQuery, query);
    },
    create: () => `${SIRENS_API}users`,
    findOne: (id: string) => `${SIRENS_API}users/${id}`,
    update: (id: string) => `${SIRENS_API}users/${id}`,
    updateEmail: (id: string) => `${SIRENS_API}users/${id}/email`,
    verifyEmail: (id: string) => `${SIRENS_API}users/${id}/verify-email`,
    changeUserActivity: (id: string) => `${SIRENS_API}users/${id}/changeUserActivity`,
    /**
     * @deprecated
     * @param id
     * @returns
     */
    changeUserGroup: (id: string) => `${SIRENS_API}users/${id}/changeUserGroup`,
    /**
     * @PUT
     * @important Not used for brokers type
     * @param id
     * @param groupName
     * @returns
     */
    addUserToGroup: (id: string, groupName: string) => `${SIRENS_API}users/${id}/groups/${groupName}`,
    /**
     * @DELETE
     * @param id
     * @returns
     */
    removeUserFromGroup: (id: string, groupName: string) => `${SIRENS_API}users/${id}/groups/${groupName}`,
    updateUserLimits: (id: string) => `${SIRENS_API}users/${id}/limits`
};

export const INVENTORY_MANAGERS_APIS = {
    findOne: (id: string) => `${FAUNA_API}inventory-managers/${id}`,
    updateRole: (id: string) => `${FAUNA_API}inventory-managers/${id}`
};

export const SYSTEM_USERS_APIS = {
    query: (query: SystemUserQueryInterface) => {
        const formatQuery: string[] = [FAUNA_API, COLLECTION_TITLE, '?'];
        return formattedQuery(formatQuery, query);
    },
    sendBatchNotification: () => `${FAUNA_API}users`
};

export default USERS_APIS;
