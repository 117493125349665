import { createSlice } from '@reduxjs/toolkit';
import { CRMControlInitialState } from './interfaces';
import { createCRM, findCRM, queryCRMs, updateCRM } from './actions';

const initialState: CRMControlInitialState = {
    loading: false,
    listRefresh: false,
    listLoading: false,
    error: false,
    listError: false,
    refresh: false,
    crms: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - CRM CONTROL ||============================== //
const crmControl = createSlice({
    name: 'crms',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryCRMs.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryCRMs.fulfilled, (state, action) => {
                state.listLoading = false;
                state.crms = action.payload;
            })
            .addCase(queryCRMs.rejected, (state, action) => {
                state.listLoading = false;
                state.error = action.payload;
            })
            .addCase(createCRM.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createCRM.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.error = false;
            })
            .addCase(createCRM.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(findCRM.pending, (state) => {
                state.loading = true;
                state.listError = false;
            })
            .addCase(findCRM.fulfilled, (state, action) => {
                state.listLoading = false;
                state.crm = action.payload;
            })
            .addCase(findCRM.rejected, (state, action) => {
                state.listLoading = false;
                state.error = action.payload;
            })
            .addCase(updateCRM.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(updateCRM.fulfilled, (state) => {
                state.listLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateCRM.rejected, (state, action) => {
                state.listLoading = false;
                state.error = action.payload;
            });
    }
});
export default crmControl.reducer;
