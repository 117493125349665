import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import FLOWS_APIS from './apis';
import {
    CreateFlowEoiInterface,
    CreateFlowPrimaryCilInterface,
    CreateFlowReservationInterface,
    CreateFlowSaleClaimInterface,
    FlowStagesEnum,
    QueryFlowsInterface,
    UpdateFlowInterface,
    UpdateFlowStageStatusInterface
} from './interfaces';

export const queryFlows = createAsyncThunk(
    'flows/query',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(FLOWS_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryPrimaryCilsFlows = createAsyncThunk(
    'flows/primary-cils',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const result = await axiosServices.get(FLOWS_APIS.queryPrimaryCilsFlows(query));
            if (runSuccess) runSuccess();
            return result.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const updateFlow = createAsyncThunk(
    'flows/update-flow',
    async (
        requestData: {
            flowId: number;
            body: UpdateFlowInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, body, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(FLOWS_APIS.update(flowId), body);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const uploadFlowDocument = createAsyncThunk(
    'flows/upload-document',
    async (
        requestData: {
            flowId: number;
            document: {
                name: string;
                type: string;
                document: any;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, document, runSuccess } = requestData;

        const formData = new FormData();
        formData.append('name', document?.name);
        formData.append('type', document?.type);
        formData.append('document', document.document);
        try {
            const successRes = await axiosServices.post(FLOWS_APIS.uploadDocument(flowId), formData, {
                headers: { 'Content-Type': `multipart/form-data;` }
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const deleteFlowDocument = createAsyncThunk(
    'flows/delete-document',
    async (
        requestData: {
            flowId: number;
            documentId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, documentId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.delete(FLOWS_APIS.deleteDocument(flowId, documentId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getFlowAnalyticaEOI = createAsyncThunk(
    'flows/analytica-min/eoi',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;

        const newQuery = { ...query, stage: FlowStagesEnum.EOI };

        try {
            const successRes = await axiosServices.get(FLOWS_APIS.queryAnalyticaMin(newQuery));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getFlowAnalyticaReservation = createAsyncThunk(
    'flows/analytica-min/reservation',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;

        const newQuery = { ...query, stage: FlowStagesEnum.Reservation };
        try {
            const successRes = await axiosServices.get(FLOWS_APIS.queryAnalyticaMin(newQuery));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const getFlowAnalyticaSaleClaim = createAsyncThunk(
    'flows/analytica/sale-claims',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;

        const newQuery = { ...query, stage: FlowStagesEnum.SaleClaim };

        try {
            const successRes = await axiosServices.get(FLOWS_APIS.queryAnalyticaFull(newQuery));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getFlowById = createAsyncThunk(
    'flows/get-by-id',
    async (
        requestData: {
            flowId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(FLOWS_APIS.getById(flowId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateFlowStatus = createAsyncThunk(
    'flow/update-status',
    async (
        requestData: {
            stageId: number;
            body: UpdateFlowStageStatusInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { stageId, body, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.post(FLOWS_APIS.updateStatus(stageId), body);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createFlowPrimaryCil = createAsyncThunk(
    'flow/create-primary-cil',
    async (
        requestData: {
            body: CreateFlowPrimaryCilInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { body, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.post(FLOWS_APIS.createPrimaryCil(), body);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createFlowEOI = createAsyncThunk(
    'flow/create-EOI',
    async (
        requestData: {
            body: CreateFlowEoiInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { body, runSuccess } = requestData;
        try {
            const { documents, ...rest } = body;
            const formData = new FormData();

            if (documents) {
                for (const document of documents) {
                    formData.append('files', document as any);
                }
            }
            formData.append('json', JSON.stringify(rest));

            const successRes = await axiosServices.post(FLOWS_APIS.createEOI(), formData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createFlowReservation = createAsyncThunk(
    'flow/create-reservation',
    async (
        requestData: {
            body: CreateFlowReservationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { body, runSuccess } = requestData;

        try {
            const { documents, ...rest } = body;
            const formData = new FormData();

            if (documents) {
                for (const document of documents) {
                    formData.append('files', document as any);
                }
            }
            formData.append('json', JSON.stringify(rest));

            const successRes = await axiosServices.post(FLOWS_APIS.createReservation(), formData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createFlowSaleClaim = createAsyncThunk(
    'flow/create-sale-claim',
    async (
        requestData: {
            body: CreateFlowSaleClaimInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { body, runSuccess } = requestData;

        try {
            const { documents, ...rest } = body;
            const formData = new FormData();

            if (documents) {
                for (const document of documents) {
                    formData.append('files', document as any);
                }
            }
            formData.append('json', JSON.stringify(rest));

            const successRes = await axiosServices.post(FLOWS_APIS.createSaleClaim(), formData, {
                headers: { 'Content-Type': `multipart/form-data;`, Accept: 'application/json' }
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const deleteFlowComment = createAsyncThunk(
    'flow/comment/delete',
    async (
        requestData: {
            commentId: string;
            flowId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { commentId, flowId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.delete(FLOWS_APIS.deleteComment(flowId, commentId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createComment = createAsyncThunk(
    'flow/comment/create',
    async (
        requestData: {
            flowId: number;
            text: string;
            isPrivate: boolean;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, text, isPrivate, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.post(FLOWS_APIS.createComment(flowId), { text, isPrivate });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const updateComment = createAsyncThunk(
    'flow/comment/update',
    async (
        requestData: {
            flowId: number;
            commentId: string;
            text?: string;
            isPrivate?: boolean;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { commentId, flowId, text, isPrivate, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(FLOWS_APIS.updateComment(flowId, commentId), { text, isPrivate });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteFlow = createAsyncThunk(
    'flow/delete',
    async (
        requestData: {
            flowId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.delete(FLOWS_APIS.delete(flowId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
