import { DialogContent, DialogContentText, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
// third party libraries
import * as yup from 'yup';

import { useDispatch, useSelector } from 'store';
import { gridSpacing, saleClaimDialogHeight } from 'store/constant';
import { setPropertyInfo } from 'store/slices/createSaleClaim';
import { crmPropertyTypes } from 'store/slices/general/interfaces';
import NumericFormatCustom from 'ui-component/MoneyInput';

import CompoundFilter from 'ui-component/Filters/CompoundFilter';
import PropertyTypeSelector from 'ui-component/Filters/PropertyTypeSelect';
import { CreateSaleClaimSectionProps } from '.';
import StepperDialogActions from './StepperDialogActions';
import useMixPanel from 'hooks/useMixPanel';

const PropertyInfoSection = ({ handleNext, handleBack }: CreateSaleClaimSectionProps) => {
    const dispatch = useDispatch();
    const { property } = useSelector((state) => state.createSaleClaimSlice);
    const { emitEvent } = useMixPanel();
    const intl = useIntl();
    const SetPropertySchema = yup.object().shape({
        compoundId: yup.number().required(`${intl.formatMessage({ id: 'compound' })} ${intl.formatMessage({ id: 'required' })}`),
        propertyTypeId: yup.number().required(intl.formatMessage({ id: 'property-type-required' })),
        unitValue: yup
            .number()
            .integer(intl.formatMessage({ id: 'integer-number-required' }))
            .required(intl.formatMessage({ id: 'unit-value-required' })),
        unitId: yup.string().required(intl.formatMessage({ id: 'unit-id-required' }))
    });
    const formik = useFormik({
        initialValues: {
            developerName: property?.developerName,
            compoundId: property?.compoundId,
            compoundName: property?.compoundName,
            propertyTypeId: property?.propertyTypeId,
            unitValue: property?.unitValue,
            unitId: property?.unitId
        },
        onSubmit(values, formikHelpers) {
            dispatch(
                setPropertyInfo({
                    ...values,
                    unitValue: values?.unitValue ? parseInt(values.unitValue.toString(), 10) : 0
                })
            );
            handleNext();
        },
        validationSchema: SetPropertySchema,
        enableReinitialize: true
    });

    return (
        <div>
            <DialogContent
                sx={{
                    height: saleClaimDialogHeight
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <DialogContentText variant="h4">
                            <FormattedMessage id="property-info" />
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <CompoundFilter
                            defaultValueId={formik.values.compoundId}
                            size="medium"
                            required
                            error={Boolean(formik.touched.compoundId && formik.errors.compoundId)}
                            helperText={formik.touched.compoundId && formik.errors.compoundId}
                            handleSelectedCompound={(selectedCompound) => {
                                if (selectedCompound) {
                                    formik.setFieldValue('compoundId', selectedCompound.id);
                                    formik.setFieldValue('compoundName', selectedCompound.name);
                                    formik.setFieldValue('developerId', selectedCompound.developerId);
                                    formik.setFieldValue('developerName', selectedCompound.developer.name);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'unit-code' })} *`}
                            name="unitId"
                            id="set-sale-property-info-unit-id"
                            value={formik.values.unitId || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.unitId && formik.errors.unitId)}
                            helperText={formik.touched.unitId && formik.errors.unitId}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            InputProps={{
                                inputComponent: NumericFormatCustom as any
                            }}
                            label={`${intl.formatMessage({ id: 'price' })} *`}
                            name="unitValue"
                            id="set-sale-property-info-price"
                            value={formik.values.unitValue || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.unitValue && formik.errors.unitValue)}
                            helperText={formik.touched.unitValue && formik.errors.unitValue}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <PropertyTypeSelector
                            defaultOption={
                                property?.propertyTypeId ? crmPropertyTypes.find((x) => x.id === property?.propertyTypeId) : null
                            }
                            onChangeHandler={(newValue) => {
                                formik.setFieldValue('propertyTypeId', newValue?.id);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <StepperDialogActions
                handleBack={handleBack}
                handleNext={() => {
                    emitEvent('Click 1st Next Sale Claim Creation', { ...formik.values, error: Boolean(formik.errors) });
                    formik.submitForm();
                }}
            />
        </div>
    );
};

export default PropertyInfoSection;
