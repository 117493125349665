import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import AccountManagersTeamsMultiSelect, { SelectedTeamWithMembers } from 'components/Organizations/UI/List/AccountManagersTeamsMultiSelect';

import useOverlay from 'hooks/useOverlay';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReassignAccountManagerDialogPayload } from './ReassignAccountManagerOnOrganizationsDialog';

interface Payload {
    accountManagerId: string;
    organizationIds: string[];
}

const ReassignAccountManagerDialog = () => {
    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<Payload>('ReassignAccountManagerOrganizationsDialog');
    const { open } = useOverlay<ReassignAccountManagerDialogPayload>();
    const [selectedTeams, setSelectedTeams] = useState<SelectedTeamWithMembers[]>([]);

    const handleClose = () => {
        close();
    };

    const handleSelectionChange = useCallback((teams: SelectedTeamWithMembers[]) => {
        setSelectedTeams(teams);
    }, []);

    const handleOnAdd = () => {
        close();
        open('ReassignAccountManagerDialog', {
            accountManagerId: payload?.accountManagerId!,
            organizationIds: payload?.organizationIds!,
            selectedTeams
        });
    };

    return (
        <Dialog open={isCurrentOpened} onClose={handleClose} aria-labelledby="" aria-describedby="" fullWidth maxWidth="xs">
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage id="reassign-partner" />
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{ mb: 2, color: 'text.secondary' }}>
                    <FormattedMessage id="choose-teams-to-reassign-partners-to" />
                </Typography>
                <AccountManagersTeamsMultiSelect onSelectionChange={handleSelectionChange} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button variant="contained" onClick={handleOnAdd} disabled={selectedTeams.length === 0} autoFocus>
                    <FormattedMessage id="add" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReassignAccountManagerDialog;
