import { formattedQuery, FAUNA_API } from 'store/apis';
import { QueryLeadRequests } from './interfaces';

const COLLECTION_TITLE = 'lead-requests';

const LEAD_REQUESTS_APIS = {
    query: (query: QueryLeadRequests) => {
        const formatQuery: string[] = [FAUNA_API, COLLECTION_TITLE, '?'];
        return formattedQuery(formatQuery, query);
    },
    getById: (id: string) => `${FAUNA_API}${COLLECTION_TITLE}/${id}`,
    create: () => `${FAUNA_API}lead-requests`
};

export default LEAD_REQUESTS_APIS;
