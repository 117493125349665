import { LoadingButton } from '@mui/lab';
// material ui
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';

import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as yup from 'yup';

import { useDispatch, useSelector } from 'store';
// constants
import { gridSpacing } from 'store/constant';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
// action
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { createPrimaryCils } from 'store/slices/primaryCils/actions';

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import useMixPanel from 'hooks/useMixPanel';
import { queryCompounds } from 'store/slices/developers/actions';
import { Compound } from 'store/slices/developers/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import FabItemBtn from 'ui-component/FabItemBtn';
import BrokerFilter from 'ui-component/Filters/BrokersFilter';
import ComponentRoleGuard from 'utils/route-guard/ComponentRoleGuard';

interface CreatePrimaryCilBtnProps {
    location: string;
    fullWidth?: boolean;
    variant?: 'contained' | 'outlined';
    btnVariant?: 'fab' | 'btn';
}

export const CreatePrimaryCilMenuItem = ({ fullWidth, variant, location, btnVariant }: CreatePrimaryCilBtnProps) => {
    const { emitEvent } = useMixPanel();
    const [openCreatePrimaryCilDialog, setOpenCreatePrimaryCilDialog] = useState(false);

    const handleOpen = () => {
        emitEvent('Click Add Primary CIL', { location });
        setOpenCreatePrimaryCilDialog(true);
    };
    return (
        <div>
            <MenuItem
                onClick={(event) => {
                    event.stopPropagation();
                    handleOpen();
                }}
            >
                <Typography>
                    <FormattedMessage id="send-cil" />
                </Typography>
            </MenuItem>
            <CreatePrimaryCilDialog open={openCreatePrimaryCilDialog} handleClose={() => setOpenCreatePrimaryCilDialog(false)} />
        </div>
    );
};

const CreatePrimaryCilBtn = ({ fullWidth, variant, location, btnVariant = 'btn' }: CreatePrimaryCilBtnProps) => {
    const [openCreatePrimaryCilDialog, setOpenCreatePrimaryCilDialog] = useState(false);
    const { emitEvent } = useMixPanel();

    const handleOpen = () => {
        emitEvent('Click Add Primary CIL', { location });
        setOpenCreatePrimaryCilDialog(true);
    };
    const intl = useIntl();
    return (
        <div>
            {btnVariant === 'btn' ? (
                <Button fullWidth={fullWidth} variant={variant || 'contained'} startIcon={<AddCircleTwoToneIcon />} onClick={handleOpen}>
                    <FormattedMessage id="add-primary-cil" />
                </Button>
            ) : (
                <FabItemBtn title={intl.formatMessage({ id: 'add-primary-cil' })} icon={<AddCircleTwoToneIcon />} onClick={handleOpen} />
            )}
            <CreatePrimaryCilDialog open={openCreatePrimaryCilDialog} handleClose={() => setOpenCreatePrimaryCilDialog(false)} />
        </div>
    );
};

function CreatePrimaryCilDialog({ compoundId, open, handleClose }: { compoundId?: number; open: boolean; handleClose: () => void }) {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();

    const CreatePrimaryCilSchema = yup.object().shape({
        clientFirstName: yup.string().required(intl.formatMessage({ id: 'first-name-required' })),
        clientLastName: yup.string().required(intl.formatMessage({ id: 'last-name-required' })),
        clientPhone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'client-phone-invalid' }))
            .required(intl.formatMessage({ id: 'client-phone-required' })),
        brokerId: yup.string(),
        compoundId: yup.number()
    });

    const dispatch = useDispatch();
    const [compoundsSearchQuery, setCompoundsSearchQuery] = useState('');
    const [selectCompound, setSelectCompound] = useState<Compound | null>(null);

    const [compoundAutocompleteVisible, setCompoundsAutocompleteVisible] = useState(false);

    const { error } = useSelector((state) => state.primaryCils);
    const { compounds, compoundsLoading } = useSelector((state) => state.developers);

    const handleCompoundsAutocompleteOpen = () => {
        setCompoundsAutocompleteVisible(true);
    };

    useEffect(() => {
        if (compoundAutocompleteVisible) dispatch(queryCompounds({ query: { name: compoundsSearchQuery, allowPrimaryCil: true } }));
    }, [dispatch, compoundAutocompleteVisible, compoundsSearchQuery]);

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const runSuccess = () => {
        formik.setSubmitting(false);
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'primary-cil-created-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const formik = useFormik({
        initialValues: {
            clientFirstName: '',
            clientLastName: '',
            clientPhone: '',
            brokerId: '',
            compoundId: compoundId || 0
        },
        onSubmit(values, formikHelpers) {
            const isPhoneValid = values.clientPhone ? parsePhoneNumber(values.clientPhone, 'EG') : undefined;
            if (!isPhoneValid?.isValid()) {
                formikHelpers.setFieldError('clientPhone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }
            const fullName = `${values.clientFirstName} ${values.clientLastName}`;
            emitEvent('Click Confirm Primary CIL Creation', {
                clientName: fullName,
                clientPhone: values.clientPhone,
                brokerId: values.brokerId,
                compoundId: values.compoundId
            });

            formikHelpers.setSubmitting(true);
            dispatch(
                createPrimaryCils({
                    data: {
                        clientName: fullName,
                        clientPhone: values.clientPhone,
                        brokerId: values.brokerId,
                        compoundId: values.compoundId
                    },
                    runSuccess
                })
            );
        },
        validationSchema: CreatePrimaryCilSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="Create-CIL"
                aria-describedby="create cil to the crm"
            >
                <DialogTitle id="create-CIL-dialog">
                    <FormattedMessage id="create-primary-cil" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={`${intl.formatMessage({ id: 'client-first-name' })} *`}
                                name="clientFirstName"
                                id="add-client-first-name"
                                type="text"
                                value={formik.values.clientFirstName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.clientFirstName && formik.errors.clientFirstName)}
                                helperText={formik.touched.clientFirstName && formik.errors.clientFirstName}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={`${intl.formatMessage({ id: 'client-last-name' })} *`}
                                name="clientLastName"
                                id="add-client-last-name"
                                type="text"
                                value={formik.values.clientLastName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.clientLastName && formik.errors.clientLastName)}
                                helperText={formik.touched.clientLastName && formik.errors.clientLastName}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <MuiTelInput
                                dir="ltr"
                                fullWidth
                                disabled={formik.isSubmitting}
                                id="client-phone"
                                name="clientPhone"
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                defaultCountry="EG"
                                excludedCountries={['IL']}
                                label={`${intl.formatMessage({ id: 'client-phone' })} *`}
                                value={formik.values.clientPhone}
                                onChange={(v) => formik.setFieldValue('clientPhone', v)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.clientPhone && formik.errors.clientPhone)}
                                helperText={formik.touched.clientPhone && formik.errors.clientPhone}
                            />
                        </Grid>

                        {!compoundId ? (
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        autoHighlight
                                        id="compoundId"
                                        options={compounds}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        getOptionLabel={(option) => option?.name}
                                        onChange={(event, newValue) => {
                                            setSelectCompound(newValue);
                                            formik.setFieldValue('compoundId', newValue?.id);
                                        }}
                                        value={selectCompound}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                                                {option.name}
                                            </Box>
                                        )}
                                        onInputChange={(event, value) => setCompoundsSearchQuery(value)}
                                        onBlur={formik.handleBlur}
                                        loading={compoundsLoading}
                                        onOpen={handleCompoundsAutocompleteOpen}
                                        loadingText={intl.formatMessage({ id: 'loading-compounds' })}
                                        filterOptions={(x) => x}
                                        renderInput={(params) => (
                                            <TextField {...params} label={`${intl.formatMessage({ id: 'compound' })} *`} name="compound" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        ) : null}

                        <ComponentRoleGuard
                            allowedAudience={[
                                AccountManagersRole.member,
                                AccountManagersRole.supervisor,
                                AccountManagersRole.head,
                                OrganizationRole.owner,
                                OrganizationRole.manager
                            ]}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    <BrokerFilter
                                        size="medium"
                                        isRequired
                                        defaultValueId={formik.values.brokerId}
                                        handleSearch={(newValue) => {
                                            formik.setFieldValue('brokerId', newValue?.id);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </ComponentRoleGuard>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton
                        onClick={formik.submitForm}
                        loading={formik.isSubmitting}
                        size="small"
                        color="secondary"
                        variant="contained"
                    >
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default CreatePrimaryCilBtn;
