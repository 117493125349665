import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useMixPanel from 'hooks/useMixPanel';

interface StringSearchProps {
    value?: string;
    label?: string;
    location: string;
    placeHolder?: string;
    handleChange: (value: string) => void;
}

const StringSearchFilter = ({ value, label, placeHolder, location, handleChange }: StringSearchProps) => {
    const { emitEvent } = useMixPanel();

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newString = event.target.value;
        handleChange(newString);
    };

    const handleFilterClick = () => emitEvent('Click Free Text filter', { location, label });

    return (
        <TextField
            fullWidth
            label={placeHolder && !value ? undefined : label}
            size="small"
            value={value}
            onClick={handleFilterClick}
            onChange={handleSearch}
            placeholder={placeHolder || label}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                    </InputAdornment>
                )
            }}
        />
    );
};

export default StringSearchFilter;
