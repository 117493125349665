import { formattedQuery, FAUNA_API, SIRENS_API, TASKEEN_EGYPT_API } from 'store/apis';
import { QueryLeads } from './interfaces';

const LEADS_APIS = {
    query: (query: QueryLeads) => {
        const formatQuery: string[] = [FAUNA_API, 'leads/search', '?'];
        return formattedQuery(formatQuery, query);
    },
    analytica: () => `${FAUNA_API}leads/analytica`,
    createPartnerAsLead: () => `${SIRENS_API}leads/create-partner-lead`,
    createPartnerAsLeadV2: () => `${SIRENS_API}leads/create-partner-lead-V2`,
    // createTaskeenLead: () => `${TASKEEN_EGYPT_API}/leads?api_key=rnprrhubgmmdscrqnsuwhyhjurpcru`
    createTaskeenLead: () => `${SIRENS_API}leads/create-taskeen-lead`
};
export default LEADS_APIS;
