// material ui
import { DialogContent, DialogContentText, FormControl, Grid } from '@mui/material';
// third party
import { useFormik } from 'formik';
// hooks

import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import { useDispatch } from 'store';
// constants
import { gridSpacing, saleClaimDialogHeight } from 'store/constant';

// interface

import { setBrokerDetails } from 'store/slices/createSaleClaim';

import { CreateSaleClaimSectionProps } from '.';

// components
import useMixPanel from 'hooks/useMixPanel';
import BrokerCognitoFilter from 'ui-component/Filters/BrokersFilter/V2/BrokerCognitoFilter';
import StepperDialogActions from './StepperDialogActions';

const BrokerSection = ({ handleNext, handleBack, limitOrganization, flow }: CreateSaleClaimSectionProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { emitEvent } = useMixPanel();

    const AddSaleBrokerAndOrganizationSchema = yup.object().shape({
        brokerId: yup.string().required(`${intl.formatMessage({ id: 'partner-agent' })} ${intl.formatMessage({ id: 'is-required' })}`),
        brokerName: yup.string().required(`${intl.formatMessage({ id: 'partner-agent' })} ${intl.formatMessage({ id: 'is-required' })}`),
        organizationId: yup
            .string()
            .required(`${intl.formatMessage({ id: 'partner-agent' })} ${intl.formatMessage({ id: 'is-required' })}`),
        organizationName: yup
            .string()
            .required(`${intl.formatMessage({ id: 'partner-agent' })} ${intl.formatMessage({ id: 'is-required' })}`)
    });

    const formik = useFormik({
        initialValues: {
            organizationId: flow?.organizationId ?? '',
            organizationName: flow?.organization?.name ?? '',
            brokerId: flow?.lead.brokerId ?? '',
            brokerName: flow?.lead.broker?.user?.name ?? ''
        },
        onSubmit(values, formikHelpers) {
            dispatch(
                setBrokerDetails({
                    broker: { id: values.brokerId, name: values.brokerName },
                    organization: { id: values.organizationId, name: values.organizationName }
                })
            );
            handleNext();
        },
        // validationSchema: AddSaleBrokerAndOrganizationSchema,
        enableReinitialize: true
    });

    return (
        <div>
            <DialogContent
                sx={{
                    height: saleClaimDialogHeight
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <DialogContentText variant="h4">
                            <FormattedMessage id="sales-agent" /> :
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <BrokerCognitoFilter
                                size="medium"
                                location="create-primary-cils"
                                defaultValueId={formik.values.brokerId}
                                handleSearch={(newValue) => {
                                    formik.setFieldValue('brokerId', newValue);
                                }}
                                handleSelected={(searchedBroker) => {
                                    formik.setFieldValue('brokerName', searchedBroker?.name);
                                    formik.setFieldValue('organizationId', searchedBroker?.organizationId);
                                    formik.setFieldValue('organizationName', searchedBroker?.organizationName);
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <StepperDialogActions
                handleBack={handleBack}
                handleNext={() => {
                    emitEvent('Click 5th Next Sale Claim Creation', { ...formik.values, error: Boolean(formik.errors) });
                    formik.submitForm();
                    handleNext?.();
                }}
            />
        </div>
    );
};

export default BrokerSection;
