import { cloneElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Button, Drawer, Grid, Stack, Typography } from '@mui/material';
import { gridSpacing, gridSpacingMedium } from 'store/constant';
import { ReactElement } from 'react-markdown/lib/react-markdown';

export interface PopupFiltersFormProps {
    filters: (ReactElement | undefined)[];
    handleClearAll: () => void;
}
const PopupFiltersForm = ({ filters, handleClearAll }: PopupFiltersFormProps) => {
    const [openState, setOpenState] = useState(false);

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setOpenState(open);
    };

    const handleOnClear = () => {
        handleClearAll();
        toggleDrawer(false);
    };

    return (
        <>
            <Button onClick={toggleDrawer(true)} endIcon={<FilterAltOutlinedIcon />}>
                <FormattedMessage id="all-filters" />
            </Button>
            <Drawer open={openState} onClose={toggleDrawer(false)} anchor="right">
                <Grid container justifyContent="space-between" alignItems="center" my={2} p={2}>
                    <Typography variant="h3" fontWeight={700}>
                        <FormattedMessage id="filters" />
                    </Typography>
                    <Button color="error" startIcon={<CloseOutlinedIcon />} onClick={handleOnClear}>
                        <FormattedMessage id="clear-filters" />
                    </Button>
                </Grid>

                <Stack width={{ xs: '263px', md: '400px' }} spacing={gridSpacing} px={2}>
                    {filters.map((filter, index) => !!filter && cloneElement(filter, { key: index }))}
                </Stack>

                <Grid container spacing={gridSpacingMedium} justifyContent="space-evenly" my={2} p={2}>
                    <Grid item xs={6}>
                        <Button fullWidth onClick={toggleDrawer(false)} variant="outlined">
                            <FormattedMessage id="cancel" />
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button fullWidth variant="contained" onClick={toggleDrawer(false)}>
                            <FormattedMessage id="apply" />
                        </Button>
                    </Grid>
                </Grid>
            </Drawer>
        </>
    );
};

export default PopupFiltersForm;
