import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import { Alarm } from '@mui/icons-material';

const Cils: NavItemType = {
    id: 'cils',
    title: <FormattedMessage id="cils" />,
    type: 'item',
    url: '/cils',
    icon: Alarm,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'CILs'
    },
    allowedAudience: [{ name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' }, { name: InvitationTypeEnum.admins }]
};

const CilsItem: NavItemType = {
    id: 'cils',
    type: 'group',
    children: [Cils]
};
export default CilsItem;
