import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import INVENTORY_MANAGERS_APIS from './apis';
import { InventoryManagersInterface } from './interfaces';

const getInventoryManagerById = createAsyncThunk(
    'inventoryManagers/get-By-id',
    async (
        requestData: {
            inventoryManagerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InventoryManagersInterface | any> => {
        const { inventoryManagerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(INVENTORY_MANAGERS_APIS.getById(inventoryManagerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default getInventoryManagerById;
