// ==============================|| CRM Control ROUTING ||============================== //

import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import CRMPage from 'views/crms/CRM';
import CRMsPage from 'views/crms/CRMs';

// const allowedGroups = [InvitationTypeEnum.admins];

const CRMControlRoutes = {
    path: '/crms',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                    <CRMsPage />
                </RoleGuard>
            )
        },
        {
            path: ':id',
            element: (
                <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                    <CRMPage />
                </RoleGuard>
            )
        }
    ]
};
export default CRMControlRoutes;
