import { format } from 'date-fns';

const monthStartAndEndDate = (date?: string | null) => {
    const day = date ? new Date(date) : new Date();
    const currentYear = format(day, 'yyyy');
    const currentMonth = format(day, 'MM');
    const endDay = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate();
    const startDate = `${currentYear}-${currentMonth}-01T00:00:00.000Z`;
    const endDate = `${currentYear}-${currentMonth}-${endDay}T00:00:00.000Z`;
    return {
        startDate,
        endDate
    };
};

export default monthStartAndEndDate;
