// hooks
import { useState } from 'react';

// material ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    IconButton,
    Tooltip
} from '@mui/material';
// icon
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/styles';

interface AlertDialogProps {
    title: string;
    btnTitle?: string;
    type?: 'icon' | 'button' | 'custom-button';
    message?: string;
    password?: string;
    onConfirm?: () => void;
    fullWidth?: boolean;
    disabled?: boolean;
    tooltip?: string;
    loading?: boolean;
    icon?: JSX.Element;
}

export default function AlertDialog({
    title,
    password,
    message = 'Are you sure ?',
    type,
    onConfirm,
    loading,
    icon,
    btnTitle = 'remove',
    fullWidth = false,
    disabled = false,
    tooltip = 'Remove'
}: AlertDialogProps) {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [inputPassword, setInputPassword] = useState<string>('');
    const [inputPasswordError, setInputPasswordError] = useState<string>();

    const btnSX = {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[200],
        background: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.background.paper
    };

    const handleToggleOpen = () => {
        setOpen(!open);
        setInputPassword('');
        setInputPasswordError(undefined);
    };

    const handleConfirm = () => {
        if ((!password || inputPassword === password) && onConfirm) {
            onConfirm();

            handleToggleOpen();
        } else {
            setInputPasswordError('Password Does Not Match');
        }
    };

    return (
        <>
            {type === 'button' ? (
                <Button fullWidth={fullWidth} disabled={disabled} variant="outlined" color="error" size="small" onClick={handleToggleOpen}>
                    {btnTitle}
                </Button>
            ) : type === 'custom-button' ? (
                <Button variant="outlined" fullWidth color="error" onClick={handleToggleOpen} sx={btnSX}>
                    {btnTitle}
                </Button>
            ) : (
                <Tooltip title={tooltip}>
                    <>
                        <IconButton disabled={disabled} size="small" onClick={handleToggleOpen}>
                            {!icon ? <DeleteTwoToneIcon color={disabled ? 'inherit' : 'error'} /> : icon}
                        </IconButton>
                    </>
                </Tooltip>
            )}
            <Dialog
                open={open}
                onClose={handleToggleOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            {password && (
                                <TextField
                                    type="password"
                                    label="Password*"
                                    name="inputPassword"
                                    id="inputPassword-alert"
                                    fullWidth
                                    color="secondary"
                                    value={inputPassword}
                                    onChange={(event) => {
                                        setInputPassword(event.target.value);
                                    }}
                                    error={!!inputPasswordError}
                                    helperText={inputPasswordError}
                                />
                            )}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={handleToggleOpen}>
                        cancel
                    </Button>
                    {onConfirm && (
                        <LoadingButton loading={loading} color="error" variant="contained" size="small" onClick={handleConfirm} autoFocus>
                            confirm
                        </LoadingButton>
                    )}
                </DialogActions>
            </Dialog>
        </>
    );
}
