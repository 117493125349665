import { createSlice } from '@reduxjs/toolkit';
import { createInvitationType, deleteInvitationType, queryInvitationType, updateInvitationType } from './actions';
import { InitialInvitationTypesStateInterface } from './interfaces';

// initial state
const initialState: InitialInvitationTypesStateInterface = {
    loading: false,
    error: false,
    refresh: false,
    types: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE - Invitation Type ||============================== //

const invitationTypes = createSlice({
    name: 'invitation-types',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryInvitationType.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryInvitationType.fulfilled, (state, action) => {
                state.loading = false;
                state.types = action.payload;
            })
            .addCase(queryInvitationType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createInvitationType.pending, (state) => {
                state.error = false;
            })
            .addCase(createInvitationType.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(createInvitationType.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateInvitationType.pending, (state) => {
                state.error = false;
            })
            .addCase(updateInvitationType.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateInvitationType.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteInvitationType.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteInvitationType.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteInvitationType.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default invitationTypes.reducer;

// export const {  } = invitationTypes.actions;
