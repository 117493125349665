import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { QueryBanksInterface } from './interfaces';
import BANKS_APIS from './apis';

export const queryBanks = createAsyncThunk(
    'banks/query',
    async (
        requestData: {
            runSuccess?: () => void;
            queryData: QueryBanksInterface;
        },
        { rejectWithValue }
    ) => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(BANKS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default queryBanks;
