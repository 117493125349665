import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import Account_MANAGERS_APIS from './apis';
import { QueryAccountManagerTarget, QueryAccountManagersInterface } from './interfaces';

export const getAccountManagerTarget = createAsyncThunk(
    'accountManagers/get-target',
    async (
        requestData: {
            query: QueryAccountManagerTarget;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.getAccountManagerTarget(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagerTargets = createAsyncThunk(
    'accountManagers/get-all-targets',
    async (
        requestData: {
            query: QueryAccountManagerTarget;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.getAccountManagerTargets(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagers = createAsyncThunk(
    'accountManagers/get-account-managers',
    async (
        requestData: {
            query: QueryAccountManagersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.getAccountManagers(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const setAccountManagersTarget = createAsyncThunk(
    'account-manager/set-target',
    async (
        requestData: {
            body: {
                accountManagersIds: string[];
                from: string;
                to: string;
                value: number;
                organizationsValue: number;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { body, runSuccess } = requestData;
        try {
            await axios.post(Account_MANAGERS_APIS.setAccountManagerTarget(), body);

            if (runSuccess) runSuccess();
            return '';
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
