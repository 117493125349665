import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCompoundDetails, getProperties, getPropertyDetails, getPropertyTypesDetails } from './actions';
import { NawyInventoryInitialStateInterface } from './interfaces';

const initialState: NawyInventoryInitialStateInterface = {
    compoundDetails: [],
    numberOfCompounds: 0,
    propertyTypesDetails: [],
    properties: [],
    propertyDetails: [],
    loadingCompoundsDetails: false,
    loadingPropertyTypesDetails: false,
    loadingProperties: false,
    loadingPropertyDetails: false,
    inventoryError: false
};

const nawyInventory = createSlice({
    name: 'nawyInventory',
    initialState,
    reducers: {
        updateNumberOfCompounds(state, action: PayloadAction<number>) {
            state.numberOfCompounds = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCompoundDetails.pending, (state) => {
                state.loadingCompoundsDetails = true;
                state.inventoryError = false;
            })
            .addCase(getCompoundDetails.rejected, (state, action) => {
                state.loadingCompoundsDetails = false;
                state.inventoryError = action.payload;
            })
            .addCase(getCompoundDetails.fulfilled, (state, action) => {
                const { compounds, number_of_compounds } = action.payload;
                state.compoundDetails = compounds;
                if (number_of_compounds) {
                    state.numberOfCompounds = number_of_compounds;
                }

                state.loadingCompoundsDetails = false;
            })
            .addCase(getPropertyTypesDetails.pending, (state) => {
                state.loadingPropertyTypesDetails = true;
                state.inventoryError = false;
            })
            .addCase(getPropertyTypesDetails.rejected, (state, action) => {
                state.loadingPropertyTypesDetails = false;
                state.inventoryError = action.payload;
            })
            .addCase(getPropertyTypesDetails.fulfilled, (state, action) => {
                state.propertyTypesDetails = action.payload;
                state.loadingPropertyTypesDetails = false;
            })
            .addCase(getProperties.pending, (state) => {
                state.loadingProperties = true;
                state.inventoryError = false;
            })
            .addCase(getProperties.rejected, (state, action) => {
                state.loadingProperties = false;
                state.inventoryError = action.payload;
            })
            .addCase(getProperties.fulfilled, (state, action) => {
                state.properties = action.payload;
                state.loadingProperties = false;
            })
            .addCase(getPropertyDetails.pending, (state) => {
                state.loadingPropertyDetails = true;
                state.inventoryError = false;
            })
            .addCase(getPropertyDetails.rejected, (state, action) => {
                state.loadingPropertyDetails = false;
                state.inventoryError = action.payload;
            })
            .addCase(getPropertyDetails.fulfilled, (state, action) => {
                state.propertyDetails = action.payload;
                state.loadingPropertyDetails = false;
            });
    }
});

export const { updateNumberOfCompounds } = nawyInventory.actions;

export default nawyInventory.reducer;
