import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import AuthGuard from 'utils/route-guard/AuthGuard';
import SystemUsersPage from 'views/users/SystemUsers';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const UsersPage = Loadable(lazy(() => import('views/users/Users')));
const AccountManagersPage = Loadable(lazy(() => import('views/users/AccountManagers')));
const BrokersPage = Loadable(lazy(() => import('views/users/Brokers')));
const Profile = Loadable(lazy(() => import('views/users/Profile')));
const Settings = Loadable(lazy(() => import('views/users/User')));
const OperationManagersPage = Loadable(lazy(() => import('views/users/OperationManagers')));

// ==============================|| Users ROUTING ||============================== //

const UsersRoutes = {
    path: '/users',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                    <UsersPage />
                </RoleGuard>
            )
        },

        {
            path: 'profile/:tab',
            element: <Profile />
        },

        {
            path: 'sales-agents',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.brokers },
                        { name: InvitationTypeEnum.operationManager },
                        { name: InvitationTypeEnum.admins }
                    ]}
                >
                    <BrokersPage />
                </RoleGuard>
            )
        },
        {
            path: 'account-managers',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.brokers },
                        { name: InvitationTypeEnum.admins }
                    ]}
                >
                    <AccountManagersPage />
                </RoleGuard>
            )
        },
        {
            path: 'operation-managers',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.operationManager }]}
                >
                    <OperationManagersPage />
                </RoleGuard>
            )
        },
        {
            path: 'system-users',
            element: (
                <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                    <SystemUsersPage />
                </RoleGuard>
            )
        },
        {
            path: ':id/:tab',
            element: (
                <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                    <Settings />
                </RoleGuard>
            )
        }
    ]
};
export default UsersRoutes;
