import { CommonListInterface, CommonQueryInterface } from 'store/interfaces';
import { AccountManagerOrganizationsListInterface } from '../accountManagers/interfaces';
import { BrokerFullDataInterface } from '../brokers/interfaces';
import { DeveloperInterface } from '../developers/interfaces';
import { InvitationsLimitsInterface } from '../invitationsLimits/interfaces';
import { TierInfo, TierInterface } from '../tiers/interfaces';

export interface OrganizationInterface {
    id: string;
    name: string;
    officialName: string;
    brokersLimit?: number;
    cilsLimit?: number;
    primaryCilsLimit?: number;
    listingLimit?: number;
    leadsLimit?: number;
    validityTime: string;
    saleClaimsLimit?: number;
    reservationLimit?: number;
    viewInventory?: boolean;
    personalId?: string;
    commissionType?: CommissionTypeEnum;
    taxId?: string;
    commercialCardId?: string;
    /**
     * @deprecated
     * @use creatorId
     */
    createdById: string;
    creatorId: string;
    /**
     * @deprecated
     * @use creatorId
     */
    createdBy: AccountManagerOrganizationsListInterface;
    creator: { name: string; cognitoId: string };
    tierId: string;
    hasVatCertificate?: boolean;
    hasWithholdingTaxCertificate?: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface OrganizationContactInfoInterface {
    id: number;
    title: string;
    name: string;
    organizationId: string;
    email?: string;
    phone?: string;
    createdAt: string;
    updatedAt: string;
}

export interface OrganizationLocationsInterface {
    id: number;
    type: string;
    address?: string;
    area: string;
    city: string;
    country: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
}

export interface OrganizationPaymentInformationInterface {
    id: number;
    nameOnAccount: string;
    accountNumber: string;
    default: boolean;
    bankName: string;
    branchAddress: string;
    iban: string;
    swift: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
}
export interface OrganizationFullDataInterface extends OrganizationInterface {
    accountManagers?: AccountManagerOrganizationsListInterface[];
    documents?: DocumentInterface[];
    contactInfo?: OrganizationContactInfoInterface[];
    locations?: OrganizationLocationsInterface[];
    areas?: OrganizationAreaInterface[];
    tier?: TierInterface;
    tierId: string;
    saleType?: OrganizationSaleTypes;
    paymentInformation?: OrganizationPaymentInformationInterface[];
    invitationsLimit?: InvitationsLimitsInterface[];
    developers: DeveloperInterface[];
    estimatedSizeMax: number | null;
    estimatedSizeMin: number | null;
    _count?: { saleClaims?: number };
}

export interface OrganizationSingleListData extends OrganizationInterface {
    paymentInformation?: OrganizationPaymentInformationInterface[];
    tier?: TierInfo;
    saleType?: OrganizationSaleTypes;
    estimatedSizeMin?: number | null;
    estimatedSizeMax?: number | null;
    accountManagers?: AccountManagerOrganizationsListInterface[];
    createBy?: AccountManagerOrganizationsListInterface;
    _count?: { documents?: number };
    documents?: DocumentInterface[];
}

export interface OrganizationsList extends CommonListInterface {
    data: OrganizationSingleListData[];
}

export interface OrganizationBrokersList extends CommonListInterface {
    data: BrokerFullDataInterface[];
}

export interface OrganizationDocument {
    id: string;
    name: string;
    link: string;
    type: DocumentTypes;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
}

export enum CommissionTypeEnum {
    Normal = 'Normal',
    Express = 'Express',
    FirstSalePromo = 'FirstSalePromo',
    DevelopersExpressPromo = 'DevelopersExpressPromo',
    CityscapePromo = 'CityscapePromo',
    CityscapePromo2 = 'CityscapePromo2',
    October24Promo = 'October24Promo',
    FreelancerFirstSale = 'FreelancerFirstSale'
}

export const commissionTypeMap = {
    [CommissionTypeEnum.DevelopersExpressPromo]: 'developers-express-promo',
    [CommissionTypeEnum.Express]: 'express',
    [CommissionTypeEnum.FirstSalePromo]: 'first-sale-promo',
    [CommissionTypeEnum.Normal]: 'normal',
    [CommissionTypeEnum.CityscapePromo]: 'cityscape-promo',
    [CommissionTypeEnum.CityscapePromo2]: 'cityscape-promo-2',
    [CommissionTypeEnum.October24Promo]: 'october-24-promo',
    [CommissionTypeEnum.FreelancerFirstSale]: 'freelancer-first-sale'
};

export enum DocumentTypes {
    TaxCard = 'TaxCard',
    NationalId = 'NationalId',
    CommercialRegister = 'CommercialRegister',
    Contract = 'Contract',
    VATCertificate = 'VATCertificate',
    WithholdingAmount = 'WithholdingAmount'
}

export enum DocumentStatus {
    Pending = 'Pending',
    Rejected = 'Rejected',
    Verified = 'Verified'
}

export type DocumentStatusType = keyof typeof DocumentStatus;

export enum DocumentStates {
    Draft = 'Draft',
    Active = 'Active'
}

export const enum OrganizationSaleTypes {
    Primary = 'Primary',
    Resale = 'Resale',
    Both = 'Both'
}

export interface QueryOrganizations extends CommonQueryInterface {
    id?: string;
    globalSearch?: string;
    name?: string;
    country?: string;
    location?: string;
    phone?: string;
    taxId?: string;
    commercialCardId?: string;
    tierId?: string;
    excludeAmbassadors?: boolean;
    active?: boolean;
    saleType?: string;
    brokerId?: string;
    accountManagerId?: string;
    createdById?: string;
    createdAtMin?: string;
    createdAtMax?: string;
    contractExpiryStatus?: string;
    source?: string;
    completedDocumentsVerification?: boolean;
    assignment?: string;
}

export interface QueryOrganizationDocuments extends CommonQueryInterface {
    organizationId?: string;
    type?: DocumentTypes | keyof typeof DocumentTypes;
    key?: string;
    tierId?: string;
    deleted?: boolean;
    verificationStatus?: DocumentStatus;
}

export interface CreateOrganizationDto {
    name: string;
    officialName?: string;
    location?: CreateOrganizationLocationsDto;
    contactInfo?: CreateOrganizationContactInfoDto;
    area?: CreateOrganizationAreaDto;
    broker?: CreateOrganizationBrokerDto;
}
export interface UpdateOrganizationInterface {
    name?: string;
    personalId?: string;
    commissionType?: string;
    taxId?: string;
    commercialCardId?: string;
    estimatedSizeMin?: number;
    estimatedSizeMax?: number;
    startingDate?: string;
    expirationDate?: string;
    hasVatCertificate?: boolean;
    hasWithholdingTaxCertificate?: boolean;
}
export interface UpdateOrganizationLimitsInterface {
    brokersLimit?: number;
    cilsLimit?: number;
    listingLimit?: number;
    saleClaimsLimit?: number;
    reservationLimit?: number;
    leadsLimit?: number;
    viewInventory?: boolean;
}

export interface UploadErrorInterface {
    name: string;
    error: string;
}

export interface OrganizationDocumentsInterface {
    commercialRegister: DocumentInterface[];
    taxCard: DocumentInterface[];
    nationalId: DocumentInterface[];
    contract: DocumentInterface[];
}

export interface OrganizationsInitialState {
    loading: boolean;
    error: any;
    loadingList: boolean;
    errorList: any;
    refresh: boolean;
    contactInfoError: any;
    locationsError: any;
    areaError: any;
    paymentInfoError: any;
    organizations: {
        page: number;
        pageSize: number;
        pages: number;
        count: number;
        data: OrganizationSingleListData[];
    };
    uploadDocuments: OrganizationDocumentsInterface;
    organization?: OrganizationFullDataInterface;
    brokersListLoading: boolean;
    brokersListError: any;
    refreshBrokers: boolean;
    brokers: OrganizationBrokersList;
    megaCompetitionSalesValue?: OrganizationCompetitionInterface;
    leaderboard?: OrganizationLeaderboardInterface[];
    allDocumentsVerified?: boolean;
    documents?: OrganizationDocumentsInterface[];
}

export interface OrganizationAreaInterface {
    id: number;
    name: string;
    createdAt: string;
    updatedAt: string;
}

export interface DocumentInterfaceWithUploadKey extends DocumentInterface {
    uploadKey: string;
}

export interface DocumentInterface {
    id: string;
    name: string;
    link: string;
    key: string;
    type: DocumentTypes;
    state: DocumentStates;
    startingDate?: string;
    expirationDate?: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
    expiryStatus?: string;
}

export interface CreateOrganizationContactInfoDto {
    title?: string;
    name?: string;
    phone?: string;
    email?: string;
}
export interface CreateOrganizationLocationsDto {
    type?: string;
    address?: string;
    area?: string;
    city?: string;
    country?: string;
}

export interface CreateOrganizationAreaDto {
    name: string;
}

export interface CreateOrganizationBrokerDto {
    phone: string;
    countryCode?: string;
    name: string;
    email?: string;
    password: string;
}
export interface CreateOrganizationPaymentInfoDto {
    nameOnAccount: string;
    accountNumber: string;
    bankName: string;
    branchAddress: string;
    iban: string;
    swift: string;
    personalId?: string;
    default: boolean;
    selectionType: string;
}
export interface UpdateOrganizationPaymentInfoDto extends Partial<CreateOrganizationPaymentInfoDto> {}

export interface OrganizationCompetitionInterface {
    megaCompetitionSalesValue: number;
}

export interface OrganizationLeaderboardInterface extends OrganizationFullDataInterface {
    megaCompetitionSalesValue?: number;
    rank: number;
}

export interface UpdateDocumentsRequestData {
    documentIds: string[];
    type?: keyof typeof DocumentTypes;
    isVerified?: boolean;
    verificationStatus?: DocumentStatus | keyof typeof DocumentStatus;
    rejectionReason?: string;
}
