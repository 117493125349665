import { FAUNA_API, formattedQuery } from 'store/apis';

import { QueryCilsInterface } from './interfaces';

const CILS_APIS = {
    query: (query: QueryCilsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '?stage=FinancingCil&'];
        return formattedQuery(formatQuery, query);
    },
    create: () => `${FAUNA_API}flows/financing-cil`,
    getById: (id: number) => `${FAUNA_API}flows/get-financing-cil/${id}`,
    /**
     * @PUT
     * @param id
     * @returns {cil, lead}
     */
    renew: (id: number) => `${FAUNA_API}cils/${id}`,
    analytica: () => `${FAUNA_API}cils/analytica`
};

export default CILS_APIS;
