import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import Filters, { FiltersInterface } from 'ui-component/Filters';
import { debounce } from 'lodash';
import { queryDevelopers } from 'store/slices/developers/actions';
import { QueryDevelopersInterface } from 'store/slices/developers/interfaces';
import FilterChip from 'ui-component/Filters/Chips/FilterChip';
import StringSearchFilter from 'ui-component/Filters/StringSearch';
import NawyTable from 'ui-component/Tables/NawyTable';
import DevelopersContactsCards from './CardView';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import DeveloperFilterV2 from 'ui-component/Filters/DeveloperFilter/v2';

const DevelopersContactsList = () => {
    const intl = useIntl();
    const theme = useTheme();
    const location = 'developers contacts page';
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    const [searchParams, setSearchParams] = useSearchParams();
    const { listLoading, refresh, developers } = useSelector((state) => state.developers);

    // Filter state
    const initialState: QueryDevelopersInterface = {
        id: parseInt(searchParams.get('id') || '', 10) || undefined,
        agentName: searchParams.get('agentName') || undefined,
        page: parseInt(searchParams.get('page') || '', 10) || undefined,
        pageSize: parseInt(searchParams.get('pageSize') || '', 10) || undefined
    };
    const [filter, setFilter] = useState(initialState);
    const chipFilterInitialState = {
        developerName: '',
        agentName: ''
    };
    const [chipFilters, setChipFilters] = useState(chipFilterInitialState);
    const [clearAll, setClearAll] = useState(false);

    useEffect(() => {
        let flag = false;

        Object.keys(filter).forEach((filterKey) => {
            const key = filterKey as keyof typeof filter;
            if (key !== 'page' && key !== 'pageSize' && filter[key] !== undefined && filter[key] !== '') {
                flag = true;
            }
        });
        setClearAll(flag);
    }, [filter]);

    const handleFilters = (newFilterField: QueryDevelopersInterface) => {
        const newFilter = { ...filter, ...newFilterField };
        setClearAll(true);

        setFilter(newFilter);
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            searchParams.set(filterKey, `${newFilter[key]}`);
            if (newFilter[key] === undefined || newFilter[key] === '') searchParams.delete(filterKey);
            setSearchParams(searchParams);
        });
    };

    const handleChipFilters = (newFilterField: {}) => {
        setChipFilters({ ...chipFilters, ...newFilterField });
    };

    const handleClearAll = () => {
        setClearAll(false);
        const newFilter: QueryDevelopersInterface = Object.keys(filter).reduce((acc, key) => {
            acc[key as keyof QueryDevelopersInterface] = undefined;
            return acc;
        }, {} as QueryDevelopersInterface);
        setFilter(newFilter);
        searchParams.forEach((value, key) => {
            searchParams.delete(key);
        });
        setSearchParams(searchParams);
        setChipFilters(chipFilterInitialState);
    };

    const filterData = useRef(
        debounce(async (query) => {
            dispatch(queryDevelopers({ query: { ...query, excludeNullContacts: true, orderBy: 'score', orderByDirection: 'desc' } }));
        })
    ).current;

    useEffect(() => {
        filterData(filter);
    }, [filter, filterData, refresh]);

    const filters: FiltersInterface[] = [
        {
            mainView: (
                <DeveloperFilterV2
                    key="developer-name"
                    location={location}
                    value={filter.id}
                    defaultValueId={filter.id}
                    handleSearch={(id) => {
                        handleFilters({ id });
                    }}
                    excludeContactLess
                    handleSelected={(developer) => {
                        handleChipFilters({ developerName: developer?.name || '' });
                    }}
                    clear={!filter.id}
                />
            ),
            chipView:
                filter.id && chipFilters.developerName ? (
                    <FilterChip
                        label={intl.formatMessage({ id: 'developer-name' })}
                        value={chipFilters.developerName}
                        variant="outlined"
                        handleOnClear={() => {
                            handleFilters({ id: undefined });
                            handleChipFilters({ developerName: '' });
                        }}
                    />
                ) : undefined
        },
        {
            mainView: (
                <StringSearchFilter
                    key="agent-name"
                    location={location}
                    label={intl.formatMessage({ id: 'agent-name' })}
                    value={filter.agentName || ''}
                    handleChange={(agentName) => {
                        handleFilters({ agentName });
                    }}
                />
            ),
            chipView: filter.agentName ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'agent-name' })}
                    value={filter.agentName}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ agentName: '' });
                        handleChipFilters({ agentName: '' });
                    }}
                />
            ) : undefined
        }
    ];

    return (
        <NawyTable
            filter={filter}
            view="card"
            handleFilter={handleFilters}
            CardView={<DevelopersContactsCards developers={developers.data} />}
            loading={listLoading}
            Title={
                <Filters clearAll={clearAll} filters={filters} handleClearAll={handleClearAll} popUpFilters={matchDownSM || matchDownMD} />
            }
            Body={<></>}
            headers={[]}
            content={developers}
        />
    );
};

export default DevelopersContactsList;
