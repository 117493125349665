import { Box, FormControl, FormLabel, Grid, Icon, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

import ImagesPreview from './Preview';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';

export default function ImageUploadDragAndDrop({
    id = '',
    documents,
    handleAddImages,
    handleDeleteImage
}: {
    id?: string;
    documents?: Array<File>;
    handleAddImages: (files: FileList) => void;
    handleDeleteImage: (name: string) => void;
}) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();
    const documentsParsed = documents?.map((document) => ({
        src: URL.createObjectURL(document),
        name: document.name,
        error: false
    }));
    const [images, setImages] = useState<{ src: string; name: string; error: boolean }[]>(documentsParsed ?? []);
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const IMAGE_SIZE_LIMIT = 2097152;

    const handleFiles = (files: FileList) => {
        const currentImages = [...images];

        for (const file of files) {
            if (file.size <= IMAGE_SIZE_LIMIT) {
                // override images having the same name
                const existingImageIndex = currentImages.findIndex((image) => image.name === file.name);
                if (existingImageIndex !== -1) {
                    currentImages.splice(existingImageIndex, 1);
                    handleDeleteImage(file.name);
                }
                currentImages.push({ src: URL.createObjectURL(file), name: file.name, error: false });
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'image-cant-exceed-limit' }),
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        }
        setImages(currentImages);
    };

    const handleRemove = (name: string) => {
        const newImages = images.filter((file) => file.name !== name);
        setImages(newImages);
        handleDeleteImage(name);
    };

    const handleDrag: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e?.dataTransfer?.files?.[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { target } = e;
        if (target?.files) {
            handleFiles(target.files);
            handleAddImages(target.files);
        }

        target.value = '';
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl
                    id={`${id}-form-file-upload`}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                    sx={{
                        height: '200px',
                        width: '100%',
                        textAlign: 'center',
                        position: 'relative'
                    }}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        style={{ display: 'none', height: '100%', width: '100%' }}
                        ref={inputRef}
                        type="file"
                        id={`${id}-input-file-upload`}
                        multiple
                        onChange={handleChange}
                    />
                    <FormLabel
                        id={`${id}-label-file-upload`}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: '2px',
                            borderRadius: '1rem',
                            borderStyle: 'dashed',
                            borderColor: theme.palette.primary.light,
                            backgroundColor: theme.palette.background.default
                        }}
                        htmlFor={`${id}-input-file-upload`}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Icon>
                                    <AddPhotoAlternateOutlinedIcon />
                                </Icon>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <FormattedMessage id="drop-image-to-upload" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    <FormattedMessage id="click-here-to-browse" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </FormLabel>
                    {dragActive && (
                        <Box
                            id={`${id}-drag-file-element`}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        />
                    )}
                </FormControl>
            </Grid>
            <Grid item mt={1}>
                <Typography variant="subtitle2">
                    <FormattedMessage id="max-image-size" />
                </Typography>
            </Grid>
            <Grid item>
                <ImagesPreview images={images} handleRemoveImage={handleRemove} />
            </Grid>
        </Grid>
    );
}
