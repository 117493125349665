import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryBannerInterface } from './interfaces';

const BANNER_COLLECTION = 'banners';

const BANNERS_APIS = {
    createBanner: () => `${FAUNA_API}${BANNER_COLLECTION}`,
    query: (query: QueryBannerInterface) => {
        const formatQuery: string[] = [FAUNA_API, BANNER_COLLECTION, '?'];
        return formattedQuery(formatQuery, query);
    },
    getBannerById: (bannerId: string) => `${FAUNA_API}${BANNER_COLLECTION}/${bannerId}`,
    getRecentBanners: () => `${FAUNA_API}${BANNER_COLLECTION}/recent`,
    updateBanner: (bannerId: string) => `${FAUNA_API}${BANNER_COLLECTION}/${bannerId}`,
    deleteBanner: (bannerId: string) => `${FAUNA_API}${BANNER_COLLECTION}/${bannerId}`
};

export default BANNERS_APIS;
