// assets
import RocketLaunchTwoToneIcon from '@mui/icons-material/RocketLaunchTwoTone';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { TierViewsEnum } from 'store/slices/tiers/interfaces';
import { NavItemType } from 'types';

const launches: NavItemType = {
    id: 'launches',
    title: <FormattedMessage id="launches" />,
    type: 'item',
    url: '/launches',
    icon: RocketLaunchTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Launches'
    },
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers },
        {
            name: InvitationTypeEnum.brokers,
            organizationTierView: TierViewsEnum.brokerView,
            roles: [OrganizationRole.owner, OrganizationRole.manager]
        },
        { name: InvitationTypeEnum.internalAgents },
        { name: InvitationTypeEnum.inventoryManagers },
        { name: InvitationTypeEnum.nawyInventory },
        { name: InvitationTypeEnum.operationManager }
    ]
};

const launchesItem: NavItemType = {
    id: 'launches',
    type: 'group',
    children: [launches]
};

export default launchesItem;
