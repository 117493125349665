import { Box } from '@mui/material';

const TopSellingCompoundsIframe = () => {
    const srcUrl = 'https://lookerstudio.google.com/embed/reporting/ffcacb0b-4985-4114-8cc3-d6459dd582ec/page/buvfD';

    return (
        <Box
            component="iframe"
            id="top-selling-compounds-iframe"
            title="Top selling compounds Report"
            src={srcUrl}
            width="100%"
            height="100%"
            borderRadius="12px"
            border={0}
            padding={0}
        />
    );
};

export default TopSellingCompoundsIframe;
