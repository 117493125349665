import { FAUNA_API, queryData } from 'store/apis';

import { QueryReservationsInterface } from './interfaces';

const RESERVATIONS_APIS = {
    /**
     * @summary create sale claim for an organization
     * @POST /organizations/:id/reservations',
     * @param organizationId
     * @returns success message
     */
    create: () => `${FAUNA_API}reservations`,
    /**
     * @GET /reservations
     * @returns full sale claim entity
     */
    query: (query: QueryReservationsInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'reservations', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    /**
     * @GET /reservations
     * @param reservationId
     * @returns full sale claim entity
     */
    getById: (reservationId: number) => `${FAUNA_API}reservations/${reservationId}`,
    /**
     * @PATCH /reservations/:id
     * @param reservationId
     * @returns full sale claim entity
     */
    update: (reservationId: number) => `${FAUNA_API}reservations/${reservationId}`,
    /**
     * @DELETE /reservations/:id
     * @param reservationId
     * @returns success message
     */
    delete: (reservationId: number) => `${FAUNA_API}reservations/${reservationId}`,

    /**
     * @Patch /reservations/:id/documents
     * @param reservationId
     * @Body ReservationDocumentInterface
     * @returns void
     */
    uploadReservationDocument: (reservationId: number) => `${FAUNA_API}reservations/${reservationId}/documents`,

    /**
     * @Patch /reservations/:reservationId/documents/:documentId
     * @param reservationId
     * @param documentId
     * @Body ReservationDocumentInterface
     * @returns void
     */

    deleteReservationDocument: (reservationId: number, documentId: string) =>
        `${FAUNA_API}reservations/${reservationId}/documents/${documentId}`,

    /**
     * @GET
     * @description get `Reservations & EOIs` analytics
     * @returns ReservationAnalyticaClass[]
     */
    analytica: (query: QueryReservationsInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'reservations', '/analytica', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    }
};
export default RESERVATIONS_APIS;
