export interface HeadCell {
    disablePadding: boolean;
    id: string;
    label: string;
    sort: boolean;
    align: 'left' | 'center' | 'right';
}

export const notificationTypesHeaders: readonly HeadCell[] = [
    {
        id: 'type-id',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'type-id'
    },
    {
        id: 'name',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'name'
    },

    {
        id: 'portal',
        align: 'left',
        disablePadding: false,
        sort: false,
        label: 'portal'
    },
    {
        id: 'createdBy',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'created-by'
    },
    {
        id: 'createdAt',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'created'
    },
    {
        id: 'Action',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'actions'
    }
];
