import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import PROPERTY_LISTING_APIS from '../propertiesListing/apis';
import { ListingStatusEnum, PropertyListingInterface } from '../propertiesListing/interfaces';

export const getListingDetails = createAsyncThunk(
    'listings/details/get-by-id',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.getListingById(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const unlistListing = createAsyncThunk(
    'listings/details/unlist',
    async (
        requestData: {
            listingId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { listingId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.unlist(listingId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateListingStatus = createAsyncThunk(
    'listings/details/update-status',
    async (
        requestData: {
            listingId: number;
            body: {
                reviewerId?: string;
                status?: ListingStatusEnum;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { listingId, body, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateStatus(listingId), body);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteListing = createAsyncThunk(
    'listings/details/delete',
    async (
        requestData: {
            listingId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { listingId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.deleteListing(listingId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
