import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

const Loading = () => (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <SkeletonTotalIncomeCard />
        </Grid>
        <Grid item xs={12}>
            <SkeletonTotalIncomeCard />
        </Grid>
    </Grid>
);

export default Loading;
