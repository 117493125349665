import { createAsyncThunk } from '@reduxjs/toolkit';
import { InvitationTypeEnum } from 'store/interfaces';
import axios from 'utils/axios';
import { AccountManagersRole } from '../accountManagers/interfaces';
import USERS_APIS, { INVENTORY_MANAGERS_APIS, SYSTEM_USERS_APIS } from './apis';
import {
    ChangeUserGroupInterface,
    SendBatchNotificationInterface,
    SystemUserListInterface,
    SystemUserQueryInterface,
    UserCreateInterface,
    UserInterface,
    UserListInterface,
    UserQueryInterface,
    VerificationInterface
} from './interfaces';

export const createUser = createAsyncThunk(
    'users/create',
    async (
        requestData: {
            createData: UserCreateInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(USERS_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

// eslint-disable-next-line import/prefer-default-export
export const queryUsers = createAsyncThunk(
    'users/query',
    async (
        requestData: {
            queryData: UserQueryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserListInterface | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(USERS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
/**
 * @deprecated
 */
export const changeUserGroup = createAsyncThunk(
    'users/changeUserGroup',
    async (
        requestData: {
            id: string;
            groupData: ChangeUserGroupInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, groupData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.changeUserGroup(id), groupData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
/**
 * @summary add user to group.
 * @Important
 * ! Don't add user to group brokers
 */
export const addUserToGroup = createAsyncThunk(
    'users/add-user-to-group',
    async (
        requestData: {
            id: string;
            groupName: InvitationTypeEnum;
            crmId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, groupName, crmId, runSuccess } = requestData;
        if (groupName === InvitationTypeEnum.brokers) return rejectWithValue({ message: 'Can Not add user to Brokers' });
        try {
            // TODO: dont put the body in an object
            const successRes = await axios.put(USERS_APIS.addUserToGroup(id, groupName), { crmId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

/**
 * @summary add user to group.
 * @Important
 * ! Don't remove user from group brokers
 */
export const removeUserFromGroup = createAsyncThunk(
    'users/remove-user-from-group',
    async (
        requestData: {
            id: string;
            groupName: InvitationTypeEnum;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, groupName, runSuccess } = requestData;
        if (groupName === InvitationTypeEnum.brokers) return rejectWithValue({ message: 'Can Not remove user from Brokers' });
        try {
            const successRes = await axios.delete(USERS_APIS.removeUserFromGroup(id, groupName));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const changeUserActivity = createAsyncThunk(
    'users/changeUserActivity',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.changeUserActivity(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateUser = createAsyncThunk(
    'users/updateUser',
    async (
        requestData: {
            id: string;
            updateData: {
                name: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.update(id), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateUserEmail = createAsyncThunk(
    'users/updateUserEmail',
    async (
        requestData: {
            id: string;
            updateData: {
                email?: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.updateEmail(id), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const verifyUserEmail = createAsyncThunk(
    'users/verifyUserEmail',
    async (
        requestData: {
            id: string;
            updateData: VerificationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.verifyEmail(id), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const findOne = createAsyncThunk(
    'users/findOne',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { id, runSuccess } = requestData;
        try {
            const successRes = await axios.get(USERS_APIS.findOne(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getInventoryManager = createAsyncThunk(
    'users/inventory-manager/find-one',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const successRes = await axios.get(INVENTORY_MANAGERS_APIS.findOne(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const updateInventoryManagerRole = createAsyncThunk(
    'users/inventory-manager/update-role',
    async (
        requestData: {
            id: string;
            newRole: {
                role: AccountManagersRole;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, newRole, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(INVENTORY_MANAGERS_APIS.updateRole(id), newRole);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateUserLimits = createAsyncThunk(
    'users/updateUserLimits',
    async (
        requestData: {
            id: string;
            invitationsLimit: number;
            leadsLimit: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<UserInterface | any> => {
        const { id, invitationsLimit, leadsLimit, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(USERS_APIS.updateUserLimits(id), {
                invitationsLimit,
                leadsLimit
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const querySystemUsers = createAsyncThunk(
    'system-users/query',
    async (
        requestData: {
            queryData: SystemUserQueryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<SystemUserListInterface | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(SYSTEM_USERS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const sendBatchNotification = createAsyncThunk(
    'system-users/send-batch-notification',
    async (
        requestData: {
            data: SendBatchNotificationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<string | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axios.post(SYSTEM_USERS_APIS.sendBatchNotification(), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
