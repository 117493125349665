// assets
// assets
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { NavItemType } from 'types';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import { PercentOutlined } from '@mui/icons-material';
import invitationTypes from 'store/slices/invitationTypes';

// ==============================|| Ambassador Side Menu Items ||============================== //

// const commissionsItem: NavItemType = {
//     id: 'commissions',
//     title: <FormattedMessage id="commissions" />,
//     type: 'item',
//     url: '/commissions',
//     icon: PercentOutlined,
//     allowedAudience: [
//         { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit', roles: [OrganizationRole.owner] },
//         { name: InvitationTypeEnum.admins }
//     ],
//     mixpanelEvent: {
//         tab_name: 'Commissions'
//     }
// };

const termsAndConditionItem: NavItemType = {
    id: 'terms-and-conditions',
    title: <FormattedMessage id="terms-conditions" />,
    type: 'item',
    url: '/terms-and-conditions',
    icon: InfoTwoToneIcon,
    allowedAudience: [{ name: InvitationTypeEnum.brokers }],
    mixpanelEvent: {
        tab_name: 'Terms And Conditions'
    }
};
const requestFormItem: NavItemType = {
    id: 'request-forms',
    title: <FormattedMessage id="request-forms" />,
    type: 'item',
    target: true,
    url: 'https://sites.google.com/nawy.com/nawy-partners-help-center/home?authuser=0',
    icon: DashboardCustomizeIcon,
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'request-forms'
    }
};

// const whatsappSupportItems: NavItemType = {
//     id: 'whatsapp-support',
//     title: <FormattedMessage id="support" />,
//     type: 'item',
//     icon: WhatsAppIcon,
//     breadcrumbs: false,
//     mixpanelEvent: {
//         tab_name: 'Click view WhatsApp Support page'
//     },
//     allowedAudience: [
//         { name: InvitationTypeEnum.admins },
//         { name: InvitationTypeEnum.operationManager },
//         { name: InvitationTypeEnum.accountManagers },
//         { name: InvitationTypeEnum.internalAgents }
//     ]
// };

const commonItemsBottom = {
    id: 'common-side-menu-items-bottom',
    type: 'group',
    children: [requestFormItem, termsAndConditionItem]
};

export default commonItemsBottom;
