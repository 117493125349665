import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import Account_MANAGERS_APIS from '../accountManagers/apis';
import { OrganizationRole, QueryBrokersInterface } from '../brokers/interfaces';
import TIER_INVITATION_LIMITS from '../invitationsLimits/apis';
import { CreateInvitationLimitInterface, UpdateInvitationLimitInterface } from '../invitationsLimits/interfaces';
import ORGANIZATIONS_APIS, {
    ORGANIZATION_AREAS_APIS,
    ORGANIZATION_BROKERS_APIS,
    ORGANIZATION_COMPETITION_APIS,
    ORGANIZATION_CONTACT_INFO_APIS,
    ORGANIZATION_LOCATIONS_APIS,
    ORGANIZATION_PAYMENT_INFO_APIS
} from './apis';
import {
    CreateOrganizationContactInfoDto,
    CreateOrganizationDto,
    CreateOrganizationLocationsDto,
    CreateOrganizationPaymentInfoDto,
    DocumentStates,
    DocumentTypes,
    OrganizationDocumentsInterface,
    OrganizationFullDataInterface,
    OrganizationsList,
    QueryOrganizationDocuments,
    QueryOrganizations,
    UpdateDocumentsRequestData,
    UpdateOrganizationInterface,
    UpdateOrganizationLimitsInterface,
    UpdateOrganizationPaymentInfoDto
} from './interfaces';

export const createOrganization = createAsyncThunk(
    'organizations/create',
    async (
        requestData: {
            createData: CreateOrganizationDto;
            runSuccess?: (organizationId: string) => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ORGANIZATIONS_APIS.create(), createData);
            if (runSuccess) runSuccess(successRes.data.id);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryOrganizations = createAsyncThunk(
    'organizations/query',
    async (
        requestData: {
            queryData: QueryOrganizations;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationsList | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ORGANIZATIONS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getOrganizationById = createAsyncThunk(
    'organizations/getById',
    async (
        requestData: {
            organizationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ORGANIZATIONS_APIS.getById(organizationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrganizationInfoById = createAsyncThunk(
    'organizations/update-info',
    async (
        requestData: {
            organizationId: string;
            updateData: UpdateOrganizationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.updateInfo(organizationId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addOrganizationDevelopersRelations = createAsyncThunk(
    'organizations/add-developers',
    async (
        requestData: {
            organizationId: string;
            updateData: { developerIds?: number[] };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.addDevelopersRelations(organizationId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const removeOrganizationDevelopersRelations = createAsyncThunk(
    'organizations/remove-developers',
    async (
        requestData: {
            organizationId: string;
            updateData: { developerIds?: number[] };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.removeDevelopersRelations(organizationId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrganizationLimitsById = createAsyncThunk(
    'organizations/update-limits',
    async (
        requestData: {
            organizationId: string;
            updateData: UpdateOrganizationLimitsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.updateLimits(organizationId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganization = createAsyncThunk(
    'organizations/delete',
    async (
        requestData: {
            organizationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATIONS_APIS.delete(organizationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const uploadOrganizationDocuments = createAsyncThunk(
    'organizations/uploadDocument',
    async (
        requestData: {
            organizationId: string;
            type: DocumentTypes;
            documents: File[];
            state: DocumentStates;
            startingDate?: string;
            expirationDate?: string;
            runSuccess?: () => void;
        },
        { rejectWithValue, dispatch }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, documents, type, state, startingDate, expirationDate, runSuccess } = requestData;
        const uploadPromises = documents.map((document, index) => {
            const formData = new FormData();
            formData.append('type', type);
            formData.append('files', document);
            formData.append('state', state);
            if (startingDate) formData.append('startingDate', startingDate);
            if (expirationDate) formData.append('expirationDate', expirationDate);

            return axios
                .post(ORGANIZATIONS_APIS.uploadDocument(organizationId), formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                })
                .catch((error) => {
                    throw error; // Re-throw to be caught by `Promise.all`
                });
        });

        try {
            const successResponses = await Promise.all(uploadPromises);
            if (runSuccess) runSuccess();
            return successResponses.map((res) => res.data); // Return all the successful data responses
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const editDocumentsState = createAsyncThunk(
    'organizations/editDocumentState',
    async (
        requestData: {
            organizationId: string;
            documentIds: string[];
            state: DocumentStates;
            documentType: keyof typeof DocumentTypes;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, runSuccess, ...data } = requestData;

        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.editDocumentState(organizationId), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationDocuments = createAsyncThunk(
    'organizations/deleteDocument',
    async (
        requestData: {
            organizationId: string;
            documentId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, documentId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATIONS_APIS.deleteDocument(organizationId, documentId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateDocuments = createAsyncThunk(
    'organizations/update-documents',
    async (
        requestData: {
            organizationId: string;
            data: UpdateDocumentsRequestData;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, data, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATIONS_APIS.updateDocument(organizationId), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryOrganizationDocuments = createAsyncThunk(
    'organizations/query-documents',
    async (
        requestData: {
            organizationId: string;
            queryData: QueryOrganizationDocuments;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationDocumentsInterface | any> => {
        const { organizationId, queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ORGANIZATIONS_APIS.queryDocuments(organizationId, queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDocumentsByType = createAsyncThunk(
    'organizations/get-active-documents-by-type',
    async (
        requestData: {
            organizationId: string;
            type: DocumentTypes | keyof typeof DocumentTypes;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, type, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ORGANIZATIONS_APIS.getActiveDocumentsByType(organizationId, type));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const subscribeToTier = createAsyncThunk(
    'organizations/subscribe',
    async (
        requestData: {
            organizationId: string;
            tierId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, tierId, runSuccess } = requestData;
        try {
            const successRes = await axios.put(ORGANIZATIONS_APIS.subscribeToTier(organizationId, tierId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const unsubscribeFromTier = createAsyncThunk(
    'organizations/unsubscribe',
    async (
        requestData: {
            organizationId: string;
            tierId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OrganizationFullDataInterface | any> => {
        const { organizationId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATIONS_APIS.unsubscribe(organizationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const assignAccountManager = createAsyncThunk(
    'organizations/assignManager',
    async (
        requestData: {
            organizationId: string;
            accountManagerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, accountManagerCId, runSuccess } = requestData;
        try {
            await axios.put(Account_MANAGERS_APIS.assignManager(accountManagerCId, organizationId));
            if (runSuccess) runSuccess();
            return '';
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const unassignAccountManager = createAsyncThunk(
    'organizations/unassignManager',
    async (
        requestData: {
            organizationId: string;
            accountManagerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, accountManagerCId, runSuccess } = requestData;
        try {
            await axios.delete(Account_MANAGERS_APIS.unassignManager(accountManagerCId, organizationId));
            if (runSuccess) runSuccess();
            return '';
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const inviteOrganizationBroker = createAsyncThunk(
    'organizations/invite-brokers',
    async (
        requestData: {
            organizationId: string;
            invitationData: {
                countryCode: string;
                phone: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, invitationData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ORGANIZATION_BROKERS_APIS.invite(organizationId), invitationData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryOrganizationBrokers = createAsyncThunk(
    'organizations/query-brokers',
    async (
        requestData: {
            organizationId: string;
            brokersQuery: QueryBrokersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokersQuery, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ORGANIZATION_BROKERS_APIS.query(organizationId, brokersQuery));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrganizationBrokerRole = createAsyncThunk(
    'organizations/update-broker-role',
    async (
        requestData: {
            organizationId: string;
            brokerCId: string;
            newRole: OrganizationRole;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokerCId, newRole, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATION_BROKERS_APIS.updateRole(organizationId, brokerCId), { role: newRole });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const cancelOrganizationBrokerInvitation = createAsyncThunk(
    'organizations/cancel-broker-invitation',
    async (
        requestData: {
            organizationId: string;
            brokerTempId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokerTempId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATION_BROKERS_APIS.cancelInvitation(organizationId, brokerTempId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const softDeleteOrganizationBroker = createAsyncThunk(
    'organizations/soft-delete-broker',
    async (
        requestData: {
            organizationId: string;
            brokerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATION_BROKERS_APIS.softDelete(organizationId, brokerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const moveBrokerFromOrganization = createAsyncThunk(
    'organizations/move-broker',
    async (
        requestData: {
            organizationId: string;
            brokerCId: string;
            targetOrganizationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokerCId, targetOrganizationId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(
                ORGANIZATION_BROKERS_APIS.moveToAnotherOrganization(organizationId, brokerCId, targetOrganizationId)
            );
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationBroker = createAsyncThunk(
    'organizations/delete-broker',
    async (
        requestData: {
            organizationId: string;
            brokerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, brokerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ORGANIZATION_BROKERS_APIS.deleteBroker(organizationId, brokerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createOrganizationContactInfo = createAsyncThunk(
    'organizations/contact-info/create',
    async (
        requestData: {
            organizationId: string;
            contactInfoDetails: CreateOrganizationContactInfoDto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, contactInfoDetails, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ORGANIZATION_CONTACT_INFO_APIS.create(organizationId), contactInfoDetails);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationContactInfo = createAsyncThunk(
    'organizations/contact-info/delete',
    async (
        requestData: {
            organizationId: string;
            contactInfoId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, contactInfoId, runSuccess } = requestData;
        try {
            await axios.delete(ORGANIZATION_CONTACT_INFO_APIS.delete(organizationId, contactInfoId));
            if (runSuccess) runSuccess();
            return contactInfoId;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createOrganizationLocation = createAsyncThunk(
    'organizations/locations/create',
    async (
        requestData: {
            organizationId: string;
            locationsDetails: CreateOrganizationLocationsDto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, locationsDetails, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ORGANIZATION_LOCATIONS_APIS.create(organizationId), locationsDetails);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationLocation = createAsyncThunk(
    'organizations/locations/delete',
    async (
        requestData: {
            organizationId: string;
            locationId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, locationId, runSuccess } = requestData;
        try {
            await axios.delete(ORGANIZATION_LOCATIONS_APIS.delete(organizationId, locationId));
            if (runSuccess) runSuccess();
            return locationId;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const addAreaToOrganization = createAsyncThunk(
    'organizations/areas/add',
    async (
        requestData: {
            organizationId: string;
            areaName: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, areaName, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ORGANIZATION_AREAS_APIS.addArea(organizationId, areaName));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const removeAreaFromOrganization = createAsyncThunk(
    'organizations/areas/remove',
    async (
        requestData: {
            organizationId: string;
            areaName: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, areaName, runSuccess } = requestData;
        try {
            await axios.delete(ORGANIZATION_AREAS_APIS.removeArea(organizationId, areaName));
            if (runSuccess) runSuccess();
            return areaName;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createOrganizationPaymentInfo = createAsyncThunk(
    'organizations/payment-info/create',
    async (
        requestData: {
            organizationId: string;
            createData: CreateOrganizationPaymentInfoDto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ORGANIZATION_PAYMENT_INFO_APIS.addPaymentInfo(organizationId), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrganizationPaymentInfo = createAsyncThunk(
    'organizations/payment-info/update',
    async (
        requestData: {
            organizationId: string;
            paymentInfoId: number;
            updateData: UpdateOrganizationPaymentInfoDto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, paymentInfoId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(
                ORGANIZATION_PAYMENT_INFO_APIS.updatePaymentInfo(organizationId, paymentInfoId),
                updateData
            );
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationPaymentInfo = createAsyncThunk(
    'organizations/payment-info/delete',
    async (
        requestData: {
            organizationId: string;
            paymentInfoId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { organizationId, paymentInfoId, runSuccess } = requestData;
        try {
            await axios.delete(ORGANIZATION_PAYMENT_INFO_APIS.removePaymentInfo(organizationId, paymentInfoId));
            if (runSuccess) runSuccess();
            return paymentInfoId;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createOrganizationInvitationLimits = createAsyncThunk(
    'organizations/invitation-limits/create',
    async (
        requestData: {
            createData: CreateInvitationLimitInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(TIER_INVITATION_LIMITS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrganizationInvitationLimits = createAsyncThunk(
    'organizations/invitation-limits/update',
    async (
        requestData: {
            id: number;
            updateData: UpdateInvitationLimitInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const { data } = await axios.patch(TIER_INVITATION_LIMITS.update(id), updateData);
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteOrganizationInvitationLimits = createAsyncThunk(
    'organizations/invitation-limits/delete',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.delete(TIER_INVITATION_LIMITS.delete(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getOrganizationMegaCompetitionSales = createAsyncThunk(
    'organizations/mega-competition/get',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;
        try {
            const { data } = await axios.get(ORGANIZATION_COMPETITION_APIS.getMegaCompetitionValue());
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getOrganizationLeaderboard = createAsyncThunk(
    'organizations/leaderboard/get',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;
        try {
            const { data } = await axios.get(ORGANIZATION_COMPETITION_APIS.getLeaderboard());
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
