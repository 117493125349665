import {
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    useTheme,
    useMediaQuery,
    ListItemIcon,
    ListItemText,
    ListItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage, useIntl } from 'react-intl';
import { CircleIcon } from 'lucide-react';

const FAQSection = () => {
    const intl = useIntl();
    const theme = useTheme();
    const FAQData = [
        {
            question: 'question-1',
            answer: (
                <>
                    <Typography>{intl.formatMessage({ id: 'answer-1' })}</Typography>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-1-1' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-1-2' })} />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-1-3' })} />
                    </ListItem>
                </>
            )
        },
        {
            question: 'question-2',
            answer: <Typography>{intl.formatMessage({ id: 'answer-2' })}</Typography>
        },
        {
            question: 'question-3',
            answer: (
                <>
                    <Typography>{intl.formatMessage({ id: 'answer-3' })}</Typography>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-1' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-2' })} />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-3' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-4' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-5' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-3-6' })} />
                    </ListItem>
                </>
            )
        },
        {
            question: 'question-4',
            answer: (
                <>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-4-1' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-4-2' })} />
                    </ListItem>
                </>
            )
        },
        {
            question: 'question-5',
            answer: (
                <>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-5-1' })} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CircleIcon size={12} />
                        </ListItemIcon>
                        <ListItemText primary={intl.formatMessage({ id: 'answer-5-2' })} />
                    </ListItem>
                </>
            )
        }
    ];

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container sx={{ backgroundColor: theme.palette.grey[50] }}>
            <Grid item xs={12} marginTop={2}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{
                            color: theme.palette.primary[600],
                            fontSize: isSmallScreen ? '24px' : '32px',
                            fontWeight: 700,
                            marginTop: '2%'
                        }}
                        align="center"
                    >
                        <FormattedMessage id="frequently-Asked-Questions" />
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} marginBottom={7} marginTop={4}>
                {FAQData.map((FAQ) => (
                    <Box
                        sx={{ display: 'flex', justifyContent: 'center', maxHeight: '688px' }}
                        key={FAQ.question}
                        marginLeft={isSmallScreen ? '10px' : ''}
                        marginRight={isSmallScreen ? '10px' : ''}
                    >
                        <Accordion
                            sx={{
                                backgroundColor: theme.palette.grey[50],
                                width: isSmallScreen ? '100%' : '50%',
                                padding: 0,
                                alignItems: 'center',
                                borderBottom: '1px solid #E5E5E5'
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography
                                    sx={{
                                        fontSize: isSmallScreen ? '16px' : '18px',
                                        fontWeight: '600',
                                        lineHeight: '28px',
                                        color: theme.palette.grey[800]
                                    }}
                                >
                                    <FormattedMessage id={FAQ.question} />
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                                <Typography
                                    sx={{
                                        fontSize: isSmallScreen ? '14px' : '16px',
                                        fontWeight: '400',
                                        lineHeight: '24px',
                                        paddingLeft: '20px',
                                        paddingRight: '20px'
                                    }}
                                >
                                    {FAQ.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
};

export default FAQSection;
