import { FAUNA_API, formattedQuery } from 'store/apis';

import { QueryAccountManagersActivityInterface } from './interfaces';

const res = 'manager-activities';

const ACCOUNT_MANAGER_ACTIVITIES_APIS = {
    query: (query: QueryAccountManagersActivityInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '?'];
        return formattedQuery(formatQuery, query);
    },
    getById: (activityId: number) => `${FAUNA_API}${res}/${activityId}`,
    create: () => `${FAUNA_API}${res}`,
    update: (activityId: number) => `${FAUNA_API}${res}/${activityId}`,
    delete: (activityId: number) => `${FAUNA_API}${res}/${activityId}`,
    metadata: (query: QueryAccountManagersActivityInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '/metadata', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ACCOUNT_MANAGER_ACTIVITIES_APIS;
