export type AnalyticsUserProfile = {
    phoneNumber: string;
    username: string;
};

export type TrackingEvent = {
    name: string;
    params?: Object;
    category?: string;
    label?: string;
};

declare global {
    interface Window {
        Moengage: {
            track_event: (name: string, params: any) => void;
            add_unique_user_id: (id: string) => Promise<void>;
            destroy_session: () => void;
            add_user_attribute: (key: string, value: string) => void;
            add_mobile: (phone: string) => void;
            add_user_name: (name: string) => void;
        };
    }
}

export class Moengage {
    public static APP_ID = process.env.REACT_APP_MOENGAGE_ID;

    public static DATA_CENTER = process.env.REACT_APP_MOENGAGE_CENTER;

    public static DEBUG_MODE = process.env.REACT_APP_MOENGAGE_DEBUG;

    public static track = async ({ name, params, category, label }: TrackingEvent) => {
        if (!this.APP_ID && !this.DATA_CENTER) {
            return;
        }
        try {
            await window.Moengage.track_event(name, { ...params, event_category: category, event_label: label });
        } catch (error) {
            console.log('error in MoEngage tracking');
        }
    };

    public static identify = async (username: string): Promise<void> => {
        if (!this.APP_ID && !this.DATA_CENTER) {
            return;
        }

        try {
            await window.Moengage.add_unique_user_id(username);
        } catch (error) {
            console.log('error in add unique user');
        }
    };

    public static logout = async () => {
        if (!this.APP_ID && !this.DATA_CENTER) {
            return;
        }
        try {
            await window.Moengage.destroy_session();
        } catch (error) {
            console.log('error in destroy');
        }
    };

    public static registerUser = async (name: string, phone: string, tier: string, role: string, group: string, organization: string) => {
        if (!this.APP_ID && !this.DATA_CENTER) {
            return;
        }

        try {
            await window.Moengage.add_user_name(name);
            await window.Moengage.add_mobile(phone);
            await window.Moengage.add_user_attribute('Tier', tier);
            await window.Moengage.add_user_attribute('Role', role);
            await window.Moengage.add_user_attribute('UserGroup', group);
            await window.Moengage.add_user_attribute('Organization', organization);
        } catch (error) {
            console.log('error in add name and phone');
        }
    };
}
