import { createSlice } from '@reduxjs/toolkit';
import {
    createBatchDeveloper,
    createDevelopers,
    getDeveloperById,
    queryCompounds,
    queryDevelopers,
    renewDeveloper,
    updateDeveloper,
    updateDeveloperConfig,
    updateDeveloperConfigStatus
} from './actions';
import { DevelopersInitialStateInterface } from './interfaces';

// initial state
const initialState: DevelopersInitialStateInterface = {
    loading: false,
    error: false,
    refresh: false,
    listLoading: false,
    listError: false,
    developer: null,
    developers: {
        page: 1,
        pages: 1,
        pageSize: 10,
        count: 0,
        data: []
    },
    compounds: [],
    compoundsLoading: false,
    compoundsError: false
};

// ==============================|| SLICE - developers ||============================== //

const developers = createSlice({
    name: 'developers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryDevelopers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryDevelopers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.developers = action.payload;
            })
            .addCase(queryDevelopers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryCompounds.pending, (state) => {
                state.compoundsLoading = true;
                state.compoundsError = false;
            })
            .addCase(queryCompounds.fulfilled, (state, action) => {
                state.compoundsLoading = false;
                state.compounds = action.payload;
            })
            .addCase(queryCompounds.rejected, (state, action) => {
                state.compoundsLoading = false;
                state.compoundsError = action.payload;
            })
            .addCase(createDevelopers.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(createDevelopers.fulfilled, (state, action) => {
                state.refresh = true;
                state.loading = false;
            })
            .addCase(createDevelopers.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(createBatchDeveloper.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(createBatchDeveloper.fulfilled, (state, action) => {
                state.refresh = true;
                state.loading = false;
            })
            .addCase(createBatchDeveloper.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateDeveloperConfig.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(updateDeveloperConfig.fulfilled, (state, action) => {
                state.refresh = true;
                state.loading = false;
            })
            .addCase(updateDeveloperConfig.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getDeveloperById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getDeveloperById.fulfilled, (state, action) => {
                state.loading = false;
                state.developer = action.payload;
            })
            .addCase(getDeveloperById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateDeveloper.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(updateDeveloper.fulfilled, (state, action) => {
                state.refresh = true;
                state.loading = false;
            })
            .addCase(updateDeveloper.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(renewDeveloper.pending, (state) => {
                state.error = false;
            })
            .addCase(renewDeveloper.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(renewDeveloper.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateDeveloperConfigStatus.pending, (state) => {
                state.error = false;
            })
            .addCase(updateDeveloperConfigStatus.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateDeveloperConfigStatus.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default developers.reducer;
