import { createSlice } from '@reduxjs/toolkit';
import {
    createRejection,
    queryRejectionReasons,
    deleteRejectionReason,
    updateRejectionReasonsById,
    getRecentRejectionReasonsByDocIds
} from './actions';
import { RejectionReasonsInitialState } from './interfaces';

const initialState: RejectionReasonsInitialState = {
    loading: false,
    error: false,
    refresh: false,
    rejectionReasons: []
};

const rejectionReasons = createSlice({
    name: 'rejectionReasons',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryRejectionReasons.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryRejectionReasons.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectionReasons = action.payload;
            })
            .addCase(queryRejectionReasons.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createRejection.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createRejection.fulfilled, (state, action) => {
                state.loading = true;
                const newList = [...state.rejectionReasons];
                newList.push(action.payload);
                state.rejectionReasons = newList;
            })
            .addCase(createRejection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateRejectionReasonsById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateRejectionReasonsById.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectionReasons = [...state.rejectionReasons].map((rejectionReason) => {
                    if (action.payload.id === rejectionReason.id) rejectionReason = action.payload;
                    return rejectionReason;
                });
            })
            .addCase(updateRejectionReasonsById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteRejectionReason.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteRejectionReason.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectionReasons = [...state.rejectionReasons].filter((rejectionReason) => action.payload.id !== rejectionReason.id);
            })
            .addCase(deleteRejectionReason.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getRecentRejectionReasonsByDocIds.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getRecentRejectionReasonsByDocIds.fulfilled, (state, action) => {
                state.loading = false;
                state.rejectionReasons = action.payload;
            })
            .addCase(getRecentRejectionReasonsByDocIds.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default rejectionReasons.reducer;
