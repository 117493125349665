export interface ICompound {
    id: number;
    name: string;
    area_id: number;
    developer_id: number;
    developer_name: string;
    slug: string;
    property_types: IPropertyType[];
}

export interface IArea {
    id: number;
    name: string;
}

export interface IDeveloper {
    id: number;
    name: string;
    slug: string;
    all_slugs: {
        en: string;
        ar: string;
    };
}

export interface IPropertyType {
    id: number;
    created_at: Date;
    updated_at: Date;
    name: string;
    manual_ranking: string;
}

export interface ICategory {
    id: number;
    name: string;
    precedence: number;
    created_at: Date;
    updated_at: Date;
}

export interface IStatus {
    id: number;
    status: string;
    status_category: ICategory;
}

export interface IGeneralInitialStateInterface {
    compounds: ICompound[];
    categories: ICategory[];
    areas: IArea[];
    propertyTypes: IPropertyType[];
    amenities: IAmenity[];
    developers: IDeveloper[];
    filterOptions: IFilterOptions | null;
    loading: boolean;
}

export interface IAmenity {
    id: number;
    name: string;
}

export interface IFilterOptions {
    finishings: string[];
    min_bedrooms: number;
    max_bedrooms: number;
    min_bathrooms: number;
    max_bathrooms: number;
    min_unit_area: number;
    max_unit_area: number;
    min_price: number;
    max_price: number;
    price_list: number[];
    min_installment_years: number;
    max_installment_years: number;
    installment_years_list: number[];
    min_down_payment: 43839;
    max_down_payment: 11025000;
    down_payment_list: number[];
    min_installments: number;
    max_installments: number;
    installments_list: number[];
    max_ready_by: string;
    amenities: IAmenity[];
    propertyTypes: IPropertyType[];
    tags: string[];
}

export enum LeadSourcesEnum {
    personal = 'Personal',
    ambassador = 'Ambassador',
    nawy = 'Nawy'
}
export interface CrmPropertyType {
    id: number;
    name: string;
}

export const crmPropertyTypes: CrmPropertyType[] = [
    { id: 21, name: 'Apartment' },
    { id: 1, name: 'Villa' },
    { id: 6, name: 'Twinhouse' },
    { id: 7, name: 'Townhouse' },
    { id: 3, name: 'Duplex' },
    { id: 4, name: 'Penthouse' },
    { id: 5, name: 'Chalet' },
    { id: 9, name: 'Studio' },
    { id: 11, name: 'Cabin' },
    { id: 13, name: 'Clinic' },
    { id: 8, name: 'Office' },
    { id: 10, name: 'Retail' },
    { id: 12, name: 'Family House' },
    { id: 20, name: 'Serviced Apartment' }
];
