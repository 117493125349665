import { Grid, Typography } from '@mui/material';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { gridSpacing } from 'store/constant';
import QRCode from 'assets/images/QRCode.png';

const VodafoneCollaborationPage = () => {
    const { emitEvent } = useMixPanel();

    useEffect(() => {
        emitEvent('View Special Offer Landing Page', { eventType: 'Pixel' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid
            container
            spacing={gridSpacing}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '110vh',
                textAlign: 'center',
                backgroundColor: '#f5f5f5',
                padding: '24px'
            }}
        >
            <Grid item xs={12} md={6} lg={4}>
                <Grid
                    item
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '16px'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'white',
                            padding: '24px',
                            borderRadius: '12px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            src={QRCode}
                            alt="Download App"
                            style={{
                                width: '100%',
                                maxWidth: '300px',
                                height: 'auto'
                            }}
                        />
                    </div>

                    <Typography
                        variant="h6"
                        style={{
                            fontWeight: 'bold',
                            color: 'black',
                            marginTop: '16px'
                        }}
                    >
                        <FormattedMessage id="app-QR-code" />
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default VodafoneCollaborationPage;
