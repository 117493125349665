import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AllowedAudienceGuard from 'utils/route-guard/AllowedAudienceGuard';
import { InvitationTypeEnum } from 'store/interfaces';

// invitations routing
const PartnersInvitationsPage = Loadable(lazy(() => import('views/invitations/PartnersInvitations')));
const InvitationsPage = Loadable(lazy(() => import('views/invitations/UserInvitations')));

// ==============================|| ALLOWED GROUPS ROUTING ||============================== //
// all users are allowed
const allowedGroups = Object.values(InvitationTypeEnum);

// ==============================|| Invitations ROUTING ||============================== //
const InvitationsRoutes = {
    path: '/invitations',
    element: (
        <AllowedAudienceGuard groups={allowedGroups}>
            <MainLayout />
        </AllowedAudienceGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <AllowedAudienceGuard groups={[InvitationTypeEnum.admins]}>
                    <InvitationsPage />
                </AllowedAudienceGuard>
            )
        },
        {
            path: 'partners',
            element: <PartnersInvitationsPage />
        },
        {
            path: ':id',
            element: <PartnersInvitationsPage />
        }
    ]
};

export default InvitationsRoutes;
