// hooks
import { useSelector } from 'store';

// material-ui
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

// interfaces
import { ICompoundDetails } from 'store/slices/nawyInventory/interfaces';

// constants
import { gridSpacing } from 'store/constant';
import { compoundHeadCells } from './InventoryHeaders';

// components
import { useTheme } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import NoDataFound from 'ui-component/NoDataFound';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import CompoundDetailsRow from './CompoundDetailsRow';

export default function CompoundsList() {
    const intl = useIntl();
    const { compoundDetails, loadingCompoundsDetails } = useSelector((state) => state.nawyInventory);

    return (
        <TableContainer sx={{ position: 'relative', overflow: 'auto' }}>
            {loadingCompoundsDetails ? (
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SkeletonTotalIncomeCard />
                    </Grid>
                    <Grid item xs={12}>
                        <SkeletonTotalIncomeCard />
                    </Grid>
                </Grid>
            ) : (
                <Table size="small" aria-label="collapsible table">
                    <EnhancedTableHead />
                    <TableBody>
                        {compoundDetails.map((compound: ICompoundDetails) => (
                            <CompoundDetailsRow key={compound.id} compound={compound} />
                        ))}
                    </TableBody>
                </Table>
            )}
            {!loadingCompoundsDetails && compoundDetails.length === 0 && (
                <NoDataFound message={intl.formatMessage({ id: 'no-compound-match' })} />
            )}
        </TableContainer>
    );
}

function EnhancedTableHead() {
    const theme = useTheme();
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ pl: 3 }} />
                {compoundHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{ whiteSpace: 'nowrap', minWidth: headCell.minWidth }}
                    >
                        <FormattedMessage id={headCell.label} />
                    </TableCell>
                ))}

                <TableCell
                    align="center"
                    padding="normal"
                    sx={{ whiteSpace: 'nowrap', position: 'sticky', right: 0, background: theme.palette.background.paper }}
                >
                    <FormattedMessage id="actions" />
                </TableCell>
            </TableRow>
        </TableHead>
    );
}
