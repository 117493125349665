import { Typography, Grid, useMediaQuery } from '@mui/material';
import landingpage from 'assets/images/landingpage.jpg';
import landingpageAR from 'assets/images/landingpageAr.jpg';
import landingpageMobile from 'assets/images/landingpageMobile.jpg';
import landingpageMobileAR from 'assets/images/landingpageMobileAr.jpg';
import { Box, Container, useTheme } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import frame1 from 'assets/images/frame1.svg';
import frame2 from 'assets/images/frame2.svg';
import frame3 from 'assets/images/frame3.svg';
import JoinNowButton from './JoinNowButton';

function GrowSection({ scrollToTarget }: { scrollToTarget: () => void }) {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const intl = useIntl();
    const locale = intl.locale;
    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <Grid
                container
                style={{
                    position: 'inherit',
                    width: '100%',
                    height: '70vh',
                    display: 'flex',
                    alignItems: isSmallScreen ? 'flex-start' : 'center',
                    justifyContent: 'flex-end',
                    overflow: 'hidden'
                }}
            >
                <img
                    src={
                        isSmallScreen
                            ? locale === 'ar'
                                ? landingpageMobileAR
                                : landingpageMobile
                            : locale === 'ar'
                            ? landingpageAR
                            : landingpage
                    }
                    alt="landingpage"
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        objectFit: 'fill',
                        zIndex: 1
                    }}
                />

                <Grid
                    container
                    alignContent={isSmallScreen ? 'center' : 'center'}
                    justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                    alignItems={isSmallScreen ? 'flex-start' : 'center'}
                    marginTop={isSmallScreen ? 4 : 0}
                    sx={{
                        backgroundColor: 'transparent',
                        position: 'relative',
                        zIndex: 2
                    }}
                >
                    <Grid item alignContent="center" marginLeft={isSmallScreen ? 0 : 8}>
                        <Typography
                            variant="h4"
                            gutterBottom
                            sx={{ color: theme.palette.primary[800] }}
                            fontSize={isSmallScreen ? '22px' : '40px'}
                            fontWeight={700}
                            paragraph
                            align={isSmallScreen ? 'center' : 'left'}
                            maxWidth="500px"
                        >
                            <span style={{ color: '#FF5E00' }}>
                                <FormattedMessage id="Grow" />
                            </span>
                            <FormattedMessage id="your-Business-With-Nawy" />
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{ color: theme.palette.primary[800] }}
                            paragraph
                            align={isSmallScreen ? 'center' : 'left'}
                            marginLeft={isSmallScreen ? 2 : 0}
                            marginRight={isSmallScreen ? 2 : 0}
                        >
                            <FormattedMessage id="high-commission-faster-collection-easier-control" />
                        </Typography>
                        <Grid
                            item
                            marginBottom={isSmallScreen ? 4 : 0}
                            marginLeft={isSmallScreen ? 2 : 0}
                            marginRight={isSmallScreen ? 2 : 0}
                            xs={isSmallScreen ? 12 : 6}
                            display="flex"
                            justifyContent={isSmallScreen ? 'center' : 'flex-start'}
                        >
                            <JoinNowButton
                                scrollToTarget={scrollToTarget}
                                btnColor={theme.palette.primary['400']}
                                btnWidth="363px"
                                btnFontColor="white"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    maxWidth: '1300px',
                    transform: isSmallScreen ? 'translate(-50%, 20%)' : 'translate(-50%, 50%)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    zIndex: 2
                }}
            >
                {isSmallScreen ? (
                    <span
                        style={{
                            position: 'relative',
                            color: 'white',
                            transform: 'translateY(25%)'
                        }}
                    >
                        <Container>
                            <Grid
                                container
                                height="560px"
                                maxWidth="363px"
                                borderRadius="24px"
                                style={{
                                    backgroundImage: `linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                    boxShadow: `0px 8px 10px -6px rgba(16, 24, 40, 0.06),0px 20px 25px -5px rgba(16, 24, 40, 0.06)`,
                                    margin: '0 auto',
                                    width: `calc(100% - 20px)`,
                                    paddingInline: '20px'
                                }}
                            >
                                <Grid item xs={12} alignItems="center">
                                    <Box sx={{ display: 'flex', marginTop: '3%' }} justifyContent="center">
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            color="primary.600"
                                            fontSize={isSmallScreen ? '24px' : '32px'}
                                            fontWeight={700}
                                            align="center"
                                            marginTop={isSmallScreen ? 4 : 0}
                                            marginBottom={isSmallScreen ? 4 : 0}
                                        >
                                            <FormattedMessage id="why-Nawy-Partners" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid gridRow={3} justifyContent="center" marginBottom={isSmallScreen ? 4 : 0}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            paddingBottom: '16px'
                                        }}
                                    >
                                        <Box
                                            alignItems="center"
                                            borderRadius="24px"
                                            style={{
                                                width: '48px',
                                                height: '48px',
                                                flexShrink: '0',
                                                gap: '8px',
                                                background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                borderRadius: '8px',
                                                boxShadow: '0px 1px 3px 0px #10182814'
                                            }}
                                        >
                                            <img src={frame1} alt="frame1" style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '30px' }}>
                                        <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                            <FormattedMessage id="faster-commissions-and-smoother-collection" />
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}>
                                        <Box
                                            alignItems="center"
                                            borderRadius="24px"
                                            style={{
                                                width: '48px',
                                                height: '48px',
                                                flexShrink: '0',
                                                gap: '8px',
                                                background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                borderRadius: '8px',
                                                boxShadow: ' 0px 1px 3px 0px #10182814'
                                            }}
                                        >
                                            <img src={frame2} alt="frame2" style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '30px' }}>
                                        <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                            <FormattedMessage id="reach-a-great-network-of-the-top-developers" />
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}>
                                        <Box
                                            alignItems="center"
                                            borderRadius="24px"
                                            style={{
                                                width: '48px',
                                                height: '48px',
                                                flexShrink: '0',
                                                gap: '8px',
                                                background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                borderRadius: '8px',
                                                boxShadow: '0px 1px 3px 0px #10182814'
                                            }}
                                        >
                                            <img src={frame3} alt="frame3" style={{ width: '100%', height: 'auto' }} />
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            paddingBottom: '30px'
                                        }}
                                    >
                                        <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                            <FormattedMessage id="all-availability-information-in-one-place" />
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </span>
                ) : (
                    <span
                        style={{
                            position: 'relative',
                            color: 'white',
                            fontSize: '20px'
                        }}
                    >
                        <Container maxWidth={false}>
                            <Grid
                                container
                                height="360px"
                                maxWidth="100%"
                                borderRadius="24px"
                                paddingTop={2}
                                style={{
                                    backgroundImage: `linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                    boxShadow: `0px 8px 10px -6px rgba(16, 24, 40, 0.06),0px 20px 25px -5px rgba(16, 24, 40, 0.06)`,
                                    margin: '0 auto',
                                    width: `calc(100% - 80px)`,
                                    paddingInline: '20px'
                                }}
                            >
                                <Grid item xs={12}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>
                                        <Typography
                                            variant="h4"
                                            gutterBottom
                                            color="primary.600"
                                            fontSize={isSmallScreen ? '24px' : '32px'}
                                            fontWeight={700}
                                        >
                                            <FormattedMessage id="why-Nawy-Partners" />
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography variant="h4" gutterBottom color="primary.800" fontSize="16px" fontWeight={400}>
                                            <FormattedMessage id="we-provide-full-support-from-highly-trained-professionals" />
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid container spacing={4} justifyContent="center">
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                paddingBottom: '16px'
                                            }}
                                        >
                                            <Box
                                                alignItems="center"
                                                borderRadius="24px"
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    flexShrink: '0',
                                                    gap: '8px',
                                                    background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                    borderRadius: '8px',
                                                    boxShadow: '0px 1px 3px 0px #10182814'
                                                }}
                                            >
                                                <img src={frame1} alt="frame1" style={{ width: '100%', height: 'auto' }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                                <FormattedMessage id="faster-commissions-and-smoother-collection" />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}
                                        >
                                            <Box
                                                alignItems="center"
                                                borderRadius="24px"
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    flexShrink: '0',
                                                    gap: '8px',
                                                    background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                    borderRadius: '8px',
                                                    boxShadow: ' 0px 1px 3px 0px #10182814'
                                                }}
                                            >
                                                <img src={frame2} alt="frame2" style={{ width: '100%', height: 'auto' }} />
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}
                                        >
                                            <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                                <FormattedMessage id="reach-a-great-network-of-the-top-developers" />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Box
                                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '16px' }}
                                        >
                                            <Box
                                                alignItems="center"
                                                borderRadius="24px"
                                                style={{
                                                    width: '48px',
                                                    height: '48px',
                                                    flexShrink: '0',
                                                    gap: '8px',
                                                    background: `linear-gradient(180deg, #FFF 0%, #E7EDF3 100%)`,
                                                    borderRadius: '8px',
                                                    boxShadow: '0px 1px 3px 0px #10182814'
                                                }}
                                            >
                                                <img src={frame3} alt="frame3" style={{ width: '100%', height: 'auto' }} />
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography component="p" align="center" color="primary.800" fontSize="16px" fontWeight="500">
                                                <FormattedMessage id="all-availability-information-in-one-place" />
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </span>
                )}
            </Grid>
        </div>
    );
}
export default GrowSection;
