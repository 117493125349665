import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { queryCompounds } from 'store/slices/developers/actions';
import { Compound } from 'store/slices/propertiesListing/interfaces';

// allowPrimaryCil => lock the return to the compounds that the developer have primary CIls configured
interface CompoundFilterProps {
    defaultValueId?: number | undefined;
    allowPrimaryCil?: boolean;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    handleSearch?: (newValue: number | undefined) => void;
    handleSelectedCompound?: (compound: Compound | null | undefined) => void;
    error?: boolean;
    helperText?: string | false;
    required?: boolean;
}

const CompoundFilter = ({
    defaultValueId,
    allowPrimaryCil,
    handleSearch,
    handleSelectedCompound,
    helperText,
    size = 'small',
    fullWidth = false,
    error = false,
    required = false
}: CompoundFilterProps) => {
    const intl = useIntl();
    const [selectCompound, setSelectCompound] = useState<Compound | null | undefined>(null);
    const [compoundsSearchQuery, setCompoundsSearchQuery] = useState('');
    const { compounds, compoundsLoading } = useSelector((state) => state.developers);
    const firstRender = useRef(true);

    useEffect(() => {
        dispatch(queryCompounds({ query: { name: compoundsSearchQuery, allowPrimaryCil } }));
    }, [allowPrimaryCil, compoundsSearchQuery]);

    const handleOnChange = (newValue?: Compound | null) => {
        setSelectCompound(newValue);
        if (handleSelectedCompound) handleSelectedCompound(newValue);
        if (handleSearch) handleSearch(newValue?.id);
    };

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && compounds.length > 0) {
            firstRender.current = false;
            const compound = compounds.find((c) => c.id === defaultValueId) ?? null;
            setSelectCompound(compound);
        }
    }, [compounds, defaultValueId]);

    return (
        <Autocomplete
            autoHighlight
            size={size}
            id="compoundId"
            options={compounds}
            fullWidth={fullWidth}
            isOptionEqualToValue={(option, v) => option.id === v.id}
            getOptionLabel={(option) => option?.name}
            onChange={(event, newValue) => handleOnChange(newValue ?? null)}
            value={selectCompound}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid item xs={12} pl={1}>
                        <Typography textTransform="capitalize">{option.name}</Typography>
                        <Typography variant="subtitle2" textTransform="capitalize">
                            {option.developer.name}
                        </Typography>
                    </Grid>
                </li>
            )}
            onInputChange={(event, v) => setCompoundsSearchQuery(v)}
            loading={compoundsLoading}
            loadingText={intl.formatMessage({ id: 'loading-compounds' })}
            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={`${intl.formatMessage({ id: 'compound' })} ${required ? '*' : ''}`}
                    name="compound"
                    error={error}
                    helperText={helperText}
                    sx={{ textTransform: 'capitalize' }}
                />
            )}
        />
    );
};

export default CompoundFilter;
