// types
import { createSlice } from '@reduxjs/toolkit';

import {
    createReservation,
    deleteReservationDocument,
    getReservationAnalytica,
    getReservationById,
    queryReservation,
    updateReservation,
    updateReservationProperty,
    uploadReservationDocument
} from './actions';
import { ReservationsInitialState } from './interfaces';

// initial state
const initialState: ReservationsInitialState = {
    loading: false,
    listLoading: false,
    updateReservationLoading: false,
    updateReservationPropertyLoading: false,
    uploadReservationDocumentLoading: false,
    deleteReservationDocumentLoading: false,
    updateSaleCommissionTypeLoading: false,
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    reservations: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    analytica: {
        totalEOIPrice: 0,
        totalResPrice: 0,
        countEOI: 0,
        countRes: 0
    },
    eoiAnalytica: {
        count: 0,
        totalPrice: 0
    },
    reservationAnalytica: {
        count: 0,
        totalPrice: 0
    },
    analyticaLoading: false,
    analyticaError: false
};

// ==============================|| SLICE - SALE CLAIMS ||============================== //

const reservations = createSlice({
    name: 'reservations',
    initialState,
    reducers: {
        refreshReservationsList: (state) => {
            state.listRefresh = !state.listRefresh;
        },
        refreshReservation: (state) => {
            state.refresh = !state.refresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(queryReservation.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryReservation.fulfilled, (state, action) => {
                state.listLoading = false;
                state.reservations = action.payload;
            })
            .addCase(queryReservation.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(createReservation.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(createReservation.fulfilled, (state, action) => {
                state.reservation = action.payload.reservation;
                state.refresh = true;
                state.loading = false;
            })
            .addCase(createReservation.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getReservationById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getReservationById.fulfilled, (state, action) => {
                state.loading = false;
                state.reservation = action.payload;
            })
            .addCase(getReservationById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateReservation.pending, (state, action) => {
                state.updateReservationLoading = true;
                state.error = false;
            })
            .addCase(updateReservation.rejected, (state, action) => {
                state.updateReservationLoading = false;
                state.error = action.payload;
            })
            .addCase(updateReservation.fulfilled, (state, action) => {
                state.updateReservationLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateReservationProperty.pending, (state, action) => {
                state.updateReservationPropertyLoading = true;
                state.error = false;
            })
            .addCase(updateReservationProperty.rejected, (state, action) => {
                state.updateReservationPropertyLoading = false;
                state.error = action.payload;
            })
            .addCase(updateReservationProperty.fulfilled, (state, action) => {
                state.updateReservationPropertyLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(uploadReservationDocument.pending, (state) => {
                state.uploadReservationDocumentLoading = true;
                state.error = false;
            })
            .addCase(uploadReservationDocument.rejected, (state, action) => {
                state.uploadReservationDocumentLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadReservationDocument.fulfilled, (state) => {
                state.uploadReservationDocumentLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteReservationDocument.pending, (state) => {
                state.deleteReservationDocumentLoading = true;
                state.error = false;
            })
            .addCase(deleteReservationDocument.rejected, (state, action) => {
                state.deleteReservationDocumentLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteReservationDocument.fulfilled, (state) => {
                state.deleteReservationDocumentLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(getReservationAnalytica.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getReservationAnalytica.fulfilled, (state, action) => {
                const { reservationData, eoiDate } = action.payload;
                state.eoiAnalytica = {
                    count: eoiDate.count,
                    totalPrice: eoiDate.totalValue
                };

                state.reservationAnalytica = {
                    count: reservationData.count,
                    totalPrice: reservationData.totalValue
                };

                state.analyticaLoading = false;
                state.analytica = {
                    countEOI: action.payload.eoiDate.count,
                    totalEOIPrice: action.payload.eoiDate.totalValue,
                    countRes: action.payload.reservationData.count,
                    totalResPrice: action.payload.reservationData.totalValue
                };
            })
            .addCase(getReservationAnalytica.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default reservations.reducer;

export const { refreshReservationsList, refreshReservation } = reservations.actions;
