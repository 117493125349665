// third party
import { Box } from '@mui/material';
import useConfig from 'hooks/useConfig';
import Chart, { Props as ChartProps } from 'react-apexcharts';

// ===========================|| REVENUE CHART CARD ||=========================== //

const DonutChart = ({ chartData }: { chartData: ChartProps }) => {
    const { rtlLayout } = useConfig();
    return (
        <Box sx={{ direction: rtlLayout ? 'rtl' : 'ltr', '& .apexcharts-legend-text': { marginLeft: rtlLayout ? '8px' : 'initial' } }}>
            <Chart {...chartData} />
        </Box>
    );
};

export default DonutChart;
