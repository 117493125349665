import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import ACCOUNT_MANAGER_ACTIVITIES_APIS from './apis';
import {
    CreateAccountManagersActivityInterface,
    QueryAccountManagersActivityInterface,
    UpdateAccountManagersActivityInterface
} from './interfaces';

export const queryAccountManagerActivities = createAsyncThunk(
    'account-manager-activities/query',
    async (
        requestData: {
            queryData: QueryAccountManagersActivityInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ACCOUNT_MANAGER_ACTIVITIES_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagerActivityById = createAsyncThunk(
    'account-manager-activities/get-By-id',
    async (
        requestData: {
            activityId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { activityId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ACCOUNT_MANAGER_ACTIVITIES_APIS.getById(activityId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createAccountManagerActivity = createAsyncThunk(
    'account-manager-activities/create',
    async (
        requestData: {
            createBody: CreateAccountManagersActivityInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createBody, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ACCOUNT_MANAGER_ACTIVITIES_APIS.create(), createBody);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateAccountManagerActivity = createAsyncThunk(
    'account-manager-activities/update',
    async (
        requestData: {
            activityId: number;
            updateBody: UpdateAccountManagersActivityInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { activityId, updateBody, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ACCOUNT_MANAGER_ACTIVITIES_APIS.update(activityId), updateBody);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteAccountManagerActivity = createAsyncThunk(
    'account-manager-activities/delete',
    async (
        requestData: {
            activityId: number;

            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { activityId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ACCOUNT_MANAGER_ACTIVITIES_APIS.delete(activityId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagerActivityMetadata = createAsyncThunk(
    'account-manager-activities/get-metadata',
    async (
        requestData: {
            queryData: QueryAccountManagersActivityInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ACCOUNT_MANAGER_ACTIVITIES_APIS.metadata(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
