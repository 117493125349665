import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { AccountManagersRole } from '../accountManagers/interfaces';
import OPERATION_MANAGERS_APIS from './apis';
import {
    OperationManagerFullDataInterface,
    OperationManagerInterface,
    // OperationManagersList,
    QueryOperationManagersInterface
} from './interfaces';

export const queryOperationManagers = createAsyncThunk(
    'operationManagers/query',
    async (
        requestData: {
            queryData: QueryOperationManagersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OperationManagerInterface[] | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(OPERATION_MANAGERS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getOperationManagerById = createAsyncThunk(
    'operationManagers/get-By-id',
    async (
        requestData: {
            operationManagerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OperationManagerInterface | any> => {
        const { operationManagerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(OPERATION_MANAGERS_APIS.getById(operationManagerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createOperationManagerFromExistingUser = createAsyncThunk(
    'operationManagers/create-Operation-manager-from-existing-user',
    async (
        requestData: {
            userData: {
                cognitoId: string;
                phone: string;
                name: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OperationManagerFullDataInterface | any> => {
        const { userData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(OPERATION_MANAGERS_APIS.createOperationManagerFromExistingUser(), userData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOperationManagerRole = createAsyncThunk(
    'operationManagers/update-Operation-manager-role',
    async (
        requestData: {
            operationManagerCId: string;
            newRole: {
                role: AccountManagersRole;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<OperationManagerFullDataInterface | any> => {
        const { operationManagerCId, newRole, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(OPERATION_MANAGERS_APIS.changeOperationManagerRole(operationManagerCId), newRole);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
