import CancelIcon from '@mui/icons-material/Cancel';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputAdornment,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import useAuth from 'hooks/useAuth';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import { AccountManagerFullDataInterface, AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { queryAccountManagersTeams } from 'store/slices/accountManagerTeams/actions';
import { AccountManagerTeamInterface } from 'store/slices/accountManagerTeams/interfaces';

interface TeamMember {
    id: string;
    name: string;
    fullData: AccountManagerFullDataInterface;
    isTeamHead?: boolean;
}

interface Team {
    id: string;
    name: string;
    collapsible: boolean;
    teamHead: string;
    teamHeadData: AccountManagerFullDataInterface;
    members: TeamMember[];
    allMembers: TeamMember[]; // This includes both team head and members
}

type TeamDisplayItem = { id: string; label: string; type: 'team'; team: Team };
type MemberDisplayItem = { id: string; label: string; type: 'member'; member: TeamMember };
type DisplayItem = TeamDisplayItem | MemberDisplayItem;

export interface SelectedTeamWithMembers {
    teamHead: AccountManagerFullDataInterface;
    members: AccountManagerFullDataInterface[];
}

interface AccountManagersTeamsMultiSelectProps {
    /** Initial selected teams */
    initialSelectedTeams?: Team[];
    /** Initial selected members */
    initialSelectedMembers?: string[];
    /** Callback when selection changes */
    onSelectionChange?: (selectedTeams: SelectedTeamWithMembers[], selectedAll: boolean) => void;
    /** Whether the component is disabled */
    disabled?: boolean;
    /** Custom placeholder text when no items are selected */
    placeholder?: React.ReactNode;
    /** Maximum height of the menu in pixels */
    maxMenuHeight?: number;
    /** Whether to show the search field */
    showSearch?: boolean;
    /** Whether to show the select all option */
    showSelectAll?: boolean;
    /** Callback when teams are selected */
    onChange?: (teams: Team[]) => void;
}

export interface AccountManagersTeamsMultiSelectRef {
    reset: () => void;
    closeDropdown: () => void;
}

// Constants
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

// Sub-components
const SearchField = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => (
    <MenuItem
        style={{
            padding: '8px',
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 3
        }}
        sx={{
            bgcolor: 'white',
            '&:hover': { bgcolor: 'white' }
        }}
        disableRipple
    >
        <TextField
            size="small"
            fullWidth
            placeholder="Search..."
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            autoComplete="off"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                )
            }}
        />
    </MenuItem>
);

const SelectAllOption = ({
    filteredTeams,
    selectedTeams,
    selectedMembers,
    onChange
}: {
    filteredTeams: Team[];
    selectedTeams: Team[];
    selectedMembers: string[];
    onChange: (event: SelectChangeEvent<string[]>) => void;
}) => {
    const handleSelectAll = (event: React.MouseEvent | React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();
        const allTeamsSelected = filteredTeams.every((team) => selectedTeams.some((selected) => selected.id === team.id));
        const allMembersSelected = filteredTeams.every((team) => team.allMembers?.every((member) => selectedMembers.includes(member.id)));

        const selectedNames = !allTeamsSelected || !allMembersSelected ? ['all'] : [];
        onChange({ target: { value: selectedNames, name: '' } } as SelectChangeEvent<string[]>);
    };

    return (
        <MenuItem
            value="all"
            onClick={handleSelectAll}
            sx={{
                position: 'sticky',
                top: 56,
                backgroundColor: 'white !important',
                zIndex: 2,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                '&:hover': {
                    backgroundColor: 'white !important'
                }
            }}
        >
            <Checkbox
                checked={
                    filteredTeams.length > 0 && filteredTeams.every((team) => selectedTeams.some((selected) => selected.id === team.id))
                }
                indeterminate={
                    selectedTeams.length > 0 && !filteredTeams.every((team) => selectedTeams.some((selected) => selected.id === team.id))
                }
                onChange={handleSelectAll}
                onClick={(e) => e.stopPropagation()}
                size="small"
            />
            <ListItemText primary={<FormattedMessage id="select-all" />} />
        </MenuItem>
    );
};

const TeamMemberItem = ({
    member,
    teamId,
    isSelected,
    onMemberClick,
    onMemberChange
}: {
    member: TeamMember;
    teamId: string;
    isSelected: boolean;
    onMemberClick: (member: TeamMember, teamId: string, event: React.MouseEvent) => void;
    onMemberChange: (member: TeamMember, teamId: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
    <MenuItem
        sx={{
            pl: 6,
            py: 1,
            '&:hover': {
                bgcolor: 'action.selected'
            }
        }}
        onClick={(e) => onMemberClick(member, teamId, e)}
    >
        <Checkbox
            checked={isSelected}
            onChange={(e) => onMemberChange(member, teamId, e)}
            size="small"
            onClick={(e) => e.stopPropagation()}
        />
        <ListItemText
            primary={
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                        {member.name}
                    </Typography>
                    {member.isTeamHead && (
                        <Typography
                            variant="caption"
                            sx={{
                                bgcolor: 'primary.main',
                                color: 'white',
                                px: 1,
                                py: 0.25,
                                borderRadius: 1
                            }}
                        >
                            Team Head
                        </Typography>
                    )}
                </Stack>
            }
        />
    </MenuItem>
);

const SelectedItemsDisplay = ({
    displayItems,
    handleDelete,
    handleMemberDelete,
    handleRemoveRemainingItems
}: {
    displayItems: DisplayItem[];
    handleDelete: (team: Team, event?: React.MouseEvent) => void;
    handleMemberDelete: (memberId: string, event: React.MouseEvent) => void;
    handleRemoveRemainingItems: (event: React.MouseEvent) => void;
}) => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, minHeight: 32, alignItems: 'center' }}>
        {displayItems.slice(0, 2).map((item) =>
            item.type === 'team' ? (
                <Chip
                    key={item.id}
                    label={item.label}
                    onDelete={(e) => handleDelete(item.team, e)}
                    deleteIcon={
                        <CancelIcon
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        />
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ height: 24 }}
                />
            ) : (
                <Chip
                    key={item.id}
                    label={item.label}
                    onDelete={(e) => handleMemberDelete(item.id, e)}
                    deleteIcon={
                        <CancelIcon
                            onMouseDown={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        />
                    }
                    size="small"
                    variant="outlined"
                    color="primary"
                    sx={{ height: 24 }}
                />
            )
        )}
        {displayItems.length > 2 && (
            <RemainingItemsTooltip
                displayItems={displayItems}
                handleDelete={handleDelete}
                handleMemberDelete={handleMemberDelete}
                handleRemoveRemainingItems={handleRemoveRemainingItems}
            />
        )}
    </Box>
);

const RemainingItemsTooltip = ({
    displayItems,
    handleDelete,
    handleMemberDelete,
    handleRemoveRemainingItems
}: {
    displayItems: DisplayItem[];
    handleDelete: (team: Team, event?: React.MouseEvent) => void;
    handleMemberDelete: (memberId: string, event: React.MouseEvent) => void;
    handleRemoveRemainingItems: (event: React.MouseEvent) => void;
}) => (
    <Tooltip
        title={
            <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                <Stack spacing={0.5} sx={{ p: 0.5 }}>
                    {displayItems.slice(2).map((item) => (
                        <Stack key={item.id} direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Typography variant="caption" sx={{ color: 'white' }}>
                                {item.label}
                            </Typography>
                            <CancelIcon
                                sx={{
                                    color: 'white',
                                    fontSize: '0.875rem',
                                    cursor: 'pointer',
                                    '&:hover': { opacity: 0.8 }
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    item.type === 'team' ? handleDelete(item.team, e) : handleMemberDelete(item.id, e);
                                }}
                            />
                        </Stack>
                    ))}
                </Stack>
            </Box>
        }
        PopperProps={{
            sx: {
                '& .MuiTooltip-tooltip': {
                    maxWidth: 'none'
                }
            }
        }}
    >
        <Chip
            label={`+${displayItems.length - 2} more`}
            size="small"
            variant="outlined"
            color="primary"
            onDelete={handleRemoveRemainingItems}
            deleteIcon={
                <CancelIcon
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                />
            }
        />
    </Tooltip>
);

// Main component
const AccountManagersTeamsMultiSelect = forwardRef<AccountManagersTeamsMultiSelectRef, AccountManagersTeamsMultiSelectProps>(
    (
        {
            initialSelectedTeams = [],
            initialSelectedMembers = [],
            onSelectionChange,
            disabled,
            placeholder = <FormattedMessage id="choose-teams" />,
            maxMenuHeight = ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
            showSearch = true,
            showSelectAll = true,
            onChange
        },
        ref
    ) => {
        const { user } = useAuth();
        const dispatch = useDispatch();
        const { teams: teamsData, loading } = useSelector((state) => state.accountManagersTeams);

        const [selectedTeams, setSelectedTeams] = useState<Team[]>(initialSelectedTeams);
        const [selectedMembers, setSelectedMembers] = useState<string[]>(initialSelectedMembers);
        const [searchTerm, setSearchTerm] = useState('');
        const [expandedTeams, setExpandedTeams] = useState<string[]>([]);
        const [open, setOpen] = useState(false);

        const isAdmin = user?.groups?.includes(InvitationTypeEnum.admins);

        useImperativeHandle(ref, () => ({
            reset: () => {
                setSelectedTeams([]);
                setSelectedMembers([]);
                setSearchTerm('');
                setExpandedTeams([]);
            },
            closeDropdown: () => setOpen(false)
        }));

        useEffect(() => {
            if (!teamsData?.data?.length) {
                dispatch(queryAccountManagersTeams({ queryData: { page: 1, pageSize: 100, excludeDeactivated: true } }));
            }
        }, [dispatch, teamsData?.data?.length]);

        const teams =
            teamsData?.data
                ?.map((team: AccountManagerTeamInterface) => {
                    const teamHead = team.accountManagers?.find((am) => am.role === AccountManagersRole.head);
                    const teamMembers =
                        team.accountManagers
                            ?.filter((am) => am.role === AccountManagersRole.member)
                            ?.map((manager) => ({
                                id: manager.cognitoId,
                                name: manager.user.name,
                                fullData: manager,
                                isTeamHead: false
                            })) ?? [];

                    const teamHeadMember = teamHead
                        ? {
                              id: teamHead.cognitoId,
                              name: teamHead.user.name,
                              fullData: teamHead,
                              isTeamHead: true
                          }
                        : null;

                    const allMembers = teamHeadMember ? [teamHeadMember, ...teamMembers] : teamMembers;

                    return {
                        id: team.id.toString(),
                        name: team.name,
                        collapsible: isAdmin ? allMembers.length > 0 : false,
                        teamHead: teamHead?.user?.name ?? team.name,
                        teamHeadData: teamHead!,
                        members: teamMembers,
                        allMembers
                    };
                })
                .filter((team) => team.members.length > 0) || // Exclude teams with no additional members
            [];

        const handleChange = (event: SelectChangeEvent<string[]>) => {
            const selectedNames = event.target.value as string[];

            if (selectedNames.includes('all')) {
                // Include all teams, even those with no members
                const selectableTeams = teams;
                setSelectedTeams(selectableTeams);

                // Add all members, including team heads
                const allMembers = new Set<string>();
                selectableTeams.forEach((team) => {
                    if (team.allMembers) {
                        team.allMembers.forEach((member) => {
                            allMembers.add(member.id);
                        });
                    }
                });
                setSelectedMembers(Array.from(allMembers) as string[]);
            } else {
                setSelectedTeams([]);
                setSelectedMembers([]);
            }
        };

        const handleTeamClick = (team: Team, event: React.MouseEvent) => {
            event.stopPropagation();
            const isSelected = selectedTeams.some((t) => t.id === team.id);

            setSelectedTeams(isSelected ? selectedTeams.filter((t) => t.id !== team.id) : [...selectedTeams, team]);

            if (team.allMembers) {
                setSelectedMembers((prev) => {
                    const newMembers = new Set(prev);
                    team.allMembers.forEach((member) => {
                        if (!isSelected) {
                            newMembers.add(member.id);
                        } else {
                            newMembers.delete(member.id);
                        }
                    });
                    return Array.from(newMembers);
                });
            }
        };

        const handleDelete = (teamToDelete: Team, event?: React.MouseEvent) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            setSelectedTeams((prev) => prev.filter((team) => team.id !== teamToDelete.id));

            if (teamToDelete.collapsible && teamToDelete.allMembers) {
                const memberIds = teamToDelete.allMembers.map((m) => m.id);
                setSelectedMembers((prev) => prev.filter((id) => !memberIds.includes(id)));
            }
        };

        const handleMemberDelete = (memberId: string, event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            setSelectedMembers((prev) => prev.filter((id) => id !== memberId));

            const parentTeam = teams.find((team) => team.allMembers?.some((m) => m.id === memberId));
            if (parentTeam?.allMembers) {
                const remainingMembers = parentTeam.allMembers
                    .map((m) => m.id)
                    .filter((id) => id !== memberId)
                    .filter((id) => selectedMembers.includes(id));

                if (remainingMembers.length === 0) {
                    setSelectedTeams((prev) => prev.filter((team) => team.id !== parentTeam.id));
                }
            }
        };

        const handleToggleExpand = (teamId: string, event: React.MouseEvent) => {
            event.stopPropagation();
            setExpandedTeams((prev) => (prev.includes(teamId) ? prev.filter((id) => id !== teamId) : [...prev, teamId]));
        };

        const handleMemberClick = (member: TeamMember, teamId: string, event: React.MouseEvent) => {
            event.stopPropagation();
            const isSelected = selectedMembers.includes(member.id);
            const newSelectedMembers = isSelected ? selectedMembers.filter((id) => id !== member.id) : [...selectedMembers, member.id];

            setSelectedMembers(newSelectedMembers);

            const team = teams.find((t) => t.id === teamId);
            if (team) {
                const hasSelectedMembers = team.allMembers.some((m) => newSelectedMembers.includes(m.id));

                if (!hasSelectedMembers) {
                    setSelectedTeams((prev) => prev.filter((t) => t.id !== teamId));
                } else if (!selectedTeams.some((t) => t.id === teamId)) {
                    setSelectedTeams((prev) => [...prev, team]);
                }
            }
        };

        const handleMemberChange = (member: TeamMember, teamId: string, event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();
            const isSelected = selectedMembers.includes(member.id);
            const newSelectedMembers = isSelected ? selectedMembers.filter((id) => id !== member.id) : [...selectedMembers, member.id];

            setSelectedMembers(newSelectedMembers);

            const team = teams.find((t) => t.id === teamId);
            if (team) {
                const hasSelectedMembers = team.allMembers.some((m) => newSelectedMembers.includes(m.id));

                if (!hasSelectedMembers) {
                    setSelectedTeams((prev) => prev.filter((t) => t.id !== teamId));
                } else if (!selectedTeams.some((t) => t.id === teamId)) {
                    setSelectedTeams((prev) => [...prev, team]);
                }
            }
        };

        const handleSearch = (value: string) => {
            setSearchTerm(value);
            if (value) {
                const teamsWithMatchingMembers = teams
                    .filter(
                        (team) =>
                            team.collapsible &&
                            (team.name.toLowerCase().includes(value.toLowerCase()) ||
                                team.allMembers?.some((member) => member.name.toLowerCase().includes(value.toLowerCase())))
                    )
                    .map((team) => team.id);

                setExpandedTeams((prev) => {
                    const newExpanded = new Set([...prev, ...teamsWithMatchingMembers]);
                    return Array.from(newExpanded);
                });
            } else {
                setExpandedTeams([]);
            }
        };

        const handleRemoveRemainingItems = (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();

            const displayItems = [
                ...selectedTeams
                    .filter((team) => !team.collapsible)
                    .map((team) => ({ id: team.id, label: team.name, type: 'team' as const, team })),
                ...selectedMembers
                    .map((memberId) => {
                        const team = teams.find((t) => t.allMembers?.some((m) => m.id === memberId));
                        const member = team?.allMembers?.find((m) => m.id === memberId);

                        if (team?.collapsible && member) {
                            return {
                                id: member.id,
                                label: member.name,
                                type: 'member' as const,
                                member,
                                team
                            };
                        }
                        return null;
                    })
                    .filter((item): item is NonNullable<typeof item> => item !== null)
            ];

            const itemsToKeep = displayItems.slice(0, 2);
            const newSelectedTeams: Team[] = [];
            const newSelectedMembers: string[] = [];

            itemsToKeep.forEach((item) => {
                if (item.type === 'team') {
                    newSelectedTeams.push(item.team);
                } else if (item.type === 'member' && item.team) {
                    newSelectedMembers.push(item.member.id);
                    if (!newSelectedTeams.some((t) => t.id === item.team.id)) {
                        newSelectedTeams.push(item.team);
                    }
                }
            });

            setSelectedTeams(newSelectedTeams);
            setSelectedMembers(newSelectedMembers);
        };

        useEffect(() => {
            const notifyChanges = () => {
                if (onSelectionChange) {
                    const selectedTeamsWithMembers = selectedTeams.map((team) => {
                        const selectedTeamMembers = team.allMembers
                            .filter((member) => selectedMembers.includes(member.id))
                            .map((member) => member.fullData);

                        return {
                            teamHead: team.teamHeadData,
                            members: selectedTeamMembers
                        };
                    });

                    const isAllSelected = selectedTeams.length === teamsData.data.length;
                    onSelectionChange(selectedTeamsWithMembers, isAllSelected);
                }

                if (onChange) {
                    onChange(selectedTeams);
                }
            };

            const timeoutId = setTimeout(notifyChanges, 0);
            return () => clearTimeout(timeoutId);
        }, [selectedTeams, selectedMembers, onSelectionChange, onChange, teamsData.data.length]);

        const filteredTeams = teams.filter((team) => {
            const teamNameMatch = team.name.toLowerCase().includes(searchTerm.toLowerCase());
            const membersMatch = team.allMembers?.some((member) => member.name.toLowerCase().includes(searchTerm.toLowerCase()));
            return teamNameMatch || membersMatch;
        });

        const displayItems = [
            ...selectedTeams
                .filter((team) => !team.collapsible)
                .map((team) => ({ id: team.id, label: team.name, type: 'team' as const, team })),
            ...selectedMembers
                .map((memberId) => {
                    const team = teams.find((t) => t.allMembers?.some((m) => m.id === memberId));
                    const member = team?.allMembers?.find((m) => m.id === memberId);

                    if (team?.collapsible && member) {
                        return {
                            id: member.id,
                            label: member.name,
                            type: 'member' as const,
                            member,
                            team
                        };
                    }
                    return null;
                })
                .filter((item): item is NonNullable<typeof item> => item !== null)
        ];

        return (
            <FormControl fullWidth>
                <Select
                    multiple
                    value={selectedTeams.map((team) => team.name)}
                    onChange={handleChange}
                    displayEmpty
                    size="small"
                    disabled={disabled || loading}
                    sx={{ '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'rgba(0, 0, 0, 0.23)' } }}
                    renderValue={(selected) =>
                        selected.length === 0 ? (
                            <Typography color="text.secondary">{placeholder}</Typography>
                        ) : (
                            <SelectedItemsDisplay
                                displayItems={displayItems}
                                handleDelete={handleDelete}
                                handleMemberDelete={handleMemberDelete}
                                handleRemoveRemainingItems={handleRemoveRemainingItems}
                            />
                        )
                    }
                    MenuProps={{
                        ...MenuProps,
                        PaperProps: {
                            ...MenuProps.PaperProps,
                            style: {
                                ...MenuProps.PaperProps.style,
                                maxHeight: maxMenuHeight
                            }
                        },
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                >
                    {showSearch && <SearchField value={searchTerm} onChange={handleSearch} />}
                    {showSelectAll && filteredTeams.length > 0 && isAdmin && (
                        <SelectAllOption
                            filteredTeams={filteredTeams}
                            selectedTeams={selectedTeams}
                            selectedMembers={selectedMembers}
                            onChange={handleChange}
                        />
                    )}
                    {filteredTeams.length === 0 ? (
                        <MenuItem disabled>
                            <ListItemText primary="No results found" sx={{ textAlign: 'center', color: 'text.secondary' }} />
                        </MenuItem>
                    ) : (
                        filteredTeams.map((team) => (
                            <Box key={team.id}>
                                {isAdmin ? (
                                    <MenuItem
                                        value={team.name}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center'
                                        }}
                                        onClick={(e) => handleTeamClick(team, e)}
                                    >
                                        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                                            <Checkbox
                                                checked={selectedTeams.some((selected) => selected.name === team.name)}
                                                size="small"
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={(e) => handleTeamClick(team, e as any)}
                                            />
                                            <ListItemText
                                                primary={team.teamHead}
                                                sx={{
                                                    '& .MuiTypography-root': {
                                                        fontSize: '0.875rem'
                                                    }
                                                }}
                                            />
                                        </Box>
                                        {team.collapsible && team.allMembers && (
                                            <IconButton size="small" onClick={(e) => handleToggleExpand(team.id, e)} sx={{ ml: 1 }}>
                                                {expandedTeams.includes(team.id) ? (
                                                    <ExpandLess fontSize="small" />
                                                ) : (
                                                    <ExpandMore fontSize="small" />
                                                )}
                                            </IconButton>
                                        )}
                                    </MenuItem>
                                ) : (
                                    team.allMembers?.map((member) => (
                                        <TeamMemberItem
                                            key={member.id}
                                            member={member}
                                            teamId={team.id}
                                            isSelected={selectedMembers.includes(member.id)}
                                            onMemberClick={handleMemberClick}
                                            onMemberChange={handleMemberChange}
                                        />
                                    ))
                                )}
                                {isAdmin && team.collapsible && expandedTeams.includes(team.id) && (
                                    <Box sx={{ bgcolor: 'action.hover' }}>
                                        {team.allMembers?.map((member) => (
                                            <TeamMemberItem
                                                key={member.id}
                                                member={member}
                                                teamId={team.id}
                                                isSelected={selectedMembers.includes(member.id)}
                                                onMemberClick={handleMemberClick}
                                                onMemberChange={handleMemberChange}
                                            />
                                        ))}
                                    </Box>
                                )}
                            </Box>
                        ))
                    )}
                </Select>
            </FormControl>
        );
    }
);

export default AccountManagersTeamsMultiSelect;
