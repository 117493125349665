import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { IProperty } from 'store/slices/nawyInventory/interfaces';
import NoDataFound from 'ui-component/NoDataFound';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import SubCard from 'ui-component/cards/SubCard';
import { propertyHeaders } from './InventoryHeaders';
import PropertyRow from './PropertyRow';

export default function PropertiesList() {
    const intl = useIntl();
    const { properties, loadingProperties } = useSelector((state) => state.nawyInventory);

    return (
        <TableContainer>
            <SubCard title={intl.formatMessage({ id: 'properties' })} content={false}>
                {loadingProperties ? (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SkeletonTotalIncomeCard />
                        </Grid>
                    </Grid>
                ) : (
                    <Table size="small" aria-label="sticky table">
                        <EnhancedTableHead />
                        <TableBody>
                            {properties.map((property: IProperty) => (
                                <PropertyRow key={property.property_id} property={property} />
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!loadingProperties && properties.length === 0 && (
                    <NoDataFound message={intl.formatMessage({ id: 'no-property-types-match' })} />
                )}
            </SubCard>
        </TableContainer>
    );
}

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ pl: 3 }} />
                {propertyHeaders.map((headCell) => (
                    <TableCell
                        size="small"
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        white-space="nowrap"
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <FormattedMessage id={headCell.label} />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
