import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router';

// Admin routing
const OrganizationDetails = Loadable(lazy(() => import('views/organizations/Organization')));
const OrganizationsPage = Loadable(lazy(() => import('views/organizations/Organizations')));

// ==============================|| Organizations Allowed Audience ||============================== //

// const allowedGroups = [InvitationTypeEnum.brokers, InvitationTypeEnum.accountManagers, InvitationTypeEnum.operationManager];
// const internalProtectedRoutes = [InvitationTypeEnum.accountManagers, InvitationTypeEnum.operationManager];
// ==============================|| Organizations ROUTING ||============================== //

const OrganizationsRoutes = {
    path: '/organizations',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.operationManager },
                        { name: InvitationTypeEnum.admins }
                    ]}
                >
                    <OrganizationsPage />
                </RoleGuard>
            )
        },
        {
            path: ':id/:tab',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.operationManager },
                        { name: InvitationTypeEnum.brokers },
                        { name: InvitationTypeEnum.admins }
                    ]}
                >
                    <OrganizationDetails />
                </RoleGuard>
            )
        }
    ]
};

export default OrganizationsRoutes;
