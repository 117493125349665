import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import WHO_CAN_INVITE from './whoCanInvite';
import { Grid } from '@mui/material';
import CreatePartnerInvitationPublicDialog from './Public';
import CreatePartnerInvitationDialog from './Private';
import useOverlay from 'hooks/useOverlay';

export interface InvitePartnerDialogProps {
    showTrigger?: boolean;
    fullWidth?: boolean;
    variant?: 'outlined' | 'contained';
    openDialog?: boolean;
    onClose?: () => void;
}

const InvitePartnerDialog = ({ fullWidth, variant, showTrigger = false, onClose, openDialog }: InvitePartnerDialogProps) => {
    const { isCurrentOpened, close } = useOverlay('InvitePartner');
    const handleOnClose = () => {
        onClose?.();
        close();
    };

    return (
        <RoleGuard
            groups={[{ name: InvitationTypeEnum.brokers }]}
            ifNotAllowed={
                <RoleGuard groups={WHO_CAN_INVITE}>
                    <Grid item>
                        <CreatePartnerInvitationPublicDialog
                            fullWidth={fullWidth}
                            variant={variant}
                            showTrigger={showTrigger}
                            openDialog={isCurrentOpened}
                            onClose={handleOnClose}
                        />
                    </Grid>
                </RoleGuard>
            }
        >
            <Grid item>
                <CreatePartnerInvitationDialog
                    openDialog={isCurrentOpened}
                    showTrigger={showTrigger}
                    fullWidth={fullWidth}
                    variant={variant}
                    onClose={handleOnClose}
                />
            </Grid>
        </RoleGuard>
    );
};

export default InvitePartnerDialog;
