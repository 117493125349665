import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { InvitationTypeEnum } from 'store/interfaces';
import { queryMinimalBrokers } from 'store/slices/brokers/actions';
import { MinimalBrokersInterface, OrganizationRole } from 'store/slices/brokers/interfaces';
import InfoItem from 'ui-component/InfoItem';
import RoleGuard from 'utils/RoleGuard';

interface BrokerCognitoFilterProps {
    defaultValueId?: string | '';
    value?: string | '';
    location: string;
    size?: 'small' | 'medium';
    handleSearch: (cognitoId?: string) => void;
    handleSelected?: (
        broker?: { cognitoId: string; name: string; organizationId: string; organizationName: string } | null | undefined
    ) => void;
}

const BrokerCognitoFilter = ({
    defaultValueId = '',
    size = 'small',
    location,
    value = '',
    handleSearch,
    handleSelected
}: BrokerCognitoFilterProps) => {
    const { user } = useAuth();
    return (
        <RoleGuard
            groups={[
                { name: InvitationTypeEnum.admins },
                { name: InvitationTypeEnum.accountManagers },
                { name: InvitationTypeEnum.operationManager },
                { name: InvitationTypeEnum.brokers, roles: [OrganizationRole.owner, OrganizationRole.manager] }
            ]}
            ifNotAllowed={
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} sm={6}>
                        <InfoItem label="agent" value={user?.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InfoItem label="organizations" value={user?.brokerAuth?.organization?.name} />
                    </Grid>
                </Grid>
            }
        >
            <Filter
                defaultValueId={defaultValueId}
                size={size}
                location={location}
                value={value}
                handleSearch={handleSearch}
                handleSelected={handleSelected}
            />
        </RoleGuard>
    );
};

const Filter = ({ defaultValueId, value, size, location, handleSearch, handleSelected }: BrokerCognitoFilterProps) => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const { brokersMinimal, listLoading } = useSelector((state) => state.brokers);

    const [newValue, setNewValue] = useState<string>('');
    const firstRender = useRef(true);

    const handleInputChange = (event: React.SyntheticEvent<Element, Event>, name: string) => {
        dispatch(queryMinimalBrokers({ queryData: { name } }));

        setNewValue(name);
    };

    const handleOnChange = (entity: MinimalBrokersInterface | null | undefined) => {
        handleSearch(entity?.cognitoId);
        if (handleSelected)
            handleSelected(
                entity
                    ? {
                          cognitoId: entity?.cognitoId,
                          name: entity?.user.name,
                          organizationId: entity.organization.id,
                          organizationName: entity.organization.name
                      }
                    : undefined
            );
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(queryMinimalBrokers({ queryData: { id: defaultValueId, isDeleted: false, isRegistered: true } }));
        } else {
            dispatch(queryMinimalBrokers({ queryData: { name: newValue, isDeleted: false, isRegistered: true } }));
        }
    }, [defaultValueId, newValue]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && brokersMinimal.count > 0) {
            firstRender.current = false;
            const broker = brokersMinimal.data.find((c) => c.cognitoId === defaultValueId) ?? null;
            if (handleSelected)
                handleSelected(
                    broker
                        ? {
                              cognitoId: broker?.cognitoId,
                              name: broker?.user.name,
                              organizationId: broker.organization.id,
                              organizationName: broker.organization.name
                          }
                        : undefined
                );
        }
    }, [brokersMinimal, defaultValueId, handleSelected]);

    const handleFilterClick = () => {
        emitEvent('Click Partner Agent filter', { location });
    };

    return (
        <Autocomplete
            id="autocomplete-generic-filter-brokers"
            fullWidth
            size={size}
            onOpen={handleFilterClick}
            options={brokersMinimal.data}
            loading={listLoading}
            value={brokersMinimal.data.find((v) => v.cognitoId === value)}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            filterOptions={(option) => option}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option) => option?.cognitoId === value}
            getOptionLabel={(option) => `${option?.user?.name}`}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid item xs={12} pl={1}>
                        <Typography textTransform="capitalize">{option?.user?.name}</Typography>
                        <Typography variant="subtitle2" textTransform="capitalize">
                            {option.organization.name}
                        </Typography>
                    </Grid>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-brokers"
                    label={intl.formatMessage({ id: 'partner-agent' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default BrokerCognitoFilter;
