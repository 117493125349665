import { FAUNA_API, formattedQuery } from 'store/apis';
import { QuerySalesAgents } from './interfaces';

const COLLECTION_TITLE = 'sales-agents';

const SALES_AGENTS_APIS = {
    query: (query: QuerySalesAgents) => {
        const formatQuery: string[] = [FAUNA_API, COLLECTION_TITLE, '?'];
        return formattedQuery(formatQuery, query);
    },
    move: () => `${FAUNA_API}sales-agents/move`
};

export default SALES_AGENTS_APIS;
