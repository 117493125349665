/* eslint-disable no-useless-escape */
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
// action
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
// material ui
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/styles';
import * as csc from 'country-state-city';
import { useFormik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';
// hooks
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// third party libraries
import * as yup from 'yup';

import { useDispatch, useSelector } from 'store';
// constants
import useOverlay from 'hooks/useOverlay';
import { gridSpacing } from 'store/constant';
import { InvitationTypeEnum } from 'store/interfaces';
import { createOrganization } from 'store/slices/organizations/actions';
import { openSnackbar } from 'store/slices/snackbar';
import SubCard from 'ui-component/cards/SubCard';
import RoleGuard from 'utils/RoleGuard';

export interface CreateOrganizationDialogProps {
    open?: boolean;
    showTrigger?: boolean;
    fullWidth?: boolean;
    variant?: 'btn' | 'fab';
    onClose?: () => void;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function CreateOrganizationDialog({
    open = false,
    showTrigger = false,
    fullWidth,
    variant = 'btn',
    onClose
}: CreateOrganizationDialogProps) {
    const intl = useIntl();
    const theme = useTheme();

    const { isCurrentOpened, open: openOverlay, close } = useOverlay('CreateOrganizationDialog');

    const CreateOrganizationSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        officialName: yup.string(),
        contactInfo: yup
            .object()
            .optional()
            .shape({
                title: yup.string(),
                name: yup.string(),
                phone: yup.number().typeError(intl.formatMessage({ id: 'invalid-phone' })),
                email: yup.string().email(intl.formatMessage({ id: 'contact-info-email-format' }))
            }),
        location: yup.object().optional().shape({
            type: yup.string(),
            area: yup.string(),
            city: yup.string(),
            country: yup.string(),
            address: yup.string()
        }),
        // area: yup.object().optional().shape({
        //     name: yup.string()
        // }),
        broker: yup
            .object()
            .optional()
            .shape({
                phone: yup.number().typeError(intl.formatMessage({ id: 'invalid-phone' })),
                name: yup.string(),
                password: yup
                    .string()
                    .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
                        'Not Strong Enough'
                    ),
                email: yup.string().email(intl.formatMessage({ id: 'invalid-email' }))
            })
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { error } = useSelector((state) => state.organizations);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const handleClickOpen = () => {
        openOverlay();
    };

    const handleClose = () => {
        formik.resetForm();
        close();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
            handleClickOpen();
        }
    }, [open]);

    const runSuccess = (organizationId: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'org-created-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        // formik.setSubmitting(false);

        navigate(`/organizations/${organizationId}/details`, { replace: false });
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            officialName: undefined,
            contactInfo: {
                title: undefined,
                name: undefined,
                phone: undefined,
                email: undefined
            },
            location: {
                type: '',
                country: '',
                area: '',
                city: '',
                address: ''
            },
            // area: {
            //     name: ''
            // },
            broker: {
                name: '',
                phone: '',
                password: '',
                email: undefined
            }
        },
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            let brokerCountryCode;
            if (values.contactInfo?.phone) {
                try {
                    parsePhoneNumber(values.contactInfo?.phone, 'EG');
                } catch (err) {
                    formik.setFieldError('contactInfo.phone', 'Phone must be a valid phone number');
                    formikHelpers.setSubmitting(false);
                    return;
                }
            }

            if (values?.broker?.phone) {
                try {
                    const isPhoneValid = parsePhoneNumber(values.broker?.phone, 'EG');
                    brokerCountryCode = isPhoneValid.country;
                } catch (err) {
                    formik.setFieldError('broker.phone', 'Phone must be a valid phone number');
                    formikHelpers.setSubmitting(false);
                    return;
                }
            }
            dispatch(
                createOrganization({
                    createData: {
                        ...values,

                        location: values.location.type ? { ...values.location } : undefined,
                        contactInfo: values.contactInfo?.phone ? { ...values.contactInfo } : undefined,
                        broker: values?.broker?.phone
                            ? {
                                  ...values.broker,
                                  countryCode: brokerCountryCode
                              }
                            : undefined
                    },
                    runSuccess
                })
            ).then(() => {
                handleClose();
            });
        },
        validationSchema: CreateOrganizationSchema,
        enableReinitialize: true
    });

    const handleGetCountryCode = () => {
        const countryCode = csc.Country.getAllCountries().find((country) => country.name === formik.values.location.country)?.isoCode;
        if (countryCode) return countryCode;
        return '';
    };

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
            {showTrigger && variant === 'btn' ? (
                <Button startIcon={<AddCircleTwoToneIcon />} fullWidth={fullWidth} variant="contained" onClick={handleClickOpen}>
                    <FormattedMessage id="add-partner" />
                </Button>
            ) : null}

            <Dialog
                open={isCurrentOpened}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="Create-Organization"
                aria-describedby="Admin-account-manager-head-create-organization"
            >
                <DialogTitle id="create-organization-dialog">
                    <FormattedMessage id="create-organization" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SubCard title={`${intl.formatMessage({ id: 'partner' })} ${intl.formatMessage({ id: 'info' })}`}>
                                <Grid container py={1} spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'name' })} *`}
                                            fullWidth
                                            type="text"
                                            name="name"
                                            id="create-organization-name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.name && formik.errors.name)}
                                            helperText={formik.touched.name && formik.errors.name}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'official-name' })}`}
                                            fullWidth
                                            type="text"
                                            name="officialName"
                                            id="create-organization-officialName"
                                            value={formik.values.officialName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.officialName && formik.errors.officialName)}
                                            helperText={formik.touched.officialName && formik.errors.officialName}
                                        />
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>

                        <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
                            <Grid item xs={12}>
                                <SubCard
                                    title={`${intl.formatMessage({ id: 'user' })} ${intl.formatMessage({
                                        id: 'info'
                                    })} (${intl.formatMessage({ id: 'optional' })})`}
                                >
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'name' })} *`}
                                                fullWidth
                                                type="text"
                                                name="broker.name"
                                                id="create-user-name"
                                                value={formik.values.broker.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.broker?.name && formik.errors.broker?.name)}
                                                helperText={formik.touched.broker?.name && formik.errors.broker?.name}
                                            />
                                        </Grid>
                                        {/* password field  */}

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'password' })} *`}
                                                fullWidth
                                                type={showPassword ? 'text' : 'password'}
                                                name="broker.password"
                                                autoComplete="new-password"
                                                id="create-user-password"
                                                value={formik.values.broker.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.broker?.password && formik.errors.broker?.password)}
                                                helperText={formik.touched.broker?.password && formik.errors.broker?.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={handleMouseDownPassword}
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            {/* email */}
                                            <TextField
                                                label={intl.formatMessage({ id: 'email' })}
                                                fullWidth
                                                type="text"
                                                name="broker.email"
                                                id="create-user-email"
                                                value={formik.values.broker.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched?.broker?.email && formik.errors?.broker?.email)}
                                                helperText={formik.touched?.broker?.email && formik.errors?.broker?.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <MuiTelInput
                                                dir="ltr"
                                                fullWidth
                                                disabled={formik.isSubmitting}
                                                id="create-user-phone"
                                                name="broker.phone"
                                                forceCallingCode
                                                focusOnSelectCountry
                                                excludedCountries={['IL']}
                                                disableFormatting
                                                defaultCountry="EG"
                                                label={`${intl.formatMessage({ id: 'phone' })} *`}
                                                value={formik.values.broker.phone}
                                                onChange={(v) => formik.setFieldValue('broker.phone', v)}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.broker?.phone && formik.errors.broker?.phone)}
                                                helperText={formik.touched.broker?.phone && formik.errors.broker?.phone}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        </RoleGuard>

                        <Grid item xs={12}>
                            <SubCard title={`Contact Information (${intl.formatMessage({ id: 'optional' })})`}>
                                <Grid container py={1} spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'title' })} *`}
                                            fullWidth
                                            type="text"
                                            name="contactInfo.title"
                                            id="create-organization-contactInfo-title"
                                            value={formik.values.contactInfo?.title}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.contactInfo?.title && formik.errors?.contactInfo?.title)}
                                            helperText={formik.touched?.contactInfo?.title && formik.errors?.contactInfo?.title}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'name' })} *`}
                                            fullWidth
                                            type="text"
                                            name="contactInfo.name"
                                            id="create-organization-contactInfo.name"
                                            value={formik.values.contactInfo?.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.contactInfo?.name && formik.errors.contactInfo?.name)}
                                            helperText={formik.touched.contactInfo?.name && formik.errors.contactInfo?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MuiTelInput
                                            dir="ltr"
                                            id="create-organization-contactInfo.phone"
                                            name="contactInfo.phone"
                                            fullWidth
                                            disabled={formik.isSubmitting}
                                            forceCallingCode
                                            excludedCountries={['IL']}
                                            disableFormatting
                                            defaultCountry="EG"
                                            label={`${intl.formatMessage({ id: 'phone' })} *`}
                                            value={formik.values.contactInfo?.phone || ''}
                                            onChange={(v) => formik.setFieldValue('contactInfo.phone', v)}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone)}
                                            helperText={formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'email' })}`}
                                            fullWidth
                                            type="text"
                                            name="contactInfo.email"
                                            id="create-organization-contactInfo.email"
                                            value={formik.values.contactInfo?.email || ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.contactInfo?.email && formik.errors.contactInfo?.email)}
                                            helperText={formik.touched.contactInfo?.email && formik.errors.contactInfo?.email}
                                        />
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        <Grid item xs={12}>
                            <SubCard title={`${intl.formatMessage({ id: 'location' })} (${intl.formatMessage({ id: 'optional' })})`}>
                                <Grid container py={1} spacing={gridSpacing}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'type' })} *`}
                                            name="location.type"
                                            fullWidth
                                            id="contact-info-type"
                                            value={formik.values.location.type || ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            onReset={formik.handleReset}
                                            error={Boolean(formik.touched.location?.type && formik.errors.location?.type)}
                                            helperText={formik.touched.location?.type && formik.errors.location?.type}
                                            select
                                        >
                                            <MenuItem value="Head Quartes">Head Quartes</MenuItem>
                                            <MenuItem value="Secondary">Secondary</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'area' })} *`}
                                            fullWidth
                                            type="text"
                                            name="location.area"
                                            id="create-organization-location.area"
                                            value={formik.values.location?.area}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.location?.area && formik.errors.location?.area)}
                                            helperText={formik.touched.location?.area && formik.errors.location?.area}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'address' })} *`}
                                            fullWidth
                                            type="text"
                                            name="location.address"
                                            id="create-organization-location.address"
                                            value={formik.values.location?.address}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.location?.address && formik.errors.location?.address)}
                                            helperText={formik.touched.location?.address && formik.errors.location?.address}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            name="location.country"
                                            fullWidth
                                            displayEmpty
                                            id="create-organization-location-country"
                                            value={formik.values.location.country}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            onReset={formik.handleReset}
                                            error={Boolean(formik.touched.location?.country && formik.errors.location?.country)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem disabled value="">
                                                Select Country
                                            </MenuItem>
                                            {csc.Country.getAllCountries().map((country) => (
                                                <MenuItem key={country.isoCode} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {formik.touched.location?.country && (
                                            <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                {formik.errors.location?.country}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Select
                                            name="location.city"
                                            fullWidth
                                            displayEmpty
                                            id="create-organization-location-city"
                                            value={formik.values.location.city}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            onReset={formik.handleReset}
                                            disabled={!handleGetCountryCode()}
                                            error={Boolean(formik.touched.location?.city && formik.errors.location?.city)}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem disabled value="">
                                                <FormattedMessage id="select-city" />
                                            </MenuItem>
                                            {csc.City.getCitiesOfCountry(handleGetCountryCode())?.map((city) => (
                                                <MenuItem key={city.name} value={city.name}>
                                                    {city.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {formik.touched.location?.city && (
                                            <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                {formik.errors.location?.city}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <SubCard title={`${intl.formatMessage({ id: 'area' })}`}>
                                <Grid container spacing={gridSpacing}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={`${intl.formatMessage({ id: 'area' })} *`}
                                            fullWidth
                                            type="text"
                                            name="area.name"
                                            id="create-organization-area-name"
                                            value={formik.values.area.name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.area?.name && formik.errors.area?.name)}
                                            helperText={formik.touched.area?.name && formik.errors.area?.name}
                                        />
                                    </Grid>
                                </Grid>
                            </SubCard>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </RoleGuard>
    );
}
