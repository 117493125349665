/* eslint-disable eqeqeq */
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
// material ui
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
// third party
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import NoDataFound from 'ui-component/NoDataFound';
import { HeadCell, notificationTypesHeaders } from './NotificationTypesHeaders';
import { NotificationTypeInterface } from 'store/slices/notifications/interfaces';

function NotificationTypeRow({ notificationType }: { notificationType: NotificationTypeInterface }) {
    const navigate = useNavigate();
    const handleMoreDetails = () => {
        navigate(`${notificationType.typeId}/details`, { replace: false });
    };

    return (
        <TableRow hover sx={{ '& > *': { borderBottom: 'unset', cursor: 'pointer' } }} onClick={handleMoreDetails}>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {notificationType.typeId}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {notificationType.name}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {notificationType.portal}
            </TableCell>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {notificationType.createdBy}
            </TableCell>
            <TableCell align="left">{format(new Date(notificationType.createdAt), 'dd MMM yyyy')}</TableCell>
            <TableCell align="center" padding="none">
                <Tooltip title="More Info">
                    <IconButton size="small" onClick={handleMoreDetails}>
                        <MoreHorizTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    );
}

export default function NotificationTypesList() {
    const intl = useIntl();

    const { notificationTypes, loadingList } = useSelector((state) => state.notificationTypes);

    return (
        <TableContainer component={Paper}>
            {loadingList ? (
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SkeletonTotalIncomeCard />
                    </Grid>
                    <Grid item xs={12}>
                        <SkeletonTotalIncomeCard />
                    </Grid>
                </Grid>
            ) : (
                <Table size="small" aria-label="Collections table">
                    <EnhancedTableHead />

                    <TableBody>
                        {notificationTypes.data.map((notificationType) => (
                            <NotificationTypeRow key={notificationType.typeId} notificationType={notificationType} />
                        ))}
                    </TableBody>
                </Table>
            )}
            {(!notificationTypes.count || notificationTypes.count == 0) && !loadingList && (
                <NoDataFound message={intl.formatMessage({ id: 'no-notification-types-available' })} />
            )}
        </TableContainer>
    );
}

function EnhancedTableHead() {
    const renderTableCell = (headCell: HeadCell) => (
        <TableCell key={headCell.id} align={headCell.align}>
            <FormattedMessage id={headCell.label} />
        </TableCell>
    );

    return (
        <TableHead>
            <TableRow>{notificationTypesHeaders.map((headCell) => renderTableCell(headCell))}</TableRow>
        </TableHead>
    );
}
