import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { FormikHelpers } from 'formik';
import useOverlay from 'hooks/useOverlay';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { createBanner, getRecentBanners } from 'store/slices/banners/actions';
import { BannerFormInterface } from 'store/slices/banners/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import BannerDialog from './BannerDialog';

const CreateBannerDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const { loading, error } = useSelector((state) => state.banners);

    const { isCurrentOpened, close } = useOverlay('CreateBannerDialog');

    const handleOnClose = () => close();

    const onSuccessSnackbar = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'banner-created-successfully' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );

        handleOnClose();
    };

    const handleOnSubmit = (values: BannerFormInterface, formikHelpers: FormikHelpers<BannerFormInterface>) => {
        setDisabledSubmitButton(true);

        dispatch(
            createBanner({
                createData: values,
                runSuccess: () => {
                    formikHelpers.resetForm();
                    onSuccessSnackbar();
                }
            })
        )
            .then(() => dispatch(getRecentBanners({})))
            .finally(() => setDisabledSubmitButton(false));
    };

    useEffect(() => {
        setDisabledSubmitButton(false);
    }, [error]);

    return (
        <BannerDialog
            open={isCurrentOpened}
            onClose={() => close()}
            footer={
                <Stack
                    direction={{ xs: 'column', lg: 'row' }}
                    justifyContent={{ xs: 'space-between', lg: 'end' }}
                    width="100%"
                    px={3}
                    gap={2}
                >
                    <Stack order={{ xs: 2, lg: 1 }}>
                        <Button onClick={handleOnClose}>
                            <FormattedMessage id="cancel" />
                        </Button>
                    </Stack>
                    <Stack order={{ xs: 1, lg: 2 }}>
                        <LoadingButton loading={loading} type="submit" variant="contained" disabled={disabledSubmitButton}>
                            <FormattedMessage id="publish" />
                        </LoadingButton>
                    </Stack>
                </Stack>
            }
            onSubmit={handleOnSubmit}
        />
    );
};

export default CreateBannerDialog;
