import { LoadingButton } from '@mui/lab';
// material ui
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';
// hooks
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// third party
import * as yup from 'yup';

import { useDispatch, useSelector } from 'store';
// constants
import { gridSpacing } from 'store/constant';
import { createLeadRequest } from 'store/slices/leadRequests/actions';
import { openSnackbar } from 'store/slices/snackbar';

import useMixPanel from 'hooks/useMixPanel';
import useOverlay from 'hooks/useOverlay';
import selectableAreas from 'types/selectableAreas';

interface AddLeadProps {
    fullWidth?: boolean;
    event?: {
        location: string;
    };
    variant?: 'contained' | 'outlined';
}

export default function AddLead({ fullWidth, variant, event }: AddLeadProps) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { createLeadRequestError } = useSelector((state) => state.leadRequests);
    const { emitEvent } = useMixPanel();

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<AddLeadProps>('AddLead');

    const AddLeadSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        telephone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        budget: yup.number(),
        agreedTerm: yup
            .boolean()
            .isTrue(intl.formatMessage({ id: 'add-lead-condition' }))
            .required(intl.formatMessage({ id: 'agree-term' })),
        comment: yup.string(),
        location: yup.string().required(intl.formatMessage({ id: 'location-required' }))
    });
    const handleClose = () => {
        formik.resetForm();
        close();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (isCurrentOpened) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }

            emitEvent('Click Add Buyer', { event_location: payload?.event?.location });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCurrentOpened]);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'lead-added-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);
        handleClose();
    };

    const handleSubmit = (values: {
        agreedTerm?: boolean;
        name: string;
        telephone: string;
        budget?: number;
        moveNow: boolean;
        location?: string;
        comment?: string;
    }) => {
        formik.setSubmitting(true);
        const isPhoneValid = parsePhoneNumber(values.telephone, 'EG')?.isValid();
        if (!isPhoneValid) {
            formik.setFieldError('telephone', intl.formatMessage({ id: 'phone-invalid' }));
            formik.setSubmitting(false);
            return;
        }
        dispatch(
            createLeadRequest({
                createData: {
                    name: values.name,
                    moveNow: values.moveNow,
                    telephone: values.telephone.replace('+20', '0').replace('+', '00'),
                    location: values.location,
                    comment: values.comment
                },
                runSuccess
            })
        );
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            telephone: '',
            location: undefined,
            moveNow: false,
            agreedTerm: false,
            comment: ''
        },
        onSubmit: handleSubmit,
        validationSchema: AddLeadSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (createLeadRequestError?.validationErrors) formik.setErrors(createLeadRequestError?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createLeadRequestError]);

    return (
        <>
            <Dialog
                open={isCurrentOpened}
                onClose={!formik.isSubmitting ? handleClose : undefined}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="Invite User"
                aria-describedby="Invite other users to join nawy sirens"
            >
                <DialogTitle id="invite-user-dialog">
                    <FormattedMessage id="add-interested-buyer" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'name' })}
                                name="name"
                                id="add-lead-name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiTelInput
                                dir="ltr"
                                fullWidth
                                disabled={formik.isSubmitting}
                                id="invite-partner-phone"
                                name="phone"
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                excludedCountries={['IL']}
                                defaultCountry="EG"
                                label={`${intl.formatMessage({ id: 'phone' })} *`}
                                value={formik.values.telephone}
                                onChange={(v) => {
                                    formik.setFieldValue('telephone', v);
                                }}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.telephone && formik.errors.telephone)}
                                helperText={formik.touched.telephone && formik.errors.telephone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="add-lead-location-label">
                                    <FormattedMessage id="location" />
                                </InputLabel>
                                <Select
                                    labelId="add-lead-location-label"
                                    id="add-lead-location"
                                    value={formik.values.location}
                                    label={intl.formatMessage({ id: 'location' })}
                                    name="location"
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                    }}
                                    error={Boolean(formik.touched.location && formik.errors.location)}
                                >
                                    <MenuItem value="">
                                        <FormattedMessage id="none" />
                                    </MenuItem>

                                    {selectableAreas.map((area, i) => (
                                        <MenuItem value={area.name} key={i}>
                                            {area.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.location && formik.errors.location && (
                                    <FormHelperText error>{formik.errors.location}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                        <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox value={formik.values.moveNow} onChange={formik.handleChange} name="moveNow" />}
                                    label={intl.formatMessage({ id: 'ready-move-unit' })}
                                />
                            </FormGroup>
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'comment' })}
                                name="comment"
                                id="add-lead-comment"
                                type="text"
                                multiline
                                minRows={3}
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.comment && formik.errors.comment)}
                                helperText={formik.touched.comment && formik.errors.comment}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox value={formik.values.agreedTerm} onChange={formik.handleChange} name="agreedTerm" />}
                                    label={intl.formatMessage({ id: 'add-lead-message' })}
                                />
                                {formik.touched.agreedTerm && formik.errors.agreedTerm && (
                                    <FormHelperText error>{formik.errors.agreedTerm}</FormHelperText>
                                )}
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                <FormattedMessage id="add-buyer-payment-info-warning" />
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton
                        loading={formik.isSubmitting}
                        onClick={() => {
                            emitEvent('Click Create Buyer', {
                                name: formik.values.name,
                                number: formik.values.telephone,
                                location: formik.values.location,
                                comment: formik.values.comment,
                                consent: formik.values.agreedTerm,
                                error: Boolean(formik.errors)
                            });
                            formik.submitForm();
                        }}
                        size="small"
                        variant="contained"
                    >
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
