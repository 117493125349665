import { createSlice } from '@reduxjs/toolkit';
import { DeveloperContactInitialState } from './interfaces';
import queryDeveloperContacts, { createDeveloperContact, deleteDeveloperContact } from './actions';

const initialState: DeveloperContactInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    listRefresh: false,
    refresh: false,
    developerContacts: []
};

const developerContacts = createSlice({
    name: 'developerContacts',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(queryDeveloperContacts.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryDeveloperContacts.fulfilled, (state, action) => {
                state.listLoading = false;
                state.developerContacts = action.payload;
            })
            .addCase(queryDeveloperContacts.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(createDeveloperContact.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createDeveloperContact.fulfilled, (state, action) => {
                state.loading = false;
                state.developerContacts = action.payload;
            })
            .addCase(createDeveloperContact.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteDeveloperContact.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteDeveloperContact.fulfilled, (state, action) => {
                state.loading = false;
                state.developerContacts = action.payload;
            })
            .addCase(deleteDeveloperContact.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default developerContacts.reducer;
