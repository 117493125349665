import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import CILS_APIS from './apis';
import { QueryCilsInterface } from './interfaces';

export const getCilsAnalytica = createAsyncThunk(
    'cils/analytica',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;
        try {
            const { data } = await axios.get(CILS_APIS.analytica());
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const queryCils = createAsyncThunk(
    'cils/query',
    async (
        requestData: {
            query: QueryCilsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data } = await axios.get(CILS_APIS.query(query));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const createCils = createAsyncThunk(
    'cils/create',
    async (
        requestData: {
            name: string;
            phone: string;
            location: string;
            areaName?: string;
            comment?: string;
            brokerId?: string;
            runSuccess?: (cilId: number) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { name, phone, location, areaName, comment, brokerId, runSuccess } = requestData;
        try {
            const { data } = await axios.post(CILS_APIS.create(), { name, phone, location, areaName, comment, brokerId });
            if (runSuccess) runSuccess(data?.cil?.id);
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCilById = createAsyncThunk(
    'cils/get-by-id',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.get(CILS_APIS.getById(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const renewCil = createAsyncThunk(
    'cils/renew',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.put(CILS_APIS.renew(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
