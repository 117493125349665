// material-ui
import { Box, Collapse, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
// import Chip from 'ui-component/extended/Chip';

// assets
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// project imports
import { format } from 'date-fns';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { businessTypesObject, propertyFinishing } from 'store/slices/inventoryTable/interfaces';
import { IPropertyTypeDetails } from 'store/slices/nawyInventory/interfaces';
import { propertyHeaders } from './InventoryHeaders';
import PropertiesList from './PropertiesList';
import removeDecimalPoint from './helpers';

export default function PropertyTypesRow({ propertyType }: { propertyType: IPropertyTypeDetails }) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const { emitEvent } = useMixPanel();

    useEffect(() => {
        const selected_property_type_id = searchParams.get('selected_property_type_id');
        if (selected_property_type_id && +selected_property_type_id === propertyType.property_type_id) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const handlePropertyTypeChange = () => {
        const selected_property_type_id = searchParams.get('selected_property_type_id');
        searchParams.delete('selected_property_type_id');
        searchParams.delete('selected_property_id');

        if ((selected_property_type_id && +selected_property_type_id !== propertyType.property_type_id) || !selected_property_type_id) {
            searchParams.set('selected_property_type_id', propertyType.property_type_id.toString());
        } else {
            searchParams.delete('selected_property_type_id');
        }
        emitEvent('Click Property Inventory', { ...propertyType });
        setSearchParams(searchParams);
    };

    const getPropertyTypeSaleTypes = () =>
        Object.entries(propertyType.business_types).map(
            ([key, value]): string => `${intl.formatMessage({ id: businessTypesObject[key] || 'others' })} (${value})`
        );

    const getPropertyTypeFinishing = () =>
        Object.entries(propertyType.finishing).map(
            ([key, value]): string => `${intl.formatMessage({ id: propertyFinishing[key] || 'others' })} (${value})`
        );

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} onClick={handlePropertyTypeChange}>
                <TableCell sx={{ pl: 3 }}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">{propertyType.name}</TableCell>
                <TableCell align="center">
                    {getPropertyTypeSaleTypes().map((saleType) => (
                        <Typography variant="subtitle1" textTransform="capitalize" key={saleType}>
                            {saleType}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell align="center">
                    {propertyType.max_area !== propertyType.min_area
                        ? `${propertyType.min_area} - ${propertyType.max_area}`
                        : propertyType.max_area}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_price !== propertyType.max_price ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {propertyType.min_price ? removeDecimalPoint(propertyType.min_price) : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {propertyType.max_price ? removeDecimalPoint(propertyType.max_price) : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {propertyType.max_price ? removeDecimalPoint(propertyType.max_price) : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_down_payment !== propertyType.max_down_payment ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {propertyType.min_down_payment
                                    ? `${removeDecimalPoint(
                                          (+propertyType.min_price * +propertyType.min_down_payment) / 100
                                      )}(${removeDecimalPoint(propertyType.min_down_payment)})`
                                    : '-'}
                                -
                            </Typography>
                            <Typography variant="inherit">
                                {propertyType.max_down_payment
                                    ? `${removeDecimalPoint(
                                          (+propertyType.max_price * +propertyType.max_down_payment) / 100
                                      )}(${removeDecimalPoint(propertyType.max_down_payment)})`
                                    : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {propertyType.min_down_payment
                                ? `${removeDecimalPoint(
                                      (+propertyType.min_price * +propertyType.min_down_payment) / 100
                                  )}(${removeDecimalPoint(propertyType.min_down_payment)})`
                                : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_installments !== propertyType.max_installments ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {propertyType.min_installments ? removeDecimalPoint(propertyType.min_installments) : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {propertyType.max_installments ? removeDecimalPoint(propertyType.max_installments) : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {propertyType.min_installments ? removeDecimalPoint(propertyType.min_installments) : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_delivery_date !== propertyType.max_delivery_date ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {propertyType.min_delivery_date ? format(new Date(propertyType.min_delivery_date), 'dd-MM-yyyy') : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {propertyType.max_delivery_date ? format(new Date(propertyType.max_delivery_date), 'dd-MM-yyyy') : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {propertyType.min_delivery_date ? format(new Date(propertyType.min_delivery_date), 'dd-MM-yyyy') : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_bedrooms !== propertyType.max_bedrooms
                        ? `${propertyType.min_bedrooms} - ${propertyType.max_bedrooms}`
                        : propertyType.min_bedrooms}
                </TableCell>
                <TableCell align="center">
                    {propertyType.min_bathrooms !== propertyType.max_bathrooms ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{propertyType.min_bathrooms ? propertyType.min_bathrooms : '-'} -</Typography>
                            <Typography variant="inherit">{propertyType.max_bathrooms ? propertyType.max_bathrooms : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{propertyType.min_bathrooms ? propertyType.min_bathrooms : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {getPropertyTypeFinishing().map((finishing, i) => (
                        <Typography variant="subtitle1" textTransform="capitalize" key={finishing}>
                            {finishing}
                        </Typography>
                    ))}
                </TableCell>
                <TableCell align="center">
                    {propertyType.last_update ? format(new Date(propertyType.last_update), 'dd-MM-yyyy') : '-'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell padding="none" colSpan={propertyHeaders.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{ margin: 1 }}>
                                <PropertiesList />
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
