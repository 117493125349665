import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const FlowsPage = Loadable(lazy(() => import('views/flows/Flows')));
const FlowPage = Loadable(lazy(() => import('views/flows/Flow')));

// ==============================|| Flows Allowed Audience ||============================== //

// const allowedGroups = [
//     InvitationTypeEnum.brokers,
//     InvitationTypeEnum.accountManagers,
//     InvitationTypeEnum.operationManager,
//     InvitationTypeEnum.admins
// ];

// ==============================|| Flows ROUTING ||============================== //

const FlowRoutes = {
    path: '/flows',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.accountManagers },
                    { name: InvitationTypeEnum.operationManager },
                    { name: InvitationTypeEnum.admins },
                    { name: InvitationTypeEnum.brokers }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <FlowsPage />
        },
        {
            path: ':id',
            element: <FlowPage />
        }
    ]
};
export default FlowRoutes;
