import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import { PercentOutlined } from '@mui/icons-material';
import { OrganizationRole } from 'store/slices/brokers/interfaces';

const commissions: NavItemType = {
    id: 'commissions',
    title: <FormattedMessage id="commissions" />,
    type: 'item',
    url: '/commissions',
    icon: PercentOutlined,
    allowedAudience: [
        { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit', roles: [OrganizationRole.owner] },
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers }
    ],
    mixpanelEvent: {
        tab_name: 'Commissions'
    }
};

const commissionsItem_Freelance: NavItemType = {
    id: 'commissions',
    title: <FormattedMessage id="commissions" />,
    type: 'item',
    url: '/commissions',
    icon: PercentOutlined,
    allowedAudience: [
        {
            name: InvitationTypeEnum.brokers,
            organizationFeature: 'saleClaimsLimit',
            roles: [OrganizationRole.owner, OrganizationRole.employee],
            tiers: ['96af4348-4dcb-4d98-b8a6-33bac4cd831b']
        }
    ],
    mixpanelEvent: {
        tab_name: 'Commissions'
    }
};

const commissionItem: NavItemType = {
    id: 'commissions',
    type: 'group',
    children: [commissions, commissionsItem_Freelance]
};
export default commissionItem;
