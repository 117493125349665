import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import useMixPanel from 'hooks/useMixPanel';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { createFlowEOI } from 'store/slices/flows/actions';
import { CreateFlowEoiInterface, FlowInterface } from 'store/slices/flows/interfaces';
import { crmPropertyTypes } from 'store/slices/general/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import BrokerCognitoFilter from 'ui-component/Filters/BrokersFilter/V2/BrokerCognitoFilter';
import CompoundFilter from 'ui-component/Filters/CompoundFilter';
import PropertyTypeSelector from 'ui-component/Filters/PropertyTypeSelect';
import ImageUploadDragAndDrop from 'ui-component/ImageUpload';
import NumericFormatCustom from 'ui-component/MoneyInput';
import ComponentRoleGuard from 'utils/route-guard/ComponentRoleGuard';

export interface CreateeoiDialogContentProps {
    open: boolean;
    flowInitialValues?: FlowInterface;
    handleClose: () => void;
    flow?: FlowInterface;
}

const CreateEOIDialog = ({ open = false, flowInitialValues, flow, handleClose }: CreateeoiDialogContentProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { emitEvent } = useMixPanel();
    const { error } = useSelector((state) => state.flows);

    const onClose = (location: string) => {
        formik.resetForm();
        handleClose();
        emitEvent('Click Cancel eoi Creation', { location });
    };

    const handleAddImages = (files: FileList) => {
        const newDocuments = formik.values?.documents ? [...formik.values.documents, ...files] : [];
        formik.setFieldValue(`documents`, newDocuments);
    };

    const handleDeleteImage = (name: string) => {
        const newDocuments = formik.values?.documents?.filter((file: File) => file.name !== name);
        formik.setFieldValue(`documents`, newDocuments);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const runSuccess = (eoiId?: number) => {
        dispatch(
            openSnackbar({
                open: true,
                message: `${intl.formatMessage({ id: 'eoi' })} ${intl.formatMessage({ id: 'created-success' })}`,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const CreateeoiSchema = yup.object().shape({
        amountPaid: yup
            .number()
            .required(intl.formatMessage({ id: 'amount-paid-required' }))
            .min(0),
        name: yup.string().required(intl.formatMessage({ id: 'buyer-name-required' })),
        phone: yup
            .number()
            .required()
            .typeError(intl.formatMessage({ id: 'buyer-phone-invalid' })),
        compoundId: yup.number().required(),
        unitValue: yup
            .number()
            .required(intl.formatMessage({ id: 'unit_price_is_required' }))
            .min(0)
    });

    const initialValues: Partial<CreateFlowEoiInterface> = {
        actualDate: '',
        amountPaid: undefined,
        brokerId: flow?.lead.brokerId ?? '',
        comment: '',
        compoundId: undefined,
        flowId: flow?.id ?? undefined,
        unitId: '',
        unitValue: undefined,
        name: flow?.lead.name,
        phone: flow?.lead.phoneNumber,
        documents: []
    };

    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);

            emitEvent('Click Confirm EOI Creation', { ...formik.values, error: Boolean(formik.errors) });

            const isPhoneValid = values.phone ? parsePhoneNumber(values.phone, 'EG') : undefined;
            if (!isPhoneValid?.isValid() && values.phone) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);

                return;
            }

            const { propertyTypeId, compoundId, amountPaid, unitValue, brokerId, name, phone } = values;

            const parseAmountPaid = parseInt((amountPaid as number).toString(), 10);
            const parsedUnitValue = unitValue ? parseInt((unitValue as number).toString(), 10) : undefined;

            dispatch(
                createFlowEOI({
                    body: {
                        flowId: flow?.id,
                        amountPaid: parseAmountPaid,
                        unitValue: parsedUnitValue,
                        propertyTypeId: propertyTypeId || 0,
                        compoundId: compoundId || 0,
                        brokerId: brokerId || undefined,
                        name: name || '',
                        phone: phone || '',
                        documents: values.documents
                    },
                    runSuccess
                })
            );
        },
        validationSchema: CreateeoiSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Dialog
            open={open}
            onClose={() => onClose('Out Dialog')}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            aria-labelledby="create-eoi"
            aria-describedby="create eoi"
        >
            <DialogTitle id="create-eoi">
                <FormattedMessage id="create-eoi" />
            </DialogTitle>
            <DialogContent>
                <Grid container py={1} spacing={gridSpacing}>
                    <Grid item container spacing={gridSpacing}>
                        <ComponentRoleGuard
                            allowedAudience={[
                                AccountManagersRole.member,
                                AccountManagersRole.supervisor,
                                AccountManagersRole.head,
                                OrganizationRole.owner
                            ]}
                        >
                            <Grid item xs={12} sm={12} md={6}>
                                <FormControl fullWidth>
                                    <BrokerCognitoFilter
                                        size="medium"
                                        location="create-eoi"
                                        defaultValueId={formik.values.brokerId}
                                        handleSearch={(newValue) => {
                                            formik.setFieldValue('brokerId', newValue);
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </ComponentRoleGuard>
                    </Grid>

                    {/* buyer name */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label={`${intl.formatMessage({ id: 'buyer-name' })} *`}
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiTelInput
                            dir="ltr"
                            fullWidth
                            disabled={formik.isSubmitting}
                            id="buyer-phone"
                            name="phone"
                            forceCallingCode
                            focusOnSelectCountry
                            disableFormatting
                            excludedCountries={['IL']}
                            defaultCountry="EG"
                            label={`${intl.formatMessage({ id: 'buyer-phone' })} *`}
                            value={formik.values.phone}
                            onChange={(v) => formik.setFieldValue('phone', v)}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.phone && formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <CompoundFilter
                            defaultValueId={formik.values?.compoundId}
                            size="medium"
                            required
                            handleSelectedCompound={(newValue) => {
                                formik.setFieldValue('compoundId', newValue?.id || undefined);
                            }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <PropertyTypeSelector
                            defaultOption={crmPropertyTypes[0] as any}
                            onChangeHandler={(newValue) => {
                                formik.setFieldValue('propertyTypeId', newValue?.id);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={`${intl.formatMessage({ id: 'amount-paid' })} *`}
                            name="amountPaid"
                            InputProps={{
                                inputComponent: NumericFormatCustom as any,
                                endAdornment: <InputAdornment position="end">EGP</InputAdornment>
                            }}
                            value={formik.values.amountPaid}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.amountPaid && formik.errors.amountPaid)}
                            helperText={formik.touched.amountPaid && formik.errors.amountPaid}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={`${intl.formatMessage({ id: 'unit-value' })} *`}
                            name="unitValue"
                            InputProps={{
                                inputComponent: NumericFormatCustom as any,
                                endAdornment: <InputAdornment position="end">EGP</InputAdornment>
                            }}
                            value={formik.values.unitValue}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.unitValue && formik.errors.unitValue)}
                            helperText={formik.touched.unitValue && formik.errors.unitValue}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>{`${intl.formatMessage({ id: 'documents' })} (optional)`}:</Typography>
                        <ImageUploadDragAndDrop handleAddImages={handleAddImages} handleDeleteImage={handleDeleteImage} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={formik.isSubmitting} onClick={() => onClose('Cancel Button')} size="small" color="error">
                    <FormattedMessage id="cancel" />
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                    <FormattedMessage id="confirm" />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default CreateEOIDialog;
