import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreatePartnerInvitationInterface, QueryPartnerInvitationsInterface } from './interfaces';
import axiosServices from 'utils/axios';
import PARTNER_INVITATIONS from './apis';
import INVITATION_LIMITS from '../invitationsLimits/apis';
import INVITATION_TYPES_APIS from '../invitationTypes/apis';
import { InvitationTypesQueryInterface } from '../invitationTypes/interfaces';

export const queryPartnersInvitations = createAsyncThunk(
    'partners-invitations/query',
    async (
        requestData: {
            queryData: QueryPartnerInvitationsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PARTNER_INVITATIONS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createPartnersInvitations = createAsyncThunk(
    'partners-invitations/create',
    async (
        requestData: {
            createBody: CreatePartnerInvitationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createBody, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PARTNER_INVITATIONS.create(), createBody);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resendPartnersInvitations = createAsyncThunk(
    'partners-invitations/resend',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PARTNER_INVITATIONS.resend(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const cancelPartnersInvitations = createAsyncThunk(
    'partners-invitations/cancel',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PARTNER_INVITATIONS.cancel(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryPartnerInvitationLimits = createAsyncThunk(
    'partners-invitations/invitation-limit/query',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(INVITATION_LIMITS.query());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryInvitationTypes = createAsyncThunk(
    'partners-invitations/types/query',
    async (
        requestData: {
            query: InvitationTypesQueryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(INVITATION_TYPES_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getPartnerInvitationsAnalytica = createAsyncThunk(
    'partners-invitations/analytica',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PARTNER_INVITATIONS.analytica());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
