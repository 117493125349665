import { HeadProps } from 'ui-component/Tables/NawyTable/Headers';

const CRMControlHeaders: HeadProps[] = [
    {
        disablePadding: true,
        sort: true,
        id: 'id',
        label: 'crm-id',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: false,
        id: 'name',
        label: 'crm-name',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: false,
        id: '_count.salesAgents',
        minWidth: '10px',
        label: 'agents',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: false,
        id: 'viewInventory',
        label: 'view-inventory',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: false,
        id: 'creationDate',
        label: 'creation-date',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: false,
        minWidth: '200px',
        id: 'createdBy',
        label: 'created-by',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: false,
        id: 'disabled',
        label: 'status',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: false,
        id: 'actions',
        label: 'actions',
        align: 'right'
    }
];
export default CRMControlHeaders;
