import { Grid, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import { useIntl } from 'react-intl';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import Chip from 'ui-component/extended/Chip';
import { gridSpacing } from 'store/constant';
import { format } from 'date-fns';

const CRMDetailsSection = ({ crm }: { crm: CRMControlInterface }) => {
    const intl = useIntl();

    return (
        <Grid item container display="block">
            <SubCard title={intl.formatMessage({ id: 'crm-details' })}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={6} md={3} sm={3}>
                        <Typography>{intl.formatMessage({ id: 'status' })}</Typography>
                        <Chip
                            label={crm.disabled ? intl.formatMessage({ id: 'deactivated' }) : intl.formatMessage({ id: 'active' })}
                            variant="outlined"
                            size="small"
                            chipcolor={!crm.disabled ? 'success' : 'error'}
                        />
                    </Grid>
                    <Grid item xs={6} md={3} sm={3}>
                        <Typography>{intl.formatMessage({ id: 'view-inventory' })}</Typography>
                        <Chip
                            label={crm.viewInventory ? intl.formatMessage({ id: 'yes' }) : intl.formatMessage({ id: 'no' })}
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={6} md={3} sm={3}>
                        <Typography variant="body1">{intl.formatMessage({ id: 'created-by' })}</Typography>
                        <Typography variant="h5">{crm.createdBy.name}</Typography>
                    </Grid>
                    <Grid item xs={6} md={3} sm={3}>
                        <Typography>{intl.formatMessage({ id: 'created-at' })}</Typography>
                        <Typography variant="h5">{format(new Date(crm.createdAt), 'dd-MM-yyyy')}</Typography>
                    </Grid>
                </Grid>
            </SubCard>
        </Grid>
    );
};

export default CRMDetailsSection;
