import { Grid, IconButton, Typography, useMediaQuery } from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { gridSpacing, gridSpacingMedium } from 'store/constant';

import { useTheme } from '@mui/styles';
import { ReactNode } from 'react';
import RoleGuard, { RoleGuardGroupProps } from 'utils/RoleGuard';
import { FormattedMessage } from 'react-intl';

export interface PageHeaderAction {
    desktop?: ReactNode[];
    mobile?: React.ReactElement;
    groups?: RoleGuardGroupProps[];
}

export interface PageHeaderProps {
    title?: string;
    actions?: PageHeaderAction;
    backBtn?: boolean;
    color?: string;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, actions, color, backBtn }) => {
    const navigate = useNavigate();
    const handleBackNavigate = () => navigate(-1);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container spacing={gridSpacing}>
            <Grid xs={12} item container alignItems="center" justifyContent="space-between" wrap="nowrap">
                <Grid item>
                    <Grid container alignItems="center" spacing={gridSpacingMedium}>
                        {backBtn && (
                            <Grid item>
                                <IconButton
                                    onClick={handleBackNavigate}
                                    sx={{
                                        width: 35,
                                        height: 35,
                                        borderRadius: 1,
                                        border: '1px solid'
                                    }}
                                >
                                    <ArrowBackIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                        {title ? (
                            <Grid item>
                                <Typography textTransform="capitalize" variant="h3" fontWeight={700} color={color}>
                                    <FormattedMessage id={title} />
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>

                {/* Desktop */}
                {actions && !isMobile ? (
                    <Grid item>
                        <Grid container spacing={2}>
                            {actions.desktop?.map((action, i) => (
                                <RoleGuard key={`desktop-action-${i}`} groups={actions.groups ?? []}>
                                    <Grid item>{action}</Grid>
                                </RoleGuard>
                            ))}
                        </Grid>
                    </Grid>
                ) : null}

                {/* Mobile */}
                {actions && isMobile ? <RoleGuard groups={actions.groups ?? []}>{actions.mobile}</RoleGuard> : null}
            </Grid>
        </Grid>
    );
};

export default PageHeader;
