import { SIRENS_API } from 'store/apis';

const SETTINGS_APIS = {
    getSettings: () => `${SIRENS_API}settings`,
    updateSettings: (id: number) => `${SIRENS_API}settings/${id}`
};
export const GROUPS_APIS = {
    /**
     * @GET /groups
     * @returns groups[]
     */
    getGroups: () => `${SIRENS_API}groups`,
    /**
     * @GET /groups
     * @returns groups[]
     */
    getGroupById: (id: string) => `${SIRENS_API}groups/${id}`,
    /**
     * @POST
     * @returns Group
     */
    createGroup: () => `${SIRENS_API}groups`,
    /**
     * @PATCH
     * @returns Group
     */
    updateGroup: (id: string) => `${SIRENS_API}groups/${id}`,
    /**
     * @DELETE
     * @returns success message
     */
    deleteGroup: (id: string) => `${SIRENS_API}groups/${id}`
};

export default SETTINGS_APIS;
