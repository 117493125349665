// types
import { createSlice } from '@reduxjs/toolkit';

import {
    createAccountManagerActivity,
    deleteAccountManagerActivity,
    getAccountManagerActivityById,
    getAccountManagerActivityMetadata,
    queryAccountManagerActivities,
    updateAccountManagerActivity
} from './actions';
import { AccountManagerActivitiesInitialState } from './interfaces';

const initialState: AccountManagerActivitiesInitialState = {
    loading: false,
    error: false,
    refresh: false,
    metadata: {
        statusesCount: {},
        nextActivity: null
    },
    activities: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - ACCOUNT MANAGERS Activities ||============================== //
const accountManagerActivities = createSlice({
    name: 'accountManagerActivities', // Update the slice name
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryAccountManagerActivities.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryAccountManagerActivities.fulfilled, (state, action) => {
                state.loading = false;
                state.activities = action.payload;
            })
            .addCase(queryAccountManagerActivities.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getAccountManagerActivityById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagerActivityById.fulfilled, (state, action) => {
                state.loading = false;
                state.activity = action.payload;
            })
            .addCase(getAccountManagerActivityById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createAccountManagerActivity.pending, (state) => {
                state.error = false;
            })
            .addCase(createAccountManagerActivity.fulfilled, (state, action) => {
                state.activity = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(createAccountManagerActivity.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateAccountManagerActivity.pending, (state) => {
                state.error = false;
            })
            .addCase(updateAccountManagerActivity.fulfilled, (state, action) => {
                state.activity = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(updateAccountManagerActivity.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteAccountManagerActivity.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteAccountManagerActivity.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteAccountManagerActivity.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getAccountManagerActivityMetadata.pending, (state) => {
                state.error = false;
            })
            .addCase(getAccountManagerActivityMetadata.fulfilled, (state, action) => {
                state.metadata = action.payload;
            })
            .addCase(getAccountManagerActivityMetadata.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default accountManagerActivities.reducer; // Update the export
