// types
import { createSlice } from '@reduxjs/toolkit';
import { deleteListing, getListingDetails, unlistListing, updateListingStatus } from './actions';
import { initialListingDetailsState } from './interfaces';

// initial state
const initialState: initialListingDetailsState = {
    loading: false,
    error: false,
    refresh: false
};

// ==============================|| SLICE - listingDetails ||============================== //

const listingDetails = createSlice({
    name: 'listing/details',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getListingDetails.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getListingDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.listing = action.payload;
            })
            .addCase(getListingDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(unlistListing.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(unlistListing.fulfilled, (state, action) => {
                state.loading = false;
                state.listing = action.payload;
            })
            .addCase(unlistListing.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateListingStatus.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateListingStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.listing = action.payload;
            })
            .addCase(updateListingStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteListing.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteListing.fulfilled, (state, action) => {
                state.loading = false;
                state.listing = action.payload;
            })
            .addCase(deleteListing.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default listingDetails.reducer;

// export const {} = listingDetails.actions;
