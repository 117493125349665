import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import INVITATION_TYPES_APIS from './apis';
import { CreateInvitationTypeInterface, InvitationTypesQueryInterface, InvitationTypesUpdateInterface } from './interfaces';

export const createInvitationType = createAsyncThunk(
    'invitation-types/create',
    async (
        requestData: {
            createData: CreateInvitationTypeInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(INVITATION_TYPES_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryInvitationType = createAsyncThunk(
    'invitation-types/query',
    async (
        requestData: {
            queryData: InvitationTypesQueryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(INVITATION_TYPES_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateInvitationType = createAsyncThunk(
    'invitation-types/update',
    async (
        requestData: {
            id: number;
            update: InvitationTypesUpdateInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, update, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(INVITATION_TYPES_APIS.update(id), update);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteInvitationType = createAsyncThunk(
    'invitation-types/delete',
    async (
        requestData: {
            id: number;

            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(INVITATION_TYPES_APIS.delete(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
