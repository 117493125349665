import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import ACTIVITY_TYPE_APIS from './api';
import { QueryActivityTypeInterface } from './interfaces';

// eslint-disable-next-line import/prefer-default-export
export const queryActivityTypes = createAsyncThunk(
    'activity-type/query',
    async (
        requestData: {
            runSuccess?: () => void;
            query: QueryActivityTypeInterface;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess, query } = requestData;

        try {
            const successRes = await axios.get(ACTIVITY_TYPE_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
