// hooks
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

// routes
import AccountManagerRoutes from './AccountManagerRoutes';
import AdminRoutes from './AdminRoutes';
// import AmbassadorRoutes from './AmbassadorRoutes';
import CRMRoutes from './CRMRouts';
import CilsRoutes from './CilsRoute';

// import InternalAgentsRoutes from './InternalAgentsRoutes';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import redirectRoutes from '../redirects';
import AuthRoutes from './AuthRoutes';
import BannersRoutes from './BannersRoutes';
import CRMControlRoutes from './CRMControlRoutes';
import CRMFunnelReportRoutes from './CRMFunnelReportRoutes';
import CommissionTerms from './CommissionTerms';
import CommonRoutes from './CommonRoutes';
import DevelopersContactsRoutes from './DevelopersContactsRoutes';
import DevelopersRoutes from './DevelopersRoute';
import FreelancersTermsAndConditionsRoutes from './FreelancersTermsAndConditionsRoutes';
import InventoryRoutes from './InventoryRoutes';
import InvitationsRoutes from './InvitationsRoutes';
import LaunchesRoutes from './LaunchesRoutes';
import LeadsRoutes from './LeadRoutes';
import ListingsRoutes from './ListingsRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';
import NawyInventoryRoutes from './NawyInventoryRoutes';
import OrganizationsRoutes from './OrganizationsRoutes';
import PublicRoutes from './PublicRoutes';
import SalesRoutes from './SalesRoutes';
import SettingsRoutes from './SettingsRoutes';
import UsersRoutes from './UsersRoutes';
import VerificationRoutes from './VerificationRoutes';
import ActivitiesRoutes from './activitiesRoutes';
import FlowRoutes from './flowRoutes';
import GrowWithPartnersRoutes from './GrowWithPartnersRoutes';
import DailyInsightsRoutes from './DailyInsightsRoutes';
import TopSellingCompoundsRoutes from './TopSellingCompounds';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const isRedirectRoute = redirectRoutes.some((route) =>
            new RegExp(`^${route.replace(/:[^\s/]+/g, '[^/]+')}$`).test(location.pathname)
        );

        if (user && location.state?.redirectTo) {
            navigate(location.state.redirectTo.pathname, { replace: true });
        } else if (!user && isRedirectRoute) {
            navigate('/', { state: { redirectTo: location }, replace: true });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, user]);

    return useRoutes([
        AuthRoutes,
        PublicRoutes,
        CommonRoutes,
        SalesRoutes,
        SettingsRoutes,
        AccountManagerRoutes,
        UsersRoutes,
        OrganizationsRoutes,
        InventoryRoutes,
        LeadsRoutes,
        ActivitiesRoutes,
        AdminRoutes,
        NawyInventoryRoutes,
        DevelopersRoutes,
        LaunchesRoutes,
        CRMRoutes,
        CRMControlRoutes,
        CilsRoutes,
        FlowRoutes,
        ListingsRoutes,
        InvitationsRoutes,
        MaintenanceRoutes,
        VerificationRoutes,
        BannersRoutes,
        DevelopersContactsRoutes,
        CommissionTerms,
        CRMFunnelReportRoutes,
        FreelancersTermsAndConditionsRoutes,
        GrowWithPartnersRoutes,
        DailyInsightsRoutes,
        TopSellingCompoundsRoutes
    ]);
}
