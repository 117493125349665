import { Typography, Grid, Container } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import PagesEnum from 'components/Auth/Utils/pagesEnum';
import Icon1 from 'assets/images/icon1.svg';
import Icon2 from 'assets/images/icon2.svg';
import Icon3 from 'assets/images/icon3.svg';
import BecomePartnerCardV2 from 'components/Auth/Actions/BecomePartnerV2';
import UserCrmFormInfo from 'components/Auth/Utils/UserCrmFormInfo';
import PartnersBenefitsContent from './PartnersBenefitsContent';
import PartnersCompetitionContent from './PartnersCompetitionContent';

function BecomePartnerContent() {
    const theme = useTheme();
    const [, setUserInfo] = useState<UserCrmFormInfo>({ phone: '', fullName: '', companyName: '' });
    const [, setCurrentPage] = useState(PagesEnum.LOGIN);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { locale } = useIntl();

    const items = [
        { text: 'partner-menu-text-1', icon: Icon1 },
        { text: 'partner-menu-text-2', icon: Icon2 },
        { text: 'partner-menu-text-3', icon: Icon3 }
    ];

    return (
        <div>
            {isSmallScreen ? (
                <Grid container justifyContent="center" sx={{ background: theme.palette.primary[800], height: '2200px' }}>
                    <Grid
                        item
                        md={6}
                        alignItems="center"
                        sx={{
                            backgroundImage: `linear-gradient(180deg, #165DB2 0%, #00234C 100%)`
                        }}
                        width="100%"
                        top="0"
                    >
                        <Grid container alignContent="flex-top" justifyContent="center" alignItems="center">
                            <Grid item alignItems="center" marginLeft={2} marginRight={2} marginTop={2}>
                                <Typography variant="h2" fontSize="34px" fontWeight={700} color="white" align="center" marginBottom={2}>
                                    <FormattedMessage id="become-partner-text-1" />
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontSize={isSmallScreen ? '16px' : '24px'}
                                    fontWeight={400}
                                    color="white"
                                    marginBottom={2}
                                    marginLeft={3}
                                    marginRight={3}
                                    align="center"
                                >
                                    <FormattedMessage id="become-partner-text-2" />
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        gap: 2,
                                        padding: 2,
                                        borderRadius: 1,
                                        width: '370px',
                                        color: 'white'
                                    }}
                                >
                                    {items.map((item, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                                backgroundColor: `rgba(255, 255, 255, 0.10)`,
                                                padding: 1,
                                                borderRadius: '8px',
                                                width: '100%',
                                                filter: locale === 'ar' ? 'FlipH' : 'none'
                                            }}
                                        >
                                            <img src={item.icon} alt="icons" style={{ width: '48px', height: '48px' }} />

                                            <Typography variant="body1">
                                                <FormattedMessage id={item.text} />
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} justifyContent="center" alignItems="center" marginTop={2}>
                        <Grid
                            container
                            height="550px"
                            maxWidth="395px"
                            borderRadius="24px"
                            style={{
                                backgroundImage: `linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                boxShadow: `0px 8px 10px -6px rgba(16, 24, 40, 0.06),0px 20px 25px -5px rgba(16, 24, 40, 0.06)`,
                                margin: '0 auto',
                                width: `calc(100% - 30px)`,
                                paddingInline: '20px'
                            }}
                        >
                            <BecomePartnerCardV2
                                key={PagesEnum.BECOME_PARTNER}
                                setUserInfo={setUserInfo}
                                setCurrentPage={setCurrentPage}
                                mixPanelEventName="Submit form become partner page"
                            />
                        </Grid>
                    </Grid>

                    <PartnersCompetitionContent />
                    <PartnersBenefitsContent />
                </Grid>
            ) : (
                <Grid container justifyContent="center">
                    <Box
                        sx={{
                            width: '100%',
                            height: '860px',
                            flexShrink: '0',
                            backgroundImage: `radial-gradient(90.12% 107.66% at 92.11% 2.97%, #165DB2 0%, #00234C 100%)`
                        }}
                    >
                        <Container maxWidth={false} sx={{ maxWidth: '1200px', width: '100%' }}>
                            <Grid container marginTop={9} marginBottom={2} columnSpacing={16}>
                                <Grid item md={6}>
                                    <Grid container alignContent="flex-top" justifyContent="center" marginTop={2}>
                                        <Grid item>
                                            <Typography
                                                variant="h2"
                                                fontSize="40px"
                                                fontWeight={700}
                                                color="white"
                                                align="left"
                                                marginBottom={2}
                                            >
                                                <FormattedMessage id="become-partner-text-1" />
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                fontSize={isSmallScreen ? '16px' : '24px'}
                                                fontWeight={400}
                                                color="white"
                                                marginBottom={2}
                                            >
                                                <FormattedMessage id="become-partner-text-2" />
                                            </Typography>
                                            <Typography
                                                variant="h2"
                                                fontSize={isSmallScreen ? '24px' : '32px'}
                                                fontWeight={700}
                                                color="white"
                                                marginBottom={2}
                                            >
                                                <FormattedMessage id="become-partner-text-3" />
                                            </Typography>
                                            <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                                                <FormattedMessage id="become-partner-text-4" />
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: 2,
                                                    borderRadius: 1,
                                                    color: 'white',
                                                    paddingTop: 2,
                                                    margin: '0 auto',
                                                    width: `calc(100% - 30px)`
                                                }}
                                            >
                                                {items.map((item, index) => (
                                                    <Box
                                                        key={index}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            gap: 1,
                                                            backgroundColor: `rgba(255, 255, 255, 0.10)`,
                                                            padding: 1,
                                                            borderRadius: '8px',
                                                            width: '100%',
                                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                                        }}
                                                    >
                                                        <img src={item.icon} alt="icons" style={{ width: '48px', height: '48px' }} />

                                                        <Typography variant="body1">
                                                            <FormattedMessage id={item.text} />
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid
                                        container
                                        height="620px"
                                        maxWidth="480px"
                                        borderRadius="24px"
                                        style={{
                                            backgroundImage: `linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                            boxShadow: `0px 8px 10px -6px rgba(16, 24, 40, 0.06),0px 20px 25px -5px rgba(16, 24, 40, 0.06)`,
                                            width: `calc(100% - 30px)`,
                                            paddingInline: '20px'
                                        }}
                                    >
                                        <BecomePartnerCardV2
                                            key={PagesEnum.BECOME_PARTNER}
                                            setUserInfo={setUserInfo}
                                            setCurrentPage={setCurrentPage}
                                            mixPanelEventName="Submit form sign up page"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                    <PartnersCompetitionContent />
                    <PartnersBenefitsContent />
                </Grid>
            )}
        </div>
    );
}
export default BecomePartnerContent;
