import { Grid } from '@mui/material';
import WidgetCard from './WidgetCard';
import { useIntl } from 'react-intl';
import { AnalyticsWidgetsData } from 'ui-component/Tables/NawyTable/Widget';
import RoleGuard from 'utils/RoleGuard';
import { gridSpacingSmall } from 'store/constant';

const TotalsWidgetCard = ({ data }: { data: AnalyticsWidgetsData[] }) => {
    const intl = useIntl();

    return (
        <Grid container alignItems="center" spacing={gridSpacingSmall}>
            {data.map((analytica, i) =>
                analytica.allowedAudience ? (
                    <RoleGuard key={i} groups={analytica.allowedAudience}>
                        <Grid item key={i}>
                            {analytica.label === intl.formatMessage({ id: 'total' }) ? (
                                <WidgetCard label={intl.formatMessage({ id: 'total' })} upperTotal={analytica.value} isMain={false} />
                            ) : (
                                <WidgetCard label={analytica.label} upperTotal={analytica.value} isMain={false} />
                            )}
                        </Grid>
                    </RoleGuard>
                ) : (
                    <Grid key={i} item>
                        {analytica.label === intl.formatMessage({ id: 'total' }) ? (
                            <WidgetCard label={intl.formatMessage({ id: 'total' })} upperTotal={analytica.value} isMain={false} />
                        ) : (
                            <WidgetCard label={analytica.label} upperTotal={analytica.value} isMain={false} />
                        )}
                    </Grid>
                )
            )}
        </Grid>
    );
};

export default TotalsWidgetCard;
