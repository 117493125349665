// hooks
import useAuth from 'hooks/useAuth';
import { SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from '@mui/material';

// icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// third party
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import * as Yup from 'yup';

// project imports
import { LoadingButton } from '@mui/lab';
import AnimateButton from 'ui-component/extended/AnimateButton';

import { MuiTelInput } from 'mui-tel-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacingSmall } from 'store/constant';

// ============================|| Cognito - LOGIN ||============================ //

export interface CognitoLoginProps {
    loginProp?: number;
    onLoggedIn?: () => void;
}

const CognitoLogin = ({ loginProp, onLoggedIn, ...others }: CognitoLoginProps) => {
    const intl = useIntl();
    const LoginSchema = Yup.object().shape({
        phone: Yup.number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        password: Yup.string()
            .max(255)
            .required(intl.formatMessage({ id: 'password-required' }))
    });

    const [checked, setChecked] = useState(true);
    const { login } = useAuth();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: SyntheticEvent) => {
        event.preventDefault();
    };

    const initialValues = {
        phone: '',
        password: '',
        submit: null
    };

    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            formikHelpers.setErrors({ submit: '' });

            const isPhoneValid = parsePhoneNumber(values?.phone, 'EG')?.isValid();
            if (!isPhoneValid) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }

            login(values?.phone, values.password, true)
                .then(() => {
                    formikHelpers.setSubmitting(false);
                    onLoggedIn?.();
                })
                .catch((_err) => {
                    formikHelpers.setSubmitting(false);
                    formikHelpers.setErrors({ submit: _err.message });
                });
        },
        validationSchema: LoginSchema,
        enableReinitialize: true
    });

    return (
        <form noValidate onSubmit={formik.handleSubmit} {...others}>
            <Grid container spacing={gridSpacingSmall}>
                <Grid item xs={12}>
                    <MuiTelInput
                        dir="ltr"
                        fullWidth
                        id="invite-partner-phone"
                        name="phone"
                        forceCallingCode
                        focusOnSelectCountry
                        disableFormatting
                        defaultCountry="EG"
                        excludedCountries={['IL']}
                        disabled={formik.isSubmitting}
                        label={`${intl.formatMessage({ id: 'phone' })} *`}
                        value={formik.values.phone}
                        onChange={(v) => formik.setFieldValue('phone', v)}
                        onBlur={formik.handleBlur}
                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                        helperText={formik.touched.phone && formik.errors.phone}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        disabled={formik.isSubmitting}
                        fullWidth
                        id="outlined-adornment-password-login"
                        type={showPassword ? 'text' : 'password'}
                        value={formik.values.password}
                        placeholder={`${intl.formatMessage({ id: 'password' })} *`}
                        name="password"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        autoComplete="password"
                        helperText={formik.touched.password && formik.errors.password}
                        error={Boolean(formik.touched.password && formik.errors.password)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        size="large"
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        label={`${intl.formatMessage({ id: 'password' })} *`}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checked}
                                    disabled={formik.isSubmitting}
                                    onChange={(event) => setChecked(event.target.checked)}
                                    name="checked"
                                    color="primary"
                                />
                            }
                            label={intl.formatMessage({ id: 'remember-me' })}
                        />
                        <Typography
                            variant="subtitle1"
                            component={Link}
                            to={loginProp ? `/forgot${loginProp}` : '/forgot'}
                            color="primary"
                            sx={{ textDecoration: 'none' }}
                        >
                            <FormattedMessage id="forget-password?" />
                        </Typography>
                    </Stack>
                    {formik.errors.submit && (
                        <Box sx={{ mt: 3 }}>
                            <FormHelperText error>{formik.errors.submit}</FormHelperText>
                        </Box>
                    )}
                </Grid>

                <Grid item xs={12}>
                    <AnimateButton>
                        <LoadingButton
                            disableElevation
                            loading={formik.isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            <FormattedMessage id="login" />
                        </LoadingButton>
                    </AnimateButton>
                </Grid>
            </Grid>
        </form>
    );
};

export default CognitoLogin;
