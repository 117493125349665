import { createSlice } from '@reduxjs/toolkit';
import { addConditionToEvent, createEvent, deleteEvent, getEventById, queryEvents, removeConditionFromEvent, updateEvent } from './actions';
// import {  } from './actions';
import { EventsInitialStateInterface } from './interfaces';

// initial state
const initialState: EventsInitialStateInterface = {
    loading: false,
    error: false,
    errorList: false,
    loadingList: false,
    refresh: false,
    events: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE -events ||============================== //

const events = createSlice({
    name: 'events',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryEvents.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryEvents.fulfilled, (state, action) => {
                state.loadingList = false;
                state.events = action.payload;
            })
            .addCase(queryEvents.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(getEventById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getEventById.fulfilled, (state, action) => {
                state.loading = false;
                state.event = action.payload;
            })
            .addCase(getEventById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createEvent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.event = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateEvent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateEvent.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteEvent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addConditionToEvent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(addConditionToEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(addConditionToEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(removeConditionFromEvent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(removeConditionFromEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(removeConditionFromEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addDefaultCase((state, action) => {});
    }
});

export default events.reducer;

// export const {  } = events.actions;
