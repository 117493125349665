import { SIRENS_API, formattedQuery } from 'store/apis';
import { CommonQueryInterface } from 'store/interfaces';

const EVENTS_APIS = {
    query: (query: CommonQueryInterface) => {
        const formatQuery: string[] = [SIRENS_API, 'events', '?'];
        return formattedQuery(formatQuery, query);
    },
    create: () => `${SIRENS_API}events`,
    update: (id: string) => `${SIRENS_API}events/${id}`,
    delete: (id: string) => `${SIRENS_API}events/${id}`,
    getById: (id: string) => `${SIRENS_API}events/${id}`,
    addCondition: (id: string) => `${SIRENS_API}events/${id}/conditions`,
    removeCondition: (id: string, conditionId: string) => `${SIRENS_API}events/${id}/conditions/${conditionId}`
};

export default EVENTS_APIS;
