import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import ANALYTICA_APIS from './apis';

export const getSaleClaimsAnalytics = createAsyncThunk(
    'analytica/sale-claims',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(ANALYTICA_APIS.SaleClaims());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getCilsAnalytics = createAsyncThunk(
    'analytica/cils',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(ANALYTICA_APIS.cils());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getInvitationsAnalytics = createAsyncThunk(
    'analytica/invitations',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(ANALYTICA_APIS.invitations());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
