import { Box, FormControl, FormLabel, FormLabelTypeMap, Grid, GridTypeMap, Icon, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

import ImagesPreview from './Preview';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { gridSpacing } from 'store/constant';
import { UploadErrorInterface } from 'store/slices/organizations/interfaces';

interface ImageUploadProps {
    id?: string;
    documents?: File[];
    errors?: UploadErrorInterface[];
    renderOverlay?: React.ReactElement | null;
    accept?: string;
    mutliple?: boolean;
    props?: {
        containerProps?: GridTypeMap['props'];
        uploadProps?: GridTypeMap['props'];
        thumbnailProps?: GridTypeMap['props'];
        errorProps?: FormLabelTypeMap['props'];
    };
    handleAddImages: (files: File[]) => void;
    handleDeleteImage: (name: string) => void;
}

export default function ImageUploadDragAndDropV3({
    id = '',
    documents,
    errors,
    renderOverlay,
    mutliple = false,
    accept = 'image/png, image/jpeg, image/jpg',
    props = {},
    handleAddImages,
    handleDeleteImage
}: ImageUploadProps) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();
    const documentsParsed = documents?.map((document) => ({
        src: URL.createObjectURL(document),
        name: document.name,
        file: document
    }));
    const [images, setImages] = useState<{ src: string; name: string; file?: File }[]>(documentsParsed ?? []);
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const IMAGE_SIZE_LIMIT = 12097152;

    const handleFiles = (files: FileList) => {
        const currentImages = [...images];

        for (const file of files) {
            if (file.size <= IMAGE_SIZE_LIMIT) {
                const existingImageIndex = currentImages.findIndex((image) => image.name === file.name);
                if (existingImageIndex !== -1) {
                    currentImages.splice(existingImageIndex, 1);
                    handleDeleteImage(file.name);
                }
                currentImages.push({ src: URL.createObjectURL(file), name: file.name, file });
            } else {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: intl.formatMessage({ id: 'image-cant-exceed-limit' }),
                        variant: 'alert',
                        alert: {
                            color: 'error'
                        },
                        close: false
                    })
                );
            }
        }
        setImages(currentImages);
    };

    const handleRemove = (name: string) => {
        const newImages = images.filter((file) => file.name !== name);
        setImages(newImages);
        handleDeleteImage(name);
    };

    const handleDrag: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e?.dataTransfer?.files?.[0]) {
            handleFiles(e.dataTransfer.files);
        }
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { target } = e;
        if (target?.files) {
            handleFiles(target.files);
            handleAddImages([...target.files]);
        }
        target.value = '';
    };

    return (
        <Grid container spacing={gridSpacing} {...props?.containerProps}>
            <Grid item xs={12} md={4} {...props?.uploadProps}>
                <FormControl
                    id={`${id}-form-file-upload`}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                    sx={{
                        height: '150px',
                        width: '100%',
                        textAlign: 'center',
                        position: 'relative'
                    }}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        style={{ display: 'none', height: '100%', width: '100%' }}
                        ref={inputRef}
                        type="file"
                        id={`${id}-input-file-upload`}
                        multiple={mutliple}
                        accept={accept}
                        onChange={handleChange}
                    />
                    <FormLabel
                        id={`${id}-label-file-upload`}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: '2px',
                            borderRadius: '1rem',
                            borderStyle: 'dashed',
                            borderColor: theme.palette.primary.light,
                            backgroundColor: theme.palette.grey[50],
                            ...props.errorProps?.sx
                        }}
                        htmlFor={`${id}-input-file-upload`}
                    >
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Icon>
                                    <AddPhotoAlternateOutlinedIcon />
                                </Icon>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color: theme.palette.grey[600] }}>
                                    <FormattedMessage id="drag-and-drop-files-here" />
                                </Typography>
                            </Grid>
                            <Grid item container xs={12} justifyContent="center">
                                <Typography sx={{ color: theme.palette.grey[600] }}>
                                    <FormattedMessage id="or" />
                                </Typography>
                                <Typography>&nbsp;</Typography>
                                <Typography sx={{ color: theme.palette.primary.main, textDecoration: 'underline' }}>
                                    <FormattedMessage id="browse" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </FormLabel>
                    {dragActive && (
                        <Box
                            id={`${id}-drag-file-element`}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        />
                    )}
                </FormControl>
                {renderOverlay}
            </Grid>

            {images?.length > 0 ? (
                <Grid item xs={12} md={8} {...props?.thumbnailProps}>
                    <ImagesPreview
                        images={images}
                        errors={errors}
                        handleRestartUpload={(name) => {
                            const image = images.find((img) => img.name === name);
                            if (image?.file) {
                                handleAddImages([image.file]); // Re-upload
                            }
                        }}
                        handleRemoveImage={handleRemove}
                    />
                </Grid>
            ) : null}
        </Grid>
    );
}
