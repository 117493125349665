import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryBrokersInterface, QueryBrokersMinimalInterface } from './interfaces';

const ENTITY = 'brokers';

const BROKERS_APIS = {
    // GET
    query: (query: QueryBrokersInterface) => {
        const formatQuery: string[] = [FAUNA_API, ENTITY, '?'];
        return formattedQuery(formatQuery, query);
    },
    /**
     * @GET
     * @Summary
     * @param brokerCId
     * @returns Full Brokers Data
     */
    getById: (brokerCId: string) => `${FAUNA_API}${ENTITY}/${brokerCId}`,
    /**
     * @GET
     * @Summary
     * @param brokerCId
     * @returns Full Brokers Data
     */
    queryMinimal: (query: QueryBrokersMinimalInterface) => {
        const formatQuery: string[] = [FAUNA_API, ENTITY, '/minimal', '?'];
        return formattedQuery(formatQuery, query);
    },
    // PATCH
    changeRole: (brokerCId: string) => `${FAUNA_API}${ENTITY}/${brokerCId}`,
    // PATCH
    recoverBroker: (brokerCId: string) => `${FAUNA_API}${ENTITY}/${brokerCId}/recover`,
    // PATCH
    updateBrokerSalesAgent: (id: string) => `${FAUNA_API}${ENTITY}/${id}`,
    // DELETE
    hardDelete: (brokerCId: string) => `${FAUNA_API}${ENTITY}/${brokerCId}`,
    /**
     * @POST
     * @summary Create a new broker in fauna and then add the user to brokers group is sirens with queue
     * @param organizationId
     * @param brokerCId
     * @body  phone, name
     * @returns string
     */
    createBrokerFromExistingUser: (organizationId: string, brokerCId: string) =>
        `${FAUNA_API}organizations/${organizationId}/${ENTITY}/${brokerCId}`,
    moveBroker: (brokerCId: string) => `${FAUNA_API}brokers/${brokerCId}/move-broker`
};

export default BROKERS_APIS;
