import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useMixPanel from 'hooks/useMixPanel';
import useOverlay from 'hooks/useOverlay';
import { FormattedMessage, useIntl } from 'react-intl';
import { utils, writeFile } from 'xlsx';

export interface ExportDialogProps {
    open?: boolean;
    data?: Record<string, string | undefined>[];
    title?: string;
    handleDialogState?: (v: boolean) => void;
}

const ExportDialog = ({ open = false, handleDialogState, data = [], title }: ExportDialogProps) => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<ExportDialogProps>('ExportDialog');

    const handleClose = () => {
        handleDialogState?.(false);
        close('ExportDialog');
    };

    const handleExport = async () => {
        emitEvent(`Click Confirm Export ${payload?.title ?? title}`);
        try {
            const worksheet = utils.json_to_sheet(payload?.data ?? []);
            const workbook = utils.book_new();
            utils.book_append_sheet(workbook, worksheet, payload?.title ?? title);

            writeFile(workbook, `${payload?.title ?? title}-${intl.formatDate(new Date(), { dateStyle: 'medium' })}.xlsx`);
            handleClose();
        } catch (error) {
            emitEvent('Error - Export Failed', { errorMessage: JSON.stringify(error) });
        }
    };

    return (
        <Dialog open={isCurrentOpened} onClose={handleClose} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>
                <FormattedMessage id="export" /> {payload?.title ?? title}
            </DialogTitle>
            <DialogContent>
                <FormattedMessage id="export-dialog-text" />
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={handleClose}>
                    <FormattedMessage id="cancel" />
                </Button>
                <LoadingButton variant="contained" onClick={handleExport}>
                    <FormattedMessage id="confirm" />
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ExportDialog;
