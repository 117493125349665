import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useSelector } from 'store';
import { queryOrganizations } from 'store/slices/organizations/actions';
import { allowedOrganizations } from 'store/slices/organizations/apis';
import { OrganizationInterface, OrganizationSingleListData } from 'store/slices/organizations/interfaces';
import RoleGuard from 'utils/RoleGuard';

interface OrganizationsFilterProps {
    defaultValueId?: string;
    value: string;
    handleSearch: (newValue: string) => void;
    handleSelectedOrganization?: (organization: OrganizationSingleListData | null | undefined) => void;
    clear?: boolean;
    excludeAmbassadors?: boolean;
}

const OrganizationsFilter = ({
    defaultValueId,
    value,
    handleSearch,
    handleSelectedOrganization,
    clear = false,
    excludeAmbassadors = false
}: OrganizationsFilterProps) => (
    <RoleGuard groups={allowedOrganizations.query}>
        <Filter
            defaultValueId={defaultValueId}
            value={value}
            handleSearch={handleSearch}
            handleSelectedOrganization={handleSelectedOrganization}
            clear={clear}
            excludeAmbassadors={excludeAmbassadors}
        />
    </RoleGuard>
);

const Filter = ({
    defaultValueId,
    value,
    handleSearch,
    handleSelectedOrganization,
    clear,
    excludeAmbassadors = false
}: OrganizationsFilterProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { organizations, loadingList } = useSelector((state) => state.organizations);

    const [newValue, setNewValue] = useState<string>('');
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(queryOrganizations({ queryData: { name, excludeAmbassadors, pageSize: organizations.count } }));
        setNewValue(name);
    };

    const handleOnChange = (organization: OrganizationSingleListData | null) => {
        setSelectedOrganization(organization);
        handleSearch(organization?.id || '');
        if (handleSelectedOrganization) handleSelectedOrganization(organization);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(queryOrganizations({ queryData: { id: defaultValueId, excludeAmbassadors, pageSize: organizations.count } }));
        } else {
            dispatch(queryOrganizations({ queryData: { name: newValue, excludeAmbassadors, pageSize: organizations.count } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValueId, newValue]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && organizations.data.length > 0) {
            firstRender.current = false;
            const organization = organizations.data.find((c) => c.id === defaultValueId) ?? null;
            setSelectedOrganization(organization);
            if (handleSelectedOrganization) handleSelectedOrganization(organization);
        }
    }, [organizations, defaultValueId, handleSelectedOrganization]);

    useEffect(() => {
        if (clear) {
            setSelectedOrganization(null);
        }
    }, [clear, handleSelectedOrganization]);

    return (
        <Autocomplete
            id="autocomplete-generic-filter-organization"
            fullWidth
            size="small"
            options={organizations.data}
            loading={loadingList}
            value={selectedOrganization}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option) => option?.id === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-organization"
                    label={intl.formatMessage({ id: 'partners' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default OrganizationsFilter;
