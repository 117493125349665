import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const CampaignPage = Loadable(lazy(() => import('views/common/Campaign')));
const NotFoundPage = Loadable(lazy(() => import('views/common/NotFound')));
const ComissionsLandingPage = Loadable(lazy(() => import('views/common/ComissionsLandingPage')));
const AuthLogin = Loadable(lazy(() => import('views/auth/Login/index')));

// ==============================|| Common GUARD ROUTING ||============================== //

const MaintenanceRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '*',
            element: <NotFoundPage />
        },
        {
            path: '/campaign',
            element: <CampaignPage />
        }
    ]
};

export default MaintenanceRoutes;
