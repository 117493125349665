import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { NavItemType } from 'types';

const dailyInsightsItem: NavItemType = {
    id: 'daily-insights',
    type: 'group',
    children: [
        {
            id: 'daily-insights',
            title: <FormattedMessage id="daily-insights" />,
            type: 'item',
            url: '/daily-insights',
            icon: TrendingUpIcon,
            breadcrumbs: false,
            mixpanelEvent: {
                tab_name: 'daily-insights'
            },
            allowedAudience: [
                { name: InvitationTypeEnum.accountManagers, roles: [AccountManagersRole.head, AccountManagersRole.supervisor] }
            ]
        }
    ]
};

export default dailyInsightsItem;
