import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import { useFormik } from 'formik';
import useOverlay from 'hooks/useOverlay';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch } from 'store';
import { updateDocuments } from 'store/slices/organizations/actions';
import { DocumentInterface, DocumentStatus, DocumentTypes, UpdateDocumentsRequestData } from 'store/slices/organizations/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import * as Yup from 'yup';
import { documentsVerificationIntlMap } from '../common/VerificationButton';

interface DocumentStatusPayload {
    documentType: keyof typeof DocumentTypes;
    status: DocumentStatus;
    documents: DocumentInterface[];
    onClose?: () => void;
    onConfirmedRejection?: (status: DocumentStatus) => void;
}

export const rejectionReasonIds = [
    'expired',
    'incorrect',
    'document-rejection-reason-not-updated',
    'document-rejection-reason-does-not-match',
    'documents-pages-incomplete',
    'other'
];

export const rejectionReasonIdsContract = [
    'wrong-phone-number',
    'wrong-e-mail',
    'wrong-tax-id',
    'wrong-cr',
    'not-eligible-for-signing',
    'other'
];

const DocumentRejectionReasonDialog = () => {
    const intl = useIntl();

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<DocumentStatusPayload & { organizationId: string }>('DocumentRejectionReasonDialog');

    const schema = Yup.object().shape({
        reason: Yup.string()
            .trim()
            .required(intl.formatMessage({ id: 'required' })),
        otherReason: Yup.string().when('reason', {
            is: (value: string) => value === intl.formatMessage({ id: 'other' }),
            then: Yup.string()
                .trim()
                .required(intl.formatMessage({ id: 'required' })),
            otherwise: Yup.string().notRequired()
        })
    });

    const handleOnClose = () => {
        formik.resetForm();
        payload?.onClose?.();
        close();
    };

    const onSuccess = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    const formik = useFormik({
        initialValues: {
            reason: '',
            otherReason: ''
        },
        validationSchema: schema,
        onSubmit: (values, formikHelpers) => {
            let reason = values.reason;
            if (reason === intl.formatMessage({ id: 'other' })) {
                reason = values.otherReason;
            }

            const data: UpdateDocumentsRequestData = {
                type: payload?.documentType,
                documentIds: payload?.documents.map((doc) => doc.id) ?? [],
                verificationStatus: payload?.status
            };

            if (payload?.status === 'Rejected') {
                data.rejectionReason = reason;
            }

            dispatch(
                updateDocuments({
                    organizationId: payload?.organizationId!,
                    data,
                    runSuccess: () =>
                        onSuccess(intl.formatMessage({ id: documentsVerificationIntlMap[payload?.status!][payload?.documentType!]! }))
                })
            ).then(() => {
                payload?.onConfirmedRejection?.(payload?.status);
                formikHelpers.setSubmitting(false);
                formikHelpers.resetForm();
                close();
            });
        }
    });

    const isOtherOptionSelected = formik.values.reason === 'other';

    return (
        <Dialog open={isCurrentOpened} onClose={() => handleOnClose()} scroll="paper" maxWidth="sm" fullWidth>
            <DialogTitle>
                <FormattedMessage id="rejection-reason" />
            </DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="reason"
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        >
                            {(payload?.documentType == 'Contract' ? rejectionReasonIdsContract : rejectionReasonIds).map((reason) => (
                                <FormControlLabel
                                    key={reason}
                                    value={isOtherOptionSelected ? reason : intl.formatMessage({ id: reason })}
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: reason })}
                                />
                            ))}
                        </RadioGroup>
                        {formik.touched.reason && formik.errors.reason ? <div style={{ color: 'red' }}>{formik.errors.reason}</div> : null}
                    </FormControl>

                    {formik.values.reason === intl.formatMessage({ id: 'other' }) && (
                        <FormControl fullWidth>
                            <TextField
                                name="otherReason"
                                value={formik.values.otherReason}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.otherReason && Boolean(formik.errors.otherReason)}
                                helperText={formik.touched.otherReason && formik.errors.otherReason}
                                placeholder={intl.formatMessage({ id: 'rejection-note' })}
                            />
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOnClose}>Cancel</Button>
                    <Button variant="contained" type="submit" disabled={!formik.values.reason || formik.isSubmitting}>
                        <FormattedMessage id="confirm" />
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default DocumentRejectionReasonDialog;
