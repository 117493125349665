import { FLORA_API, queryData } from 'store/apis';
import { InventoryControlQueryInterface } from './interfaces';

const primaryInventoryAPIs = {
    query: (query: InventoryControlQueryInterface) => {
        const formatQuery: string[] = [FLORA_API, 'inventory-control', '?'];
        const queryValues = Object.values(query);

        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i] !== undefined) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formatQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });

        return formatQuery.join('');
    },
    updateDeveloperConfigs: () => `${FLORA_API}inventory-control/updateDeveloperConfigs`,
    updateCompound: () => `${FLORA_API}inventory-control/updateCompounds`
};

export default primaryInventoryAPIs;
