import { FAUNA_API, queryData } from 'store/apis';

import { QueryPrimaryCilsInterface } from './interfaces';

const PRIMARY_CILS_APIS = {
    query: (query: QueryPrimaryCilsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'flows', '?stage=PrimaryCil&'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formatQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formatQuery.join('');
    },
    create: () => `${FAUNA_API}primary-cils`,
    analytica: () => `${FAUNA_API}primary-cils/analytica`,
    getById: (id: number) => `${FAUNA_API}primary-cils/${id}`,
    /**
     * @PUT
     * @param id
     * @returns {cil, lead}
     */
    renew: (id: number) => `${FAUNA_API}primary-cils/${id}/renew`,
    updateStatus: (id: number) => `${FAUNA_API}primary-cils/${id}/status`
};

export default PRIMARY_CILS_APIS;
