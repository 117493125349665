import { keyframes } from '@emotion/react';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { Card, Collapse, Grid, IconButton, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import EnBackgroundFull from './background-images/ltr-full.svg';
import EnBackground from './background-images/ltr.svg';
import ArBackground from './background-images/rtl.svg';

export interface ExpandableCardInterface {
    children: ReactNode;
    expandedChildren: ReactNode[];
}

const ExpandableCard = ({ children, expandedChildren }: ExpandableCardInterface) => {
    const myEffect = keyframes`
  0% {
    opacity: 0;
    height: 50;
  }
  100% {
    opacity: 1;
    height: auto;
  }
`;

    const intl = useIntl();
    const theme = useTheme();
    const pageDirection = intl.locale;

    const [expanded, setExpanded] = useState(false);
    const handleExpand = () => {
        setExpanded(!expanded);
    };
    return (
        <Card
            style={{
                borderRadius: 4,
                padding: 12,
                background:
                    pageDirection === 'en' && expanded
                        ? `url("${EnBackgroundFull}") no-repeat right top, linear-gradient(90deg, rgba(0, 52, 174, 0.40) -126.91%, rgba(0, 52, 174, 0.00) 53.11%), linear-gradient(0deg, ${theme.palette.primary[200]} 0%, ${theme.palette.primary[100]} 100%), #FFF`
                        : pageDirection === 'en' && !expanded
                        ? `url("${EnBackground}") no-repeat right top, linear-gradient(90deg, rgba(0, 52, 174, 0.40) -126.91%, rgba(0, 52, 174, 0.00) 53.11%), linear-gradient(0deg, ${theme.palette.primary[200]} 0%, ${theme.palette.primary[100]} 100%), #FFF`
                        : pageDirection === 'ar' && expanded
                        ? `url("${ArBackground}") no-repeat left top, linear-gradient(270deg, rgba(0, 52, 174, 0.40) -98.84%, rgba(0, 52, 174, 0.00) 53.96%), linear-gradient(0deg, #ff0000 0%, ${theme.palette.primary[100]} 100%), #FFF`
                        : `url("${ArBackground}") no-repeat left top, linear-gradient(270deg, rgba(0, 52, 174, 0.40) -98.84%, rgba(0, 52, 174, 0.00) 53.96%), linear-gradient(0deg, ${theme.palette.primary[200]} 0%, ${theme.palette.primary[100]} 100%), #FFF`
            }}
        >
            <Grid container rowGap={gridSpacing}>
                <Grid item container alignItems="center" justifyContent="space-between" wrap="nowrap">
                    {children}
                    <Grid item alignSelf="flex-start">
                        <IconButton onClick={handleExpand} color="primary">
                            <ExpandCircleDownIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                {expanded && (
                    <Collapse in={expanded}>
                        <Grid
                            item
                            container
                            justifyItems="center"
                            spacing={gridSpacing}
                            sx={{
                                animation: `${myEffect} 1s linear`
                            }}
                        >
                            {expandedChildren?.map((child, i) => (
                                <Grid key={i} item xs={12}>
                                    {child}
                                </Grid>
                            ))}
                        </Grid>
                    </Collapse>
                )}
            </Grid>
        </Card>
    );
};
export default ExpandableCard;
