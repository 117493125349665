// hooks
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

// material-ui
import { Box, Collapse, IconButton, Link, Menu, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';

// project imports
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';

// icons
import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';

// interface
import { businessTypesObject } from 'store/slices/inventoryTable/interfaces';
import { ICompoundDetails } from 'store/slices/nawyInventory/interfaces';

// project imports
import { useTheme } from '@mui/styles';
import DevelopersContactsDetails from 'components/DevelopersContacts/UI/List/DevelopersContactsDetails';
import { CreatePrimaryCilMenuItem } from 'components/PrimaryCils/Actions/Create';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import { dispatch, useSelector } from 'store';
import { gridSpacingMedium } from 'store/constant';
import { InvitationTypeEnum } from 'store/interfaces';
import { getBrokerByCId } from 'store/slices/brokers/actions';
import { getDeveloperById } from 'store/slices/developers/actions';
import { getOrganizationById } from 'store/slices/organizations/actions';
import Chip from 'ui-component/extended/Chip';
import RoleGuard from 'utils/RoleGuard';
import { propertyHeaders } from './InventoryHeaders';
import PropertyTypesList from './PropertyTypesList';
import removeDecimalPoint from './helpers';

export default function CompoundDetailsRow({ compound }: { compound: ICompoundDetails }) {
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const { emitEvent } = useMixPanel();
    const [searchParams, setSearchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const theme = useTheme();
    const { user } = useAuth();
    const { broker, error } = useSelector((state: any) => state.brokers);
    const [openContacts, setOpenContacts] = useState(false);
    const { developer } = useSelector((state: any) => state.developers);

    const handleOpenContacts = () => {
        emitEvent('Click on contact icon in inventory page at a certain compound', {
            'Compound name': compound?.name
        });
        if (compound.developer_id) {
            dispatch(getDeveloperById({ id: compound.developer_id })).then(() => {
                setOpenContacts(true);
            });
        }
    };

    const handleCloseContacts = () => {
        setOpenContacts(false);
    };

    useEffect(() => {
        if (!error && !broker && user && user.cognitoId && user.brokerAuth) {
            dispatch(getBrokerByCId({ brokerCId: user.cognitoId }));
        }

        if (broker) {
            dispatch(getOrganizationById({ organizationId: broker.organizationId }));
        }
    }, [user, broker, error]);

    useEffect(() => {
        const selected_compound_id = searchParams.get('selected_compound_id');
        if (selected_compound_id && +selected_compound_id === compound.id) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const handleCompoundChange = () => {
        const selected_compound_id = searchParams.get('selected_compound_id');
        searchParams.delete('selected_compound_id');
        searchParams.delete('selected_property_type_id');
        searchParams.delete('selected_property_id');
        if (!selected_compound_id || (selected_compound_id && +selected_compound_id !== compound.id)) {
            searchParams.set('selected_compound_id', compound.id.toString());
        }
        emitEvent('Click Compound Inventory', { ...compound });

        setSearchParams(searchParams);
    };
    const getCompoundPropertyTypes = () =>
        Object.entries(compound.property_types_names).map(([key, value]) => (
            <Typography variant="subtitle1" textTransform="capitalize" key={key}>
                <FormattedMessage id={key} />
                <Typography>{value}</Typography>
            </Typography>
        ));
    const getCompoundFinishing = () =>
        Object.entries(compound.finishing).map(([key, value]) => (
            <Typography variant="subtitle1" textTransform="capitalize" key={key || 'others'}>
                <FormattedMessage id={key || 'others'} />
                <Typography>{value}</Typography>
            </Typography>
        ));

    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }} onClick={handleCompoundChange}>
                <TableCell sx={{ pl: 3 }}>
                    <IconButton aria-label="expand row" size="small">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="subtitle1" textTransform="capitalize">
                        {compound.name}
                    </Typography>
                    {compound.on_hold && (
                        <Chip size="small" variant="outlined" chipcolor="error" label={intl.formatMessage({ id: 'inactive' })} />
                    )}
                </TableCell>
                <TableCell align="center">{compound.area_name}</TableCell>
                <TableCell align="center">{compound.developer_name}</TableCell>
                <TableCell align="center">{getCompoundPropertyTypes().map((propertyType) => propertyType)}</TableCell>
                <TableCell align="center">
                    {Object.entries(compound.business_types).map(([business_type, value]) => (
                        <Typography variant="subtitle1" textTransform="capitalize" key={business_type}>
                            <FormattedMessage id={businessTypesObject[business_type]} />
                            <Typography>{value}</Typography>
                        </Typography>
                    ))}
                </TableCell>
                <TableCell align="center">
                    {compound.min_area !== compound.max_area ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{compound.min_area ? compound.min_area : '-'} -</Typography>
                            <Typography variant="inherit">{compound.max_area ? compound.max_area : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{compound.min_area ? compound.min_area : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {compound.min_price !== compound.max_price ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">{compound.min_price ? removeDecimalPoint(compound.min_price) : '-'} -</Typography>
                            <Typography variant="inherit">{compound.max_price ? removeDecimalPoint(compound.max_price) : '-'}</Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">{compound.min_price ? removeDecimalPoint(compound.min_price) : '-'}</Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {compound.min_down_payment && compound.min_price && parseInt(compound.min_down_payment, 10)
                        ? `${removeDecimalPoint((+compound.min_down_payment / 100) * +compound.min_price)} (${removeDecimalPoint(
                              compound.min_down_payment
                          )}%)`
                        : '-'}
                </TableCell>
                <TableCell align="center">
                    {compound.min_installments !== compound.max_installments ? (
                        <Stack direction="column" spacing={1}>
                            <Typography variant="inherit">
                                {compound.min_installments ? removeDecimalPoint(compound.min_installments) : '-'} -
                            </Typography>
                            <Typography variant="inherit">
                                {compound.max_installments ? removeDecimalPoint(compound.max_installments) : '-'}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography variant="inherit">
                            {compound.min_installments ? removeDecimalPoint(compound.min_installments) : '-'}
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">{getCompoundFinishing().map((finishing) => finishing)}</TableCell>
                <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
                    {compound.last_update ? format(new Date(compound.last_update), 'dd-MM-yyyy') : intl.formatMessage({ id: 'n-a' })}
                </TableCell>

                <TableCell
                    align="center"
                    sx={{ whiteSpace: 'nowrap', position: 'sticky', right: 0, background: theme.palette.background.paper }}
                >
                    <RoleGuard
                        groups={[
                            { name: InvitationTypeEnum.brokers },
                            { name: InvitationTypeEnum.admins },
                            { name: InvitationTypeEnum.operationManager },
                            { name: InvitationTypeEnum.accountManagers },
                            { name: InvitationTypeEnum.developerRelationsManagers }
                        ]}
                    >
                        <Stack direction="row" spacing={gridSpacingMedium}>
                            <Tooltip title={intl.formatMessage({ id: 'contacts' })}>
                                <IconButton
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        handleOpenContacts();
                                    }}
                                    sx={{ backgroundColor: '#F0F1F3', borderRadius: '64px' }}
                                >
                                    <WifiCalling3Icon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={intl.formatMessage({ id: 'actions' })}>
                                <IconButton
                                    size="medium"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(event.currentTarget);
                                    }}
                                >
                                    <MoreHorizTwoToneIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                        <DevelopersContactsDetails
                            location="inventory page"
                            developer={developer}
                            compoundName={compound?.name}
                            open={openContacts}
                            onClose={handleCloseContacts}
                        />
                        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
                            <MenuItem
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAnchorEl(null);
                                    emitEvent('Click Actions in Inventory', { action_type: 'Sell Unit' });
                                }}
                            />
                            <CreatePrimaryCilMenuItem location="Nawy Inventory Page" />
                            {compound?.bruchure &&
                                compound?.bruchure?.length > 0 &&
                                compound?.bruchure.map((bruchure, index) => (
                                    <MenuItem key={index} download={compound.name} component={Link} target="_blank" href={bruchure}>
                                        <Typography>
                                            <FormattedMessage id="download" /> <FormattedMessage id="bruchure" /> #{index + 1}
                                        </Typography>
                                    </MenuItem>
                                ))}
                        </Menu>
                    </RoleGuard>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell padding="none" colSpan={propertyHeaders.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {open && (
                            <Box sx={{ margin: 1 }}>
                                <PropertyTypesList />
                            </Box>
                        )}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
