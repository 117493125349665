import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import FLOWS_APIS from '../flows/apis';
import { FlowStagesEnum, QueryFlowsInterface } from '../flows/interfaces';
import { QueryPartnersDashboardInterface } from './interfaces';
import PARTNERS_DASHBOARD_APIS from './apis';

export const financingTopPartners = createAsyncThunk(
    'dashboard/financing-cils/top-partners',
    async (
        requestData: {
            query: QueryPartnersDashboardInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(PARTNERS_DASHBOARD_APIS.financingTopPartners(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const primaryCilsTopDevelopers = createAsyncThunk(
    'dashboard/primary-cils/top-developers',
    async (
        requestData: {
            query: QueryPartnersDashboardInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(FLOWS_APIS.topDevelopers({ ...query, stage: FlowStagesEnum.PrimaryCil }));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const saleClaimsTopDevelopers = createAsyncThunk(
    'dashboard/sale-claims/top-developers',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(FLOWS_APIS.topDevelopers({ ...query, stage: FlowStagesEnum.SaleClaim }));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const saleClaimsTopCompounds = createAsyncThunk(
    'dashboard/sale-claims/top-compounds',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(FLOWS_APIS.topCompounds({ ...query, stage: FlowStagesEnum.SaleClaim }));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const reservationsTopCompounds = createAsyncThunk(
    'dashboard/reservations/top-compounds',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(FLOWS_APIS.topCompounds(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const reservationsTopDevelopers = createAsyncThunk(
    'dashboard/reservations/top-developers',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(FLOWS_APIS.topDevelopers(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
