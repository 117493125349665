import { CommonListInterface, CommonQueryInterface } from 'store/interfaces';
import { OrganizationFullDataInterface } from '../organizations/interfaces';

export interface BrokerInterface {
    id: string;
    cognitoId: string | null;
    organizationId: string;
    salesAgentId: string;
    invitationPhone?: string;
    role: OrganizationRole;
    isDeleted: boolean;
    createdAt: Date;
    updatedAt: Date;
}

export interface BrokerFullDataInterface extends BrokerInterface {
    user?: {
        name: string;
        phone?: string;
    };
    organization: {
        id: string;
        name: string;
        tier?: {
            id: string;
            name: string;
        };
        createdBy?: {
            cognitoId: string;
            user: {
                name: string;
            };
        };
        creator?: {
            cognitoId: string;
            name: string;
            phone?: string;
        };
    };
    salesAgent?: {
        cognitoId: string;
        user: {
            name: string;
        };
    };
    cognitoId: string;
    oldBrokerMove?: BrokerMoveInterface;
    newBrokerMove?: BrokerMoveInterface;
}

export interface BrokerOrganizationFullDataInterface extends BrokerInterface {
    organization?: OrganizationFullDataInterface;
}

export interface BrokersListInterface extends CommonListInterface {
    data: BrokerFullDataInterface[];
}

export interface BrokersInitialState {
    loading: boolean;
    error: any;
    listLoading: boolean;
    listError: any;
    listRefresh: boolean;
    refresh: boolean;
    broker?: BrokerFullDataInterface;
    brokers: BrokersListInterface;
    brokersMinimal: MinimalBrokersFilterInterface;
}

export interface MinimalBrokersFilterInterface extends CommonListInterface {
    data: MinimalBrokersInterface[];
}

export interface MinimalBrokersInterface {
    id: string;
    cognitoId: string;
    user: {
        name: string;
    };
    organization: {
        id: string;
        name: string;
    };
    salesAgent: {
        cognitoId: string;
        user: {
            name: string;
        };
    };
}

export interface BrokerMoveInterface {
    id: number;
    user: {
        cognitoId: string;
        name: string;
    };
    newBroker?: {
        id: string;
        organization: {
            id: string;
            name: string;
        };
    };
    actor: {
        cognitoId: string;
        name: string;
    };
    oldBroker?: {
        id: string;
        organization: {
            id: string;
            name: string;
        };
    };
}

export interface QueryBrokersInterface extends CommonQueryInterface {
    name?: string;
    phone?: string;
    organizationId?: string;
    organizationName?: string;
    globalSearch?: string;
    tierId?: string;
    registered?: boolean;
    role?: OrganizationRole;
    isDeleted?: boolean;
    createdAtMin?: string;
    createdAtMax?: string;
}

export enum OrganizationRole {
    owner = 'Owner',
    manager = 'Manager',
    employee = 'Employee'
}

export interface QueryBrokersMinimalInterface {
    id?: string;
    cognitoId?: string;
    organizationId?: string;
    name?: string;
    isRegistered?: boolean;
    isDeleted?: boolean;
}
