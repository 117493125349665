import { IntlShape } from 'react-intl';
import * as yup from 'yup';

export const createContactInfoSchema = (intl: IntlShape, isRequired: boolean) =>
    yup.object().shape({
        title: isRequired ? yup.string().required(intl.formatMessage({ id: 'contact-info-title-required' })) : yup.string().nullable(),
        name: isRequired ? yup.string().required(intl.formatMessage({ id: 'contact-info-name-required' })) : yup.string().nullable(),
        phone: isRequired
            ? yup
                  .number()
                  .typeError(intl.formatMessage({ id: 'invalid-phone' }))
                  .required(intl.formatMessage({ id: 'phone-required' }))
            : yup.number().nullable(),
        email: isRequired ? yup.string().email(intl.formatMessage({ id: 'contact-info-email-format' })) : yup.string().nullable()
    });

export const createLocationInfoSchema = (intl: IntlShape, isRequired: boolean) =>
    yup.object().shape({
        type: isRequired ? yup.string().required(intl.formatMessage({ id: 'location-type-required' })) : yup.string().nullable(),
        area: isRequired ? yup.string().required(intl.formatMessage({ id: 'location-area-required' })) : yup.string().nullable(),
        city: isRequired ? yup.string().required(intl.formatMessage({ id: 'location-city-required' })) : yup.string().nullable(),
        country: isRequired ? yup.string().required(intl.formatMessage({ id: 'location-country-required' })) : yup.string().nullable(),
        address: isRequired
            ? yup.string().required(`${intl.formatMessage({ id: 'location-address' })} ${intl.formatMessage({ id: 'is-required' })}`)
            : yup.string().nullable()
    });
