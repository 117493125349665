// material-ui
import { createTheme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';

// assets
import theme1 from 'assets/scss/_theme1.module.scss';

// types
import { ColorProps } from 'types';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (navType: PaletteMode, presetColor: string) => {
    let colors: ColorProps;
    switch (presetColor) {
        case 'theme1':
            colors = theme1;
            break;
        default:
            colors = theme1;
    }

    return createTheme({
        palette: {
            mode: navType,
            common: {
                black: colors.darkPaper
            },
            primary: {
                100: colors.primary100,
                200: colors.primary200,
                300: colors.primary300,
                400: colors.primary400,
                500: colors.primary500,
                600: colors.primary600,
                700: colors.primary700,
                800: colors.primary800,
                light: colors.primaryLight,
                main: colors.primaryMain,
                dark: colors.primaryDark,
                contrastText: colors.paper
            },
            secondary: {
                100: colors.secondary100,
                200: colors.secondary200,
                300: colors.secondary300,
                400: colors.secondary400,
                500: colors.secondary500,
                600: colors.secondary600,
                700: colors.secondary700,
                800: colors.secondary800,
                light: colors.secondaryLight,
                main: colors.secondaryMain,
                dark: colors.secondaryDark,
                contrastText: colors.paper
            },
            orange: {
                100: colors.orange100,
                200: colors.orange200,
                300: colors.orange300,
                400: colors.orange400,
                500: colors.orange500,
                600: colors.orange600,
                700: colors.orange700,
                800: colors.orange800,
                light: colors.orangeLight,
                main: colors.orangeMain,
                dark: colors.orangeDark,
                contrastText: colors.paper
            },
            success: {
                100: colors.success100,
                300: colors.success300,
                500: colors.success500,
                600: colors.success600,
                light: colors.successLight,
                main: colors.successMain,
                dark: colors.successDark,
                contrastText: colors.paper
            },
            warning: {
                100: colors.warning100,
                300: colors.warning300,
                500: colors.warning500,
                600: colors.warning600,
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark,
                contrastText: colors.paper
            },
            error: {
                100: colors.error100,
                300: colors.error300,
                500: colors.error500,
                600: colors.error600,
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark,
                contrastText: colors.paper
            },
            grey: {
                50: colors.neutral50,
                100: colors.neutral100,
                200: colors.neutral200,
                300: colors.neutral300,
                400: colors.neutral400,
                500: colors.neutral500,
                600: colors.neutral600,
                700: colors.neutral700,
                800: colors.neutral800
            }
        }
    });
};

export default Palette;
