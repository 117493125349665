export type Finishing = 'not_finished' | 'semi_finished' | 'finished' | 'furnished';

export enum ImageType {
    propertyImage = 'images',
    floorPlan = 'floor_plans',
    coverImage = 'cover_image'
}
export interface QueryPropertiesListingInterface {
    developer?: string;
    compound?: string;
    broker?: string;
    organizationName?: string;
    status?: string;
    reviewerName?: string;
    lastUpdated?: Date;
    page?: number;
    pageSize?: number;
}

export interface QueryPropertiesListingResultInterface {
    id: number;
    brokerId: string;
    ownerName: string;
    ownerPhone: string;
    locationId: number;
    title: string | null;
    reviewerId: string;
    createdAt: Date;
    updatedAt: Date;
    broker: {
        user: {
            name: string;
        };
    };
    location: {
        area: {
            name: string;
        };
        compound: {
            developer: {
                name: string;
            };
            name: string;
        };
    };
    property: PropertyInterface | null;
    payment: PaymentInterface | null;
    listingStatus: [
        {
            status: ListingStatusEnum;
        }
    ];
}

export const enum ListingStatusEnum {
    Draft = 'Draft',
    Pending = 'Pending',
    Listed = 'Listed',
    Unlisted = 'Unlisted',
    Rejected = 'Rejected',
    Deleted = 'Deleted'
}

interface SectionStatus {
    id: number;
    isValid: boolean;
    status: 'Approved' | 'Rejected' | null;
    comment?: {
        id: number;
        reason: string;
        createdAt: string;
    };
    rejectionReasonId?: string;
}

export interface ImageInterface {
    id: number;
    listingId: number;
    sectionStatusId: null;
    createdAt: string;
    updatedAt: string;
    images: [
        {
            id: number;
            name: string;
            link: string;
            key: string;
            type: string;
            imagesSectionId: string;
            createdAt: string;
            updatedAt: string;
        }
    ];
    sectionStatus?: SectionStatus;
}

export interface SubmitImageInterface {
    listingId: number;
    name: string;
    type: ImageType;
    document: any;
    index?: number;
}

export interface PropertyListingInterface {
    id: number;
    brokerId: string;
    titleEn?: string;
    titleAr?: string;
    step: number;
    locationId: number;
    listingStatusId: number;
    broker: {
        id: string;
        cognitoId: string;
        user: {
            name: string;
        };
        organization: {
            id: string;
            name: string;
        };
    };
    location: LocationInterface;
    description: DescriptionInterface;
    payment: PaymentInterface;
    property: PropertyInterface;
    ownerName?: string;
    ownerPhone?: string;
    listingStatus: ListingStatusInterface;
    reviewer?: {
        cognitoId: number;
        user: {
            name: string;
        };
    };
    images: ImageInterface;
    editor?: string;
    createdAt: string;
    updatedAt: string;
}

export interface ListingStatusInterface {
    id: number;
    status: ListingStatusEnum;
    listingId: number;
    createdAt: Date;
    updatedAt: Date;
}

export interface SearchCompound {
    id: string;
    name: string;
    area_id: string;
    area_name: string;
    developer_id: string;
    developer_name: string;
    in_quick_search: string;
    category: string;
}

export interface Broker {
    cognitoId: string;
    id: string;
    user: {
        name: string;
    };
    organization: {
        id: string;
        name: string;
    };
}

export interface PropertiesListingInitialInterface {
    loading: boolean;
    searchLoading: boolean;
    error: any;
    deleteLoading: number;
    refresh: boolean;
    loadingManagers: boolean;
    errorManagers: any;
    uploadProgress: number[];
    currentUploading: number;
    inventoryManagers?: {
        data: InventoryManagersInterface[];
    };
    currentPropertyListing: PropertyListingInterface | null;
    compounds: SearchCompound[];
    brokers: Broker[];
    properties: {
        page: number;
        pageSize: number;
        pages: number;
        count: number;
        data: PropertyListingInterface[];
    };
}

export interface InventoryManagersInterface {
    cognitoId: string;
    user: {
        name: string;
    };
}

interface Area {
    id: number;
    name: string;
    developerId: number;
    createdAt: Date;
    updatedAt: Date;
    developer: {
        id: number;
        name: string;
    };
}
export interface Compound {
    id: number;
    name: string;
    developerId: number;
    createdAt: Date;
    updatedAt: Date;
    developer: {
        id: number;
        name: string;
        primaryCilConfig?: {
            renewalDeadlineInWeeks: number;
        };
    };
}

export interface SubmitLocationInterface {
    id?: number;
    phase: string;
    area: {
        id: number;
        name: string;
    };
    building?: string;
    floor?: number;
    compound: {
        id: number;
        name: string;
        developer: {
            id: number;
            name: string;
        };
    };
}

export interface UpdateListingLocationInterface extends SubmitLocationInterface {
    listingId: number;
}

export interface LocationInterface {
    id?: number;
    area: Area;
    compound: Compound;
    sectionStatus: SectionStatus;
    listingId?: number;
    phase: string;
    areaId: number;
    building: string;
    floor: number;
    compoundId: number;
}

export interface PropertyType {
    id: number;
    name: string;
}

export interface SubmitPropertyInterface {
    id?: number;
    listingId: number;
    developerUnitId: string;
    brokerUnitId: string;
    propertyType: PropertyType;
    bedrooms?: number;
    bathrooms?: number;
    builtUpArea: number;
    landArea?: number;
    gardenArea?: number;
    terraceArea?: number;
    finishing: Finishing;
    roofArea?: number;
    readyToMove: boolean;
    deliveryDate?: string;
    amenities?: {
        id: number;
        name: string;
    }[];
}

export interface PropertyInterface {
    id?: number;
    listingId: number;
    developerUnitId: string;
    brokerUnitId: string;
    propertyTypeId: number;
    propertyType: PropertyType;
    bedrooms: number;
    bathrooms: number;
    builtUpArea: number;
    landArea?: number;
    gardenArea?: number;
    terraceArea?: number;
    finishing: Finishing;
    roofArea?: number;
    readyToMove: boolean;
    deliveryDate: Date;
    amenities: {
        id: number;
        name: string;
    }[];
    sectionStatus: SectionStatus;
}

export interface InternalDetailsInterface {
    listingId: number;
    brokerId?: string;
    ownerName?: string;
    ownerPhone?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface PaymentInterface {
    id?: number;
    listingId: number;
    price: number;
    payed?: number;
    instalment?: number;
    maintenance?: number;
    comment?: string;
    years?: number;
    sectionStatus?: SectionStatus;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface DescriptionInterface {
    id?: number;
    listingId?: number;
    englishDescription: string;
    arabicDescription: string;
    sectionStatus?: SectionStatus;
    createdAt?: Date;
    updatedAt?: Date;
}

export enum ListingImageTypes {}
