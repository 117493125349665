import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import ACCOUNT_MANAGERS_TEAMS_APIS from './apis';
import { CreateAccountManagersTeamInterface, QueryAccountManagersTeamInterface, UpdateAccountManagersTeamInterface } from './interfaces';

export const queryAccountManagersTeams = createAsyncThunk(
    'accountManagersTeams/query',
    async (
        requestData: {
            queryData: QueryAccountManagersTeamInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ACCOUNT_MANAGERS_TEAMS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAccountManagerTeamById = createAsyncThunk(
    'accountManagersTeams/get-By-id',
    async (
        requestData: {
            teamId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { teamId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ACCOUNT_MANAGERS_TEAMS_APIS.getById(teamId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createAccountManagersTeam = createAsyncThunk(
    'accountManagersTeams/create',
    async (
        requestData: {
            createBody: CreateAccountManagersTeamInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createBody, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ACCOUNT_MANAGERS_TEAMS_APIS.create(), createBody);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateAccountManagersTeam = createAsyncThunk(
    'accountManagersTeams/update',
    async (
        requestData: {
            teamId: number;
            updateBody: UpdateAccountManagersTeamInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { teamId, updateBody, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ACCOUNT_MANAGERS_TEAMS_APIS.update(teamId), updateBody);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteAccountManagersTeam = createAsyncThunk(
    'accountManagersTeams/delete',
    async (
        requestData: {
            teamId: number;

            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { teamId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ACCOUNT_MANAGERS_TEAMS_APIS.delete(teamId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
