import { FLORA_API, WEBSITE_API, queryData } from 'store/apis';
import { QueryPropertiesListingInterface } from './interfaces';

const PROPERTY_LISTING_APIS = {
    query: (query: QueryPropertiesListingInterface) => {
        const formateQuery: string[] = [FLORA_API, 'listing', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    submit: (id: number) => `${FLORA_API}listing/${id}/submitListing`,
    updateTitle: (id: number) => `${FLORA_API}listing/${id}`,
    createListing: () => `${FLORA_API}listing`,
    getListingById: (id: string) => `${FLORA_API}listing/${id}`,
    createPayment: () => `${FLORA_API}payment`,
    updatePayment: (id: number) => `${FLORA_API}payment/${id}`,
    createProperty: () => `${FLORA_API}property`,
    updateProperty: (id: number) => `${FLORA_API}property/${id}`,
    createDescription: () => `${FLORA_API}description`,
    updateDescription: (id: number) => `${FLORA_API}description/${id}`,
    createLocation: () => `${FLORA_API}location`,
    updateLocation: (id: number) => `${FLORA_API}location/${id}`,
    createInternalDetails: () => `${FLORA_API}internal-details`,
    updateInternalDetails: (id: number) => `${FLORA_API}internal-details/${id}`,
    getCompoundDetails: (name: string) => `${WEBSITE_API}locations_partners?search_term=${name}&types=["compound"]&limit=10`,
    getBrokers: (name: string) => `${FLORA_API}brokers/get-by-name?name=${name}`,
    uploadPropertyListingImage: () => `${FLORA_API}images`,
    updatePropertyListingCoverImage: (id: number) => `${FLORA_API}images/${id}`,
    assignReviewer: (reviewerId: string) => `${FLORA_API}inventory-managers/${reviewerId}/assignReviewer`,
    queryInventoryManagers: () => `${FLORA_API}inventory-managers`,
    deletePropertyListingImage: (id: number) => `${FLORA_API}images/${id}`,
    reviewLocation: (id: number) => `${FLORA_API}location/${id}/review`,
    reviewDescription: (id: number) => `${FLORA_API}description/${id}/review`,
    reviewProperty: (id: number) => `${FLORA_API}property/${id}/review`,
    reviewPayment: (id: number) => `${FLORA_API}payment/${id}/review`,
    reviewImages: (sectionId: number) => `${FLORA_API}images/submitReview/${sectionId}`,
    updateStatus: (listingId: number) => `${FLORA_API}listing/${listingId}/update-status`,
    unlist: (listingId: number) => `${FLORA_API}listing/${listingId}/unList`,
    deleteListing: (listingId: number) => `${FLORA_API}listing/${listingId}/deleteListing`,
    submitFulReview: (listingId: number) => `${FLORA_API}listing/${listingId}/submitReview`
};

export default PROPERTY_LISTING_APIS;
