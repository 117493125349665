// hooks
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';

// material ui
import { Dialog, DialogTitle, IconButton, LinearProgress } from '@mui/material';

// action
import CloseIcon from '@mui/icons-material/Close';
import { setStepper } from 'store/slices/createSaleClaim';
// components
import BuyerInfoSection from './BuyerInfoSection';
import MainInfoSection from './MainInfoSection';
import PropertyInfoSection from './PropertyInfoSection';
import ReviewSection from './ReviewSection';

// interface
import { FormattedMessage, useIntl } from 'react-intl';
import { saleClaimDialogWidth } from 'store/constant';
import { StepperSectionsEnums } from 'store/slices/createSaleClaim/interfaces';
import { FlowInterface } from 'store/slices/flows/interfaces';
import BrokerSection from './BrokerSection';
import DocumentsSection from './DocumentsSection';
import useOverlay from 'hooks/useOverlay';
import useMixPanel from 'hooks/useMixPanel';

export interface CreateSaleClaimSectionProps {
    handleNext: () => void;
    handleBack?: () => void;
    handleClose?: (event?: any) => void;
    flow?: FlowInterface;
    disableNext?: boolean;
    limitOrganization?: string;
}

export interface CreateSaleClaimProps {
    open: boolean;
    onClose: () => void;
    flow?: FlowInterface;
    event?: { location: string };
    limitOrganization?: string;
}

const CreateSaleClaimDialog = ({ open, onClose, flow, limitOrganization }: Partial<CreateSaleClaimProps>) => {
    const intl = useIntl();

    const { emitEvent } = useMixPanel();
    const { stepper } = useSelector((state) => state.createSaleClaimSlice);
    const [progress, setProgress] = useState<number>(0);
    const totalSections = Object.keys(StepperSectionsEnums).length - 1;

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<CreateSaleClaimProps>('CreateSaleClaim');

    const handleStepper = (step: StepperSectionsEnums) => {
        dispatch(setStepper(step));
    };

    const handleDialogClose = (event: any, reason?: any) => {
        if (reason === 'backdropClick') {
            return;
        }
        setProgress(0);
        emitEvent('Click Cancel Sale Claim Creation');
        onClose?.();
        close();
    };

    useEffect(() => {
        const calculatedProgress = (Object.values(StepperSectionsEnums).indexOf(stepper) / totalSections) * 100;
        setProgress(calculatedProgress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stepper]);

    useEffect(() => {
        emitEvent('Click Add Sale Claim', { location: payload?.event?.location });
    }, [isCurrentOpened]);

    const handleWhichSection = () => {
        switch (stepper) {
            case StepperSectionsEnums.mainInfo:
                return (
                    <MainInfoSection
                        handleNext={() => handleStepper(StepperSectionsEnums.propertyInfo)}
                        handleClose={handleDialogClose}
                        flow={payload?.flow}
                    />
                );
            case StepperSectionsEnums.propertyInfo:
                return (
                    <PropertyInfoSection
                        handleNext={() => handleStepper(StepperSectionsEnums.buyerInfo)}
                        handleBack={() => handleStepper(StepperSectionsEnums.mainInfo)}
                        flow={payload?.flow}
                    />
                );
            case StepperSectionsEnums.buyerInfo:
                return (
                    <BuyerInfoSection
                        handleNext={() => handleStepper(StepperSectionsEnums.documentsSection)}
                        handleBack={() => handleStepper(StepperSectionsEnums.propertyInfo)}
                        flow={payload?.flow}
                    />
                );

            case StepperSectionsEnums.documentsSection:
                return (
                    <DocumentsSection
                        handleNext={() => handleStepper(StepperSectionsEnums.brokerSection)}
                        handleBack={() => handleStepper(StepperSectionsEnums.buyerInfo)}
                        flow={payload?.flow}
                    />
                );
            case StepperSectionsEnums.brokerSection:
                return (
                    <BrokerSection
                        handleNext={() => handleStepper(StepperSectionsEnums.review)}
                        handleBack={() => handleStepper(StepperSectionsEnums.documentsSection)}
                        limitOrganization={payload?.limitOrganization || limitOrganization}
                        flow={payload?.flow}
                    />
                );
            case StepperSectionsEnums.review:
                return (
                    <ReviewSection
                        flow={payload?.flow}
                        handleClose={handleDialogClose}
                        handleNext={() => {}}
                        handleBack={() => handleStepper(StepperSectionsEnums.brokerSection)}
                    />
                );
            default:
                return <>{intl.formatMessage({ id: 'unknown-step' })}</>;
        }
    };
    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: saleClaimDialogWidth
                }
            }}
            open={isCurrentOpened}
            onClose={handleDialogClose}
            scroll="paper"
            maxWidth="md"
            fullWidth
            key={flow?.id}
            aria-labelledby="create-sale-claim-flow"
            aria-describedby="create-sale-claim-flow"
        >
            <DialogTitle id="create-sale-claim-flow-dialog-title">
                <FormattedMessage id="create-sale-claim" />
                <IconButton size="small" onClick={handleDialogClose} color="inherit" style={{ float: 'right' }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <div>
                <LinearProgress variant="determinate" value={progress} style={{ margin: '0 25px', zIndex: 99 }} />
            </div>
            {handleWhichSection()}
        </Dialog>
    );
};

export default CreateSaleClaimDialog;
