import { Button, Grid, Menu, MenuItem, Pagination, useMediaQuery, useTheme } from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as appConfig from 'config';
import { InvitationTypeEnum } from 'store/interfaces';
import useAuth from 'hooks/useAuth';

export default function CustomPagination({
    page,
    setPage,
    setPageSize,
    pageSize,
    count
}: {
    page: number;
    setPage: (newPage: number) => void;
    setPageSize: (newPageSize: number) => void;
    pageSize: number;
    count: number;
}) {
    const { user } = useAuth();
    const intl = useIntl();
    const theme = useTheme();
    const [rowsPerPageOptions, setRowsPerPageOptions] = useState(appConfig.default.rowsPerPageOptions);
    useEffect(() => {
        if (user?.groups?.includes(InvitationTypeEnum.admins)) setRowsPerPageOptions(appConfig.default.rowsPerPageOptionsMax);
    }, [user]);
    const handleChangePageNew = (event: any | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangePageSizeNew = (event: any) => {
        setPageSize(parseInt(event.target.value, 10));
        handleClose();
    };

    const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container justifyContent="space-between" gap={gridSpacing} p={2} alignItems="center">
            <Grid item>
                <Pagination
                    boundaryCount={1}
                    shape="rounded"
                    count={Math.ceil(count / pageSize)}
                    page={page}
                    size={isMobile ? 'small' : 'medium'}
                    onChange={handleChangePageNew}
                    color="primary"
                />
            </Grid>
            <Grid item>
                <Button sx={{ color: theme.palette.grey[700] }} startIcon={<ExpandMoreRoundedIcon />} onClick={handleClick}>
                    {intl.locale === 'ar' ? count : pageSize} <FormattedMessage id="records-of" /> {intl.locale === 'ar' ? pageSize : count}
                </Button>
                <Menu
                    id="menu-user-list-style1"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    variant="selectedMenu"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    {rowsPerPageOptions.map((rows) => (
                        <MenuItem value={rows} key={rows} onClick={handleChangePageSizeNew}>
                            {rows}
                        </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </Grid>
    );
}
