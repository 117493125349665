import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import LEAD_REQUESTS_APIS from './apis';
import { INewLead, LeadRequestsListInterface, QueryLeadRequests } from './interfaces';

export const createLeadRequest = createAsyncThunk(
    'leads/create',
    async (
        requestData: {
            createData: INewLead;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(LEAD_REQUESTS_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryLeadRequests = createAsyncThunk(
    'leadRequests/query',
    async (
        requestData: {
            queryData: QueryLeadRequests;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<LeadRequestsListInterface | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(LEAD_REQUESTS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getLeadRequestById = createAsyncThunk(
    'leadRequests/getById',
    async (
        requestData: {
            leadRequestId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { leadRequestId, runSuccess } = requestData;

        try {
            const successRes = await axios.get(LEAD_REQUESTS_APIS.getById(leadRequestId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
