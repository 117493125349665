import { createSlice } from '@reduxjs/toolkit';
import {
    addUserToGroup,
    changeUserActivity,
    createUser,
    findOne,
    getInventoryManager,
    querySystemUsers,
    queryUsers,
    removeUserFromGroup,
    updateInventoryManagerRole,
    updateUser,
    updateUserLimits,
    verifyUserEmail
} from './actions';
import { UserInitialStateInterface } from './interfaces';

const initialState: UserInitialStateInterface = {
    loading: false,
    error: false,
    errorList: false,
    loadingList: false,
    refresh: false,
    loadingInventoryManager: false,
    errorInventoryManager: false,
    systemUsers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    loadingSystemUsers: false,
    errorSystemUsers: false,
    users: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

const users = createSlice({
    name: 'users',
    initialState,
    reducers: {
        refreshUser(state) {
            state.refresh = !state.refresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(queryUsers.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryUsers.fulfilled, (state, action) => {
                state.loadingList = false;
                state.users = action.payload;
            })
            .addCase(queryUsers.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(addUserToGroup.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(addUserToGroup.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(addUserToGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createUser.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createUser.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(removeUserFromGroup.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(removeUserFromGroup.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(removeUserFromGroup.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(changeUserActivity.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(changeUserActivity.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(changeUserActivity.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(findOne.pending, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(findOne.fulfilled, (state, action) => {
                state.loading = false;
                state.userDetails = action.payload;
            })
            .addCase(findOne.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateUserLimits.pending, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(updateUserLimits.fulfilled, (state, action) => {
                state.loading = false;
                state.userDetails = action.payload;
            })
            .addCase(updateUserLimits.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getInventoryManager.pending, (state) => {
                state.loadingInventoryManager = false;
                state.errorInventoryManager = false;
            })
            .addCase(getInventoryManager.fulfilled, (state, action) => {
                state.loadingInventoryManager = false;
                state.inventoryManager = action.payload;
            })
            .addCase(getInventoryManager.rejected, (state, action) => {
                state.loadingInventoryManager = false;
                state.errorInventoryManager = action.payload;
            })
            .addCase(updateInventoryManagerRole.pending, (state) => {
                state.errorInventoryManager = false;
            })
            .addCase(updateInventoryManagerRole.fulfilled, (state, action) => {
                state.inventoryManager = action.payload;
            })
            .addCase(updateInventoryManagerRole.rejected, (state, action) => {
                state.errorInventoryManager = action.payload;
            })
            .addCase(verifyUserEmail.pending, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(verifyUserEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.userDetails = action.payload;
            })
            .addCase(verifyUserEmail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(querySystemUsers.pending, (state) => {
                state.loadingSystemUsers = true;
                state.errorSystemUsers = false;
            })
            .addCase(querySystemUsers.fulfilled, (state, action) => {
                state.loadingSystemUsers = false;
                state.systemUsers = action.payload;
            })
            .addCase(querySystemUsers.rejected, (state, action) => {
                state.loadingSystemUsers = false;
                state.errorSystemUsers = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default users.reducer;

export const { refreshUser } = users.actions;
