// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import AxiosProvider from 'contexts/AxiosProvider';
import { MixPanelProvider } from 'contexts/MixpanelContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import OverlayProvider from 'ui-component/OverlayProvider';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <MixPanelProvider>
                            <AxiosProvider>
                                <>
                                    <Routes />
                                    <Snackbar />
                                    <OverlayProvider />
                                </>
                            </AxiosProvider>
                        </MixPanelProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);
// Yasmin was here
// Khattab was here
// A.Morsy was here
export default App;
