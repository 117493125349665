export interface CommonQueryInterface {
    page?: number;
    pageSize?: number;
    orderBy?: string;
    orderByDirection?: OrderByDirection;
    createdAtMax?: string;
    createdAtMin?: string;
    updatedAtMax?: string;
    updatedAtMin?: string;
}

export enum OrderByDirection {
    asc = 'asc',
    desc = 'desc'
}

export interface CommonListInterface {
    page: number;
    pageSize: number;
    count: number;
    pages: number;
    data: any[];
}

export interface CommonInitialState {
    loading: boolean;
    error: any;
    refresh: boolean;
    listLoading: boolean;
    listError: any;
    listRefresh: boolean;
}

export interface AnchorInterface {
    caption: string;
    location: string;
    image: any;
    title?: string;
}

export enum InvitationTypeEnum {
    /**
     * @deprecated
     */
    ambassadors = 'Ambassadors',
    admins = 'Admins',
    internalAgents = 'InternalAgents',
    // TODO Change Broker Group To Partner
    brokers = 'Brokers',
    accountManagers = 'AccountManagers',
    nawyInventory = 'NawyInventory',
    operationManager = 'OperationManagers',
    inventoryManagers = 'InventoryManagers',
    developerRelationsManagers = 'DeveloperRelationsManagers',
    freelancers = 'Freelancers',
    nawyApp = 'NawyApp'
}

export interface NameAndIdInterface {
    id?: string;
    name?: string;
}

export enum DepartmentRole {
    head = 'Head',
    supervisor = 'Supervisor',
    member = 'Member'
}
