import { Autocomplete, Button, Chip, FormControl, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { requestDelay } from 'store/constant';
import { UsersInterface } from 'store/slices/notifications/interfaces';
import { queryUsers } from 'store/slices/users/actions';

const DefaultUsers = ({ formik, edit }: { formik: FormikProps<any>; edit: boolean }) => {
    const intl = useIntl();
    const [selectedUser, setSelectedUser] = useState<UsersInterface | null>(null);
    const [searchUsers, setSearchUsers] = useState('');
    const { users, loading } = useSelector((state) => state.users);

    const getUsers = useRef(
        debounce(async (name: string) => {
            dispatch(queryUsers({ queryData: { name } }));
        }, requestDelay)
    ).current;

    useEffect(() => {
        setSelectedUser(null);
        setSearchUsers('');
    }, [edit]);

    const handleAddDefaultUser = () => {
        if (selectedUser) formik.setFieldValue('defaultUsers', [...formik.values.defaultUsers, selectedUser]);
        setSearchUsers('');
        setSelectedUser(null);
    };

    const handleRemoveDefaultUser = (variableIndex: number) => {
        const temp = [...formik.values.defaultUsers];
        temp.splice(variableIndex, 1);
        formik.setFieldValue('defaultUsers', [...temp]);
    };

    return (
        <>
            {edit ? (
                <FormControl fullWidth>
                    <Grid container spacing={2}>
                        <Grid item xs={12} container alignItems="center" spacing={2} justifyContent="flex-start">
                            <Grid item xs={10}>
                                <Autocomplete
                                    id="defaultUsers"
                                    options={users.data.filter(
                                        (x) => !formik.values.defaultUsers.map((y: UsersInterface) => y.cognitoId).includes(x.cognitoId)
                                    )}
                                    autoHighlight
                                    loading={loading}
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, newValue) => {
                                        if (newValue) setSelectedUser(newValue);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setSearchUsers(newInputValue);
                                        getUsers(newInputValue);
                                    }}
                                    value={selectedUser}
                                    inputValue={searchUsers}
                                    renderOption={(props, option) => (
                                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.cognitoId}>
                                            {option.name}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={intl.formatMessage({ id: 'default-users' })}
                                            name="defaultUsers"
                                            id="defaultUsers"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="outlined" onClick={handleAddDefaultUser}>
                                    <FormattedMessage id="add" />
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item container spacing={1}>
                            {formik.values.defaultUsers &&
                                formik.values.defaultUsers.map((user: UsersInterface, i: number) => (
                                    <Grid item key={user.cognitoId}>
                                        <Chip size="small" label={user.name} onDelete={() => handleRemoveDefaultUser(i)} />
                                    </Grid>
                                ))}
                        </Grid>
                    </Grid>
                </FormControl>
            ) : (
                <>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12}>
                            <Typography>
                                <FormattedMessage id="default-users" />:
                            </Typography>
                        </Grid>
                        <Grid item container spacing={1} xs={12}>
                            {(formik.values.defaultUsers &&
                                formik.values.defaultUsers.length > 0 &&
                                formik.values.defaultUsers.map((variable: UsersInterface) => (
                                    <Grid item key={variable.cognitoId}>
                                        <Chip size="small" label={variable.name} />
                                    </Grid>
                                ))) || (
                                <Grid item>
                                    <Typography>
                                        <FormattedMessage id="no-default-users" />
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default DefaultUsers;
