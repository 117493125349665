import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { dispatch, useSelector } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import { queryTiers } from 'store/slices/tiers/actions';
import { TierInterface } from 'store/slices/tiers/interfaces';
import RoleGuard from 'utils/RoleGuard';

export type TierType = 'broker a' | 'partners plus' | 'freelancer';

interface TiersFilterProps {
    defaultValueId?: string;
    value: string;
    handleSearch: (newValue: string) => void;
    handleSelectedTier?: (tier: TierInterface | null) => void;
    pick?: TierType[];
    defaultTier?: TierType;
    clear?: boolean;
}
const TierSearch = ({
    defaultValueId,
    value,
    handleSearch,
    handleSelectedTier,
    pick = [],
    defaultTier,
    clear = false
}: TiersFilterProps) => (
    <RoleGuard groups={[{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }]}>
        <Filter
            defaultValueId={defaultValueId}
            value={value}
            handleSearch={handleSearch}
            handleSelectedTier={handleSelectedTier}
            pick={pick}
            defaultTier={defaultTier}
            clear={clear}
        />
    </RoleGuard>
);
const Filter = ({ defaultValueId, value, handleSearch, handleSelectedTier, clear, pick = [], defaultTier }: TiersFilterProps) => {
    const intl = useIntl();

    // eslint-disable-next-line prefer-const
    const { tiers, listLoading } = useSelector((state) => state.tiers);

    const tiersData = pick.length > 0 ? tiers?.data.filter((tier) => pick.some((t) => t === tier.name)) : tiers?.data;
    const [selectedTier, setSelectedTier] = useState<TierInterface | null>(null);

    const [newValue, setNewValue] = useState<string>('');

    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(queryTiers({ queryData: { name } }));
        setNewValue(name);
    };

    useEffect(() => {
        dispatch(queryTiers({ queryData: { name: newValue } }));
    }, [newValue]);

    const handleOnChange = (tier: TierInterface | null) => {
        setSelectedTier(tier);
        handleSearch(tier?.id ?? '');
        if (handleSelectedTier) handleSelectedTier(tier);
    };

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if ((defaultTier || defaultValueId) && tiersData.length > 0) {
            firstRender.current = false;
            const tier = tiersData.find((t) => t.id === defaultValueId || t.name === defaultTier) ?? null;
            setSelectedTier(tier);
        }
    }, [defaultValueId, defaultTier, tiersData]);

    useEffect(() => {
        if (clear) {
            setNewValue('');
            setSelectedTier(null);
        }
    }, [clear]);

    return (
        <Autocomplete
            id="select-tier"
            size="small"
            fullWidth
            options={tiersData}
            loading={listLoading}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            value={selectedTier}
            onInputChange={(e, v) => handleInputChange(v)}
            isOptionEqualToValue={(option) => option.id === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="tierId"
                    label={intl.formatMessage({ id: 'tiers' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};

export default TierSearch;
