// assets
import { Alarm } from '@mui/icons-material';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import AttractionsIcon from '@mui/icons-material/Attractions';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
// ==============================|| Account Manager Side Menu Items ||============================== //

const CilsItem: NavItemType = {
    id: 'cils',
    type: 'item',
    url: '/cils',
    breadcrumbs: false,
    icon: Alarm,
    title: <FormattedMessage id="cils" />,
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'CILs'
    }
};

const listingItem: NavItemType = {
    id: 'listings',
    type: 'item',
    url: '/listings',
    breadcrumbs: false,
    icon: AddHomeOutlinedIcon,
    title: <FormattedMessage id="listings" />,
    allowedAudience: [
        { name: InvitationTypeEnum.inventoryManagers },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.brokers, organizationFeature: 'listingLimit' }
    ],
    mixpanelEvent: {
        tab_name: 'Listings'
    }
};

const leadsItem: NavItemType = {
    id: 'leads',
    title: (
        <>
            <FormattedMessage id="added" /> <FormattedMessage id="buyers" />
        </>
    ),
    type: 'item',
    url: '/leads',
    icon: ContactsTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.brokers, organizationFeature: 'leadsLimit' }],
    mixpanelEvent: {
        tab_name: 'Add Buyer'
    }
};

const commonItems = [
    // flowsItem,
    // primaryCilItem,
    CilsItem,
    //   reservationItem, saleClaimItem,
    listingItem
    // leadRequestItems
];

const featureItems: NavItemType = {
    id: 'features',
    type: 'group',
    breadcrumbs: false,
    children: [leadsItem, ...commonItems]
};

const featuresCollapse: NavItemType = {
    id: 'features-collapse-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Features'
    },
    children: [
        {
            id: 'features-collapse',
            type: 'collapse',
            title: <FormattedMessage id="features" />,
            icon: AttractionsIcon,
            breadcrumbs: false,
            children: commonItems
        }
    ]
};

export { featureItems };
export default featuresCollapse;
