// material ui
import { Grid, Typography } from '@mui/material';
import { ReactNode } from 'react';

// hooks
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import { useIntl } from 'react-intl';
import noResultsIcon from '../../assets/images/icons/no-results.svg';
import noDataLottie from '../../assets/lotties/not-found.json';
import { gridSpacingSmall } from 'store/constant';

const NoDataFound = ({ message, ActionItem, noLottie }: { message?: string; noLottie?: boolean; ActionItem?: ReactNode }) => {
    const intl = useIntl();
    const noDataMessage = message || intl.formatMessage({ id: 'no-data-found' });
    return (
        <Grid container alignItems="center" justifyContent="center">
            {noLottie ? (
                <>
                    <Grid item minHeight={100} justifyContent="center">
                        <Grid item container>
                            <NoDataFoundText message={noDataMessage} />
                        </Grid>
                        <Grid item>{ActionItem}</Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12}>
                        <Lottie animationData={noDataLottie} style={{ height: 200 }} />
                    </Grid>

                    <Grid item minHeight={100}>
                        <NoDataFoundText message={noDataMessage} />
                        <Grid item>{ActionItem}</Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export const NoDataFoundText = ({ message }: { message?: string }) => {
    const theme = useTheme();
    const intl = useIntl();
    const noDataMessage = message || intl.formatMessage({ id: 'no-data-found' });

    return (
        <Grid container spacing={gridSpacingSmall} alignItems="center">
            <Grid item>
                <img src={noResultsIcon} alt="No Results" width="24" />
            </Grid>
            <Grid item>
                <Typography gutterBottom color={theme.palette.grey[500]}>
                    {noDataMessage}
                </Typography>
            </Grid>
        </Grid>
    );
};
export default NoDataFound;
