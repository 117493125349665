// types
import { createSlice } from '@reduxjs/toolkit';
import {
    getListingForReview,
    queryRejectionReasons,
    submitDescriptionReview,
    submitFullReview,
    submitImagesReview,
    submitLocationReview,
    submitPaymentReview,
    submitPropertyReview,
    updateListingTitle
} from './actions';
import { InitialListingReviewState } from './interfaces';

// initial state
const initialState: InitialListingReviewState = {
    loading: false,
    error: false,
    loadingRejectionReasons: false,
    errorRejectionReasons: false
};

// ==============================|| SLICE - reviewListing ||============================== //

const reviewListing = createSlice({
    name: 'listing/review',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getListingForReview.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getListingForReview.fulfilled, (state, action) => {
                state.loading = false;
                state.listing = action.payload;
            })
            .addCase(getListingForReview.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateListingTitle.pending, (state) => {
                state.error = false;
            })
            .addCase(updateListingTitle.fulfilled, (state, action) => {
                state.listing = action.payload;
            })
            .addCase(updateListingTitle.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(queryRejectionReasons.pending, (state) => {
                state.loadingRejectionReasons = true;
                state.errorRejectionReasons = false;
            })
            .addCase(queryRejectionReasons.fulfilled, (state, action) => {
                state.loadingRejectionReasons = false;
                state.rejectionReasons = action.payload;
            })
            .addCase(queryRejectionReasons.rejected, (state, action) => {
                state.loadingRejectionReasons = false;
                state.errorRejectionReasons = action.payload;
            })
            .addCase(submitLocationReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitLocationReview.fulfilled, (state, action) => {
                if (state.listing) state.listing = { ...state.listing, location: action.payload };
            })
            .addCase(submitLocationReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(submitDescriptionReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitDescriptionReview.fulfilled, (state, action) => {
                if (state.listing) state.listing = { ...state.listing, description: action.payload };
            })
            .addCase(submitDescriptionReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(submitPropertyReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitPropertyReview.fulfilled, (state, action) => {
                if (state.listing) state.listing = { ...state.listing, property: action.payload };
            })
            .addCase(submitPropertyReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(submitPaymentReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitPaymentReview.fulfilled, (state, action) => {
                if (state.listing) state.listing = { ...state.listing, payment: action.payload };
            })
            .addCase(submitPaymentReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(submitImagesReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitImagesReview.fulfilled, (state, action) => {
                if (state.listing)
                    state.listing = {
                        ...state.listing,
                        images: {
                            ...state.listing.images,
                            sectionStatus: action.payload.sectionStatus
                        }
                    };
            })
            .addCase(submitImagesReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(submitFullReview.pending, (state) => {
                state.error = false;
            })
            .addCase(submitFullReview.fulfilled, (state, action) => {
                if (state.listing) state.listing = { ...state.listing, listingStatus: action.payload.listingStatus };
            })
            .addCase(submitFullReview.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default reviewListing.reducer;

// export const {} = reviewListing.actions;
