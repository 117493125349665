import { createSlice } from '@reduxjs/toolkit';
import { getAllAreas, getAllCompounds, getAmenities, getCategories, getDevelopers, getFilterOptions, getPropertyTypes } from './actions';
import { IAmenity, IGeneralInitialStateInterface, IPropertyType } from './interfaces';

// initial state
const initialState: IGeneralInitialStateInterface = {
    compounds: [],
    categories: [],
    areas: [],
    propertyTypes: [],
    amenities: [],
    developers: [],
    filterOptions: null,
    loading: false
};

// ==============================|| SLICE - GENERAL ||============================== //

const general = createSlice({
    name: 'general',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAllCompounds.fulfilled, (state, action) => {
                state.compounds = action.payload;
            })
            .addCase(getCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
            })
            .addCase(getAllAreas.fulfilled, (state, action) => {
                state.areas = action.payload;
            })
            .addCase(getPropertyTypes.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getPropertyTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.propertyTypes = action.payload.map((propertyType: IPropertyType) => ({
                    id: propertyType.id,
                    name: propertyType.name
                }));
            })
            .addCase(getPropertyTypes.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(getAmenities.fulfilled, (state, action) => {
                state.loading = false;
                state.amenities = action.payload.map((amenity: IAmenity) => ({
                    id: amenity.id,
                    name: amenity.name
                }));
            })
            .addCase(getFilterOptions.fulfilled, (state, action) => {
                state.filterOptions = action.payload;
            })
            .addCase(getDevelopers.fulfilled, (state, action) => {
                state.developers = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default general.reducer;
