import useMixPanel from 'hooks/useMixPanel';
import { FormattedMessage, useIntl } from 'react-intl';
import FabItemBtn from 'ui-component/FabItemBtn';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { Button } from '@mui/material';
import { useState } from 'react';
import RoleGuard from 'utils/RoleGuard';
import { InvitationTypeEnum } from 'store/interfaces';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import CreateOrUpdateCRMDialog from './CreateOrUpdateCRMDialog';
import EditIcon from '@mui/icons-material/Edit';

interface CreateOrUpdateCRMBtnProps {
    trigger?: (setState: React.Dispatch<React.SetStateAction<boolean>>, state: boolean) => void;
    fullWidth?: boolean;
    buttonVariant?: 'outlined' | 'contained';
    location?: string;
    variant?: 'fab' | 'btn';
    crm?: CRMControlInterface;
}
// eslint-disable-next-line import/prefer-default-export
export function CreateOrUpdateCRMBtn({ trigger, fullWidth, buttonVariant, location, variant = 'btn', crm }: CreateOrUpdateCRMBtnProps) {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        emitEvent('Click CRM Action', { location });
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
            {trigger ? (
                trigger(setOpen, open)
            ) : variant === 'btn' ? (
                crm ? (
                    <Button startIcon={<EditIcon />} color="inherit" onClick={handleOpen}>
                        <FormattedMessage id="edit" />
                    </Button>
                ) : (
                    <Button fullWidth={fullWidth} variant={buttonVariant ?? 'outlined'} onClick={handleOpen}>
                        <FormattedMessage id="add-crm" />
                    </Button>
                )
            ) : (
                <FabItemBtn
                    title={crm ? intl.formatMessage({ id: 'edit' }) : intl.formatMessage({ id: 'add-crm' })}
                    icon={<AddCircleTwoToneIcon />}
                    onClick={handleOpen}
                />
            )}
            <CreateOrUpdateCRMDialog
                crm={crm}
                open={open}
                handleClose={() => {
                    emitEvent('Click Cancel CRM Action');
                    handleClose();
                }}
            />
        </RoleGuard>
    );
}
