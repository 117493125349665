import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Menu, MenuItem, Switch, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { createCRM, queryCRMs, updateCRM } from 'store/slices/crmControl/actions';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import Chip from 'ui-component/extended/Chip';
import * as Yup from 'yup';

export interface CreateOrUpdateCRMDialogProps {
    crm?: CRMControlInterface; // in case of edit
    open: boolean;
    handleClose: () => void;
}

const CreateOrUpdateCRMDialog = ({ crm, open, handleClose }: CreateOrUpdateCRMDialogProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.crmControl);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleStatusChange = (value: boolean) => {
        formik.setFieldValue('disabled', value);
        setAnchorEl(null);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'crm-name-is-required' })),
        api: Yup.string().required(intl.formatMessage({ id: 'api-is-required' })),
        apiKey: Yup.string().required(intl.formatMessage({ id: 'api-key-is-required' })),
        defaultUserId: Yup.string().required(intl.formatMessage({ id: 'default-user-id-is-required' })),
        url: Yup.string().required(intl.formatMessage({ id: 'url-is-required' }))
    });

    const runSuccess = (crmId?: number) => {
        formik.setSubmitting(false);
        const id = crm?.id ? 'crm-updated-successfully' : 'crm-created-successfully';
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        dispatch(queryCRMs({ query: {} }));
        handleClose();
        // TODO: add navigation
        // navigate(`/crms/${crmId}`, { replace: false });
    };

    const formik = useFormik({
        initialValues: {
            name: crm?.name ?? '',
            api: crm?.api ?? '',
            apiKey: crm?.apiKey ?? '',
            defaultUserId: crm?.defaultUserId ?? '',
            url: crm?.url ?? '',
            viewInventory: crm?.viewInventory ?? false,
            disabled: crm?.disabled ?? false
        },
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            crm?.id
                ? dispatch(
                      updateCRM({
                          crmId: crm.id,
                          updateData: {
                              api: values.api,
                              apiKey: values.apiKey,
                              defaultUserId: values.defaultUserId,
                              disabled: values.disabled,
                              viewInventory: values.viewInventory,
                              name: values.name,
                              url: values.url
                          },
                          runSuccess
                      })
                  )
                : dispatch(
                      createCRM({
                          data: {
                              api: values.api,
                              apiKey: values.apiKey,
                              defaultUserId: values.defaultUserId,
                              viewInventory: values.viewInventory,
                              name: values.name,
                              url: values.url
                          },
                          runSuccess
                      })
                  );
        },
        validationSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth aria-labelledby="Create-CRM">
            <DialogTitle>{crm?.id ? <FormattedMessage id="edit-crm" /> : <FormattedMessage id="create-crm" />}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={gridSpacing} justifyContent="space-between">
                    <Grid item xs={crm?.id ? 6 : 12}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'crm-name' })} `}
                            name="name"
                            id="set-crm-property-crm-name"
                            value={formik.values.name || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    {!!crm?.id && (
                        <Grid container item xs={6}>
                            <Grid item xs={12}>
                                <FormattedMessage id="status" />
                            </Grid>
                            <Grid item xs={12}>
                                <Chip
                                    size="medium"
                                    variant="filled"
                                    chipcolor={formik.values.disabled ? 'error' : 'success'}
                                    avatar={<ExpandMoreIcon />}
                                    label={
                                        formik.values.disabled
                                            ? intl.formatMessage({ id: 'deactivated' })
                                            : intl.formatMessage({ id: 'active' })
                                    }
                                    onClick={handleClick}
                                />
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => {
                                        setAnchorEl(null);
                                    }}
                                >
                                    <MenuItem key="active" onClick={() => handleStatusChange(false)}>
                                        <FormattedMessage id="active" />
                                    </MenuItem>
                                    <MenuItem key="deactivated" onClick={() => handleStatusChange(true)}>
                                        <FormattedMessage id="deactivated" />
                                    </MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'api' })} `}
                            name="api"
                            id="set-crm-property-api"
                            value={formik.values.api || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.api && formik.errors.api)}
                            helperText={formik.touched.api && formik.errors.api}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'api-key' })} `}
                            name="apiKey"
                            id="set-crm-property-api-key"
                            value={formik.values.apiKey || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.apiKey && formik.errors.apiKey)}
                            helperText={formik.touched.apiKey && formik.errors.apiKey}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'url' })} `}
                            name="url"
                            id="set-crm-property-url"
                            value={formik.values.url || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.url && formik.errors.url)}
                            helperText={formik.touched.url && formik.errors.url}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'leads-default-id' })} `}
                            name="defaultUserId"
                            id="set-crm-property-leads-default-id"
                            value={formik.values.defaultUserId || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.defaultUserId && formik.errors.defaultUserId)}
                            helperText={formik.touched.defaultUserId && formik.errors.defaultUserId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormattedMessage id="view-inventory" />
                        <Switch
                            name="viewInventory"
                            size="medium"
                            id="set-crm-property-view-inventory"
                            onChange={formik.handleChange}
                            checked={formik.values.viewInventory}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button disabled={formik.isSubmitting} onClick={handleClose} color="error">
                    <FormattedMessage id="cancel" />
                </Button>
                <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                    {crm?.id ? <FormattedMessage id="save" /> : <FormattedMessage id="add-crm" />}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
export default CreateOrUpdateCRMDialog;
