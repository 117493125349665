import { UserProfile } from 'types/user-profile';
import { RoleGuardGroupProps } from '.';

const isOrganizationFeatureAllowed = (user: UserProfile | undefined | null, userGroup: RoleGuardGroupProps | undefined): boolean => {
    const organizationFeature = userGroup?.organizationFeature;

    if (!organizationFeature) return true;

    const brokerAuthOrganization = user?.brokerAuth?.organization[organizationFeature];

    const isOrganizationFeatureArrayEmpty = Array.isArray(brokerAuthOrganization) && brokerAuthOrganization.length === 0;
    const isOrganizationFeatureFalsy = !brokerAuthOrganization;

    return !isOrganizationFeatureArrayEmpty && !isOrganizationFeatureFalsy;
};

export default isOrganizationFeatureAllowed;
