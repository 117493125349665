import { TopCount } from 'store/slices/partnersDashboard/interfaces';
import { Props } from 'react-apexcharts';

const donutConfig = (data: TopCount[], label: string, colors: string[]): Props => {
    const labels: string[] = [];
    const series: number[] = [];

    data.forEach((targetData) => {
        labels.push(targetData.name);
        series.push(targetData.totalCount);
    });

    return {
        height: 400,
        type: 'donut',
        options: {
            colors,
            chart: {
                id: label
            },
            dataLabels: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                label,
                                showAlways: true,
                                show: true,

                                fontFamily: 'Poppins',
                                fontSize: '12px'
                            }
                        }
                    }
                }
            },
            labels,
            legend: {
                show: true,
                formatter: (val: string, opts: any) => (val.length > 10 ? `${val.substring(0, 10)}...` : val),
                position: 'bottom',
                fontFamily: 'Poppins',
                itemMargin: {
                    horizontal: 10,
                    vertical: 10
                },
                offsetX: 0,
                offsetY: 0,
                containerMargin: { left: 0, top: 0 },
                markers: {
                    offsetX: 0
                }
            }
        },
        series
    };
};

export default donutConfig;
