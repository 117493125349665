import { TableBody, TableCell } from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import { Link } from 'react-router-dom';

import { useIntl } from 'react-intl';
import { useSelector } from 'store';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import StyledTableRow from 'ui-component/Tables/Rows/StyledTableRow';
import UpdateCRMDisabledStatus from 'components/CRMControl/Actions/UpdateCRMDisabledStatus';
import { CreateOrUpdateCRMBtn } from 'components/CRMControl/Actions/CreateOrUpdateCRMBtn';
import { format } from 'date-fns';

const CRMControlTableBody = () => {
    const { crms } = useSelector((state) => state.crmControl);

    return (
        <TableBody>
            {crms.data.map((crm) => (
                <CRMRow crm={crm} key={crm.id} />
            ))}
        </TableBody>
    );
};

const CRMRow = ({ crm }: { crm: CRMControlInterface }) => {
    const intl = useIntl();

    return (
        <StyledTableRow key={crm.id}>
            <TableCell align="center">{crm.id}</TableCell>
            <TableCell align="left">
                <span>
                    <Link to={`/crms/${crm.id}`}>{crm.name}</Link>
                </span>
            </TableCell>
            <TableCell align="center">{crm._count.salesAgents}</TableCell>
            <TableCell align="center">
                <UpdateCRMDisabledStatus crm={crm} option="viewInventory" />
            </TableCell>
            <TableCell align="left">{format(new Date(crm.createdAt), 'dd-MM-yyyy')}</TableCell>
            <TableCell align="left">{crm.createdBy.name}</TableCell>
            <TableCell align="center">
                <Chip
                    label={crm.disabled ? intl.formatMessage({ id: 'deactivated' }) : intl.formatMessage({ id: 'active' })}
                    size="medium"
                    variant="filled"
                    style={{ width: '100%' }}
                    chipcolor={crm.disabled ? 'error' : 'success'}
                />
            </TableCell>
            <TableCell align="right">
                <CreateOrUpdateCRMBtn crm={crm} />
            </TableCell>
        </StyledTableRow>
    );
};

export default CRMControlTableBody;
