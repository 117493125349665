import { createSlice } from '@reduxjs/toolkit';
import { NotificationTypesInitialState } from './interfaces';
import {
    createNotificationType,
    createTypeConfig,
    deleteNotificationType,
    deleteTypeConfig,
    getNotificationTypeById,
    queryNotificationTypes,
    updateNotificationType,
    updateTypeConfig
} from './actions';

// initial state
const initialState: NotificationTypesInitialState = {
    loading: false,
    error: false,
    loadingList: false,
    errorList: false,
    refresh: false,
    notificationTypes: {
        page: undefined,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    users: [],
    usersLoading: false,
    usersError: false
};

// ==============================|| SLICE - ORGANIZATIONS ||============================== //

const notificationTypes = createSlice({
    name: 'notificationTypes',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryNotificationTypes.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryNotificationTypes.fulfilled, (state, action) => {
                state.loadingList = false;
                state.notificationTypes = action.payload;
            })
            .addCase(queryNotificationTypes.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(createNotificationType.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.notificationType = undefined;
            })
            .addCase(createNotificationType.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(createNotificationType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getNotificationTypeById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getNotificationTypeById.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationType = action.payload;
            })
            .addCase(getNotificationTypeById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateNotificationType.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateNotificationType.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationType = action.payload;
            })
            .addCase(updateNotificationType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteNotificationType.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteNotificationType.fulfilled, (state) => {
                state.loading = false;
                state.notificationType = undefined;
            })
            .addCase(deleteNotificationType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createTypeConfig.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createTypeConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationType = action.payload;
            })
            .addCase(createTypeConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateTypeConfig.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateTypeConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationType = action.payload;
            })
            .addCase(updateTypeConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteTypeConfig.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteTypeConfig.fulfilled, (state, action) => {
                state.loading = false;
                state.notificationType = action.payload;
            })
            .addCase(deleteTypeConfig.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default notificationTypes.reducer;
