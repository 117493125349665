import { WEBSITE_API, SIRENS_API } from 'store/apis';

const GENERAL_APIS = {
    getCompounds: (developerId?: string, language?: string) =>
        `${WEBSITE_API}compounds${developerId ? `?developer_id=${developerId}` : ''}${language ? `?language=${language}` : ''}`,
    getCategories: () => `${SIRENS_API}categories`,
    // getCategories: () => `${API}categories`,
    getPropertyTypes: (language?: string) => `${WEBSITE_API}property_types${language ? `?language=${language}` : ''}`,
    getAmenities: (language?: string) => `${WEBSITE_API}amenities${language ? `?language=${language}` : ''}`,
    getDevelopers: (language?: string) => `${WEBSITE_API}developers${language ? `?language=${language}` : ''}`,
    getAreas: (language?: string) => `${WEBSITE_API}areas${language ? `?language=${language}` : ''}`,
    getFilterOptions: (language?: string) => `${WEBSITE_API}properties/get_filter_options${language ? `?language=${language}` : ''}`
};

export default GENERAL_APIS;
