import { debounce } from 'lodash';
import { useState, useEffect } from 'react';

function useDebouncedValue<T>(value: T, delay = 300) {
    const [debouncedValue, setDebouncedValue] = useState<T>(value);

    useEffect(() => {
        const debouncedHandler = debounce((newValue: T) => {
            setDebouncedValue(newValue);
        }, delay);

        debouncedHandler(value);

        return () => {
            debouncedHandler.cancel();
        };
    }, [value, delay]);

    return debouncedValue;
}

export default useDebouncedValue;
