import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const SalesPage = Loadable(lazy(() => import('views/sales')));

// ==============================|| Sales Allowed Audience ||============================== //

// const allowedGroups = [
//     InvitationTypeEnum.brokers,
//     InvitationTypeEnum.accountManagers,
//     InvitationTypeEnum.operationManager,
//     InvitationTypeEnum.internalAgents
// ];

// ==============================|| Sales ROUTING ||============================== //

const SalesRoutes = {
    path: '/sales',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.brokers },
                    { name: InvitationTypeEnum.accountManagers },
                    { name: InvitationTypeEnum.operationManager },
                    { name: InvitationTypeEnum.internalAgents },
                    { name: InvitationTypeEnum.admins }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <SalesPage />
        },
        {
            path: ':tab',
            element: <SalesPage />
        }
    ]
};
export default SalesRoutes;
