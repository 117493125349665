import { Grid } from '@mui/material';
import { gridSpacing } from 'store/constant';
import PageHeader from 'ui-component/PageHeader';
import SystemUsersListSection from 'components/SystemUsers/UI';

const SystemUsersPage = () => (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <PageHeader title="system-users" />
        </Grid>
        <Grid item xs={12}>
            <SystemUsersListSection />
        </Grid>
    </Grid>
);

export default SystemUsersPage;
