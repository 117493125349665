// project imports
import CRMGuard from 'utils/route-guard/CRMGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NawyInventoryPage from 'views/common/NawyInventory';

// Pages

// ==============================|| CRM ROUTING ||============================== //

const CRMRoutes = {
    path: '/',
    element: (
        <CRMGuard>
            <MinimalLayout />
        </CRMGuard>
    ),
    children: [
        {
            path: '/crm/:token/inventory/:organization',
            element: <NawyInventoryPage />
        }
    ]
};

export default CRMRoutes;
