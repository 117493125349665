import LoadingButton from '@mui/lab/LoadingButton';
import { AppBar, Toolbar, Typography, Grid, Dialog, DialogContent, useTheme, Button, useMediaQuery, Container } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AnimateButton from 'ui-component/extended/AnimateButton';
import logo from 'assets/images/logo.svg';
import { Globe } from 'lucide-react';
import useConfig from 'hooks/useConfig';
import useMixPanel from 'hooks/useMixPanel';
import LoginRightPanelV2 from 'components/Auth/UI/LoginRightPanelV2';
import useAuth from 'hooks/useAuth';

export interface LandingPageHeaderProps {
    brandLogo?: string;
    hideLogin?: boolean;
    hideLanguageSwitcher?: boolean;
}

function LandingHeader({ brandLogo, hideLogin = false, hideLanguageSwitcher = false }: LandingPageHeaderProps) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { isLoggedIn } = useAuth();

    const initialValues = {
        submit: null
    };
    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            formikHelpers.setErrors({ submit: '' });
        }
    });

    const handleLoginDialog = () => {
        formik.resetForm();
        setOpen(!open);
        emitEvent('Click Login');
    };

    const { locale, onChangeLocale } = useConfig();
    const { emitEvent } = useMixPanel();

    const anchorRef = useRef<any>(null);
    const [language, setLanguage] = useState<string>(locale);

    const handleClick = (lng: string) => {
        emitEvent('Click on language switch landing page and become partner page', { lng });
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
        // window.location.reload();
    };

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    return (
        <AppBar style={{ backgroundColor: 'white', captionSide: 'top', position: 'sticky', top: 0 }}>
            <Container maxWidth={false} sx={{ maxWidth: '1300px', width: '100%', margin: '0 auto' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, paddingLeft: '2%' }}>
                        <img src={brandLogo || logo} style={{ width: '96px' }} alt="logo" />
                    </Typography>

                    {!hideLanguageSwitcher ? (
                        language === 'en' ? (
                            <Button ref={anchorRef} onClick={() => handleClick('ar')} sx={{ marginLeft: '10px', marginRight: '10px' }}>
                                <Globe color={theme.palette.primary.main} />
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    paddingLeft={1}
                                    fontSize="16px"
                                    fontFamily={theme.typography.fontFamily}
                                >
                                    {isSmallScreen ? 'ع' : 'عربي'}
                                </Typography>
                            </Button>
                        ) : (
                            <Button ref={anchorRef} onClick={() => handleClick('en')} sx={{ marginLeft: '10px', marginRight: '10px' }}>
                                <Globe color={theme.palette.primary.main} />
                                <Typography
                                    variant="h5"
                                    color="inherit"
                                    paddingLeft={1}
                                    fontSize="16px"
                                    fontFamily={theme.typography.fontFamily}
                                >
                                    {isSmallScreen ? 'EN' : 'English'}
                                </Typography>
                            </Button>
                        )
                    ) : null}

                    {!isLoggedIn && !hideLogin && (
                        <Grid paddingRight="2%">
                            <AnimateButton>
                                <LoadingButton
                                    disableElevation
                                    loading={formik.isSubmitting}
                                    onClick={handleLoginDialog}
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        borderRadius: '8px',
                                        width: isSmallScreen ? '120px' : '192px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <FormattedMessage id="login" />
                                </LoadingButton>
                            </AnimateButton>
                        </Grid>
                    )}

                    <Dialog open={open} onClose={handleLoginDialog}>
                        <DialogContent
                            style={{
                                maxWidth: '400px',
                                maxHeight: '360px',
                                paddingRight: '32px',
                                paddingLeft: '32px',
                                paddingTop: '20px',
                                paddingBottom: '20px'
                            }}
                        >
                            <LoginRightPanelV2 />
                        </DialogContent>
                    </Dialog>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default LandingHeader;
