import MinimalLayout from 'layout/MinimalLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';

const FreelancersTermsAndConditionsPage = Loadable(lazy(() => import('views/become-freelancer/terms-and-conditions')));

// ==============================|| Banners Routes GUARD ROUTING ||============================== //

const FreelancersTermsAndConditionsRoutes = {
    path: '/freelancer-terms-and-conditions',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '',
            element: <FreelancersTermsAndConditionsPage />
        }
    ]
};

export default FreelancersTermsAndConditionsRoutes;
