import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';
import RevenueChartCard from '../Charts/Dount';

import MainCard from 'ui-component/cards/MainCard';
import { useSelector } from 'store';

import donutConfig from '../Charts/Dount/config';

const CilsDashboardSection = () => {
    const theme = useTheme();
    const intl = useIntl();

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    if (isMediumScreen)
        return (
            <>
                <Typography variant="h4">{intl.formatMessage({ id: 'cils' })}</Typography>
                <CilsDashboardSectionContent />
                <Divider />
            </>
        );
    return (
        <MainCard title={intl.formatMessage({ id: 'cils' })} content={false}>
            <CilsDashboardSectionContent />
        </MainCard>
    );
};

const CilsDashboardSectionContent = () => {
    const intl = useIntl();
    const theme = useTheme();
    const colors = [
        theme.palette.primary[800],
        theme.palette.primary.dark,
        theme.palette.primary.main,
        theme.palette.primary[200],
        theme.palette.primary.light
    ];
    const { primaryCilsTopDevelopers, financingCilsTopPartners } = useSelector((state) => state.partnersDashboard);

    return (
        <Grid container>
            <Grid item xs={12} md={6}>
                <RevenueChartCard chartData={donutConfig(primaryCilsTopDevelopers, intl.formatMessage({ id: 'primary-cils' }), colors)} />
            </Grid>
            <Grid item xs={12} md={6}>
                <RevenueChartCard chartData={donutConfig(financingCilsTopPartners, intl.formatMessage({ id: 'financing-cils' }), colors)} />
            </Grid>
        </Grid>
    );
};

export default CilsDashboardSection;
