import { createSlice } from '@reduxjs/toolkit';
import {
    analyticaPrimaryCils,
    createPrimaryCils,
    getPrimaryCilById,
    queryPrimaryCils,
    renewPrimaryCil,
    updatePrimaryCilStatus
} from './actions';
import { PrimaryCilsInitialStateInterface } from './interfaces';

// initial state
const initialState: PrimaryCilsInitialStateInterface = {
    loading: false,
    error: false,
    refresh: false,
    listLoading: false,
    listError: false,
    primaryCils: {
        page: 1,
        pages: 1,
        pageSize: 10,
        count: 0,
        data: []
    },
    analyticaLoading: false,
    analyticaError: false,
    analytica: {
        accepted: 0,
        pending: 0,
        rejected: 0,
        total: 0
    }
};

// ==============================|| SLICE - primaryCils ||============================== //

const primaryCils = createSlice({
    name: 'primaryCils',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryPrimaryCils.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryPrimaryCils.fulfilled, (state, action) => {
                state.listLoading = false;
                state.primaryCils = action.payload;
            })
            .addCase(queryPrimaryCils.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(analyticaPrimaryCils.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(analyticaPrimaryCils.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analytica = action.payload;
            })
            .addCase(analyticaPrimaryCils.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addCase(createPrimaryCils.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(createPrimaryCils.fulfilled, (state, action) => {
                state.primaryCil = action.payload.primaryCil;
                state.refresh = !state.refresh;
                state.loading = false;
            })
            .addCase(createPrimaryCils.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(renewPrimaryCil.pending, (state) => {
                state.error = false;
            })
            .addCase(renewPrimaryCil.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(renewPrimaryCil.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addCase(updatePrimaryCilStatus.pending, (state) => {
                state.error = false;
            })
            .addCase(updatePrimaryCilStatus.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updatePrimaryCilStatus.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getPrimaryCilById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getPrimaryCilById.fulfilled, (state, action) => {
                state.loading = false;
                state.primaryCil = action.payload.primaryCil;
            })
            .addCase(getPrimaryCilById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default primaryCils.reducer;
