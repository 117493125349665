import { createAsyncThunk } from '@reduxjs/toolkit';

import axiosServices from 'utils/axios';
import SALES_AGENTS_APIS from './apis';
import { MoveSalesAgent, QuerySalesAgents, SalesAgentsListInterface } from './interfaces';

export const querySalesAgents = createAsyncThunk(
    'salesAgent/query',
    async (
        requestData: {
            queryData: QuerySalesAgents;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<SalesAgentsListInterface | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(SALES_AGENTS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const moveSalesAgent = createAsyncThunk(
    'salesAgent/move',
    async (
        requestData: {
            data: MoveSalesAgent;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<SalesAgentsListInterface | any> => {
        const { data, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(SALES_AGENTS_APIS.move(), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
