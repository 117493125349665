import { CommonInitialState, CommonListInterface, CommonQueryInterface } from 'store/interfaces';
import { CommissionTypeEnum } from '../organizations/interfaces';
import { PrimaryCilInterface } from '../primaryCils/interfaces';
import { ReservationInterface } from '../reservations/interfaces';
import { UserInterface } from '../users/interfaces';
import { Compound } from '../developers/interfaces';
import { SaleInterface } from '../sales/interfaces';

export enum SaleClaimDocumentTypes {
    NationalId = 'NationalId',
    ReservationForm = 'ReservationForm',
    BankTransfer = 'BankTransfer',
    DeveloperContract = 'DeveloperContract'
}
interface SaleClaimCommissionTypeInterface {
    id: string;
    type: CommissionTypeEnum;
    updatedBy?: UserInterface;
    createdAt: string;
    updatedAt: string;
}

export interface SaleClaimsInitialState extends CommonInitialState {
    updateSaleClaimLoading: boolean;
    updateSaleClaimBuyerLoading: boolean;
    updateSaleClaimPropertyLoading: boolean;
    updateSaleClaimStatusLoading: boolean;
    uploadSaleClaimDocumentLoading: boolean;
    deleteSaleClaimDocumentLoading: boolean;
    updateSaleCommissionTypeLoading: boolean;
    analyticsLoading: boolean;
    analyticsError: any;
    analytica: SaleClaimsAnalytica;
    createCommentLoading: boolean;
    updateCommentLoading: boolean;
    deleteCommentLoading: boolean;
    refreshSaleClaimComments: boolean;
    commissionType?: SaleClaimCommissionTypeInterface;
    saleClaims: SaleClaimsListInterface;
    saleClaim?: SaleClaimInterface;
    comments?: SaleClaimCommentInterface[];
    loadingBuyers: boolean;
    buyers: BuyersListInterface;
    sale?: SaleInterface;
}

export interface SaleClaimsListInterface extends CommonListInterface {
    data: SaleClaimInterface[];
}

export interface SaleClaimInterface {
    id: string;
    contractionDate: string;
    status: SaleClaimStatus;
    type: SaleType;
    downPayment: number;
    downPaymentDate?: string;
    reservationDate?: string;
    isDeleted?: string;
    installmentsYears?: number;
    createdAt: string;
    updatedAt: string;
    organizationId: string;
    commissionPercentage?: number;
    reviewerId?: string;
    brokerId?: string;
    reservation?: ReservationInterface;
    primaryCilId?: string;
    primaryCil?: PrimaryCilInterface;
    commissionType?: SaleClaimCommissionTypeInterface;
    buyer: SaleClaimBuyerInterface;
    organization?: { id: string; name: string; tierId: string };
    reviewer?: { user: { id: string; name: string } };
    broker?: { user: { id: string; name: string } };
    documents?: SaleClaimDocument[];
    _count?: { comments?: number; documents?: number };
    compound?: Compound;
    propertyType?: PlatformPropertyType;
    unitValue?: number;
    unitId?: string;
}
export interface SaleClaimBuyerInterface {
    id: string;
    name: string;
    phone: string;
    saleClaimId: string;
    createdAt: string;
    updatedAt: string;
}
export interface PlatformPropertyType {
    id: number;
    name: string;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
}
export interface BuyersListInterface extends CommonListInterface {
    data: SaleClaimBuyerInterface[];
}

export interface SaleClaimDocument {
    id: string;
    name: string;
    link: string;
    key: string;
    type: SaleClaimDocumentTypes;
    createdAt: string;
    updatedAt: string;
    saleClaimId: string;
}

export enum SaleClaimStatus {
    pending = 'Pending',
    approved = 'Approved',
    preApproved = 'PreApproved',
    rejected = 'Rejected'
}

export enum SaleType {
    financing = 'Financing',
    resale = 'Resale',
    primary = 'Primary'
}

export interface QuerySaleClaimsInterface extends CommonQueryInterface {
    contractionDateMax?: string;
    contractionDateMin?: string;
    status?: string;
    commissionType?: string;
    saleType?: SaleType;
    downPaymentMin?: number;
    downPaymentMax?: number;
    installmentsYearsMin?: number;
    installmentsYearsMax?: number;
    commissionPercentageMin?: number;
    commissionPercentageMax?: number;
    unitValueMin?: number;
    unitValueMax?: number;
    reviewerId?: string;
    reviewed?: boolean;
    accountManagerId?: string;
    brokerId?: string;
    creatorId?: string;
    buyerName?: string;
    buyerPhone?: string;
    compoundId?: number;
    developerId?: number;
    unitId?: string;
    type?: SaleType;
    organizationName?: string;
    brokerName?: string;
    organizationId?: string;
    globalSearch?: string;
    updatedAtMax?: string;
    updatedAtMin?: string;
    createdAtMax?: string;
    createdAtMin?: string;
    isDeleted?: boolean | '';
}

export interface SaleClaimCommentInterface {
    id: string;
    text: string;
    isPrivate: boolean;
    saleClaimId: string;
    commenter: {
        cognitoId: string;
        name: string;
    };
    createdAt: string;
    updatedAt: string;
}

export interface UpdateSaleClaimInterface {
    contractionDate?: string;
    downPayment?: number;
    downPaymentDate?: string;
    reservationDate?: string;
    type?: SaleType;
}
export interface UpdateSaleClaimAdminInterface extends UpdateSaleClaimInterface {
    commissionPercentage?: number;
}

export interface UpdateSaleClaimBuyerInterface {
    name?: string;
    phone?: string;
}

export interface QueryBuyersInterface extends CommonQueryInterface {
    id?: string;
    name?: string;
    phone?: string;
    saleClaimId?: string;
    globalSearch?: string;
    createdAtMax?: string;
    createdAtMin?: string;
}

export interface UpdateSaleClaimPropertyInterface {
    developerName?: string;
    compoundName?: string;
    propertyType?: string;
    unitValue?: number;
    unitId?: string;
}

export interface SaleClaimsAnalytica {
    total: number;
    approved: number;
    preApproved: number;
    pending: number;
    rejected: number;
    noAction: number;
    sumInprogress: number;
    sumApproved: number;
}

export enum SaleClaimsRejectionReasonEnum {
    SaleClaimRejectionReason1 = 'sale-claim-rejection-reason-1',
    SaleClaimRejectionReason2 = 'sale-claim-rejection-reason-2',
    SaleClaimRejectionReason3 = 'sale-claim-rejection-reason-3',
    SaleClaimRejectionReason4 = 'sale-claim-rejection-reason-4'
}
