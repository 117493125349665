import { Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { gridSpacing, gridSpacingSmall } from 'store/constant';

export interface TableWidgetInterface {
    label: string;
    money?: number;
    upperTotal?: number;
    lowerTotal?: number;
    loading?: boolean;
    icon?: ReactNode;
    isMain?: boolean;
}

const WidgetCard = ({ loading, label, money, upperTotal = 0, lowerTotal, icon, isMain = true }: TableWidgetInterface) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const intl = useIntl();

    return (
        <Grid container height="100%" justifyContent="start" alignItems="center" spacing={gridSpacing} wrap="nowrap">
            {!!icon && (
                <Grid item>
                    <Grid
                        container
                        bgcolor={theme.palette.primary.light}
                        alignItems="center"
                        justifyContent="center"
                        p={2}
                        borderRadius={4}
                    >
                        {icon}
                    </Grid>
                </Grid>
            )}
            <Grid item minHeight="100%" container xs={icon && !matchDownSM && !matchDownMD ? 8 : 12} spacing={gridSpacingSmall}>
                <Grid item spacing={gridSpacingSmall} xs={12} container wrap="nowrap">
                    {loading ? (
                        <Grid item>
                            <Skeleton sx={{ width: '20px', height: '20px' }} />
                        </Grid>
                    ) : (
                        !!upperTotal && (
                            <Grid item>
                                <Typography
                                    variant={matchDownSM ? 'h6' : 'h4'}
                                    fontWeight={700}
                                    color={isMain ? theme.palette.primary[800] : theme.palette.primary[900]}
                                >
                                    {intl.formatNumber(upperTotal)}
                                </Typography>
                            </Grid>
                        )
                    )}
                    <Grid item>
                        <Typography
                            noWrap
                            variant={matchDownSM ? 'h6' : isMain ? 'h4' : 'h6'}
                            color={isMain ? theme.palette.primary[800] : theme.palette.grey[800]}
                            fontWeight={400}
                        >
                            {label}
                        </Typography>
                    </Grid>
                </Grid>

                {typeof money === 'number' ? (
                    <Grid item xs={12} container spacing={gridSpacingSmall} wrap="nowrap">
                        <Grid item>
                            <Typography variant={matchDownSM ? 'h6' : isMain ? 'h2' : 'h6'} fontWeight={isMain ? 700 : 500}>
                                EGP
                            </Typography>
                        </Grid>
                        <Grid item>
                            {loading ? (
                                <Skeleton sx={{ minWidth: '50px' }} />
                            ) : (
                                <Typography variant={matchDownSM ? 'h5' : isMain ? 'h2' : 'h4'} fontWeight={700}>
                                    {Intl.NumberFormat('en', { notation: 'compact' }).format(money || 0)}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    lowerTotal && (
                        <Grid item>
                            {loading ? (
                                <Skeleton sx={{ minWidth: '50px' }} />
                            ) : (
                                <Typography
                                    variant={matchDownSM ? 'h5' : isMain ? 'h3' : 'h4'}
                                    color={theme.palette.primary[800]}
                                    align="center"
                                >
                                    {intl.formatNumber(lowerTotal)}
                                </Typography>
                            )}
                        </Grid>
                    )
                )}
            </Grid>
        </Grid>
    );
};

export default WidgetCard;
