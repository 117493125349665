import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const whatsappSupportItems: NavItemType = {
    id: 'whatsapp-support',
    title: <FormattedMessage id="support" />,
    type: 'item',
    icon: WhatsAppIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Click view WhatsApp Support page'
    },
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.operationManager },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.internalAgents }
    ]
};

const supportItem: NavItemType = {
    id: 'whatsapp-support',
    type: 'group',
    children: [whatsappSupportItems]
};
export default supportItem;
