import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import { useIntl } from 'react-intl';
import { Autocomplete, TextField } from '@mui/material';

interface UsersSelectFilterProps {
    value: string;
    label: string;
    handleSearch: (newValue: InvitationTypeEnum | null) => void;
}

const SystemUsersGroupFilter = ({ value, label, handleSearch }: UsersSelectFilterProps) => (
    <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
        <Filter label={label} value={value} handleSearch={handleSearch} />
    </RoleGuard>
);

const Filter = ({ value, label, handleSearch }: UsersSelectFilterProps) => {
    const intl = useIntl();

    const handleOnChange = (group: InvitationTypeEnum | null) => {
        handleSearch(group);
    };
    return (
        <Autocomplete
            id="autocomplete-generic-filter-user-groups"
            fullWidth
            size="small"
            options={Object.values(InvitationTypeEnum).filter(
                (group) =>
                    group !== InvitationTypeEnum.admins &&
                    group !== InvitationTypeEnum.nawyInventory &&
                    group !== InvitationTypeEnum.ambassadors
            )}
            onChange={(event, option) => handleOnChange(option)}
            isOptionEqualToValue={(option) => option === value}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-user-groups"
                    label={intl.formatMessage({ id: label })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};

export default SystemUsersGroupFilter;
