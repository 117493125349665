import { Grid, TableCell, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HeadProps } from '../NawyTable/Headers';

const DefaultHeader = ({ headCell }: { headCell: HeadProps }) => (
    <TableCell sx={{ minWidth: headCell.minWidth || '150px' }} key={headCell?.id} align={headCell?.align}>
        <Grid container>
            <Grid item xs={12}>
                <FormattedMessage id={headCell.label} />
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between">
                <Grid item>
                    {headCell?.secondaryLabel && (
                        <Typography variant="caption" display="block">
                            <FormattedMessage id={headCell.secondaryLabel} />
                        </Typography>
                    )}
                </Grid>
                <Grid item>
                    {headCell?.secondaryLabelRight && (
                        <Typography variant="caption" display="block">
                            <FormattedMessage id={headCell.secondaryLabelRight} />
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    </TableCell>
);
export default DefaultHeader;
