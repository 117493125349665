import useMixPanel from 'hooks/useMixPanel';
import useOverlay from 'hooks/useOverlay';
import { useEffect } from 'react';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import { CreatePrimaryCilDialog } from '.';

export interface CreatePrimaryCilDialogActionProps {
    open?: boolean;
    location?: string;
    onClose?: () => void;
}

const CreatePrimaryCilDialogAction = ({ open = false, location, onClose }: CreatePrimaryCilDialogActionProps) => {
    const { emitEvent } = useMixPanel();

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<CreatePrimaryCilDialogActionProps>('CreatePrimaryCilDialogAction');

    const handleOpen = () => {
        emitEvent('Click Add Primary CIL', { location: payload?.location });
        // setOpenCreatePrimaryCilDialog(true);
    };

    const handleOnClose = () => {
        // setOpenCreatePrimaryCilDialog(false);
        onClose?.();
        close();
    };

    useEffect(() => {
        if (open) handleOpen();
    }, [open]);

    return (
        <RoleGuard
            groups={[
                { name: InvitationTypeEnum.accountManagers },
                { name: InvitationTypeEnum.admins },
                { name: InvitationTypeEnum.brokers, organizationFeature: 'primaryCilsLimit' }
            ]}
        >
            <CreatePrimaryCilDialog open={isCurrentOpened} handleClose={handleOnClose} />
        </RoleGuard>
    );
};

export default CreatePrimaryCilDialogAction;
