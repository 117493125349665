import { FAUNA_API, SIRENS_API, formattedQuery } from 'store/apis';
import { InvitationQueryAdminInterface, InvitationQueryOptionsInterface } from './interfaces';

const INVITATIONS_APIS = {
    query: (query: InvitationQueryOptionsInterface) => {
        const formatQuery: string[] = [SIRENS_API, 'invitations/query', '?'];
        return formattedQuery(formatQuery, query);
    },
    queryAdmin: (query: InvitationQueryAdminInterface) => {
        const formatQuery: string[] = [SIRENS_API, 'invitations/admin/query', '?'];
        return formattedQuery(formatQuery, query);
    },
    create: () => `${SIRENS_API}invitations`,
    sirensCreateBatch: () => `${SIRENS_API}invitations/batch`,
    faunaCreateBatch: () => `${FAUNA_API}invitations/bulk`,
    delete: (id: string) => `${SIRENS_API}invitations/${id}`,
    getById: (id: string) => `${SIRENS_API}invitations/${id}`,
    resendSMS: (id: string) => `${SIRENS_API}invitations/${id}/resend-sms`,
    resend: (id: string) => `${SIRENS_API}invitations/${id}/resend`,
    checkState: (phone: string) => `${SIRENS_API}invitations/user-state/${phone}`,
    resendInvitationHourly: () => `${SIRENS_API}invitations/resend-token-hourly`
};

export default INVITATIONS_APIS;
