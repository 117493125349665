import { Card, Grid, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import { gridSpacing, requestDelay } from 'store/constant';
import { CilStatusEnum } from 'store/slices/cils/interfaces';
import { FlowStageStatusesEnum, FlowStagesEnum as flowStages } from 'store/slices/flows/interfaces';
import {
    financingTopPartners,
    primaryCilsTopDevelopers,
    reservationsTopCompounds,
    reservationsTopDevelopers,
    saleClaimsTopCompounds,
    saleClaimsTopDevelopers
} from 'store/slices/partnersDashboard/actions';
import { DashboardFilterInterface, FlowStagesEnum } from 'store/slices/partnersDashboard/interfaces';
import { getReservationAnalytica } from 'store/slices/reservations/actions';
import { getSaleClaimsAnalytics } from 'store/slices/saleClaims/actions';
import {
    queryTopAgents,
    queryTopCompounds,
    queryTopDevelopers,
    queryTopOrganizations,
    queryTopPropertyTypes
} from 'store/slices/sales/actions';
import Filters, { FiltersInterface } from 'ui-component/Filters';
import AccountManagersFilter from 'ui-component/Filters/AccountManagersFilter';
import CompoundsFilterV2 from 'ui-component/Filters/CompoundFilter/V2';
import DateRangePickerFilter from 'ui-component/Filters/DateRangePicker';
import DeveloperFilterV2 from 'ui-component/Filters/DeveloperFilter/v2';
import OrganizationsFilter from 'ui-component/Filters/OrganizationsFilter';
import TiersFilter from 'ui-component/Filters/TierFilter';
import PropertyTypeFilter from 'ui-component/Filters/propertyTypeFilter';
import PageHeader from 'ui-component/PageHeader';
import constructBarChartData from './Charts/Bar/constract-chart-data';
import CilsDashboardSection from './CilsSection';
import SalesPipelineSection from './SalesPipelineSection';
import SalesBarChart from './salesBarChart';

const PartnersDashboardPage = () => {
    const intl = useIntl();
    const location = 'Dashboard';

    const { loading, topDevelopers, topCompounds, topOrganizations, topAgents, topPropertyTypes } = useSelector((state) => state.sales);

    const [searchParams, setSearchParams] = useSearchParams();
    const { listLoading, topCompounds: tc, topDevelopers: td } = useSelector((state) => state.partnersDashboard);

    const initialState: DashboardFilterInterface = {
        organizationId: searchParams.get('organizationId') || '',
        page: parseInt(searchParams.get('page') || '', 10) || undefined,
        pageSize: parseInt(searchParams.get('pageSize') || '5', 10) || undefined,
        accountManagerId: searchParams.get('accountManagerId') || '',
        createdAtMin: searchParams.get('createdAtMin') || '',
        createdAtMax: searchParams.get('createdAtMax') || '',
        compoundId: searchParams.get('compoundId') ? parseInt(searchParams.get('compoundId') as string, 10) : undefined,
        developerId: searchParams.get('developerId') ? parseInt(searchParams.get('developerId') as string, 10) : undefined,
        propertyTypeId: searchParams.get('propertyTypeId') ? parseInt(searchParams.get('propertyTypeId') || '', 10) : undefined,
        flowType: (searchParams.get('flowType') as FlowStagesEnum) || FlowStagesEnum.eoi,
        tierId: searchParams.get('tierId') ? searchParams.get('tierId')! : undefined
    };

    const [filter, setFilter] = useState(initialState);
    const [flowStageType, setFlowStageType] = useState(initialState.flowType ?? FlowStagesEnum.eoi);

    const handleFilters = (newFilterField: DashboardFilterInterface) => {
        const newFilter = { ...filter, ...newFilterField };
        setFilter(newFilter);
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            searchParams.set(filterKey, `${newFilter[key]}`);
            if (newFilter[key] === undefined || newFilter[key] === '') searchParams.delete(filterKey);
            setSearchParams(searchParams);
        });
    };

    const handleClearAll = () => {
        const newFilter = { ...filter };
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            newFilter[key] = undefined;
            searchParams.set(filterKey, `${newFilter[key]}`);
            searchParams.delete(filterKey);
        });
        setFilter(newFilter);

        setSearchParams(searchParams);
    };

    const handleFlowStageChange = (type: FlowStagesEnum, filters: Record<string, any> = {}) => {
        switch (type) {
            case FlowStagesEnum.eoi:
                dispatch(
                    reservationsTopDevelopers({
                        query: { ...filters, stage: flowStages.EOI }
                    })
                );
                dispatch(
                    reservationsTopCompounds({
                        query: { ...filters, stage: flowStages.EOI }
                    })
                );
                break;
            case FlowStagesEnum.reservation:
                dispatch(
                    reservationsTopDevelopers({
                        query: { ...filters, stage: flowStages.Reservation }
                    })
                );
                dispatch(
                    reservationsTopCompounds({
                        query: { ...filters, stage: flowStages.Reservation }
                    })
                );
                break;
            case FlowStagesEnum.in_progress_sale_claim:
                dispatch(saleClaimsTopCompounds({ query: { ...filters, status: FlowStageStatusesEnum.Pending } }));
                dispatch(saleClaimsTopDevelopers({ query: { ...filters, status: FlowStageStatusesEnum.Pending } }));
                break;
            case FlowStagesEnum.approved_sale_claim:
                dispatch(saleClaimsTopCompounds({ query: { ...filters, status: FlowStageStatusesEnum.Approved } }));
                dispatch(saleClaimsTopDevelopers({ query: { ...filters, status: FlowStageStatusesEnum.Approved } }));
                break;
            default:
                break;
        }
    };

    const filterData = useRef(
        debounce(async (data: DashboardFilterInterface) => {
            const filterWith = {
                developerId: data.developerId,
                compoundId: data.compoundId,
                propertyTypeId: data.propertyTypeId,
                organizationId: data.organizationId,
                accountManagerId: data.accountManagerId,
                tierId: data.tierId,
                createdAtMin: data.createdAtMin,
                createdAtMax: data.createdAtMax,
                pageSize: data.pageSize
            };
            dispatch(getSaleClaimsAnalytics({ query: { ...filterWith } }));
            dispatch(
                getReservationAnalytica({
                    query: {
                        ...filterWith
                    }
                })
            );
            // Sales Dashboard Section
            dispatch(queryTopDevelopers({ query: { ...filterWith, pageSize: 10 } }));
            dispatch(queryTopCompounds({ query: { ...filterWith, pageSize: 10 } }));
            dispatch(queryTopAgents({ query: { ...filterWith, pageSize: 10 } }));
            dispatch(queryTopOrganizations({ query: { ...filterWith, pageSize: 10 } }));
            dispatch(queryTopPropertyTypes({ query: { ...filterWith, pageSize: 10 } }));
            // Partners Dashboard Section
            dispatch(primaryCilsTopDevelopers({ query: { ...filterWith, orderBy: 'count', pageSize: 5 } }));

            handleFlowStageChange(data.flowType ?? FlowStagesEnum.eoi, filterWith);

            delete filterWith.developerId;
            delete filterWith.propertyTypeId;
            delete filterWith.compoundId;
            dispatch(financingTopPartners({ query: { ...filterWith, status: CilStatusEnum.accepted } }));
        }, requestDelay)
    ).current;

    const filterSalesPipline = useRef(
        debounce((data: DashboardFilterInterface) => {
            const filterWith = {
                developerId: data.developerId,
                compoundId: data.compoundId,
                propertyTypeId: data.propertyTypeId,
                organizationId: data.organizationId,
                accountManagerId: data.accountManagerId,
                createdAtMin: data.createdAtMin,
                createdAtMax: data.createdAtMax,
                pageSize: data.pageSize
            };

            handleFlowStageChange(data.flowType ?? FlowStagesEnum.eoi, filterWith);
        })
    ).current;

    useEffect(() => {
        filterData(filter);
    }, [filter, filterData]);

    useEffect(() => {
        filterSalesPipline({ ...filter, flowType: flowStageType });
    }, [flowStageType, filterSalesPipline, filter]);

    const filters: FiltersInterface[] = [
        {
            mainView: (
                <DateRangePickerFilter
                    location={location}
                    DateMin={filter.createdAtMin || ''}
                    DateMax={filter.createdAtMax || ''}
                    handleSearch={(createdAtMin, createdAtMax) => handleFilters({ createdAtMin, createdAtMax })}
                    label={intl.formatMessage({ id: 'date' })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <OrganizationsFilter
                    defaultValueId={filter.organizationId || ''}
                    value={filter.organizationId || ''}
                    handleSearch={(v) => handleFilters({ organizationId: v })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <DeveloperFilterV2
                    location={location}
                    defaultValueId={filter.developerId}
                    value={filter.developerId}
                    handleSearch={(developerId) => handleFilters({ developerId })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <CompoundsFilterV2
                    location={location}
                    value={filter.compoundId}
                    defaultValueId={filter.compoundId}
                    handleSearch={(compoundId) => handleFilters({ compoundId })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <PropertyTypeFilter
                    defaultValueId={filter.propertyTypeId}
                    value={filter.propertyTypeId}
                    handleSearch={(v) => handleFilters({ propertyTypeId: v })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <AccountManagersFilter
                    defaultValueId={filter.accountManagerId || ''}
                    value={filter.accountManagerId || ''}
                    handleSearch={(accountManagerId) => handleFilters({ accountManagerId })}
                />
            ),
            chipView: undefined
        },
        {
            mainView: (
                <TiersFilter
                    value={filter.tierId || ''}
                    defaultValueId={filter.tierId || ''}
                    handleSearch={(tierId) => handleFilters({ tierId })}
                />
            ),
            chipView: undefined
        }
    ];

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <PageHeader title={intl.formatMessage({ id: 'partners-dashboard' })} />
            </Grid>

            <Grid item xs={12}>
                <Card
                    style={{
                        padding: 16,
                        boxShadow: '0px 4px 6px -4px rgba(16, 24, 40, 0.06), 0px 10px 16px -3px rgba(16, 24, 40, 0.06)'
                    }}
                >
                    <Filters filters={filters} clearAll={false} handleClearAll={handleClearAll} filterLimit={4} popUpFilters />
                </Card>
            </Grid>

            <Grid item xs={12}>
                <SalesPipelineSection
                    topDevelopers={constructBarChartData(td, intl, intl.formatMessage({ id: 'top-developers' }), filter.pageSize)}
                    developersLoading={listLoading}
                    compoundLoading={listLoading}
                    topCompounds={constructBarChartData(tc, intl, intl.formatMessage({ id: 'top-compounds' }), filter.pageSize)}
                    showMore={filter.pageSize === 5}
                    handleShow={(pageSize) => {
                        handleFilters({ pageSize });
                    }}
                    flowStage={flowStageType}
                    handleFlowStageChange={(flowStage) => setFlowStageType(flowStage)}
                />
            </Grid>
            <Grid item xs={12}>
                <CilsDashboardSection />
            </Grid>

            <Grid item mt={2} xs={12}>
                <Typography variant="h3">
                    <FormattedMessage id="sales-insights" />
                </Typography>
            </Grid>

            <Grid container item xs={12} spacing={gridSpacing}>
                <Grid item xs={12} md={6}>
                    <SalesBarChart
                        title={intl.formatMessage({ id: 'top-developers' })}
                        isLoading={loading}
                        type="vertical"
                        data={constructBarChartData(topDevelopers, intl, intl.formatMessage({ id: 'top-developers' }), 5)}
                        dialogData={constructBarChartData(topDevelopers, intl, intl.formatMessage({ id: 'top-developers' }), 10)}
                        mainCard
                        showAll
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SalesBarChart
                        title={intl.formatMessage({ id: 'top-compounds' })}
                        isLoading={loading}
                        type="vertical"
                        data={constructBarChartData(topCompounds, intl, intl.formatMessage({ id: 'top-compounds' }))}
                        dialogData={constructBarChartData(topCompounds, intl, intl.formatMessage({ id: 'top-developers' }), 10)}
                        mainCard
                        showAll
                    />
                </Grid>
            </Grid>
            {/* 2nd component */}
            <Grid container item xs={12}>
                <SalesBarChart
                    title={intl.formatMessage({ id: 'top-property-types' })}
                    type="dynamic"
                    isLoading={loading}
                    data={constructBarChartData(topPropertyTypes, intl, intl.formatMessage({ id: 'top-property-types' }))}
                    mainCard
                    showAll={false}
                />
            </Grid>
            {/* 3rd component */}
            <Grid container item xs={12} spacing={gridSpacing}>
                <Grid item xs={12} md={6}>
                    <SalesBarChart
                        title={intl.formatMessage({ id: 'top-partners' })}
                        isLoading={loading}
                        type="vertical"
                        data={constructBarChartData(topOrganizations, intl, intl.formatMessage({ id: 'top-partners' }))}
                        dialogData={constructBarChartData(topOrganizations, intl, intl.formatMessage({ id: 'top-developers' }), 10)}
                        mainCard
                        showAll
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SalesBarChart
                        title={intl.formatMessage({ id: 'top-agents' })}
                        isLoading={loading}
                        type="vertical"
                        data={constructBarChartData(topAgents, intl, intl.formatMessage({ id: 'top-agents' }))}
                        dialogData={constructBarChartData(topAgents, intl, intl.formatMessage({ id: 'top-developers' }), 10)}
                        mainCard
                        showAll
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PartnersDashboardPage;
