import { HeadProps } from 'ui-component/Tables/NawyTable/Headers';

const systemUsersHeaders: HeadProps[] = [
    {
        id: 'name',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'name'
    },
    {
        id: 'phone',
        align: 'left',
        disablePadding: false,
        sort: true,
        label: 'phone'
    },
    {
        id: 'banned',
        align: 'center',
        disablePadding: false,
        sort: true,
        label: 'banned'
    },
    {
        id: 'broker',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'broker'
    },
    {
        id: 'account-manager',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'account-manager'
    },
    {
        id: 'sales-agent',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'sales-agent'
    },
    {
        id: 'operation-manager',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'operation-manager'
    },
    {
        id: 'inventory-manager',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'inventory-manager'
    },
    {
        id: 'createdAt',
        align: 'center',
        disablePadding: true,
        sort: true,
        label: 'created-at'
    },
    {
        id: 'updatedAt',
        align: 'center',
        disablePadding: false,
        sort: false,
        label: 'updated-at'
    }
];
export default systemUsersHeaders;
