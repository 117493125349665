import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import { Navigate } from 'react-router';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';

const CommissionTermsPage = Loadable(lazy(() => import('views/commission-terms')));

const commissionTermsRoutes = {
    path: '/commission-terms',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit', roles: [OrganizationRole.owner] },
                    { name: InvitationTypeEnum.admins },
                    { name: InvitationTypeEnum.accountManagers },
                    {
                        name: InvitationTypeEnum.brokers,
                        organizationFeature: 'saleClaimsLimit',
                        roles: [OrganizationRole.owner, OrganizationRole.employee],
                        tiers: ['96af4348-4dcb-4d98-b8a6-33bac4cd831b']
                    }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <CommissionTermsPage />
        }
    ]
};

export default commissionTermsRoutes;
