import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import useOverlay from 'hooks/useOverlay';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch } from 'store';
import { updateDocuments } from 'store/slices/organizations/actions';
import { DocumentInterface, DocumentStatus, DocumentStatusType, DocumentTypes } from 'store/slices/organizations/interfaces';
import { openSnackbar } from 'store/slices/snackbar';

export interface VerificationButtonProps {
    organizationId: string;
    documentType: DocumentTypes | keyof typeof DocumentTypes;
    documents: DocumentInterface[];
    value?: keyof typeof DocumentStatus;
    onStatusChange?: (status: DocumentStatus) => void;
    onConfirmedRejection?: (status: DocumentStatus) => void;
}

const colorMap: Record<DocumentStatusType, { 100: string; main: string }> = {
    Pending: {
        100: 'warning.100',
        main: 'warning.main'
    },
    Rejected: {
        100: 'error.100',
        main: 'error.main'
    },
    Verified: {
        100: 'success.100',
        main: 'success.main'
    }
};

const menuItems: { label: string; value: DocumentStatus | DocumentStatusType }[] = [
    { label: 'pending', value: 'Pending' },
    { label: 'rejected', value: 'Rejected' },
    { label: 'verified', value: 'Verified' }
];

export const documentsVerificationIntlMap: Record<DocumentStatusType, Partial<Record<keyof typeof DocumentTypes, string>>> = {
    Pending: {
        VATCertificate: 'financial-documents-vat-certificate-pending-successfully',
        WithholdingAmount: 'financial-documents-withholding-amount-pending-successfully',
        CommercialRegister: 'organization-documents-commercial-register-pending-successfully',
        TaxCard: 'organization-documents-tax-card-pending-successfully',
        NationalId: 'organization-documents-national-id-pending-successfully'
    },
    Rejected: {
        VATCertificate: 'financial-documents-vat-certificate-rejected-successfully',
        WithholdingAmount: 'financial-documents-withholding-amount-rejected-successfully',
        CommercialRegister: 'organization-documents-commercial-register-rejected-successfully',
        TaxCard: 'organization-documents-tax-card-rejected-successfully',
        NationalId: 'organization-documents-national-id-rejected-successfully'
    },
    Verified: {
        VATCertificate: 'financial-documents-vat-certificate-verified-successfully',
        WithholdingAmount: 'financial-documents-withholding-amount-verified-successfully',
        CommercialRegister: 'organization-documents-commercial-register-verified-successfully',
        TaxCard: 'organization-documents-tax-card-verified-successfully',
        NationalId: 'organization-documents-national-id-verified-successfully'
    }
};

const VerificationButton: FC<VerificationButtonProps & TextFieldProps> = ({
    organizationId,
    documentType,
    documents = [],
    value = 'Pending',
    onStatusChange,
    onConfirmedRejection,
    ...props
}) => {
    const intl = useIntl();
    const [selected, setSelected] = useState<DocumentStatusType>(value);
    const { open } = useOverlay();

    const onSuccess = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    const onSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prevStatus = selected;
        const status = e.target.value as DocumentStatus;
        const commonData = {
            type: documentType,
            documentIds: documents.map((doc) => doc.id)
        };

        switch (status) {
            case DocumentStatus.Pending: {
                dispatch(
                    updateDocuments({
                        organizationId,
                        data: { ...commonData, verificationStatus: 'Pending' },
                        runSuccess: () => onSuccess(intl.formatMessage({ id: documentsVerificationIntlMap[status][documentType]! }))
                    })
                );
                break;
            }
            case DocumentStatus.Rejected: {
                open('DocumentRejectionReasonDialog', {
                    organizationId,
                    documentType,
                    status,
                    documents,
                    onClose: () => setSelected(prevStatus),
                    onConfirmedRejection
                });
                break;
            }
            case DocumentStatus.Verified: {
                dispatch(
                    updateDocuments({
                        organizationId,
                        data: { ...commonData, verificationStatus: 'Verified' },
                        runSuccess: () => onSuccess(intl.formatMessage({ id: documentsVerificationIntlMap[status][documentType]! }))
                    })
                );
                break;
            }
        }

        setSelected(status);
        onStatusChange?.(status);
    };

    useEffect(() => {
        onStatusChange?.(selected as DocumentStatus);
    }, [selected]);

    const isDisabled = documents.length === 0;

    useEffect(() => {
        setSelected(value);
    }, [value, documents.length]);

    return (
        <TextField
            size="small"
            value={selected}
            onChange={onSelectChange}
            select
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: colorMap[selected][100]
                    },
                    '&:hover fieldset': {
                        borderColor: isDisabled ? 'transparent' : colorMap[selected].main
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: colorMap[selected].main
                    },
                    '& .MuiSelect-select': {
                        bgcolor: colorMap[selected][100],
                        '&.Mui-focused .MuiSelect-select': {
                            bgcolor: colorMap[selected][100]
                        },
                        '&.Mui-disabled': {
                            bgcolor: 'inherit',
                            borderColor: 'transparent'
                        },
                        '&:focus': {
                            bgcolor: colorMap[selected][100]
                        }
                    },
                    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDisabled ? 'transparent' : colorMap[selected].main
                    }
                }
            }}
            disabled={isDisabled}
            {...props}
        >
            {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value} sx={{ display: item.value !== selected ? 'block' : 'none' }}>
                    <FormattedMessage id={item.label} />
                </MenuItem>
            ))}
        </TextField>
    );
};

export default VerificationButton;
