import { TableCell } from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';

interface StickyCellProps {
    children: ReactNode;
    sx?: {};
}

const StickyCell = ({ children, sx }: StickyCellProps) => (
    <Box
        sx={{
            ...sx,
            position: 'sticky',
            right: 0,
            backgroundColor: 'white',
            boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.08), 0px 1px 2px -1px rgba(16, 24, 40, 0.08)'
        }}
        component={TableCell}
    >
        {children}
    </Box>
);

export default StickyCell;
