// assets
import EngineeringTwoToneIcon from '@mui/icons-material/EngineeringTwoTone';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { TierViewsEnum } from 'store/slices/tiers/interfaces';
import { NavItemType } from 'types';

const allUsersItem: NavItemType = {
    id: 'users',
    title: <FormattedMessage id="all" />,
    type: 'item',
    url: '/users',
    icon: SupervisedUserCircleIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'All Users'
    },
    allowedAudience: [{ name: InvitationTypeEnum.admins }]
};

const accountManagersItem: NavItemType = {
    id: 'account-managers',
    title: <FormattedMessage id="account-managers" />,
    type: 'item',
    url: '/users/account-managers',
    icon: GroupsIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'Users-Account Manager'
    }
};

const operationManagerItem: NavItemType = {
    id: 'operation-managers',
    title: <FormattedMessage id="operation-managers" />,
    type: 'item',
    url: '/users/operation-managers',
    icon: HomeRepairServiceIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.operationManager }],
    mixpanelEvent: {
        tab_name: 'Users: Operation Manager'
    }
};

const brokerItem: NavItemType = {
    id: 'sales-agents-general',
    title: (
        <>
            <FormattedMessage id="partner" /> <FormattedMessage id="agent" />
        </>
    ),
    type: 'item',
    url: '/users/sales-agents',
    icon: EngineeringTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.accountManagers }, { name: InvitationTypeEnum.operationManager }],
    mixpanelEvent: {
        tab_name: 'Users: Brokers'
    }
};

const brokerForBrokerItem: NavItemType = {
    id: 'sales-agents-for-broker',
    title: <FormattedMessage id="team" />,
    type: 'item',
    url: '/users/sales-agents',
    icon: EngineeringTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.brokers, organizationTierView: TierViewsEnum.brokerView }],
    mixpanelEvent: {
        tab_name: 'Users: Brokers'
    }
};

const faunaUsers: NavItemType = {
    id: 'system-users',
    title: <FormattedMessage id="system-users" />,
    type: 'item',
    url: '/users/system-users',
    icon: SupervisedUserCircleIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Users:All Partners Users'
    }
};

export const userItemsGroup: NavItemType = {
    id: 'user-items-group-items',
    type: 'group',
    children: [accountManagersItem, operationManagerItem, brokerItem, brokerForBrokerItem]
};

const userItemsCollapse: NavItemType = {
    id: 'user-items-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'collapse',
            icon: SupervisedUserCircleIcon,
            breadcrumbs: false,
            children: [allUsersItem, accountManagersItem, operationManagerItem, brokerItem, faunaUsers]
        }
    ]
};

export default userItemsCollapse;
