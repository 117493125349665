import { memo } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports

import menuItem from 'menu-items';

import RoleGuard from 'utils/RoleGuard';
import NavGroup from './NavGroup';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const items = [menuItem.commonItemsTop, menuItem.items, menuItem.commonItemsBottom];
    const navItems = items.map((itemGroup) =>
        itemGroup.map((item) => {
            switch (item.type) {
                case 'group':
                    if (!item.allowedAudience) return <NavGroup key={item.id} item={item} />;
                    return (
                        <RoleGuard key={item.id} groups={item.allowedAudience}>
                            <NavGroup key={item.id} item={item} />
                        </RoleGuard>
                    );

                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        })
    );

    return <>{navItems}</>;
};

export default memo(MenuList);
