import { FAUNA_API } from 'store/apis';

const resource = 'analytica';

const ANALYTICA_APIS = {
    /**
     * @GET
     * @description get `Sale Claims` analytics
     * @returns SaleClaimStatusAnalytica[]
     */
    SaleClaims: () => `${FAUNA_API}${resource}/sale-claims`,
    /**
     * @GET
     * @description get `Cils` analytics
     * @returns CilsAnalyticaInterface[]
     */
    cils: () => `${FAUNA_API}${resource}/cils`,
    /**
     * @GET
     * @description get `Invitations` analytics
     * @returns InvitationsAnalyticaInterface
     */
    invitations: () => `${FAUNA_API}${resource}/invitations`
};

export default ANALYTICA_APIS;
