import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useFormik } from 'formik';
import useOverlay from 'hooks/useOverlay';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { createAccountManagerActivity } from 'store/slices/accountManagerActivities/actions';
import { ActivityCategoryInterface } from 'store/slices/activityCategory/interfaces';
import { ActivityStatusInterface } from 'store/slices/activityStatus/interfaces';
import { ActivityTypeInterface } from 'store/slices/activityType/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import OrganizationsFilter from 'ui-component/Filters/OrganizationsFilter';
import * as yup from 'yup';

export interface CreateAccountManagerActivityDialogProps {
    organizationId?: string;
    showTrigger?: boolean;
}

export default function CreateAccountManagerActivityDialog({
    showTrigger = false,
    organizationId
}: CreateAccountManagerActivityDialogProps) {
    const dispatch = useDispatch();
    const intl = useIntl();
    const [open, setOpen] = useState(false);

    const { isCurrentOpened, open: openOverlay, close } = useOverlay('CreateAccountManagerActivityDialog');

    const CreateActivitySchema = yup.object().shape({
        organizationId: yup.string().required(intl.formatMessage({ id: 'required' })),
        statusId: yup.string().required(intl.formatMessage({ id: 'activity-reason-required' })),
        date: yup.date().required(intl.formatMessage({ id: 'activity-date-time-required' })),
        note: yup.string()
    });

    const firstRender = useRef(true);
    const { categories } = useSelector((state) => state.activityCategory);
    const { error } = useSelector((state) => state.accountManagerActivities);

    const [selectedCategory, setSelectedCategory] = useState<ActivityCategoryInterface | null>(categories[0]);
    const [selectedTypeId, setSelectedTypeId] = useState<number | null>(null);
    const [types, setTypes] = useState<ActivityTypeInterface[]>([]);
    const [selectedStatus, setSelectedStatus] = useState<ActivityStatusInterface | null>(null);
    const [statuses, setStatuses] = useState<ActivityStatusInterface[] | []>([]);

    const handleToggleOpen = () => {
        formik.resetForm();
        setOpen(!open);
        openOverlay();
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        closeDialog();
    };

    const closeDialog = () => {
        formik.resetForm();
        setSelectedTypeId(null);
        setSelectedStatus(null);
        setOpen(false);
        close();
    };

    const formik = useFormik({
        initialValues: {
            organizationId: organizationId || '',
            statusId: undefined,
            date: undefined,
            note: ''
        },
        onSubmit(values, formikHelpers) {
            if (values.statusId && values.date) {
                dispatch(
                    createAccountManagerActivity({
                        createBody: {
                            organizationId: values.organizationId,
                            statusId: values.statusId,
                            date: values.date,
                            note: values.note
                        },
                        runSuccess
                    })
                );
            }
        },
        validationSchema: CreateActivitySchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (!firstRender) return;
        firstRender.current = false;
        setSelectedCategory(categories?.[0]);
    }, [categories]);

    useEffect(() => {
        setTypes(selectedCategory?.types || []);
        setSelectedTypeId(null);
        setSelectedStatus(null);
    }, [selectedCategory]);

    const handleActivitCategoryChange = (event: any, category: any) => {
        if (category !== null) setSelectedCategory(category);
    };

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
    return (
        <>
            {showTrigger && (
                <Button onClick={handleToggleOpen} variant="contained">
                    <FormattedMessage id="add-activity" />
                </Button>
            )}

            <Dialog open={isCurrentOpened} onClose={handleToggleOpen} aria-labelledby="activity-dialog-title" maxWidth="sm" fullWidth>
                <DialogTitle id="activity-dialog-title">
                    <FormattedMessage id="add-activity" />
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {!organizationId && (
                            <Grid mt={1} item xs={12}>
                                <OrganizationsFilter
                                    value={formik.values.organizationId}
                                    handleSearch={(v) => {
                                        formik.setFieldValue('organizationId', v);
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} style={{ flexGrow: 1 }}>
                            <ToggleButtonGroup
                                value={selectedCategory}
                                exclusive
                                onChange={handleActivitCategoryChange}
                                aria-label="activity type"
                                style={{ width: '100%' }}
                            >
                                {categories?.map((category: ActivityCategoryInterface, i) => (
                                    <ToggleButton value={category} key={category.id} style={{ width: '100%' }} color="primary">
                                        <FormattedMessage id={category.name} />
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                id="select-type"
                                options={types}
                                getOptionLabel={(option: ActivityTypeInterface) => option.name}
                                onChange={(_, newValue: any) => {
                                    setSelectedTypeId(newValue?.id);
                                    setStatuses(newValue?.statuses || []);
                                    formik.setFieldValue('statusId', null);
                                    setSelectedStatus(null);
                                }}
                                value={types.find((t) => t.id === selectedTypeId) || null}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={intl.formatMessage({ id: 'type' })}
                                        fullWidth
                                        error={Boolean(formik.touched.statusId && formik.errors.statusId)}
                                        helperText={formik.touched.statusId && formik.errors.statusId}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <Autocomplete
                                id="select-status"
                                options={statuses}
                                getOptionLabel={(option: ActivityStatusInterface) => option.name}
                                onChange={(_, newValue: any) => {
                                    setSelectedStatus(newValue);
                                    formik.setFieldValue('statusId', newValue ? newValue.id : null); // Update statusId
                                }}
                                value={selectedStatus}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={intl.formatMessage({ id: 'status' })}
                                        fullWidth
                                        error={Boolean(formik.touched.statusId && formik.errors.statusId)}
                                        helperText={formik.touched.statusId && formik.errors.statusId}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label={intl.formatMessage({ id: 'date' })}
                                    value={formik.values.date ? new Date(formik.values.date) : null}
                                    onChange={(newValue) => {
                                        if (newValue?.getDate()) formik.setFieldValue('date', newValue.toISOString());
                                        else formik.setFieldValue('date', '');
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            name="date"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.date && formik.errors.date)}
                                            helperText={formik.touched.date && formik.errors.date}
                                            color="secondary"
                                            fullWidth
                                            {...params}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                label={<FormattedMessage id="notes" />}
                                name="note"
                                fullWidth
                                multiline
                                rows={4}
                                value={formik.values.note}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} size="small" onClick={closeDialog}>
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton loading={formik.isSubmitting} variant="contained" size="small" onClick={formik.submitForm} autoFocus>
                        <FormattedMessage id="add" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
