import { AxiosRequestConfig } from 'axios';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import jwtDecode from 'jwt-decode';
import { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import axios from 'utils/axios';
import cookie from 'js-cookie';

const AxiosProvider = ({ children }: { children: ReactElement | null }) => {
    const { refreshUserState } = useAuth();
    const { emitEvent } = useMixPanel();
    const { logout } = useAuth();
    const intl = useIntl();
    const [refresh, setRefresh] = useState(false);
    // const [startDate, setStartDate] = useState<Date | null>(new Date(Date.now() + 18000));

    useEffect(() => {
        setTimeout(() => {
            setRefresh(!refresh);
        }, 18000);
        axios.interceptors.request.use(
            async (config: AxiosRequestConfig) => {
                try {
                    const serviceToken = cookie.get(`${process.env.REACT_APP_AWS_CLIENT_ID}.accessToken`);
                    if (serviceToken) {
                        const token: any = jwtDecode(serviceToken);

                        if (token) {
                            const currentTime = Date.now() / 1000;
                            const timeLeft = Math.floor(token.exp - currentTime);

                            if (timeLeft < 10) {
                                const refreshedTokens = await refreshUserState();
                                config.headers = {
                                    Authorization: `Bearer ${refreshedTokens.getAccessToken().getJwtToken()}`
                                };
                            }
                        }
                        return config;
                    }
                    logout();
                    return config;
                } catch (error) {
                    // emitEvent('Error - can not refresh token', { errorMessage: JSON.stringify(error) });
                    window.location.reload();
                    return config;
                }
            },
            (error) => Promise.reject(error)
        );
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                const errorMessage = error?.message || error?.response?.data || undefined;
                dispatch(
                    openSnackbar({
                        open: true,
                        message: errorMessage || intl.formatMessage({ id: 'something-wrong' }),
                        variant: 'alert',
                        alert: {
                            color: 'error',
                            severity: 'error'
                        },
                        close: true
                    })
                );

                return Promise.reject(error || 'Something went wrong, please try again later.');
            }
        );
    }, [emitEvent, intl, logout, refreshUserState, refresh]);

    return children;
};

export default AxiosProvider;
