import { useDispatch, useSelector } from 'store';
import { closeAllOverlays, closeOverlay, openOverlay } from 'store/slices/overlays';
import { OverlayState, OverlayTypeArg } from 'store/slices/overlays/types';

const useOverlay = <T>(overlayType?: OverlayTypeArg) => {
    const dispatch = useDispatch();
    const overlays = useSelector((state) => state.overlays);

    const open = (type?: OverlayTypeArg, payload?: T) => {
        if (type || overlayType) {
            dispatch(openOverlay({ type: (type ?? overlayType)!, payload }));
        } else {
            console.warn('No overlay type provided to openOverlay');
        }
    };

    const close = (type?: OverlayTypeArg) => {
        if (type || overlayType) {
            dispatch(closeOverlay({ type: (type ?? overlayType)! }));
        } else {
            console.warn('No overlay type provided to closeOverlay');
        }
    };

    const closeAll = () => {
        dispatch(closeAllOverlays());
    };

    const getState = (type?: OverlayTypeArg) => overlays.find((overlay) => overlay.type === type) ?? ({} as OverlayState);

    const checkIfCurrentOverlayOpened = (type?: OverlayTypeArg) =>
        overlayType ? !!getState(type).isOpen && getState(type).type === overlayType : false;

    return {
        isCurrentOpened: checkIfCurrentOverlayOpened(overlayType),
        state: getState(overlayType) as OverlayState<T>,
        open,
        close,
        closeAll
    };
};

export default useOverlay;
