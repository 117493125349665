import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import REJECTION_REAONS_APIS from './apis';

import { CreateRjectionReasons, IRejectionReasons, QueryRejctionReasons } from './interfaces';

export const createRejection = createAsyncThunk(
    'rejectionReasons/create',
    async (
        requestData: {
            data: CreateRjectionReasons;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IRejectionReasons | any> => {
        const { data, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.post(REJECTION_REAONS_APIS.create(), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryRejectionReasons = createAsyncThunk(
    'rejectionReasons/query',
    async (
        requestData: {
            queryData: QueryRejctionReasons;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IRejectionReasons[] | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(REJECTION_REAONS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateRejectionReasonsById = createAsyncThunk(
    'rejectionReasons/update',
    async (
        requestData: {
            id: number;
            updateData: any;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IRejectionReasons | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(REJECTION_REAONS_APIS.update(id), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteRejectionReason = createAsyncThunk(
    'rejectionReasons/delete',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IRejectionReasons | any> => {
        const { id, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.delete(REJECTION_REAONS_APIS.delete(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getRecentRejectionReasonsByDocIds = createAsyncThunk(
    'rejectionReasons/get-recent-by-doc-ids',
    async (
        requestData: {
            docIds: string[];
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IRejectionReasons[] | any> => {
        const { docIds, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(REJECTION_REAONS_APIS.getRecentByDocIds(docIds));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
