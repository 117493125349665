import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { gridSpacingMedium } from 'store/constant';
import notFound from 'assets/images/no-developer-logo-found.svg';

const DeveloperTitle = ({ developerName, developerLogoUrl }: { developerName: string; developerLogoUrl: string }) => (
    <Stack direction="row" alignItems="center" spacing={gridSpacingMedium}>
        <Box sx={{ width: '64px', height: '64px', overflow: 'hidden' }} borderColor="grey" borderRadius="64px" flexShrink={0}>
            <img
                src={developerLogoUrl || notFound}
                alt=""
                style={{
                    borderRadius: '64px',
                    borderColor: 'green',
                    width: '100%',
                    height: '100%',
                    objectFit: 'fill',
                    objectPosition: 'center'
                }}
            />
        </Box>
        <Typography variant="h4" fontWeight={700}>
            {developerName}
        </Typography>
    </Stack>
);

export default DeveloperTitle;
