import Footer from 'views/landing-page/footer';
import LandingHeader from 'views/landing-page/header';
import BecomePartnerContent from './content/BecomePartnerContent';

function BecomePartnerPage() {
    return (
        <>
            <LandingHeader />
            <BecomePartnerContent />
            <Footer />
        </>
    );
}

export default BecomePartnerPage;
