// assets
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const notificationTypesItem: NavItemType = {
    id: 'notifications',
    title: <FormattedMessage id="types" />,
    type: 'item',
    url: '/admin/notification-types',
    icon: NotificationsIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Notifications'
    }
};

const notificationsItemsCollapse: NavItemType = {
    id: 'notifications-side-menu-items-collapse',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'notifications',
            title: <FormattedMessage id="notifications" />,
            type: 'collapse',
            icon: NotificationsIcon,
            children: [notificationTypesItem]
        }
    ]
};

export default notificationsItemsCollapse;
