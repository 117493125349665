import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import ACTIVITY_CATEGORY_APIS from './apis';
import { CreateActivityCategoryInterface, QueryActivityCategoryInterface } from './interfaces';

export const queryActivityCategories = createAsyncThunk(
    'activity-category/query',
    async (
        requestData: {
            queryData: QueryActivityCategoryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(ACTIVITY_CATEGORY_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getActivityCategoryById = createAsyncThunk(
    'activity-category/get-By-id',
    async (
        requestData: {
            categoryId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { categoryId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(ACTIVITY_CATEGORY_APIS.getById(categoryId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createActivityCategory = createAsyncThunk(
    'activity-category/create',
    async (
        requestData: {
            createBody: CreateActivityCategoryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createBody, runSuccess } = requestData;
        try {
            const successRes = await axios.post(ACTIVITY_CATEGORY_APIS.create(), createBody?.activity);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateActivityCategory = createAsyncThunk(
    'activity-category/update',
    async (
        requestData: {
            categoryId: number;
            updateBody: CreateActivityCategoryInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { categoryId, updateBody, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(ACTIVITY_CATEGORY_APIS.update(categoryId), updateBody?.activity);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteActivityCategory = createAsyncThunk(
    'activity-category/delete',
    async (
        requestData: {
            categoryId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { categoryId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(ACTIVITY_CATEGORY_APIS.delete(categoryId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
