import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const LaunchesIframePage = Loadable(lazy(() => import('views/launches')));

const LaunchesRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/launches',
            element: <LaunchesIframePage />
        }
    ]
};

export default LaunchesRoutes;
