import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import { updateUploadProgress } from '.';
import PROPERTY_LISTING_APIS from './apis';
import {
    DescriptionInterface,
    ImageType,
    InternalDetailsInterface,
    LocationInterface,
    PaymentInterface,
    PropertyInterface,
    PropertyListingInterface,
    QueryPropertiesListingInterface,
    SubmitImageInterface,
    SubmitLocationInterface,
    SubmitPropertyInterface,
    UpdateListingLocationInterface
} from './interfaces';

export const queryPropertiesList = createAsyncThunk(
    'propertyListing/query',
    async (
        requestData: {
            queryData: QueryPropertiesListingInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface[] | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getPropertyListingById = createAsyncThunk(
    'propertyListing/getById',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.getListingById(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const assignListingReviewer = createAsyncThunk(
    'propertyListing/assign-reviewer',
    async (
        requestData: {
            id: string;
            listingId: number[];
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { id, listingId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.assignReviewer(id), { listingId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const submitPropertyListing = createAsyncThunk(
    'propertyListing/submit',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.submit(id));
            if (!successRes.data.invalidSections && runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryInventoryManagers = createAsyncThunk(
    'propertyListing/query-managers',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;

        try {
            const { data } = await axiosServices.get(PROPERTY_LISTING_APIS.queryInventoryManagers());
            if (runSuccess) runSuccess();
            return data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createPayment = createAsyncThunk(
    'propertyListing/createPayment',
    async (
        requestData: {
            data: PaymentInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PaymentInterface | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.createPayment(), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updatePayment = createAsyncThunk(
    'propertyListing/updatePayment',
    async (
        requestData: {
            id: number;
            data: PaymentInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PaymentInterface | any> => {
        const { data, id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updatePayment(id), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createLocation = createAsyncThunk(
    'propertyListing/createLocation',
    async (
        requestData: {
            data: SubmitLocationInterface;
            runSuccess?: (id: number) => void;
        },
        { rejectWithValue }
    ): Promise<LocationInterface | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.createLocation(), { ...data });
            if (runSuccess) runSuccess(successRes.data.listingId);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateLocation = createAsyncThunk(
    'propertyListing/updateLocation',
    async (
        requestData: {
            id: number;
            data: UpdateListingLocationInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<LocationInterface | any> => {
        const { data, id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateLocation(id), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createDescription = createAsyncThunk(
    'propertyListing/createDescription',
    async (
        requestData: {
            data: DescriptionInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<DescriptionInterface | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.createDescription(), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateDescription = createAsyncThunk(
    'propertyListing/updateDescription',
    async (
        requestData: {
            id: number;
            data: DescriptionInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<DescriptionInterface | any> => {
        const { data, id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateDescription(id), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createProperty = createAsyncThunk(
    'propertyListing/createProperty',
    async (
        requestData: {
            data: SubmitPropertyInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyInterface | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.createProperty(), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateProperty = createAsyncThunk(
    'propertyListing/updateProperty',
    async (
        requestData: {
            id: number;
            data: SubmitPropertyInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyInterface | any> => {
        const { data, id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateProperty(id), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createInternalDetails = createAsyncThunk(
    'propertyListing/createInternalDetails',
    async (
        requestData: {
            data: InternalDetailsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InternalDetailsInterface | any> => {
        const { data, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.createInternalDetails(), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateInternalDetails = createAsyncThunk(
    'propertyListing/updateInternalDetails',
    async (
        requestData: {
            id: number;
            data: InternalDetailsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InternalDetailsInterface | any> => {
        const { data, id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateInternalDetails(id), { ...data });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getCompoundsDetails = createAsyncThunk(
    'propertyListing/getCompoundDetails',
    async (
        requestData: {
            name: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { name, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.getCompoundDetails(name));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getBrokers = createAsyncThunk(
    'propertyListing/getBrokers',
    async (
        requestData: {
            name: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { name, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.getBrokers(name));
            if (runSuccess) runSuccess();
            return successRes.data.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const uploadPropertyListingImage = createAsyncThunk(
    'propertyListing/uploadPropertyListingImage ',
    async (
        requestData: {
            data: SubmitImageInterface;
            runSuccess?: any;
        },
        { rejectWithValue, dispatch }
    ): Promise<any> => {
        const { data, runSuccess } = requestData;

        const formData = new FormData();
        formData.append('listingId', data.listingId.toString());
        formData.append('name', data.name);
        formData.append('type', data.type);
        formData.append('document', data.document);

        try {
            const successRes = await axiosServices.post(PROPERTY_LISTING_APIS.uploadPropertyListingImage(), formData, {
                headers: { 'Content-Type': `multipart/form-data;` },
                onUploadProgress: (progressEvent) => {
                    dispatch(
                        updateUploadProgress({
                            progress: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                            index: data.index || 0
                        })
                    );
                }
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deletePropertyListingImage = createAsyncThunk(
    'propertyListing/deletePropertyListingImage ',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.delete(PROPERTY_LISTING_APIS.deletePropertyListingImage(id));
            if (runSuccess) runSuccess();
            return { sectionStatus: successRes.data.sectionStatus, id };
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updatePropertyListingCoverImage = createAsyncThunk(
    'propertyListing/updatePropertyListingCoverImage ',
    async (
        requestData: {
            listingId: number;
            prevCoverImageId: number | undefined;
            newCoverImageId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { listingId, prevCoverImageId, newCoverImageId, runSuccess } = requestData;

        try {
            if (prevCoverImageId)
                await axiosServices.patch(PROPERTY_LISTING_APIS.updatePropertyListingCoverImage(prevCoverImageId), {
                    listingId,
                    type: ImageType.propertyImage
                });
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updatePropertyListingCoverImage(newCoverImageId), {
                listingId,
                type: ImageType.coverImage
            });
            if (runSuccess) runSuccess();
            return { newCoverImageId, prevCoverImageId, sectionStatus: successRes.data.sectionStatus };
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
