// project import

// types
import { NavItemType } from 'types';
import Cils from './Cils';
import commissionsItem from './commissionsItem';
import commonItemsBottom from './common-bottom.items';
import commonItemsTop from './common-top.items';
import DevelopersContactsItem from './DevelopersContacts.items';
import DevelopersItem from './DevelopersItem';
import eRealtyItem from './eRealty';
import { featureItems } from './Features';
import flowsItem from './flow';
import { InventoryItemsGroup } from './Inventory.items';
import invitationItemsGroup, { invitationItemsCollapse, invitationItemsFreelancerGroup } from './Invitations';
import launchesItem from './launches';
import moreCollapse, { leadRequestItemsInternalAgentsGroup } from './more';
import notificationsItemsCollapse from './Notifications.items';
import ActivitiesItem from './orgActivities';
import PartnerItems from './orgPartnersItem';
import reportsCollapse from './reports';
import Sales from './Sales';
import settingsItemsCollapse, { settingsItemsGroup } from './Settings.items';
import userItemsCollapse, { userItemsGroup } from './Users.items';
import supportItem from './whatsappSupportItem';
import GrowWithPartnersItem from './GrowWithPartners';
import dailyInsightsItem from './DailyInsights';
import topSellingCompoundsItem from './topSellingCompounds';

// ==============================|| MENU ITEMS ||============================== //
const menuItems: { [item: string]: NavItemType[] } = {
    commonItemsTop: [commonItemsTop],
    items: [
        GrowWithPartnersItem,
        InventoryItemsGroup,
        topSellingCompoundsItem,
        // featuresCollapse,
        eRealtyItem,
        flowsItem,
        dailyInsightsItem,
        Sales,
        Cils,
        commissionsItem,
        DevelopersContactsItem,
        launchesItem,
        PartnerItems,
        // OrganizationsItemsGroup,
        userItemsCollapse,
        ActivitiesItem,
        reportsCollapse,
        // invitationItems,
        invitationItemsCollapse,
        invitationItemsFreelancerGroup,
        invitationItemsGroup,
        DevelopersItem,
        featureItems,
        userItemsGroup,
        // CrmControlItem,
        settingsItemsGroup,
        settingsItemsCollapse,
        notificationsItemsCollapse,
        leadRequestItemsInternalAgentsGroup,
        supportItem,
        moreCollapse
    ],
    commonItemsBottom: [commonItemsBottom]
};

export default menuItems;
