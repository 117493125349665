import { Props } from 'react-apexcharts';
import { ChartDataInterface } from './vertical-config';

const horizontalBarConfig = ({ categories, seriesData, id = 'bar-chart' }: ChartDataInterface): Props => {
    const chartData: Props = {
        height: 75 * seriesData[0].data.length >= 400 ? 75 * seriesData[0].data.length : 400,
        type: 'bar',
        options: {
            noData: {
                text: 'No Data Available'
            },
            colors: ['#165db2'],
            chart: {
                id,
                stacked: true,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '80%',
                    dataLabels: {
                        total: {
                            enabled: true,
                            offsetY: 5,
                            formatter(val, opts) {
                                return Intl.NumberFormat('en', { notation: 'compact' }).format(parseInt(val || '', 10));
                            },
                            style: {
                                fontSize: '13px',
                                fontWeight: 900,
                                color: '#212121'
                            }
                        }
                    }
                }
            },
            xaxis: {
                max: Math.max(...seriesData[0].data) * 1.1,
                type: 'category',
                categories,
                position: 'top',
                tickAmount: 5,
                labels: {
                    formatter(val, opts) {
                        return Intl.NumberFormat('en', { notation: 'compact' }).format(parseInt(val, 10));
                    },

                    style: {
                        fontSize: '12px',
                        fontFamily: `'Poppins', sans-serif`,
                        colors: '#9E9E9E'
                    }
                }
            },
            yaxis: {
                labels: {
                    maxWidth: 85,

                    style: {
                        fontFamily: `'Poppins', sans-serif`
                    }
                }
            },
            legend: {
                show: false,
                fontFamily: `'Poppins', sans-serif`,
                position: 'bottom',
                labels: {
                    useSeriesColors: false
                },
                markers: {
                    width: 16,
                    height: 16,
                    radius: 5
                },
                itemMargin: {
                    horizontal: 8,
                    vertical: 8
                }
            },
            fill: {
                type: 'solid'
            },
            dataLabels: {
                enabled: false,
                formatter(val, _opts) {
                    return val.toLocaleString(undefined, { maximumFractionDigits: 2 });
                }
            },
            grid: {
                show: false
            },

            tooltip: {
                shared: true,
                intersect: false,
                style: {
                    fontFamily: 'Poppins'
                },
                y: [
                    {
                        formatter(val, opts) {
                            return `${Intl.NumberFormat('en', { notation: 'compact' }).format(val)}`;
                        }
                    },
                    {
                        formatter(val, opts) {
                            return `${val} units`;
                        }
                    }
                ]
            }
        },
        series: seriesData
    };
    return chartData;
};
export default horizontalBarConfig;
