import { CommonListInterface, CommonQueryInterface } from 'store/interfaces';
import { FlowLeadInterface, FlowStageInterface, FlowStageStatusInterface } from '../flows/interfaces';
import { BuyersListInterface } from '../saleClaims/interfaces';
import { OrganizationInterface } from '../organizations/interfaces';

export interface CilInterface {
    id: number;
    leadId: number;
    crmLeadId?: number;
    lead: FlowLeadInterface;
    phone: string;
    name: string;
    comment?: string;
    brokerId: number;
    activePrimary: boolean;
    broker: {
        organizationId: string;
        cognitoId: string;
        user: {
            name: string;
        };
        organization: {
            name: string;
        };
    };
    rejectedReason?: RejectedReasonInterface;
    renewalAttempts?: RenewalAttemptInterface[];
    status: CilStatusEnum;
    createdAt: string;
    updatedAt: string;
    statuses?: Partial<FlowStageStatusInterface>[];
    stages?: FlowStageInterface[];
    buyers?: BuyersListInterface[];
    organization?: OrganizationInterface;
}

export enum CilStatusEnum {
    accepted = 'Accepted',
    rejected = 'Rejected'
}

export interface RejectedReasonInterface {
    id: number;
    leadStatus: string;
    validityTime: string;
    cilId: number;
    createdAt: string;
    updatedAt: string;
}

export interface RenewalAttemptInterface {
    id: number;
    cilId: number;
    renewedById: string;
    renewedBy?: {
        name: string;
    };
    createdAt: Date;
    updatedAt: Date;
}

export interface CilsInitialStateInterface {
    loading: boolean;
    error: any;
    listLoading: boolean;
    listError: any;
    cils: CilsList;
    cil?: CilInterface;
    lead?: LeadInterface;
    analytica: CilsAnalyticaInterface;
    analyticaLoading: boolean;
    analyticaError: any;
}

export enum LeadTypeEnum {
    primary = 'Primary',
    financing = 'Financing'
}
export interface LeadInterface {
    id?: number;
    phone?: string;
    validityTime?: string;
    category?: string;
    status?: string;
    type?: LeadTypeEnum;
    remainingTime?: number;
    partnerId?: string;
    active?: boolean;
}

interface CilsList extends CommonListInterface {
    data: CilInterface[];
}

export interface QueryCilsInterface extends CommonQueryInterface {
    id?: string;
    phone?: string;
    leadPhone?: string;
    leadId?: string;
    crmLeadId?: string;
    status?: CilStatusEnum;
    organizationId?: string;
    createdById?: string;
    organizationName?: string;
    accountManagerId?: string;
    brokerId?: string;
    brokerName?: string;
}

export interface CilsAnalyticaInterface {
    total: number;
    accepted: number;
    rejected: number;
}
