import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const DevelopersPage = Loadable(lazy(() => import('views/developers')));

// ==============================|| ALLOWED GROUPS ROUTING ||============================== //

// const allowedGroups = [InvitationTypeEnum.admins, InvitationTypeEnum.operationManager, InvitationTypeEnum.developerRelationsManagers];

// ==============================|| CILs ROUTING ||============================== //

const DevelopersRoutes = {
    path: '/developers',
    element: (
        <RoleGuard
            groups={[
                { name: InvitationTypeEnum.admins },
                { name: InvitationTypeEnum.operationManager },
                { name: InvitationTypeEnum.developerRelationsManagers }
            ]}
            ifNotAllowed={<Navigate to="/home" replace />}
        >
            <MainLayout />
        </RoleGuard>
    ),
    children: [
        {
            path: '',
            element: <DevelopersPage />
        }
    ]
};

export default DevelopersRoutes;
