import { ChartDataInterface } from './vertical-config';
import { IntlShape } from 'react-intl';

const constructBarChartData = (data: any[], intl: IntlShape, title: string, limit = 5): ChartDataInterface => ({
    categories: data.map((entity) => entity.name).splice(0, limit),
    seriesData: [
        {
            name: intl.formatMessage({ id: 'total-unit-value' }),
            data: data.map((entity) => entity.totalUnitValue).splice(0, limit)
        },
        {
            name: intl.formatMessage({ id: 'unit-count' }),
            data: data
                .map((entity) => {
                    if (entity?.count) return entity?.count;
                    return entity?.totalCount;
                })
                .splice(0, limit)
        }
    ],
    title,
    id: `sales-pipe-line-top-${title}`
});
export default constructBarChartData;
