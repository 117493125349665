import { FileBarChartIcon } from 'lucide-react';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { NavItemType } from 'types';

const topSellingCompounds: NavItemType = {
    id: 'top-selling-compounds',
    title: <FormattedMessage id="top-selling-compounds" />,
    type: 'item',
    url: '/top-selling-compounds',
    icon: () => <FileBarChartIcon />,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'top-selling-compounds'
    },
    allowedAudience: [
        {
            name: InvitationTypeEnum.brokers,
            tiers: ['b60671f6-031d-4242-8dd3-cdcb57c1cbc5', '8275b93f-3ea5-4531-84a5-884911297fcd'],
            roles: [OrganizationRole.owner]
        },
        { name: InvitationTypeEnum.accountManagers }
    ]
};

const topSellingCompoundsItem: NavItemType = {
    id: 'top-selling-compounds',
    type: 'group',
    children: [topSellingCompounds]
};

export default topSellingCompoundsItem;
