import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Listings routing
const ListingPage = Loadable(lazy(() => import('views/listings/PropertyListing')));
const DraftListingPage = Loadable(lazy(() => import('views/listings/PropertyListing/CreateNewProperty')));
const DetailsListingPage = Loadable(lazy(() => import('views/listings/Details')));
const ReviewPage = Loadable(lazy(() => import('views/listings/Review')));
const RejectionReasonsPage = Loadable(lazy(() => import('views/settings/ListingsRejectionReasons')));

// ==============================|| ALLOWED GROUPS ROUTING ||============================== //

// const allowedGroups = [InvitationTypeEnum.brokers, InvitationTypeEnum.accountManagers, InvitationTypeEnum.inventoryManagers];

// ==============================|| CILs ROUTING ||============================== //

const ListingsRoutes = {
    path: '/listings',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.brokers },
                    { name: InvitationTypeEnum.accountManagers },
                    { name: InvitationTypeEnum.inventoryManagers },
                    { name: InvitationTypeEnum.admins }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <ListingPage />
        },
        {
            path: ':id/draft',
            element: <DraftListingPage />
        },
        {
            path: ':id/details',
            element: <DetailsListingPage />
        },
        {
            path: ':id/review',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.inventoryManagers }, { name: InvitationTypeEnum.admins }]}
                >
                    <ReviewPage />
                </RoleGuard>
            )
        },
        {
            path: 'rejection-reasons',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.inventoryManagers }, { name: InvitationTypeEnum.admins }]}
                >
                    <RejectionReasonsPage />
                </RoleGuard>
            )
        }
    ]
};

export default ListingsRoutes;
