import { FAUNA_API } from 'store/apis';
import { QueryDeveloperContactInterface } from './interfaces';

const DEVELOPER_CONTACTS_COLLECTION = 'developers/contacts';

const DEVELOPER_CONTACT_APIS = {
    create: () => `${FAUNA_API}${DEVELOPER_CONTACTS_COLLECTION}`,
    query: (query: QueryDeveloperContactInterface) =>
        `${FAUNA_API}${DEVELOPER_CONTACTS_COLLECTION}/query?${new URLSearchParams(query as URLSearchParams).toString()}`,
    getContactById: (id: string) => `${FAUNA_API}${DEVELOPER_CONTACTS_COLLECTION}/${id}`,
    update: (developerId: number) => `${FAUNA_API}${DEVELOPER_CONTACTS_COLLECTION}/${developerId}`,
    delete: (contactId: string) => `${FAUNA_API}${DEVELOPER_CONTACTS_COLLECTION}/${contactId}`
};

export default DEVELOPER_CONTACT_APIS;
