import { createSlice } from '@reduxjs/toolkit';
import { queryInventoryControl, updateCompound, updateDeveloperConfigs } from './actions';
import { InventoryControlInitialStateInterface } from './interfaces';

const initialState: InventoryControlInitialStateInterface = {
    developers: [],
    loading: false,
    refresh: false,
    numberOfDevelopers: 0,
    error: null
};

const inventoryControl = createSlice({
    name: 'inventoryControl',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryInventoryControl.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryInventoryControl.fulfilled, (state, action) => {
                state.loading = false;
                state.developers = action.payload.developers;
                state.numberOfDevelopers = action.payload.total_count;
            })
            .addCase(queryInventoryControl.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateDeveloperConfigs.pending, (state) => {
                // state.loading = true;
                state.error = false;
            })
            .addCase(updateDeveloperConfigs.fulfilled, (state, action) => {
                state.loading = false;
                // state.developers = action.payload;
                state.refresh = !state.refresh;
            })
            .addCase(updateDeveloperConfigs.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateCompound.pending, (state) => {
                // state.loading = true;
                state.error = false;
            })
            .addCase(updateCompound.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
                // state.developers = action.payload;
            })
            .addCase(updateCompound.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default inventoryControl.reducer;
