import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';
import TopSellingCompoundsIframe from 'views/common/TopSellingCompounds';

const TopSellingCompoundsRoutes = {
    path: '/top-selling-compounds',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <TopSellingCompoundsIframe />
        }
    ]
};

export default TopSellingCompoundsRoutes;
