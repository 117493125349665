import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InventoryTableInitialState } from './interfaces';

const inventoryTable = createSlice({
    name: 'inventoryTable',
    initialState: {} as InventoryTableInitialState,
    reducers: {
        updateSelectedCompound(state, action: PayloadAction<{ compound_id?: number }>) {
            state.selected_compound_id = action.payload?.compound_id;
            state.selected_property_id = undefined;
            state.selected_property_type_id = undefined;
        },
        updateSelectedPropertyType(state, action: PayloadAction<{ property_type_id?: number }>) {
            state.selected_property_type_id = action.payload?.property_type_id;
            state.selected_property_id = undefined;
        },
        updateSelectedProperty(state, action: PayloadAction<{ property_id?: number }>) {
            state.selected_property_id = action.payload?.property_id;
        }
    }
});

export const { updateSelectedCompound, updateSelectedPropertyType, updateSelectedProperty } = inventoryTable.actions;

export default inventoryTable.reducer;
