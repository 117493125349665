import { Typography, Grid } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import developer1 from 'assets/images/developer1.png';
import developer2 from 'assets/images/developer2.png';
import developer3 from 'assets/images/developer3.png';
import developer4 from 'assets/images/developer4.png';
import developer5 from 'assets/images/developer5.png';
import developer6 from 'assets/images/developer6.png';
import developer7 from 'assets/images/developer7.png';
import developer8 from 'assets/images/developer8.png';
import developer9 from 'assets/images/developer9.png';
import developer10 from 'assets/images/developer10.png';
import developer11 from 'assets/images/developer11.png';
import developer12 from 'assets/images/developer12.png';
import developer13 from 'assets/images/developer13.png';
import developer14 from 'assets/images/developer14.png';
import developer15 from 'assets/images/developer15.png';
import developer16 from 'assets/images/developer16.png';
import { FormattedMessage } from 'react-intl';

function NetworkSection() {
    const devImages = [
        {
            image: developer1
        },
        {
            image: developer2
        },
        {
            image: developer3
        },
        {
            image: developer4
        },
        {
            image: developer5
        },
        {
            image: developer6
        },
        {
            image: developer7
        },
        {
            image: developer8
        },
        {
            image: developer9
        },
        {
            image: developer10
        },
        {
            image: developer11
        },
        {
            image: developer12
        },
        {
            image: developer13
        },
        {
            image: developer14
        },
        {
            image: developer15
        },
        {
            image: developer16
        }
    ];

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={2} sx={{ backgroundColor: 'white' }} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'White' }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        color="primary.600"
                        fontSize={isSmallScreen ? '22px' : '32px'}
                        fontWeight={700}
                        paddingBottom="24px"
                        marginTop={2}
                        marginBottom={0}
                        align="center"
                    >
                        <FormattedMessage id="nawy-s-Network" />
                    </Typography>
                </Box>
            </Grid>

            <Grid container maxWidth="1200px" justifyContent="center" justifyItems="center" marginLeft={isSmallScreen ? 1 : 0}>
                {devImages.map((dev) => (
                    <Grid item key={dev.image} justifyContent="center" alignItems="center">
                        <Box
                            style={{
                                width: isSmallScreen ? '80px' : '112px',
                                height: isSmallScreen ? '80px' : '112px'
                            }}
                        >
                            <img src={dev.image} alt="developers" style={{ width: '100%', height: '100%' }} />
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}
export default NetworkSection;
