// types
import { createSlice } from '@reduxjs/toolkit';

import { FlowAnalyticaFull } from '../flows/interfaces';
import {
    approveSaleClaim,
    createSaleClaimComment,
    deleteSaleClaimComment,
    deleteSaleClaimDocument,
    getSaleClaimById,
    getSaleClaimComments,
    getSaleClaimsAnalytics,
    queryBuyers,
    querySaleClaim,
    rejectSaleClaim,
    updateSaleClaim,
    updateSaleClaimAdmin,
    updateSaleClaimBuyer,
    updateSaleClaimComment,
    updateSaleClaimLinks,
    updateSaleClaimProperty,
    updateSaleClaimStatus,
    updateSaleCommissionType,
    uploadSaleClaimDocument
} from './actions';
import { SaleClaimsInitialState } from './interfaces';

// initial state
const initialState: SaleClaimsInitialState = {
    loading: false,
    listLoading: false,
    updateSaleClaimLoading: false,
    updateSaleClaimBuyerLoading: false,
    updateSaleClaimPropertyLoading: false,
    updateSaleClaimStatusLoading: false,
    createCommentLoading: false,
    updateCommentLoading: false,
    deleteCommentLoading: false,
    uploadSaleClaimDocumentLoading: false,
    deleteSaleClaimDocumentLoading: false,
    updateSaleCommissionTypeLoading: false,
    analyticsLoading: false,
    analyticsError: false,
    analytica: {
        approved: 0,
        preApproved: 0,
        pending: 0,
        rejected: 0,
        noAction: 0,
        sumApproved: 0,
        sumInprogress: 0,
        total: 0
    },
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    refreshSaleClaimComments: false,
    saleClaims: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    loadingBuyers: false,
    buyers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - SALE CLAIMS ||============================== //

const saleClaims = createSlice({
    name: 'saleClaims',
    initialState,
    reducers: {
        refreshSaleClaimsList: (state) => {
            state.listRefresh = !state.listRefresh;
        },
        refreshSaleClaim: (state) => {
            state.refresh = !state.refresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(querySaleClaim.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(querySaleClaim.fulfilled, (state, action) => {
                state.listLoading = false;
                state.saleClaims = action.payload;
            })
            .addCase(querySaleClaim.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getSaleClaimById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getSaleClaimById.fulfilled, (state, action) => {
                state.loading = false;
                state.saleClaim = action.payload.saleClaim;
                state.sale = action.payload.sale;
            })
            .addCase(getSaleClaimById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaim.pending, (state, action) => {
                state.updateSaleClaimLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaim.rejected, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaim.fulfilled, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateSaleClaimAdmin.pending, (state, action) => {
                state.updateSaleClaimLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimAdmin.rejected, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaimAdmin.fulfilled, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateSaleClaimLinks.pending, (state, action) => {
                state.updateSaleClaimLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimLinks.rejected, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaimLinks.fulfilled, (state, action) => {
                state.updateSaleClaimLoading = false;
                state.refresh = !state.refresh;
            })

            .addCase(getSaleClaimComments.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getSaleClaimComments.fulfilled, (state, action) => {
                state.loading = false;
                state.comments = action.payload;
            })
            .addCase(getSaleClaimComments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createSaleClaimComment.pending, (state) => {
                state.createCommentLoading = true;
                state.error = false;
            })
            .addCase(createSaleClaimComment.rejected, (state, action) => {
                state.createCommentLoading = false;
                state.error = action.payload;
            })
            .addCase(createSaleClaimComment.fulfilled, (state) => {
                state.createCommentLoading = false;
                state.refreshSaleClaimComments = !state.refreshSaleClaimComments;
            })
            .addCase(updateSaleClaimComment.pending, (state) => {
                state.updateCommentLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimComment.fulfilled, (state, action) => {
                state.updateCommentLoading = false;
                state.refreshSaleClaimComments = !state.refreshSaleClaimComments;
            })
            .addCase(updateSaleClaimComment.rejected, (state, action) => {
                state.updateCommentLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSaleClaimComment.pending, (state) => {
                state.deleteCommentLoading = true;
                state.error = false;
            })
            .addCase(deleteSaleClaimComment.rejected, (state, action) => {
                state.deleteCommentLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSaleClaimComment.fulfilled, (state) => {
                state.deleteCommentLoading = false;
                state.refreshSaleClaimComments = true;
            })
            .addCase(queryBuyers.fulfilled, (state, action) => {
                state.loadingBuyers = false;
                state.buyers = action.payload;
            })

            .addCase(queryBuyers.pending, (state) => {
                state.loadingBuyers = true;
            })
            .addCase(queryBuyers.rejected, (state, action) => {
                state.loadingBuyers = false;
            })
            .addCase(updateSaleClaimBuyer.pending, (state) => {
                state.updateSaleClaimBuyerLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimBuyer.rejected, (state, action) => {
                state.updateSaleClaimBuyerLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaimBuyer.fulfilled, (state) => {
                state.updateSaleClaimBuyerLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateSaleClaimProperty.pending, (state) => {
                state.updateSaleClaimPropertyLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimProperty.rejected, (state, action) => {
                state.updateSaleClaimPropertyLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaimProperty.fulfilled, (state) => {
                state.updateSaleClaimPropertyLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(approveSaleClaim.pending, (state) => {
                state.updateSaleClaimStatusLoading = true;
                state.error = false;
            })
            .addCase(approveSaleClaim.rejected, (state, action) => {
                state.updateSaleClaimStatusLoading = false;
                state.error = action.error;
            })
            .addCase(approveSaleClaim.fulfilled, (state) => {
                state.updateSaleClaimStatusLoading = false;
                state.refresh = !state.refresh;
            })

            .addCase(updateSaleCommissionType.pending, (state) => {
                state.updateSaleCommissionTypeLoading = true;
                state.error = false;
            })
            .addCase(updateSaleCommissionType.rejected, (state, action) => {
                state.updateSaleCommissionTypeLoading = false;
                state.error = action.error;
            })
            .addCase(updateSaleCommissionType.fulfilled, (state) => {
                state.updateSaleCommissionTypeLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(rejectSaleClaim.pending, (state) => {
                state.updateSaleClaimStatusLoading = true;
                state.error = false;
            })
            .addCase(rejectSaleClaim.rejected, (state, action) => {
                state.updateSaleClaimStatusLoading = false;
                state.error = action.payload;
            })
            .addCase(rejectSaleClaim.fulfilled, (state) => {
                state.updateSaleClaimStatusLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateSaleClaimStatus.pending, (state) => {
                state.updateSaleClaimStatusLoading = true;
                state.error = false;
            })
            .addCase(updateSaleClaimStatus.rejected, (state, action) => {
                state.updateSaleClaimStatusLoading = false;
                state.error = action.payload;
            })
            .addCase(updateSaleClaimStatus.fulfilled, (state) => {
                state.updateSaleClaimStatusLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(uploadSaleClaimDocument.pending, (state) => {
                state.uploadSaleClaimDocumentLoading = true;
                state.error = false;
            })
            .addCase(uploadSaleClaimDocument.rejected, (state, action) => {
                state.uploadSaleClaimDocumentLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadSaleClaimDocument.fulfilled, (state) => {
                state.uploadSaleClaimDocumentLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteSaleClaimDocument.pending, (state) => {
                state.deleteSaleClaimDocumentLoading = true;
                state.error = false;
            })
            .addCase(deleteSaleClaimDocument.rejected, (state, action) => {
                state.deleteSaleClaimDocumentLoading = false;
                state.error = action.payload;
            })
            .addCase(deleteSaleClaimDocument.fulfilled, (state) => {
                state.deleteSaleClaimDocumentLoading = false;
                state.refresh = !state.refresh;
            })
            .addCase(getSaleClaimsAnalytics.pending, (state) => {
                state.analyticsLoading = true;
                state.analyticsError = false;
            })
            .addCase(getSaleClaimsAnalytics.rejected, (state, action) => {
                state.analyticsLoading = false;
                state.analyticsError = action.payload;
            })
            .addCase(getSaleClaimsAnalytics.fulfilled, (state, action) => {
                const data = action.payload as FlowAnalyticaFull;
                state.analyticsLoading = false;
                state.analytica = {
                    approved: data.Approved,
                    noAction: data.NoAction,
                    pending: data.Pending,
                    preApproved: data.PreApproved,
                    rejected: data.Rejected,
                    sumApproved: data.sumApproved,
                    sumInprogress: data.sumInprogress,
                    total: data.count
                };
            })
            .addDefaultCase((state, action) => {});
    }
});

export default saleClaims.reducer;

export const { refreshSaleClaimsList, refreshSaleClaim } = saleClaims.actions;
