import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { useSelector } from 'store';
import { queryTiers } from 'store/slices/tiers/actions';
import { TierInterface } from 'store/slices/tiers/interfaces';

interface TiersFilterProps {
    defaultValueId?: string;
    value: string;
    handleSearch: (newValue: string) => void;
    handleSelectedTier?: (tier: TierInterface | null | undefined) => void;
}

const TiersFilter = ({ defaultValueId, value, handleSearch, handleSelectedTier }: TiersFilterProps) => (
    <Filter defaultValueId={defaultValueId} value={value} handleSearch={handleSearch} handleSelectedTier={handleSelectedTier} />
);

const Filter = ({ defaultValueId, value, handleSearch, handleSelectedTier }: TiersFilterProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { tiers, loading } = useSelector((state) => state.tiers);

    const [newValue, setNewValue] = useState<string>('');
    const [selectedTier, setSelectedTier] = useState<TierInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(queryTiers({ queryData: { name: defaultValueId } }));
        setNewValue(name);
    };

    const handleOnChange = (tier: TierInterface | null) => {
        setSelectedTier(tier);
        handleSearch(tier?.id || '');
        if (handleSelectedTier) handleSelectedTier(tier);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(queryTiers({ queryData: { id: defaultValueId } }));
        } else {
            dispatch(queryTiers({ queryData: { id: newValue } }));
        }
    }, [defaultValueId, newValue, dispatch]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && tiers.data.length > 0) {
            firstRender.current = false;
            const tier = tiers.data.find((c) => c.id === defaultValueId) ?? null;
            setSelectedTier(tier);
            if (handleSelectedTier) handleSelectedTier(tier);
        }
    }, [tiers, defaultValueId, handleSelectedTier]);

    return (
        <Autocomplete
            id="autocomplete-generic-filter-tier"
            fullWidth
            size="small"
            options={tiers.data}
            loading={loading}
            value={selectedTier}
            onChange={(_, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={(_, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option) => option?.id === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-tier"
                    label={intl.formatMessage({ id: 'tiers' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default TiersFilter;
