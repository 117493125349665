import { createSlice } from '@reduxjs/toolkit';
import { querySalesAgents, moveSalesAgent } from './actions';
import { InitialSalesAgentsInterface } from './interfaces';

// initial state
const initialState: InitialSalesAgentsInterface = {
    error: null,
    loading: false,
    refresh: false,
    salesAgents: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE - Lead Requests ||============================== //

const salesAgents = createSlice({
    name: 'salesAgents',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(querySalesAgents.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(querySalesAgents.fulfilled, (state, action) => {
                state.loading = false;
                state.salesAgents = action.payload;
            })
            .addCase(querySalesAgents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(moveSalesAgent.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(moveSalesAgent.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(moveSalesAgent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default salesAgents.reducer;
