import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, Typography } from '@mui/material';

// assets
import useConfig from 'hooks/useConfig';
import useMixPanel from 'hooks/useMixPanel';

// ==============================|| LOCALIZATION ||============================== //

const LocalizationSection = () => {
    const { locale, onChangeLocale } = useConfig();
    const { emitEvent } = useMixPanel();
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef<any>(null);
    const [language, setLanguage] = useState<string>(locale);

    const handleClick = (lng: string) => {
        emitEvent('Click on language switch landing page and become partner page', { lng });
        setLanguage(lng);
        onChangeLocale(lng);
        setOpen(false);
        window.location.reload();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        setLanguage(locale);
    }, [locale]);

    return (
        <>
            <Box>
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        border: '1px solid',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: theme.palette.primary.dark,
                        transition: 'all .2s ease-in-out',
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            borderColor: theme.palette.primary.main,
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    {language === 'en' ? (
                        <Button ref={anchorRef} onClick={() => handleClick('ar')}>
                            <Typography
                                variant="h5"
                                color="inherit"
                                fontSize="16px"
                                justifyContent="center"
                                fontFamily={theme.typography.fontFamily}
                            >
                                ع
                            </Typography>
                        </Button>
                    ) : (
                        <Button ref={anchorRef} onClick={() => handleClick('en')}>
                            <Typography variant="h5" color="inherit" fontSize="16px" fontFamily={theme.typography.fontFamily}>
                                EN
                            </Typography>
                        </Button>
                    )}
                </Avatar>
            </Box>
        </>
    );
};

export default LocalizationSection;
