import { Menu, MenuItem } from '@mui/material';
import Chip from 'ui-component/extended/Chip';
import { useIntl } from 'react-intl';
import { dispatch } from 'store';
import { useState } from 'react';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // Import the arrow icon
import { updateCRM } from 'store/slices/crmControl/actions';
import { useTheme } from '@mui/styles';

export default function UpdateCRMDisabledStatus({ crm, option }: { crm: CRMControlInterface; option: 'viewInventory' | 'disabled' }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const intl = useIntl();

    const { id, viewInventory, disabled } = crm;
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'status-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const handleStatusChange = (newStatus: any) => {
        dispatch(
            updateCRM({ crmId: id, updateData: option === 'disabled' ? { disabled: newStatus } : { viewInventory: newStatus }, runSuccess })
        );
    };

    return (
        <>
            <Chip
                label={option === 'viewInventory' ? (viewInventory ? 'Yes' : 'No') : disabled ? 'Deactivated' : 'Active'}
                variant="outlined"
                size="medium"
                sx={{ width: '80px', '& .MuiChip-avatar': { color: theme.palette.primary.dark } }}
                onClick={handleClick}
                avatar={<ExpandMoreIcon />}
                chipcolor={option === 'disabled' ? (!disabled ? 'success' : 'error') : 'secondary'}
            />
            {option === 'viewInventory' ? (
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem
                        sx={{
                            minWidth: '83px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        key="yes"
                        onClick={() => handleStatusChange(true)}
                    >
                        {intl.formatMessage({ id: 'yes' })}
                    </MenuItem>
                    <MenuItem
                        sx={{
                            minWidth: '83px',
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                        key="no"
                        onClick={() => handleStatusChange(false)}
                    >
                        {intl.formatMessage({ id: 'no' })}
                    </MenuItem>
                </Menu>
            ) : (
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem key="active" onClick={() => handleStatusChange(true)}>
                        {intl.formatMessage({ id: 'active' })}
                    </MenuItem>
                    <MenuItem key="deactivated" onClick={() => handleStatusChange(false)}>
                        {intl.formatMessage({ id: 'deactivated' })}
                    </MenuItem>
                </Menu>
            )}
        </>
    );
}
