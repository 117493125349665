import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Button, Drawer, Fab, Grid, TablePagination, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { appDrawerWidth, gridSpacing } from 'store/constant';
import { getAllAreas, getAllCompounds, getDevelopers, getFilterOptions, getPropertyTypes } from 'store/slices/general/actions';
import { updateSelectedCompound } from 'store/slices/inventoryTable';
import { InventoryQueryInterface } from 'store/slices/inventoryTable/interfaces';
import { openDrawer } from 'store/slices/menu';
import { getCompoundDetails, getProperties, getPropertyDetails, getPropertyTypesDetails } from 'store/slices/nawyInventory/actions';
import { openSnackbar } from 'store/slices/snackbar';
import MainComponent from 'ui-component/MainComponent';
import MainCard from 'ui-component/cards/MainCard';
import CompoundsList from './CompoundsList';
import InventoryFilterView from './InventoryFilterView';
import InventoryFilters from './InventoryFilters';

const NawyInventoryPage = () => {
    const dispatch = useDispatch();
    const { borderRadius } = useConfig();
    const { emitEvent } = useMixPanel();
    const intl = useIntl();
    const [searchParams, setSearchParams] = useSearchParams();
    const { areas, developers, compounds, filterOptions, propertyTypes } = useSelector((state) => state.general);

    const initialState: InventoryQueryInterface = {
        page: +(searchParams.get('page') || '1') - 1,
        page_size: +(searchParams.get('page_size') || '10'),
        compounds_ids: searchParams.get('compounds_ids') ? searchParams.get('compounds_ids')?.split(',').map(Number) : undefined,
        finishing: searchParams.get('finishing') ? searchParams.get('finishing')?.split(',') : undefined,
        business_types: searchParams.get('business_types') ? searchParams.get('business_types')?.split(',') : undefined,
        property_types_ids: searchParams.get('property_types_ids')
            ? searchParams.get('property_types_ids')?.split(',').map(Number)
            : undefined,
        areas_ids: searchParams.get('areas_ids') ? searchParams.get('areas_ids')?.split(',').map(Number) : undefined,
        min_bedrooms: Number(searchParams.get('min_bedrooms')) || undefined,
        max_bedrooms: Number(searchParams.get('max_bedrooms')) || undefined,
        min_area: Number(searchParams.get('min_area')) || undefined,
        max_area: Number(searchParams.get('max_area')) || undefined,
        min_price: Number(searchParams.get('min_price')) || undefined,
        max_price: Number(searchParams.get('max_price')) || undefined,
        delivery_date: searchParams.get('delivery_date') || undefined,
        orderBy: searchParams.get('orderBy') || undefined,
        min_garden_area: Number(searchParams.get('min_garden_area')) || undefined,
        max_garden_area: Number(searchParams.get('max_garden_area')) || undefined,
        min_land_area: Number(searchParams.get('min_land_area')) || undefined,
        max_land_area: Number(searchParams.get('max_land_area')) || undefined,
        developers_ids: searchParams.get('developers_ids') ? searchParams.get('developers_ids')?.split(',').map(Number) : undefined
    };
    const { numberOfCompounds, inventoryError } = useSelector((state) => state.nawyInventory);
    const [filter, setFilter] = useState(initialState);
    const theme = useTheme();

    const handleFilter = (type: string, params: InventoryQueryInterface) => {
        switch (type) {
            case 'areas_ids':
                if (params.areas_ids) {
                    searchParams.set('areas_ids', params.areas_ids.toString());
                } else {
                    searchParams.delete('areas_ids');
                }

                setFilter({ ...filter, areas_ids: params.areas_ids, page: 0 });
                break;
            case 'compounds_ids':
                if (params.compounds_ids) {
                    searchParams.set('compounds_ids', params.compounds_ids.toString());
                } else {
                    searchParams.delete('compounds_ids');
                }
                setFilter({ ...filter, compounds_ids: params.compounds_ids, page: 0 });
                break;
            case 'property_types_ids':
                if (params.property_types_ids) {
                    searchParams.set('property_types_ids', params.property_types_ids.toString());
                } else {
                    searchParams.delete('property_types_ids');
                }
                setFilter({ ...filter, property_types_ids: params.property_types_ids, page: 0 });
                break;
            case 'bedrooms':
                if (params.min_bedrooms) {
                    searchParams.set('min_bedrooms', params.min_bedrooms.toString());
                } else {
                    searchParams.delete('min_bedrooms');
                }
                if (params.max_bedrooms) {
                    searchParams.set('max_bedrooms', params.max_bedrooms.toString());
                } else {
                    searchParams.delete('max_bedrooms');
                }
                setFilter({ ...filter, min_bedrooms: params.min_bedrooms, max_bedrooms: params.max_bedrooms, page: 0 });
                break;
            case 'price':
                if (params.min_price) {
                    searchParams.set('min_price', params.min_price.toString());
                } else {
                    searchParams.delete('min_price');
                }
                if (params.max_price) {
                    searchParams.set('max_price', params.max_price.toString());
                } else {
                    searchParams.delete('max_price');
                }
                setFilter({ ...filter, min_price: params.min_price, max_price: params.max_price, page: 0 });
                break;

            case 'down_payment':
                if (params.min_down_payment) {
                    searchParams.set('min_down_payment', params.min_down_payment.toString());
                } else {
                    searchParams.delete('min_down_payment');
                }
                if (params.max_down_payment) {
                    searchParams.set('max_down_payment', params.max_down_payment.toString());
                } else {
                    searchParams.delete('max_down_payment');
                }
                setFilter({ ...filter, min_down_payment: params.min_down_payment, max_down_payment: params.max_down_payment, page: 0 });
                break;
            case 'installments':
                if (params.min_installments) {
                    searchParams.set('min_installments', params.min_installments.toString());
                } else {
                    searchParams.delete('min_installments');
                }
                if (params.max_installments) {
                    searchParams.set('max_installments', params.max_installments.toString());
                } else {
                    searchParams.delete('max_installments');
                }
                setFilter({ ...filter, min_installments: params.min_installments, max_installments: params.max_installments, page: 0 });
                break;
            case 'area':
                if (params.min_area) {
                    searchParams.set('min_area', params.min_area.toString());
                } else {
                    searchParams.delete('min_area');
                }
                if (params.max_area) {
                    searchParams.set('max_area', params.max_area.toString());
                } else {
                    searchParams.delete('max_area');
                }
                setFilter({ ...filter, min_area: params.min_area, max_area: params.max_area, page: 0 });
                break;

            case 'garden_area':
                if (params.min_garden_area) {
                    searchParams.set('min_garden_area', params.min_garden_area.toString());
                } else {
                    searchParams.delete('min_garden_area');
                }
                if (params.max_garden_area) {
                    searchParams.set('max_garden_area', params.max_garden_area.toString());
                } else {
                    searchParams.delete('max_garden_area');
                }
                setFilter({ ...filter, min_garden_area: params.min_garden_area, max_garden_area: params.max_garden_area, page: 0 });
                break;

            case 'land_area':
                if (params.min_land_area) {
                    searchParams.set('min_land_area', params.min_land_area.toString());
                } else {
                    searchParams.delete('min_land_area');
                }
                if (params.max_land_area) {
                    searchParams.set('max_land_area', params.max_land_area.toString());
                } else {
                    searchParams.delete('max_land_area');
                }
                setFilter({ ...filter, min_land_area: params.min_land_area, max_land_area: params.max_land_area, page: 0 });
                break;

            case 'delivery_date':
                if (params.delivery_date) {
                    searchParams.set('delivery_date', params.delivery_date.toString());
                } else {
                    searchParams.delete('delivery_date');
                }
                setFilter({ ...filter, delivery_date: params.delivery_date, page: 0 });
                break;
            case 'finishing':
                if (params.finishing) {
                    searchParams.set('finishing', params.finishing.toString());
                } else {
                    searchParams.delete('finishing');
                }
                setFilter({ ...filter, finishing: params.finishing, page: 0 });
                break;

            case 'business_types':
                if (params.business_types) {
                    searchParams.set('business_types', params.business_types.toString());
                } else {
                    searchParams.delete('business_types');
                }
                setFilter({ ...filter, business_types: params.business_types, page: 0 });
                break;
            case 'orderBy':
                if (params.orderBy) {
                    searchParams.set('orderBy', params.orderBy.toString());
                } else {
                    searchParams.delete('orderBy');
                }
                setFilter({ ...filter, orderBy: params.orderBy, page: 0 });
                break;
            case 'developers_ids':
                if (params.developers_ids) {
                    searchParams.set('developers_ids', params.developers_ids.toString());
                } else {
                    searchParams.delete('developers_ids');
                }
                setFilter({ ...filter, developers_ids: params.developers_ids, page: 0 });
                break;
            case 'page':
                if (params.page) {
                    searchParams.set('page', (params.page + 1).toString());
                } else {
                    searchParams.delete('page');
                }
                setFilter({ ...filter, page: params.page });
                break;
            case 'page_size':
                if (params.page_size) {
                    searchParams.set('page_size', params.page_size.toString());
                } else {
                    searchParams.delete('page_size');
                }
                setFilter({ ...filter, page_size: params.page_size });
                break;
            case 'reset':
                // eslint-disable-next-line no-case-declarations
                const keysList: string[] = [];
                for (const key of searchParams.keys()) {
                    keysList.push(key);
                }
                keysList.forEach((key) => {
                    searchParams.delete(key);
                });
                setFilter({ page_size: filter.page_size });
                break;
            default:
        }

        searchParams.delete('selected_compound_id');
        searchParams.delete('selected_property_type_id');
        searchParams.delete('selected_property_id');
        if (type !== 'page' && type !== 'page_size') {
            searchParams.set('page', '1');
        }

        setSearchParams(searchParams);
    };

    // drawer
    const [open, setOpen] = useState(false);

    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const handleDrawerOpen = () => {
        setOpen((prevState) => !prevState);
    };

    const filterIsEqual = (a1: InventoryQueryInterface, a2: InventoryQueryInterface) =>
        a1 === a2 ||
        (JSON.stringify(a1.areas_ids) === JSON.stringify(a2.areas_ids) &&
            JSON.stringify(a1.compounds_ids) === JSON.stringify(a2.compounds_ids) &&
            JSON.stringify(a1.property_types_ids) === JSON.stringify(a2.property_types_ids) &&
            JSON.stringify(a1.business_types) === JSON.stringify(a2.business_types) &&
            JSON.stringify(a1.min_bedrooms) === JSON.stringify(a2.min_bedrooms) &&
            JSON.stringify(a1.max_bedrooms) === JSON.stringify(a2.max_bedrooms) &&
            JSON.stringify(a1.min_area) === JSON.stringify(a2.min_area) &&
            JSON.stringify(a1.max_area) === JSON.stringify(a2.max_area) &&
            JSON.stringify(a1.min_garden_area) === JSON.stringify(a2.min_garden_area) &&
            JSON.stringify(a1.max_garden_area) === JSON.stringify(a2.max_garden_area) &&
            JSON.stringify(a1.min_land_area) === JSON.stringify(a2.min_land_area) &&
            JSON.stringify(a1.max_land_area) === JSON.stringify(a2.max_land_area) &&
            JSON.stringify(a1.min_price) === JSON.stringify(a2.min_price) &&
            JSON.stringify(a1.max_price) === JSON.stringify(a2.max_price) &&
            JSON.stringify(a1.min_installments) === JSON.stringify(a2.min_installments) &&
            JSON.stringify(a1.max_installments) === JSON.stringify(a2.max_installments) &&
            JSON.stringify(a1.min_down_payment) === JSON.stringify(a2.min_down_payment) &&
            JSON.stringify(a1.max_down_payment) === JSON.stringify(a2.max_down_payment) &&
            JSON.stringify(a1.finishing) === JSON.stringify(a2.finishing) &&
            JSON.stringify(a1.developers_ids) === JSON.stringify(a2.developers_ids) &&
            JSON.stringify(a1.delivery_date) === JSON.stringify(a2.delivery_date) &&
            JSON.stringify(a1.orderBy) === JSON.stringify(a2.orderBy));

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        handleFilter('page', { page: newPage });
    };
    const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        handleFilter('page_size', { page_size: parseInt(event.target.value, 10) });
    };

    useEffect(() => {
        if (inventoryError) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: inventoryError?.message || intl.formatMessage({ id: 'something-wrong' }),
                    variant: 'alert',
                    alert: {
                        color: 'error',
                        severity: 'error'
                    },
                    close: true
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryError]);

    useEffect(() => {
        if (numberOfCompounds) {
            searchParams.set('number_of_compounds', numberOfCompounds.toString());
            setSearchParams(searchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfCompounds]);

    useEffect(() => {
        const selected_compound_id = searchParams.get('selected_compound_id');
        const selected_property_type_id = searchParams.get('selected_property_type_id');
        const selected_property_id = searchParams.get('selected_property_id');

        if (selected_compound_id && !selected_property_type_id) {
            dispatch(getPropertyTypesDetails({ compound_id: +selected_compound_id, query: { ...filter, page: 0, page_size: 30 } }));
        }

        if (selected_compound_id && selected_property_type_id && !selected_property_id) {
            dispatch(
                getProperties({
                    compound_id: +selected_compound_id,
                    query: { ...filter, page: 0, page_size: 30 },
                    property_type_id: +selected_property_type_id
                })
            );
        }

        if (selected_property_id) {
            dispatch(getPropertyDetails({ property_id: +selected_property_id, query: { ...filter, page: 0, page_size: 30 } }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, searchParams]);

    useEffect(() => {
        dispatch(updateSelectedCompound({ compound_id: undefined }));
        dispatch(getCompoundDetails({ query: { ...filter } }));
        setSearchParams(searchParams);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    useEffect(() => {
        if (!areas.length) dispatch(getAllAreas({ language: intl.locale === 'ar' ? 'ar' : 'en' }));
        if (!compounds.length) dispatch(getAllCompounds({ language: intl.locale === 'ar' ? 'ar' : 'en' }));
        if (!propertyTypes.length) dispatch(getPropertyTypes({ language: intl.locale === 'ar' ? 'ar' : 'en' }));
        if (!filterOptions) dispatch(getFilterOptions({ language: intl.locale === 'ar' ? 'ar' : 'en' }));
        if (!developers.length) dispatch(getDevelopers({ language: intl.locale === 'ar' ? 'ar' : 'en' }));
        dispatch(openDrawer(false));

        const selected_compound_id = searchParams.get('selected_compound_id');
        const selected_property_type_id = searchParams.get('selected_property_type_id');
        const selected_property_id = searchParams.get('selected_property_id');

        if (selected_compound_id) {
            dispatch(getPropertyTypesDetails({ compound_id: +selected_compound_id, query: { ...filter, page: 0, page_size: 30 } }));
        }

        if (selected_compound_id && selected_property_type_id) {
            dispatch(
                getProperties({
                    compound_id: +selected_compound_id,
                    query: { ...filter, page: 0, page_size: 30 },
                    property_type_id: +selected_property_type_id
                })
            );
        }

        if (selected_property_id) {
            dispatch(getPropertyDetails({ property_id: +selected_property_id, query: { ...filter, page: 0, page_size: 30 } }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        emitEvent('View Inventory Page');
    }, [emitEvent]);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard
                    bgcolor={theme.palette.background.default}
                    title={
                        <Grid container spacing={gridSpacing} alignItems="center">
                            <Grid item>
                                <Typography variant="h3">
                                    <FormattedMessage id="nawy-inventory" />
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    secondary={
                        <Box>
                            <Button
                                disableRipple
                                onClick={handleDrawerOpen}
                                startIcon={<FilterAltIcon sx={{ fontWeight: 500, color: 'primary.main' }} />}
                            >
                                <FormattedMessage id="filter" />
                            </Button>
                        </Box>
                    }
                    content={false}
                />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex' }}>
                    <Grid container sx={{ overflowX: open ? 'scroll' : '', display: 'block' }}>
                        <MainComponent open={open}>
                            <InventoryFilterView
                                filter={filter}
                                initialState={{}}
                                filterIsEqual={filterIsEqual}
                                handleFilter={handleFilter}
                            />
                            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                <MainCard content={false}>
                                    <CompoundsList />
                                    <TablePagination
                                        component="div"
                                        count={+(searchParams.get('number_of_compounds') || '0')}
                                        page={filter.page || 0}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={filter.page_size || 10}
                                        onRowsPerPageChange={handleChangePageSize}
                                        labelRowsPerPage={<FormattedMessage id="per_page" />}
                                    />
                                </MainCard>
                            </Box>
                        </MainComponent>

                        <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <MainCard content={false}>
                                <CompoundsList />
                                <TablePagination
                                    component="div"
                                    count={numberOfCompounds}
                                    page={filter.page || 0}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={filter.page_size || 10}
                                    onRowsPerPageChange={handleChangePageSize}
                                />
                            </MainCard>
                        </Grid>
                    </Grid>

                    <Drawer
                        sx={{
                            ml: open ? 3 : 0,
                            height: matchDownLG ? '100vh' : 'auto',
                            flexShrink: 0,
                            zIndex: { xs: 1200, lg: open ? 0 : -1 },
                            overflowX: 'hidden',
                            width: appDrawerWidth,
                            '& .MuiDrawer-paper': {
                                height: 'auto',
                                width: appDrawerWidth,
                                position: matchDownLG ? 'fixed' : 'relative',
                                border: 'none',
                                borderRadius: matchDownLG ? 0 : `${borderRadius}px`,
                                marginTop: matchDownLG ? (matchDownMD ? 0 : 10) : 0
                            }
                        }}
                        variant={matchDownLG ? 'temporary' : 'persistent'}
                        anchor="right"
                        open={open}
                        ModalProps={{ keepMounted: true }}
                        onClose={handleDrawerOpen}
                    >
                        {open && (
                            <PerfectScrollbar component="div">
                                <InventoryFilters filter={filter} handleFilter={handleFilter} handleDrawerOpen={handleDrawerOpen} />
                            </PerfectScrollbar>
                        )}
                    </Drawer>
                </Box>
            </Grid>
            <Grid item sx={{ display: { xs: 'block', sm: 'none' } }}>
                <Fab sx={{ position: 'fixed', bottom: 16, right: 16 }} color="primary" variant="extended" onClick={handleDrawerOpen}>
                    <FilterAltIcon />
                    <FormattedMessage id="filter" />
                </Fab>
            </Grid>
        </Grid>
    );
};

export default NawyInventoryPage;
