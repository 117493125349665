import { FLORA_API, formattedQuery } from 'store/apis';
import { QueryRejctionReasons } from './interfaces';

const REJECTION_REAONS_APIS = {
    // post
    create: () => `${FLORA_API}rejection-reasons`,
    // get
    query: (query: QueryRejctionReasons) => {
        const formatQuery: string[] = [FLORA_API, 'rejection-reasons', '?'];
        return formattedQuery(formatQuery, query);
    },
    // get
    getById: (id: number) => `${FLORA_API}rejection-reasons/${id}`,
    // update
    update: (id: number) => `${FLORA_API}rejection-reasons/${id}`,
    // delete
    delete: (id: number) => `${FLORA_API}rejection-reasons/${id}`,
    // get
    getRecentByDocIds: (documentIds: string[]) => {
        const params = new URLSearchParams();
        documentIds.forEach((id: string) => {
            params.append('documentIds', id);
        });
        const q = params.toString();
        return `${FLORA_API}rejection-reasons/get-recent-rejection-reason-for-documents?${q}`;
    }
};

export default REJECTION_REAONS_APIS;
