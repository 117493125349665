import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { IPropertyTypeDetails } from 'store/slices/nawyInventory/interfaces';
import NoDataFound from 'ui-component/NoDataFound';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import SubCard from 'ui-component/cards/SubCard';
import { propertyTypesHeadCells } from './InventoryHeaders';
import PropertyTypesRow from './PropertyTypesRow';

export default function PropertyTypesList() {
    const intl = useIntl();
    const theme = useTheme();

    const { propertyTypesDetails, loadingPropertyTypesDetails } = useSelector((state) => state.nawyInventory);

    return (
        <TableContainer>
            <SubCard
                sx={{ bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark[900] : theme.palette.grey[100] }}
                title={intl.formatMessage({ id: 'property-types' })}
                content={false}
            >
                {loadingPropertyTypesDetails ? (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SkeletonTotalIncomeCard />
                        </Grid>
                    </Grid>
                ) : (
                    <Table size="small" aria-label="collapsible table">
                        <EnhancedTableHead />
                        <TableBody>
                            {propertyTypesDetails.map((propertyType: IPropertyTypeDetails) => (
                                <PropertyTypesRow key={propertyType.property_type_id} propertyType={propertyType} />
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!loadingPropertyTypesDetails && propertyTypesDetails.length === 0 && (
                    <NoDataFound message={intl.formatMessage({ id: 'no-property-types-match' })} />
                )}
            </SubCard>
        </TableContainer>
    );
}

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                <TableCell sx={{ pl: 3 }} />
                {propertyTypesHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <FormattedMessage id={headCell.label} />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
