import { YMIR_API, formattedQuery } from 'store/apis';
import { QueryNotificationTypes } from './interfaces';

const NOTIFICATION_TYPES_APIS = {
    // post
    create: () => `${YMIR_API}notification-type`,
    // get
    query: (query: QueryNotificationTypes) => {
        const formatQuery: string[] = [YMIR_API, 'notification-type', '?'];
        return formattedQuery(formatQuery, query);
    },
    // get
    getById: (id: number | undefined) => `${YMIR_API}notification-type/${id}`,
    // get
    getTypesNames: () => `${YMIR_API}notification-type/typesNames`,
    // patch
    updateType: (id: number) => `${YMIR_API}notification-type/${id}`,
    // delete
    delete: (id: number) => `${YMIR_API}notification-type/${id}`
};

export const TYPE_CONFIGURATION_APIS = {
    // post
    create: () => `${YMIR_API}type-configuration`,
    // get
    query: (typeId: number) => `${YMIR_API}type-configuration/${typeId}`,
    // patch
    updateTypeConfig: (id: string) => `${YMIR_API}type-configuration/${id}`,
    // delete
    deleteTypeConfig: (id: string, typeId: number) => `${YMIR_API}type-configuration/${id}/${typeId}`
};

export const USERS_APIS = {
    // get
    query: (name: string) => {
        const formatQuery: string[] = [YMIR_API, 'user', '?'];
        return formattedQuery(formatQuery, { name });
    }
};

export default NOTIFICATION_TYPES_APIS;
