import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Chip } from '@mui/material';

export interface FilterChipProps {
    label: string;
    value: string;
    variant?: 'outlined' | 'filled';
    handleOnClear: () => void;
}
const FilterChip = ({ label, value, variant = 'outlined', handleOnClear }: FilterChipProps) => (
    <Chip
        sx={{ textTransform: 'capitalize' }}
        size="small"
        label={`${label}: ${value}`}
        variant={variant}
        deleteIcon={<CloseOutlinedIcon />}
        onDelete={handleOnClear}
    />
);

export default FilterChip;
