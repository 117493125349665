import React, { useState } from 'react';
import { Autocomplete, Box, TextField } from '@mui/material';
import { CrmPropertyType, crmPropertyTypes } from 'store/slices/general/interfaces';
import { useIntl } from 'react-intl';

interface PropertyTypeSelectorProps {
    defaultOption?: CrmPropertyType | null;
    onChangeHandler: (newValue: CrmPropertyType | null) => void;
    size?: 'small' | 'medium';
}

const PropertyTypeSelector: React.FC<PropertyTypeSelectorProps> = ({ defaultOption, onChangeHandler, size = 'medium' }) => {
    const intl = useIntl();
    const [selectedPropertyType, setSelectedPropertyType] = useState<CrmPropertyType | null>(defaultOption ?? null);
    const options: CrmPropertyType[] = crmPropertyTypes;
    return (
        <Autocomplete
            autoHighlight
            id="set-property-type"
            size={size}
            options={options}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
                setSelectedPropertyType(newValue);
                onChangeHandler(newValue);
            }}
            value={selectedPropertyType}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField {...params} fullWidth label={`${intl.formatMessage({ id: 'property-type' })} *`} name="propertyType" />
            )}
        />
    );
};

export default PropertyTypeSelector;
