/* eslint-disable react/destructuring-assignment */
import { useTheme } from '@mui/material/styles';
import { Card, Divider, Grid, useMediaQuery } from '@mui/material';
import RoleGuard from 'utils/RoleGuard';
import { ReactNode, useState } from 'react';
import { AnalyticsWidgetsData } from 'ui-component/Tables/NawyTable/Widget';
import { gridSpacingSmall } from 'store/constant';
import DashboardWidgetCard from './DashboardWidgetCard';
import { useIntl } from 'react-intl';
import { useSelector } from 'store';
import { FlowStagesEnum } from 'store/slices/partnersDashboard/interfaces';

export enum WidgetTypeEnum {
    count = 'count',
    money = 'money',
    default = 'default'
}
interface DashboardWidgetSectionProps {
    flowStage: FlowStagesEnum;
    handleFlowStageChange: (flowStage: FlowStagesEnum) => void;
}
const DashboardWidgetSection = ({ flowStage, handleFlowStageChange }: DashboardWidgetSectionProps) => {
    const theme = useTheme();
    const intl = useIntl();
    const { analytica: rsAnalytica, analyticaLoading: rsAnalyticaLoading } = useSelector((state) => state.reservations);
    const { analytica, analyticsLoading } = useSelector((state) => state.saleClaims);
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    const [mouseOnCard, setMouseOnCard] = useState(111);

    const handleSelectCard = (selectedFlow?: FlowStagesEnum) => {
        handleFlowStageChange(selectedFlow || FlowStagesEnum.eoi);
    };

    const handleOnMouseCard = (cardId: number) => {
        setMouseOnCard(cardId);
    };

    const analyticsWidgets: AnalyticsWidgetsData[] = [
        {
            type: WidgetTypeEnum.money,
            label: intl.formatMessage({ id: 'eois' }),
            value: rsAnalytica?.totalEOIPrice,
            count: rsAnalytica?.countEOI,
            loading: rsAnalyticaLoading,
            expandable: false,
            flowType: FlowStagesEnum.eoi
        },
        {
            type: WidgetTypeEnum.money,
            label: intl.formatMessage({ id: 'reservations' }),
            value: rsAnalytica?.totalResPrice,
            count: rsAnalytica?.countRes,
            loading: rsAnalyticaLoading,
            expandable: false,
            flowType: FlowStagesEnum.reservation
        },
        {
            type: WidgetTypeEnum.money,
            title: intl.formatMessage({ id: 'sale-claims' }),
            label: intl.formatMessage({ id: 'in-progress' }) + (!matchDownMD ? ` ${intl.formatMessage({ id: 'sale-claims' })}` : ''),
            value: analytica.sumInprogress,
            count: analytica.noAction + analytica.pending,
            loading: analyticsLoading,
            expandable: false,
            flowType: FlowStagesEnum.in_progress_sale_claim
        },
        {
            type: WidgetTypeEnum.money,
            title: intl.formatMessage({ id: 'sale-claims' }),
            label: intl.formatMessage({ id: 'approved' }) + (!matchDownMD ? ` ${intl.formatMessage({ id: 'sale-claims' })}` : ''),
            value: analytica.sumApproved,
            count: analytica.approved,
            loading: analyticsLoading,
            expandable: false,
            flowType: FlowStagesEnum.approved_sale_claim
        }
    ];

    const GuardedWidgetCard = (widget: AnalyticsWidgetsData, isMain?: boolean, isContrastText?: boolean, icon?: ReactNode) =>
        widget.allowedAudience ? (
            <Grid item>
                <RoleGuard allowAdminByDefault={false} groups={widget.allowedAudience}>
                    <DashboardWidgetCard
                        label={widget.label}
                        money={widget.value}
                        title={widget.title}
                        upperTotal={widget.count}
                        loading={widget.loading}
                        icon={icon}
                        isContrastText={isContrastText}
                    />
                </RoleGuard>
            </Grid>
        ) : (
            <Grid item>
                <DashboardWidgetCard
                    label={widget.label}
                    money={widget.value}
                    upperTotal={widget.count}
                    title={widget.title}
                    loading={widget.loading}
                    icon={icon}
                    isContrastText={isContrastText}
                />
            </Grid>
        );

    return (
        <Grid container spacing={matchDownSM ? gridSpacingSmall : 0} style={{ borderBottomColor: theme.palette.primary.dark }}>
            {analyticsWidgets.map((analyticsWidget, index) => (
                <Grid key={index} item xs={matchDownSM ? 6 : 3}>
                    <Card
                        key={index}
                        elevation={matchDownSM ? 0 : 4}
                        variant={matchDownSM ? 'outlined' : 'elevation'}
                        style={
                            matchDownSM
                                ? {
                                      padding: 12,
                                      backgroundColor:
                                          flowStage === analyticsWidget.flowType
                                              ? theme.palette.primary.light
                                              : theme.palette.background.paper,
                                      borderColor: theme.palette.primary[200]
                                  }
                                : {
                                      borderStartStartRadius: 10,
                                      borderStartEndRadius: 0,
                                      borderEndEndRadius: 0,
                                      height: '100%',
                                      borderEndStartRadius: 0,
                                      padding: 12,
                                      backgroundColor:
                                          flowStage === analyticsWidget.flowType
                                              ? theme.palette.primary.main
                                              : mouseOnCard === index
                                              ? theme.palette.primary.light
                                              : theme.palette.background.paper
                                  }
                        }
                        onClick={() => handleSelectCard(analyticsWidget?.flowType)}
                        onMouseOver={() => handleOnMouseCard(index)}
                        onMouseLeave={() => handleOnMouseCard(111)}
                    >
                        {GuardedWidgetCard(
                            analyticsWidget,
                            true,
                            flowStage === analyticsWidget.flowType && !matchDownSM,
                            analyticsWidget.icon
                        )}
                    </Card>
                </Grid>
            ))}
            <Grid item xs={12} display={{ xs: 'none', sm: 'block' }}>
                <Divider style={{ height: 4 }} color={theme.palette.primary.main} />
            </Grid>
        </Grid>
    );
};

export default DashboardWidgetSection;
