import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { getCompoundsFilter } from 'store/slices/filters/actions';

import { PerfectFilterValueInterface } from 'store/slices/filters/interfaces';

interface CompoundsFilterV2Props {
    defaultValueId?: number | '';
    value?: number | '';
    location: string;
    handleSearch: (newValue?: number) => void;
    handleSelected?: (compound?: PerfectFilterValueInterface | null | undefined) => void;
}

const CompoundsFilterV2 = ({ defaultValueId = '', location, value = '', handleSearch, handleSelected }: CompoundsFilterV2Props) => (
    <Filter defaultValueId={defaultValueId} location={location} value={value} handleSearch={handleSearch} handleSelected={handleSelected} />
);

const Filter = ({ defaultValueId, value, location, handleSearch, handleSelected }: CompoundsFilterV2Props) => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const { compounds, compoundsLoading } = useSelector((state) => state.filters);
    const [newValue, setNewValue] = useState<string>('');
    const firstRender = useRef(true);

    const handleInputChange = (event: React.SyntheticEvent<Element, Event>, name: string) => {
        dispatch(getCompoundsFilter({ query: { name } }));
        setNewValue(name);
    };

    const handleOnChange = (entity: PerfectFilterValueInterface | null | undefined) => {
        handleSearch(entity?.id);
        if (handleSelected) handleSelected(entity);
    };
    useEffect(() => {
        if (defaultValueId) {
            dispatch(getCompoundsFilter({ query: { id: defaultValueId } }));
        } else {
            dispatch(getCompoundsFilter({ query: { name: newValue } }));
        }
    }, [defaultValueId, newValue]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && compounds.count > 0) {
            firstRender.current = false;
            const compound = compounds.data.find((c) => c.id === defaultValueId) ?? null;

            if (handleSelected) handleSelected(compound);
        }
    }, [compounds, defaultValueId, handleSelected]);
    const handleFilterClick = () => {
        emitEvent('Click developer filter', { location });
    };
    return (
        <Autocomplete
            id="autocomplete-generic-filter-compounds"
            fullWidth
            onOpen={handleFilterClick}
            size="small"
            options={compounds.data}
            loading={compoundsLoading}
            value={compounds.data.find((v) => v.id === value) || null}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option) => option?.id === value}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option: any) => (
                <li {...props}>
                    <Grid item xs={12} pl={1}>
                        <Typography textTransform="capitalize">
                            {(intl.locale === 'ar' ? option.arabicName : option.name) || option.name}
                        </Typography>
                        <Typography variant="subtitle2" textTransform="capitalize">
                            {(intl.locale === 'ar' ? option.developer.arabicName : option.developer.name) || option.developer.name}
                        </Typography>
                    </Grid>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-compounds"
                    label={intl.formatMessage({ id: 'compounds' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default CompoundsFilterV2;
