import { createSlice } from '@reduxjs/toolkit';
import {
    createDescription,
    createLocation,
    createPayment,
    createProperty,
    submitPropertyListing,
    getBrokers,
    getCompoundsDetails,
    getPropertyListingById,
    queryPropertiesList,
    updateDescription,
    updateLocation,
    updatePayment,
    updateProperty,
    createInternalDetails,
    uploadPropertyListingImage,
    deletePropertyListingImage,
    updatePropertyListingCoverImage,
    queryInventoryManagers,
    assignListingReviewer
} from './actions';
import { ImageType, PropertiesListingInitialInterface } from './interfaces';

const initialState: PropertiesListingInitialInterface = {
    loading: true,
    searchLoading: false,
    deleteLoading: -1,
    error: null,
    refresh: false,
    loadingManagers: false,
    errorManagers: false,
    currentPropertyListing: null,
    uploadProgress: [],
    currentUploading: 0,
    compounds: [],
    brokers: [],
    properties: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

const propertiesListing = createSlice({
    name: 'propertiesListing',
    initialState,
    reducers: {
        updateUploadProgress: (state, action) => {
            if (state.uploadProgress[action.payload.index] === undefined) state.currentUploading += 1;
            state.uploadProgress[action.payload.index] = action.payload.progress;
            if (action.payload.progress === 100) {
                state.currentUploading -= 1;
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(queryInventoryManagers.pending, (state) => {
                state.loadingManagers = true;
                state.errorManagers = false;
            })
            .addCase(queryInventoryManagers.fulfilled, (state, action) => {
                state.loadingManagers = false;
                state.inventoryManagers = action.payload;
            })
            .addCase(queryInventoryManagers.rejected, (state, action) => {
                state.loadingManagers = false;
                state.errorManagers = action.payload;
            })
            .addCase(assignListingReviewer.pending, (state) => {
                state.errorManagers = false;
            })
            .addCase(assignListingReviewer.fulfilled, (state, action) => {})
            .addCase(assignListingReviewer.rejected, (state, action) => {
                state.errorManagers = action.payload;
            })
            .addCase(queryPropertiesList.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryPropertiesList.fulfilled, (state, action) => {
                state.loading = false;
                state.properties = action.payload;
            })
            .addCase(queryPropertiesList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getPropertyListingById.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.currentPropertyListing = null;
            })
            .addCase(getPropertyListingById.fulfilled, (state, action) => {
                state.loading = false;
                state.currentPropertyListing = action.payload;
            })
            .addCase(getPropertyListingById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(submitPropertyListing.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(submitPropertyListing.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(submitPropertyListing.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createInternalDetails.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createInternalDetails.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.broker = action.payload.broker;
                    state.currentPropertyListing.ownerName = action.payload.ownerName;
                    state.currentPropertyListing.ownerPhone = action.payload.ownerPhone;
                }
            })
            .addCase(createInternalDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createPayment.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createPayment.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.payment = action.payload;
                }
            })
            .addCase(createPayment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createLocation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createLocation.fulfilled, (state, action) => {
                state.loading = false;
                state.currentPropertyListing = action.payload;
            })
            .addCase(createLocation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createDescription.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createDescription.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) state.currentPropertyListing.description = action.payload;
            })
            .addCase(createDescription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createProperty.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createProperty.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.property = action.payload;
                }
            })
            .addCase(createProperty.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateLocation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateLocation.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.location = action.payload;
                }
            })
            .addCase(updateLocation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateProperty.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateProperty.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.property = action.payload;
                }
            })
            .addCase(updateProperty.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updatePayment.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updatePayment.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.payment = action.payload;
                }
            })
            .addCase(updatePayment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateDescription.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateDescription.fulfilled, (state, action) => {
                state.loading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.description = action.payload;
                }
            })
            .addCase(updateDescription.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getCompoundsDetails.pending, (state) => {
                state.searchLoading = true;
                state.error = false;
            })
            .addCase(getCompoundsDetails.fulfilled, (state, action) => {
                state.searchLoading = false;
                state.compounds = action.payload;
            })
            .addCase(getCompoundsDetails.rejected, (state, action) => {
                state.searchLoading = false;
                state.error = action.payload;
            })
            .addCase(getBrokers.pending, (state) => {
                state.searchLoading = true;
                state.error = false;
            })
            .addCase(getBrokers.fulfilled, (state, action) => {
                state.searchLoading = false;
                state.brokers = action.payload;
            })
            .addCase(getBrokers.rejected, (state, action) => {
                state.searchLoading = false;
                state.error = action.payload;
            })
            .addCase(uploadPropertyListingImage.pending, (state) => {
                state.searchLoading = true;
                state.error = false;
            })
            .addCase(uploadPropertyListingImage.fulfilled, (state, action) => {
                state.searchLoading = false;
                if (state.currentPropertyListing) {
                    state.currentPropertyListing.images = action.payload;
                }
            })
            .addCase(uploadPropertyListingImage.rejected, (state, action) => {
                state.searchLoading = false;
                state.error = action.payload;
            })
            .addCase(deletePropertyListingImage.pending, (state, action) => {
                state.searchLoading = true;
                state.deleteLoading = action.meta.arg.id;
                state.error = false;
            })
            .addCase(deletePropertyListingImage.fulfilled, (state, action) => {
                state.searchLoading = false;
                state.deleteLoading = -1;
                if (state.currentPropertyListing?.images?.images) {
                    const i = state?.currentPropertyListing?.images?.images.findIndex((image) => image.id === action.payload.id);
                    state?.currentPropertyListing?.images?.images.splice(i, 1);
                    state.currentPropertyListing.images.sectionStatus = action.payload.sectionStatus;
                }
            })
            .addCase(deletePropertyListingImage.rejected, (state, action) => {
                state.searchLoading = false;
                state.error = action.payload;
            })
            .addCase(updatePropertyListingCoverImage.pending, (state) => {
                state.searchLoading = true;
                state.error = false;
            })
            .addCase(updatePropertyListingCoverImage.fulfilled, (state, action) => {
                state.searchLoading = false;
                if (state.currentPropertyListing?.images && state.currentPropertyListing.images.images) {
                    if (action.payload.prevCoverImageId) {
                        state?.currentPropertyListing?.images?.images.map((image) => {
                            if (image.id === action.payload.prevCoverImageId) image.type = ImageType.propertyImage;
                            return image;
                        });
                    }
                    state?.currentPropertyListing?.images?.images.map((image) => {
                        if (image.id === action.payload.newCoverImageId) image.type = ImageType.coverImage;
                        return image;
                    });
                    state.currentPropertyListing.images.sectionStatus = action.payload.sectionStatus;
                }
            })
            .addCase(updatePropertyListingCoverImage.rejected, (state, action) => {
                state.searchLoading = false;
                state.error = action.payload;
            });
    }
});

export const { updateUploadProgress } = propertiesListing.actions;

export default propertiesListing.reducer;
