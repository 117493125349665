import { createSlice } from '@reduxjs/toolkit';
import { createCils, getCilById, getCilsAnalytica, queryCils, renewCil } from './actions';
import { CilsInitialStateInterface } from './interfaces';

// initial state
const initialState: CilsInitialStateInterface = {
    loading: false,
    error: false,
    listLoading: false,
    listError: false,
    cils: {
        page: 1,
        pages: 1,
        pageSize: 10,
        count: 0,
        data: []
    },
    analytica: {
        total: 0,
        accepted: 0,
        rejected: 0
    },
    analyticaLoading: false,
    analyticaError: false
};

// ==============================|| SLICE - cils ||============================== //

const cils = createSlice({
    name: 'cils',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryCils.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryCils.fulfilled, (state, action) => {
                state.listLoading = false;
                state.cils = action.payload;
            })
            .addCase(queryCils.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(createCils.pending, (state) => {
                state.error = false;
            })
            .addCase(createCils.fulfilled, (state, action) => {
                state.cil = action.payload.cil;
                state.lead = action.payload.lead;
            })
            .addCase(createCils.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(renewCil.pending, (state) => {
                state.error = false;
            })
            .addCase(renewCil.fulfilled, (state, action) => {
                state.cil = action.payload.cil;
                state.lead = action.payload.lead;
            })
            .addCase(renewCil.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getCilById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getCilById.fulfilled, (state, action) => {
                state.loading = false;
                state.cil = action.payload.cil;
                state.lead = action.payload.lead;
            })
            .addCase(getCilById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getCilsAnalytica.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getCilsAnalytica.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analytica = action.payload;
            })
            .addCase(getCilsAnalytica.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default cils.reducer;
