import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import {
    Grid,
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    TextField,
    Typography,
    Autocomplete,
    Box,
    CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { AnyObject } from 'yup/lib/types';
import { useTheme } from '@mui/styles';
import { NotificationTypeConfigsFullDataInterface, NotificationTypeMinimalResponse } from 'store/slices/notifications/interfaces';
import { SystemUserQueryInterface } from 'store/slices/users/interfaces';
import { sendBatchNotification } from 'store/slices/users/actions';
import { getNotificationTypeById } from 'store/slices/notifications/actions';
import axios from 'utils/axios';
import NOTIFICATION_TYPES_APIS from 'store/slices/notifications/apis';

export default function SendNotificationDialog({
    fullWidth,
    selectedRows,
    isAllSelected,
    filter
}: {
    fullWidth?: boolean;
    selectedRows: string[];
    isAllSelected: boolean;
    filter?: SystemUserQueryInterface;
}) {
    const intl = useIntl();
    const theme = useTheme();

    const [selectedNotificationType, setSelectedNotificationType] = useState<NotificationTypeConfigsFullDataInterface>();
    const [notificationTypesNames, setNotificationTypesNames] = useState<NotificationTypeMinimalResponse[]>([]);

    const { notificationType, loading } = useSelector((state) => state.notificationTypes);

    let validationSchema = yup.object().shape({});
    if (selectedNotificationType?.requiredData) {
        validationSchema = yup.object().shape(
            selectedNotificationType.requiredData.reduce(
                (
                    accumulator: {
                        [key: string]: yup.StringSchema<string | undefined, AnyObject>;
                    },
                    fieldName
                ) => {
                    accumulator[fieldName] = yup.string().required(`${fieldName} is required`);
                    return accumulator;
                },
                {} as { [key: string]: yup.StringSchema<string> }
            )
        );
    }

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (formik: FormikHelpers<any>) => {
        formik.resetForm();
        setOpen(false);
        setSelectedNotificationType(undefined);
    };

    const initialValues: { [key: string]: string } = {};
    selectedNotificationType?.requiredData.forEach((fieldName: string) => {
        initialValues[fieldName] = '';
    });

    const handleSearch = async (typeId: number | undefined) => {
        if (typeId) {
            dispatch(getNotificationTypeById({ typeId }));
        } else setSelectedNotificationType(undefined);
    };

    const runSuccess = (formik: FormikHelpers<any>) => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-sent-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);
    };

    const submit = (values: any, formik: FormikHelpers<any>) => {
        formik.setSubmitting(true);
        if (selectedNotificationType) {
            dispatch(
                sendBatchNotification({
                    data: {
                        typeId: selectedNotificationType.typeId,
                        requiredData: values,
                        filter: isAllSelected ? filter : undefined,
                        targetUsers: isAllSelected ? undefined : selectedRows
                    },
                    runSuccess: () => runSuccess(formik)
                })
            );
        }
        formik.setSubmitting(false);
        handleClose(formik);
    };

    const getTypesNames = async () => {
        const typesNamesRes = await axios.get(NOTIFICATION_TYPES_APIS.getTypesNames());
        setNotificationTypesNames(typesNamesRes.data);
    };

    useEffect(() => {
        if (notificationType) setSelectedNotificationType(notificationType);
    }, [notificationType]);

    useEffect(() => {
        if (open) getTypesNames();
    }, [open]);

    return (
        <Formik initialValues={initialValues} onSubmit={submit} validationSchema={validationSchema} enableReinitialize>
            {(formik) => (
                <Form>
                    <Button
                        fullWidth={fullWidth}
                        disabled={selectedRows?.length === 0}
                        variant="contained"
                        size="small"
                        onClick={handleClickOpen}
                    >
                        <FormattedMessage id="send-notification" />
                    </Button>
                    <Dialog
                        open={open}
                        onClose={() => handleClose(formik)}
                        scroll="paper"
                        maxWidth="sm"
                        fullWidth
                        aria-labelledby="send-notification "
                    >
                        <DialogTitle id="send-notification-dialog">
                            <FormattedMessage id="send-notification" />
                        </DialogTitle>
                        <DialogContent>
                            <Grid container py={1} spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="notification-type"
                                        fullWidth
                                        options={notificationTypesNames}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        onChange={(event, newValue) => {
                                            if (newValue) handleSearch(newValue.typeId);
                                            else handleSearch(undefined);
                                        }}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.typeId}>
                                                {option.name}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={intl.formatMessage({ id: 'notification-type' })}
                                                name="notification-type"
                                                id="notification-type"
                                                placeholder={intl.formatMessage({ id: 'notification-type' })}
                                            />
                                        )}
                                    />
                                </Grid>
                                {loading && (
                                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Grid>
                                )}
                                {selectedNotificationType?.requiredData &&
                                    selectedNotificationType.requiredData.map((variable: string, index) => (
                                        <Grid item xs={6} key={index}>
                                            <TextField
                                                label={`${variable} *`}
                                                fullWidth
                                                type="text"
                                                name={variable}
                                                id={variable}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(
                                                    Object.keys(formik.touched).find((key) => key === variable) &&
                                                        Object.keys(formik.errors).find((key) => key === variable)
                                                )}
                                            />
                                            <ErrorMessage name={variable}>
                                                {(msg) => (
                                                    <Typography mt={1} color={theme.palette.error.main}>
                                                        {msg}
                                                    </Typography>
                                                )}
                                            </ErrorMessage>
                                        </Grid>
                                    ))}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button disabled={formik.isSubmitting} onClick={() => handleClose(formik)} size="small" color="error">
                                <FormattedMessage id="cancel" />
                            </Button>
                            <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                                <FormattedMessage id="create" />
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                </Form>
            )}
        </Formik>
    );
}
