import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import PRIMARY_CILS_APIS from './apis';
import { CreatePrimaryCilInterface, QueryPrimaryCilsInterface } from './interfaces';

export const queryPrimaryCils = createAsyncThunk(
    'primary-cils/query',
    async (
        requestData: {
            query: QueryPrimaryCilsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data } = await axios.get(PRIMARY_CILS_APIS.query(query));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const analyticaPrimaryCils = createAsyncThunk(
    'primary-cils/analytica',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess } = requestData;
        try {
            const { data } = await axios.get(PRIMARY_CILS_APIS.analytica());
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createPrimaryCils = createAsyncThunk(
    'primary-cils/create',
    async (
        requestData: {
            data: CreatePrimaryCilInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { data, runSuccess } = requestData;
        try {
            await axios.post(PRIMARY_CILS_APIS.create(), data);
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getPrimaryCilById = createAsyncThunk(
    'primary-cils/get-by-id',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.get(PRIMARY_CILS_APIS.getById(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const renewPrimaryCil = createAsyncThunk(
    'primary-cils/renew',
    async (
        requestData: {
            id: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, runSuccess } = requestData;
        try {
            const { data } = await axios.patch(PRIMARY_CILS_APIS.renew(id));
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updatePrimaryCilStatus = createAsyncThunk(
    'primary-cils/update-status',
    async (
        requestData: {
            id: number;
            status: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, status, runSuccess } = requestData;
        try {
            const { data } = await axios.patch(PRIMARY_CILS_APIS.updateStatus(id), { status });
            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
