import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryTiersInterface } from './interfaces';

const TIER_COLLECTION = 'tiers';

const TIERS_APIS = {
    // GET
    query: (query: QueryTiersInterface) => {
        const formatQuery: string[] = [FAUNA_API, TIER_COLLECTION, '?'];
        return formattedQuery(formatQuery, query);
    },
    // GET
    getById: (tierId: string) => `${FAUNA_API}${TIER_COLLECTION}/${tierId}`,
    // GET
    getBrokerTier: () => `${FAUNA_API}${TIER_COLLECTION}/broker`,
    // POST
    create: () => `${FAUNA_API}tiers`,
    // PATCH
    update: (tierId: string) => `${FAUNA_API}${TIER_COLLECTION}/${tierId}`,
    // POST
    addRequirement: (tierId: string) => `${FAUNA_API}${TIER_COLLECTION}/${tierId}/requirements`,
    // DELETE
    delete: (tierId: string) => `${FAUNA_API}${TIER_COLLECTION}/${tierId}`,
    // DELETE
    removeRequirement: (tierId: string, requirementId: string) => `${FAUNA_API}tiers/${tierId}/requirements/${requirementId}`
};

export default TIERS_APIS;
