import { CommonListInterface } from 'store/interfaces';

export interface NotificationTypeInterface {
    typeId: number;
    name: string;
    portal: string;
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
}

export interface UsersInterface {
    cognitoId: string;
    name: string;
}

export interface NotificationTypeList extends CommonListInterface {
    data: NotificationTypeInterface[];
}

export interface NotificationTypeFullDataInterface {
    typeId: number;
    name: string;
    link: string;
    portal: Portal;
    requiredData: string[];
    defaultUsers: string[];
    defaultUsersInfo: UsersInterface[];
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
}

export interface TypeConfigurationInterface {
    configId: string;
    typeId: number;
    channel: Channel;
    defaultUsers: string[];
    body: string;
    subject: string;
    emailFrom: string;
    whatsappTemplateName?: string;
    whatsappLanguageCode?: string;
    whatsappPdfFileName?: string;
    whatsappPdfLink?: string;
    whatsappVideoUrl?: string;
    whatsappBtnUrl?: string;
    whatsappVariables?: string[];
    createdAt: Date;
    createdBy: string;
    updatedAt: Date;
    updatedBy: string;
}

export interface TypeConfigFullUsersEntity extends TypeConfigurationInterface {
    defaultUsersFullInfo: UsersInterface[];
}

export interface NotificationTypeConfigsFullDataInterface extends NotificationTypeFullDataInterface {
    configurations: TypeConfigFullUsersEntity[];
}

export interface QueryNotificationTypes {
    portal?: string;
    name?: string;
    typeId?: number;
    page?: string;
    pageSize?: number;
}

export interface CreateNotificationTypeV1Dto {
    name: string;
    portal: Portal | undefined;
    requiredData?: string[];
    defaultUsers?: string[];
}

export interface UpdateNotificationTypeV1Dto extends Partial<CreateNotificationTypeV1Dto> {
    link?: string;
}

export interface DeleteNotificationTypeV1Dto {
    portal: Portal;
    typeConfigs: string[];
}

export interface NotificationTypesInitialState {
    loading: boolean;
    error: any;
    loadingList: boolean;
    errorList: any;
    refresh: boolean;
    notificationTypes: {
        page: string | undefined;
        pageSize: number;
        pages: number;
        count: number;
        data: NotificationTypeInterface[];
    };
    notificationType?: NotificationTypeConfigsFullDataInterface;
    users: UsersInterface[];
    usersLoading: boolean;
    usersError: any;
}

export interface CreateTypeConfigV1Dto {
    typeId: number;
    body?: string;
    subject?: string;
    emailFrom?: string;
    channel: Channel;
    defaultUsers?: string[];
    whatsappTemplateName?: string;
    whatsappLanguageCode?: string;
    whatsappPdfFileName?: string;
    whatsappPdfLink?: string;
    whatsappVideoUrl?: string;
    whatsappBtnUrl?: string;
    whatsappVariables?: string[];
}

export interface UpdateTypeConfigV1Dto {
    typeId: number;
    body?: string;
    subject?: string;
    emailFrom?: string;
    defaultUsers?: string[];
    whatsappTemplateName?: string;
    whatsappLanguageCode?: string;
    whatsappPdfFileName?: string;
    whatsappPdfLink?: string;
    whatsappVideoUrl?: string;
    whatsappBtnUrl?: string;
    whatsappVariables?: string[];
}

export interface NotificationTypeMinimalResponse {
    name: string;
    typeId: number;
}

export enum Portal {
    crm = 'crm',
    partners = 'partners',
    sales = 'sales',
    shares = 'shares'
}

export enum Channel {
    sms = 'sms',
    email = 'email',
    system = 'system',
    whatsapp = 'whatsapp'
}

export enum ReservedVariables {
    userName = 'userName',
    userId = 'userId'
}
