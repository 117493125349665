import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, Container, Divider, Grid, Link as MuiLink, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps, useMediaQuery, useTheme } from '@mui/system';
import logoDark from 'assets/images/logo-dark.svg';
import mobileApp1Ar from 'assets/images/mobileApp.svg';
import mobileApp2Ar from 'assets/images/mobileApp2.svg';
import mobileApp1En from 'assets/images/mobileAppEn1.svg';
import mobileApp2En from 'assets/images/mobileAppEn2.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { WEBSITE_FRONTEND } from 'store/apis';

export interface FooterProps {
    brandLogo?: string;
    hideTermsAndConditions?: boolean;
    hideCopyrights?: boolean;
    hideDownloadApp?: boolean;
    taskneenLayout?: boolean;
    socialLinks?: { facebook?: string; instagram?: string; linkedin?: string };
    sx?: SxProps;
    termsAndConditionsLink?: string;
}

const Footer: React.FC<FooterProps> = ({
    brandLogo,
    taskneenLayout = false,
    hideTermsAndConditions = false,
    hideDownloadApp = false,
    hideCopyrights = false,
    socialLinks,
    sx,
    termsAndConditionsLink
}) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { locale } = useIntl();

    socialLinks = socialLinks ?? { facebook: 'https://www.facebook.com/profile.php?id=61559664333716&mibextid=LQQJ4d' };

    const socialLinksElement = (
        <Stack
            direction="row"
            gap={2}
            justifyContent={taskneenLayout ? (isSmallScreen ? 'center' : 'start') : isSmallScreen ? 'center' : 'end'}
            width="100%"
        >
            {socialLinks?.linkedin && (
                <MuiLink href={socialLinks.linkedin} target="_blank" sx={{ color: 'white' }}>
                    <LinkedInIcon />
                </MuiLink>
            )}

            {socialLinks?.instagram && (
                <MuiLink href={socialLinks.instagram} target="_blank" sx={{ color: 'white' }}>
                    <InstagramIcon />
                </MuiLink>
            )}

            {socialLinks?.facebook && (
                <MuiLink href={socialLinks.facebook} target="_blank" sx={{ color: 'white' }}>
                    <FacebookIcon />
                </MuiLink>
            )}
        </Stack>
    );

    return (
        <Grid container sx={{ backgroundColor: theme.palette.primary[800], height: '50%', ...sx }} justifyContent="center">
            <Container maxWidth={false} sx={{ maxWidth: isSmallScreen ? '100%' : '1300px', width: '100%' }}>
                <Grid
                    container
                    gridColumn={2}
                    marginTop={isSmallScreen ? 10 : 3}
                    justifyContent="center"
                    alignContent="center"
                    sx={{ maxWidth: isSmallScreen ? '100%' : '1300px', width: '100%' }}
                >
                    <Grid
                        item
                        container
                        direction={{ lg: 'row', sm: 'column' }}
                        alignItems={{ lg: 'center', sm: 'flex-start' }}
                        marginLeft={isSmallScreen ? '' : '6px'}
                        {...(taskneenLayout
                            ? {}
                            : {
                                  xs: isSmallScreen ? 'auto' : 6,
                                  md: isSmallScreen ? 'auto' : 7
                              })}
                    >
                        <Container>
                            <Stack direction="column" alignItems={{ xs: 'center', md: 'start' }} width="100%">
                                <img
                                    src={brandLogo || logoDark}
                                    style={{
                                        width: '96px',
                                        marginBottom: '10px',
                                        alignContent: 'center'
                                    }}
                                    alt="logo dark"
                                />
                                {!hideTermsAndConditions && (
                                    <Box marginTop={2} textAlign={isSmallScreen ? 'center' : 'left'}>
                                        <Typography
                                            component={Link}
                                            to={termsAndConditionsLink ?? `${WEBSITE_FRONTEND}terms`}
                                            variant="subtitle1"
                                            color="white"
                                            sx={{ textDecoration: 'none' }}
                                            marginTop="10px"
                                        >
                                            <FormattedMessage id="terms-conditions" />
                                        </Typography>
                                    </Box>
                                )}
                            </Stack>
                        </Container>
                    </Grid>
                    {!hideDownloadApp && (
                        <Grid
                            item
                            justifyContent={isSmallScreen ? 'center' : 'right'}
                            alignItems={isSmallScreen ? 'center' : 'right'}
                            marginTop={4}
                            marginBottom={2}
                            xs={isSmallScreen ? 'auto' : 6}
                            md={isSmallScreen ? 'auto' : 4}
                        >
                            <Grid container direction={{ sm: 'row' }} justifyContent="center">
                                <Grid item>
                                    <Button
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            textTransform: 'none'
                                        }}
                                        onClick={() => window.open('https://apps.apple.com/us/app/nawy-partners/id1667156465', '_blank')}
                                    >
                                        <img
                                            src={locale === 'ar' ? mobileApp2Ar : mobileApp2En}
                                            style={{
                                                width: '135px',
                                                height: '40px'
                                            }}
                                            alt="download app 1"
                                        />
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        style={{
                                            backgroundColor: 'transparent',
                                            boxShadow: 'none',
                                            textTransform: 'none'
                                        }}
                                        onClick={() =>
                                            window.open('https://play.google.com/store/apps/details?id=com.nawy.partners&pli=1', '_blank')
                                        }
                                    >
                                        <img
                                            src={locale === 'ar' ? mobileApp1Ar : mobileApp1En}
                                            style={{
                                                width: '135px',
                                                height: '40px'
                                            }}
                                            alt="download app 2"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {isSmallScreen ? (
                    <Grid item md={12} marginBottom={3}>
                        <Grid gridRow={2} justifyContent="center" alignContent="center">
                            <Grid item md={12} marginTop={1} marginBottom={2}>
                                {socialLinksElement}
                            </Grid>
                            {!hideCopyrights && (
                                <Grid item md={12} marginBottom={3}>
                                    <Typography sx={{ color: 'white' }} align="center">
                                        <FormattedMessage id="nawy-copyright" />
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                ) : (
                    <Container>
                        <Grid item md={12} marginBottom={3}>
                            <Divider sx={{ marginTop: '2%', marginBottom: '2%' }} style={{ backgroundColor: 'white' }} />
                            <Grid container>
                                {!hideCopyrights && (
                                    <Grid item md={8}>
                                        <Typography sx={{ color: 'white' }}>
                                            <FormattedMessage id="nawy-copyright" />
                                        </Typography>
                                    </Grid>
                                )}

                                <Grid item md={4}>
                                    <Stack direction="row" gap={2}>
                                        {socialLinksElement}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                )}
            </Container>
        </Grid>
    );
};

export default Footer;
