import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import RESERVATIONS_APIS from './apis';
import {
    CreateReservationInterface,
    QueryReservationsInterface,
    UpdateReservationInterface,
    UpdateReservationPropertyInterface
} from './interfaces';
import { FlowStagesEnum, QueryFlowsInterface } from '../flows/interfaces';
import FLOWS_APIS from '../flows/apis';

export const queryReservation = createAsyncThunk(
    'reservations/query',
    async (
        requestData: {
            query: QueryReservationsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(RESERVATIONS_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getReservationById = createAsyncThunk(
    'reservations/get-by-id',
    async (
        requestData: {
            reservationId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { reservationId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(RESERVATIONS_APIS.getById(reservationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateReservation = createAsyncThunk(
    'reservations/update',
    async (
        requestData: {
            reservationId: number;
            updateData: UpdateReservationInterface;
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { reservationId, updateData, runSuccess } = requestData;
        const { documents, ...rest } = updateData;
        const formData = new FormData();

        if (documents) {
            for (const document of documents) {
                formData.append('files', document as any);
            }
        }
        formData.append('json', JSON.stringify(rest));

        try {
            const successRes = await axios.patch(RESERVATIONS_APIS.update(reservationId), formData, {
                headers: { 'Content-Type': `multipart/form-data;`, Accept: 'application/json' }
            });

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createReservation = createAsyncThunk(
    'reservations/create',
    async (
        requestData: {
            data: CreateReservationInterface;
            runSuccess?: (id: number) => void;
        },
        { rejectWithValue }
    ) => {
        const { runSuccess, data } = requestData;
        const { documents, ...rest } = data;
        const formData = new FormData();

        if (documents) {
            for (const document of documents) {
                formData.append('files', document as any);
            }
        }
        formData.append('json', JSON.stringify(rest));
        try {
            const successRes = await axios.post(RESERVATIONS_APIS.create(), formData, {
                headers: { 'Content-Type': `multipart/form-data;`, Accept: 'application/json' }
            });

            if (runSuccess) runSuccess(successRes.data.id);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateReservationProperty = createAsyncThunk(
    'reservations/update-property',
    async (
        requestData: { reservationId: number; updateData: UpdateReservationPropertyInterface; runSuccess: () => void },
        { rejectWithValue }
    ) => {
        const { reservationId, updateData, runSuccess } = requestData;

        try {
            const successRes = await axios.patch(RESERVATIONS_APIS.update(reservationId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadReservationDocument = createAsyncThunk(
    'reservations/uploadReservationDocuments',
    async (
        requestData: {
            reservationId: number;
            document: {
                name: string;
                document: any;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { reservationId, document, runSuccess } = requestData;

        const formData = new FormData();
        formData.append('name', document.name);
        formData.append('document', document.document);
        try {
            const successRes = await axios.post(RESERVATIONS_APIS.uploadReservationDocument(reservationId), formData, {
                headers: { 'Content-Type': `multipart/form-data;` }
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteReservationDocument = createAsyncThunk(
    'reservations/deleteReservationDocument',
    async (
        requestData: {
            reservationId: number;
            documentId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { reservationId, documentId, runSuccess } = requestData;

        try {
            const successRes = await axios.delete(RESERVATIONS_APIS.deleteReservationDocument(reservationId, documentId));

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const getReservationAnalytica = createAsyncThunk(
    'reservations/analytica',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;
        try {
            const { data: reservationData } = await axios.get(
                FLOWS_APIS.queryAnalyticaMin({ ...query, stage: FlowStagesEnum.Reservation })
            );
            const { data: eoiDate } = await axios.get(FLOWS_APIS.queryAnalyticaMin({ ...query, stage: FlowStagesEnum.EOI }));

            const data = {
                reservationData,
                eoiDate
            };

            if (runSuccess) runSuccess();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
