import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const VerificationPage = Loadable(lazy(() => import('views/verification')));

const VerificationRoutes = {
    path: '/verified',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: ':code',
            element: <VerificationPage />
        }
    ]
};

export default VerificationRoutes;
