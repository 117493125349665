// import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Channel } from 'store/slices/notifications/interfaces';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'store';
import { useState } from 'react';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0'
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const ChannelButton = ({ setChannel }: { setChannel: React.Dispatch<React.SetStateAction<Channel | undefined>> }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { notificationType } = useSelector((state) => state.notificationTypes);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                <FormattedMessage id="add" />
            </Button>

            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {Object.values(Channel)
                    .filter((channel) => !notificationType?.configurations.some((c) => c.channel === channel))
                    .map((channel, i) => (
                        <>
                            <MenuItem
                                onClick={() => {
                                    setChannel(channel);
                                    handleClose();
                                }}
                                disableRipple
                            >
                                {channel}
                            </MenuItem>
                            {i <
                                Object.values(Channel).filter(
                                    (channelValue) => !notificationType?.configurations.some((c) => c.channel === channelValue)
                                ).length -
                                    1 && <Divider sx={{ my: 0.5 }} />}
                        </>
                    ))}
            </StyledMenu>
        </div>
    );
};

export default ChannelButton;
