import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryActivityTypeInterface } from './interfaces';

const res = 'activity-type'; // Update the resource name

const ACTIVITY_TYPE_APIS = {
    /**
     * @GET
     * @returns typesList
     */
    query: (query: QueryActivityTypeInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default ACTIVITY_TYPE_APIS;
