import { Box, FormControl, FormLabel, Grid, Icon, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { Stack } from '@mui/system';

export default function FileBrowse({ id = '', handleAddFile }: { id?: string; handleAddFile: (inputFile: File) => void }) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const intl = useIntl();

    const [file, setFile] = useState<{ src: string; name: string; size: number; error: boolean }>();
    const [dragActive, setDragActive] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const FILE_LIMIT = 2097152;

    const handleFiles = (inputFile: File) => {
        if (inputFile.size <= FILE_LIMIT) handleAddFile(inputFile);
        else
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'file-cant-exceed-limit' }),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        setFile({ src: URL.createObjectURL(inputFile), name: inputFile.name, size: inputFile.size, error: false });
    };

    const handleRemove = (name: string) => {
        setFile(undefined);
    };

    const handleDrag: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);

        if (e?.dataTransfer?.files?.[0]) {
            handleFiles(e.dataTransfer.files[0]);
        }
    };

    function isAllowedFileType(inputFile: File): boolean {
        const allowedTypes = [
            'application/vnd.ms-excel', // .xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx, .xltx, .xlsb, .xlsm
            'application/vnd.oasis.opendocument.spreadsheet' // .ods
        ];
        return allowedTypes.includes(inputFile.type);
    }

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { target } = e;
        if (target?.files?.[0] && isAllowedFileType(target?.files?.[0])) handleFiles(target.files[0]);
        else
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: 'invalid-file-format' }),
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );

        target.value = '';
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl
                    id={`${id}-form-file-upload`}
                    onDragEnter={handleDrag}
                    onSubmit={(e) => e.preventDefault()}
                    sx={{
                        height: '200px',
                        width: '100%',
                        textAlign: 'center',
                        position: 'relative'
                    }}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                >
                    <input
                        style={{ display: 'none', height: '100%', width: '100%' }}
                        ref={inputRef}
                        type="file"
                        id={`${id}-input-file-upload`}
                        accept=".xlsx,.xltx,.xlsb,.xlsm,.ods"
                        onChange={handleChange}
                    />
                    <FormLabel
                        id={`${id}-label-file-upload`}
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderWidth: '2px',
                            borderRadius: '1rem',
                            borderStyle: 'dashed',
                            borderColor: theme.palette.primary.light,
                            backgroundColor: theme.palette.background.default
                        }}
                        htmlFor={`${id}-input-file-upload`}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Icon>
                                    <AddPhotoAlternateOutlinedIcon />
                                </Icon>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    <FormattedMessage id="drop-file-to-upload" />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ color: theme.palette.primary.main }}>
                                    <FormattedMessage id="click-here-to-browse" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </FormLabel>
                    {dragActive && (
                        <Box
                            id={`${id}-drag-file-element`}
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                        />
                    )}
                </FormControl>
            </Grid>
            {file && (
                <Grid container justifyContent="space-between">
                    <Typography>{file.name}</Typography>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Typography>{file.size / 1000000}</Typography>MB
                        <IconButton onClick={() => handleRemove(file.name || '')}>
                            <DeleteIcon color="error" />
                        </IconButton>
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
}
