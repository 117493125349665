import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import useAuth from 'hooks/useAuth';
import { useIntl } from 'react-intl';
import { gridSpacingMedium } from 'store/constant';
import { DeveloperInterface } from 'store/slices/developers/interfaces';
import InfoItem from 'ui-component/InfoItem';
import DeveloperTitle from './DeveloperTitle';
import FixedDirection from 'ui-component/FixedDirection';
import useMixPanel from 'hooks/useMixPanel';

const DevelopersContactsDetails = ({
    developer,
    open,
    onClose,
    compoundName,
    location
}: {
    developer: DeveloperInterface;
    open: boolean;
    onClose: () => void;
    compoundName?: string;
    location: string;
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const { emitEvent } = useMixPanel();
    const { user } = useAuth();
    const userName = user?.name;
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    const openWhatsApp = (supportNumber: string) => {
        emitEvent(`Click on "whatsapp" at a certain ${location === 'developers contacts page' ? 'developer' : 'compound '}`, {
            'Developer name': developer?.name,
            'Compound name': compoundName,
            location
        });
        const messageTemplate =
            intl.locale === 'en' || !intl.locale
                ? compoundName
                    ? `Hello, this is ${userName} from Nawy Partners, I would like to know more about ${compoundName} from ${developer?.name}`
                    : `Hello, this is ${userName} from Nawy Partners, I would like to know more about ${developer?.name}.`
                : compoundName
                ? `انا ${userName} بتواصل من خلال Nawy Partners لمعرفة معلومات اكثر عن ${compoundName} من ${developer?.name}.`
                : `انا ${userName} بتواصل من خلال Nawy Partners لمعرفة معلومات اكثر عن ${developer?.name}.`;

        const whatsappUrl = `https://wa.me/${supportNumber}?text=${encodeURIComponent(messageTemplate)}`;
        window.open(whatsappUrl, '_blank');
    };

    const openCallsApp = (phoneNumber: string) => {
        emitEvent(`Click on "call" at a certain ${location === 'developers contacts page' ? 'developer' : 'compound '}`, {
            'Developer name': developer?.name,
            'Compound name': compoundName,
            location
        });
        window.open(`tel:${phoneNumber}`, '_blank');
    };

    return (
        <Dialog open={open} onClose={onClose} key={developer?.name} maxWidth="sm">
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={gridSpacingMedium}>
                    <DeveloperTitle
                        developerName={(intl.locale === 'ar' ? developer?.arabicName : developer?.name) || developer?.name}
                        developerLogoUrl={developer?.logoUrl}
                    />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                {developer?.contacts && developer?.contacts.length > 0 ? (
                    <Stack spacing={gridSpacingMedium} direction="column" gap={gridSpacingMedium}>
                        {developer?.contacts.map((contact) => (
                            <InfoItem
                                label={contact.position}
                                value={
                                    <Stack direction="column">
                                        <Typography variant="h5">{contact.name}</Typography>
                                        {!matchDownSM && !matchDownMD && (
                                            <FixedDirection>
                                                <Typography fontWeight={400} variant="h4">
                                                    {contact.phone}
                                                </Typography>
                                            </FixedDirection>
                                        )}
                                    </Stack>
                                }
                                endIcon={
                                    <Stack direction="row" alignItems="center" spacing={gridSpacingMedium}>
                                        <IconButton
                                            onClick={() => openCallsApp(contact.phone)}
                                            sx={{ backgroundColor: '#F0F1F3', borderRadius: '64px' }}
                                        >
                                            <WifiCalling3Icon />
                                        </IconButton>
                                        <IconButton
                                            sx={{
                                                backgroundColor: '#4CD964',
                                                borderRadius: '64px',
                                                '&:hover': {
                                                    backgroundColor: '#4CD964'
                                                }
                                            }}
                                            onClick={() => openWhatsApp(contact.phone)}
                                        >
                                            <WhatsAppIcon sx={{ color: 'white' }} />
                                        </IconButton>
                                    </Stack>
                                }
                            />
                        ))}
                    </Stack>
                ) : (
                    <Typography variant="body1">{intl.formatMessage({ id: 'no-developer-contact-info-available' })}</Typography>
                )}
            </DialogContent>
        </Dialog>
    );
};
export default DevelopersContactsDetails;
