import { Grid } from '@mui/material';
import DevelopersContactsList from 'components/DevelopersContacts/UI/List';
import useMixPanel from 'hooks/useMixPanel';
import { useIntl } from 'react-intl';

import { gridSpacing } from 'store/constant';
import PageHeader from 'ui-component/PageHeader';

const DevelopersContacts = () => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    emitEvent('View Contact Developers page');

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <PageHeader title={intl.formatMessage({ id: 'developers-contacts' })} />
            </Grid>
            <Grid item xs={12}>
                <DevelopersContactsList />
            </Grid>
        </Grid>
    );
};
export default DevelopersContacts;
