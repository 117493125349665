import { FormikProps } from 'formik';
import { useIntl } from 'react-intl';
import { MentionsInput, Mention } from 'react-mentions';
import { useSelector } from 'store';
import { Channel, ReservedVariables } from 'store/slices/notifications/interfaces';
import { useTheme } from '@mui/material/styles';
import EmailTemplateBody from './EmailTemplateBody';

interface TemplateBodyProps {
    formik: FormikProps<any>;
    singleLine: boolean;
    readOnly: boolean;
    selectedNotificationType?: Channel;
}

const TemplateBody = ({ formik, singleLine, readOnly, selectedNotificationType }: TemplateBodyProps) => {
    const intl = useIntl();
    const { notificationType } = useSelector((state) => state.notificationTypes);
    const theme = useTheme();
    const getVariablesList = () =>
        notificationType?.requiredData
            .map((variable) => ({
                id: variable,
                display: variable
            }))
            .concat(
                Object.values(ReservedVariables).map((variable) => ({
                    id: variable,
                    display: variable
                }))
            );

    if (selectedNotificationType === Channel.email) {
        return <EmailTemplateBody formik={formik} singleLine={singleLine} readOnly={readOnly} />;
    }
    return (
        <MentionsInput
            forceSuggestionsAboveCursor
            allowSuggestionsAboveCursor
            singleLine={singleLine}
            value={formik.values.templateBody}
            onChange={(e) => {
                formik.setFieldValue('templateBody', e.target.value);
            }}
            placeholder={intl.formatMessage({ id: 'add-variables-using-@' })}
            disabled={readOnly}
            style={{
                control: {
                    height: '100%'
                },
                '&singleLine': {
                    display: 'inline-block',
                    width: '100%',
                    fontWeight: 'normal',
                    input: {
                        border: 0,
                        outline: formik.errors.templateBody
                            ? `0.5px solid ${theme.palette.error.main}`
                            : readOnly
                            ? 0
                            : `0.5px solid ${theme.palette.grey[400]}`,
                        borderRadius: '8px',
                        padding: readOnly ? 0 : '15.5px 14px',
                        color: readOnly ? theme.palette.text.primary : theme.palette.text.dark
                    },
                    highlighter: {
                        padding: readOnly ? 0 : '15.5px 14px'
                    }
                },
                '&multiLine': {
                    control: {
                        height: '157px'
                    },
                    display: 'inline-block',
                    width: '100%',
                    fontWeight: 'normal',
                    input: {
                        border: 0,
                        outline: formik.errors.templateBody
                            ? `0.5px solid ${theme.palette.error.main}`
                            : readOnly
                            ? 0
                            : `0.5px solid ${theme.palette.grey[400]}`,
                        borderRadius: '8px',
                        padding: readOnly ? 0 : '15.5px 14px',
                        color: readOnly ? theme.palette.text.primary : theme.palette.text.dark,
                        overflowY: 'hidden'
                    },
                    highlighter: {
                        padding: readOnly ? 0 : '15.5px 14px',
                        width: '90%',
                        height: '100%',
                        overflowY: 'hidden'
                    }
                },
                suggestions: {
                    backgroundColor: `${theme.palette.background.paper}`,
                    border: `1px solid ${theme.palette.action.disabledBackground}`,
                    boxShadow: `0 0 8px ${theme.palette.action.disabled}`,
                    width: 'max-content',
                    maxHeight: 300,
                    zIndex: '20',
                    top: 0,
                    overflow: 'auto',
                    list: {
                        backgroundColor: 'white',
                        border: '1px solid rgba(0,0,0,0.15)',
                        fontSize: 14
                    },
                    item: {
                        padding: '5px 15px',
                        borderBottom: '1px solid rgba(0,0,0,0.15)',
                        '&focused': {
                            backgroundColor: `${theme.palette.action.hover}`
                        }
                    }
                }
            }}
        >
            <Mention
                displayTransform={(id: string, display: string) => `{${display}}`}
                style={{ background: 'unset' }}
                trigger="@"
                data={getVariablesList}
            />
        </MentionsInput>
    );
};

export default TemplateBody;
