import { createSlice } from '@reduxjs/toolkit';
import { PerfectFilterStateInterface } from './interfaces';
import { getCompoundsFilter, getDevelopersFilter, getPropertyTypesFilter } from './actions';

// initial state
const initialState: PerfectFilterStateInterface = {
    propertyTypes: {
        data: [],
        count: 0,
        page: 0,
        pages: 0,
        pageSize: 0
    },
    propertyTypesLoading: false,
    propertyTypesError: false,
    developers: {
        data: [],
        count: 0,
        page: 0,
        pages: 0,
        pageSize: 0
    },
    developersLoading: false,
    developersError: false,
    compounds: {
        data: [],
        count: 0,
        page: 0,
        pages: 0,
        pageSize: 0
    },
    compoundsLoading: false,
    compoundsError: false,
    tiers: {
        data: [],
        count: 0,
        page: 0,
        pages: 0,
        pageSize: 0
    },
    tiersLoading: false,
    tiersError: false
};

// ==============================|| SLICE - filters ||============================== //

const filters = createSlice({
    name: 'filters',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getPropertyTypesFilter.pending, (state) => {
                state.propertyTypesLoading = true;
                state.propertyTypesError = false;
            })
            .addCase(getPropertyTypesFilter.fulfilled, (state, action) => {
                state.propertyTypesLoading = false;
                state.propertyTypes = action.payload;
            })
            .addCase(getPropertyTypesFilter.rejected, (state, action) => {
                state.propertyTypesLoading = false;
                state.propertyTypesError = action.payload;
            })
            .addCase(getDevelopersFilter.pending, (state) => {
                state.developersLoading = true;
                state.developersError = false;
            })
            .addCase(getDevelopersFilter.fulfilled, (state, action) => {
                state.developersLoading = false;
                state.developers = action.payload;
            })
            .addCase(getDevelopersFilter.rejected, (state, action) => {
                state.developersLoading = false;
                state.developersError = action.payload;
            })
            .addCase(getCompoundsFilter.pending, (state) => {
                state.compoundsLoading = true;
                state.compoundsError = false;
            })
            .addCase(getCompoundsFilter.fulfilled, (state, action) => {
                state.compoundsLoading = false;
                state.compounds = action.payload;
            })
            .addCase(getCompoundsFilter.rejected, (state, action) => {
                state.compoundsLoading = false;
                state.compoundsError = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default filters.reducer;
