// hooks
import { useEffect, useRef, useState } from 'react';
import { dispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';

// constants
import { gridSpacing } from 'store/constant';

// action
import { openSnackbar } from 'store/slices/snackbar';

// third party libraries
import * as csc from 'country-state-city';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useTheme } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { createPartnersInvitations, queryInvitationTypes } from 'store/slices/partnerInvitations/actions';
import SubCard from 'ui-component/cards/SubCard';

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import useAuth from 'hooks/useAuth';
import { InvitationTypeEnum } from 'store/interfaces';
import { InvitationTypesInterface } from 'store/slices/invitationTypes/interfaces';
import { InvitePartnerDialogProps } from '..';
import { createContactInfoSchema, createLocationInfoSchema } from '../utils/createSchemas';
import useOverlay from 'hooks/useOverlay';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export default function CreatePartnerInvitationPublicDialog({ showTrigger = false, fullWidth, variant }: InvitePartnerDialogProps) {
    const intl = useIntl();

    const { isCurrentOpened, open, close } = useOverlay('InvitePartner');

    const theme = useTheme();
    const { error, types } = useSelector((state) => state.partnerInvitations);

    const [selectedInvitationType, setSelectedInvitationType] = useState<InvitationTypesInterface | null>(null);
    const { user } = useAuth();
    const [limited, setLimited] = useState(false);

    const inviteAllGroups = [InvitationTypeEnum.accountManagers, InvitationTypeEnum.admins];

    // const [open, setOpen] = useState(false);
    const ContactInfoSchema = yup
        .object()
        .required()
        .shape({
            title: yup.string().required(intl.formatMessage({ id: 'contact-info-title-required' })),
            name: yup.string().required(intl.formatMessage({ id: 'contact-info-name-required' })),
            phone: yup
                .number()
                .typeError(intl.formatMessage({ id: 'invalid-phone' }))
                .required(intl.formatMessage({ id: 'phone-required' })),
            email: yup.string().email(intl.formatMessage({ id: 'contact-info-email-format' }))
        });
    const LocationInfoSchema = yup
        .object()
        .required()
        .shape({
            type: yup.string().required(intl.formatMessage({ id: 'location-type-required' })),
            area: yup.string().required(intl.formatMessage({ id: 'location-area-required' })),
            city: yup.string().required(intl.formatMessage({ id: 'location-city-required' })),
            country: yup.string().required(intl.formatMessage({ id: 'location-country-required' })),
            address: yup.string().required(`${intl.formatMessage({ id: 'location-address' })} ${intl.formatMessage({ id: 'is-required' })}`)
        });

    const CreatePartnerInvitationSchema = yup.object().shape({
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'phone-invalid' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        officialNameRequired: yup.boolean().nullable(),
        officialName: yup.string().when('officialNameRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'official-name-required' })),
            otherwise: yup.string().nullable()
        }),
        invitationTypeId: yup.string().required(intl.formatMessage({ id: 'invitation-type-required' })),
        contactInfoRequired: yup.boolean().nullable(),
        locationRequired: yup.boolean().nullable(),
        taxIdRequired: yup.boolean().nullable(),
        commercialCardIdRequired: yup.boolean().nullable(),
        personalIdRequired: yup.boolean().nullable(),
        taxId: yup.string().when('taxIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'tax-id-no-required' })),
            otherwise: yup.string().nullable()
        }),
        commercialCardId: yup.string().when('commercialCardIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'commercial-register-no-required' })),
            otherwise: yup.string().nullable()
        }),
        personalId: yup.string().when('personalIdRequired', {
            is: true,
            then: yup.string().required(intl.formatMessage({ id: 'national-id-no-required' })),
            otherwise: yup.string().nullable()
        }),
        contactInfo: yup.object().when('contactInfoRequired', {
            is: true,
            then: createContactInfoSchema(intl, true),
            otherwise: createContactInfoSchema(intl, false)
        }),
        location: yup.object().when('locationRequired', {
            is: true,
            then: createLocationInfoSchema(intl, true),
            otherwise: createLocationInfoSchema(intl, false)
        })
    });

    const handleClickOpen = () => {
        // setOpen(true);
        open();
    };

    const handleClose = () => {
        formik.resetForm();
        setLimited(false);
        setSelectedInvitationType(null);
        // setOpen(false);
        close();
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    // useEffect(() => {
    //     if (open) {
    //         const { current: descriptionElement } = descriptionElementRef;
    //         if (descriptionElement !== null) {
    //             descriptionElement.focus();
    //         }
    //     }
    // }, [open]);
    const handleGetCountryCode = () => {
        const countryCode = csc.Country.getAllCountries().find((country) => country.name === formik.values.location.country)?.isoCode;
        if (countryCode) return countryCode;
        return '';
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const initialValues = {
        phone: '',
        invitationTypeId: selectedInvitationType?.id || '',
        country: 'EG',
        officialName: undefined,
        officialNameRequired: selectedInvitationType?.officialName || null,
        contactInfoRequired: selectedInvitationType?.contactInfo || null,
        locationRequired: selectedInvitationType?.locationInfo || null,
        taxIdRequired: selectedInvitationType?.taxId || null,
        commercialCardIdRequired: selectedInvitationType?.commercialCardId || null,
        personalIdRequired: selectedInvitationType?.personalId || null,
        contactInfo: {
            title: '',
            name: '',
            phone: undefined,
            email: undefined
        },
        location: {
            type: '',
            country: '',
            area: '',
            city: '',
            address: ''
        },
        taxId: '',
        commercialCardId: '',
        personalId: ''
    };

    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);

            const isPhoneValid = parsePhoneNumber(values.phone, 'EG').isValid();
            if (!isPhoneValid) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }
            if (values?.contactInfo?.phone) {
                const isContactInfoValid = parsePhoneNumber(values?.contactInfo?.phone, 'EG').isValid();
                if (!isContactInfoValid) {
                    formikHelpers.setFieldError('contactInfo.phone', intl.formatMessage({ id: 'phone-invalid' }));
                    formikHelpers.setSubmitting(false);
                    return;
                }
            }

            dispatch(
                createPartnersInvitations({
                    createBody: {
                        phone: values.phone.replaceAll(' ', ''),
                        contactInfo: values.contactInfo.title ? values.contactInfo : undefined,
                        invitationTypeId: selectedInvitationType?.id,
                        locationInfo: values.location.country ? values.location : undefined,
                        officialName: values.officialName,
                        taxId: values.taxId,
                        commercialCardId: values.commercialCardId,
                        personalId: values.personalId
                    },
                    runSuccess
                })
            );
        },
        validationSchema: CreatePartnerInvitationSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        dispatch(queryInvitationTypes({ query: { page: 1, pageSize: 1000, public: true } }));
    }, []);
    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    useEffect(() => {
        if (!inviteAllGroups.some((group) => user?.groups?.includes(group))) {
            setSelectedInvitationType(types.data?.find((group) => group.name === 'Ambassador') || null);
            setLimited(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [types]);

    const showBusinessInfo =
        selectedInvitationType?.personalId !== null ||
        selectedInvitationType?.commercialCardId !== null ||
        selectedInvitationType?.taxId !== null;

    return (
        <>
            {showTrigger && (
                <Button
                    fullWidth={fullWidth}
                    variant={variant || 'outlined'}
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={handleClickOpen}
                >
                    <FormattedMessage id="invite" /> <FormattedMessage id="partner" />
                </Button>
            )}
            <Dialog
                open={isCurrentOpened}
                onClose={handleClose}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="invite-partner"
                aria-describedby="invite-partner"
            >
                <DialogTitle id="invite-partner">
                    <FormattedMessage id="create" /> <FormattedMessage id="invitation" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        {limited ? (
                            <Grid item xs={12}>
                                <Typography>
                                    <FormattedMessage id="invite" /> <FormattedMessage id="partner" />
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Select
                                    name="invitationTypeId"
                                    fullWidth
                                    displayEmpty
                                    id="invitationTypeId"
                                    value={formik.values.invitationTypeId}
                                    onChange={(event) => {
                                        const cLimit = types.data.find((type) => type.id.toString() === event.target.value.toString());
                                        if (cLimit) setSelectedInvitationType(cLimit);
                                        else setSelectedInvitationType(null);
                                    }}
                                    onBlur={formik.handleBlur}
                                    onReset={formik.handleReset}
                                    error={Boolean(formik.touched.invitationTypeId && formik.errors.invitationTypeId)}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem disabled value="">
                                        Select Invitation Type
                                    </MenuItem>
                                    {types.data.map((type) => (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.location?.country && (
                                    <FormHelperText sx={{ color: theme.palette.error.main }}>
                                        {formik.errors.invitationTypeId}
                                    </FormHelperText>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <MuiTelInput
                                dir="ltr"
                                // fullWidth
                                disabled={!selectedInvitationType}
                                id="invite-partner-phone"
                                name="phone"
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                defaultCountry="EG"
                                label={`${intl.formatMessage({ id: 'phone' })} *`}
                                value={formik.values.phone}
                                onChange={(v) => formik.setFieldValue('phone', v)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>

                        {!!selectedInvitationType && selectedInvitationType.officialName !== null && (
                            <Grid item xs={12}>
                                <TextField
                                    label={`${intl.formatMessage({ id: 'brokerage' })} ${intl.formatMessage({ id: 'official-name' })} *`}
                                    fullWidth
                                    type="text"
                                    name="officialName"
                                    id="create-organization-officialName"
                                    value={formik.values.officialName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={Boolean(formik.touched.officialName && formik.errors.officialName)}
                                    helperText={formik.touched.officialName && formik.errors.officialName}
                                />
                            </Grid>
                        )}
                        {!!selectedInvitationType && selectedInvitationType.contactInfo !== null && (
                            <Grid item xs={12}>
                                <SubCard title="Contact Information">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'title' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.title"
                                                id="create-organization-contactInfo-title"
                                                value={formik.values.contactInfo?.title}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.title && formik.errors?.contactInfo?.title)}
                                                helperText={formik.touched?.contactInfo?.title && formik.errors?.contactInfo?.title}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'name' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.name"
                                                id="create-organization-contactInfo.name"
                                                value={formik.values.contactInfo?.name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.name && formik.errors.contactInfo?.name)}
                                                helperText={formik.touched.contactInfo?.name && formik.errors.contactInfo?.name}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <MuiTelInput
                                                dir="ltr"
                                                fullWidth
                                                defaultCountry="EG"
                                                forceCallingCode
                                                focusOnSelectCountry
                                                disableFormatting
                                                excludedCountries={['IL']}
                                                name="contactInfo.phone"
                                                id="create-organization-contactInfo.phone"
                                                value={formik.values.contactInfo?.phone || ''}
                                                onChange={(v) => formik.setFieldValue('contactInfo.phone', v)}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone)}
                                                helperText={formik.touched.contactInfo?.phone && formik.errors.contactInfo?.phone}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'email' })} *`}
                                                fullWidth
                                                type="text"
                                                name="contactInfo.email"
                                                id="create-organization-contactInfo.email"
                                                value={formik.values.contactInfo?.email || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.contactInfo?.email && formik.errors.contactInfo?.email)}
                                                helperText={formik.touched.contactInfo?.email && formik.errors.contactInfo?.email}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                        {!!selectedInvitationType && selectedInvitationType.locationInfo !== null && (
                            <Grid item xs={12}>
                                <SubCard title="Location">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'type' })} *`}
                                                name="location.type"
                                                fullWidth
                                                id="contact-info-type"
                                                value={formik.values.location.type || ''}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                error={Boolean(formik.touched.location?.type && formik.errors.location?.type)}
                                                helperText={formik.touched.location?.type && formik.errors.location?.type}
                                                select
                                            >
                                                <MenuItem value="Head Quartes">Head Quartes</MenuItem>
                                                <MenuItem value="Secondary">Secondary</MenuItem>
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'area' })} *`}
                                                fullWidth
                                                type="text"
                                                name="location.area"
                                                id="create-organization-location.area"
                                                value={formik.values.location?.area}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.location?.area && formik.errors.location?.area)}
                                                helperText={formik.touched.location?.area && formik.errors.location?.area}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                name="location.country"
                                                fullWidth
                                                displayEmpty
                                                id="create-organization-location-country"
                                                value={formik.values.location.country}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                error={Boolean(formik.touched.location?.country && formik.errors.location?.country)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled value="">
                                                    <FormattedMessage id="select" /> <FormattedMessage id="country" />
                                                </MenuItem>
                                                {csc.Country.getAllCountries().map((country) => (
                                                    <MenuItem key={country.isoCode} value={country.name}>
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.location?.country && (
                                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                    {formik.errors.location?.country}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Select
                                                name="location.city"
                                                fullWidth
                                                displayEmpty
                                                id="create-organization-location-city"
                                                value={formik.values.location.city}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                onReset={formik.handleReset}
                                                disabled={!handleGetCountryCode()}
                                                error={Boolean(formik.touched.location?.city && formik.errors.location?.city)}
                                                MenuProps={MenuProps}
                                            >
                                                <MenuItem disabled value="">
                                                    <FormattedMessage id="select-city" />
                                                </MenuItem>
                                                {csc.City.getCitiesOfCountry(handleGetCountryCode())?.map((city) => (
                                                    <MenuItem key={city.name} value={city.name}>
                                                        {city.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {formik.touched.location?.city && (
                                                <FormHelperText sx={{ color: theme.palette.error.main }}>
                                                    {formik.errors.location?.city}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label={`${intl.formatMessage({ id: 'address' })}`}
                                                fullWidth
                                                type="text"
                                                multiline
                                                minRows={2}
                                                name="location.address"
                                                id="create-organization-location.address"
                                                value={formik.values.location?.address}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={Boolean(formik.touched.location?.address && formik.errors.location?.address)}
                                                helperText={formik.touched.location?.address && formik.errors.location?.address}
                                            />
                                        </Grid>
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                        {!!selectedInvitationType && showBusinessInfo && (
                            <Grid item xs={12}>
                                <SubCard title="Business Information">
                                    <Grid container py={1} spacing={gridSpacing}>
                                        {selectedInvitationType.taxId !== null && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'tax-card-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="taxId"
                                                    id="create-organization-businessInfo-taxId"
                                                    value={formik.values.taxId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.taxId && formik.errors?.taxId)}
                                                    helperText={formik.touched?.taxId && formik.errors?.taxId}
                                                />
                                            </Grid>
                                        )}

                                        {selectedInvitationType.commercialCardId !== null && (
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'commercial-register-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="commercialCardId"
                                                    id="create-organization-businessInfo.commericalCardId"
                                                    value={formik.values.commercialCardId}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.commercialCardId && formik.errors.commercialCardId)}
                                                    helperText={formik.touched.commercialCardId && formik.errors.commercialCardId}
                                                />
                                            </Grid>
                                        )}

                                        {selectedInvitationType.personalId !== null && (
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={`${intl.formatMessage({ id: 'national-id-no' })} *`}
                                                    fullWidth
                                                    type="text"
                                                    name="personalId"
                                                    id="create-organization-businessInfo.personalId"
                                                    value={formik.values.personalId || ''}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.personalId && formik.errors.personalId)}
                                                    helperText={formik.touched.personalId && formik.errors.personalId}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </SubCard>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
