import { FAUNA_API, queryData } from 'store/apis';
import { QueryPartnerInvitationsInterface } from './interfaces';

const PARTNER_INVITATIONS_COLLECTION = 'invitations';

const PARTNER_INVITATIONS = {
    /**
     * @Get
     * @Query QueryPartnerInvitationsInterface
     * @return PartnersInvitationsListInterface
     */
    query: (query: QueryPartnerInvitationsInterface) => {
        const formatQuery: string[] = [FAUNA_API, PARTNER_INVITATIONS_COLLECTION, '?'];
        const queryValues = Object.values(query);

        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i] !== undefined) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formatQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });

        return formatQuery.join('');
    },
    /**
     * @Post
     * @Body CreatePartnerInvitationInterface
     * @return PartnerInvitationInterface
     */
    create: () => `${FAUNA_API}${PARTNER_INVITATIONS_COLLECTION}`,
    /**
     * @Patch
     * @Body UpdateTierInvitationLimitInterface
     * @returns PartnerInvitationInterface
     */
    cancel: (id: number) => `${FAUNA_API}${PARTNER_INVITATIONS_COLLECTION}/${id}`,
    /**
     * @Patch
     * @Body UpdateTierInvitationLimitInterface
     * @returns PartnerInvitationInterface
     */
    resend: (id: number) => `${FAUNA_API}${PARTNER_INVITATIONS_COLLECTION}/${id}/resend`,
    /**
     * @Delete
     * @param id
     * @returns
     */
    delete: (id: number) => `${FAUNA_API}${PARTNER_INVITATIONS_COLLECTION}/${id}`,
    /**
     * @GET
     * @returns
     */
    analytica: () => `${FAUNA_API}${PARTNER_INVITATIONS_COLLECTION}/analytica`
};
export default PARTNER_INVITATIONS;
