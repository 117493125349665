import { TableCell, Typography } from '@mui/material';
import { format } from 'date-fns';
import { ChangeEvent } from 'react';
import { useIntl } from 'react-intl';
import { SystemUserFullDataInterface } from 'store/slices/users/interfaces';
import InfoItem from 'ui-component/InfoItem';
import StyledTableRow from 'ui-component/Tables/Rows/StyledTableRow';
import Chip from 'ui-component/extended/Chip';

export default function SystemUserRow({
    systemUser,
    selectValue,
    checked,
    handleSelect
}: {
    systemUser: SystemUserFullDataInterface;
    selectValue?: string;
    checked?: boolean;
    handleSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
    const intl = useIntl();

    return (
        <StyledTableRow entity={systemUser} handleSelect={handleSelect} checked={checked} selectValue={selectValue}>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                <Typography variant="h4">{systemUser.name}</Typography>
            </TableCell>
            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {systemUser.phone}
            </TableCell>

            <TableCell align="left">
                {systemUser.banned ? (
                    <Chip size="small" label={intl.formatMessage({ id: 'yes' })} variant="outlined" chipcolor="success" />
                ) : (
                    <Chip size="small" variant="outlined" label={intl.formatMessage({ id: 'no' })} chipcolor="error" />
                )}
            </TableCell>
            <TableCell align={systemUser.broker ? 'left' : 'center'} sx={{ textTransform: 'capitalize' }}>
                {systemUser.broker ? (
                    <>
                        <InfoItem label={intl.formatMessage({ id: 'org-name' })} value={systemUser.broker.organization?.name} />
                        <InfoItem label={intl.formatMessage({ id: 'tier' })} value={systemUser.broker.organization?.tier?.name} />
                    </>
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
                {systemUser.accountManager ? (
                    <Chip size="small" label={intl.formatMessage({ id: 'true' })} variant="outlined" chipcolor="success" />
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="center">
                {systemUser.salesAgent ? (
                    <Chip size="small" label={intl.formatMessage({ id: 'true' })} variant="outlined" chipcolor="success" />
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="center">
                {systemUser.operationManagers ? (
                    <Chip size="small" label={intl.formatMessage({ id: 'true' })} variant="outlined" chipcolor="success" />
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="center">
                {systemUser.inventoryManager ? (
                    <Chip size="small" label={intl.formatMessage({ id: 'true' })} variant="outlined" chipcolor="success" />
                ) : (
                    '-'
                )}
            </TableCell>
            <TableCell align="left">{format(new Date(systemUser.createdAt), 'dd MMM yyyy')}</TableCell>
            <TableCell align="left">{format(new Date(systemUser.updatedAt), 'dd MMM yyyy')}</TableCell>
        </StyledTableRow>
    );
}
