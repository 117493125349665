import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, IconButton, ImageListItem, ImageListItemBar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { UploadErrorInterface } from 'store/slices/organizations/interfaces';

interface ImagesPreviewProps {
    images: { src: string; name: string; file?: File }[];
    errors?: UploadErrorInterface[];
    handleRestartUpload: (name: string) => void;
    handleRemoveImage?: (name: string) => void;
    showDeleteButton?: boolean;
}

const ImagesPreview = ({ images, errors, handleRestartUpload, handleRemoveImage, showDeleteButton = true }: ImagesPreviewProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(2),
                alignItems: 'center',
                justifyContent: isSmallScreen ? 'center' : 'flex-start'
            }}
        >
            {images.map((image, index) => {
                const imageError = errors?.find((error) => error.name === image.name);

                return (
                    <Box key={image.name} sx={{ width: 84, height: 84 }}>
                        <ImageListItem>
                            <img
                                alt={`img-${index}-${image.name}`}
                                src={image.src}
                                style={{ width: 84, height: 84, maxHeight: 84, objectFit: 'cover' }}
                            />
                            <ImageListItemBar
                                title={image.name}
                                sx={{
                                    '& .MuiImageListItemBar-title	': { fontSize: '.625rem' },
                                    '& .MuiImageListItemBar-titleWrap': {
                                        padding: theme.spacing(0.5),
                                        paddingInlineStart: theme.spacing(1)
                                    }
                                }}
                                actionIcon={
                                    imageError ? (
                                        <IconButton
                                            size="small"
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)', width: 18, height: 18 }}
                                            aria-label={`info about ${image.name}`}
                                            onClick={() => handleRestartUpload(image.name)}
                                        >
                                            <RestartAltIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton
                                            size="small"
                                            sx={{ color: 'rgba(255, 255, 255, 0.54)', width: 18, height: 18 }}
                                            aria-label={`info about ${image.name}`}
                                            onClick={() => handleRemoveImage?.(image.name)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )
                                }
                            />
                        </ImageListItem>
                        {imageError && (
                            <Typography sx={{ fontSize: '.625rem' }} color="error">
                                {imageError.error}
                            </Typography>
                        )}
                    </Box>
                );
            })}
        </Box>
    );
};

export default ImagesPreview;
