import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryCommissionsInterface } from './interfaces';

const COMMISSIONS_COLLECTION = 'commissions';

const COMMISSIONS = {
    /**
     * @Post
     * @Body CreateCommissionInterface
     * @return CommissionsInterface
     */
    create: () => `${FAUNA_API}${COMMISSIONS_COLLECTION}`,

    /**
     * @Post
     * @Body CreateCommissionInterface
     * @return CommissionsInterface
     */

    createBatch: () => `${FAUNA_API}${COMMISSIONS_COLLECTION}/batch`,

    /**
     *
     * @Get
     * query commissions
     * @param query
     * @returns CommissionsListInterface
     */
    query: (query: QueryCommissionsInterface) => {
        const formatQuery: string[] = [FAUNA_API, COMMISSIONS_COLLECTION, '?'];
        return formattedQuery(formatQuery, query);
    },

    /**
     * @Get
     * query commissions by id
     * @param id
     * @returns CommissionsListInterface
     */

    getById: (id: number) => `${FAUNA_API}${COMMISSIONS_COLLECTION}/${id}`,

    /**
     * @Patch
     * @Body UpdateCommissionInterface
     * @returns CommissionsInterface
     */
    update: (id: number) => `${FAUNA_API}${COMMISSIONS_COLLECTION}/${id}`,
    /**
     * @Delete
     * @param id
     * @returns
     */
    delete: (id: number) => `${FAUNA_API}${COMMISSIONS_COLLECTION}/${id}`
};
export default COMMISSIONS;
