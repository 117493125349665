import { createSlice } from '@reduxjs/toolkit';
import { ILeadsInitialState } from './interfaces';
import { createPartnerAsLead, createPartnerAsLeadV2, createTaskeenLead, getLeadAnalytica, queryLead } from './actions';

const initialState: ILeadsInitialState = {
    loading: false,
    error: false,
    errorLeads: false,
    loadingLeads: false,
    refresh: false,
    leads: {
        totalLeadsCount: 0,
        leads: []
    },
    analytica: {
        total: 0,
        progress: 0,
        sale: 0
    },
    analyticaError: false,
    analyticaLoading: false
};

const leads = createSlice({
    name: 'leads',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryLead.pending, (state) => {
                state.loadingLeads = true;
                state.errorLeads = false;
            })
            .addCase(queryLead.fulfilled, (state, action) => {
                state.loadingLeads = false;
                state.leads = action.payload;
            })
            .addCase(queryLead.rejected, (state, action) => {
                state.loadingLeads = false;
                state.errorLeads = action.payload;
            })
            .addCase(getLeadAnalytica.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getLeadAnalytica.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analytica = action.payload;
            })
            .addCase(getLeadAnalytica.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })

            .addCase(createPartnerAsLead.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createPartnerAsLead.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(createPartnerAsLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(createPartnerAsLeadV2.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createPartnerAsLeadV2.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(createPartnerAsLeadV2.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createTaskeenLead.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createTaskeenLead.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(createTaskeenLead.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

export default leads.reducer;
