import { lazy } from 'react';

import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import LandingPage from 'views/landing-page';
import BecomePartnerPage from 'views/become-partner';
import LoginV2 from 'views/auth/LoginV2';

const AuthLogin = Loadable(lazy(() => import('views/auth/Login/index')));
const AuthRegister = Loadable(lazy(() => import('views/auth/Register/index')));
const AuthForgotPassword = Loadable(lazy(() => import('views/auth/ForgotPassword/index')));
const AuthConfirmPassword = Loadable(lazy(() => import('views/auth/ConfirmPassword/index')));
const BecomeFreelancerPage = Loadable(lazy(() => import('views/become-freelancer')));
const BecomeFranchisePage = Loadable(lazy(() => import('views/become-franchise')));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <LandingPage />
        },
        {
            path: '/login',
            element: <LandingPage />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/confirmPassword',
            element: <AuthConfirmPassword />
        },

        {
            path: '/auth',
            element: <AuthLogin />
        },
        {
            path: '/landingPage',
            element: <LandingPage />
        },
        {
            path: '/becomePartner',
            element: <BecomePartnerPage />
        },
        {
            path: '/become-freelancer',
            element: <BecomeFreelancerPage />
        },
        {
            path: '/become-franchise',
            element: <BecomeFranchisePage />
        },
        {
            path: '/loginV2',
            element: <LoginV2 />
        }
    ]
};

export default AuthRoutes;
