import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface NewDisclaimerProps {
    message: ReactNode;
    icon?: ReactNode;
    bgColor?: string;
    textColor?: string;
    iconColor?: string;
    mb?: number;
}

const NewDisclaimer = ({
    message,
    icon = <WarningAmberIcon />,
    bgColor = '#FFF4E5',
    textColor = '#663C00',
    iconColor = '#663C00',
    mb = 2
}: NewDisclaimerProps) => (
    <Paper
        elevation={0}
        sx={{
            backgroundColor: bgColor,
            mb,
            borderRadius: 2,
            border: '1px solid',
            borderColor: 'warning.300'
        }}
    >
        <Box sx={{ p: 2 }}>
            <Stack direction="row" spacing={2} alignItems="center">
                <Box sx={{ color: iconColor, display: 'flex', alignItems: 'center' }}>{icon}</Box>
                <Typography
                    variant="body2"
                    sx={{
                        color: textColor,
                        flex: 1
                    }}
                >
                    {message}
                </Typography>
            </Stack>
        </Box>
    </Paper>
);

export default NewDisclaimer;
