import { Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import InfoItem from 'ui-component/InfoItem';
import SubCard from 'ui-component/cards/SubCard';
import { Link } from 'react-router-dom';
import { CreateOrUpdateCRMBtn } from 'components/CRMControl/Actions/CreateOrUpdateCRMBtn';
import { format } from 'date-fns';

const CRMControlCardView = () => {
    const { crms } = useSelector((state) => state.crmControl);

    return (
        <Grid container spacing={gridSpacing}>
            {crms.data.map((crm) => (
                <Grid item xs={12} key={crm.id}>
                    <Card crm={crm} />
                </Grid>
            ))}
        </Grid>
    );
};
const Card = ({ crm }: { crm: CRMControlInterface }) => {
    const intl = useIntl();

    // DONE: there should be a version of the dialog with the btn
    return (
        <SubCard title={<Typography fontWeight={700}>{crm.name}</Typography>} secondary={<CreateOrUpdateCRMBtn crm={crm} />}>
            <Link style={{ color: 'inherit', textDecoration: 'none' }} to={`/crms/${crm.id}`}>
                <Grid container spacing={gridSpacing}>
                    <Grid item container alignItems="start">
                        <Grid item xs={6}>
                            <InfoItem label={intl.formatMessage({ id: 'crm-id' })} value={crm.id || '-'} />
                        </Grid>
                        <Grid xs={6}>
                            <InfoItem label={intl.formatMessage({ id: 'agents' })} value={crm?._count.salesAgents || '-'} />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={gridSpacing} alignItems="start">
                        <Grid item xs={6}>
                            <InfoItem label={intl.formatMessage({ id: 'created-by' })} value={crm?.createdBy?.name?.trim() || '-'} />
                        </Grid>
                        <Grid item xs={6}>
                            <InfoItem
                                label={intl.formatMessage({ id: 'created-at' })}
                                value={format(new Date(crm?.createdAt), 'dd-MM-yyyy')}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Link>
        </SubCard>
    );
};
export default CRMControlCardView;
