import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OverlayState, OverlayTypeArg } from './types';

const initialState: OverlayState[] = [];

const overlaysSlice = createSlice({
    name: 'overlays',
    initialState,
    reducers: {
        openOverlay: (state, action: PayloadAction<{ type: OverlayTypeArg; payload?: any }>) => {
            const { type, payload } = action.payload;
            const index = state.findIndex((overlay) => overlay.type === type);
            if (index !== -1) {
                state[index] = { ...state[index], isOpen: true, payload };
            } else {
                state.push({ type, isOpen: true, payload });
            }
        },
        closeOverlay: (state, action: PayloadAction<{ type: OverlayTypeArg }>) => {
            const { type } = action.payload;
            const index = state.findIndex((overlay) => overlay.type === type);
            if (index !== -1) {
                state[index] = { ...state[index], isOpen: false, payload: undefined };
            }
        },
        closeAllOverlays: (state) => {
            state.forEach((overlay) => {
                overlay.isOpen = false;
            });
        }
    }
});

export const { openOverlay, closeOverlay, closeAllOverlays } = overlaysSlice.actions;
export default overlaysSlice.reducer;
