import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from '@mui/material';
import useOverlay from 'hooks/useOverlay';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import { deactivateAccountManager, getAccountManagerById } from 'store/slices/accountManagers/actions';
import { AccountManagerFullDataInterface } from 'store/slices/accountManagers/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import { changeUserActivity, findOne } from 'store/slices/users/actions';
import NewDisclaimer from 'ui-component/NewDisclaimer';

const DeactivateAccountDialog = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const { isCurrentOpened, close } = useOverlay('DeactivateAccountDialog');
    const navigate = useNavigate();

    const [state, setState] = useState({
        userId: '',
        hasOrganizations: false,
        loading: false
    });

    const handleDeactivate = async () => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            const banRes = await dispatch(
                changeUserActivity({
                    id: state.userId,
                    runSuccess: () => {
                        setState({
                            userId: '',
                            hasOrganizations: false,
                            loading: false
                        });
                        close();
                        dispatch(
                            openSnackbar({
                                open: true,
                                message: 'User successfully deactivated',
                                variant: 'alert',
                                alert: {
                                    color: 'success'
                                },
                                close: false
                            })
                        );
                    }
                })
            );
            if (banRes.payload.error) {
                setState((prev) => ({ ...prev, loading: false }));
            } else {
                const deactivateRes = await dispatch(
                    deactivateAccountManager({
                        accountManagerCId: state.userId,
                        runSuccess: () => {
                            setState((prev) => ({ ...prev, loading: false }));
                        }
                    })
                );
                if (deactivateRes.payload.error) {
                    setState((prev) => ({ ...prev, loading: false }));
                }
            }
        } catch (error) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Error deactivating account',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
        }
    };

    const handleReassign = async () => {
        setState((prev) => ({ ...prev, loading: true }));
        try {
            close();
            navigate(`/admin/users/${state.userId}?tab=organizations&fullData=true`);
        } catch (error) {
            console.error('Error reassigning account:', error);
        } finally {
            setState((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (!isCurrentOpened) return;

            setState((prev) => ({ ...prev, loading: true, hasOrganizations: false }));
            const uid = location.pathname.split('/').pop();

            if (!uid) {
                setState((prev) => ({ ...prev, loading: false }));
                return;
            }

            try {
                const userResponse = await dispatch(
                    findOne({
                        id: uid,
                        runSuccess: () => setState((prev) => ({ ...prev, userId: uid }))
                    })
                );

                if (userResponse.payload?.group === InvitationTypeEnum.accountManagers) {
                    const amResponse = await dispatch(getAccountManagerById({ accountManagerCId: uid }));
                    const accountManager = amResponse.payload as AccountManagerFullDataInterface;

                    setState((prev) => ({
                        ...prev,
                        hasOrganizations: accountManager?._count?.organizations > 0
                    }));
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setState((prev) => ({ ...prev, loading: false }));
            }
        };

        fetchUserData();
    }, [isCurrentOpened, location.pathname, dispatch]);

    const accountManagerHasOrganizations = state.hasOrganizations;

    return (
        <Dialog
            open={isCurrentOpened}
            onClose={() => close()}
            aria-labelledby="Deactivate Account"
            aria-describedby="Deactivate Account"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" sx={{ px: 3, py: 2 }}>
                <FormattedMessage id="deactivate-account" />
            </DialogTitle>
            {state.loading ? (
                <DialogContent sx={{ px: 3, py: 2.5, minHeight: 120 }}>
                    <Skeleton
                        variant="rectangular"
                        width="100%"
                        height="100%"
                        sx={{
                            borderRadius: 1,
                            bgcolor: 'rgba(255, 236, 217, 0.4)',
                            minHeight: 80
                        }}
                    />
                </DialogContent>
            ) : accountManagerHasOrganizations ? (
                <DialogContent sx={{ px: 3, py: 2.5, minHeight: 120 }}>
                    <NewDisclaimer
                        message={
                            <FormattedMessage
                                id="account-manager-reassign-disclaimer"
                                defaultMessage="This account has partners assigned to it , You should reassign these partners to another Account managers."
                            />
                        }
                    />
                </DialogContent>
            ) : (
                <DialogContent sx={{ px: 3, py: 2.5, minHeight: 120 }}>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id="deactivate-account-description" />
                    </DialogContentText>
                </DialogContent>
            )}
            <DialogActions sx={{ px: 3, py: 2 }}>
                <Button onClick={() => close()} disabled={state.loading}>
                    <FormattedMessage id="cancel" />
                </Button>

                {state.loading ? (
                    <Button variant="contained" disabled sx={{ minWidth: 140, minHeight: 36 }}>
                        <Skeleton
                            variant="text"
                            width={100}
                            height={24}
                            sx={{
                                bgcolor: 'rgba(0, 0, 0, 0.11)'
                            }}
                        />
                    </Button>
                ) : accountManagerHasOrganizations ? (
                    <Button variant="contained" onClick={handleReassign} disabled={state.loading} sx={{ minWidth: 140, minHeight: 36 }}>
                        {state.loading ? <CircularProgress size={16} /> : <FormattedMessage id="reassign-now" />}
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeactivate}
                        disabled={state.loading}
                        sx={{ minWidth: 140, minHeight: 36 }}
                    >
                        {state.loading ? <CircularProgress size={16} /> : <FormattedMessage id="deactivate" />}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DeactivateAccountDialog;
