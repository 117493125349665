import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import NOTIFICATION_TYPES_APIS, { TYPE_CONFIGURATION_APIS } from './apis';
import {
    CreateNotificationTypeV1Dto,
    CreateTypeConfigV1Dto,
    DeleteNotificationTypeV1Dto,
    NotificationTypeConfigsFullDataInterface,
    NotificationTypeList,
    QueryNotificationTypes,
    UpdateNotificationTypeV1Dto,
    UpdateTypeConfigV1Dto
} from './interfaces';

export const createNotificationType = createAsyncThunk(
    'notification-type/create',
    async (
        requestData: {
            createData: CreateNotificationTypeV1Dto;
            runSuccess?: (typeId: number) => void;
        },
        { rejectWithValue }
    ): Promise<number | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(NOTIFICATION_TYPES_APIS.create(), createData);
            if (runSuccess) runSuccess(successRes.data);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryNotificationTypes = createAsyncThunk(
    'notification-type/query',
    async (
        requestData: {
            queryData: QueryNotificationTypes;
            runSuccess?: (pageState: string) => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeList | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(NOTIFICATION_TYPES_APIS.query(queryData));
            if (runSuccess) runSuccess(successRes.data.page);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getNotificationTypeById = createAsyncThunk(
    'notification-type/getById',
    async (
        requestData: {
            typeId: number | undefined;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeConfigsFullDataInterface | any> => {
        const { typeId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(NOTIFICATION_TYPES_APIS.getById(typeId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateNotificationType = createAsyncThunk(
    'notification-type/update-type',
    async (
        requestData: {
            typeId: number;
            updateData: UpdateNotificationTypeV1Dto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeConfigsFullDataInterface | any> => {
        const { typeId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(NOTIFICATION_TYPES_APIS.updateType(typeId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteNotificationType = createAsyncThunk(
    'notification-type/delete',
    async (
        requestData: {
            typeId: number;
            deleteData: DeleteNotificationTypeV1Dto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<string | any> => {
        const { typeId, deleteData, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(NOTIFICATION_TYPES_APIS.delete(typeId), { data: deleteData });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createTypeConfig = createAsyncThunk(
    'type-config/create',
    async (
        requestData: {
            createData: CreateTypeConfigV1Dto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeConfigsFullDataInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(TYPE_CONFIGURATION_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateTypeConfig = createAsyncThunk(
    'type-config/update-type-config',
    async (
        requestData: {
            id: string;
            updateData: UpdateTypeConfigV1Dto;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeConfigsFullDataInterface | any> => {
        const { id, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(TYPE_CONFIGURATION_APIS.updateTypeConfig(id), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteTypeConfig = createAsyncThunk(
    'type-config/delete',
    async (
        requestData: {
            id: string;
            typeId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<NotificationTypeConfigsFullDataInterface | any> => {
        const { id, typeId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(TYPE_CONFIGURATION_APIS.deleteTypeConfig(id, typeId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
