import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import { ReactNode } from 'react';

interface TextWithNewBadgeInterface {
    variant: 'h5' | 'body1';
    title: ReactNode;
}
const TextWithNewBadge = ({ variant, title }: TextWithNewBadgeInterface) => {
    const theme = useTheme();
    const intl = useIntl();

    return (
        <Stack direction="row" spacing={0.5}>
            <Typography variant={variant} color="inherit">
                {title}
            </Typography>
            <Typography
                variant="caption"
                bgcolor={theme.palette.primary.dark}
                borderRadius={0.5}
                color={theme.palette.grey[100]}
                padding={0.25}
                fontSize={8}
                alignSelf="center"
                gutterBottom
                sx={{ position: 'relative', top: '-3px' }}
            >
                {intl.formatMessage({ id: 'new-caps' })}
            </Typography>
        </Stack>
    );
};
export default TextWithNewBadge;
