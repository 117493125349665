import { FormikProps } from 'formik';
import { useSelector } from 'store';
import { ReservedVariables } from 'store/slices/notifications/interfaces';
import { useTheme, alpha } from '@mui/material/styles';
import StarterKit from '@tiptap/starter-kit';
import { MenuButtonBold, MenuButtonItalic, MenuControlsContainer, MenuDivider, MenuSelectHeading, RichTextEditor } from 'mui-tiptap';
import { Mention } from '@tiptap/extension-mention';
import { ReactRenderer } from '@tiptap/react';
import SuggestionList, { SuggestionListRef } from './SuggestionList';
import tippy, { type Instance as TippyInstance } from 'tippy.js';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';
import Placeholder from '@tiptap/extension-placeholder';
import { Extension } from '@tiptap/core';

interface EmailTemplateBodyProps {
    formik: FormikProps<any>;
    singleLine: boolean;
    readOnly: boolean;
}

const EmailTemplateBody = ({ formik, singleLine, readOnly }: EmailTemplateBodyProps) => {
    const { notificationType } = useSelector((state) => state.notificationTypes);
    const theme = useTheme();
    const intl = useIntl();

    const getVariablesList = () =>
        notificationType?.requiredData
            .map((variable) => ({
                id: variable,
                label: variable
            }))
            .concat(
                Object.values(ReservedVariables).map((variable) => ({
                    id: variable,
                    label: variable
                }))
            );

    return (
        <Box
            sx={{
                '& .ProseMirror': {
                    bgcolor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                    borderRadius: '12px',
                    minHeight: 135,
                    padding: '16px',
                    '& p': {
                        margin: '0',
                        lineHeight: 1.75
                    },
                    '& h1': {
                        fontSize: '1.75rem',
                        fontWeight: 500,
                        margin: '0 0 0.5em 0'
                    },
                    '& h2': {
                        fontSize: '1.5rem',
                        fontWeight: 500,
                        margin: '0 0 0.5em 0'
                    },
                    '& a': {
                        color: theme.palette.primary.main
                    },
                    '& ul, & ol': {
                        padding: '0 1rem',
                        margin: '0.5em 0'
                    },
                    '& li': {
                        margin: '0.2em 0'
                    },
                    '& blockquote': {
                        borderLeft: `4px solid ${theme.palette.grey[300]}`,
                        margin: '1em 0',
                        padding: '0 1em',
                        color: theme.palette.text.secondary
                    },
                    '& .tiptap-menu-bar': {
                        bgcolor: theme.palette.mode === 'dark' ? 'dark.light' : 'grey.100',
                        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.light + 20 : 'primary.light',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px'
                    },
                    '& .MuiInputBase-root': {
                        borderColor: theme.palette.mode === 'dark' ? `${theme.palette.dark.light + 20} !important` : 'primary.light',
                        borderBottomLeftRadius: '12px',
                        borderBottomRightRadius: '12px'
                    },
                    '& [data-type="mention"]': {
                        color: theme.palette.primary.main,
                        backgroundColor:
                            theme.palette.mode === 'dark'
                                ? alpha(theme.palette.primary.main, 0.15)
                                : alpha(theme.palette.primary.main, 0.1),
                        padding: '2px 4px',
                        borderRadius: '4px',
                        textDecoration: 'none',
                        '&:hover': {
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? alpha(theme.palette.primary.main, 0.25)
                                    : alpha(theme.palette.primary.main, 0.2)
                        }
                    }
                }
            }}
        >
            <RichTextEditor
                extensions={[
                    StarterKit.configure({
                        hardBreak: singleLine
                            ? false
                            : {
                                  keepMarks: true
                              }
                    }),
                    Mention.configure({
                        renderLabel: ({ node }) => `@{${node.attrs.label}}`,
                        suggestion: {
                            items: ({ query }) => {
                                const variables = getVariablesList();
                                return variables?.filter((variable) => variable.label.toLowerCase().includes(query.toLowerCase())) || [];
                            },
                            render: () => {
                                let component: ReactRenderer<SuggestionListRef> | undefined;
                                let popup: TippyInstance | undefined;

                                return {
                                    onStart: (props) => {
                                        component = new ReactRenderer(SuggestionList, {
                                            props,
                                            editor: props.editor
                                        });

                                        const body = document.querySelector('body');
                                        if (body) {
                                            popup = tippy(body, {
                                                getReferenceClientRect: () => props.clientRect?.() || new DOMRect(),
                                                appendTo: () => document.body,
                                                content: component.element,
                                                showOnCreate: true,
                                                interactive: true,
                                                trigger: 'manual',
                                                placement: 'bottom-start'
                                            });
                                        }
                                    },

                                    onUpdate(props) {
                                        component?.updateProps(props);

                                        popup?.setProps({
                                            getReferenceClientRect: () => props.clientRect?.() || new DOMRect()
                                        });
                                    },

                                    onKeyDown(props) {
                                        if (props.event.key === 'Escape') {
                                            popup?.hide();
                                            return true;
                                        }

                                        if (!component?.ref) {
                                            return false;
                                        }

                                        return component.ref.onKeyDown(props);
                                    },

                                    onExit() {
                                        popup?.destroy();
                                        component?.destroy();
                                        popup = undefined;
                                        component = undefined;
                                    }
                                };
                            }
                        }
                    }),
                    Placeholder.configure({
                        placeholder: intl.formatMessage({ id: 'add-variables-using-@' })
                    }),
                    Extension.create({
                        addKeyboardShortcuts() {
                            return {
                                Enter: () => {
                                    if (singleLine) {
                                        return true;
                                    }
                                    return false;
                                }
                            };
                        }
                    })
                ]}
                content={formatInputVariables(formik.values.templateBody) || ''}
                onUpdate={({ editor }) => {
                    const html = editor.getHTML();
                    const formattedHtml = formatOutputVariables(html);
                    formik.setFieldValue('templateBody', formattedHtml);
                }}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectHeading />
                        <MenuDivider />
                        <MenuButtonBold />
                        <MenuButtonItalic />
                    </MenuControlsContainer>
                )}
                RichTextFieldProps={{
                    variant: 'outlined'
                }}
                editable={!readOnly}
            />
        </Box>
    );
};

export default EmailTemplateBody;

const formatInputVariables = (html: string) => {
    if (!html) return '';

    html = html.replace(/@\[(.*?)\]\((.*?)\)/g, '@{$1}');

    // convert the mention to @{mention-label}
    return html.replace(
        /@\{(.*?)\}/g,
        (match, token) => `<span data-type="mention" data-id="${token}" data-label="${token}">@${token}</span>`
    );
};

const formatOutputVariables = (html: string) =>
    html.replace(/<span data-type="mention" data-id="(.*?)" data-label=".*?">@.*?<\/span>/g, '@[$1]($1) ');
