import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import PROPERTY_LISTING_APIS from '../propertiesListing/apis';
import { PropertyListingInterface } from '../propertiesListing/interfaces';
import REJECTION_REAONS_APIS from '../rejectionReasons/apis';

export const getListingForReview = createAsyncThunk(
    'listings/review/get-by-id',
    async (
        requestData: {
            id: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { id, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(PROPERTY_LISTING_APIS.getListingById(id));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const updateListingTitle = createAsyncThunk(
    'listings/review/update-title',
    async (
        requestData: {
            id: number;
            body: {
                titleEn: string;
                titleAr: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { id, body, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.updateTitle(id), body);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const submitLocationReview = createAsyncThunk(
    'listing/review/submit-location',
    async (
        requestData: {
            id: number;
            rejectionReasonId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, rejectionReasonId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.reviewLocation(id), { rejectionReasonId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const submitDescriptionReview = createAsyncThunk(
    'listing/review/submit-description',
    async (
        requestData: {
            id: number;
            rejectionReasonId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, rejectionReasonId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.reviewDescription(id), { rejectionReasonId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const submitPropertyReview = createAsyncThunk(
    'listing/review/submit-property',
    async (
        requestData: {
            id: number;
            rejectionReasonId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, rejectionReasonId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.reviewProperty(id), { rejectionReasonId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const submitPaymentReview = createAsyncThunk(
    'listing/review/submit-payment',
    async (
        requestData: {
            id: number;
            rejectionReasonId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, rejectionReasonId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.reviewPayment(id), { rejectionReasonId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const submitImagesReview = createAsyncThunk(
    'listing/review/submit-images',
    async (
        requestData: {
            id: number;
            rejectionReasonId?: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { id, rejectionReasonId, runSuccess } = requestData;

        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.reviewImages(id), { rejectionReasonId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const submitFullReview = createAsyncThunk(
    'listing/review/submit-full-review',
    async (
        requestData: {
            listingId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { listingId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.patch(PROPERTY_LISTING_APIS.submitFulReview(listingId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryRejectionReasons = createAsyncThunk(
    'listing/review/query-rejection-reasons',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<PropertyListingInterface | any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axiosServices.get(REJECTION_REAONS_APIS.query({}));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
