import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryActivityCategoryInterface } from './interfaces';

const res = 'activity-category'; // Update the resource name

const ACTIVITY_CATEGORY_APIS = {
    /**
     * @GET
     * @returns categoryList
     */
    query: (query: QueryActivityCategoryInterface) => {
        const formatQuery: string[] = [FAUNA_API, res, '?'];
        return formattedQuery(formatQuery, query);
    },
    /**
     * @GET
     * @returns category
     */
    getById: (categoryId: number) => `${FAUNA_API}${res}/${categoryId}`,
    /**
     * @POST
     * @returns category
     */
    create: () => `${FAUNA_API}${res}`,
    /**
     * @PATCH
     * @returns category
     */
    update: (categoryId: number) => `${FAUNA_API}${res}/${categoryId}`,

    /**
     * @DELETE
     * @returns String
     */
    delete: (categoryId: number) => `${FAUNA_API}${res}/${categoryId}`
};

export default ACTIVITY_CATEGORY_APIS;
