import { Typography, Box, Link as MuiLink, Grid } from '@mui/material';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import PageHeader from 'ui-component/PageHeader';
import './index.css';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';

const CommissionTermsPage = () => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const theme = useTheme();
    useEffect(() => {
        emitEvent("View Commissions T&C's page");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MuiLink
                    component={Link}
                    to="/commissions"
                    underline="always"
                    sx={{
                        textDecorationColor: 'currentColor',
                        '&:hover': {
                            textDecorationColor: 'blue'
                        }
                    }}
                    onClick={() => {
                        emitEvent('Click "commission terms"');
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', lg: 'block' } }}>
                        <FormattedMessage id="back" />
                    </Box>
                    <Box component="span" sx={{ display: { xs: 'block', lg: 'none' } }}>
                        <FormattedMessage id="back" />
                    </Box>
                </MuiLink>
            </Grid>
            <Grid item xs={12}>
                <PageHeader title={intl.formatMessage({ id: 'commissions' })} />
            </Grid>
            <Grid item xs={12}>
                <div style={{ backgroundColor: 'white', borderRadius: '12px', padding: '32px' }}>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontWeight: 700,
                            textAlign: 'left',
                            color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.primary.main,
                            lineHeight: '20px'
                        }}
                    >
                        <h2>Nawy Partners commission terms</h2>
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Almarai',
                            lineHeight: '30px'
                        }}
                    >
                        <div id="text">
                            <h1>شروط وأحكام المدفوعات لـ Nawy Partners</h1>

                            <h2>1.1 طريقة الدفع الاكسبريس وعرض الاكسبريس لأول بيعة</h2>
                            <ul>
                                <li>
                                    <h3>ما هي طريقة الدفع الاكسبريس؟</h3>
                                </li>
                                <ul>
                                    <li>
                                        هو واحد من مزايا Nawy Partners وتؤهلك للحصول على نصف عمولتك خلال أسبوعين إلى حد أقصى شهر من تاريخ
                                        تسجيل الوحدة المباعة على منصة Nawy Partners.
                                    </li>
                                    <li>
                                        تتنفذ المبيعات بطريقة الدفع الاكسبريس بنسبة عمولة مختلفة ويمكن معرفة نسبة العمولة الأكسبريس لكل مطور
                                        من خلال منصة Nawy Partners.
                                    </li>
                                </ul>
                                <li>
                                    <h3>ما هو عرض طريقة الدفع الاكسبريس لأول بيعة؟</h3>
                                </li>

                                <ul>
                                    <li>هو عرض مخصص لأول بيعة لـ Nawy Partners، وتكون مؤهل للدفع بطريقة الاكسبريس بدون أي رسوم.</li>
                                    <li>
                                        المطورين المؤهلين: المطورين المؤهلين لطريقة الدفع الاكسبريس موجودين بالكامل على منصة Nawy Partners.
                                    </li>
                                </ul>
                                <li>
                                    <h4>ملاحظات:</h4>
                                    <ul>
                                        <li>
                                            في حالة دفع العميل 5% من ثمن الوحدة المباعة، يتم دفع النصف الأول من العمولة بطريقة الدفع
                                            الأكسبريس من قبل شركة Nawy خلال أسبوعين إلى حد أقصى شهر من تاريخ تسجيل الوحدة المباعة على منصة
                                            Nawy Partners، وسيتم دفع باقي العمولة بعد التحصيل من المطور إجمالي عمولة الوحدة المباعة
                                        </li>
                                        <li>
                                            في حالة دفع العميل 10% من ثمن الوحدة المباعة مع التعاقد، يتم دفع العمولة الكاملة بطريقة الدفع
                                            الأكسبريس من قبل شركة Nawy خلال أسبوعين إلى حد أقصى شهر من تاريخ تسجيل الوحدة المباعة على منصة
                                            Nawy Partners
                                        </li>
                                        <li>
                                            تعتبر نسبة ال٥٪ هي النسبة الكاملة للمقدم عند بعض المطورين و يمكن تفقدهم بالكامل فى القسم 2.1.2
                                        </li>
                                        <li>
                                            الحد الأقصى لعرض طريقة الدفع الاكسبريس: الحد الأقصى للعرض هو 20 مليون جنيه للوحدة المباعة ولا
                                            يمكن تقسيم العرض على أكثر من وحده.
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <h2>1.2 شروط عدم تطبيق العمولات (لطرق الدفع العادية أو الأكسبريس):</h2>
                            <ul>
                                <li>
                                    <h3>فى حالة الدفع بالطريقة العادية أو الأكسبرس</h3>
                                </li>
                                <ul>
                                    <li>مشكلة في المقدم المدفوع من العميل.</li>
                                    <li>مقدم غير مكتمل من العميل.</li>
                                    <li>مشكلة في التعاقد بين العميل والمطور.</li>
                                    <li>وحدة مبيعة غير مؤكدة باسم ناوي عند المطور.</li>
                                    <li>وحدة مبيعة بأقل من 5% مقدم.</li>
                                </ul>
                                <li>
                                    <h3>فى حالة الدفع بطريقة الأكسبرس:</h3>
                                </li>
                                <ul>
                                    <li>عروض المطورين لوحدات بمقدم 0%.</li>
                                    <li>الإطلاقات (Launches).</li>
                                </ul>
                            </ul>

                            <h2>1.3 دفع العمولات بطريقة الدفع العادية:</h2>
                            <ul>
                                <li>
                                    الدفعة الأولى من العمولة: تضمن لك شركة ناوي دفع الدفعة الأولى من العمولة في خلال 90-120 يوماً من تاريخ
                                    تسجيل الوحدة المباعة على منصة Nawy Partners بشرط عدم ظهور أي من النقاط المذكورة في القسم 1.2.
                                </li>
                                <li>
                                    الـ 90-120 يوماً يطبقوا على الدفعة الأولى فقط من العمولة؛ الدفعة الثانية تتم بعد التحصيل من المطور.
                                    وتلتزم شركة Nawy بدفع العمولة مبكراً في حالة تحصيلها مبكراً من قبل المطور.
                                </li>
                                <li>
                                    الدفعة الثانية من العمولة: سيتم دفع الدفعة الثانية من قبل شركة ناوي بعد تحصيل العمولة بالكامل من المطور.
                                </li>
                                <li>
                                    <h4>ملاحظات:</h4>
                                </li>
                                <ul>
                                    <li>
                                        في حالة دفع العميل 5% من ثمن الوحدة المباعة، يتم دفع النصف الأول من العمولة من قبل شركة Nawy خلال
                                        90-120 يوماً، وسيتم دفع باقي العمولة بعد دفع العميل من إجمالي ثمن الوحدة المباعة 10%. (تعتبر نسبة
                                        الـ 5% هي النسبة الكاملة للمقدم عند بعض المطورين ويمكن تفقدهم بالكامل في القسم 2.1.2).
                                    </li>
                                    <li>
                                        في حالة المبيعات بدون مقدم (0% مقدم)، لا يتم تطبيق فترة الضمان 90-120 يوماً لدفع النصف الأول من
                                        العمولة من قبل شركة Nawy.
                                    </li>
                                    <li>
                                        تبدأ فترة الالتزام من قبل شركة Nawy بدفع العمولة في مدة من 90-120 يوماً من جديد عند ظهور أي مشكلة في
                                        الصفقة. (يرجى مراجعة المشاكل في القسم 1.3)
                                    </li>
                                </ul>
                            </ul>
                            <h2>1.4 القواعد العامة:</h2>
                            <ul>
                                <li>قبل استلام أي عمولة، يجب الالتزام بالنقاط التالية:</li>
                                <ul>
                                    <li>يجب على الشريك التوقيع على نسختين عقد Nawy Partners وتسليم النسختين لفريق دعم المبيعات في Nawy.</li>
                                    <li>يجب إضافة صور أصلية من المستندات المطلوبة التالية على منصة Nawy Partners:</li>
                                    <ul>
                                        <li>البطاقة الضريبية للشركة</li>
                                        <li>بطاقة الرقم القومي لصاحب/أصحاب الشركة</li>
                                        <li>السجل التجاري للشركة</li>
                                        <li>إثبات دفع ضريبة الأرباح المقتطعة (شهادة دفعات مقدمة) إن وجدت</li>
                                        <li>البيانات البنكية الخاصة بالشركة</li>
                                        <li>شهادة ضريبة القيمة المضافة</li>
                                    </ul>
                                </ul>
                                <li>
                                    <h4>ملاحظات:</h4>
                                </li>
                                <ul>
                                    <li>سيتم خصم ضريبة القيمة المضافة إذا لم يتم إضافة صورة من الشهادة الأصلية على منصة Nawy Partners.</li>
                                    <li>
                                        لا يمكن تحصيل مبلغ ضريبة القيمة المضافة لاحقاً في حالة إضافة شهادة ضريبة القيمة المضافة بعد دفع
                                        العمولة.
                                    </li>
                                </ul>
                                <li>
                                    <b>ملاحظة هامة</b>: في حالة عدم إضافة أي من المستندات المطلوبة أو معلومات على المنصة بشكل صحيح، سيؤدي
                                    إلى تأجيل دفع العمولة حتى يتم إضافتها أو تعديلها.
                                </li>
                            </ul>

                            <h2>1.5 المواعيد النهائية لفترات دفع العمولات:</h2>
                            <ul>
                                <li>
                                    الفترة الأولى: تشمل العمولات بطريقة الدفع الأكسبريس وعرض الدفع الأكسبريس لأول بيعة مع Nawy Partners
                                    والعمولات المؤجلة من الفترة السابقة والعمولات المحصلة من المطور قبل ميعادها المستحق.
                                </li>
                                <li>
                                    الموعد النهائي لتقديم جميع الأوراق المطلوبة للمبيعات المؤهلة للفترة: يوم 7 من كل شهر (بحد أقصى الساعة 6
                                    مساءً).
                                </li>
                                <li>
                                    الموعد النهائي لتقديم فواتير المبيعات المؤهلة: من يوم 9 إلى يوم 11 من كل شهر (بحد أقصى الساعة 6 مساءً).
                                </li>
                                <li>التحويلات: خلال أسبوع عمل من الموعد النهائي لتقديم الفواتير.</li>
                                <li>
                                    الفترة الثانية: تشمل العمولات بطريقة الدفع الأكسبريس وعرض الدفع الأكسبريس لأول بيعة مع Nawy Partners،
                                    المبيعات المؤجلة من الفترة السابقة، والعمولات المحصلة من المطور قبل ميعادها المستحق، وأي مدفوعات تعدت
                                    الـ 90 إلى 120 يوم من تاريخ تسجيل الوحدة المباعة على منصة Nawy Partners.
                                </li>
                                <li>
                                    الموعد النهائي لتقديم جميع الأوراق المطلوبة للمبيعات المؤهلة للفترة: يوم 18 من الشهر (بحد أقصى الساعة 6
                                    مساءً).
                                </li>
                                <li>
                                    الموعد النهائي لتقديم فواتير المبيعات المؤهلة: من يوم 20 إلى يوم 22 من كل شهر (بحد أقصى الساعة 6 مساءً).
                                </li>
                                <li>التحويلات: خلال أسبوع عمل من الموعد النهائي لتقديم الفواتير.</li>
                                <li>
                                    <b>ملاحظة هامة</b>: يمكن تغيير المواعيد المذكورة أعلاه على حسب أيام العمل لكل شهر.
                                </li>
                            </ul>
                            <h2>1.6 قواعد تقديم الفواتير:</h2>
                            <ul>
                                <li>يجب كتابة رقم الوحدة صحيح في وصف الفاتورة.</li>
                                <li>يجب كتابة المبالغ المفوترة والبيانات الضريبية بشكل صحيح للوحدة المباعة.</li>
                                <li>
                                    يتم دفع العمولات عن طريق التحويلات البنكية فقط إلى حساب البنك الخاص بالشركة والموجود على منصة Nawy
                                    Partners.
                                </li>
                                <li>
                                    يجب أن يكون اسم الشركة مذكوراً على الفاتورة الإلكترونية المرفوعة هو الاسم المذكور في السجل التجاري
                                    للشركة، وليس اسم أي من الأفراد أو الاسم التسويقي للشركة.
                                </li>
                                <li>يتم إرسال رقم الفاتورة إلى المسؤول الخاص من Nawy Partners لشركتكم بعد تقديم الفاتورة.</li>

                                <li>
                                    ملاحظة: في حالة عدم وجود أي مستندات أو إذا كانت المعلومات غير مكتملة أو تم رفضها على منصة Nawy Partners،
                                    سيتم تأجيل تحويل العمولة حتى يتم استكمالها أو تصحيحها.
                                </li>
                            </ul>

                            <h2>2.1 شروط وأحكام دفع عمولات المطورين:</h2>
                            <ul>
                                <li>
                                    <h4>
                                        2.1.1 شروط طريقة الدفع العادية أو الإكسبريس لا تنطبق على المطورين التاليين إلا بعد تحصيل العمولة
                                        بالكامل من المطور:
                                    </h4>
                                </li>
                                <ul>
                                    <li>Palm Hills</li>
                                    <li>Horizon</li>
                                    <li>City Edge</li>
                                    <li>Arabia Holding</li>
                                    <li>SED</li>
                                    <li>Redcon مشروع Golden Gate Commercial</li>
                                    <li>Pyramids</li>
                                    <li>Make Place</li>
                                    <li>Centrada</li>
                                    <li>Beta</li>
                                    <li>Karnak</li>
                                    <li>Mardev</li>
                                    <li>Tatweer Misr</li>
                                    <li>Corner Stone</li>
                                </ul>
                                <li>
                                    <h4>2.1.2 العمولات على المبيعات مع المطورين التاليين تُدفع كاملة عند دفع العميل 5% من سعر الوحدة:</h4>
                                </li>
                                <ul>
                                    <li>LMD</li>
                                    <li>Mabany Edris</li>
                                </ul>

                                <h4>ملاحظات هامة:</h4>
                                <ul>
                                    <li>النصف الثاني من العمولة يتم دفعه عند التحصيل وغير قابل لطريقة الدفع الأكسبريس.</li>
                                    <li>
                                        أي مطور غير مذكور في القائمة أعلاه، سيتم دفع نصف العمولة عند دفع العميل 5% من الوحدة المباعة، والنصف
                                        الثاني يدفع عند دفع العميل 10% من إجمالي ثمن الوحدة المباعة.
                                    </li>
                                    <li>قائمة المطورين قابلة للتغير، تابع مع مدير حسابك لأجدد قائمة.</li>
                                </ul>
                            </ul>

                            <h2>2.2 عرض يناير الحصري من Nawy Partners</h2>
                            <ul>
                                <li>
                                    <h4>2.2.1 العرض: أعلى عمولات مع المطورين التاليين لفتره محدوده:</h4>
                                </li>
                                <ul>
                                    <li>Ora Developers</li>
                                    <li>Mountain View</li>
                                    <li>Hyde Park</li>
                                    <li>Tatweer Misr</li>
                                    <li>Al Ahly Sabbour Developments</li>
                                    <li>PRE Developments</li>
                                    <li>LMD</li>
                                    <li>Misr Italia Properties</li>
                                    <li>MAVEN DEVELOPMENTS</li>
                                    <li>Roya Developments</li>
                                    <li>La Vista Developments</li>
                                </ul>
                                <li>
                                    <h4>2.2.2 مؤهلات العرض:</h4>
                                </li>
                                <ul>
                                    <li>يجب أن يكون المشاركون في العرض من Nawy Partners المعتمدين.</li>
                                    <li>يجب أن تكون الجهات المشاركة كيانات معترف بها قانونًا أو شركات مسجلة قادرة على إبرام عقود ملزمة.</li>
                                    <li>سيتم احتساب المبيعات المتعاقد عليها في العرض بعد اعتماد الوحدة المباعة من قبل المطور العقارى.</li>
                                    <li>يجب أن يكون اسم Nawy مذكورًا في جميع العقود التعاقدية ليتم اعتبارها صالحة للمشاركة في العرض.</li>
                                </ul>
                                <li>
                                    <h4>2.2.3 فترة العرض:</h4>
                                </li>
                                <ul>
                                    <li>العرض ساري حتى 31 يناير 2025</li>
                                </ul>
                                <li>
                                    <h4>2.2.4 قواعد عامة:</h4>
                                </li>
                                <ul>
                                    <li>يمنع منعًا باتًا سحب مبيعات موثقة باسم الشركة مع المطور وإعادة تسجيلها باسم شركة ناوي.</li>
                                    <li>المشاركة في العرض تعني قبول هذه الشروط والأحكام.</li>
                                    <li>تحتفظ Nawy بحق إلغاء العرض أو تعديله في أي وقت دون إشعار مسبق.</li>
                                </ul>
                                <li>
                                    <h4>2.2.5 قبل استلام أى عمولة، يجب الالتزام بالنقاط التالية:</h4>
                                </li>
                                <ul>
                                    <li>يجب على الشريك التوقيع على نسختين عقد Nawy Partners و تسليم النسختين لفريق دعم المبيعات في Nawy</li>
                                    <li>يجب إضافة صور اصليه من المستندات المطلوبة التالية على منصة Nawy Partners</li>
                                    <ul className="rectangular-list">
                                        <li>البطاقة الضريبية للشركة</li>
                                        <li>السجل التجاري للشركة</li>
                                        <li>إثبات دفع ضريبة الأرباح المقتطعة (شهادة دفعات مقدمة) إن وجدت</li>
                                        <li>البيانات البنكية الخاصة بالشركة</li>
                                        <li>شهادة ضريبة القيمة المضافة</li>
                                        <ul className="solid-circle-list">
                                            <li>ملاحظات: </li>
                                            <ul className="circle-list">
                                                <li>
                                                    سيتم خصم ضريبة القيمة المضافة إذا لم يتم إضافة صوره من الشهاده الاصليه على منصة Nawy
                                                    Partners.
                                                </li>
                                                <li>
                                                    لا يمكن تحصيل مبلغ ضريبة القيمة المضافة لاحقا في حالة إضافة شهادة ضريبة القيمة المضافة
                                                    بعد دفع العمولة.
                                                </li>
                                            </ul>
                                        </ul>
                                    </ul>
                                </ul>
                                <ul>
                                    <li>
                                        <strong>ملاحظة هامة: </strong> فى حالة عدم إضافة أى من المستندات المطلوبة أو معلومات على المنصة بشكل
                                        صحيح، سيؤدي إلى تأجيل دفع العمولة حتى يتم إضافتها أو تعديلها.
                                    </li>
                                </ul>
                                <li>
                                    <h4>2.2.6 قواعد تقديم الفواتير:</h4>
                                </li>
                                <ul>
                                    <li>يجب كتابة رقم الوحدة صحيح في وصف الفاتورة.</li>

                                    <li>يجب كتابة المبالغ المفوترة و البيانات الضريبة بشكل صحيح للوحدة المباعة.</li>

                                    <li>
                                        يتم دفع العمولات عن طريق التحويلات البنكية فقط إلى حساب البنك الخاص بالشركة والموجود على منصة Nawy
                                        Partners.
                                    </li>
                                    <li>
                                        يجب أن يكون اسم الشركة مذكور على الفاتورة الإلكترونية المرفوعة هو الاسم المذكور في السجل التجاري
                                        للشركة، وليس اسم اى من الأفراد أو الاسم التسويقي للشركة.
                                    </li>
                                    <li>يتم إرسال رقم الفاتورة إلى المسؤول الخاص من Nawy Partners لشركتكم بعد تقديم الفاتورة.</li>
                                    <li>
                                        <strong>ملاحظة : </strong> في حالة عدم وجود أي مستندات أو إذا كانت المعلومات غير مكتملة أو تم رفضها
                                        على منصة Nawy Partners، سيتم تأجيل تحويل العمولة حتى يتم استكمالها أو تصحيحها.
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        {/* <div>
                            <h1>Payment Terms & Conditions for Nawy Partners</h1>
                            <h2>1.1 Normal Payment</h2>
                            <ul>
                                <li>
                                    1st Half Payment: Processed within 90 to 120 days (From Sale Creation Date on Nawy&apos;s System). The
                                    90-120 days commitment applies only to the 1st half of the commission; the 2nd half will be processed
                                    upon collection from the developer.
                                </li>
                                <li>
                                    2nd Half Payment: Processed after collection of the full commission. Note: Depending on the down payment
                                    made by the client to the developer, for some developers, 5% is considered a full down payment. (List in
                                    section 2.1 (2))
                                </li>
                                <li>
                                    Note: Depending on the down payment made by the client to the developer, for some developers, 5% is
                                    considered a full down payment. (List in section 2.1 (2))
                                </li>
                                <li>The 90-120 days commitment is not eligible on 0% down payment deals.</li>
                                <li>The 90-120 days commitment will be reset if any issue in sales occurs. (Issues in Section 1.4)</li>
                                <li>First Sale Promo Payment (Express Payment Promo)</li>
                                <li>Eligibility: Only developers listed as eligible for express payment on Partners Platform.</li>
                                <li>1st Half Payment: Processed as an Express payment with the normal commission percentage.</li>
                                <li>2nd Half Payment: Processed after the down payment has been collected.</li>
                                <li>
                                    Note: Depending on the down payment made by the client to the developer, for some developers, 5% is
                                    considered a full down payment. (List in section 2.1 (2)) Cap for First Sale Promo: The cap for the
                                    First Sale Promo is 20M and cannot be subdivided.
                                </li>
                            </ul>

                            <h2>1.2 Express Payment</h2>
                            <ul>
                                <li>Eligibility: Only developers listed as eligible for express payment on Partners Platform.</li>
                                <li>1st Half Payment: Processed as an Express payment with the express payment commission percentage.</li>
                                <li>
                                    2nd Half Payment: Processed after the 1st half payment is collected with the same express payment rate
                                    that was processed in the 1st Half. 2nd half payment will be processed upon collection from the
                                    developer.
                                </li>
                                <li>
                                    Note: Depending on the down payment made by the client to the developer, for some developers, 5% is
                                    considered a full down payment. (List in section 2.1 (2))
                                </li>
                            </ul>
                            <h2>1.3 Not Eligible for Express Payment</h2>
                            <ul>
                                <li>Issue in Down Payment.</li>
                                <li>Down Payment incomplete.</li>
                                <li>0% Down Payment.</li>
                                <li>Launches.</li>
                                <li>Issue in contract. (Issue in contract between customer and developer).</li>
                                <li>Pending source.</li>
                            </ul>
                            <h2>1.4 Not Eligible for Payment</h2>
                            <ul>
                                <li>Issue in Down Payment.</li>
                                <li>Down Payment incomplete.</li>
                                <li>Issue in contract. (Issue in contract between customer and developer).</li>
                                <li>Pending source.</li>
                                <li>Any sale with less than 5% down payment.</li>
                            </ul>

                            <h2>1.5 General Rules</h2>
                            <ul>
                                <li>Before proceeding with any payment, it&apos;s obligatory to follow the points below:</li>
                                <ul>
                                    <li>
                                        Contract between Partner and Nawy Partners must be signed by the partner and handed to the sales
                                        support team (2 hard copies).
                                    </li>
                                    <li>The required documents must be uploaded on the partner&apos;s platform</li>
                                    <ul>
                                        <li>Tax Card</li>
                                        <li>National ID</li>
                                        <li>Commercial Registration</li>
                                        <li>VAT certificate</li>
                                    </ul>
                                </ul>
                                <li>Payment will not include VAT if the VAT certificate was not uploaded on Nawy Partners platform</li>
                                <li>If payment went through without VAT, VAT cannot be paid at a later time</li>
                                <li>Withholding Tax Payment Proof (شهادة دفعات مقدمة).</li>
                                <li>Company payment information must be filled out (bank details).</li>
                                <li>
                                    <b>Note:</b> If any of the above is missing, the payment will be automatically on hold until completion.
                                </li>
                            </ul>

                            <h2>1.6 Payout Cycle Cutoff Dates</h2>
                            <ul>
                                <li>Dates might be changed in accordance with working days.</li>
                                <li>
                                    1st Cycle: Includes Express, First Sale Promo, postponed resolved sales from previous cycle, and early
                                    collected payments from developer.
                                </li>
                                <ul>
                                    <li>Sales cut-off date: 7th of the month (End of Day)</li>
                                    <li>Invoice submission cut-off date: 9th-11th of the month 11:59PM</li>
                                    <li>Transfers: Within 1 working week of invoice cut-off dates</li>
                                </ul>
                                <li>
                                    2nd Cycle: Includes Express, First Sale Promo, postponed resolved sales from previous cycle, early
                                    collected payments and payments that exceeded the 90-120 days.
                                </li>
                                <ul>
                                    <li>Sales cut-off date: 18th of the month (End of Day)</li>
                                    <li>Invoice submission cut-off date: 20th-22nd of the month (End of Day)</li>
                                    <li>Transfers: Within 1 working week of invoice cut-off dates</li>
                                </ul>
                            </ul>

                            <h2>2.1 Developers Commission Payout Terms and Conditions</h2>
                            <p>(1) Commissions for sales done with the below developers will be paid upon collection:</p>
                            <ul>
                                <li>Palm Hills</li>
                                <li>Horizon</li>
                                <li>City Edge</li>
                                <li>Arabia Holding</li>
                                <li>Il Latini - SED</li>
                                <li>Redcon - Golden Gate Commercial</li>
                                <li>Pyramids</li>
                                <li>Make Place</li>
                            </ul>
                            <p>
                                (2) Commissions for sales done with the below developers will be paid full when the customer pays 5% of the
                                unit price otherwise, it will be partially paid:
                            </p>
                            <ul>
                                <li>Hyde Park: Commission paid once customer pays 5%</li>
                                <li>LMD: Commission paid once customer pays 5%</li>
                                <li>Mabany Edris: Commission paid once customer pays 5%</li>
                            </ul>
                            <p>
                                <b>Note:</b> The second half of the commission will be paid upon collection and this does not apply to
                                express payment
                            </p>
                            <p>
                                <b>Important note: </b> The list of developers is subject to change, please keep in contact with your
                                account manager to know of any changes.
                            </p>
                        </div> */}
                    </Typography>
                </div>
            </Grid>
        </Grid>
    );
};
export default CommissionTermsPage;
