import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import LEADS_APIS from './apis';
import { CreatePartnerAsCrmLead, CreateTaskeenLead, ICreatePartnerAsLead, IFullLead, QueryLeads } from './interfaces';

export const queryLead = createAsyncThunk(
    'leads/query',
    async (
        requestData: {
            queryData: QueryLeads;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IFullLead | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(LEADS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const getLeadAnalytica = createAsyncThunk(
    'leads/analytica',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IFullLead | any> => {
        const { runSuccess } = requestData;
        try {
            const successRes = await axios.get(LEADS_APIS.analytica());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createPartnerAsLead = createAsyncThunk(
    'leads/create-partner-as-lead',
    async (
        requestData: {
            leadData: ICreatePartnerAsLead;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IFullLead | any> => {
        const { leadData, runSuccess } = requestData;

        try {
            const successRes = await axios.post(LEADS_APIS.createPartnerAsLead(), leadData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createPartnerAsLeadV2 = createAsyncThunk(
    'leads/create-partner-as-lead-V2',
    async (
        requestData: {
            leadData: CreatePartnerAsCrmLead;
            runSuccess?: () => void;
        } & { queryParams?: string },
        { rejectWithValue }
    ): Promise<IFullLead | any> => {
        const { leadData, runSuccess } = requestData;

        try {
            const successRes = await axios.post(`${LEADS_APIS.createPartnerAsLeadV2()}`, leadData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createTaskeenLead = createAsyncThunk(
    'leads/create-taskeen-lead',
    async (
        requestData: {
            leadData: CreateTaskeenLead;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<IFullLead | any> => {
        const { leadData, runSuccess } = requestData;

        try {
            const successRes = await axios.post(LEADS_APIS.createTaskeenLead(), leadData);
            console.log(successRes);

            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
