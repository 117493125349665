import { HeadProps } from 'ui-component/Tables/NawyTable/Headers';

const SalesAgentsHeaders: HeadProps[] = [
    {
        id: 'userHistory',
        align: 'left',
        disablePadding: false,
        label: ' ',
        minWidth: '50px',
        sort: true
    },
    {
        disablePadding: true,
        sort: true,
        id: 'id',
        label: 'name',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'name',
        label: 'phone',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: true,
        id: '_count.salesAgents',
        label: 'group',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'disabled',
        label: 'status',
        align: 'center'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'creationDate',
        label: 'invited-by',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'createdAt',
        label: 'created-at',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'updatedAt',
        label: 'updated-at',
        align: 'left'
    },
    {
        disablePadding: true,
        sort: true,
        id: 'actions',
        label: 'actions',
        minWidth: '80px',
        align: 'right'
    }
];
export default SalesAgentsHeaders;
