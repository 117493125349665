import { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import TemplateBody from './TemplateBody';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import { Button, Grid } from '@mui/material';
import AlertDialog from 'ui-component/DeleteDialog';
import { LoadingButton } from '@mui/lab';
import { updateNotificationType } from 'store/slices/notifications/actions';
import { openSnackbar } from 'store/slices/snackbar';
import { UpdateNotificationTypeV1Dto } from 'store/slices/notifications/interfaces';

const Link = () => {
    const intl = useIntl();
    const [edit, setEdit] = useState(false);
    const UpdateLinkSchema = yup.object().shape({
        templateBody: yup.string().required(intl.formatMessage({ id: 'template-body-required' }))
    });
    const { notificationType } = useSelector((state) => state.notificationTypes);

    const handleToggleEdit = (formik: FormikHelpers<any>) => {
        setEdit(!edit);
        formik.resetForm();
    };
    const submit = (values: any, formik: FormikHelpers<any>) => {
        formik.setSubmitting(true);
        let link = values.templateBody;
        link = link.length === 0 ? null : link.replace(/\s/g, '');

        if (notificationType) {
            const filteredData: UpdateNotificationTypeV1Dto = {};
            if (link !== notificationType?.link) filteredData.link = link;

            if (Object.keys(filteredData).length === 0) {
                formik.setSubmitting(false);
                return;
            }
            dispatch(
                updateNotificationType({
                    typeId: notificationType.typeId,
                    updateData: filteredData,
                    runSuccess
                })
            );
        }
        formik.setSubmitting(false);
        handleToggleEdit(formik);
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    return (
        <Formik
            initialValues={{
                templateBody: notificationType?.link ?? ''
            }}
            onSubmit={submit}
            validationSchema={UpdateLinkSchema}
            enableReinitialize
        >
            {(formik) => (
                <Form style={{ position: 'relative', zIndex: '1' }}>
                    <MainCard
                        title={intl.formatMessage({ id: 'link' })}
                        secondary={
                            !edit ? (
                                <Grid container spacing={gridSpacing}>
                                    {notificationType?.link && (
                                        <Grid item>
                                            <AlertDialog
                                                type="button"
                                                title={intl.formatMessage({ id: 'remove' })}
                                                onConfirm={() => {
                                                    formik.setFieldValue('templateBody', '');
                                                    formik.submitForm();
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button variant="outlined" size="small" onClick={() => handleToggleEdit(formik)}>
                                            <FormattedMessage id={notificationType?.link ? 'edit' : 'add'} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={gridSpacing}>
                                    <Grid item>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            color="error"
                                            size="small"
                                            onClick={() => handleToggleEdit(formik)}
                                        >
                                            <FormattedMessage id="cancel" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            loading={formik.isSubmitting}
                                            variant="contained"
                                            size="small"
                                            onClick={formik.submitForm}
                                        >
                                            <FormattedMessage id="confirm" />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                        content={notificationType?.link || edit}
                    >
                        {(notificationType?.link || edit) && (
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sx={{ paddingBottom: edit ? '40px' : '10px', height: '100%' }}>
                                    <TemplateBody formik={formik} singleLine readOnly={!edit} />
                                </Grid>
                            </Grid>
                        )}
                    </MainCard>
                </Form>
            )}
        </Formik>
    );
};

export default Link;
