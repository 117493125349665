import { dispatch, useSelector } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';

import { useEffect, useRef, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { queryUsers } from 'store/slices/users/actions';

import { UserInterface } from 'store/slices/users/interfaces';

interface UsersSelectFilterProps {
    defaultValueId?: string;
    value: string;
    handleSearch: (newValue: string) => void;
    handleSelectedUser?: (user: UserInterface | null) => void;
    label: string;
}

const UsersSelectFilter = ({ defaultValueId, value, label, handleSearch, handleSelectedUser }: UsersSelectFilterProps) => (
    <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
        <Filter
            defaultValueId={defaultValueId}
            label={label}
            value={value}
            handleSelectedUser={handleSelectedUser}
            handleSearch={handleSearch}
        />
    </RoleGuard>
);

const Filter = ({ defaultValueId, value, label, handleSearch, handleSelectedUser }: UsersSelectFilterProps) => {
    const { users, loadingList } = useSelector((state) => state.users);

    const [newValue, setNewValue] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<UserInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (name: string) => {
        dispatch(queryUsers({ queryData: { name } }));
        setNewValue(name);
    };

    const handleOnChange = (user: UserInterface | null) => {
        setSelectedUser(user);
        handleSearch(user?.cognitoId || '');
        if (handleSelectedUser) handleSelectedUser(user);
    };

    useEffect(() => {
        dispatch(queryUsers({ queryData: {} }));
    }, []);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && users.data.length > 0) {
            firstRender.current = false;
            const user = users.data.find((c) => c.id === defaultValueId) ?? null;
            setSelectedUser(user);
        }
    }, [users, defaultValueId]);

    return (
        <Autocomplete
            id="autocomplete-generic-filter-users"
            fullWidth
            size="small"
            options={users.data}
            loading={loadingList}
            value={selectedUser}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option) => option?.cognitoId === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-users"
                    label={label}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};

export default UsersSelectFilter;
