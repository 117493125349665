import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// assets

import { WEBSITE_FRONTEND } from 'store/apis';
import AuthLogin from '../Actions/AuthLogin';

const LoginRightPanelV2 = () => {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <Grid item justifyContent="flex-start">
                    <Typography variant="caption" fontSize="16px" color="black" textAlign={isMediumScreen ? 'center' : 'inherit'}>
                        <FormattedMessage id="login" />
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AuthLogin />
            </Grid>

            <Grid item container direction="column" alignItems="flex-start" xs={12}>
                <Typography
                    component={Link}
                    to={`${WEBSITE_FRONTEND}terms`}
                    variant="subtitle1"
                    color="primary"
                    sx={{ textDecoration: 'none' }}
                >
                    <FormattedMessage id="terms-conditions" />
                </Typography>
            </Grid>
        </Grid>
    );
};

export default LoginRightPanelV2;
