// material-ui
import { Button, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import Chip from 'ui-component/extended/Chip';

// interfaces
import { gridSpacing } from 'store/constant';
import { InventoryQueryInterface, orderByTypes } from 'store/slices/inventoryTable/interfaces';

// assets
import CloseIcon from '@mui/icons-material/Close';

// third party
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'store';
import { businessTypes } from 'store/slices/nawyInventory/interfaces';

// ==============================|| INVENTORY FILTER GRID - FILTER VIEW ||============================== //

interface InventoryFilterViewProps {
    filter: InventoryQueryInterface;
    initialState: InventoryQueryInterface;
    filterIsEqual: (initialState: InventoryQueryInterface, filter: InventoryQueryInterface) => boolean;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}
const InventoryFilterView = ({ filter, filterIsEqual, handleFilter, initialState }: InventoryFilterViewProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const { compounds, areas, propertyTypes, developers } = useSelector((state) => state.general);

    const finishingStatuses = [
        { name: intl.formatMessage({ id: 'not-finished' }), value: 'not_finished' },
        { name: intl.formatMessage({ id: 'semi-finished' }), value: 'semi_finished' },
        { name: intl.formatMessage({ id: 'finished' }), value: 'finished' },
        { name: intl.formatMessage({ id: 'furnished' }), value: 'furnished' }
    ];

    const getCompoundName = (compound_id: number) => {
        const selectedCompound = compounds.find((compound) => compound.id === compound_id);
        return selectedCompound ? selectedCompound.name : undefined;
    };
    const removeCompound = (compound_id: number) => {
        const filterCompounds = filter.compounds_ids?.filter((compound) => compound !== compound_id);

        return filterCompounds && filterCompounds.length > 0 ? filterCompounds : undefined;
    };

    const getDeveloperName = (developer_id: number) => {
        const selectedDeveloper = developers.find((developer) => developer.id === developer_id);
        return selectedDeveloper ? selectedDeveloper.name : undefined;
    };
    const removeDeveloper = (developer_id: number) => {
        const filterDevelopers = filter.developers_ids?.filter((developer) => developer !== developer_id);

        return filterDevelopers && filterDevelopers.length > 0 ? filterDevelopers : undefined;
    };

    const getAreaName = (area_id: number) => {
        const selectedArea = areas.find((area) => area.id === area_id);

        return selectedArea ? selectedArea.name : intl.formatMessage({ id: 'unknown' });
    };

    const removeArea = (area_id: number) => {
        const filterAreas = filter.areas_ids?.filter((area) => area !== area_id);

        return filterAreas && filterAreas.length > 0 ? filterAreas : undefined;
    };

    const getPropertyTypeName = (property_type_id: number) => {
        const selectedPropertyType = propertyTypes.find((propertyType) => propertyType.id === property_type_id);

        return selectedPropertyType ? selectedPropertyType.name : intl.formatMessage({ id: 'unknown' });
    };

    const removePropertyType = (property_type_id: number) => {
        const filterPropertyTypes = filter.property_types_ids?.filter((property_type) => property_type !== property_type_id);

        return filterPropertyTypes && filterPropertyTypes.length > 0 ? filterPropertyTypes : undefined;
    };

    const getStatusName = (label: string, container: any) => {
        const selectedStatus = container.find((status: any) => status.value === label);
        return selectedStatus ? selectedStatus.name : intl.formatMessage({ id: 'unknown' });
    };

    const removeStatus = (label: string, container: any) => {
        const filterStatuses = container?.filter((status: any) => status !== label);
        return filterStatuses && filterStatuses.length > 0 ? filterStatuses : undefined;
    };

    const deliveryDateLabel = (date: string) => {
        const deliveryYear = new Date(date).getFullYear();
        const currentYear = new Date().getFullYear();
        let res;

        if (deliveryYear === currentYear) res = `${intl.formatMessage({ id: 'now' })}`;
        else if (deliveryYear === currentYear + 1)
            res = `${intl.formatMessage({ id: 'near-future' })}(${intl.formatMessage({ id: 'before' })} ${deliveryYear})`;
        else res = `${intl.formatMessage({ id: 'before' })} ${deliveryYear}`;
        return res;
    };

    return (
        <>
            {!filterIsEqual(initialState, filter) && (
                <Grid container spacing={gridSpacing} sx={{ pb: gridSpacing }} alignItems="center">
                    {filter.compounds_ids ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs="auto">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="compound" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.compounds_ids.length > 0 &&
                                                filter.compounds_ids.map((compound) => (
                                                    <Grid item key={compound}>
                                                        <Chip
                                                            size={matchDownMD ? 'small' : undefined}
                                                            label={getCompoundName(compound)}
                                                            chipcolor="secondary"
                                                            onDelete={() =>
                                                                handleFilter('compounds_ids', { compounds_ids: removeCompound(compound) })
                                                            }
                                                            sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.developers_ids ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item xs="auto">
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="developer" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.developers_ids.length > 0 &&
                                                filter.developers_ids.map((developer) => (
                                                    <Grid item key={`developer#${developer}`}>
                                                        <Chip
                                                            size={matchDownMD ? 'small' : undefined}
                                                            label={getDeveloperName(developer)}
                                                            chipcolor="secondary"
                                                            onDelete={() =>
                                                                handleFilter('developers_ids', {
                                                                    developers_ids: removeDeveloper(developer)
                                                                })
                                                            }
                                                            sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.areas_ids ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="area-name" />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.areas_ids.map((area_id) => (
                                                <Grid item key={area_id}>
                                                    <Chip
                                                        size={matchDownMD ? 'small' : undefined}
                                                        label={getAreaName(area_id)}
                                                        chipcolor="secondary"
                                                        onDelete={() => handleFilter('areas_ids', { areas_ids: removeArea(area_id) })}
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.property_types_ids ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="property-type" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.property_types_ids.map((property_type_id) => (
                                                <Grid item key={property_type_id}>
                                                    <Chip
                                                        size={matchDownMD ? 'small' : undefined}
                                                        label={getPropertyTypeName(property_type_id)}
                                                        chipcolor="secondary"
                                                        onDelete={() =>
                                                            handleFilter('property_types_ids', {
                                                                property_types_ids: removePropertyType(property_type_id)
                                                            })
                                                        }
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.finishing ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="finishing" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.finishing.map((status) => (
                                                <Grid item key={status}>
                                                    <Chip
                                                        size={matchDownMD ? 'small' : undefined}
                                                        label={getStatusName(status, finishingStatuses)}
                                                        chipcolor="secondary"
                                                        onDelete={() =>
                                                            handleFilter('finishing', {
                                                                finishing: removeStatus(status, filter.finishing)
                                                            })
                                                        }
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.business_types ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="sale-type" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            {filter.business_types.map((status) => (
                                                <Grid item key={status}>
                                                    <Chip
                                                        size={matchDownMD ? 'small' : undefined}
                                                        label={getStatusName(status, businessTypes)}
                                                        chipcolor="secondary"
                                                        onDelete={() =>
                                                            handleFilter('business_types', {
                                                                business_types: removeStatus(status, filter.business_types)
                                                            })
                                                        }
                                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {filter.orderBy ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="order-by" />
                                            </Typography>
                                        </Grid>

                                        <Grid item xs="auto" container spacing={1}>
                                            <Grid item>
                                                <Chip
                                                    size={matchDownMD ? 'small' : undefined}
                                                    label={getStatusName(filter.orderBy, orderByTypes)}
                                                    chipcolor="secondary"
                                                    onDelete={() =>
                                                        handleFilter('orderBy', {
                                                            orderBy: undefined
                                                        })
                                                    }
                                                    sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_bedrooms) || JSON.stringify(filter.max_bedrooms) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="bedrooms" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_bedrooms?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_bedrooms?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('bedrooms', { min_bedrooms: undefined, max_bedrooms: filter.max_bedrooms })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_bedrooms?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_bedrooms?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('bedrooms', { min_bedrooms: filter.min_bedrooms, max_bedrooms: undefined })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_area) || JSON.stringify(filter.max_area) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="area" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_area?.toString()
                                                }
                                                onDelete={() => handleFilter('area', { min_area: undefined, max_area: filter.max_area })}
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_area?.toString()
                                                }
                                                onDelete={() => handleFilter('area', { min_area: filter.min_area, max_area: undefined })}
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_garden_area) || JSON.stringify(filter.max_garden_area) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="garden-area" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_garden_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_garden_area?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('garden_area', {
                                                        min_garden_area: undefined,
                                                        max_garden_area: filter.max_garden_area
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_garden_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_garden_area?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('garden_area', {
                                                        min_garden_area: filter.min_garden_area,
                                                        max_garden_area: undefined
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_land_area) || JSON.stringify(filter.max_land_area) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="land-area" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_land_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_land_area?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('land_area', {
                                                        min_land_area: undefined,
                                                        max_land_area: filter.max_land_area
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_land_area?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_land_area?.toString()
                                                }
                                                onDelete={() =>
                                                    handleFilter('land_area', {
                                                        min_land_area: filter.min_land_area,
                                                        max_land_area: undefined
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_price) || JSON.stringify(filter.max_price) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="price" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_price?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_price?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('price', { min_price: undefined, max_price: filter.max_price })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_price?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_price?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('price', { min_price: filter.min_price, max_price: undefined })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_installments) || JSON.stringify(filter.max_installments) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="monthly-installments" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_installments?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_installments?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('installments', {
                                                        min_installments: undefined,
                                                        max_installments: filter.max_installments
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_installments?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_installments?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('installments', {
                                                        min_installments: filter.min_installments,
                                                        max_installments: undefined
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.min_down_payment) || JSON.stringify(filter.max_down_payment) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="down-payment" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="from" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.min_down_payment?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.min_down_payment?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('down_payment', {
                                                        min_down_payment: undefined,
                                                        max_down_payment: filter.max_down_payment
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="to" />
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={
                                                    filter.max_down_payment?.toString() === ('' || undefined)
                                                        ? intl.formatMessage({ id: 'any' })
                                                        : filter.max_down_payment?.toLocaleString(undefined, { maximumFractionDigits: 2 })
                                                }
                                                onDelete={() =>
                                                    handleFilter('down_payment', {
                                                        min_down_payment: filter.min_down_payment,
                                                        max_down_payment: undefined
                                                    })
                                                }
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    {JSON.stringify(filter.delivery_date) ? (
                        <Grid item>
                            <SubCard content={false}>
                                <CardContent sx={{ pb: '12px !important', p: 1.5 }}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle1">
                                                <FormattedMessage id="delivery-date" />
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Chip
                                                size={matchDownMD ? 'small' : undefined}
                                                label={filter.delivery_date ? deliveryDateLabel(filter.delivery_date) : ''}
                                                onDelete={() => handleFilter('delivery_date', { delivery_date: undefined })}
                                                chipcolor="info"
                                                sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </SubCard>
                        </Grid>
                    ) : null}

                    <Grid item>
                        <Button variant="outlined" startIcon={<CloseIcon />} color="error" onClick={() => handleFilter('reset', {})}>
                            <FormattedMessage id="clear-all" />
                        </Button>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default InventoryFilterView;
