import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

interface BecomePartnerCardDialogProps {
    isDialogOpen: boolean;
    setisDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    handleDialogClose: () => void;
    success: boolean;
    isInvited: boolean;
    userPhoneNumber: string;
}

const BecomePartnerCardDialogV2 = ({
    isDialogOpen,
    setisDialogOpen,
    handleDialogClose,
    success,
    isInvited,
    userPhoneNumber
}: BecomePartnerCardDialogProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            {!isInvited && success && (
                <DialogTitle color="green">
                    <FormattedMessage id="form-sent-success" />
                </DialogTitle>
            )}
            <DialogContent>
                <Typography>
                    {!isInvited && success ? (
                        <FormattedMessage id="expect-confirmation-call" />
                    ) : (
                        <Typography color="black" sx={{ fontWeight: 500 }}>
                            <FormattedMessage id="already-connected" values={{ phoneNumber: userPhoneNumber }} />
                        </Typography>
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                {!isInvited && success ? (
                    <Button variant={isSmallScreen ? 'text' : 'contained'} size="medium" onClick={handleDialogClose}>
                        <FormattedMessage id="got-it" />
                    </Button>
                ) : (
                    <Grid container xs={12} justifyContent="flex-end">
                        <Grid item>
                            <Button
                                variant="text"
                                size="medium"
                                onClick={() => {
                                    setisDialogOpen(false);
                                    navigate(`/forgot`, { replace: false });
                                }}
                            >
                                <FormattedMessage id="forgot-password" />
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="text"
                                size="medium"
                                onClick={() => {
                                    setisDialogOpen(false);
                                    navigate(`/loginV2`, { replace: false });
                                }}
                            >
                                <FormattedMessage id="back-to-sign-in" />
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default BecomePartnerCardDialogV2;
