import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { findCRM } from 'store/slices/crmControl/actions';
import NoDataFound from 'ui-component/NoDataFound';
import PageHeader from 'ui-component/PageHeader';
import Loading from 'ui-component/Tables/NawyTable/Loading';
import CRMDetailsSection from '../../../components/CRMControl/UI/CRMDetailsSection';
import SalesAgentsSection from '../../../components/SalesAgents/UI';
import BatchInvitation from 'components/SalesAgents/Actions/BatchInvitation';
import CreateInvitation from 'components/SalesAgents/Actions/CreateInvitation';

const CRMPage = () => {
    const { id: crmId } = useParams();
    const dispatch = useDispatch();
    const { crm } = useSelector((state) => state.crmControl);
    useEffect(() => {
        if (crmId) dispatch(findCRM({ crmId: parseInt(crmId, 10) }));
    }, [dispatch, crmId]);

    if (!crm || !crmId) return <Loading />;

    const actionItems = [<BatchInvitation key="batch-invite" crm={crm} />, <CreateInvitation key="create" crm={crm} />];

    return crm ? (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <PageHeader
                    title={crm.name}
                    actions={{
                        desktop: actionItems
                    }}
                />
            </Grid>

            <Grid item xs={12}>
                <CRMDetailsSection crm={crm} />
            </Grid>
            <Grid item xs={12}>
                <SalesAgentsSection crm={crm} />
            </Grid>
        </Grid>
    ) : (
        <NoDataFound />
    );
};

export default CRMPage;
