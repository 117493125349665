import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSelector } from 'store';
import { InvitationTypeEnum } from 'store/interfaces';
import { queryAccountManagers } from 'store/slices/accountManagers/actions';
import RoleGuard from 'utils/RoleGuard';

import { AccountManagerFullDataInterface } from 'store/slices/accountManagers/interfaces';

interface AccountManagersFilterProps {
    defaultValueId?: string;
    teamId?: number;
    value?: string;
    handleSearch: (newValue: string) => void;
    handleSelectedAccountManager?: (accountManager: AccountManagerFullDataInterface | null | undefined) => void;
}

const AccountManagersFilter = ({
    defaultValueId,
    teamId,
    value,
    handleSearch,
    handleSelectedAccountManager
}: AccountManagersFilterProps) => (
    <RoleGuard groups={[{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }]}>
        <Filter
            defaultValueId={defaultValueId}
            teamId={teamId}
            value={value}
            handleSearch={handleSearch}
            handleSelectedAccountManager={handleSelectedAccountManager}
        />
    </RoleGuard>
);

const Filter = ({ defaultValueId, teamId, value, handleSearch, handleSelectedAccountManager }: AccountManagersFilterProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const { accountManagers, listLoading } = useSelector((state) => state.accountManagers);
    const [newValue, setNewValue] = useState<string>('');
    const [selectedAccountManager, setSelectedAccountManager] = useState<AccountManagerFullDataInterface | null | undefined>(null);
    const firstRender = useRef(true);

    const handleInputChange = (v: string) => {
        setNewValue(v);
    };

    useEffect(() => {
        dispatch(queryAccountManagers({ queryData: { teamId, name: newValue } }));
    }, [dispatch, newValue, teamId]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && accountManagers.data.length > 0) {
            firstRender.current = false;
            const accountManager = accountManagers.data.find((a) => a.cognitoId === defaultValueId) ?? null;
            setSelectedAccountManager(accountManager);
            if (handleSelectedAccountManager) handleSelectedAccountManager(accountManager);
        }
    }, [accountManagers, defaultValueId, handleSelectedAccountManager]);

    const handleOnChange = (_: React.SyntheticEvent<Element, Event>, val: AccountManagerFullDataInterface | null) => {
        handleSearch(val?.cognitoId || '');
        if (handleSelectedAccountManager) handleSelectedAccountManager(val);
    };

    return (
        <Autocomplete
            id="autocomplete-generic-filter-account-managers"
            fullWidth
            size="small"
            options={accountManagers.data}
            loading={listLoading}
            onChange={handleOnChange}
            // sx={{ minWidth: '200px' }}
            inputValue={newValue}
            value={selectedAccountManager}
            onInputChange={(e, v) => {
                handleInputChange(v);
            }}
            isOptionEqualToValue={(option) => option?.cognitoId === value}
            getOptionLabel={(option) => `${option.user.name}`}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} container>
                            <Grid>{option.user.name}</Grid>
                        </Grid>

                        <Grid item xs={12}>
                            {option.team?.name ? (
                                <Typography variant="subtitle2">{option.team?.name}</Typography>
                            ) : (
                                <Typography variant="subtitle2" color="error">
                                    Not Assigned To Team
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-account-managers"
                    label={intl.formatMessage({ id: 'account-managers' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};

export default AccountManagersFilter;
