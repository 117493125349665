import { FAUNA_API, queryData } from 'store/apis';
import { QueryPartnersDashboardInterface } from './interfaces';

const PARTNERS_DASHBOARD_APIS = {
    /**
     * @GET /partners-dashboard
     * @returns full sale claim entity
     */
    query: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'partners-dashboard', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    financingTopPartners: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'cils/top-partners', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    primaryCilsTopDevelopers: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'primary-cils/top-developers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    saleClaimTopDevelopers: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sale-claims/top-developers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    saleClaimTopCompounds: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sale-claims/top-compounds', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    reservationsTopCompounds: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'reservations/top-compounds', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },

    reservationsTopDevelopers: (query: QueryPartnersDashboardInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'reservations/top-developers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    }
};
export default PARTNERS_DASHBOARD_APIS;
