// types
import { createSlice } from '@reduxjs/toolkit';
import { PartnersInvitationsInitialStateInterface } from './interfaces';
import {
    cancelPartnersInvitations,
    createPartnersInvitations,
    getPartnerInvitationsAnalytica,
    queryInvitationTypes,
    queryPartnerInvitationLimits,
    queryPartnersInvitations,
    resendPartnersInvitations
} from './actions';

// initial state
const initialState: PartnersInvitationsInitialStateInterface = {
    loading: false,
    error: false,
    loadingTypes: false,
    errorTypes: false,
    refresh: false,
    invitations: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    limitLoading: false,
    limits: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    types: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    analytica: {
        total: 0,
        accepted: 0
    },
    analyticaLoading: false,
    analyticaError: false
};

// ==============================|| SLICE - partnerInvitations ||============================== //

const partnerInvitations = createSlice({
    name: 'partner-invitations',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryPartnersInvitations.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryPartnersInvitations.fulfilled, (state, action) => {
                state.loading = false;
                state.invitations = action.payload;
            })
            .addCase(queryPartnersInvitations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createPartnersInvitations.pending, (state) => {
                state.error = false;
            })
            .addCase(createPartnersInvitations.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
                state.invitation = action.payload;
            })
            .addCase(createPartnersInvitations.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(resendPartnersInvitations.pending, (state) => {
                state.error = false;
            })
            .addCase(resendPartnersInvitations.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
                state.invitation = action.payload;
            })
            .addCase(resendPartnersInvitations.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(cancelPartnersInvitations.pending, (state) => {
                state.error = false;
            })
            .addCase(cancelPartnersInvitations.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
                state.invitation = action.payload;
            })
            .addCase(cancelPartnersInvitations.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(queryPartnerInvitationLimits.pending, (state) => {
                state.error = false;
                state.limitLoading = true;
            })
            .addCase(queryPartnerInvitationLimits.fulfilled, (state, action) => {
                state.limitLoading = false;
                state.limits = action.payload;
            })
            .addCase(queryPartnerInvitationLimits.rejected, (state, action) => {
                state.limitLoading = false;
                state.error = action.payload;
            })
            .addCase(queryInvitationTypes.pending, (state) => {
                state.errorTypes = false;
                state.loadingTypes = true;
            })
            .addCase(queryInvitationTypes.fulfilled, (state, action) => {
                state.loadingTypes = false;
                state.types = action.payload;
            })
            .addCase(queryInvitationTypes.rejected, (state, action) => {
                state.loadingTypes = false;
                state.errorTypes = action.payload;
            })
            .addCase(getPartnerInvitationsAnalytica.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = true;
            })
            .addCase(getPartnerInvitationsAnalytica.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analytica = action.payload;
            })
            .addCase(getPartnerInvitationsAnalytica.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })

            .addDefaultCase((state, action) => {});
    }
});

export default partnerInvitations.reducer;

// export const {} = partnerInvitations.actions;
