import CreateReservationDialog, { CreateReservationDialogContentProps } from 'components/Flows/Actions/CreateReservation/Dialog';
import useOverlay from 'hooks/useOverlay';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';

export interface CreateReservationDialogInterface {
    trigger?: JSX.Element;
    onClose?: () => void;
}

const CreateReservation = ({ trigger, onClose }: CreateReservationDialogInterface) => {
    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<CreateReservationDialogContentProps>('CreateReservation');

    const handleOnClose = () => {
        onClose?.();
        close();
    };

    return (
        <RoleGuard
            groups={[
                { name: InvitationTypeEnum.accountManagers },
                { name: InvitationTypeEnum.brokers },
                { name: InvitationTypeEnum.admins }
            ]}
        >
            {trigger}

            <CreateReservationDialog open={isCurrentOpened} handleClose={handleOnClose} {...payload} />
        </RoleGuard>
    );
};

export default CreateReservation;
