import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useOverlay from 'hooks/useOverlay';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export interface PaymentTermsDialogPayload {
    onProceed?: () => void;
    onCancel?: () => void;
}

export default function PaymentTermsDialog() {
    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<PaymentTermsDialogPayload>('PaymentTermsDialog');
    const [acceptedPaymentTerms, setAcceptedPaymentTerms] = useState(false);

    const handleOnProceed = () => {
        payload?.onProceed?.();
    };

    const handleOnCancel = () => {
        payload?.onCancel?.();
        setAcceptedPaymentTerms(false);
        close();
    };

    return (
        <Dialog
            open={isCurrentOpened}
            onClose={handleOnCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <FormattedMessage id="payment-terms" />
            </DialogTitle>
            <DialogContent>
                <Stack gap={1}>
                    <DialogContentText id="alert-dialog-description">
                        <FormattedMessage id="payment-terms-content" />
                    </DialogContentText>

                    <FormControlLabel
                        control={<Checkbox onChange={(e) => setAcceptedPaymentTerms(e.target.checked)} />}
                        label={<FormattedMessage id="checkbox-accept-payment-terms" />}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnCancel}>
                    <FormattedMessage id="cancel" />
                </Button>
                <Button variant="contained" autoFocus onClick={handleOnProceed} disabled={!acceptedPaymentTerms}>
                    <FormattedMessage id="proceed" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}
