import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import SETTINGS_APIS, { GROUPS_APIS } from './apis';
import { GroupInterface, SettingsInterface } from './interfaces';

export const getSettings = createAsyncThunk(
    'settings/get',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<SettingsInterface | any> => {
        const { runSuccess } = requestData;
        try {
            const successRes = await axios.get(SETTINGS_APIS.getSettings());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateSettings = createAsyncThunk(
    'settings/update',
    async (
        requestData: {
            updateData: SettingsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<SettingsInterface | any> => {
        const { updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(SETTINGS_APIS.updateSettings(updateData.id), {
                leadsLimit: updateData.leadsLimit,
                invitationsLimit: updateData.invitationsLimit
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getGroups = createAsyncThunk(
    'settings/get-groups',
    async (
        requestData: {
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<GroupInterface[] | any> => {
        const { runSuccess } = requestData;
        try {
            const successRes = await axios.get(GROUPS_APIS.getGroups());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const createGroup = createAsyncThunk(
    'settings/create-group',
    async (
        requestData: {
            createData: {
                name: string;
                description: string;
                domain?: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<GroupInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(GROUPS_APIS.createGroup(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteGroup = createAsyncThunk(
    'settings/delete-group',
    async (
        requestData: {
            groupId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { groupId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(GROUPS_APIS.deleteGroup(groupId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
