import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateDeveloperContact, DeveloperContactInterface, QueryDeveloperContactInterface, UpdateDeveloperContact } from './interfaces';
import DEVELOPER_CONTACT_APIS from './apis';
import axios from 'utils/axios';

const queryDeveloperContacts = createAsyncThunk(
    'developer-contact/query',
    async (
        requestData: {
            query: QueryDeveloperContactInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { runSuccess, query } = requestData;

        try {
            const successRes = await axios.get<DeveloperContactInterface>(DEVELOPER_CONTACT_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createDeveloperContact = createAsyncThunk(
    'developer-contact/create',
    async (
        requestData: {
            createData: CreateDeveloperContact;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(DEVELOPER_CONTACT_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateOrCreateDeveloperContact = createAsyncThunk(
    'developer-contact/update',
    async (
        requestData: {
            developerId: number;
            updateData: UpdateDeveloperContact;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { developerId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(DEVELOPER_CONTACT_APIS.update(developerId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteDeveloperContact = createAsyncThunk(
    'developer-contact/delete',
    async (
        requestData: {
            contactId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { contactId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(DEVELOPER_CONTACT_APIS.delete(contactId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default queryDeveloperContacts;
