// assets
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const OrganizationsPartnersItems: NavItemType = {
    id: 'organizationsPartners',
    title: <FormattedMessage id="partners" />,
    type: 'item',
    url: '/organizations',
    icon: CorporateFareIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'Partners'
    }
};

const PartnerItems: NavItemType = {
    id: 'organizations-side-menu-items-group',
    type: 'group',
    children: [OrganizationsPartnersItems]
};

export default PartnerItems;
