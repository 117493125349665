export interface InventoryQueryInterface {
    areas_ids?: number[];
    compounds_ids?: number[];
    property_types_ids?: number[];
    min_bedrooms?: number;
    max_bedrooms?: number;
    min_price?: number;
    max_price?: number;
    min_installments?: number;
    max_installments?: number;
    min_down_payment?: number;
    max_down_payment?: number;
    min_area?: number;
    max_area?: number;
    min_garden_area?: number;
    max_garden_area?: number;
    min_land_area?: number;
    max_land_area?: number;
    delivery_date?: string;
    developers_ids?: number[];
    finishing?: string[];
    business_types?: string[];
    orderBy?: string;
    page?: number;
    page_size?: number;
    language?: string;
}
export interface InventoryTableInitialState {
    selected_compound_id?: number;
    selected_property_type_id?: number;
    selected_property_id?: number;
}

interface IPropertyFinishing {
    [key: string]: string;
}

export const propertyFinishing: IPropertyFinishing = {
    not_finished: 'not-finished',
    semi_finished: 'semi-finished',
    finished: 'finished',
    furnished: 'furnished'
};

export const businessTypesObject: IPropertyFinishing = {
    developer_sale: 'primary',
    resale: 'resale',
    move_now_pay_later: 'nawy-now'
};

export const orderByTypes = [
    { name: 'Installments', value: 'installments' },
    { name: 'Price', value: 'price' },
    { name: 'Delivery Date', value: 'delivery_date' },
    { name: 'Unit Area', value: 'unit_area' },
    { name: 'Number Of Bedrooms', value: 'number_of_bedrooms' }
];
