import { Typography, Grid } from '@mui/material';
import { Box, useMediaQuery, useTheme } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import handsImg from 'assets/images/hands_2.svg';
import bulletPoint from 'assets/images/bulletPoint.svg';
import bulletPointWhite from 'assets/images/bulletPointWhite.svg';

function PartnersCompetitionContent() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { locale } = useIntl();

    return (
        <div>
            {isSmallScreen ? (
                <Grid container justifyContent="center" sx={{ background: theme.palette.primary[800] }}>
                    <Grid item marginTop={2} md={12}>
                        <Typography
                            variant="h2"
                            fontSize={isSmallScreen ? '24px' : '32px'}
                            fontWeight={700}
                            color="white"
                            align="center"
                            marginBottom={3}
                            marginTop={3}
                            margin={2}
                        >
                            <FormattedMessage id="competition-partner-text-1" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box
                            component="img"
                            src={handsImg}
                            alt="competition"
                            style={{
                                width: '307px',
                                height: '166px',
                                transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                filter: locale === 'ar' ? 'FlipH' : 'none'
                            }}
                            alignItems="center"
                            marginBottom={2}
                        />
                    </Grid>
                    <Grid item width="100%">
                        <Typography
                            align="center"
                            variant="h5"
                            fontSize={isSmallScreen ? '16px' : '24px'}
                            fontWeight={400}
                            color="white"
                            marginBottom={3}
                        >
                            <FormattedMessage id="win-a" />
                            <span style={{ color: '#E2B266', padding: '5px' }}>
                                <FormattedMessage id="Gold-Coin" />
                            </span>
                            <FormattedMessage id="and-Enter-the-Draw-for-a-Mercedes" />
                        </Typography>
                    </Grid>
                    <Grid item width="100%" marginRight={4} marginLeft={4}>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="competition-partner-text-3" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="competition-partner-text-4" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={4}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="competition-partner-text-5" />
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container justifyContent="center">
                    <Grid item md={12} justifyContent="center" alignItems="center">
                        <Grid
                            container
                            height="420px"
                            maxWidth="1136px"
                            borderRadius="24px"
                            zIndex={10}
                            style={{
                                backgroundImage: `linear-gradient(180deg, #0F3F79 0%, #00234C 70.58%), radial-gradient(48.22% 54.93% at 99.1% 54.16%, rgba(0, 189, 207, 0.20) 0%, rgba(0, 189, 207, 0.00) 100%), radial-gradient(41.2% 60.23% at 3.4% 38.75%, rgba(0, 189, 207, 0.20) 0%, rgba(0, 189, 207, 0.00) 100%), linear-gradient(0deg, #00234C 0%, #00234C 100%), linear-gradient(179deg, #E6ECF2 1.11%, #FFF 91.68%)`,
                                boxShadow: `0px 0px 2px 0px rgba(0, 0, 0, 0.50), 0px -10px 48px -5px rgba(22, 94, 239, 0.12), 0px 4px 24px 0px rgba(0, 0, 0, 0.24)`,
                                margin: '0 auto',
                                width: `calc(100% - 30px)`,
                                paddingInline: '20px',
                                transform: 'translateY(-100px)'
                            }}
                        >
                            <Grid container item xs={6}>
                                <Grid item container alignItems="center" justifyContent="center">
                                    <Box
                                        component="img"
                                        src={handsImg}
                                        alt="competition"
                                        sx={{ objectFit: 'contain', objectPosition: 'center' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={6} marginTop={4} marginBottom={2} marginLeft={8} md={5}>
                                <Typography
                                    variant="h2"
                                    fontSize={isSmallScreen ? '24px' : '32px'}
                                    fontWeight={700}
                                    color="white"
                                    align="left"
                                    marginBottom={3}
                                    marginTop={3}
                                >
                                    <FormattedMessage id="competition-partner-text-1" />
                                </Typography>
                                <Typography
                                    variant="h5"
                                    fontSize={isSmallScreen ? '16px' : '24px'}
                                    fontWeight={400}
                                    color="white"
                                    marginBottom={3}
                                >
                                    <FormattedMessage id="win-a" />
                                    <span style={{ color: '#E2B266', padding: '5px' }}>
                                        <FormattedMessage id="Gold-Coin" />
                                    </span>
                                    <FormattedMessage id="and-Enter-the-Draw-for-a-Mercedes" />
                                </Typography>

                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                                    <img
                                        src={bulletPoint}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="competition-partner-text-3" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                                    <img
                                        src={bulletPoint}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="competition-partner-text-4" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                                    <img
                                        src={bulletPoint}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="competition-partner-text-5" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
export default PartnersCompetitionContent;
