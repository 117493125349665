// types
import { createSlice } from '@reduxjs/toolkit';
import { AnalyticaInitialStateInterface } from './interfaces';
import { getCilsAnalytics, getInvitationsAnalytics, getSaleClaimsAnalytics } from './actions';

// initial state
const initialState: AnalyticaInitialStateInterface = {
    saleClaimsAnalyticsLoading: false,
    saleClaimsAnalyticsError: false,
    saleClaimsAnalytica: {
        total: 0,
        approved: 0,
        pending: 0,
        rejected: 0
    },
    cilsAnalyticsLoading: false,
    cilsAnalyticsError: false,
    cilsAnalytica: [],
    invitationsAnalyticsLoading: false,
    invitationsAnalyticsError: false,
    invitationsAnalytica: {
        total: 0,
        accepted: 0
    }
};

const analytica = createSlice({
    name: 'analytica',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getSaleClaimsAnalytics.pending, (state) => {
                state.saleClaimsAnalyticsLoading = true;
                state.saleClaimsAnalyticsError = false;
            })
            .addCase(getSaleClaimsAnalytics.fulfilled, (state, action) => {
                state.saleClaimsAnalyticsLoading = false;
                state.saleClaimsAnalytica = action.payload;
            })
            .addCase(getSaleClaimsAnalytics.rejected, (state, action) => {
                state.saleClaimsAnalyticsLoading = false;
                state.saleClaimsAnalyticsError = action.payload;
            })
            .addCase(getCilsAnalytics.pending, (state) => {
                state.cilsAnalyticsLoading = true;
                state.cilsAnalyticsError = false;
            })
            .addCase(getCilsAnalytics.fulfilled, (state, action) => {
                state.cilsAnalyticsLoading = false;
                state.cilsAnalytica = action.payload;
            })
            .addCase(getCilsAnalytics.rejected, (state, action) => {
                state.cilsAnalyticsLoading = false;
                state.cilsAnalyticsError = action.payload;
            })
            .addCase(getInvitationsAnalytics.pending, (state) => {
                state.invitationsAnalyticsLoading = true;
                state.invitationsAnalyticsError = false;
            })
            .addCase(getInvitationsAnalytics.fulfilled, (state, action) => {
                state.invitationsAnalyticsLoading = false;
                state.invitationsAnalytica = action.payload;
            })
            .addCase(getInvitationsAnalytics.rejected, (state, action) => {
                state.invitationsAnalyticsLoading = false;
                state.invitationsAnalyticsError = action.payload;
            })

            .addDefaultCase((state, action) => {});
    }
});

export default analytica.reducer;

// export const {} = analytica.actions;
