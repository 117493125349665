import { formattedQuery, FAUNA_API } from 'store/apis';
import { InvitationTypesQueryInterface } from './interfaces';

const COLLECTION_TITLE = 'invitations-types';

const INVITATION_TYPES_APIS = {
    /**
     * @Get
     * query invitation types
     * @param query
     * @returns InvitationTypesListInterface
     */
    query: (query: InvitationTypesQueryInterface) => {
        const formatQuery: string[] = [FAUNA_API, COLLECTION_TITLE, '?'];
        return formattedQuery(formatQuery, query);
    },
    /**
     * @Post
     * create a new invitation type
     * @Body CreateInvitationTypeInterface
     * @returns InvitationTypesInterface
     */
    create: () => `${FAUNA_API}${COLLECTION_TITLE}`,
    /**
     * @Get
     * get invitation type
     * @param id
     * @returns InvitationTypesInterface
     */
    getById: (id: number) => `${FAUNA_API}${COLLECTION_TITLE}/${id}`,
    /**
     * @Patch
     * update invitation type
     * @param id
     * @Body InvitationTypesUpdateInterface
     * @returns InvitationTypesInterface
     */
    update: (id: number) => `${FAUNA_API}${COLLECTION_TITLE}/${id}`,
    /**
     * @Patch
     * soft delete invitation type
     * @param id
     * @returns InvitationTypesInterface
     */
    softDelete: (id: number) => `${FAUNA_API}${COLLECTION_TITLE}/${id}/isDeleted`,
    /**
     * @Delete
     * delete invitation type
     * @param id
     * @returns InvitationTypesInterface
     */
    delete: (id: number) => `${FAUNA_API}${COLLECTION_TITLE}/${id}`
};

export default INVITATION_TYPES_APIS;
