/**
 * axios setup to use mock service
 */

import axios from 'axios';

// TODO: Remove every error dispatch in the application and employment a global error dispatch handler
const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error?.response && error?.response?.data) || 'Something went wrong, please try again later')
);

export default axiosServices;
