import { Chip, Dialog, DialogTitle, Grid, List, ListItem, Paper, Table, TableContainer, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CommonListInterface } from 'store/interfaces';
import { CommonHandleFilterType } from 'ui-component/Filters/types';
import NoDataFound from 'ui-component/NoDataFound';
import Headers, { HeadProps } from './Headers';
import Loading from './Loading';
import { Box } from '@mui/system';
import WidgetSection, { AnalyticsWidgetsData } from './Widget';
import MainCard from 'ui-component/cards/MainCard';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { gridSpacingMedium } from 'store/constant';
import CustomPagination from 'ui-component/CustomPagination';

interface NawyTableProps {
    content: Partial<CommonListInterface>;
    headers: HeadProps[];
    loading?: boolean;
    Title?: ReactNode;
    CardView: ReactNode;
    Body: ReactNode;
    analyticsWidgets?: AnalyticsWidgetsData[];
    ActionItem?: ReactNode;
    filter: { [key: string]: any };
    selectDisplay?: string;
    selectValue?: string;
    view?: 'table' | 'card' | 'dynamic';
    handleFilter: CommonHandleFilterType;
    handleSelection?: (selectedIds: any[], isAllSelected: boolean) => void;
    resetSelection?: boolean;
}

const NawyTable = ({
    Title,
    headers,
    Body,
    loading,
    filter,
    analyticsWidgets,
    content,
    CardView,
    view = 'dynamic',
    ActionItem,
    selectDisplay,
    selectValue,
    handleFilter,
    handleSelection,
    resetSelection
}: NawyTableProps) => {
    const theme = useTheme();

    const [nawyView, setNawyView] = useState(view);
    const [isPaginated, setIsPaginated] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [selectedRows, setSelectedRows] = useState<{ [key: string]: any }[]>([]);

    const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const TitleSection = Title || (ActionItem ? <></> : undefined);

    const SecondarySection = matchDownSM || !ActionItem ? undefined : ActionItem;

    const handleChangePageV2 = (newPage: number) => {
        handleFilter({ page: newPage, pageSize: content.pageSize });
        resetSelectedRows();
    };

    const handleChangePageSizeV2 = (newPageSize: number) => {
        handleFilter({ page: 1, pageSize: newPageSize });
        resetSelectedRows();
    };
    const handleRemoveSelectedRow = (value: any) => {
        if (selectValue) {
            setSelectedRows([...selectedRows].filter((row) => row[selectValue] !== value));
            if (handleSelection)
                handleSelection(
                    [...selectedRows].filter((row) => row[selectValue] !== value),
                    false
                );
        }
    };
    const handleSelect = (event: ChangeEvent<HTMLInputElement>) => {
        if (selectValue && selectDisplay) {
            let newRows = [...selectedRows];
            const val = content.data?.find((row) => row[selectValue] === event.target.value);
            if (val) {
                if (event.target.checked) {
                    newRows.push({
                        [selectValue]: val[selectValue],
                        [selectDisplay]: val[selectDisplay]
                    });
                } else {
                    newRows = newRows.filter((row) => row[selectValue] !== val[selectValue]);
                }
                setSelectedRows(newRows);
            }
            setSelectAll(!!checkIfAllSelected());

            if (handleSelection) handleSelection(newRows, !!checkIfAllSelected());
        }
    };

    const checkIfAllSelected = (): boolean | undefined => {
        if (selectValue)
            return content.data?.every((row) => selectedRows.some((selectedRow) => selectedRow[selectValue] === row[selectValue]));
        return undefined;
    };

    // doubt
    const checkIfSelectedInPage = (): boolean | undefined => {
        if (selectValue)
            return content.data?.some((row) => selectedRows.some((selectedRow) => selectedRow[selectValue] === row[selectValue]));
        return undefined;
    };

    const handleSelectHeader = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (selectValue && selectDisplay) {
            if (!checked) {
                const newSelectedRows = selectedRows.filter(
                    (row) => content.data?.findIndex((selectedRow) => selectedRow[selectValue] === row[selectValue]) === -1
                );
                setSelectedRows(newSelectedRows);
                setSelectAll(false);
                if (handleSelection) handleSelection(newSelectedRows, false);
            } else {
                const newSelected = [...selectedRows];
                if (content.data) {
                    for (const row of content.data) {
                        if (newSelected.findIndex((selectedRow) => selectedRow[selectValue] === row[selectValue]) === -1)
                            newSelected.push({
                                [selectValue]: row[selectValue],
                                [selectDisplay]: row[selectDisplay]
                            });
                    }
                    setSelectedRows(newSelected);
                    setSelectAll(true);
                    if (handleSelection) handleSelection(newSelected, true);
                }
            }
        }
    };

    const modifiedBody =
        selectDisplay && selectValue ? React.cloneElement(Body as React.ReactElement, { handleSelect, selectValue, selectedRows }) : Body;

    useEffect(() => {
        setIsPaginated(!!content.count && !!content.page && !!content.pageSize);
    }, [content]);

    const resetSelectedRows = () => {
        setSelectAll(false);
        setSelectedRows([]);
    };

    useEffect(() => {
        if (resetSelection) {
            resetSelectedRows();
        }
    }, [resetSelection]);

    useEffect(() => {
        if (view === 'dynamic') {
            if (matchDownSM) {
                setNawyView('card');
            } else {
                setNawyView('table');
            }
        }
    }, [matchDownSM, view]);

    return (
        <Grid container spacing={gridSpacingMedium} sx={{ minHeight: '100%' }}>
            <Grid item xs={12}>
                <Grid container alignItems="flex-start" justifyContent="space-between">
                    <Grid item xs={12} md={10}>
                        {TitleSection}
                    </Grid>
                    {!!SecondarySection && (
                        <Grid item display={{ sm: 'none', md: 'block' }} sm={4} md={2}>
                            {SecondarySection}
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {!!analyticsWidgets && (
                <Grid item xs={12}>
                    <WidgetSection analyticsWidgets={analyticsWidgets} />
                </Grid>
            )}
            <Grid item xs={12}>
                {loading ? (
                    <Loading />
                ) : nawyView === 'table' ? (
                    <MainCard content={false}>
                        {selectDisplay && selectValue && showMore ? (
                            <Dialog onClose={() => setShowMore(false)} open={showMore} fullWidth>
                                <DialogTitle>
                                    <FormattedMessage id="selected-rows" />
                                </DialogTitle>
                                <Box>
                                    <List sx={{ p: 1 }}>
                                        {selectedRows.map((row: any) => (
                                            <ListItem key={row[selectValue]}>
                                                <Typography>{row[selectDisplay]}</Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            </Dialog>
                        ) : null}
                        {selectValue && selectDisplay && selectedRows.length > 0 ? (
                            <Grid container m={1} spacing={1} sx={{ height: 40 }}>
                                {selectedRows.slice(0, 4).map((row: any) => (
                                    <Grid item key={row[selectValue]}>
                                        <Chip
                                            label={row[selectDisplay]}
                                            variant="outlined"
                                            onDelete={() => handleRemoveSelectedRow(row[selectValue])}
                                        />
                                    </Grid>
                                ))}
                                {selectedRows.length > 4 ? (
                                    <>
                                        <Grid item>
                                            <Typography>...</Typography>
                                        </Grid>

                                        <Grid item>
                                            <Chip
                                                label={`+${selectedRows.length - 4} more`}
                                                color="info"
                                                variant="outlined"
                                                onClick={() => setShowMore(true)}
                                            />
                                        </Grid>
                                    </>
                                ) : null}
                            </Grid>
                        ) : null}
                        <TableContainer component={Paper}>
                            <PerfectScrollbar component="div">
                                <Table size="small" aria-label="Collections table">
                                    <Headers
                                        sort={{ by: filter.orderBy, direction: filter.orderByDirection }}
                                        handleFilter={handleFilter}
                                        headers={headers}
                                        select={(selectDisplay && selectValue && true) || false}
                                        indeterminate={!checkIfAllSelected() && checkIfSelectedInPage() && selectedRows.length > 0}
                                        allSelected={selectAll}
                                        handleSelectAll={handleSelectHeader}
                                    />

                                    {modifiedBody}
                                </Table>
                            </PerfectScrollbar>
                            {content?.count !== undefined && content.count === 0 && (
                                <Grid container alignItems="center" justifySelf="center">
                                    <Grid item xs={12}>
                                        <NoDataFound ActionItem={SecondarySection} />
                                    </Grid>
                                </Grid>
                            )}
                        </TableContainer>
                        {isPaginated && (
                            <Grid item xs={12} pr={1}>
                                <CustomPagination
                                    count={content.count || 0}
                                    page={content?.page || 1}
                                    setPage={handleChangePageV2}
                                    setPageSize={handleChangePageSizeV2}
                                    pageSize={content.pageSize || 10}
                                />
                            </Grid>
                        )}
                    </MainCard>
                ) : (
                    <Grid item xs={12}>
                        {React.cloneElement(CardView as React.ReactElement<any>)}
                    </Grid>
                )}
            </Grid>
            {content?.count !== undefined && content.count === 0 && nawyView === 'card' && (
                <Grid container alignItems="center" justifySelf="center">
                    <Grid item xs={12}>
                        <NoDataFound ActionItem={SecondarySection} />
                    </Grid>
                </Grid>
            )}
            {isPaginated && nawyView === 'card' && (
                <Grid item xs={12} paddingBottom={{ xs: 5 }}>
                    <CustomPagination
                        count={content.count || 0}
                        page={content?.page || 1}
                        setPage={handleChangePageV2}
                        setPageSize={handleChangePageSizeV2}
                        pageSize={content.pageSize || 10}
                    />
                </Grid>
            )}

            {matchDownXS && (
                <Grid item sx={{ display: { xs: 'block', sm: 'none', position: 'fixed', bottom: 20, right: 30 } }}>
                    {ActionItem}
                </Grid>
            )}
        </Grid>
    );
};

export default NawyTable;
