// project imports
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import DevelopersContacts from 'views/developers-contacts';

// ==============================|| Developers Contacts ROUTING ||============================== //

// const allowedGroups = [
//     InvitationTypeEnum.brokers,
//     InvitationTypeEnum.admins,
//     InvitationTypeEnum.accountManagers,
//     InvitationTypeEnum.developerRelationsManagers
// ];

const DevelopersContactsRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/contacts',
            element: (
                <RoleGuard
                    groups={[
                        { name: InvitationTypeEnum.brokers },
                        { name: InvitationTypeEnum.admins },
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.developerRelationsManagers }
                    ]}
                    ifNotAllowed={<Navigate to="/home" replace />}
                >
                    <DevelopersContacts />
                </RoleGuard>
            )
        }
    ]
};

export default DevelopersContactsRoutes;
