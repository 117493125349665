import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const LeadsRequestPage = Loadable(lazy(() => import('views/leads/LeadRequests')));
const LeadsPage = Loadable(lazy(() => import('views/leads/Leads')));

// ==============================|| LEADS ROUTING ||============================== //

const LeadsRoutes = {
    path: '/leads',
    element: (
        <AuthGuard>
            <RoleGuard
                ifNotAllowed={<Navigate to="/home" replace />}
                groups={[
                    { name: InvitationTypeEnum.admins },
                    { name: InvitationTypeEnum.brokers },
                    { name: InvitationTypeEnum.internalAgents }
                ]}
            >
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: <LeadsPage />
        },
        {
            path: 'requests',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.internalAgents }]}
                >
                    <LeadsRequestPage />
                </RoleGuard>
            )
        }
    ]
};

export default LeadsRoutes;
