import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import useOverlay from 'hooks/useOverlay';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { deleteBanner, getRecentBanners, updateBanner } from 'store/slices/banners/actions';
import { BannerFormInterface, BannerInterface } from 'store/slices/banners/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import BannerDialog from './BannerDialog';

const EditBannerDialog = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [isDeleting, setIsDeleting] = useState(false);

    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<BannerInterface>('EditBannerDialog');

    const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
    const { loading, error } = useSelector((state) => state.banners);

    const runSuccessOnUpdate = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'banner-updated-successfully' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        close();
    };

    const runSuccessOnDelete = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'banner-deleted-successfully' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        close();
    };

    const handleOnSubmit = (values: BannerFormInterface) => {
        setDisabledSubmitButton(true);

        if (payload) {
            dispatch(
                updateBanner({
                    bannerId: payload.id,
                    updateData: values,
                    runSuccess: runSuccessOnUpdate
                })
            )
                .then(() => dispatch(getRecentBanners({})))
                .finally(() => setDisabledSubmitButton(false));
        }
    };

    const handleOnDelete = () => {
        if (payload) {
            dispatch(
                deleteBanner({
                    bannerId: payload.id,
                    runSuccess: runSuccessOnDelete
                })
            )
                .then(() => dispatch(getRecentBanners({})))
                .finally(() => {
                    setIsDeleting(false);
                    setDisabledSubmitButton(false);
                });
        }
    };

    useEffect(() => {
        setDisabledSubmitButton(false);
    }, [error]);

    return (
        <BannerDialog
            initialData={payload!}
            open={isCurrentOpened}
            onClose={() => close()}
            onSubmit={handleOnSubmit}
            footer={
                <Stack direction={{ xs: 'column', lg: 'row' }} justifyContent="space-between" width="100%" px={3} gap={2}>
                    <Stack order={{ xs: 2, lg: 1 }}>
                        <LoadingButton loading={isDeleting && loading} variant="contained" color="error" onClick={handleOnDelete}>
                            <FormattedMessage id="remove" /> <FormattedMessage id="banner" />
                        </LoadingButton>
                    </Stack>
                    <Stack direction={{ xs: 'column', lg: 'row' }} gap={2} order={{ xs: 1, lg: 2 }}>
                        <Stack order={{ xs: 2, lg: 1 }}>
                            <Button onClick={() => close()}>
                                <FormattedMessage id="cancel" />
                            </Button>
                        </Stack>
                        <Stack order={{ xs: 1, lg: 2 }}>
                            <LoadingButton loading={loading} type="submit" variant="contained" disabled={disabledSubmitButton}>
                                <FormattedMessage id="publish" />
                            </LoadingButton>
                        </Stack>
                    </Stack>
                </Stack>
            }
        />
    );
};

export default EditBannerDialog;
