import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { CRMControlInterface, QuerySalesAgentsInterface } from 'store/slices/crmControl/interfaces';
import Filters, { FiltersInterface } from 'ui-component/Filters';
import StringSearchFilter from 'ui-component/Filters/StringSearch';
import NawyTable from 'ui-component/Tables/NawyTable';
import SalesAgentsHeaders from './headers';
import { Grid, useMediaQuery } from '@mui/material';
import SalesAgentsTableBody from './TableBody';
import { dispatch, useSelector } from 'store';
import { querySalesAgents } from 'store/slices/salesAgents/actions';
import UsersPhoneFilter from 'ui-component/Filters/UsersPhoneFilter';
import { requestDelay } from 'store/constant';
import { debounce } from 'lodash';
import CRMCardView from './CardView';
import { useTheme } from '@mui/material/styles';
import DateRangePickerFilter from 'ui-component/Filters/DateRangePicker';
import FilterChip from 'ui-component/Filters/Chips/FilterChip';

const SalesAgentsSection = ({ crm }: { crm: CRMControlInterface }) => {
    const intl = useIntl();
    const location = 'CRM Sales Agents Table';
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [searchParams, setSearchParams] = useSearchParams();
    // DONE: fix refresh behavior
    const { salesAgents, loading, refresh } = useSelector((state) => state.salesAgents);

    const initialState: QuerySalesAgentsInterface = {
        name: searchParams.get('name') || '',
        phone: searchParams.get('phone') || '',
        createdAtMax: searchParams.get('createdAtMax') || '',
        createdAtMin: searchParams.get('createdAtMin') || ''
    };

    const [filter, setFilter] = useState(initialState);

    const [clearAll, setClearAll] = useState(false);

    useEffect(() => {
        let flag = false;
        Object.keys(filter).forEach((filterKey) => {
            const key = filterKey as keyof typeof filter;
            if (filter[key] !== undefined && filter[key] !== '') {
                flag = true;
            }
        });
        setClearAll(flag);
    }, [filter]);

    const filterData = useRef(
        debounce(async (queryData) => {
            dispatch(
                querySalesAgents({
                    queryData: { ...queryData, crmId: crm.id }
                })
            );
        }, requestDelay)
    ).current;

    useEffect(() => {
        filterData(filter);
    }, [filter, filterData, refresh]);

    const handleFilters = (newFilterField: any) => {
        const newFilter = { ...filter, ...newFilterField };
        setFilter(newFilter);
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            searchParams.set(filterKey, `${newFilter[key]}`);
            if (!newFilter[key]) searchParams.delete(filterKey);
            setSearchParams(searchParams);
        });
    };

    const handleClearAll = () => {
        const newFilter = { ...filter };
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            newFilter[key] = undefined;
        });
        setFilter(newFilter);
        setSearchParams(searchParams);
    };

    // DONE: Chip view
    const filters: FiltersInterface[] = [
        {
            mainView: (
                <StringSearchFilter
                    label={intl.formatMessage({ id: 'name' })}
                    location={location}
                    handleChange={(name) => handleFilters({ name })}
                />
            ),
            chipView: filter.name ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'name' })}
                    value={filter.name || ''}
                    handleOnClear={() => handleFilters({ name: undefined })}
                />
            ) : undefined
        },
        {
            mainView: (
                <UsersPhoneFilter
                    label={intl.formatMessage({ id: 'phone' })}
                    value={filter.phone}
                    handleSearch={(phone) => handleFilters({ phone })}
                />
            ),
            chipView: filter.phone ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'phone' })}
                    value={filter.phone || ''}
                    handleOnClear={() => handleFilters({ phone: undefined })}
                />
            ) : undefined
        },
        {
            mainView: (
                <DateRangePickerFilter
                    location={location}
                    DateMin={filter.createdAtMin || ''}
                    DateMax={filter.createdAtMax || ''}
                    handleSearch={(createdAtMin, createdAtMax) => handleFilters({ createdAtMin, createdAtMax })}
                    label={intl.formatMessage({ id: 'created-at' })}
                />
            ),
            chipView:
                filter.createdAtMax && filter.createdAtMin ? (
                    <FilterChip
                        label={intl.formatMessage({ id: 'created-at' })}
                        value={`${intl.formatDate(filter.createdAtMin, { dateStyle: 'medium' })}-${intl.formatDate(filter.createdAtMax, {
                            dateStyle: 'medium'
                        })}`}
                        handleOnClear={() => handleFilters({ createdAtMin: '', createdAtMax: '' })}
                    />
                ) : undefined
        }
    ];

    return (
        <Grid item>
            <NawyTable
                filter={filter}
                handleFilter={handleFilters}
                CardView={<CRMCardView />}
                loading={loading}
                Title={
                    <Filters
                        filters={filters}
                        clearAll={clearAll}
                        handleClearAll={handleClearAll}
                        popUpFilters={!!(matchDownMd || matchDownSm)}
                    />
                }
                Body={<SalesAgentsTableBody headersLength={SalesAgentsHeaders.length} salesAgents={salesAgents} />}
                headers={SalesAgentsHeaders}
                content={salesAgents}
            />
        </Grid>
    );
};

export default SalesAgentsSection;
