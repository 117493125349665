import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { createTypeConfig, deleteTypeConfig, updateTypeConfig } from 'store/slices/notifications/actions';
import {
    Channel,
    CreateTypeConfigV1Dto,
    TypeConfigFullUsersEntity,
    UpdateTypeConfigV1Dto,
    UsersInterface
} from 'store/slices/notifications/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import AlertDialog from 'ui-component/DeleteDialog';
import InfoItem from 'ui-component/InfoItem';
import SubCard from 'ui-component/cards/SubCard';
import * as yup from 'yup';
import DefaultUsers from '../DefaultUsers';

const WhatsAppConfig = ({
    addNew,
    onCancel
}: {
    addNew: boolean;
    onCancel?: React.Dispatch<React.SetStateAction<Channel | undefined>>;
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const [edit, setEdit] = useState(false);
    const [configType, setConfigType] = useState<TypeConfigFullUsersEntity>();

    const { notificationType } = useSelector((state) => state.notificationTypes);
    const whatsappSchema = yup.object().shape({
        whatsappTemplateName: yup
            .string()
            .required(`${intl.formatMessage({ id: 'template-name' })} ${intl.formatMessage({ id: 'is-required' })}`),
        whatsappLanguageCode: yup
            .mixed<['en', 'ar']>()
            .required(`${intl.formatMessage({ id: 'language-code' })} ${intl.formatMessage({ id: 'is-required' })}`),
        whatsappPdfFileName: yup.string(),
        whatsappPdfLink: yup.string(),
        whatsappVideoUrl: yup.string(),
        whatsappBtnUrl: yup.string(),
        whatsappVariables: yup.array(yup.string())
    });

    const handleToggleEdit = (formik: FormikHelpers<any>) => {
        setEdit(!edit);
        formik.resetForm();
    };

    const checkDefaultUsersChange = (array1: UsersInterface[], array2: UsersInterface[]) => {
        array1.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        array2.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        return !(array1.length === array2.length && array1.every((value, index) => value.cognitoId === array2[index].cognitoId));
    };

    const handleDeleteConfig = () => {
        if (notificationType && configType) {
            dispatch(
                deleteTypeConfig({
                    id: configType.configId,
                    typeId: notificationType.typeId,
                    runSuccess
                })
            );
        }
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    useEffect(() => {
        if (addNew) setEdit(true);
    }, [addNew]);

    useEffect(() => {
        const contyp = notificationType?.configurations.find((x) => x.channel === Channel.whatsapp);
        setConfigType(contyp);
    }, [notificationType]);

    return (
        <Formik
            initialValues={{
                whatsappTemplateName: configType?.whatsappTemplateName ?? '',
                whatsappLanguageCode: configType?.whatsappLanguageCode ?? '',
                whatsappPdfFileName: configType?.whatsappPdfFileName ?? '',
                whatsappPdfLink: configType?.whatsappPdfLink ?? '',
                whatsappVideoUrl: configType?.whatsappVideoUrl ?? '',
                whatsappBtnUrl: configType?.whatsappBtnUrl ?? '',
                whatsappVariables: configType?.whatsappVariables ?? [],
                defaultUsers: addNew ? notificationType?.defaultUsersInfo ?? [] : configType?.defaultUsersFullInfo ?? []
            }}
            onSubmit={(values, formikHelpers) => {
                formikHelpers.setSubmitting(true);

                if (notificationType) {
                    if (addNew) {
                        const filteredData: CreateTypeConfigV1Dto = {
                            typeId: notificationType.typeId,
                            channel: Channel.whatsapp,
                            whatsappTemplateName: values.whatsappTemplateName
                        };
                        if (checkDefaultUsersChange([...values.defaultUsers], [...notificationType.defaultUsersInfo]))
                            filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);
                        dispatch(
                            createTypeConfig({
                                createData: filteredData,
                                runSuccess
                            })
                        );
                    } else {
                        const config = notificationType.configurations.find((x) => x.channel === Channel.whatsapp);
                        if (config) {
                            const filteredData: UpdateTypeConfigV1Dto = {
                                typeId: notificationType.typeId,
                                whatsappTemplateName: values.whatsappTemplateName,
                                whatsappLanguageCode: values.whatsappLanguageCode,
                                whatsappPdfFileName: values.whatsappPdfFileName,
                                whatsappPdfLink: values.whatsappPdfLink,
                                whatsappVideoUrl: values.whatsappVideoUrl,
                                whatsappBtnUrl: values.whatsappBtnUrl,
                                whatsappVariables: values.whatsappVariables
                            };

                            if (config.defaultUsersFullInfo)
                                if (checkDefaultUsersChange([...values.defaultUsers], [...config.defaultUsersFullInfo]))
                                    filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);
                            if (values.whatsappTemplateName !== config.whatsappTemplateName)
                                filteredData.whatsappTemplateName = values.whatsappTemplateName;

                            if (Object.keys(filteredData).length === 1) {
                                formikHelpers.setSubmitting(false);
                                return;
                            }
                            dispatch(
                                updateTypeConfig({
                                    id: config.configId,
                                    updateData: filteredData,
                                    runSuccess
                                })
                            );
                        }
                    }
                }
                formikHelpers.setSubmitting(false);
                handleToggleEdit(formikHelpers);
            }}
            validationSchema={whatsappSchema}
            enableReinitialize
        >
            {(formik) => (
                <Form>
                    <SubCard
                        title={intl.formatMessage({ id: 'whatsapp' })}
                        secondary={
                            !edit ? (
                                <Grid container spacing={gridSpacing}>
                                    {notificationType?.configurations.find((x) => x.channel === Channel.whatsapp) && (
                                        <Grid item>
                                            <AlertDialog
                                                type="button"
                                                title={intl.formatMessage({ id: 'remove' })}
                                                onConfirm={() => {
                                                    handleDeleteConfig();
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button variant="outlined" size="small" onClick={() => handleToggleEdit(formik)}>
                                            <FormattedMessage id="edit" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={gridSpacing}>
                                    <Grid item>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                if (onCancel) onCancel(undefined);
                                                handleToggleEdit(formik);
                                            }}
                                        >
                                            <FormattedMessage id="cancel" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            loading={formik.isSubmitting}
                                            variant="contained"
                                            size="small"
                                            onClick={formik.submitForm}
                                        >
                                            <FormattedMessage id="confirm" />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'template-name' })} *`}
                                        id="whatsappTemplateName"
                                        name="whatsappTemplateName"
                                        value={formik.values.whatsappTemplateName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappTemplateName && formik.errors.whatsappTemplateName)}
                                        helperText={formik.touched.whatsappTemplateName && formik.errors.whatsappTemplateName}
                                    />
                                ) : (
                                    <InfoItem
                                        label={intl.formatMessage({ id: 'template-name' })}
                                        value={configType?.whatsappTemplateName}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        select
                                        label={`${intl.formatMessage({ id: 'language-code' })} *`}
                                        id="whatsappLanguageCode"
                                        name="whatsappLanguageCode"
                                        value={formik.values.whatsappLanguageCode}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappLanguageCode && formik.errors.whatsappLanguageCode)}
                                        helperText={formik.touched.whatsappLanguageCode && formik.errors.whatsappLanguageCode}
                                    >
                                        <MenuItem value="en">en</MenuItem>
                                        <MenuItem value="ar">ar</MenuItem>
                                    </TextField>
                                ) : (
                                    <InfoItem
                                        label={intl.formatMessage({ id: 'language-code' })}
                                        value={configType?.whatsappLanguageCode}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'btn-url' })}`}
                                        id="whatsappBtnUrl"
                                        name="whatsappBtnUrl"
                                        value={formik.values.whatsappBtnUrl}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappBtnUrl && formik.errors.whatsappBtnUrl)}
                                        helperText={formik.touched.whatsappBtnUrl && formik.errors.whatsappBtnUrl}
                                    />
                                ) : (
                                    <InfoItem label={intl.formatMessage({ id: 'btn-url' })} value={configType?.whatsappBtnUrl || '-'} />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'video-url' })}`}
                                        id="whatsappVideoUrl"
                                        name="whatsappVideoUrl"
                                        value={formik.values.whatsappVideoUrl}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappVideoUrl && formik.errors.whatsappVideoUrl)}
                                        helperText={formik.touched.whatsappVideoUrl && formik.errors.whatsappVideoUrl}
                                    />
                                ) : (
                                    <InfoItem label={intl.formatMessage({ id: 'video-url' })} value={configType?.whatsappVideoUrl || '-'} />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'pdf-file-name' })}`}
                                        id="whatsappPdfFileName"
                                        name="whatsappPdfFileName"
                                        value={formik.values.whatsappPdfFileName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappPdfFileName && formik.errors.whatsappPdfFileName)}
                                        helperText={formik.touched.whatsappPdfFileName && formik.errors.whatsappPdfFileName}
                                    />
                                ) : (
                                    <InfoItem
                                        label={intl.formatMessage({ id: 'pdf-file-name' })}
                                        value={configType?.whatsappPdfFileName || '-'}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'pdf-file-link' })}`}
                                        id="whatsappPdfLink"
                                        name="whatsappPdfLink"
                                        value={formik.values.whatsappPdfLink}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.whatsappPdfLink && formik.errors.whatsappPdfLink)}
                                        helperText={formik.touched.whatsappPdfLink && formik.errors.whatsappPdfLink}
                                    />
                                ) : (
                                    <InfoItem
                                        label={intl.formatMessage({ id: 'pdf-file-link' })}
                                        value={configType?.whatsappPdfLink || '-'}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                {edit ? (
                                    <Autocomplete
                                        multiple
                                        id="tags-standard"
                                        options={notificationType?.requiredData || []}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        defaultValue={configType?.whatsappVariables || []}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('whatsappVariables', value);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                helperText={formik.touched.whatsappVariables && formik.errors.whatsappVariables}
                                                error={Boolean(formik.touched.whatsappVariables && formik.errors.whatsappVariables)}
                                                label={intl.formatMessage({ id: 'variables' })}
                                                name="whatsappVariables"
                                                value={formik.values.whatsappVariables}
                                                onBlur={formik.handleBlur}
                                            />
                                        )}
                                    />
                                ) : (
                                    <InfoItem
                                        label={intl.formatMessage({ id: 'variables' })}
                                        value={configType?.whatsappVariables?.join(' ,')}
                                    />
                                )}
                                <Typography color={theme.palette.warning.main} variant="subtitle2">
                                    Warning: Order of variable is impotent
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <DefaultUsers edit={edit} formik={formik} />
                            </Grid>
                        </Grid>
                    </SubCard>
                </Form>
            )}
        </Formik>
    );
};

export default WhatsAppConfig;
