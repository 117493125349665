import { FabProps, FabTypeMap, Fab as MuiFab } from '@mui/material';

const fabStyles: FabTypeMap['props']['sx'] = {
    display: { lg: 'none' },
    position: 'fixed',
    bottom: 16,
    insetInlineEnd: 16,
    borderRadius: 1
};

const Fab = ({ children, ...props }: FabProps) => (
    <MuiFab variant="extended" color="primary" sx={fabStyles} {...props}>
        {children}
    </MuiFab>
);

export default Fab;
