// types
import { createSlice } from '@reduxjs/toolkit';
import {
    changeBrokerRole,
    createBrokerFromExistingUser,
    getBrokerByCId,
    moveBroker,
    queryFullBrokers,
    queryMinimalBrokers,
    recoverBroker,
    updateBrokerSalesAgent
} from './actions';
import { BrokersInitialState } from './interfaces';
import { cancelOrganizationBrokerInvitation, softDeleteOrganizationBroker } from '../organizations/actions';

// initial state
const initialState: BrokersInitialState = {
    loading: false,
    error: false,
    listLoading: false,
    listError: false,
    listRefresh: false,
    refresh: false,
    brokersMinimal: { page: 1, pages: 1, pageSize: 10, count: 0, data: [] },
    brokers: {
        page: 1,
        pages: 1,
        pageSize: 10,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE - BROKERS ||============================== //

const brokers = createSlice({
    name: 'brokers',
    initialState,
    reducers: {
        refreshBroker(state) {
            state.refresh = !state.refresh;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(queryFullBrokers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryFullBrokers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.brokers = action.payload;
            })
            .addCase(queryFullBrokers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryMinimalBrokers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryMinimalBrokers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.brokersMinimal = action.payload;
            })
            .addCase(queryMinimalBrokers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getBrokerByCId.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getBrokerByCId.fulfilled, (state, action) => {
                state.loading = false;
                state.broker = action.payload;
            })
            .addCase(getBrokerByCId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(recoverBroker.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(softDeleteOrganizationBroker.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(createBrokerFromExistingUser.pending, (state) => {
                state.error = false;
            })
            .addCase(createBrokerFromExistingUser.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(changeBrokerRole.pending, (state) => {
                state.error = false;
            })
            .addCase(changeBrokerRole.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(changeBrokerRole.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateBrokerSalesAgent.pending, (state) => {
                state.error = false;
            })
            .addCase(updateBrokerSalesAgent.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(moveBroker.pending, (state) => {
                state.error = false;
            })
            .addCase(moveBroker.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(moveBroker.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(cancelOrganizationBrokerInvitation.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default brokers.reducer;

export const { refreshBroker } = brokers.actions;
