// types
import { createSlice } from '@reduxjs/toolkit';
import { getAccountManagerTarget, getAccountManagerTargets, getAccountManagers, setAccountManagersTarget } from './actions';
import { AccountManagersInitialState } from './interfaces';

// initial state
const initialState: AccountManagersInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    accountManagers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    },
    achieved: 0,
    targetHistory: []
};

// ==============================|| SLICE - ACCOUNT MANAGERS ||============================== //

const accountManagers = createSlice({
    name: 'accountManagerTargets',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getAccountManagerTarget.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagerTarget.fulfilled, (state, action) => {
                state.loading = false;
                state.targetHistory = action.payload;
            })
            .addCase(getAccountManagerTarget.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAccountManagerTargets.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagerTargets.fulfilled, (state, action) => {
                state.loading = false;
                state.Targets = action.payload;
            })
            .addCase(getAccountManagerTargets.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            .addCase(getAccountManagers.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getAccountManagers.fulfilled, (state, action) => {
                state.loading = false;
                state.accountManagers = action.payload;
            })
            .addCase(getAccountManagers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(setAccountManagersTarget.pending, (state) => {
                state.error = false;
            })
            .addCase(setAccountManagersTarget.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(setAccountManagersTarget.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addDefaultCase((state, action) => {});
    }
});

export default accountManagers.reducer;
