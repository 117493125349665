import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import AlertDialog from 'ui-component/DeleteDialog';
import SubCard from 'ui-component/cards/SubCard';
import * as yup from 'yup';
import TemplateBody from '../TemplateBody';
import { Channel, CreateTypeConfigV1Dto, UpdateTypeConfigV1Dto, UsersInterface } from 'store/slices/notifications/interfaces';
import DefaultUsers from '../DefaultUsers';
import { openSnackbar } from 'store/slices/snackbar';
import { createTypeConfig, deleteTypeConfig, updateTypeConfig } from 'store/slices/notifications/actions';

const EmailConfig = ({ addNew, onCancel }: { addNew: boolean; onCancel?: React.Dispatch<React.SetStateAction<Channel | undefined>> }) => {
    const intl = useIntl();
    const [edit, setEdit] = useState(false);
    const { notificationType } = useSelector((state) => state.notificationTypes);
    const emailSchema = yup.object().shape({
        subject: yup.string().required(intl.formatMessage({ id: 'subject-required' })),
        emailFrom: yup
            .string()
            .email(intl.formatMessage({ id: 'email-valid' }))
            .matches(/^[a-zA-Z0-9._%+-]+@nawy\.com$/, intl.formatMessage({ id: 'format-@nawy' })),
        defaultUsers: yup.mixed<UsersInterface>(),
        templateBody: yup.string().required(intl.formatMessage({ id: 'template-body-required' }))
    });

    const handleToggleEdit = (formik: FormikHelpers<any>) => {
        setEdit(!edit);
        formik.resetForm();
    };

    const checkDefaultUsersChange = (array1: UsersInterface[], array2: UsersInterface[]) => {
        array1.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        array2.sort((a, b) => a.cognitoId.localeCompare(b.cognitoId));
        return !(array1.length === array2.length && array1.every((value, index) => value.cognitoId === array2[index].cognitoId));
    };

    const submit = (values: any, formik: FormikHelpers<any>) => {
        formik.setSubmitting(true);
        if (notificationType) {
            if (addNew) {
                const filteredData: CreateTypeConfigV1Dto = {
                    typeId: notificationType.typeId,
                    channel: Channel.email,
                    subject: values.subject,
                    emailFrom: values.emailFrom.length === 0 ? null : values.emailFrom,
                    body: values.templateBody
                };
                if (checkDefaultUsersChange([...values.defaultUsers], [...notificationType.defaultUsersInfo]))
                    filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);
                dispatch(
                    createTypeConfig({
                        createData: filteredData,
                        runSuccess
                    })
                );
            } else {
                const config = notificationType.configurations.find((x) => x.channel === Channel.email);
                if (config) {
                    const filteredData: UpdateTypeConfigV1Dto = {
                        typeId: notificationType.typeId
                    };
                    if (values.templateBody !== config.body) filteredData.body = values.templateBody;
                    if (config.defaultUsersFullInfo)
                        if (checkDefaultUsersChange([...values.defaultUsers], [...config.defaultUsersFullInfo]))
                            filteredData.defaultUsers = values.defaultUsers.map((user: UsersInterface) => user.cognitoId);
                    if (values.subject !== config.subject) filteredData.subject = values.subject;
                    if (values.emailFrom !== config.emailFrom) {
                        filteredData.emailFrom = values.emailFrom.length > 0 ? values.emailFrom : null;
                    }

                    if (Object.keys(filteredData).length === 1) {
                        formik.setSubmitting(false);
                        return;
                    }
                    dispatch(
                        updateTypeConfig({
                            id: config.configId,
                            updateData: filteredData,
                            runSuccess
                        })
                    );
                }
            }
        }
        formik.setSubmitting(false);
        handleToggleEdit(formik);
    };

    const handleDeleteConfig = () => {
        if (notificationType) {
            const config = notificationType.configurations.find((x) => x.channel === Channel.email);
            if (config)
                dispatch(
                    deleteTypeConfig({
                        id: config.configId,
                        typeId: notificationType.typeId,
                        runSuccess
                    })
                );
        }
    };

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-updated-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    useEffect(() => {
        if (addNew) setEdit(true);
    }, [addNew]);

    return (
        <Formik
            initialValues={{
                subject: notificationType?.configurations.find((x) => x.channel === Channel.email)?.subject ?? '',
                emailFrom: notificationType?.configurations.find((x) => x.channel === Channel.email)?.emailFrom ?? '',
                defaultUsers: addNew
                    ? notificationType?.defaultUsersInfo ?? []
                    : notificationType?.configurations.find((x) => x.channel === Channel.email)?.defaultUsersFullInfo ?? [],
                templateBody: notificationType?.configurations.find((x) => x.channel === Channel.email)?.body ?? ''
            }}
            onSubmit={submit}
            validationSchema={emailSchema}
            enableReinitialize
        >
            {(formik) => (
                <Form>
                    <SubCard
                        title={intl.formatMessage({ id: 'email' })}
                        secondary={
                            !edit ? (
                                <Grid container spacing={gridSpacing}>
                                    {notificationType?.configurations.find((x) => x.channel === Channel.email) && (
                                        <Grid item>
                                            <AlertDialog
                                                type="button"
                                                title={intl.formatMessage({ id: 'remove' })}
                                                onConfirm={() => {
                                                    handleDeleteConfig();
                                                }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button variant="outlined" size="small" onClick={() => handleToggleEdit(formik)}>
                                            <FormattedMessage id="edit" />
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={gridSpacing}>
                                    <Grid item>
                                        <Button
                                            disabled={formik.isSubmitting}
                                            color="error"
                                            size="small"
                                            onClick={() => {
                                                if (onCancel) onCancel(undefined);
                                                handleToggleEdit(formik);
                                            }}
                                        >
                                            <FormattedMessage id="cancel" />
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <LoadingButton
                                            loading={formik.isSubmitting}
                                            variant="contained"
                                            size="small"
                                            onClick={formik.submitForm}
                                        >
                                            <FormattedMessage id="confirm" />
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            )
                        }
                    >
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={`${intl.formatMessage({ id: 'subject' })} *`}
                                        id="email-subject"
                                        name="subject"
                                        value={formik.values.subject}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.subject && formik.errors.subject)}
                                        helperText={formik.touched.subject && formik.errors.subject}
                                    />
                                ) : (
                                    <>
                                        <Typography>
                                            <FormattedMessage id="subject" />:
                                        </Typography>
                                        <Typography variant="h3">{formik.values.subject}</Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                {edit ? (
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'email-from' })}
                                        id="config-email-from"
                                        name="emailFrom"
                                        value={formik.values.emailFrom}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.emailFrom && formik.errors.emailFrom)}
                                        helperText={formik.touched.emailFrom && formik.errors.emailFrom}
                                    />
                                ) : (
                                    <>
                                        <Typography>
                                            <FormattedMessage id="email-from" />:
                                        </Typography>
                                        <Typography variant="h3">{formik.values.emailFrom}</Typography>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <DefaultUsers edit={edit} formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ marginBottom: '5px' }}>
                                    <FormattedMessage id="template-body" />:
                                </Typography>
                                <TemplateBody
                                    singleLine={false}
                                    formik={formik}
                                    readOnly={!edit}
                                    selectedNotificationType={Channel.email}
                                />
                            </Grid>
                        </Grid>
                    </SubCard>
                </Form>
            )}
        </Formik>
    );
};

export default EmailConfig;
