import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Grid, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import { ReactElement, cloneElement, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { gridSpacingSmall } from 'store/constant';
import PopupFiltersForm from './PopUpFiltersForm';

export interface FiltersInterface {
    mainView?: ReactElement;
    chipView?: ReactElement | false;
    mobileView?: ReactElement;
}

interface FiltersProps {
    filters: FiltersInterface[];
    clearAll: boolean;
    disableClearAll?: boolean;
    handleClearAll: () => void;
    filterLimit?: number;
    popUpFilters?: boolean;
}

const Filters = ({ filters, handleClearAll, clearAll, filterLimit, popUpFilters = true, disableClearAll }: FiltersProps) => {
    const theme = useTheme();

    // mediaQueries
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const [filtersLimit, setFiltersLimit] = useState(1);

    useEffect(() => {
        if (matchDownSM) setFiltersLimit(1);
        else if (filterLimit) setFiltersLimit(filterLimit);
        else setFiltersLimit(3);
    }, [filterLimit, matchDownMD, matchDownSM]);

    if (filters.length === 0) return null;
    return (
        <Grid container rowGap={gridSpacingSmall} alignItems="center" width="100%">
            <Grid item xs={12}>
                <Stack direction="row" width="100%" spacing={gridSpacingSmall}>
                    {!matchDownSM
                        ? filters.map((filter, index) =>
                              index > filtersLimit
                                  ? false
                                  : filters.length === 1
                                  ? filter.mainView && (
                                        <Box key={index} sx={{ maxWidth: '300px', width: '100%', flexShrink: 0 }}>
                                            {filter.mainView}
                                        </Box>
                                    )
                                  : filter.mainView && (
                                        <Grid xs={12} key={index}>
                                            {filter.mainView}
                                        </Grid>
                                    )
                          )
                        : filters[0].mainView}
                    {popUpFilters && (
                        <Grid container justifyContent={{ xs: 'flex-end', sm: 'flex-end', md: 'flex-end', lg: 'flex-start' }}>
                            <PopupFiltersForm
                                filters={filters.map((filter) => filter.mobileView || filter.mainView)}
                                handleClearAll={handleClearAll}
                            />
                        </Grid>
                    )}
                </Stack>
            </Grid>

            {!!filters && clearAll && (
                <Grid item xs={12}>
                    <Stack gap={gridSpacingSmall} direction="row" overflow="auto" alignItems="center">
                        {!disableClearAll && (
                            <Button endIcon={<CloseOutlinedIcon />} size="small" sx={{ minWidth: 125 }} onClick={handleClearAll}>
                                <FormattedMessage id="clear-filters" />
                            </Button>
                        )}
                        {filters.map((filter, index) =>
                            filter.chipView ? cloneElement(filter?.chipView, { key: index }) : <Box key={index} sx={{ display: 'none' }} />
                        )}
                    </Stack>
                </Grid>
            )}
        </Grid>
    );
};

export default Filters;
