import useOverlay from 'hooks/useOverlay';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import CreateEOIDialog, { CreateeoiDialogContentProps } from './Dialog';

export interface CreateEOICTADialogInterface {
    trigger?: JSX.Element;
    onClose?: () => void;
}

const CreateEOICTA = ({ trigger, onClose }: CreateEOICTADialogInterface) => {
    const {
        isCurrentOpened,
        close,
        state: { payload }
    } = useOverlay<CreateeoiDialogContentProps>('CreateEOICTA');

    return (
        <RoleGuard
            groups={[
                { name: InvitationTypeEnum.accountManagers },
                { name: InvitationTypeEnum.brokers },
                { name: InvitationTypeEnum.admins }
            ]}
        >
            {trigger}

            <CreateEOIDialog open={isCurrentOpened} handleClose={close} {...payload} />
        </RoleGuard>
    );
};

export default CreateEOICTA;
