// material-ui
import { Grid, Skeleton, Typography } from '@mui/material';

// third-party
import Chart, { Props } from 'react-apexcharts';

// project imports
import useConfig from 'hooks/useConfig';

interface BarChartProps {
    isLoading: boolean;
    title?: string;
    chartData: Props;
}

const BarChart = ({ isLoading, title, chartData }: BarChartProps) => {
    const { rtlLayout } = useConfig();

    return (
        <Grid container sx={{ direction: rtlLayout ? 'rtl' : 'ltr' }}>
            {!!title && (
                <Grid item>
                    <Typography variant="h4">{title}</Typography>
                </Grid>
            )}
            <Grid
                item
                xs={12}
                sx={{
                    m: '0 !important',
                    '& .apexcharts-legend-text': { marginLeft: rtlLayout ? '8px' : 'initial' },
                    boxSizing: 'border-box'
                }}
            >
                {isLoading ? (
                    <Grid container>
                        {Array(5)
                            .fill('a')
                            .map((loading, i) => (
                                <Grid key={i} item xs={12}>
                                    <Skeleton height={70} />
                                </Grid>
                            ))}
                    </Grid>
                ) : (
                    <Chart {...chartData} />
                )}
            </Grid>
        </Grid>
    );
};

export default BarChart;
