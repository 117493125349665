import { FAUNA_API, queryData } from 'store/apis';
import { QueryOperationManagersInterface } from './interfaces';

const OPERATION_MANAGERS_APIS = {
    query: (query: QueryOperationManagersInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'operation-managers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    getById: (operationManagerCId: string) => `${FAUNA_API}operation-managers/${operationManagerCId}`,
    /**
     * @POST
     * @returns account manager
     */
    createOperationManagerFromExistingUser: () => `${FAUNA_API}operation-managers`,
    /**
     * @PATCH
     * Update account manager role
     * @returns account manager
     */
    changeOperationManagerRole: (operationManagerCId: string) => `${FAUNA_API}operation-managers/${operationManagerCId}`
};

export default OPERATION_MANAGERS_APIS;
