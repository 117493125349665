import { Grid, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing, requestDelay } from 'store/constant';
import { debounce } from 'lodash';
import Filters, { FiltersInterface } from 'ui-component/Filters';
import StringSearchFilter from 'ui-component/Filters/StringSearch';
import PageHeader from 'ui-component/PageHeader';
import NawyTable from 'ui-component/Tables/NawyTable';
import CRMControlCardView from '../../../components/CRMControl/UI/CardsView';
import CRMControlTableBody from '../../../components/CRMControl/UI/TableBody';
import { QueryCRMControlInterface } from 'store/slices/crmControl/interfaces';
import CRMControlHeaders from '../../../components/CRMControl/UI/headers';
import { dispatch, useSelector } from 'store';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { queryCRMs } from 'store/slices/crmControl/actions';
import UsersSelectFilter from 'ui-component/Filters/UsersSelectFilter';
import CRMsFilter from 'ui-component/Filters/CRMFilter';
import { CreateOrUpdateCRMBtn } from 'components/CRMControl/Actions/CreateOrUpdateCRMBtn';
import Fab from 'ui-component/Fab';

const CRMsPage = () => {
    const intl = useIntl();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    const actionItems = [
        <CreateOrUpdateCRMBtn
            key="create"
            location="CRM Control Page"
            variant={matchDownMd || matchDownSm ? 'fab' : 'btn'}
            buttonVariant="contained"
        />
    ];

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <PageHeader
                    title={intl.formatMessage({ id: 'crm-control' })}
                    actions={{
                        desktop: actionItems,
                        mobile: (
                            <CreateOrUpdateCRMBtn
                                key="create"
                                location="CRM Control Page"
                                trigger={(setOpenDialog) => (
                                    <Fab onClick={() => setOpenDialog(true)}>
                                        <FormattedMessage id="add-crm" />
                                    </Fab>
                                )}
                            />
                        )
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <CRMsSection />
            </Grid>
        </Grid>
    );
};

const CRMsSection = () => {
    const intl = useIntl();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const location = 'CRM Control Table';
    const { crms, listLoading, refresh } = useSelector((state) => state.crmControl);

    const [searchParams, setSearchParams] = useSearchParams();

    const handleFilters = (newFilterField: any) => {
        const newFilter = { ...filter, ...newFilterField };
        setFilter(newFilter);
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            searchParams.set(filterKey, `${newFilter[key]}`);
            if (!newFilter[key]) searchParams.delete(filterKey);
            setSearchParams(searchParams);
        });
    };

    const initialState: QueryCRMControlInterface = {
        id: parseInt(searchParams.get('id') || '', 10),
        name: searchParams.get('name') || '',
        createdById: searchParams.get('createdById') || '',
        page: parseInt(searchParams.get('page') || '', 10) || undefined,
        pageSize: parseInt(searchParams.get('pageSize') || '', 10) || undefined
    };

    const [filter, setFilter] = useState(initialState);

    const filterData = useRef(
        debounce(async (query) => {
            dispatch(
                queryCRMs({
                    query
                })
            );
        }, requestDelay)
    ).current;

    useEffect(() => {
        filterData(filter);
    }, [filter, filterData, refresh]);

    const filters: FiltersInterface[] = [
        {
            mainView: <CRMsFilter size="small" handleSearch={(newValue) => handleFilters({ id: newValue })} />
        },
        {
            mainView: (
                <StringSearchFilter
                    label={intl.formatMessage({ id: 'crm-id' })}
                    location={location}
                    handleChange={(id) => handleFilters({ id })}
                />
            )
        },
        {
            mainView: (
                <UsersSelectFilter
                    value={filter.createdById || ''}
                    defaultValueId={filter.createdById || ''}
                    handleSearch={(createdById) => handleFilters({ createdById })}
                    label={intl.formatMessage({ id: 'created-by' })}
                />
            )
        }
    ];

    return (
        <NawyTable
            filter={filter}
            handleFilter={handleFilters}
            CardView={<CRMControlCardView />}
            loading={listLoading}
            Title={<Filters filters={filters} handleClearAll={() => {}} clearAll={false} popUpFilters={!!(matchDownMd || matchDownSM)} />}
            Body={<CRMControlTableBody />}
            headers={CRMControlHeaders}
            content={crms}
        />
    );
};

export default CRMsPage;
