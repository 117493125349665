import { CommonInitialState, NameAndIdInterface } from 'store/interfaces';

import { Compound, DeveloperInterface } from '../developers/interfaces';

export interface CreateSaleClaimInitialInterface extends CommonInitialState {
    saleInfo?: CreateSaleInfoInterface;
    property?: CreateSaleFinancePropertyInterface;
    documents?: Array<any>;
    buyer?: CreateSaleBuyerInterface;
    organization?: { id?: string; name?: string; tierId?: string };
    broker?: NameAndIdInterface;
    stepper: StepperSectionsEnums;
    dialogOpen: boolean;
}

export interface CreateSaleInfoInterface {
    type: any;
    contractionDate: string;
    downPaymentDate: string;
    reservationDate: string;
    installmentsYears: number;
    downPayment: number;
    primaryCilId?: number;
    reservationId?: number;
}

export interface CreateSaleFinancePropertyInterface {
    developerName?: string;
    compoundId?: number;
    compoundName?: string;
    developer?: Partial<DeveloperInterface>;
    compound?: Partial<Compound>;
    propertyTypeId?: number;
    unitValue?: number;
    unitId?: string;
}

export interface CreateSaleBuyerInterface {
    buyerName?: string;
    buyerPhone?: string;
}
export enum StepperSectionsEnums {
    mainInfo = 'Main Information',
    propertyInfo = 'Property Information',
    buyerInfo = 'Buyer Information',
    documentsSection = 'Documents Section',
    brokerSection = 'Broker Section',
    review = 'Review'
}
