import CancelIcon from '@mui/icons-material/Cancel';
import UndoIcon from '@mui/icons-material/Undo';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';
import AccountManagersTeamsMultiSelect, {
    AccountManagersTeamsMultiSelectRef,
    SelectedTeamWithMembers
} from 'components/Organizations/UI/List/AccountManagersTeamsMultiSelect';
import useOverlay from 'hooks/useOverlay';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'store';
import { bulkAssignAccountManagerPreview, bulkAssignOrganizationsToAccountManager } from 'store/slices/accountManagers/actions';
import { BulkAssignOrganizationsToAccountManagerDto, BulkAssignPreviewResponse } from 'store/slices/accountManagers/interfaces';
import { openSnackbar } from 'store/slices/snackbar';

export interface ReassignAccountManagerDialogPayload {
    accountManagerId: string;
    organizationIds: string[];
    selectedTeams: SelectedTeamWithMembers[];
}

interface AccountManagerPreview {
    cognitoId: string;
    name: string;
    teamName: string;
    currentOrganizationsCount: number;
    suggestedOrganizations: { name: string; id: string; isRemoved?: boolean }[];
}

const ReassignAccountManagerOnOrganizationsDialog = () => {
    const pathParts = window.location.pathname.split('/');
    const userId = pathParts[3]; // Assuming URL structure: /admin/users/{id}

    const dispatch = useDispatch();
    const {
        isCurrentOpened,
        closeAll,
        state: { payload }
    } = useOverlay<ReassignAccountManagerDialogPayload>('ReassignAccountManagerDialog');
    const [searchParams, setSearchParams] = useSearchParams();

    // State declarations
    const [previewData, setPreviewData] = useState<AccountManagerPreview[]>([]);
    const [unassignedPartners, setUnassignedPartners] = useState<{ id: string; name: string; cognitoId: String; originalIndex: number }[]>(
        []
    );
    const [selectedUnassignedPartners, setSelectedUnassignedPartners] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<SelectedTeamWithMembers[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const teamsSelectRef = useRef<AccountManagersTeamsMultiSelectRef>(null);

    const fetchPreviewData = useCallback(
        (accountManagerIds: string[], organizationIds: string[]) => {
            setIsLoading(true);
            return dispatch(
                bulkAssignAccountManagerPreview({
                    data: {
                        assignedOrgs: organizationIds,
                        accountManagerIds,
                        excludedAccountManagerIds: accountManagerIds.length === 1 && accountManagerIds[0] === userId ? [] : [userId]
                    }
                })
            )
                .then((res) => {
                    const mappedData = (res.payload as BulkAssignPreviewResponse[]).map((item) => ({
                        cognitoId: item.accountManager.cognitoId,
                        name: item.accountManager.name,
                        teamName: item.accountManager.teamName,
                        currentOrganizationsCount: item.accountManager.currentOrganizationsCount,
                        suggestedOrganizations: item.accountManager.suggestedOrganizations
                    }));
                    setPreviewData(mappedData);
                })
                .catch(() => {
                    setPreviewData([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [dispatch, userId]
    );

    const handleClose = useCallback(() => {
        setUnassignedPartners([]);
        setSelectedUnassignedPartners([]);
        setSelectAll(false);
        setSelectedTeams([]);
        setPreviewData([]);
        closeAll();
    }, [closeAll]);

    const handleTeamsChange = useCallback(
        (newSelectedTeams: SelectedTeamWithMembers[], selectedAll: boolean) => {
            // Only proceed if there's a change in teams selection
            if (JSON.stringify(newSelectedTeams) !== JSON.stringify(selectedTeams)) {
                setSelectedTeams(newSelectedTeams);

                if (newSelectedTeams.length > 0) {
                    const accountManagerIds = newSelectedTeams.flatMap((team) => team.members.map((member) => member.cognitoId));

                    if (accountManagerIds.length > 0 && !isLoading) {
                        setIsLoading(true);
                        dispatch(
                            bulkAssignAccountManagerPreview({
                                data: {
                                    assignedOrgs: selectedUnassignedPartners || [],
                                    accountManagerIds,
                                    excludedAccountManagerIds:
                                        accountManagerIds.length === 1 && accountManagerIds[0] === userId ? [] : [userId]
                                }
                            })
                        )
                            .then((res) => {
                                const mappedData = (res.payload as BulkAssignPreviewResponse[]).map((item) => ({
                                    cognitoId: item.accountManager.cognitoId,
                                    name: item.accountManager.name,
                                    teamName: item.accountManager.teamName,
                                    currentOrganizationsCount: item.accountManager.currentOrganizationsCount,
                                    suggestedOrganizations: item.accountManager.suggestedOrganizations
                                }));

                                setPreviewData((prev) => {
                                    // Create a map of organizations that will be reassigned
                                    const reassignedOrgs = new Map<string, string>();
                                    mappedData.forEach((r) => {
                                        r.suggestedOrganizations.forEach((org) => {
                                            reassignedOrgs.set(org.id, r.cognitoId);
                                        });
                                    });

                                    // Remove organizations from previous account managers if they're being reassigned
                                    const updatedPrev = prev.map((manager) => ({
                                        ...manager,
                                        suggestedOrganizations: manager.suggestedOrganizations.filter((org) => {
                                            const newManagerId = reassignedOrgs.get(org.id);
                                            return (!newManagerId || newManagerId === manager.cognitoId) && !org.isRemoved;
                                        })
                                    }));

                                    // Filter out managers that are no longer selected
                                    const filteredData = updatedPrev.filter(
                                        (manager) =>
                                            accountManagerIds.includes(manager.cognitoId) || manager.suggestedOrganizations.length > 0
                                    );

                                    // Update or add new account managers
                                    const finalData = [...filteredData];
                                    mappedData.forEach((r) => {
                                        const existingIndex = finalData.findIndex((item) => item.cognitoId === r.cognitoId);

                                        if (existingIndex !== -1) {
                                            finalData[existingIndex] = {
                                                ...finalData[existingIndex],
                                                ...r,
                                                suggestedOrganizations: [
                                                    ...finalData[existingIndex].suggestedOrganizations,
                                                    ...r.suggestedOrganizations.map((org) => ({
                                                        ...org,
                                                        isRemoved: false
                                                    }))
                                                ]
                                            };
                                        } else {
                                            finalData.push({
                                                ...r,
                                                suggestedOrganizations: r.suggestedOrganizations.map((org) => ({
                                                    ...org,
                                                    isRemoved: false
                                                }))
                                            });
                                        }
                                    });

                                    return finalData;
                                });

                                setSelectedTeams([]);
                                teamsSelectRef.current?.reset();
                                teamsSelectRef.current?.closeDropdown();
                                setUnassignedPartners((prev) => prev.filter((partner) => !selectedUnassignedPartners.includes(partner.id)));
                                setSelectedUnassignedPartners([]);
                                setIsLoading(false);
                            })
                            .catch(() => {
                                setIsLoading(false);
                                setPreviewData([]);
                            });
                    } else {
                        setPreviewData([]);
                    }
                }
            }
        },
        [dispatch, selectedUnassignedPartners, selectedTeams, isLoading, userId]
    );

    const handleSelectAll = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked;
            setSelectAll(checked);
            setSelectedUnassignedPartners(checked ? unassignedPartners.map((p) => p.id) : []);
        },
        [unassignedPartners]
    );

    const handlePartnerSelect = useCallback(
        (partnerId: string) => {
            setSelectedUnassignedPartners((prev) => {
                const newSelection = prev.includes(partnerId) ? prev.filter((id) => id !== partnerId) : [...prev, partnerId];
                return newSelection;
            });

            if (selectedUnassignedPartners.length > 0 && selectedTeams.length > 0) {
                const accountManagerIds = selectedTeams.flatMap((team) => team.members.map((member) => member.cognitoId));
                const organizationIds = [...selectedUnassignedPartners.map((partner) => partner), ...(payload?.organizationIds || [])];

                fetchPreviewData(accountManagerIds, [...new Set(organizationIds)]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedUnassignedPartners.length, selectedTeams.length]
    );

    // Update selectAll state when all partners are selected and reset teams select when no unassigned partners
    useEffect(() => {
        if (unassignedPartners.length > 0) {
            const allSelected = unassignedPartners.every((partner) => selectedUnassignedPartners.includes(partner.id));
            setSelectAll(allSelected);
        } else {
            // Reset teams select when there are no unassigned partners
            setSelectedTeams([]);
            teamsSelectRef.current?.reset();
        }
    }, [unassignedPartners, selectedUnassignedPartners]);

    const handleRemovePartner = useCallback((org: { name: string; id: string }, cognitoId: string, previewIndex: number) => {
        setPreviewData((prev) =>
            prev.map((manager, index) => {
                if (index === previewIndex) {
                    return {
                        ...manager,
                        suggestedOrganizations: manager.suggestedOrganizations.map((partner) =>
                            partner.id === org.id ? { ...partner, isRemoved: true } : partner
                        )
                    };
                }
                return manager;
            })
        );

        // Add the removed partner to the unassigned list
        setUnassignedPartners((prev) => [...prev, { id: org.id, name: org.name, cognitoId, originalIndex: previewIndex }]);
    }, []);

    const handleUndoAll = useCallback(() => {
        setPreviewData((prev) =>
            prev.map((manager, index) => {
                // Find all unassigned partners that belong to this manager
                const partnersToRestore = unassignedPartners.filter((partner) => partner.cognitoId === manager.cognitoId);

                // If there are partners to restore, merge them back into the suggestedOrganizations array
                if (partnersToRestore.length > 0) {
                    const restoredOrganizations = manager.suggestedOrganizations.map((org) => {
                        const partnerToRestore = partnersToRestore.find((p) => p.id === org.id);
                        return partnerToRestore ? { ...org, isRemoved: false } : org;
                    });

                    return {
                        ...manager,
                        suggestedOrganizations: restoredOrganizations
                    };
                }

                // If no partners to restore, return the manager as-is
                return manager;
            })
        );

        // Clear all selections
        setUnassignedPartners([]);
        setSelectedUnassignedPartners([]);
        setSelectAll(false);
        handleTeamsChange([], false);
        // Reset the teams select component
        teamsSelectRef.current?.reset();
    }, [unassignedPartners, handleTeamsChange]);

    const handleUndoRemove = useCallback((partner: { id: string; name: string; originalIndex: number }) => {
        setPreviewData((prev) =>
            prev.map((manager, index) => {
                if (index === partner.originalIndex) {
                    return {
                        ...manager,
                        suggestedOrganizations: manager.suggestedOrganizations.map((p) =>
                            p.id === partner.id ? { ...p, isRemoved: false } : p
                        )
                    };
                }
                return manager;
            })
        );

        // Remove the partner from the unassigned list
        setUnassignedPartners((prev) => prev.filter((p) => p.id !== partner.id));
        setSelectedUnassignedPartners((prev) => prev.filter((id) => id !== partner.id));
    }, []);

    const renderChips = useCallback(
        (item: AccountManagerPreview) => {
            if (isLoading) {
                return (
                    <>
                        <Skeleton
                            variant="rectangular"
                            width={80}
                            height={24}
                            sx={{
                                borderRadius: '16px',
                                bgcolor: 'primary.100'
                            }}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={80}
                            height={24}
                            sx={{
                                borderRadius: '16px',
                                bgcolor: 'primary.100'
                            }}
                        />
                    </>
                );
            }

            // Filter out removed organizations
            const organizations = item.suggestedOrganizations.filter((org) => !org.isRemoved);

            // Display the first few organizations and show a "+X more" chip if there are more
            const visibleOrgs = organizations.slice(0, 2); // Show first 2 organizations
            const remainingOrgs = organizations.slice(2); // Get remaining organizations
            const remainingCount = remainingOrgs.length; // Calculate remaining organizations

            return (
                <Stack direction="row" spacing={0.5} alignItems="center">
                    {visibleOrgs.map((org) => (
                        <Chip
                            key={org.id}
                            label={org.name}
                            size="small"
                            variant="outlined"
                            onDelete={() =>
                                handleRemovePartner(
                                    org,
                                    item.cognitoId,
                                    previewData.findIndex((prev) => prev.cognitoId === item.cognitoId)
                                )
                            }
                            deleteIcon={<CancelIcon />}
                            sx={{
                                bgcolor: 'primary.100',
                                color: 'primary.main',
                                borderRadius: '16px',
                                height: '24px',
                                border: 'none',
                                maxWidth: '150px',
                                '& .MuiChip-label': {
                                    px: 1,
                                    maxWidth: '120px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap'
                                },
                                '& .MuiChip-deleteIcon': {
                                    fontSize: '16px',
                                    color: 'primary.main',
                                    '&:hover': {
                                        color: 'primary.dark'
                                    },
                                    marginRight: '4px',
                                    marginLeft: '-4px'
                                }
                            }}
                        />
                    ))}
                    {remainingCount > 0 && (
                        <Tooltip
                            title={
                                <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
                                    <Stack spacing={0.5} sx={{ p: 0.5 }}>
                                        {remainingOrgs.map((org) => (
                                            <Stack
                                                key={org.id}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                spacing={1}
                                            >
                                                <Typography variant="caption" sx={{ color: 'white' }}>
                                                    {org.name}
                                                </Typography>
                                                <CancelIcon
                                                    sx={{
                                                        color: 'white',
                                                        fontSize: '0.875rem',
                                                        cursor: 'pointer',
                                                        '&:hover': { opacity: 0.8 }
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        handleRemovePartner(
                                                            org,
                                                            item.cognitoId,
                                                            previewData.findIndex((prev) => prev.cognitoId === item.cognitoId)
                                                        );
                                                    }}
                                                />
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Box>
                            }
                            arrow
                            PopperProps={{
                                sx: {
                                    '& .MuiTooltip-tooltip': {
                                        maxWidth: 'none'
                                    }
                                }
                            }}
                        >
                            <Chip
                                label={`+${remainingCount} more`}
                                size="small"
                                variant="outlined"
                                onDelete={(e) => {
                                    e.stopPropagation();
                                    // Unassign all remaining partners
                                    remainingOrgs.forEach((org) => {
                                        handleRemovePartner(
                                            org,
                                            item.cognitoId,
                                            previewData.findIndex((prev) => prev.cognitoId === item.cognitoId)
                                        );
                                    });
                                }}
                                deleteIcon={<CancelIcon />}
                                sx={{
                                    bgcolor: 'transparent',
                                    color: 'primary.main',
                                    borderRadius: '16px',
                                    height: '24px',
                                    borderColor: 'primary.main',
                                    '& .MuiChip-label': {
                                        px: 1,
                                        fontSize: '0.75rem'
                                    },
                                    '& .MuiChip-deleteIcon': {
                                        color: 'primary.main',
                                        fontSize: '14px',
                                        marginRight: '4px',
                                        marginLeft: '-4px',
                                        '&:hover': {
                                            color: 'primary.dark'
                                        }
                                    }
                                }}
                            />
                        </Tooltip>
                    )}
                </Stack>
            );
        },
        [isLoading, handleRemovePartner, previewData]
    );
    const renderLoadingRows = useCallback(
        () =>
            Array(3)
                .fill(0)
                .map((_, index) => (
                    <TableRow key={`loading-${index}`} hover sx={{ '& td': { py: 2 } }}>
                        <TableCell>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" width={150} height={24} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1}>
                                        <Skeleton
                                            variant="rectangular"
                                            width={120}
                                            height={28}
                                            sx={{
                                                borderRadius: '24px'
                                            }}
                                        />
                                        <Skeleton
                                            variant="rectangular"
                                            width={100}
                                            height={28}
                                            sx={{
                                                borderRadius: '24px'
                                            }}
                                        />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell>
                            <Skeleton variant="text" width={120} height={24} />
                        </TableCell>
                        <TableCell>
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Chip
                                    label={
                                        <Skeleton
                                            variant="text"
                                            width={80}
                                            height={16}
                                            sx={{
                                                bgcolor: 'primary.100'
                                            }}
                                        />
                                    }
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: 'primary.100',
                                        color: 'primary.main',
                                        borderRadius: '16px',
                                        height: '24px',
                                        border: 'none',
                                        maxWidth: '150px',
                                        '& .MuiChip-label': {
                                            px: 1,
                                            py: 0.25
                                        }
                                    }}
                                />
                                <Chip
                                    label={
                                        <Skeleton
                                            variant="text"
                                            width={60}
                                            height={16}
                                            sx={{
                                                bgcolor: 'primary.100'
                                            }}
                                        />
                                    }
                                    size="small"
                                    variant="outlined"
                                    sx={{
                                        bgcolor: 'primary.100',
                                        color: 'primary.main',
                                        borderRadius: '16px',
                                        height: '24px',
                                        border: 'none',
                                        maxWidth: '150px',
                                        '& .MuiChip-label': {
                                            px: 1,
                                            py: 0.25
                                        }
                                    }}
                                />
                            </Stack>
                        </TableCell>
                    </TableRow>
                )),
        []
    );

    // Initial data fetch effect
    useEffect(() => {
        if (isCurrentOpened && payload) {
            setUnassignedPartners([]);
            setSelectedUnassignedPartners([]);
            setSelectAll(false);
            setSelectedTeams(payload.selectedTeams || []);

            const accountManagerIds = payload.selectedTeams.flatMap((team) => team.members.map((member) => member.cognitoId));

            if (accountManagerIds.length > 0) {
                fetchPreviewData(accountManagerIds, payload.organizationIds);
            } else {
                setIsLoading(false);
                setPreviewData([]);
            }
        }
    }, [isCurrentOpened, payload?.accountManagerId, fetchPreviewData, payload]);

    const checkboxStyle = {
        color: 'primary.main',
        '&.Mui-checked': {
            color: 'primary.main'
        }
    };

    const handleConfirm = () => {
        // Create a map to store all assignments
        const assignmentMap = new Map<string, string[]>();

        // First, handle the preview data assignments (excluding unassigned partners)
        previewData
            .filter((manager) => manager.suggestedOrganizations.length > 0) // Include only managers with suggested organizations
            .forEach((manager) => {
                const orgIds = manager.suggestedOrganizations
                    .filter((org) => !unassignedPartners.some((partner) => partner.id === org.id)) // Exclude unassigned partners
                    .map((org) => org.id); // Extract organization IDs

                if (orgIds.length > 0) {
                    assignmentMap.set(manager.cognitoId, orgIds); // Add to the assignment map
                }
            });

        // Handle unassigned partners if they are selected and teams are chosen
        if (selectedUnassignedPartners.length > 0 && selectedTeams.length > 0) {
            // Get all account managers from selected teams
            const selectedAccountManagers = selectedTeams.flatMap((team) =>
                team.members.map((member) => ({
                    id: member.cognitoId,
                    name: member.user?.name || ''
                }))
            );

            if (selectedAccountManagers.length > 0) {
                // Get the organization IDs from selected unassigned partners
                const unassignedOrgIds = selectedUnassignedPartners
                    .map((partnerId) => {
                        const partner = unassignedPartners.find((p) => p.id === partnerId);
                        return partner ? partner.id : null;
                    })
                    .filter((id): id is string => id !== null); // Filter out null values

                if (unassignedOrgIds.length > 0) {
                    // Distribute unassigned partners evenly among selected account managers
                    const partnersPerManager = Math.ceil(unassignedOrgIds.length / selectedAccountManagers.length);

                    selectedAccountManagers.forEach((manager, index) => {
                        const start = index * partnersPerManager;
                        const end = Math.min(start + partnersPerManager, unassignedOrgIds.length);
                        const assignedPartners = unassignedOrgIds.slice(start, end);

                        if (assignedPartners.length > 0) {
                            if (!assignmentMap.has(manager.id)) {
                                assignmentMap.set(manager.id, []);
                            }
                            assignmentMap.get(manager.id)?.push(...assignedPartners); // Assign unassigned partners
                        }
                    });
                }
            }
        }

        // Convert the map to the final assignments array
        const assignments = Array.from(assignmentMap.entries())
            .filter(([_, orgIds]) => orgIds.length > 0) // Ensure only non-empty assignments are included
            .map(([accountManagerId, organizationIds]) => ({
                accountManagerId,
                organizationIds: [...new Set(organizationIds)] // Remove any duplicates
            }));

        if (assignments.length > 0) {
            const requestDto: BulkAssignOrganizationsToAccountManagerDto = {
                assignments
            };

            setIsSubmitting(true);

            dispatch(
                bulkAssignOrganizationsToAccountManager({
                    accountManagerId: payload?.accountManagerId!,
                    data: requestDto
                })
            )
                .then(() => {
                    dispatch(
                        openSnackbar({
                            open: true,
                            message: 'Partners reassigned successfully',
                            variant: 'alert',
                            alert: {
                                color: 'success'
                            },
                            close: false
                        })
                    );
                    searchParams.set('refresh', Date.now().toString());
                    setSearchParams(searchParams);
                    setIsSubmitting(false);
                    handleClose();
                })
                .catch(() => {
                    setIsSubmitting(false);
                });
        } else {
            handleClose();
        }
    };

    return (
        <Dialog
            open={isCurrentOpened}
            onClose={handleClose}
            aria-labelledby="reassign-dialog-title"
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: {
                    height: '90vh',
                    maxHeight: '800px'
                }
            }}
        >
            <DialogTitle id="reassign-dialog-title">
                <FormattedMessage id="reassign-partners" />
            </DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100% - 64px)' }}>
                <Box sx={{ px: 3, pt: 1 }}>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
                                <Stack direction="row" alignItems="center" spacing={1} flex={1}>
                                    <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                                        <FormattedMessage id="un-assigned-partners" />
                                    </Typography>
                                    {unassignedPartners.length > 0 && (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectAll}
                                                        onChange={handleSelectAll}
                                                        size="small"
                                                        sx={checkboxStyle}
                                                    />
                                                }
                                                label={<FormattedMessage id="select-all" />}
                                            />
                                            <Button
                                                startIcon={<UndoIcon />}
                                                onClick={handleUndoAll}
                                                size="small"
                                                color="primary"
                                                sx={{
                                                    borderRadius: '20px'
                                                }}
                                            >
                                                <FormattedMessage id="undo-all" defaultMessage="Undo All" />
                                            </Button>
                                        </>
                                    )}
                                </Stack>
                                <Stack flex={1}>
                                    <AccountManagersTeamsMultiSelect
                                        ref={teamsSelectRef}
                                        disabled={selectedUnassignedPartners.length === 0}
                                        onSelectionChange={handleTeamsChange}
                                        placeholder={<FormattedMessage id="choose-teams" />}
                                    />
                                </Stack>
                            </Stack>
                            <Box
                                sx={{
                                    maxHeight: '120px',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    pr: 1,
                                    mr: -1,
                                    '&::-webkit-scrollbar': {
                                        width: '8px'
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        backgroundColor: 'transparent'
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                                        borderRadius: '4px'
                                    }
                                }}
                            >
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, pb: 2 }}>
                                    {unassignedPartners.map((partner) => (
                                        <Box key={partner.id} display="flex" alignItems="center">
                                            <Chip
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Checkbox
                                                            checked={selectedUnassignedPartners.includes(partner.id)}
                                                            size="small"
                                                            onChange={(e) => {
                                                                e.stopPropagation();
                                                                handlePartnerSelect(partner.id);
                                                            }}
                                                            onClick={(e) => e.stopPropagation()}
                                                            sx={{
                                                                padding: '0',
                                                                marginRight: 1
                                                            }}
                                                        />
                                                        <Box
                                                            component="span"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handlePartnerSelect(partner.id);
                                                            }}
                                                            sx={{ cursor: 'pointer' }}
                                                        >
                                                            {partner.name}
                                                        </Box>
                                                    </Box>
                                                }
                                                size="small"
                                                variant="outlined"
                                                sx={{
                                                    height: '32px',
                                                    borderRadius: '16px',
                                                    borderColor: 'primary.main',
                                                    bgcolor: 'transparent',
                                                    '& .MuiChip-label': {
                                                        px: 1
                                                    }
                                                }}
                                                deleteIcon={
                                                    <Tooltip title={<FormattedMessage id="undo" defaultMessage="Undo" />}>
                                                        <IconButton
                                                            size="small"
                                                            sx={{
                                                                p: 0.5,
                                                                mr: 0.5,
                                                                color: 'primary.main',
                                                                '&:hover': {
                                                                    backgroundColor: 'transparent',
                                                                    color: 'primary.dark'
                                                                }
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleUndoRemove(partner);
                                                            }}
                                                        >
                                                            <UndoIcon sx={{ fontSize: 16 }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                onDelete={(e) => {
                                                    e.stopPropagation();
                                                    handleUndoRemove(partner);
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <DialogContent sx={{ flex: 1, overflow: 'hidden', pt: 3 }}>
                    <TableContainer
                        component={Paper}
                        variant="outlined"
                        sx={{
                            height: '100%',
                            minHeight: '300px',
                            overflow: 'auto',
                            '& thead': {
                                position: 'sticky',
                                top: 0,
                                backgroundColor: 'background.paper',
                                zIndex: 1
                            },
                            '& thead th': {
                                backgroundColor: 'background.paper'
                            },
                            '& .MuiTableBody-root': {
                                minHeight: '250px'
                            }
                        }}
                    >
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ backgroundColor: 'background.paper', fontWeight: 600 }}>
                                        <FormattedMessage id="account-managers" />
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: 'background.paper', fontWeight: 600 }}>
                                        <FormattedMessage id="team-name" />
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: 'background.paper', fontWeight: 600 }}>
                                        <FormattedMessage id="reassigned-partners" />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading
                                    ? renderLoadingRows()
                                    : previewData
                                          .map((item) => ({
                                              cognitoId: item.cognitoId,
                                              name: item.name,
                                              teamName: item.teamName,
                                              currentOrganizationsCount: item.currentOrganizationsCount,
                                              suggestedOrganizations: item.suggestedOrganizations.filter(
                                                  (org) => !unassignedPartners.some((partner) => partner.id === org.id)
                                              )
                                          }))
                                          .map((item) => (
                                              <TableRow key={item.cognitoId} hover sx={{ '& td': { py: 2 } }}>
                                                  <TableCell>
                                                      <Grid container spacing={1}>
                                                          <Grid item xs={12}>
                                                              {item.name}
                                                          </Grid>
                                                          <Grid item xs={12}>
                                                              <Typography
                                                                  variant="subtitle2"
                                                                  sx={{
                                                                      bgcolor: 'primary.100',
                                                                      color: 'primary.main',
                                                                      px: 1,
                                                                      py: 0.5,
                                                                      borderRadius: '24px',
                                                                      display: 'inline-block',
                                                                      mr: 1
                                                                  }}
                                                              >
                                                                  {item.currentOrganizationsCount}{' '}
                                                                  <FormattedMessage id="assigned-partners" />
                                                              </Typography>
                                                              <Typography
                                                                  variant="subtitle2"
                                                                  sx={{
                                                                      bgcolor: 'success.100',
                                                                      color: 'success.dark',
                                                                      px: 1,
                                                                      py: 0.5,
                                                                      borderRadius: '24px',
                                                                      display: 'inline-block'
                                                                  }}
                                                              >
                                                                  {item.suggestedOrganizations.length}{' '}
                                                                  <FormattedMessage id="new-partners" />
                                                              </Typography>
                                                          </Grid>
                                                      </Grid>
                                                  </TableCell>
                                                  <TableCell>{item.teamName}</TableCell>
                                                  <TableCell>
                                                      <Stack direction="row" spacing={0.5} alignItems="center">
                                                          {renderChips(item)}
                                                      </Stack>
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="inherit">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={handleConfirm} variant="contained" color="primary" loading={isSubmitting}>
                        <FormattedMessage id="confirm" />
                    </LoadingButton>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ReassignAccountManagerOnOrganizationsDialog;
