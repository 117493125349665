import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { requestDelay } from 'store/constant';
import Filters, { FiltersInterface } from 'ui-component/Filters';
import DatePickerFilter from 'ui-component/Filters/DatePicker';
import StringSearchFilter from 'ui-component/Filters/StringSearch';
import TierSearch from 'ui-component/Filters/TiersFilter';
import NawyTable from 'ui-component/Tables/NawyTable';
import { querySystemUsers } from 'store/slices/users/actions';
import { SystemUserQueryInterface } from 'store/slices/users/interfaces';
import SystemUsersTableBody from './TableBody';
import systemUsersHeaders from './headers';
import SystemUsersGroupFilter from 'ui-component/Filters/GroupsFilter';
import OrganizationsFilter from 'ui-component/Filters/OrganizationsFilter';
import UsersPhoneFilter from 'ui-component/Filters/UsersPhoneFilter';
import SendNotificationDialog from 'components/SystemUsers/Actions/SendNotification';
import FilterChip from 'ui-component/Filters/Chips/FilterChip';

const SystemUsersListSection = () => {
    const { systemUsers, loadingSystemUsers } = useSelector((state) => state.users);
    const initialState: SystemUserQueryInterface = {
        name: '',
        phone: '',
        group: undefined,
        tierId: '',
        organizationId: ''
    };
    const location = 'System Users Table';
    const [selectAll, setSelectAll] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [filter, setFilter] = useState(initialState);
    const [resetSelection, setResetSelection] = useState(false);
    const filterData = useRef(
        debounce(async (queryData) => {
            dispatch(
                querySystemUsers({
                    queryData
                })
            );
        }, requestDelay)
    ).current;

    const handleSelectedUsers = (selectedIds: string[], isAllSelected: boolean) => {
        setSelectedUsers(selectedIds);
        setSelectAll(isAllSelected);
        setResetSelection(false);
    };

    useEffect(() => {
        filterData(filter);
        resetSelectAll();
    }, [filter, filterData]);

    const [clearAll, setClearAll] = useState(false);
    useEffect(() => {
        let flag = false;
        Object.keys(filter).forEach((filterKey) => {
            const key = filterKey as keyof typeof filter;
            if (filter[key] !== undefined && filter[key] !== '') {
                flag = true;
            }
        });
        setClearAll(flag);
    }, [filter]);
    const [chipFilters, setChipFilters] = useState({
        compoundName: '',
        developerName: '',
        accountManagerName: '',
        operationManagerName: '',
        organizationName: '',
        tierName: ''
    });
    const handleClearAll = () => {
        const newFilter = { ...filter };
        Object.keys(newFilter).forEach((filterKey) => {
            const key = filterKey as keyof typeof newFilter;
            newFilter[key] = undefined;
        });
        setFilter(newFilter);
        setChipFilters({
            compoundName: '',
            developerName: '',
            accountManagerName: '',
            operationManagerName: '',
            organizationName: '',
            tierName: ''
        });
    };
    const handleFilters = (newFilter: { [key: string]: string | number | Date | null | undefined }) => {
        setFilter({ ...filter, ...newFilter });
    };

    const resetSelectAll = () => {
        setSelectedUsers([]);
        setSelectAll(false);
        setResetSelection(true);
    };
    const intl = useIntl();
    const filters: FiltersInterface[] = [
        {
            chipView:
                filter.orderBy && filter?.orderByDirection ? (
                    <FilterChip
                        label={intl.formatMessage({ id: 'sort' })}
                        value={`${intl.formatMessage({
                            id: systemUsersHeaders.find((h) => h.id === filter?.orderBy)?.label
                        })} - ${filter?.orderByDirection}`}
                        variant="outlined"
                        handleOnClear={() => {
                            handleFilters({ orderBy: undefined, orderByDirection: undefined });
                        }}
                    />
                ) : undefined
        },
        {
            mainView: (
                <StringSearchFilter
                    location={location}
                    value={filter.name}
                    placeHolder={intl.formatMessage({ id: 'name' })}
                    handleChange={(name) => handleFilters({ name })}
                />
            ),
            chipView: filter.name ? (
                <FilterChip
                    label={`${intl.formatMessage({ id: 'name' })}`}
                    value={filter.name}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ name: undefined });
                    }}
                />
            ) : undefined
        },
        {
            mainView: (
                <UsersPhoneFilter
                    value={filter.phone}
                    label={intl.formatMessage({ id: 'phone' })}
                    handleSearch={(v) => handleFilters({ phone: v })}
                />
            ),
            chipView: filter.phone ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'phone' })}
                    value={filter.phone}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ phone: undefined });
                    }}
                />
            ) : undefined
        },
        {
            mainView: (
                <TierSearch
                    handleSelectedTier={(t) => setChipFilters({ ...chipFilters, tierName: t?.name || '' })}
                    value={filter.tierId || ''}
                    handleSearch={(v) => handleFilters({ tierId: v })}
                />
            ),
            chipView:
                filter.tierId && chipFilters.tierName ? (
                    <FilterChip
                        label={`${intl.formatMessage({ id: 'tier' })}`}
                        value={chipFilters.tierName}
                        variant="outlined"
                        handleOnClear={() => {
                            handleFilters({ tierId: undefined });
                        }}
                    />
                ) : undefined
        },
        {
            mainView: (
                <SystemUsersGroupFilter
                    value={filter.group || ''}
                    label={intl.formatMessage({ id: 'group' })}
                    handleSearch={(group) => handleFilters({ group })}
                />
            ),
            chipView: filter.group ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'group' })}
                    value={filter.group}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ group: undefined });
                    }}
                />
            ) : undefined
        },
        {
            mainView: (
                <OrganizationsFilter
                    handleSelectedOrganization={(o) => setChipFilters({ ...chipFilters, organizationName: o?.name || '' })}
                    value={filter.organizationId ?? ''}
                    handleSearch={(organizationId) => handleFilters({ organizationId })}
                />
            ),
            chipView:
                filter.organizationId && chipFilters.organizationName ? (
                    <FilterChip
                        label={intl.formatMessage({ id: 'partners' })}
                        value={chipFilters.organizationName}
                        variant="outlined"
                        handleOnClear={() => {
                            handleFilters({ organizationId: undefined });
                        }}
                    />
                ) : undefined
        },
        {
            mainView: (
                <DatePickerFilter
                    handleSearch={(createdAtMax) => handleFilters({ createdAtMax })}
                    value={filter.createdAtMax || ''}
                    label={`${intl.formatMessage({ id: 'created-to' })}`}
                />
            ),
            chipView: filter.createdAtMax ? (
                <FilterChip
                    label={`${intl.formatMessage({ id: 'created-to' })}`}
                    value={filter.createdAtMax}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ createdAtMax: undefined });
                    }}
                />
            ) : undefined
        },
        {
            mainView: (
                <DatePickerFilter
                    handleSearch={(createdAtMin) => handleFilters({ createdAtMin })}
                    value={filter.createdAtMin || ''}
                    label={`${intl.formatMessage({ id: 'created-from' })}`}
                />
            ),
            chipView: filter.createdAtMin ? (
                <FilterChip
                    label={`${intl.formatMessage({ id: 'created-from' })}`}
                    value={filter.createdAtMin}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ createdAtMin: undefined });
                    }}
                />
            ) : undefined
        },
        {
            chipView: filter.page ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'page' })}
                    value={filter?.page?.toString()}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ page: undefined });
                    }}
                />
            ) : undefined
        },
        {
            chipView: filter.pageSize ? (
                <FilterChip
                    label={intl.formatMessage({ id: 'page-size' })}
                    value={filter?.pageSize?.toString() || ''}
                    variant="outlined"
                    handleOnClear={() => {
                        handleFilters({ pageSize: undefined });
                    }}
                />
            ) : undefined
        }
    ];

    useEffect(() => {
        setResetSelection(false);
    }, [selectedUsers]);

    return (
        <NawyTable
            filter={filter}
            handleFilter={handleFilters}
            CardView={<></>}
            loading={loadingSystemUsers}
            ActionItem={<SendNotificationDialog isAllSelected={selectAll} selectedRows={selectedUsers} filter={filter} />}
            Title={<Filters filters={filters} clearAll={clearAll} handleClearAll={handleClearAll} />}
            Body={<SystemUsersTableBody />}
            headers={systemUsersHeaders}
            content={systemUsers}
            selectDisplay="name"
            selectValue="cognitoId"
            handleSelection={handleSelectedUsers}
            resetSelection={resetSelection}
        />
    );
};

export default SystemUsersListSection;
