import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import { Grid, DialogTitle, DialogContent, DialogActions, Dialog, Button, TextField, Autocomplete, FormControl, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gridSpacing } from 'store/constant';
import { openSnackbar } from 'store/slices/snackbar';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Portal, UsersInterface } from 'store/slices/notifications/interfaces';
import { createNotificationType } from 'store/slices/notifications/actions';
import DefaultUsers from '../notification/DefaultUsers';

export default function CreateNotificationTypeDialog({ fullWidth }: { fullWidth?: boolean }) {
    const intl = useIntl();

    const CreateNotificationTypeSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        portal: yup.mixed<Portal>().required(intl.formatMessage({ id: 'portal-required' })),
        requiredData: yup.array().of(yup.string()),
        defaultUsers: yup.mixed<UsersInterface>()
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [typedInRequiredData, setTypedInRequiredData] = useState('');
    const { error } = useSelector((state) => state.notificationTypes);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (!formik.isSubmitting) {
            formik.resetForm();
            setOpen(false);
            setTypedInRequiredData('');
        }
    };

    const handleAddRequiredData = () => {
        if (typedInRequiredData) {
            formik.setFieldValue('requiredData', [...formik.values.requiredData, typedInRequiredData.replace(/\s/g, '')]);
            setTypedInRequiredData('');
        }
    };

    const handleRemoveRequiredData = (variableIndex: number) => {
        const temp = [...formik.values.requiredData];
        temp.splice(variableIndex, 1);
        formik.setFieldValue('requiredData', [...temp]);
    };

    const runSuccess = (typeId: number) => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'notification-type-created-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);

        navigate(`${typeId}/details`, { replace: false });
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            portal: undefined,
            requiredData: [],
            defaultUsers: []
        },
        onSubmit(values, formikHelpers) {
            dispatch(
                createNotificationType({
                    createData: { ...values, defaultUsers: values.defaultUsers.map((user: UsersInterface) => user.cognitoId) },
                    runSuccess
                })
            );
        },
        validationSchema: CreateNotificationTypeSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            <Button fullWidth={fullWidth} variant="outlined" size="small" onClick={handleClickOpen}>
                <FormattedMessage id="create" />
            </Button>
            <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="sm" fullWidth aria-labelledby="Create-Notification-Type">
                <DialogTitle id="create-notification-type-dialog">
                    <FormattedMessage id="create-notification-type" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <Grid container py={1} spacing={gridSpacing}>
                                <Grid item xs={6}>
                                    <TextField
                                        label={`${intl.formatMessage({ id: 'name' })} *`}
                                        fullWidth
                                        type="text"
                                        name="name"
                                        id="create-notification-type-name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.name && formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        id="select-portal"
                                        fullWidth
                                        options={Object.values(Portal)}
                                        onChange={(event, targetPortal) => {
                                            formik.setFieldValue('portal', targetPortal);
                                            formik.setFieldTouched('portal', true);
                                        }}
                                        isOptionEqualToValue={(option) => option === formik.values.portal}
                                        getOptionLabel={(option) => `${option}`}
                                        onInputChange={() => formik.setFieldTouched('portal', true)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name="portal"
                                                label="Portal *"
                                                error={Boolean(formik.errors.portal && formik.touched.portal)}
                                                helperText={formik.touched.portal && formik.errors.portal}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} container alignItems="center" spacing={2} justifyContent="flex-start">
                                                <Grid item xs={10}>
                                                    <TextField
                                                        fullWidth
                                                        label={`${intl.formatMessage({ id: 'required-data' })} *`}
                                                        id="update-required-data"
                                                        name="requiredData"
                                                        onChange={(event) => {
                                                            setTypedInRequiredData(event.target.value);
                                                        }}
                                                        value={typedInRequiredData}
                                                        onBlur={formik.handleBlur}
                                                        error={Boolean(formik.touched.requiredData && formik.errors.requiredData)}
                                                        helperText={formik.touched.requiredData && formik.errors.requiredData}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Button variant="outlined" onClick={handleAddRequiredData}>
                                                        <FormattedMessage id="add" />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item container spacing={1}>
                                                {formik.values.requiredData &&
                                                    formik.values.requiredData.map((variable, i) => (
                                                        <Grid item key={variable}>
                                                            <Chip
                                                                size="small"
                                                                label={variable}
                                                                onDelete={() => handleRemoveRequiredData(i)}
                                                            />
                                                        </Grid>
                                                    ))}
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <DefaultUsers edit formik={formik} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={handleClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={formik.submitForm} loading={formik.isSubmitting} size="small" variant="contained">
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
