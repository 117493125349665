import {
    Autocomplete,
    Box,
    Button,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    InputAdornment,
    MenuItem,
    TextField,
    Theme,
    useMediaQuery
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import Accordion from 'ui-component/extended/Accordion';
import BedIcon from '@mui/icons-material/Bed';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { gridSpacing } from 'store/constant';
import { useFormik } from 'formik';
import { useSelector } from 'store';
import { InventoryQueryInterface, orderByTypes } from 'store/slices/inventoryTable/interfaces';
import PaymentsTwoToneIcon from '@mui/icons-material/PaymentsTwoTone';
import { useEffect, useState } from 'react';
import { addYears } from 'date-fns';
import { businessTypes, finishingTypes } from 'store/slices/nawyInventory/interfaces';
import { FormattedMessage, useIntl } from 'react-intl';
import NumericFormatCustom from 'ui-component/MoneyInput';

// ==============================|| Inventory FILTERS ||============================== //

const Bedrooms = ({
    min_bedrooms,
    max_bedrooms,
    handleFilter
}: {
    min_bedrooms?: number;
    max_bedrooms?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_bedrooms?: number; max_bedrooms?: number }) => {
        handleFilter('bedrooms', { min_bedrooms: values.min_bedrooms, max_bedrooms: values.max_bedrooms });
    };
    const formik = useFormik({
        initialValues: {
            min_bedrooms,
            max_bedrooms
        },
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BedIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_bedrooms ? formik.values.min_bedrooms : ''}
                    name="min_bedrooms"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'min-bedrooms' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_bedrooms && formik.errors.min_bedrooms)}
                    helperText={formik.touched.min_bedrooms && formik.errors.min_bedrooms}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BedIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_bedrooms ? formik.values.max_bedrooms : ''}
                    name="max_bedrooms"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'max-bedrooms' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_bedrooms && formik.errors.max_bedrooms)}
                    helperText={formik.touched.max_bedrooms && formik.errors.max_bedrooms}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const Price = ({
    min_price,
    max_price,
    handleFilter
}: {
    min_price?: number;
    max_price?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_price?: number; max_price?: number }) => {
        handleFilter('price', { min_price: values.min_price, max_price: values.max_price });
    };
    const formik = useFormik({
        initialValues: {
            min_price,
            max_price
        },

        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_price || ''}
                    name="min_price"
                    // type="number"
                    placeholder={intl.formatMessage({ id: 'min-price' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_price && formik.errors.min_price)}
                    helperText={formik.touched.min_price && formik.errors.min_price}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_price || ''}
                    name="max_price"
                    // type="number"
                    placeholder={intl.formatMessage({ id: 'max-price' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_price && formik.errors.max_price)}
                    helperText={formik.touched.max_price && formik.errors.max_price}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const MonthlyInstallments = ({
    min_installments,
    max_installments,
    handleFilter
}: {
    min_installments?: number;
    max_installments?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_installments?: number; max_installments?: number }) => {
        handleFilter('installments', { min_installments: values.min_installments, max_installments: values.max_installments });
    };
    const formik = useFormik({
        initialValues: {
            min_installments,
            max_installments
        },

        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_installments || ''}
                    name="min_installments"
                    // type="number"
                    placeholder={intl.formatMessage({ id: 'min-installments' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_installments && formik.errors.min_installments)}
                    helperText={formik.touched.min_installments && formik.errors.min_installments}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_installments || ''}
                    name="max_installments"
                    // type="number"
                    placeholder={intl.formatMessage({ id: 'max-installments' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_installments && formik.errors.max_installments)}
                    helperText={formik.touched.max_installments && formik.errors.max_installments}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const DownPayment = ({
    min_down_payment,
    max_down_payment,
    handleFilter
}: {
    min_down_payment?: number;
    max_down_payment?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_down_payment?: number; max_down_payment?: number }) => {
        handleFilter('down_payment', { min_down_payment: values.min_down_payment, max_down_payment: values.max_down_payment });
    };
    const formik = useFormik({
        initialValues: {
            min_down_payment,
            max_down_payment
        },

        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_down_payment || ''}
                    name="min_down_payment"
                    placeholder={`${intl.formatMessage({ id: 'min-down-payment' })}`}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_down_payment && formik.errors.min_down_payment)}
                    helperText={formik.touched.min_down_payment && formik.errors.min_down_payment}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        inputComponent: NumericFormatCustom as any,
                        startAdornment: (
                            <InputAdornment position="start">
                                <PaymentsTwoToneIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_down_payment || ''}
                    name="max_down_payment"
                    placeholder={`${intl.formatMessage({ id: 'max-down-payment' })}`}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_down_payment && formik.errors.max_down_payment)}
                    helperText={formik.touched.max_down_payment && formik.errors.max_down_payment}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const Area = ({
    min_area,
    max_area,
    handleFilter
}: {
    min_area?: number;
    max_area?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    // const dispatch = useDispatch();
    const intl = useIntl();
    const handleSubmit = (values: { min_area?: number; max_area?: number }) => {
        handleFilter('area', { min_area: values.min_area, max_area: values.max_area });
    };
    const formik = useFormik({
        initialValues: {
            min_area,
            max_area
        },
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_area ? formik.values.min_area : ''}
                    name="min_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'min-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_area && formik.errors.min_area)}
                    helperText={formik.touched.min_area && formik.errors.min_area}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_area ? formik.values.max_area : ''}
                    name="max_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'max-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_area && formik.errors.max_area)}
                    helperText={formik.touched.max_area && formik.errors.max_area}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const GardenArea = ({
    min_garden_area,
    max_garden_area,
    handleFilter
}: {
    min_garden_area?: number;
    max_garden_area?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_garden_area?: number; max_garden_area?: number }) => {
        handleFilter('garden_area', { min_garden_area: values.min_garden_area, max_garden_area: values.max_garden_area });
    };
    const formik = useFormik({
        initialValues: {
            min_garden_area,
            max_garden_area
        },
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_garden_area ? formik.values.min_garden_area : ''}
                    name="min_garden_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'min-garden-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_garden_area && formik.errors.min_garden_area)}
                    helperText={formik.touched.min_garden_area && formik.errors.min_garden_area}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_garden_area ? formik.values.max_garden_area : ''}
                    name="max_garden_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'max-garden-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_garden_area && formik.errors.max_garden_area)}
                    helperText={formik.touched.max_garden_area && formik.errors.max_garden_area}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const LandArea = ({
    min_land_area,
    max_land_area,
    handleFilter
}: {
    min_land_area?: number;
    max_land_area?: number;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSubmit = (values: { min_land_area?: number; max_land_area?: number }) => {
        handleFilter('land_area', { min_land_area: values.min_land_area, max_land_area: values.max_land_area });
    };
    const formik = useFormik({
        initialValues: {
            min_land_area,
            max_land_area
        },
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.min_land_area ? formik.values.min_land_area : ''}
                    name="min_land_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'min-land-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.min_land_area && formik.errors.min_land_area)}
                    helperText={formik.touched.min_land_area && formik.errors.min_land_area}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SquareFootIcon fontSize="small" />
                            </InputAdornment>
                        )
                    }}
                    value={formik.values.max_land_area ? formik.values.max_land_area : ''}
                    name="max_land_area"
                    type="number"
                    placeholder={intl.formatMessage({ id: 'max-land-area' })}
                    size="small"
                    fullWidth
                    onChange={formik.handleChange}
                    error={Boolean(formik.touched.max_land_area && formik.errors.max_land_area)}
                    helperText={formik.touched.max_land_area && formik.errors.max_land_area}
                />
            </Grid>

            <Grid item xs={12}>
                <Button variant="contained" fullWidth onClick={formik.submitForm}>
                    <FormattedMessage id="apply" />
                </Button>
            </Grid>
        </Grid>
    );
};

const DeliveryDate = ({
    delivery_date,
    handleFilter
}: {
    delivery_date?: string;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    type deliveryButtons = 'any' | 'now' | 'near' | 'before';
    const [selectedButton, setSelectedButton] = useState<deliveryButtons>('any');
    const { filterOptions } = useSelector((state) => state.general);
    const deliveryDates = {
        near: `${addYears(new Date(), 1).getFullYear()}-01-01`
    };

    const getDeliveryDateOptions = (): string[] => {
        const arr: string[] = [];
        if (filterOptions?.max_ready_by) {
            const date1 = new Date(filterOptions?.max_ready_by);
            const year1: number = date1.getFullYear();
            const date2 = new Date();
            const year2 = date2.getFullYear();
            return [...Array(year1 - year2 + 1).keys()].map((i) => (i + year2).toString());
        }

        return arr;
    };

    const handleSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
        const newValue = event?.target.value;
        handleFilter('delivery_date', { delivery_date: `${newValue}` });
    };

    const handleFilterButton = (value: deliveryButtons) => {
        switch (value) {
            case 'any':
                setSelectedButton(value);
                handleFilter('delivery_date', { delivery_date: undefined });
                break;
            case 'now':
                setSelectedButton(value);
                handleFilter('delivery_date', { delivery_date: `${new Date().getFullYear()}-01-01` });
                break;
            case 'near':
                setSelectedButton(value);
                handleFilter('delivery_date', { delivery_date: `${addYears(new Date(), 1).getFullYear()}-01-01` });
                break;
        }
    };

    useEffect(() => {
        if (delivery_date) {
            const date = new Date(delivery_date);
            if (date.getFullYear() === new Date().getFullYear()) {
                setSelectedButton('now');
            } else if (date.getFullYear() === new Date(deliveryDates.near).getFullYear()) {
                setSelectedButton('near');
            } else {
                setSelectedButton('before');
            }
        }
        // eslint-disable-next-line
    }, [delivery_date]);
    const intl = useIntl();
    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button variant={selectedButton === 'any' ? 'contained' : 'outlined'} onClick={() => handleFilterButton('any')}>
                        {intl.formatMessage({ id: 'any-time' })}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant={selectedButton === 'now' ? 'contained' : 'outlined'} onClick={() => handleFilterButton('now')}>
                        {intl.formatMessage({ id: 'move-now' })}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant={selectedButton === 'near' ? 'contained' : 'outlined'} onClick={() => handleFilterButton('near')}>
                        {intl.formatMessage({ id: 'near-future' })}
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button variant={selectedButton === 'before' ? 'contained' : 'outlined'} onClick={() => setSelectedButton('before')}>
                        {intl.formatMessage({ id: 'delivery-before' })}
                    </Button>
                </Grid>
            </Grid>

            {selectedButton === 'before' ? (
                <Box mt={2}>
                    <TextField
                        fullWidth
                        value={delivery_date === undefined ? '' : delivery_date}
                        onChange={handleSearch}
                        select
                        label={intl.formatMessage({ id: 'delivery-date' })}
                    >
                        <MenuItem value="" disabled>
                            Select Delivery Date
                        </MenuItem>
                        {filterOptions &&
                            getDeliveryDateOptions().map((year) => (
                                <MenuItem value={`${year}-01-01`} key={year}>
                                    {year}
                                </MenuItem>
                            ))}
                    </TextField>
                </Box>
            ) : null}
        </>
    );
};

const SearchByArea = ({
    areas_ids,
    handleFilter
}: {
    areas_ids: number[] | undefined;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const { areas } = useSelector((state) => state.general);

    const handleSearch = (areaIds: number[] | undefined) => {
        if (areaIds && areaIds.length > 0) {
            handleFilter('areas_ids', { areas_ids: areaIds });
        } else {
            handleFilter('areas_ids', { areas_ids: undefined });
        }
    };
    const intl = useIntl();
    return (
        <Autocomplete
            id="inventory-filters-area-id"
            options={areas}
            multiple
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
                handleSearch(value.map((val) => val.id));
            }}
            value={areas && areas.filter((area) => areas_ids?.includes(area.id))}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'area' })}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'area'
                    }}
                />
            )}
        />
    );
};

const SearchByPropertyType = ({
    property_types_ids,
    handleFilter
}: {
    property_types_ids: number[] | undefined;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const { propertyTypes } = useSelector((state) => state.general);

    const handleSearch = (propertyTypeIds: any) => {
        if (propertyTypeIds.length > 0) {
            handleFilter('property_types_ids', { property_types_ids: propertyTypeIds });
        } else {
            handleFilter('property_types_ids', { property_types_ids: undefined });
        }
    };
    const intl = useIntl();
    return (
        <Autocomplete
            id="inventory-filters-property-type-id"
            options={propertyTypes}
            multiple
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
                handleSearch(value.map((val) => val.id));
            }}
            value={propertyTypes && propertyTypes.filter((propertyType) => property_types_ids?.includes(propertyType.id))}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'property-type' })}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'property-type'
                    }}
                />
            )}
        />
    );
};

const SearchByCompound = ({
    compounds_ids,
    handleFilter
}: {
    compounds_ids: number[] | undefined;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const { compounds } = useSelector((state) => state.general);
    const intl = useIntl();
    const handleSearch = (compoundIds: any) => {
        if (compoundIds && compoundIds.length > 0) handleFilter('compounds_ids', { compounds_ids: compoundIds });
        else handleFilter('compounds_ids', { compounds_ids: undefined });
    };

    return (
        <Autocomplete
            id="inventory-filters-compound-id"
            options={compounds}
            multiple
            filterSelectedOptions
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
                handleSearch(value.map((val) => val.id));
            }}
            value={compounds && compounds.filter((compound) => compounds_ids?.includes(compound.id))}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'compound' })}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'compound'
                    }}
                />
            )}
        />
    );
};

const SelectFilterGeneric = ({
    selected_values,
    field,
    selectable_values,
    handleFilter
}: {
    selected_values: string[] | undefined;
    field: string;
    selectable_values: { name: string; value: string }[];
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const handleSearch = (check: boolean, status?: string) => {
        let currentStatuses: string[] | undefined = selected_values ? [...selected_values] : [];
        if (status === undefined) currentStatuses = undefined;
        else if (check) currentStatuses.push(status);
        else if (!check)
            currentStatuses.splice(
                currentStatuses.findIndex((state) => state === status),
                1
            );
        else currentStatuses = undefined;

        handleFilter(field, { [field]: currentStatuses?.length !== 0 ? currentStatuses : undefined });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControlLabel
                    control={<Checkbox checked={Boolean(selected_values === undefined)} />}
                    onChange={(event, value) => handleSearch(value)}
                    label={intl.formatMessage({ id: 'all' })}
                />
            </Grid>
            {selectable_values.map((type) => (
                <Grid item xs={6} key={type.name}>
                    <FormControlLabel
                        control={<Checkbox checked={selected_values ? selected_values?.some((item) => item === type.value) : false} />}
                        onChange={(event, value) => handleSearch(value, type.value)}
                        label={intl.formatMessage({ id: type.value })}
                        name={type.name}
                        id={type.value}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

const OrderBy = ({
    orderBy,
    handleFilter
}: {
    orderBy: string | undefined;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const handleSearch = (orderByType: string | undefined) => {
        handleFilter('orderBy', { orderBy: orderByType });
    };
    const intl = useIntl();
    return (
        <Autocomplete
            id="inventory-filters-business-type-id"
            options={orderByTypes}
            filterSelectedOptions
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, data) => {
                handleSearch(data?.value);
            }}
            value={orderBy ? orderByTypes.find((type) => type.value === orderBy) : null}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.name}>
                    {intl.formatMessage({
                        id: option.value
                    })}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'order-by' })}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'orderBy'
                    }}
                />
            )}
        />
    );
};

const SearchByDevelopers = ({
    developers_ids,
    handleFilter
}: {
    developers_ids: number[] | undefined;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
}) => {
    const intl = useIntl();
    const { developers } = useSelector((state) => state.general);
    const handleSearch = (developerIds: any) => {
        if (developerIds && developerIds.length > 0) handleFilter('developers_ids', { developers_ids: developerIds });
        else handleFilter('developers_ids', { developers_ids: undefined });
    };

    return (
        <Autocomplete
            id="inventory-filters-developer-id"
            options={developers}
            multiple
            filterSelectedOptions
            autoHighlight
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
                handleSearch(value.map((val) => val.id));
            }}
            value={developers && developers.filter((developer) => developers_ids?.includes(developer.id))}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props} key={option.id}>
                    {option.name}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={intl.formatMessage({ id: 'developer' })}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'developer'
                    }}
                />
            )}
        />
    );
};

const InventoryFilters = ({
    filter,
    handleFilter,
    handleDrawerOpen
}: {
    filter: InventoryQueryInterface;
    handleFilter: (type: string, params: InventoryQueryInterface) => void;
    handleDrawerOpen: () => void;
}) => {
    const matchDownLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('xl'));
    const intl = useIntl();
    const filterData = [
        {
            id: 'compound',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'compound' }),
            content: <SearchByCompound compounds_ids={filter.compounds_ids} handleFilter={handleFilter} />
        },
        {
            id: 'area-filter',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'area' }),
            content: <SearchByArea areas_ids={filter.areas_ids} handleFilter={handleFilter} />
        },
        {
            id: 'sale-type',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'sale-type' }),
            content: (
                <SelectFilterGeneric
                    selected_values={filter.business_types}
                    handleFilter={handleFilter}
                    field="business_types"
                    selectable_values={businessTypes}
                />
            )
        },
        {
            id: 'property_type',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'property-type' }),
            content: <SearchByPropertyType property_types_ids={filter.property_types_ids} handleFilter={handleFilter} />
        },
        {
            id: 'developers',
            defaultExpand: false,
            title: 'Developers',
            content: <SearchByDevelopers developers_ids={filter.developers_ids} handleFilter={handleFilter} />
        },
        {
            id: 'delivery-date',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'delivery-date' }),
            content: <DeliveryDate delivery_date={filter.delivery_date} handleFilter={handleFilter} />
        },
        {
            id: 'price',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'price' }),
            content: <Price min_price={filter.min_price} max_price={filter.max_price} handleFilter={handleFilter} />
        },
        {
            id: 'monthly-installments',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'monthly-installments' }),
            content: (
                <MonthlyInstallments
                    min_installments={filter.min_installments}
                    max_installments={filter.max_installments}
                    handleFilter={handleFilter}
                />
            )
        },
        {
            id: 'down-payment',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'down-payment' }),
            content: (
                <DownPayment
                    min_down_payment={filter.min_down_payment}
                    max_down_payment={filter.max_down_payment}
                    handleFilter={handleFilter}
                />
            )
        },
        {
            id: 'build-up-area',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'bua' }),
            content: <Area min_area={filter.min_area} max_area={filter.max_area} handleFilter={handleFilter} />
        },
        {
            id: 'garden-area',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'garden-area' }),
            content: (
                <GardenArea min_garden_area={filter.min_garden_area} max_garden_area={filter.max_garden_area} handleFilter={handleFilter} />
            )
        },
        {
            id: 'land-area',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'land-area' }),
            content: <LandArea min_land_area={filter.min_land_area} max_land_area={filter.max_land_area} handleFilter={handleFilter} />
        },
        {
            id: 'bedrooms',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'bedrooms' }),
            content: <Bedrooms min_bedrooms={filter.min_bedrooms} max_bedrooms={filter.max_bedrooms} handleFilter={handleFilter} />
        },
        {
            id: 'finishing',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'finishing' }),
            content: (
                <SelectFilterGeneric
                    selected_values={filter.finishing}
                    handleFilter={handleFilter}
                    field="finishing"
                    selectable_values={finishingTypes}
                />
            )
        },
        {
            id: 'order-by',
            defaultExpand: false,
            title: intl.formatMessage({ id: 'order-by' }),
            content: <OrderBy orderBy={filter.orderBy} handleFilter={handleFilter} />
        }
    ];

    return (
        <MainCard border={!matchDownLG} content={false} sx={{ overflow: 'visible' }}>
            <CardContent sx={{ p: 1, height: matchDownLG ? '100vh' : 'auto' }}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Accordion data={filterData} />
                    </Grid>
                    <Grid item xs={12} sx={{ m: 1 }}>
                        <Button sx={{ mb: 2 }} variant="contained" fullWidth color="primary" onClick={() => handleDrawerOpen()}>
                            <FormattedMessage id="done" />
                        </Button>

                        <Button variant="contained" fullWidth color="error" onClick={() => handleFilter('reset', {})}>
                            <FormattedMessage id="clear-all" />
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </MainCard>
    );
};

export default InventoryFilters;
