import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface DatePickerFilterProps {
    label: string;
    value: string;
    handleSearch: (newValue: string) => void;
}
const DatePickerFilter = ({ label, value, handleSearch }: DatePickerFilterProps) => (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
            label={label}
            value={value ? new Date(value) : null}
            onChange={(newValue) => {
                if (newValue !== null && newValue?.getDate()) handleSearch(newValue.toISOString());
                else handleSearch('');
            }}
            renderInput={(params) => <TextField size="small" name={`date-picker-filter-${label}}`} fullWidth {...params} />}
            openTo="year"
        />
    </LocalizationProvider>
);

export default DatePickerFilter;
