// assets
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import GroupsIcon from '@mui/icons-material/Groups';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone';
import SubjectIcon from '@mui/icons-material/Subject';
import ThumbDownAltTwoToneIcon from '@mui/icons-material/ThumbDownAltTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import TypeSpecimenTwoToneIcon from '@mui/icons-material/TypeSpecimenTwoTone';
import { FormattedMessage } from 'react-intl';
import { DepartmentRole, InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const tierItem: NavItemType = {
    id: 'tiers',
    title: <FormattedMessage id="tiers" />,
    type: 'item',
    url: '/settings/tiers',
    icon: CardMembershipIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Tiers'
    }
};

const invitationTypes: NavItemType = {
    id: 'types',
    title: <FormattedMessage id="types" />,
    type: 'item',
    url: '/settings/types',
    icon: TypeSpecimenTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Invitation Types'
    }
};

const accountManagerTeamItem: NavItemType = {
    id: 'teams',
    title: <FormattedMessage id="teams" />,
    type: 'item',
    url: '/settings/teams/account-managers',
    icon: GroupsIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Account Manager Teams'
    }
};

const developerPrimaryCilsConfig: NavItemType = {
    id: 'developers',
    title: <FormattedMessage id="developers" />,
    type: 'item',
    url: '/developers',
    icon: SubjectIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Developer Settings'
    }
};

const groupsItem: NavItemType = {
    id: 'groups',
    title: <FormattedMessage id="groups" />,
    type: 'item',
    url: '/settings/groups',
    icon: Diversity2Icon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Groups Settings'
    }
};

const eventItem: NavItemType = {
    id: 'events',
    title: <FormattedMessage id="events" />,
    type: 'item',
    url: '/settings/events',
    icon: TodayTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Events Settings'
    }
};

const listingRejectionReason: NavItemType = {
    id: 'rejection-reasons',
    title: <FormattedMessage id="rejection-reasons" />,
    type: 'item',
    url: '/settings/rejection-reasons',
    icon: ThumbDownAltTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.inventoryManagers, roles: [DepartmentRole.head, DepartmentRole.supervisor] }],
    mixpanelEvent: {
        tab_name: 'Listing Rejection Reasons'
    }
};

const profileSettings: NavItemType = {
    id: 'profile-settings',
    title: <FormattedMessage id="settings" />,
    type: 'item',
    url: '/users/profile/organization',
    icon: SettingsApplicationsTwoToneIcon,
    allowedAudience: [{ name: InvitationTypeEnum.brokers }],
    mixpanelEvent: {
        tab_name: 'Profile Settings'
    }
};

const managerActivitiesConfig: NavItemType = {
    id: 'activity-config',
    title: <FormattedMessage id="account-manager-actions" />,
    type: 'item',
    url: '/settings/activities',
    icon: ReceiptLongIcon,
    breadcrumbs: false
};

export const settingsItemsGroup: NavItemType = {
    id: 'settings-item-group',
    type: 'group',
    children: [listingRejectionReason, profileSettings]
};

const settingsItemsCollapse: NavItemType = {
    id: 'settings-item-group-collapse',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'settings-collapse',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: SettingsIcon,
            breadcrumbs: false,
            children: [
                tierItem,
                invitationTypes,
                accountManagerTeamItem,
                managerActivitiesConfig,
                developerPrimaryCilsConfig,
                groupsItem,
                eventItem,
                listingRejectionReason
            ]
        }
    ]
};

export default settingsItemsCollapse;
