// types
import { createSlice } from '@reduxjs/toolkit';

import { queryActivityTypes } from './actions';
import { ActivityTypesInitialState } from './interfaces';

const initialState: ActivityTypesInitialState = {
    loading: false,
    error: false,
    refresh: false,
    types: { page: 1, pageSize: 10, pages: 1, count: 1, data: [] }
};

// ==============================|| SLICE - ACTIVITY TYPES ||============================== //
const activityCategory = createSlice({
    name: 'activityType',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryActivityTypes.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryActivityTypes.fulfilled, (state, action) => {
                state.loading = false;
                state.types = action.payload;
            })
            .addCase(queryActivityTypes.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default activityCategory.reducer;
