import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { InvitationTypeEnum } from 'store/interfaces';
import { SalesAgentsInterface } from 'store/slices/salesAgents/interfaces';
import InfoItem from 'ui-component/InfoItem';
import SubCard from 'ui-component/cards/SubCard';

const CRMCardView = () => {
    const { salesAgents } = useSelector((state) => state.salesAgents);

    return (
        <Grid container spacing={gridSpacing}>
            {salesAgents.data.map((salesAgent) => (
                <Grid item xs={12} key={salesAgent.cognitoId}>
                    <Card salesAgent={salesAgent} />
                </Grid>
            ))}
        </Grid>
    );
};
const Card = ({ salesAgent }: { salesAgent: SalesAgentsInterface }) => {
    const intl = useIntl();
    const [openUserHistory, setOpenUserHistory] = useState(false);

    const handleOpen = () => {
        setOpenUserHistory(!openUserHistory);
    };

    return (
        <SubCard
            title={salesAgent.user.name}
            secondary={
                <Button color="inherit" startIcon={<EditIcon />}>
                    <FormattedMessage id="edit" />
                </Button>
            }
        >
            <Grid container rowSpacing={gridSpacing}>
                <Grid item container alignItems="center">
                    <Grid item xs={6}>
                        <InfoItem label={intl.formatMessage({ id: 'phone' })} value={salesAgent.phone || '-'} />
                    </Grid>
                    <Grid xs={6}>
                        <InfoItem
                            label={intl.formatMessage({ id: 'status' })}
                            chip={{
                                value: salesAgent.user.banned
                                    ? intl.formatMessage({ id: 'banned' })
                                    : intl.formatMessage({ id: 'active' }) || '-',
                                color: salesAgent.user.banned ? 'error' : 'success',
                                variant: 'filled'
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={6}>
                        <InfoItem
                            label={intl.formatMessage({ id: 'invited-by' })}
                            value={salesAgent?.salesAgentMoves[0]?.addedBy?.name.trim() || '-'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InfoItem label={intl.formatMessage({ id: 'group' })} value={InvitationTypeEnum.internalAgents} />
                    </Grid>
                </Grid>
                <Grid item container>
                    <Grid item xs={6}>
                        <InfoItem
                            label={intl.formatMessage({ id: 'created-at' })}
                            value={salesAgent.createdAt ? format(new Date(salesAgent.createdAt), 'dd-MM-yyyy') : '-'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InfoItem
                            label={intl.formatMessage({ id: 'updated-at' })}
                            value={salesAgent.updatedAt ? format(new Date(salesAgent.updatedAt), 'dd-MM-yyyy') : '-'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {!openUserHistory && (
                        <Button
                            onClick={handleOpen}
                            fullWidth
                            variant="outlined"
                            endIcon={openUserHistory ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        >
                            {intl.formatMessage({ id: 'user-history' })}
                        </Button>
                    )}
                    <Collapse in={openUserHistory} timeout="auto" unmountOnExit style={{ padding: 10 }}>
                        <SubCard sx={{ my: 1 }}>
                            <Typography sx={{ marginRight: 1 }} fontWeight={400}>
                                <FormattedMessage id="user-history" />:
                            </Typography>
                            {salesAgent.salesAgentMoves?.map((move) => (
                                <Typography>
                                    {format(new Date(move.createdAt), 'dd-MM-yyyy')} {intl.formatMessage({ id: 'move-to' })} {move.crm.name}{' '}
                                    {intl.formatMessage({ id: 'by' })} {move.addedBy.name}
                                </Typography>
                            ))}
                            <IconButton onClick={() => setOpenUserHistory(false)}>
                                <KeyboardArrowUpIcon color="primary" />
                            </IconButton>
                        </SubCard>
                    </Collapse>
                </Grid>
            </Grid>
        </SubCard>
    );
};
export default CRMCardView;
