// hooks
import { useDispatch, useSelector } from 'store';

// material ui
import { DialogContent, DialogContentText, Grid, TextField } from '@mui/material';

// interface
import { CreateSaleClaimSectionProps } from '.';

// constants
import { gridSpacing, saleClaimDialogHeight } from 'store/constant';

// third party libraries
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/max';
import * as yup from 'yup';

// actions
import { MuiTelInput } from 'mui-tel-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { setBuyerInfo } from 'store/slices/createSaleClaim';
import StepperDialogActions from './StepperDialogActions';
import useMixPanel from 'hooks/useMixPanel';

const BuyerInfoSection = ({ handleNext, handleBack }: CreateSaleClaimSectionProps) => {
    const dispatch = useDispatch();
    const { buyer } = useSelector((state) => state.createSaleClaimSlice);
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const SetBuyerInfoSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' }))
    });
    const formik = useFormik({
        initialValues: {
            name: buyer?.buyerName,
            phone: buyer?.buyerPhone
        },
        onSubmit(values, formikHelpers) {
            const isPhoneValid = values.phone ? parsePhoneNumber(values.phone, 'EG') : undefined;
            if (!isPhoneValid?.isValid()) {
                formikHelpers.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formikHelpers.setSubmitting(false);
                return;
            }

            dispatch(
                setBuyerInfo({
                    buyerName: values.name,
                    buyerPhone: values.phone
                })
            );
            handleNext();
        },
        validationSchema: SetBuyerInfoSchema,
        enableReinitialize: true
    });

    return (
        <div>
            <DialogContent
                sx={{
                    height: saleClaimDialogHeight
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <DialogContentText variant="h4">
                            <FormattedMessage id="buyer-info" /> :
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="string"
                            label={`${intl.formatMessage({ id: 'name-on-contract' })} *`}
                            name="name"
                            id="set-sale-buyer-info-name"
                            value={formik.values.name || ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.name && formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiTelInput
                            dir="ltr"
                            fullWidth
                            disabled={formik.isSubmitting}
                            id="invite-user-phone"
                            name="phone"
                            forceCallingCode
                            focusOnSelectCountry
                            disableFormatting
                            defaultCountry="EG"
                            excludedCountries={['IL']}
                            label={`${intl.formatMessage({ id: 'phone' })} *`}
                            value={formik.values.phone}
                            onChange={(v) => formik.setFieldValue('phone', v)}
                            onBlur={formik.handleBlur}
                            error={Boolean(formik.touched.phone && formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <StepperDialogActions
                handleBack={handleBack}
                handleNext={() => {
                    emitEvent('Click 2nd Next Sale Claim Creation', { ...formik.values, error: Boolean(formik.errors) });
                    formik.submitForm();
                }}
            />
        </div>
    );
};

export default BuyerInfoSection;
