import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import COMMISSIONS_APIS from './apis';
import { CommissionsListInterface, CreateCommissionInterface, QueryCommissionsInterface, UpdateCommissionInterface } from './interfaces';

export const createCommission = createAsyncThunk(
    'commissions/create',
    async (
        requestData: {
            createData: CreateCommissionInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(COMMISSIONS_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createBatchCommission = createAsyncThunk(
    'commissions/createBatch',
    async (
        requestData: {
            createData: { commissions: CreateCommissionInterface[]; tierId: string };
            runSuccess?: (count: number) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(COMMISSIONS_APIS.createBatch(), createData);
            if (runSuccess) runSuccess(successRes?.data?.count);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryCommission = createAsyncThunk(
    'commissions/query',
    async (
        requestData: {
            queryData: QueryCommissionsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<CommissionsListInterface | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(COMMISSIONS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateCommission = createAsyncThunk(
    'commission/update',
    async (
        requestData: {
            commissionId: number;
            updateData: UpdateCommissionInterface;
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { commissionId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(COMMISSIONS_APIS.update(commissionId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getCommissionById = createAsyncThunk(
    'commissions/getById',
    async (
        requestData: {
            commissionId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { commissionId, runSuccess } = requestData;

        try {
            const successRes = await axios.get(COMMISSIONS_APIS.getById(commissionId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteCommission = createAsyncThunk(
    'commissions/delete',
    async (
        requestData: {
            commissionId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { commissionId, runSuccess } = requestData;

        try {
            const successRes = await axios.delete(COMMISSIONS_APIS.delete(commissionId));

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
