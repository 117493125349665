import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import HISTORY_APIS from './apis';

export const getFlowHistory = createAsyncThunk(
    'flowsHistory/get-history',
    async (
        requestData: {
            flowId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { flowId, runSuccess } = requestData;
        try {
            const successRes = await axiosServices.get(HISTORY_APIS.getHistoryById(flowId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default getFlowHistory;
