import { createSlice } from '@reduxjs/toolkit';
import { createGroup, deleteGroup, getGroups, getSettings, updateSettings } from './actions';
import { SettingsInitialState } from './interfaces';

// initial state
const initialState: SettingsInitialState = {
    loading: false,
    error: false,
    groupsLoading: false,
    groupsError: false,
    groupsRefresh: false,
    groups: []
};

// ==============================|| SLICE - settings ||============================== //

const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getSettings.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.settings = action.payload;
            })
            .addCase(getSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateSettings.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.settings = action.payload;
            })
            .addCase(updateSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getGroups.pending, (state) => {
                state.groupsLoading = true;
                state.groupsError = false;
            })
            .addCase(getGroups.fulfilled, (state, action) => {
                state.groupsLoading = false;
                state.groups = action.payload;
            })
            .addCase(getGroups.rejected, (state, action) => {
                state.groupsLoading = false;
                state.groupsError = action.payload;
            })
            .addCase(createGroup.pending, (state) => {
                state.groupsError = false;
            })
            .addCase(createGroup.fulfilled, (state) => {
                state.groupsRefresh = !state.groupsRefresh;
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.groupsError = action.payload;
            })
            .addCase(deleteGroup.pending, (state) => {
                state.groupsError = false;
            })
            .addCase(deleteGroup.fulfilled, (state) => {
                state.groupsRefresh = !state.groupsRefresh;
            })
            .addCase(deleteGroup.rejected, (state, action) => {
                state.groupsError = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default settings.reducer;
