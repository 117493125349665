// material-ui
import { Stack, TableCell, TableRow, Typography } from '@mui/material';

// interfaces
import { businessTypesObject, propertyFinishing } from 'store/slices/inventoryTable/interfaces';
import { IPropertyDetails } from 'store/slices/nawyInventory/interfaces';

// third party
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import removeDecimalPoint from './helpers';

export default function DetailedPropertyRow({ detailedProperty }: { detailedProperty: IPropertyDetails }) {
    const intl = useIntl();

    const getPaymentPlans = () =>
        detailedProperty.payment_plans.map(({ installments, down_payment, years }) => {
            let res = '-';
            if (installments && down_payment && years)
                res = `${removeDecimalPoint(down_payment)}% - ${removeDecimalPoint(installments)} - ${years}`;
            return res;
        });

    return (
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell align="center">{detailedProperty.unit_id ? detailedProperty.unit_id : '-'}</TableCell>
            <TableCell align="center">{detailedProperty.unit_area ? detailedProperty.unit_area : '-'}</TableCell>
            <TableCell align="center">{detailedProperty.price ? removeDecimalPoint(detailedProperty.price) : '-'}</TableCell>
            <TableCell align="center">
                <Stack direction="column" spacing={1}>
                    {getPaymentPlans().map((paymentPlan, index) => (
                        <Typography key={`detailedProperty-${detailedProperty.unit_id}-${index}`}>{paymentPlan}</Typography>
                    ))}
                </Stack>
            </TableCell>

            <TableCell align="center">
                {detailedProperty.business_type ? intl.formatMessage({ id: businessTypesObject[detailedProperty.business_type] }) : '-'}
            </TableCell>

            <TableCell align="center">
                {detailedProperty.delivery_date ? format(new Date(detailedProperty.delivery_date), 'dd-MM-yyyy') : '-'}
            </TableCell>
            <TableCell align="center">{detailedProperty.bedrooms ? detailedProperty.bedrooms : '-'}</TableCell>
            <TableCell align="center">{detailedProperty.bathrooms ? detailedProperty.bathrooms : '-'}</TableCell>
            <TableCell align="center" sx={{ textTransform: 'capitalize' }}>
                {detailedProperty.finishing ? intl.formatMessage({ id: propertyFinishing[detailedProperty.finishing] }) : '-'}
            </TableCell>
            <TableCell align="center">{detailedProperty.garden_area ? detailedProperty.garden_area : '-'}</TableCell>
            <TableCell align="center">{detailedProperty.land_area ? detailedProperty.land_area : '-'}</TableCell>
            <TableCell align="center">
                {detailedProperty.last_update ? format(new Date(detailedProperty.last_update), 'dd-MM-yyyy') : '-'}
            </TableCell>
        </TableRow>
    );
}
