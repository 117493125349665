// assets
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';

const nawyListing: NavItemType = {
    id: 'nawy-inventory',
    title: <FormattedMessage id="nawy-inventory" />,
    type: 'item',
    url: '/nawy-inventory',
    icon: MapsHomeWorkIcon,
    breadcrumbs: false,
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.nawyInventory },
        { name: InvitationTypeEnum.internalAgents },
        { name: InvitationTypeEnum.developerRelationsManagers }
    ],
    mixpanelEvent: {
        tab_name: 'Inventory'
    }
};

const inventoryControl: NavItemType = {
    id: 'inventory-control',
    title: <FormattedMessage id="control" />,
    type: 'item',
    url: '/inventory/control',
    icon: AutoFixHighTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Inventory Control'
    }
};

export const InventoryItemsGroup = {
    id: 'nawy-inventory-side-menu-items',
    type: 'group',
    children: [nawyListing]
};

const InventoryItemsCollapse: NavItemType = {
    id: 'inventory-collapse-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'inventory-collapse',
            type: 'collapse',
            title: <FormattedMessage id="inventory" />,
            icon: MapsHomeWorkIcon,
            breadcrumbs: false,
            children: [nawyListing, inventoryControl]
        }
    ]
};

export default InventoryItemsCollapse;
