// hooks
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';

// constants
import { gridSpacing } from 'store/constant';

// icons

// action
import { sirensBatchInvitation } from 'store/slices/invitations/actions';
import { openSnackbar } from 'store/slices/snackbar';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Stack } from '@mui/system';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { FormattedMessage, useIntl } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import { read, utils } from 'xlsx';
import FileBrowse from '../../../ui-component/FileBrowse/FileBrowse';

export default function UploadInvitationsSheet({ crm, openInput }: { crm: CRMControlInterface; openInput: boolean }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.invitations);
    const [open, setOpen] = useState(openInput);
    const [users, setUsers] = useState<{ phone: string; type: InvitationTypeEnum }[]>([]);
    const [usersErrors, setUsersErrors] = useState<{ phone: string; error: string }[]>([]);
    const [next, setNext] = useState(false);

    const handleClose = () => {
        setUsersErrors([]);
        setUsers([]);
        setOpen(false);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: `${intl.formatMessage({ id: 'invitation-sent-success' })}`,
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };

    const handleBatchInvitations = () => {
        dispatch(
            sirensBatchInvitation({
                users,
                runSuccess
            })
        );
    };

    const handleSheetUpload = async (file: any) => {
        setUsersErrors([]);
        if (!file) return;
        const data = await file.arrayBuffer();
        const workbook = read(data, {
            dateNF: 'm/d/yyyy',
            cellDates: true,
            type: 'string'
        });
        if (!workbook || !workbook.Workbook || !workbook.Sheets || !workbook.SheetNames) return;

        const usersData: { phone: string }[] = utils.sheet_to_json(workbook.Sheets[workbook?.SheetNames[0]], {
            blankrows: false,
            defval: null
        });
        if (!usersData) return;
        const validUsers: { phone: string; type: InvitationTypeEnum }[] = [];
        const userSheetErrors: { phone: string; error: string }[] = [];
        const seen: { [key: string]: boolean } = {};
        let duplicatedCount = 0;

        usersData.forEach((user, i) => {
            const phone = parsePhoneNumber(`${user?.phone}`, 'EG');
            if (!seen[phone.number]) {
                if (!phone?.isValid())
                    userSheetErrors.push({
                        phone: phone.number,
                        error: `phone ${phone.number || intl.formatMessage({ id: 'not-available' })} is not valid at Location A${i + 2}`
                    });
                else userSheetErrors.push({ phone: phone.number, error: '' });
                validUsers.push({ phone: phone.number, type: InvitationTypeEnum.internalAgents });
            } else {
                // eslint-disable-next-line no-plusplus
                duplicatedCount++;
            }
            seen[phone.number] = true;
        });
        setUsersErrors(userSheetErrors);
        setUsers(validUsers);

        if (Object.keys(seen).length)
            dispatch(
                openSnackbar({
                    open: true,
                    message: `Uploaded file and removed ${duplicatedCount} duplicates phone numbers`,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
        else
            dispatch(
                openSnackbar({
                    open: true,
                    message: intl.formatMessage({ id: `document-uploaded-success` }),
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
    };

    const handleRemoveUser = (phone: string, index: number) => {
        const newUsers = users.filter((user) => user.phone !== phone);
        const newErrors = usersErrors.filter((error) => error.phone !== phone);
        setUsers(newUsers);
        setUsersErrors(newErrors);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            maxWidth="sm"
            fullWidth
            aria-labelledby="Invite User"
            aria-describedby="Invite other users to join nawy sirens"
        >
            <DialogTitle id="invite-user-dialog" fontWeight={700}>
                <FormattedMessage id="bulk-invite" />
            </DialogTitle>
            {!next ? (
                <>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item container xs={6}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" fontWeight={400}>
                                        <FormattedMessage id="CRM" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>{crm.name}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container xs={6}>
                                <Grid item xs={12}>
                                    <Typography variant="h6" fontWeight={400}>
                                        <FormattedMessage id="user-group" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormattedMessage id="internalAgent" />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" fontWeight={400}>
                                    <FormattedMessage id="update-sheet-with-numbers" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage id="max-allowed-size" />
                                </Typography>
                                <Typography>
                                    <FormattedMessage id="document-invitation-constraint" />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container py={1} spacing={gridSpacing}>
                            <Grid item xs={12}>
                                <FileBrowse id="bulk-invite" handleAddFile={(file) => handleSheetUpload(file)} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} size="small" color="secondary">
                            <FormattedMessage id="cancel" />
                        </Button>
                        <Button onClick={() => setNext(true)} size="small" color="secondary" variant="outlined">
                            <FormattedMessage id="next" />
                        </Button>
                    </DialogActions>
                </>
            ) : (
                <>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            {users.map((user, i) => (
                                <Grid item xs={12} key={user.phone}>
                                    <Stack direction="row" alignItems="center">
                                        <IconButton
                                            onClick={() => {
                                                handleRemoveUser(user.phone, i);
                                            }}
                                        >
                                            <CancelOutlinedIcon />
                                        </IconButton>
                                        <Stack direction="column">
                                            <Typography>{user.phone}</Typography>
                                            <Typography variant="h6" color="error">
                                                {usersErrors[i].error}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} size="small" color="secondary">
                            <FormattedMessage id="cancel" />
                        </Button>

                        <LoadingButton
                            onClick={handleBatchInvitations}
                            disabled={users.length < 1}
                            loading={loading}
                            size="small"
                            color="secondary"
                            variant="contained"
                        >
                            <FormattedMessage id="invite" /> {users.length}
                        </LoadingButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
