// assets
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { FormattedMessage } from 'react-intl';

import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';

const Sales: NavItemType = {
    id: 'sales',
    title: <FormattedMessage id="sales" />,
    type: 'item',
    url: '/sales',
    icon: AttachMoneyIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Click view Sales page'
    },
    allowedAudience: [
        { name: InvitationTypeEnum.admins },
        { name: InvitationTypeEnum.accountManagers },
        { name: InvitationTypeEnum.brokers },
        { name: InvitationTypeEnum.internalAgents }
    ]
};

const SalesItem: NavItemType = {
    id: 'sales',
    type: 'group',
    children: [Sales]
};
export default SalesItem;
