// third-party
import { AuthenticationDetails, CognitoRefreshToken, CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import axios from 'axios';
import cookie from 'js-cookie';
import React, { createContext, useEffect, useReducer } from 'react';

import { AWS_API } from 'config';
import accountReducer from 'store/accountReducer';
// reducer - state management
import { LOGIN, LOGOUT, PASSWORD_CONFIRMED, RESEND_CODE, VERIFICATION_CODE } from 'store/actions';
import { SIRENS_API } from 'store/apis';
import { InvitationTypeEnum } from 'store/interfaces';
import Account_MANAGERS_APIS from 'store/slices/accountManagers/apis';
import BROKERS_APIS from 'store/slices/brokers/apis';
import INVENTORY_MANAGERS_APIS from 'store/slices/inventoryManagers/apis';
import { AWSCognitoContextType, InitialLoginContextProps } from 'types/auth';
// project imports
import jwtDecode from 'jwt-decode';
import Loader from 'ui-component/Loader';
import axiosService from 'utils/axios';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

export const userPool = new CognitoUserPool({
    UserPoolId: AWS_API.poolId || '',
    ClientId: AWS_API.appClientId || ''
});

const setCookies = (accessToken: string | null, idToken?: string, refreshToken?: string) => {
    let domain: string;
    switch (process.env.REACT_APP_ENV) {
        case 'production':
            domain = 'nawy.com';
            break;
        case 'TESTING':
        case 'STAGING':
            domain = 'kafrelmatloob.com';
            break;
        default:
            domain = 'localhost';
    }
    if (accessToken && idToken && refreshToken) {
        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.accessToken`, accessToken, {
            domain,
            expires: 1,
            sameSite: 'lax',
            secure: true
        });
        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.idToken`, idToken, { domain, expires: 1, sameSite: 'lax', secure: true });
        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.refreshToken`, refreshToken, {
            domain,
            expires: 1,
            sameSite: 'lax',
            secure: true
        });
    } else {
        const options: cookie.CookieAttributes = {
            domain,
            expires: new Date(0)
        };

        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.accessToken`, '', options);
        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.idToken`, '', options);
        cookie.set(`${process.env.REACT_APP_AWS_CLIENT_ID}.refreshToken`, '', options);
    }
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axiosService.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        localStorage.removeItem('promo');
        delete axiosService.defaults.headers.common.Authorization;
    }
};

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //
const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const setUserState = async (session: CognitoUserSession) => {
        const id_token = session.getIdToken();
        const payload = id_token.payload;
        const token = session.getAccessToken().getJwtToken();

        const roles: { [key: string]: any } = {
            [`${InvitationTypeEnum.admins}`]: undefined,
            [`${InvitationTypeEnum.ambassadors}`]: undefined,
            [`${InvitationTypeEnum.accountManagers}`]: undefined,
            [`${InvitationTypeEnum.brokers}`]: undefined,
            [`${InvitationTypeEnum.internalAgents}`]: undefined,
            [`${InvitationTypeEnum.inventoryManagers}`]: undefined
        };

        if (payload['cognito:groups']?.includes(InvitationTypeEnum.accountManagers))
            roles[`${InvitationTypeEnum.accountManagers}`] = await getAccountManagerAccess(token, payload['cognito:username']);
        if (payload['cognito:groups']?.includes(InvitationTypeEnum.brokers))
            roles[`${InvitationTypeEnum.brokers}`] = await getBrokerAccess(token, payload['cognito:username']);
        if (payload['cognito:groups']?.includes(InvitationTypeEnum.inventoryManagers))
            roles[`${InvitationTypeEnum.inventoryManagers}`] = await getInventoryManagerAccess(token, payload['cognito:username']);

        setSession(session.getAccessToken().getJwtToken());
        setCookies(session.getAccessToken().getJwtToken(), session.getIdToken().getJwtToken(), session.getRefreshToken().getToken());
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user: {
                    cognitoId: payload['cognito:username'],
                    email: payload.email,
                    name: payload.name,
                    phone: payload.phone_number,
                    groups: payload['cognito:groups'],
                    accountManagerRole: roles[InvitationTypeEnum.accountManagers],
                    brokerAuth: roles[InvitationTypeEnum.brokers],
                    inventoryManagerRole: roles[InvitationTypeEnum.inventoryManagers]
                }
            }
        });
    };

    const getAccountManagerAccess = async (token: string, cognitoId: string) => {
        try {
            const successRes = await axios.get(Account_MANAGERS_APIS.getById(cognitoId), {
                headers: { content: 'application/json', Authorization: `Bearer ${token}` }
            });
            if (successRes.data.role) return successRes.data.role;
            return undefined;
        } catch (error) {
            return undefined;
        }
    };

    const getBrokerAccess = async (token: string, cognitoId: string) => {
        try {
            const successRes = await axios.get(BROKERS_APIS.getById(cognitoId), {
                headers: { content: 'application/json', Authorization: `Bearer ${token}` }
            });

            if (successRes.data.role)
                return {
                    id: successRes.data.id,
                    role: successRes.data.role,
                    organization: {
                        id: successRes.data.organization.id,
                        name: successRes.data.organization.name,
                        viewInventory: successRes.data.organization?.viewInventory,
                        cilsLimit: successRes.data.organization?.cilsLimit,
                        brokersLimit: successRes.data.organization?.brokersLimit,
                        invitationsLimit: successRes.data.organization?.invitationsLimit,
                        saleClaimsLimit: successRes.data.organization?.saleClaimsLimit,
                        reservationLimit: successRes.data.organization?.reservationLimit,
                        primaryCilsLimit: successRes.data.organization?.primaryCilsLimit,
                        leadsLimit: successRes.data.organization?.leadsLimit,
                        listingLimit: successRes.data.organization?.listingLimit,
                        tier: {
                            id: successRes.data.organization?.tier?.id,
                            name: successRes.data.organization?.tier?.name,
                            view: successRes.data.organization?.tier?.view
                        }
                    }
                };
            return undefined;
        } catch (error) {
            return undefined;
        }
    };

    const getInventoryManagerAccess = async (token: string, cognitoId: string) => {
        try {
            const successRes = await axios.get(INVENTORY_MANAGERS_APIS.getById(cognitoId), {
                headers: { content: 'application/json', Authorization: `Bearer ${token}` }
            });
            if (successRes.data.role) return successRes.data.role;
            return undefined;
        } catch (error) {
            return undefined;
        }
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceTokenCookie = cookie.get(`${process.env.REACT_APP_AWS_CLIENT_ID}.accessToken`);
                const refreshTokenCookie = cookie.get(`${process.env.REACT_APP_AWS_CLIENT_ID}.refreshToken`) || '';
                const idTokenCookie = cookie.get(`${process.env.REACT_APP_AWS_CLIENT_ID}.idToken`) || '';
                const serviceToken = serviceTokenCookie;
                if (serviceToken) {
                    const token: any = jwtDecode(serviceToken);
                    if (token) {
                        localStorage.setItem(
                            `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.${token.username}.accessToken`,
                            serviceToken
                        );
                        localStorage.setItem(
                            `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.${token.username}.refreshToken`,
                            refreshTokenCookie
                        );
                        localStorage.setItem(
                            `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.${token.username}.idToken`,
                            idTokenCookie
                        );
                        localStorage.setItem(
                            `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.LastAuthUser`,
                            token.username
                        );
                        localStorage.setItem('accessToken', serviceToken);
                    }
                    axiosService.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

                    const user = userPool.getCurrentUser();
                    if (!user) {
                        dispatch({
                            type: LOGOUT
                        });
                    } else {
                        user.getSession((error: null, session: CognitoUserSession) => {
                            if (session) {
                                setUserState(session);
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const login = async (phone: string, password: string) => {
        const usr = new CognitoUser({
            Username: phone,
            Pool: userPool
        });

        const authData = new AuthenticationDetails({
            Username: phone,
            Password: password
        });

        return new Promise<void>((resolve, reject) => {
            usr.authenticateUser(authData, {
                onSuccess: (session: CognitoUserSession) => {
                    setUserState(session);
                    resolve();
                },
                onFailure: (_err) => {
                    reject(_err);
                }
            });
        });
    };

    const register = async (phone: string, password: string, token: number, name: string, email?: string) =>
        new Promise<void>((resolve, reject) => {
            axiosService
                .post(`${SIRENS_API}users/register`, {
                    phone,
                    password,
                    token,
                    name,
                    email
                })
                .then(() => {
                    login(phone, password);
                })
                .catch((err) => reject(err));
        });

    const logout = () => {
        const loggedInUser = userPool.getCurrentUser();
        if (loggedInUser) {
            setSession(null);
            setCookies(null);
            loggedInUser.signOut();
            dispatch({ type: LOGOUT });
        }
    };

    const updateUser = async (id: string, userAttributes: any) =>
        new Promise<void>((resolve, reject) => {
            axiosService
                .patch(`${SIRENS_API}users/${id}`, userAttributes)
                .then(async (response) => {
                    await refreshUserState();
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });

    const sendVerificationCode = async (phone: string) => {
        const usr = new CognitoUser({
            Username: phone,
            Pool: userPool
        });
        return new Promise<void>((resolve, reject) => {
            usr.forgotPassword({
                onSuccess: () => {
                    dispatch({
                        type: VERIFICATION_CODE,
                        payload: {
                            isLoggedIn: false,
                            user: {
                                phone
                            }
                        }
                    });
                    resolve();
                },
                onFailure: (_err) => {
                    reject(_err);
                }
            });
        });
    };

    const resendVerificationCode = (phone: string) => {
        const usr = new CognitoUser({
            Username: phone,
            Pool: userPool
        });

        usr.resendConfirmationCode((err, result) => {
            if (result) {
                dispatch({ type: RESEND_CODE });
            }
        });
    };

    const changePassword = async (oldPassword: string, newPassword: string) =>
        new Promise<void>((resolve, reject) => {
            const user = userPool.getCurrentUser();
            if (user) {
                user.getSession((err: any, res: any) => {
                    if (res) {
                        user.changePassword(oldPassword, newPassword, (error: any, result: any) => {
                            if (error) {
                                reject(error);
                            }
                            if (result) {
                                resolve();
                            }
                        });
                    }
                });
            }
        });

    const confirmPasswordReset = (phone: string, verification_code: string, new_password: string) => {
        const usr = new CognitoUser({
            Username: phone,
            Pool: userPool
        });

        return new Promise<void>((resolve, reject) => {
            usr.confirmPassword(verification_code, new_password, {
                onSuccess: () => {
                    dispatch({
                        type: PASSWORD_CONFIRMED,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                    resolve();
                },
                onFailure: (_err) => {
                    reject(_err);
                }
            });
        });
    };

    const refreshUserState = () => {
        const user = userPool.getCurrentUser();
        const localStorageRefreshToken = `CognitoIdentityServiceProvider.${process.env.REACT_APP_AWS_CLIENT_ID}.${state.user?.cognitoId}.refreshToken`;
        const refreshToken = localStorage.getItem(localStorageRefreshToken);

        if (refreshToken && user) {
            return new Promise<CognitoUserSession>((resolve, reject) => {
                user.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, result: CognitoUserSession) => {
                    if (err) {
                        logout();
                        reject();
                    }

                    if (result) {
                        setUserState(result);
                        resolve(result);
                    }
                });
            });
        }
        throw new Error('Session Ended, Please Login Again');
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <AWSCognitoContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                sendVerificationCode,
                resendVerificationCode,
                confirmPasswordReset,
                changePassword,
                updateUser,
                refreshUserState
            }}
        >
            {children}
        </AWSCognitoContext.Provider>
    );
};

export default AWSCognitoContext;
