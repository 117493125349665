import { TableBody } from '@mui/material';
import { useSelector } from 'store';
import SystemUserRow from './TableRow';
import { ChangeEvent } from 'react';

const SystemUsersTableBody = ({
    selectValue,
    selectedRows,
    handleSelect
}: {
    selectValue?: string;
    selectedRows?: {
        [key: string]: any;
    }[];
    handleSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
    const { systemUsers } = useSelector((state) => state.users);
    return (
        <TableBody>
            {systemUsers?.data.map((systemUser) => (
                <SystemUserRow
                    handleSelect={handleSelect}
                    checked={selectedRows?.findIndex((row) => row[selectValue!] === systemUser.cognitoId) !== -1}
                    selectValue={selectValue}
                    key={systemUser.id}
                    systemUser={systemUser}
                />
            ))}
        </TableBody>
    );
};

export default SystemUsersTableBody;
