import { ForwardRefExoticComponent, RefAttributes, forwardRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { activeItem, openDrawer } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// types
import { LinkTarget, NavItemType } from 'types';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import TextWithNewBadge from 'ui-component/TextWithNewBadge';

interface NavItemProps {
    item: NavItemType;
    level: number;
}

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }: NavItemProps) => {
    const theme = useTheme();
    const { pathname } = useLocation();
    const { user } = useAuth();
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const userName = user?.name;
    const userPhone = user?.phone;
    const userCognitoId = user?.cognitoId;

    const messageTemplate = `Hello, ${userName} with phone number ${userPhone} reporting an issue on Partners`;

    const supportNumber = process.env.REACT_APP_WHATSAPP_SUPPORT_NUMBER;

    const whatsappUrl = `https://wa.me/${supportNumber}?text=${encodeURIComponent(messageTemplate)}`;
    const leaderboardUrl = `https://lookerstudio.google.com/embed/reporting/9b99b623-7785-4988-ae89-9c52f132d77f/page/p_smippe0ohd?params={"manager_cognito_id":${userCognitoId}}`;

    const { borderRadius } = useConfig();
    const dispatch = useDispatch();
    const { selectedItem } = useSelector((state) => state.menu);

    const Icon = item?.icon!;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="20px" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: selectedItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget: LinkTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let targetUrl = item.url;
    if (item.url?.split('/').includes('current-user-organization')) {
        targetUrl = `/organizations/${user?.brokerAuth?.organization.id}/${item.url?.split('/')[1]}`;
    }
    let listItemProps: {
        component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
        href?: string;
        target?: LinkTarget;
    } = { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={targetUrl!} target={itemTarget} />) };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }
    const { emitEvent } = useMixPanel();
    const itemHandler = () => {
        dispatch(activeItem([{ id: item.id }]));
        if (item.id === 'whatsapp-support') {
            window.open(whatsappUrl, '_blank');
        } else if (item.id === 'leaderboard-external') {
            window.open(leaderboardUrl, '_blank');
        }
        if (item.mixpanelEvent) emitEvent('Click Tab', { ...item.mixpanelEvent });
        matchesSM && dispatch(openDrawer(false));
    };
    // active menu item on page load
    useEffect(() => {
        if (pathname === item.url) {
            dispatch(activeItem([item.id]));
        }
        // eslint-disable-next-line
    }, [pathname]);

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${borderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={selectedItem?.findIndex((id) => id === item.id) > -1}
            onClick={itemHandler}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    item.id === 'whatsapp-support' ? (
                        <TextWithNewBadge
                            variant={selectedItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                            title={item.title}
                        />
                    ) : (
                        <Typography variant={selectedItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
                            {item.title}
                        </Typography>
                    )
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
