/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TextField, Typography, useMediaQuery, useTheme, FormControlLabel, Radio, RadioGroup, FormControl } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

// third party
import * as Yup from 'yup';

// assets
import { useFormik } from 'formik';
import { MuiTelInput, MuiTelInputCountry } from 'mui-tel-input';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import { resetUserState } from 'store/slices/invitations';
import { checkUser } from 'store/slices/invitations/actions';
import useMixPanel from 'hooks/useMixPanel';
import PagesEnum from '../Utils/pagesEnum';
import { LoadingButton } from '@mui/lab';
import BecomePartnerCardDialogV2 from '../UI/BecomePartnerCardDialogV2';
import UserCrmFormInfo from '../Utils/UserCrmFormInfo';
import { createPartnerAsLeadV2 } from 'store/slices/leads/actions';

interface BecomePartnerCardProps {
    setUserInfo: (userInfo: UserCrmFormInfo) => void;
    setCurrentPage: (page: PagesEnum) => void;
    mixPanelEventName: string;
}

interface UTMQueryParam {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    fbclid?: string;
    utm_adset?: string;
    utm_adterm?: string;
    utm_adgroup?: string;
    utm_content?: string;
}

const BecomePartnerCardV2: React.FC<BecomePartnerCardProps> = ({ setUserInfo, setCurrentPage, mixPanelEventName }) => {
    const theme = useTheme();
    const { emitEvent } = useMixPanel();
    const dispatch = useDispatch();
    const { error: invitationsError } = useSelector((state) => state.invitations);
    const { error: leadsError } = useSelector((state) => state.leads);
    const [searchParams] = useSearchParams();
    const [, setErrorMassage] = useState('');
    const [, setIsNotAvailable] = useState(false);
    const [isInvited, setInvited] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const queryParams = Object.fromEntries(searchParams.entries()) as UTMQueryParam;

    const [userPhoneNumber, setUserPhoneNumber] = useState('');
    const intl = useIntl();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const resetComponent = () => {
        formik.resetForm();
        dispatch(resetUserState());
        setDialogOpen(false);
        setIsNotAvailable(false);
        setInvited(false);
        setUserPhoneNumber('');
        setSuccess(false);
    };

    const handleDialogClose = () => {
        resetComponent();
        setCurrentPage(PagesEnum.LOGIN);
    };

    const handleOnGoToActivate = () => {
        dispatch(resetUserState());
        setUserInfo({
            fullName: formik.values.fullName,
            phone: formik.values.phone,
            companyName: formik.values.companyName
        });
        setCurrentPage(PagesEnum.ACTIVATE);
    };

    const signUpSchema = Yup.object().shape({
        phone: Yup.number()
            .typeError(intl.formatMessage({ id: 'invalid-phone' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        fullName: Yup.string()
            .max(255)
            .required(intl.formatMessage({ id: 'full-name-required' })),
        companyName: Yup.string()
            .max(255)
            .required(intl.formatMessage({ id: 'company-name-required' })),
        isWorkRegister: Yup.string().required(intl.formatMessage({ id: 'radio-group-required' }))
    });

    const initialValues = {
        fullName: '',
        phone: searchParams.get('phone') || '',
        companyName: '',
        email: '',
        submit: null,
        agreed: false,
        countryCode: (searchParams.get('countryCode') as MuiTelInputCountry) || 'EG',
        methodOfContact: '',
        isWorkRegister: '',
        isCommercialRegister: ''
    };

    const formik = useFormik({
        initialValues,
        validateOnChange: true,

        onSubmit: async (values, formikHelpers) => {
            try {
                setErrorMassage('');
                setSuccess(false);
                formikHelpers.setSubmitting(true);

                if (values.isWorkRegister === 'yes') {
                    values.methodOfContact = 'Partners Website';
                } else if (values.isWorkRegister === 'no') {
                    values.methodOfContact = 'Partners website low priority';
                }

                const userStateUpdated = await dispatch(
                    checkUser({
                        queryData: { phone: `${values.phone.replaceAll(' ', '')}` }
                    })
                ).unwrap();

                setUserPhoneNumber(values.phone.replaceAll('+', ''));

                if (userStateUpdated === 'registered') {
                    setInvited(true);
                    setDialogOpen(true);
                } else if (userStateUpdated === 'invited') {
                    setInvited(true);
                    setDialogOpen(true);
                    handleOnGoToActivate();
                } else if (userStateUpdated === 'not available') {
                    emitEvent(mixPanelEventName, { 'Phone Number': values.phone, 'Organization name': values.companyName });
                    await dispatch(
                        createPartnerAsLeadV2({
                            leadData: {
                                phone: `+${values.phone.replaceAll(' ', '').replaceAll('+', '')}`,
                                fullName: values.fullName,
                                company: values.companyName,
                                email: values.email,
                                methodOfContact: values.methodOfContact,
                                commercialRegister: values.isCommercialRegister,
                                workRegister: values.isWorkRegister,
                                campaign: queryParams.utm_campaign,
                                utm_source: queryParams.utm_source,
                                utm_adset: queryParams.utm_adset,
                                utm_adterm: queryParams.utm_adterm,
                                utm_adgroup: queryParams.utm_adgroup,
                                utm_content: queryParams.utm_content,
                                utm_medium: queryParams.utm_medium
                            },
                            runSuccess: () => {
                                setInvited(false);
                                setSuccess(true);
                                setDialogOpen(true);
                                formikHelpers.setSubmitting(false);
                            }
                        })
                    ).unwrap();
                }
            } catch (error) {
                formikHelpers.setSubmitting(false);
            }
        },
        validationSchema: signUpSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (leadsError?.validationErrors) formik.setErrors(leadsError?.validationErrors);
        if (invitationsError?.validationErrors) formik.setErrors(invitationsError?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invitationsError, leadsError]);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid container spacing={2}>
                <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                    <Grid item md={12}>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="black">
                            <FormattedMessage id="Do-you-work-in-real-estate" />
                        </Typography>
                    </Grid>

                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <RadioGroup
                                sx={{ display: 'flex', flexDirection: 'row' }}
                                style={{ color: 'black' }}
                                aria-label="is-work-register-radio"
                                name="isWorkRegister"
                                value={formik.values.isWorkRegister}
                                onChange={formik.handleChange}
                            >
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio />}
                                    label={intl.formatMessage({ id: 'yes' })}
                                    sx={{ label: { color: 'black' } }}
                                />
                                <FormControlLabel value="no" color="black" control={<Radio />} label={intl.formatMessage({ id: 'no' })} />
                            </RadioGroup>
                            <Typography
                                color="error"
                                sx={{ fontSize: '10px', height: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                                {formik.errors.isWorkRegister}
                            </Typography>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container item xs={12} spacing={isSmallScreen ? 3 : 8}>
                    <Grid item container xs={12} spacing={isSmallScreen ? 2 : 2}>
                        <Grid container item xs={12} columnSpacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={`${intl.formatMessage({ id: 'full-name' })}`}
                                    name="fullName"
                                    id="register-user-full-name"
                                    type="text"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.fullName}
                                    onChange={formik.handleChange}
                                    error={Boolean(formik.touched.fullName && formik.errors.fullName)}
                                    helperText={formik.touched.fullName && formik.errors.fullName}
                                    FormHelperTextProps={{
                                        sx: { margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '10px' }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiTelInput
                                fullWidth
                                id="invite-partner-phone"
                                name="phone"
                                forceCallingCode
                                focusOnSelectCountry
                                disabled={formik.isSubmitting}
                                disableFormatting
                                defaultCountry={formik.values.countryCode}
                                excludedCountries={['IL']}
                                label={`${intl.formatMessage({ id: 'phone' })}`}
                                value={formik.values.phone}
                                onChange={(v) => formik.setFieldValue('phone', v)}
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                FormHelperTextProps={{ sx: { margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '10px' } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={`${intl.formatMessage({ id: 'company-name' })}`}
                                name="companyName"
                                id="register-user-company-name"
                                type="text"
                                disabled={formik.isSubmitting}
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.companyName && formik.errors.companyName)}
                                helperText={formik.touched.companyName && formik.errors.companyName}
                                FormHelperTextProps={{ sx: { margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '10px' } }}
                            />
                        </Grid>
                        <Grid container item xs={12} justifyContent="flex-start" alignItems="center">
                            <Grid item md={12}>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black">
                                    <FormattedMessage id="form-question2" />
                                </Typography>
                            </Grid>

                            <Grid item md={12}>
                                <FormControl fullWidth>
                                    <RadioGroup
                                        sx={{ display: 'flex', flexDirection: 'row' }}
                                        style={{ color: 'black' }}
                                        aria-label="is-commercial-register-radio"
                                        name="isCommercialRegister"
                                        value={formik.values.isCommercialRegister}
                                        onChange={formik.handleChange}
                                    >
                                        <FormControlLabel
                                            value="yes"
                                            control={<Radio />}
                                            label={intl.formatMessage({ id: 'yes' })}
                                            sx={{ label: { color: 'black' } }}
                                        />
                                        <FormControlLabel
                                            value="no"
                                            color="black"
                                            control={<Radio />}
                                            label={intl.formatMessage({ id: 'no' })}
                                        />
                                    </RadioGroup>
                                    <Typography
                                        color="error"
                                        sx={{ fontSize: '10px', height: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                    >
                                        {formik.errors.isCommercialRegister}
                                    </Typography>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={`${intl.formatMessage({ id: 'email-optional' })}`}
                                name="email"
                                id="register-user-email"
                                type="text"
                                disabled={formik.isSubmitting}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                size="large"
                                loading={formik.isSubmitting}
                                onClick={formik.submitForm}
                                fullWidth
                                sx={{ height: '48px', width: isSmallScreen ? '100%' : '100%' }}
                            >
                                <FormattedMessage id="submit-form" />
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <BecomePartnerCardDialogV2
                isDialogOpen={dialogOpen}
                handleDialogClose={handleDialogClose}
                isInvited={isInvited}
                success={success}
                setisDialogOpen={setDialogOpen}
                userPhoneNumber={userPhoneNumber}
            />
        </Grid>
    );
};

export default BecomePartnerCardV2;
