import { Card, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import { gridSpacing, gridSpacingSmall } from 'store/constant';
import { ReactNode } from 'react';

export interface DashboardWidgetInterface {
    label: string;
    money?: number;
    upperTotal?: number;
    loading?: boolean;
    icon?: ReactNode;
    isContrastText?: boolean;
    title?: string;
}

const DashboardWidgetCard = ({ title, loading, label, money, upperTotal = 0, icon, isContrastText = false }: DashboardWidgetInterface) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container justifyContent="start" alignItems="center" spacing={gridSpacing} wrap="nowrap" p={0}>
            {!!icon && !matchDownSM && !matchDownMD && (
                <Grid item>
                    <Card style={{ background: theme.palette.primary.light, padding: 8 }}>{icon}</Card>
                </Grid>
            )}

            <Grid item container xs={icon && !matchDownSM && !matchDownMD ? 8 : 12} spacing={gridSpacingSmall}>
                {!!title && matchDownMD && (
                    <Grid item xs={12}>
                        <Typography variant="body1" color={theme.palette.grey[800]}>
                            {title}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} container wrap="nowrap">
                    <Grid item>
                        {loading ? (
                            <Skeleton sx={{ width: '20px', height: '20px' }} />
                        ) : (
                            <Typography
                                variant={matchDownSM ? 'h6' : 'h4'}
                                fontWeight={700}
                                color={isContrastText ? theme.palette.primary.contrastText : theme.palette.primary[800]}
                            >
                                {upperTotal || 0}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item px={upperTotal !== null && upperTotal !== undefined ? 0.5 : 0}>
                        <Typography
                            noWrap
                            variant={matchDownSM ? 'h6' : 'h4'}
                            color={isContrastText ? theme.palette.primary.contrastText : theme.palette.primary[800]}
                            fontWeight={400}
                        >
                            {label}
                        </Typography>
                    </Grid>
                </Grid>

                <Grid item xs={12} container spacing={gridSpacingSmall}>
                    <Grid item>
                        <Typography
                            variant={matchDownSM ? 'h6' : 'h2'}
                            fontWeight={700}
                            color={isContrastText ? theme.palette.primary.contrastText : theme.palette.primary[900]}
                        >
                            <FormattedMessage id="egp" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        {loading ? (
                            <Skeleton sx={{ minWidth: '50px' }} />
                        ) : (
                            <Typography
                                variant={matchDownSM ? 'h5' : 'h2'}
                                fontWeight={700}
                                color={isContrastText ? theme.palette.primary.contrastText : theme.palette.primary[900]}
                            >
                                {money ? Intl.NumberFormat('en', { notation: 'compact' }).format(money) : 0}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DashboardWidgetCard;
