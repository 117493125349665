import { createSlice } from '@reduxjs/toolkit';
import {
    createComment,
    createFlowEOI,
    createFlowPrimaryCil,
    createFlowReservation,
    createFlowSaleClaim,
    deleteFlow,
    deleteFlowComment,
    deleteFlowDocument,
    getFlowAnalyticaEOI,
    getFlowAnalyticaReservation,
    getFlowAnalyticaSaleClaim,
    getFlowById,
    queryFlows,
    queryPrimaryCilsFlows,
    updateComment,
    updateFlow,
    updateFlowStatus,
    uploadFlowDocument
} from './actions';
import { FlowsInitialStateInterface } from './interfaces';

// initial state
const initialState: FlowsInitialStateInterface = {
    loading: false,
    error: false,
    listError: false,
    listLoading: false,
    refresh: false,
    listRefresh: false,
    analyticaLoading: false,
    analyticaError: false,
    flows: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    primaryCILs: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    }
};

// ==============================|| SLICE - FLOWS ||============================== //

const flows = createSlice({
    name: 'flows',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryFlows.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryFlows.fulfilled, (state, action) => {
                state.listLoading = false;
                state.flows = action.payload;
            })
            .addCase(queryFlows.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(queryPrimaryCilsFlows.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryPrimaryCilsFlows.fulfilled, (state, action) => {
                state.listLoading = false;
                state.primaryCILs = action.payload;
            })
            .addCase(queryPrimaryCilsFlows.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getFlowAnalyticaEOI.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getFlowAnalyticaEOI.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaEOI = action.payload;
            })
            .addCase(getFlowAnalyticaEOI.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addCase(getFlowAnalyticaReservation.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getFlowAnalyticaReservation.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaReservation = action.payload;
            })
            .addCase(getFlowAnalyticaReservation.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addCase(getFlowAnalyticaSaleClaim.pending, (state) => {
                state.analyticaLoading = true;
                state.analyticaError = false;
            })
            .addCase(getFlowAnalyticaSaleClaim.fulfilled, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaSaleClaim = action.payload;
            })
            .addCase(getFlowAnalyticaSaleClaim.rejected, (state, action) => {
                state.analyticaLoading = false;
                state.analyticaError = action.payload;
            })
            .addCase(updateFlowStatus.pending, (state) => {
                state.error = false;
            })
            .addCase(updateFlowStatus.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateFlowStatus.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(createFlowPrimaryCil.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createFlowPrimaryCil.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createFlowPrimaryCil.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createFlowEOI.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createFlowEOI.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createFlowEOI.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createFlowReservation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createFlowReservation.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createFlowReservation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createFlowSaleClaim.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createFlowSaleClaim.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createFlowSaleClaim.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateFlow.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateFlow.fulfilled, (state, action) => {
                state.loading = false;
                state.flow = action.payload;
            })
            .addCase(updateFlow.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getFlowById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getFlowById.fulfilled, (state, action) => {
                state.loading = false;
                state.flow = action.payload;
            })
            .addCase(getFlowById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteFlow.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteFlow.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteFlow.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteFlowComment.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteFlowComment.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteFlowComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createComment.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createComment.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateComment.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateComment.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateComment.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(uploadFlowDocument.pending, (state) => {
                state.error = false;
            })
            .addCase(uploadFlowDocument.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(uploadFlowDocument.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteFlowDocument.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteFlowDocument.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteFlowDocument.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default flows.reducer;

// export const {  } = flows.actions;
