// material ui
import { DialogContent, DialogContentText, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';

// material ui dates
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// third party libraries
import * as yup from 'yup';

// hooks
import { dispatch, useSelector } from 'store';

// constants
import { gridSpacing, saleClaimDialogHeight } from 'store/constant';

// actions
import { setBuyerInfo, setPropertyInfo, setSaleInfo } from 'store/slices/createSaleClaim';

// interfaces
import { SaleType } from 'store/slices/saleClaims/interfaces';

import NumericFormatCustom from 'ui-component/MoneyInput';
import { CreateSaleClaimSectionProps } from '.';
import StepperDialogActions from './StepperDialogActions';
import { FlowStagesEnum } from 'store/slices/flows/interfaces';

const MainInfoSection = ({ handleNext, handleBack, handleClose, flow }: CreateSaleClaimSectionProps) => {
    const { saleInfo, error } = useSelector((state) => state.createSaleClaimSlice);

    const intl = useIntl();
    const SetMainInfoSchema = yup.object().shape({
        type: yup.mixed<SaleType>().required(intl.formatMessage({ id: 'sale-type-required' })),
        contractionDate: yup.string().required(intl.formatMessage({ id: 'contraction-date-required' })),
        downPayment: yup
            .number()
            .integer(intl.formatMessage({ id: 'integer-number-required' }))
            .required(intl.formatMessage({ id: 'down-payment-required' }))
            .min(1, intl.formatMessage({ id: 'must-be-more-than-zero' })),
        downPaymentDate: yup.string().required(intl.formatMessage({ id: 'down-payment-date-required' })),
        reservationDate: yup.string().required(intl.formatMessage({ id: 'reservation-date-required' })),
        installmentsYears: yup
            .number()
            .integer(intl.formatMessage({ id: 'integer-number-required' }))
            .required(intl.formatMessage({ id: 'installment-years-required' }))
            .min(1, intl.formatMessage({ id: 'must-be-more-than-zero' }))
    });
    const formik = useFormik({
        initialValues: {
            type: saleInfo?.type ?? flow?.saleType,
            contractionDate: saleInfo?.contractionDate ?? flow?.contractionDate,
            downPaymentDate: saleInfo?.downPaymentDate ?? flow?.downPaymentDate,
            downPayment: saleInfo?.downPayment ?? flow?.downPayment,
            reservationDate:
                saleInfo?.reservationDate ??
                flow?.reservationDate ??
                flow?.stages.find((stage) => stage.value === FlowStagesEnum.Reservation)?.actualDate ??
                flow?.stages.find((stage) => stage.value === FlowStagesEnum.Reservation)?.createdAt,
            installmentsYears: saleInfo?.installmentsYears ?? (flow?.monthsOfInstallment ? flow?.monthsOfInstallment / 12 : undefined)
        },
        onSubmit: (values, formikHelpers) => {
            const { contractionDate, downPayment, type, downPaymentDate, reservationDate, installmentsYears, ...rest } = values;
            if (contractionDate && downPayment && type && downPaymentDate && reservationDate && installmentsYears) {
                dispatch(
                    setSaleInfo({
                        downPayment: parseInt(downPayment.toString(), 10),
                        contractionDate,
                        type,
                        downPaymentDate,
                        reservationDate: reservationDate?.toLocaleString(),
                        installmentsYears,
                        ...rest
                    })
                );
                dispatch(
                    setBuyerInfo({
                        buyerName: flow?.lead.name ?? '',
                        buyerPhone: flow?.lead.phoneNumber ?? ''
                    })
                );

                dispatch(
                    setPropertyInfo({
                        developerName: flow?.compound.developer.name ?? '',
                        compoundName: flow?.compound.name ?? '',
                        compoundId: flow?.compoundId,
                        developer: flow?.compound.developer
                            ? { id: flow?.compound.developer.id, name: flow?.compound.developer.name }
                            : undefined,
                        compound: flow?.compound ? { id: flow?.compound.id, name: flow?.compound.name } : undefined,
                        propertyTypeId: flow?.propertyTypeId,
                        unitValue: flow?.unitValue,
                        unitId: flow?.unitId
                    })
                );
            }
            handleNext();
        },
        validationSchema: SetMainInfoSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <DialogContent
                    sx={{
                        height: saleClaimDialogHeight
                    }}
                >
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="main-info" /> :
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                select
                                label={`${intl.formatMessage({ id: 'type' })} *`}
                                name="type"
                                id="set-sale-main-info-type"
                                value={formik.values.type || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.type && formik.errors.type)}
                                helperText={formik.touched.type && formik.errors.type}
                            >
                                <MenuItem value="" disabled>
                                    <FormattedMessage id="select-type" />
                                </MenuItem>
                                <MenuItem value={SaleType.primary}>{SaleType.primary}</MenuItem>
                                <MenuItem value={SaleType.resale}>{SaleType.resale}</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={`${intl.formatMessage({ id: 'down-payment' })} *`}
                                name="downPayment"
                                InputProps={{
                                    inputComponent: NumericFormatCustom as any,
                                    endAdornment: <InputAdornment position="end">EGP</InputAdornment>
                                }}
                                id="set-sale-main-info-downPayment"
                                value={formik.values.downPayment || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.downPayment && formik.errors.downPayment)}
                                helperText={formik.touched.downPayment && formik.errors.downPayment}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={`${intl.formatMessage({ id: 'down-payment-date' })} *`}
                                    value={formik.values.downPaymentDate ? new Date(formik.values.downPaymentDate) : null}
                                    onChange={(newDate) => {
                                        if (newDate && !isNaN(newDate.getTime()))
                                            formik.setFieldValue('downPaymentDate', newDate.toISOString());
                                        else formik.setFieldValue('downPaymentDate', '');
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="downPaymentDate"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.downPaymentDate && formik.errors.downPaymentDate)}
                                            helperText={formik.touched.downPaymentDate && formik.errors.downPaymentDate}
                                            fullWidth
                                        />
                                    )}
                                    openTo="day"
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={`${intl.formatMessage({ id: 'reservation-date' })} *`}
                                    value={formik.values.reservationDate ? new Date(formik.values.reservationDate) : null}
                                    onChange={(newDate) => {
                                        if (newDate && !isNaN(newDate.getTime()))
                                            formik.setFieldValue('reservationDate', newDate.toISOString());
                                        else formik.setFieldValue('reservationDate', '');
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="reservationDate"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.reservationDate && formik.errors.reservationDate)}
                                            helperText={formik.touched.reservationDate && formik.errors.reservationDate}
                                            fullWidth
                                        />
                                    )}
                                    openTo="day"
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={`${intl.formatMessage({ id: 'contraction-date' })} *`}
                                    value={formik.values.contractionDate ? new Date(formik.values.contractionDate) : null}
                                    onChange={(newDate) => {
                                        if (newDate && !isNaN(newDate.getTime()))
                                            formik.setFieldValue('contractionDate', newDate.toISOString());
                                        else formik.setFieldValue('contractionDate', '');
                                    }}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="contractionDate"
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.contractionDate && formik.errors.contractionDate)}
                                            helperText={formik.touched.contractionDate && formik.errors.contractionDate}
                                            fullWidth
                                        />
                                    )}
                                    openTo="day"
                                />
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                type="number"
                                InputProps={{ endAdornment: <InputAdornment position="end">Years</InputAdornment> }}
                                label={`${intl.formatMessage({ id: 'installment-years' })} *`}
                                name="installmentsYears"
                                id="set-sale-main-info-installmentsYears"
                                value={formik.values.installmentsYears || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.installmentsYears && formik.errors.installmentsYears)}
                                helperText={formik.touched.installmentsYears && formik.errors.installmentsYears}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Grid>
            <Grid item xs={12} pr={2}>
                <StepperDialogActions handleClose={handleClose} handleBack={handleBack} handleNext={formik.submitForm} />
            </Grid>
        </Grid>
    );
};

export default MainInfoSection;
