import { FAUNA_API, queryData } from 'store/apis';

import { QuerySalesInterface } from './interfaces';

const SALES_PORTAL_API = {
    /**
     * @GET /sales
     * @returns SaleEntity
     */
    query: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    querySalesSummary: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/summary', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    queryTopDevelopers: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/top-developers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    queryTopCompounds: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/top-compounds', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    queryTopPropertyTypes: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/top-property-types', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    queryTopAgents: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/top-agents', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    queryTopOrganizations: (query: QuerySalesInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sales/top-organizations', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    addInvoiceId: () => `${FAUNA_API}sales/add-invoice`
};

export default SALES_PORTAL_API;
