// types
import { createSlice } from '@reduxjs/toolkit';
import {
    createOperationManagerFromExistingUser,
    getOperationManagerById,
    queryOperationManagers,
    updateOperationManagerRole
} from './actions';
import { OperationManagersInitialState } from './interfaces';

// initial state
const initialState: OperationManagersInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    refresh: false,
    listRefresh: false,
    operationManagers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - OPERATION MANAGERS ||============================== //

const operationManagers = createSlice({
    name: 'operationManagers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryOperationManagers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryOperationManagers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.operationManagers = action.payload;
            })
            .addCase(queryOperationManagers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(getOperationManagerById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getOperationManagerById.fulfilled, (state, action) => {
                state.loading = false;
                state.operationManager = action.payload;
            })
            .addCase(getOperationManagerById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createOperationManagerFromExistingUser.pending, (state) => {
                state.error = false;
            })
            .addCase(createOperationManagerFromExistingUser.fulfilled, (state, action) => {
                state.operationManager = action.payload;
            })
            .addCase(createOperationManagerFromExistingUser.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateOperationManagerRole.pending, (state) => {
                state.error = false;
            })
            .addCase(updateOperationManagerRole.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateOperationManagerRole.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default operationManagers.reducer;
