// hooks
import { useDispatch, useSelector } from 'store';
import { useTheme } from '@mui/material/styles';

// material-ui
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Button } from '@mui/material';

// components
import SubCard from 'ui-component/cards/SubCard';
import SkeletonTotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';
import NoDataFound from 'ui-component/NoDataFound';
import DetailedPropertyRow from './DetailedPropertyRow';

// interface
import { IPropertyDetails } from 'store/slices/nawyInventory/interfaces';

// constants
import { gridSpacing } from 'store/constant';
import { detailedPropertyHeaders } from './InventoryHeaders';

// icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { openSnackbar } from 'store/slices/snackbar';
import { WEBSITE_FRONTEND } from 'store/apis';
import { useSearchParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

export default function DetailedPropertiesList() {
    const intl = useIntl();
    const theme = useTheme();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const getSelectedCompoundId = () => {
        const selected_compound_id = searchParams.get('selected_compound_id');

        return selected_compound_id;
    };
    const getSelectedPropertyId = () => {
        const selected_property_id = searchParams.get('selected_property_id');

        return selected_property_id;
    };

    const { propertyDetails, loadingPropertyDetails } = useSelector((state) => state.nawyInventory);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(`${WEBSITE_FRONTEND}compound/${getSelectedCompoundId()}/property/${getSelectedPropertyId()}`);
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'copy-clipboard-message' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
    };

    return (
        <TableContainer>
            <SubCard
                sx={{ bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark[900] : theme.palette.grey[100], mb: 2 }}
                title={
                    <Grid container spacing={1} alignItems="center">
                        <Grid item>
                            <FormattedMessage id="detailed-properties" />
                        </Grid>
                        <Grid item>
                            <Button startIcon={<ContentCopyIcon />} size="small" onClick={handleCopyToClipboard}>
                                <FormattedMessage id="copy-link" />
                            </Button>
                        </Grid>
                    </Grid>
                }
                content={false}
            >
                {loadingPropertyDetails ? (
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <SkeletonTotalIncomeCard />
                        </Grid>
                    </Grid>
                ) : (
                    <Table size="small" aria-label="Detailed Properties table">
                        <EnhancedTableHead />
                        <TableBody>
                            {propertyDetails.map((detailedProperty: IPropertyDetails) => (
                                <DetailedPropertyRow key={detailedProperty.detailed_property_id} detailedProperty={detailedProperty} />
                            ))}
                        </TableBody>
                    </Table>
                )}
                {!loadingPropertyDetails && propertyDetails.length === 0 && (
                    <NoDataFound message={intl.formatMessage({ id: 'no-property-match' })} />
                )}
            </SubCard>
        </TableContainer>
    );
}

function EnhancedTableHead() {
    return (
        <TableHead>
            <TableRow>
                {detailedPropertyHeaders.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        <FormattedMessage id={headCell.label} />
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
