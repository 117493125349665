import { createSlice } from '@reduxjs/toolkit';
import { BanksInitialState } from './interfaces';
import { queryBanks } from './actions';

// initial state
const initialState: BanksInitialState = {
    listLoading: false,
    listError: false,
    banks: { page: 1, pageSize: 10, pages: 1, count: 1, data: [] }
};

// ==============================|| SLICE - BANKS ||============================== //

const banks = createSlice({
    name: 'banks',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryBanks.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryBanks.fulfilled, (state, action) => {
                state.listLoading = false;
                state.banks = action.payload;
                state.listError = false;
            })
            .addCase(queryBanks.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = true;
            });
    }
});

export default banks.reducer;
