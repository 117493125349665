import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent, DialogContentText, Divider, Grid, Typography } from '@mui/material';
import { format } from 'date-fns';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import useOverlay from 'hooks/useOverlay';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'store';
import { gridSpacing } from 'store/constant';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { queryCommission } from 'store/slices/commissions/actions';
import { closesDialog, setStepper } from 'store/slices/createSaleClaim';
import { StepperSectionsEnums } from 'store/slices/createSaleClaim/interfaces';
import { createFlowSaleClaim, queryFlows } from 'store/slices/flows/actions';
import { FlowCommissionTypeEnum, FlowSaleTypeEnum, FlowStageStatusesEnum } from 'store/slices/flows/interfaces';
import { crmPropertyTypes } from 'store/slices/general/interfaces';
import { openSnackbar } from 'store/slices/snackbar';
import ImagesPreview from 'ui-component/ImageUpload/Preview';
import Chip from 'ui-component/extended/Chip';
import ComponentRoleGuard from 'utils/route-guard/ComponentRoleGuard';
import { CreateSaleClaimSectionProps } from '.';
import { PaymentTermsDialogPayload } from '../PaymentTerms';

const ReviewSection = ({ handleNext, handleBack, handleClose, flow }: CreateSaleClaimSectionProps) => {
    const intl = useIntl();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { emitEvent } = useMixPanel();
    const { saleInfo, property, buyer, organization, broker, documents, loading, error } = useSelector(
        (state) => state.createSaleClaimSlice
    );

    const { loading: flowLoading, flows } = useSelector((state) => state.flows);
    const images = documents?.map((file: any) => ({ src: URL.createObjectURL(file), name: file.name, error: false }));

    const { open, close } = useOverlay<PaymentTermsDialogPayload>('PaymentTermsDialog');

    const handleEditSection = (step: StepperSectionsEnums) => {
        dispatch(setStepper(step));
    };
    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'sale-claim-create-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        if (handleClose) handleClose(undefined);
        dispatch(closesDialog());
    };

    const handleSaleClaimCreation = () => {
        if (buyer && saleInfo && property) {
            const { compoundId, propertyTypeId, unitId, unitValue } = property;
            emitEvent('Click Confirm Sale Claim Creation', {
                brokerId: broker?.id,
                buyer,
                saleInfo,
                property: {
                    compoundId,
                    propertyTypeId,
                    unitId,
                    unitValue
                },
                documents,
                organizationId: organization?.id
            });

            dispatch(
                createFlowSaleClaim({
                    body: {
                        flowId: flow?.id ?? undefined,
                        brokerId: broker?.id || undefined,
                        buyerName: buyer.buyerName || '',
                        buyerPhone: buyer.buyerPhone || '',
                        amountPaid: saleInfo.downPayment,
                        compoundId: compoundId || 0,
                        contractionDate: saleInfo.contractionDate || '',
                        downPayment: saleInfo.downPayment,
                        downPaymentDate: saleInfo.downPaymentDate,
                        installmentsYears: saleInfo.installmentsYears,
                        phone: buyer.buyerPhone || '',
                        name: buyer.buyerName || '',
                        propertyTypeId: propertyTypeId || 0,
                        reservationDate: saleInfo.reservationDate,
                        type: saleInfo.type || FlowSaleTypeEnum.Primary,
                        unitId: unitId || '',
                        unitValue: unitValue || 0,
                        actualDate: saleInfo.downPaymentDate,
                        documents,
                        commissionType: FlowCommissionTypeEnum.Normal
                        // TODO: Change commission type
                    },
                    runSuccess
                })
            ).then((createRes) => {
                dispatch(
                    queryCommission({
                        queryData: {
                            tierId: createRes?.payload?.organization?.tierId,
                            compoundId: property?.compoundId
                        }
                    })
                );
                // .then((queryRes) => {
                //     const id = createRes?.payload?.id;
                //     const commission = queryRes?.payload?.data[0];
                //     if (id)
                //         dispatch(
                //             updateFlow({
                //                 flowId: id,
                //                 body: {
                //                     commissionPercentage:
                //                         createRes?.payload?.commissionTypes[0].type === CommissionTypeEnum.Express
                //                             ? commission.express
                //                             : commission.normal
                //                 }
                //             })
                //         );
                // });
            });
        }
    };

    useEffect(() => {
        if (user?.brokerAuth?.organization.tier?.name === 'freelancer') {
            dispatch(queryFlows({ query: {} }));
        }
    }, [dispatch, user?.brokerAuth?.organization.tier?.name]);

    const handleCreateSaleClaim = () => {
        if (user?.brokerAuth?.organization.tier?.name === 'freelancer') {
            const hasApprovedSaleClaim = flows.data.some((f) =>
                f.stages.some((stage) => stage.statuses?.some((status) => status.value === FlowStageStatusesEnum.Approved))
            );

            if (!hasApprovedSaleClaim) {
                open('PaymentTermsDialog', {
                    onProceed: () => {
                        handleSaleClaimCreation();
                        close();
                    }
                });
            } else {
                handleSaleClaimCreation();
            }
        } else {
            handleSaleClaimCreation();
        }
    };

    const propertyTypeName = crmPropertyTypes.find((type) => type.id === property?.propertyTypeId)?.name ?? '';
    return (
        <div>
            <DialogContent>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <DialogContentText variant="h3">
                            <FormattedMessage id="review" />
                        </DialogContentText>
                    </Grid>

                    <Grid item xs={12} container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="sale-information" />
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={() => handleEditSection(StepperSectionsEnums.mainInfo)}>
                                <FormattedMessage id="edit" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="type" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <Chip label={saleInfo?.type} size="small" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="down-payment" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    {saleInfo?.downPayment?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                </DialogContentText>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="down-payment-date" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    {saleInfo?.downPaymentDate
                                        ? format(new Date(saleInfo.downPaymentDate), 'dd MMM yyyy')
                                        : intl.formatMessage({ id: 'date-not-specified' })}
                                </DialogContentText>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="reservation-date" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    {saleInfo?.reservationDate
                                        ? format(new Date(saleInfo.reservationDate), 'dd MMM yyyy')
                                        : intl.formatMessage({ id: 'date-not-specified' })}
                                </DialogContentText>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="contraction-date" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    {saleInfo?.contractionDate
                                        ? format(new Date(saleInfo.contractionDate), 'dd MMM yyyy')
                                        : intl.formatMessage({ id: 'date-not-specified' })}
                                </DialogContentText>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="installment-years" />:
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{saleInfo?.installmentsYears}</DialogContentText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid item xs={12} container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="property-info" />
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={() => handleEditSection(StepperSectionsEnums.propertyInfo)}>
                                <FormattedMessage id="edit" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="developer" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{property?.developerName}</DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="compound" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{property?.compoundName}</DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">Unit Code :</DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{property?.unitId}</DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="type" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <Chip label={propertyTypeName} size="small" />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="price" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{property?.unitValue?.toLocaleString()}</DialogContentText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="buyer-info" />
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={() => handleEditSection(StepperSectionsEnums.buyerInfo)}>
                                <FormattedMessage id="edit" />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="name" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{buyer?.buyerName}</DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="phone" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{buyer?.buyerPhone}</DialogContentText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="sales-agent-information" />
                            </DialogContentText>
                        </Grid>
                        <ComponentRoleGuard allowedAudience={[OrganizationRole.manager, OrganizationRole.owner]}>
                            <Grid item>
                                <Button size="small" onClick={() => handleEditSection(StepperSectionsEnums.brokerSection)}>
                                    <FormattedMessage id="edit" />
                                </Button>
                            </Grid>
                        </ComponentRoleGuard>
                    </Grid>
                    <Grid item xs={12} container spacing={gridSpacing}>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="organization" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{organization?.name}</DialogContentText>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} container alignItems="center">
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">
                                    <FormattedMessage id="sales-agent" /> :
                                </DialogContentText>
                            </Grid>
                            <Grid item xs={6}>
                                <DialogContentText variant="subtitle1">{broker?.name}</DialogContentText>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid item>
                            <DialogContentText variant="h4">
                                <FormattedMessage id="documents-info" />
                            </DialogContentText>
                        </Grid>
                        <Grid item>
                            <Button size="small" onClick={() => handleEditSection(StepperSectionsEnums.documentsSection)}>
                                <FormattedMessage id="edit" />
                            </Button>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} container spacing={gridSpacing}>
                        <Grid item xs={12} container alignItems="center">
                            <ImagesPreview images={images || []} showDeleteButton={false} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!!error?.validationErrors &&
                            Object.keys(error?.validationErrors)?.map((key: string) => (
                                <Typography color="error" key={key} variant="subtitle1">
                                    {error?.validationErrors[key]}
                                </Typography>
                            ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button size="small" disabled={loading || flowLoading} onClick={handleBack}>
                    <FormattedMessage id="back" />
                </Button>
                <LoadingButton
                    disabled={flowLoading}
                    size="small"
                    variant="contained"
                    loading={flowLoading}
                    onClick={handleCreateSaleClaim}
                >
                    <FormattedMessage id="confirm" />
                </LoadingButton>
            </DialogActions>
        </div>
    );
};

export default ReviewSection;
