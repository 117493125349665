// types
import { ConfigProps } from 'types/config';

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: process.env.REACT_APP_AWS_POOL_ID,
    appClientId: process.env.REACT_APP_AWS_CLIENT_ID
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

const config: ConfigProps = {
    fontFamily: `'Poppins','Almarai', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme1',
    locale: 'en', // 'en' - English, 'ar' - Arabic
    rtlLayout: false,
    container: false,
    rowsPerPageOptions: [10, 25, 50, 100],
    rowsPerPageOptionsMax: [10, 25, 50, 100, 1000, 10000]
};

export default config;
