import { FAUNA_API, formattedQuery } from 'store/apis';

import { QueryDevelopersInterface, QueryPartnerCompoundsInterface } from './interfaces';

const DEVELOPERS_APIS = {
    query: (query: QueryDevelopersInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'developers', '?'];
        return formattedQuery(formatQuery, query);
    },
    create: () => `${FAUNA_API}developers`,
    update: (id: number) => `${FAUNA_API}developers/${id}`,
    updateCilConfiguration: (id: number) => `${FAUNA_API}developers/${id}/cil-configurations`,
    createBatch: () => `${FAUNA_API}developers/batch`,
    getById: (id: number) => `${FAUNA_API}developers/${id}`,
    /**
     * @PUT
     * @param id
     * @returns {cil, lead}
     */
    renew: (id: number) => `${FAUNA_API}developers/${id}/renew`,
    updateStatus: (id: number) => `${FAUNA_API}developers/${id}/status`,
    /**
     * @GET
     * @param QueryPartnerCompoundsInterface
     * @returns Compound[]
     */
    queryCompounds: (query: QueryPartnerCompoundsInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'compounds', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default DEVELOPERS_APIS;
