import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import PartnersDashboardPage from 'views/dashboard/index';
// import CommissionPage from 'views/commission';
import { Navigate } from 'react-router';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import CommissionTermsPage from 'views/commission-terms';
// import { OrganizationRole } from 'store/slices/brokers/interfaces';

// Common pages
const TermsAndConditionsPage = Loadable(lazy(() => import('views/common/TermsAndConditions')));
const HomePage = Loadable(lazy(() => import('views/common/Home')));
const CommissionPage = Loadable(lazy(() => import('views/commission')));
const ComissionsLandingPage = Loadable(lazy(() => import('views/common/ComissionsLandingPage')));

// ==============================|| Common GUARD ROUTING ||============================== //

const CommonRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/home',
            element: <HomePage />
        },
        {
            path: '/commissions',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[
                        { name: InvitationTypeEnum.brokers, organizationFeature: 'saleClaimsLimit' },
                        { name: InvitationTypeEnum.accountManagers },
                        { name: InvitationTypeEnum.admins }
                    ]}
                    redirectToHome
                >
                    <CommissionPage />
                </RoleGuard>
            )
        },
        {
            path: '/terms-and-conditions',
            element: <TermsAndConditionsPage />
        },
        {
            path: '/dashboard',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }]}
                >
                    <PartnersDashboardPage />
                </RoleGuard>
            )
        },
        {
            path: '/july-offer',
            element: <ComissionsLandingPage />
        },
        {
            path: '/commission-terms',
            element: <CommissionTermsPage />
        }
    ]
};

export default CommonRoutes;
