import { createAsyncThunk } from '@reduxjs/toolkit';
import { InvitationTypeEnum } from 'store/interfaces';
import axios from 'utils/axios';
import INVITATIONS_APIS from './apis';
import {
    CreateInvitationDataInterface,
    InvitationInterface,
    InvitationQueryAdminInterface,
    InvitationQueryOptionsInterface,
    CheckUserStateInterface
} from './interfaces';

export const createInvitation = createAsyncThunk(
    'invitations/create',
    async (
        requestData: {
            createData: CreateInvitationDataInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { createData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(INVITATIONS_APIS.create(), createData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const sirensBatchInvitation = createAsyncThunk(
    'invitations/batch',
    async (
        requestData: {
            users: { phone: string; type: InvitationTypeEnum }[];
            runSuccess?: (count: number) => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { users, runSuccess } = requestData;
        try {
            const successRes = await axios.post(INVITATIONS_APIS.sirensCreateBatch(), { users });
            if (runSuccess) runSuccess(successRes?.data?.count);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const faunaBatchInvitation = createAsyncThunk(
    'invitations/batch',
    async (
        requestData: {
            users: { phone: string; invitationTypeId: number }[];
            runSuccess?: (count: number) => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { users, runSuccess } = requestData;
        try {
            const successRes = await axios.post(INVITATIONS_APIS.faunaCreateBatch(), users);
            if (runSuccess) runSuccess(successRes?.data?.count);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteInvitation = createAsyncThunk(
    'invitations/delete',
    async (
        requestData: {
            invitationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { invitationId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(INVITATIONS_APIS.delete(invitationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resendInvitation = createAsyncThunk(
    'invitations/resend',
    async (
        requestData: {
            invitationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { invitationId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(INVITATIONS_APIS.resendSMS(invitationId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const sendInvitationWhatsapp = createAsyncThunk(
    'invitations/sendWhatsapp',
    async (
        requestData: {
            invitationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<InvitationInterface | any> => {
        const { invitationId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(INVITATIONS_APIS.resend(invitationId), {
                sms: false,
                whatsapp: true
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryInvitationsUser = createAsyncThunk(
    'invitations/query',
    async (
        requestData: {
            queryData: InvitationQueryOptionsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(INVITATIONS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryInvitationsAdmin = createAsyncThunk(
    'invitations/query/admin',
    async (
        requestData: {
            queryData: InvitationQueryAdminInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(INVITATIONS_APIS.queryAdmin(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const checkUser = createAsyncThunk(
    'invitations/user-state',
    async (
        requestData: {
            queryData: CheckUserStateInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<CheckUserStateInterface | any> => {
        const { queryData, runSuccess } = requestData;
        try {
            const successRes = await axios.get(INVITATIONS_APIS.checkState(queryData.phone));
            if (runSuccess) runSuccess();
            return successRes.data.userState;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const resendInvitationHourly = createAsyncThunk(
    'invitations/resend-token-hourly',
    async (
        requestData: {
            phone: string;
            channel: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<CheckUserStateInterface | any> => {
        const { phone, channel, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(INVITATIONS_APIS.resendInvitationHourly(), {
                phone,
                channel
            });

            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
