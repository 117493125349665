// hooks
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// interfaces
import { InvitationTypeEnum } from 'store/interfaces';

// actions
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import { MuiTelInput } from 'mui-tel-input';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing, gridSpacingSmall } from 'store/constant';
import { CRMControlInterface } from 'store/slices/crmControl/interfaces';
import { sirensBatchInvitation } from 'store/slices/invitations/actions';
import { openSnackbar } from 'store/slices/snackbar';
import UploadInvitationsSheet from './UploadInvitationsSheet';
import { parsePhoneNumber } from 'libphonenumber-js';

export default function BatchInvitation({ crm }: { crm: CRMControlInterface }) {
    const intl = useIntl();

    const CreateInvitationSchema = yup.object().shape({
        users: yup.array().of(
            yup.object().shape({
                phone: yup
                    .string()
                    .typeError(intl.formatMessage({ id: 'invalid-phone' }))
                    .required(intl.formatMessage({ id: 'phone-required' }))
            })
        )
    });

    const dispatch = useDispatch();
    const { error } = useSelector((state) => state.invitations);
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([
        { phone: '', type: InvitationTypeEnum.internalAgents },
        { phone: '', type: InvitationTypeEnum.internalAgents },
        { phone: '', type: InvitationTypeEnum.internalAgents }
    ]);
    const [usersErrors, setUsersErrors] = useState<{ phone: string; error: string }[]>([]);
    const [uploadSheet, setUploadSheet] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
    };

    const addPhoneNumber = () => {
        setUsers([...users, { phone: '', type: InvitationTypeEnum.internalAgents }]);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'invitation-sent-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);
        handleClose();
    };

    const formik = useFormik({
        initialValues: { users },

        onSubmit(values, formikHelpers) {
            const seen: { [key: string]: boolean } = {};
            const usersErrorsCopy: { phone: string; error: string }[] = [];

            values.users.forEach((value) => {
                const phoneNumber = value.phone;
                const isPhoneValid = parsePhoneNumber(phoneNumber, 'EG');

                if (!isPhoneValid?.isValid()) {
                    usersErrorsCopy.push({ phone: value.phone, error: 'Phone is invalid' });
                    formikHelpers.setSubmitting(false);
                } else if (seen[phoneNumber]) {
                    usersErrorsCopy.push({ phone: value.phone, error: 'Phone is duplicate' });
                    formikHelpers.setSubmitting(false);
                } else usersErrorsCopy.push({ phone: value.phone, error: '' });

                seen[phoneNumber] = true;
            });
            setUsersErrors(usersErrorsCopy);

            setUsers(values.users);
            dispatch(
                sirensBatchInvitation({
                    users,
                    runSuccess
                })
            );
        },
        validationSchema: CreateInvitationSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            <Button fullWidth variant="outlined" color="secondary" size="medium" onClick={handleClickOpen}>
                <FormattedMessage id="bulk-invite" />
            </Button>
            {uploadSheet && !open ? (
                <UploadInvitationsSheet crm={crm} openInput={uploadSheet} />
            ) : (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll="paper"
                    maxWidth="xs"
                    aria-labelledby="Invite User"
                    aria-describedby="Invite other users to join nawy sirens"
                >
                    <DialogTitle id="invite-user-dialog" fontWeight={700}>
                        <FormattedMessage id="bulk-invite" />
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={gridSpacing}>
                            <Grid item container>
                                <Grid item container xs={6}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" fontWeight={400}>
                                            <FormattedMessage id="CRM" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{crm.name}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={6}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" fontWeight={400}>
                                            <FormattedMessage id="user-group" />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormattedMessage id="internalAgent" />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={gridSpacing}>
                                {formik.values.users.map((user, index) => (
                                    <Grid item key={index}>
                                        <MuiTelInput
                                            dir="ltr"
                                            disabled={formik.isSubmitting}
                                            id={`invite-user-phone ${index}`}
                                            name={`phone[${index}].phone`}
                                            forceCallingCode
                                            focusOnSelectCountry
                                            excludedCountries={['IL']}
                                            disableFormatting
                                            defaultCountry="EG"
                                            label="Phone"
                                            value={user.phone}
                                            onChange={(v) => {
                                                const updatedUsers = [...users];
                                                updatedUsers[index].phone = v;
                                                setUsers(updatedUsers);
                                                formik.setFieldValue(`users[${index}].phone`, v);
                                            }}
                                            error={!!usersErrors && !!usersErrors[index] && !!usersErrors[index].error}
                                            helperText={usersErrors && usersErrors[index] && usersErrors[index].error}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item>
                                <Button startIcon={<AddCircleTwoToneIcon />} onClick={addPhoneNumber}>
                                    <FormattedMessage id="invite" /> <FormattedMessage id="more" />
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={gridSpacingSmall}>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    onClick={() => {
                                        setOpen(false);
                                        setUploadSheet(true);
                                    }}
                                >
                                    <FormattedMessage id="upload-sheet" />
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography textAlign="center">{intl.formatMessage({ id: 'or' })}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    loading={formik.isSubmitting}
                                    onClick={formik.submitForm}
                                    size="medium"
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                >
                                    <FormattedMessage id="invite" />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
