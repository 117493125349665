import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateCRMInterface, QueryCRMControlInterface, UpdateCRMInterface } from './interfaces';
import axios from 'utils/axios';
import CRM_CONTROL_API from './apis';

export const queryCRMs = createAsyncThunk(
    'crms/query',
    async (
        requestData: {
            query: QueryCRMControlInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(CRM_CONTROL_API.query({ ...query, disabled: false }));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const createCRM = createAsyncThunk(
    'crms/create',
    async (
        requestData: {
            data: CreateCRMInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { data, runSuccess } = requestData;
        try {
            const successRes = await axios.post(CRM_CONTROL_API.create(), data);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const findCRM = createAsyncThunk(
    'crms/get-by-id',
    async (
        requestData: {
            crmId: number;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { crmId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(CRM_CONTROL_API.find(crmId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateCRM = createAsyncThunk(
    'crms/update',
    async (
        requestData: {
            crmId: number;
            updateData: UpdateCRMInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { crmId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(CRM_CONTROL_API.update(crmId), updateData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
