import { FAUNA_API, queryData } from 'store/apis';

import { QueryBuyersInterface, QuerySaleClaimsInterface } from './interfaces';

const SALE_CLAIMS_APIS = {
    /**
     * @summary create sale claim for an organization
     * @POST /organizations/:id/sale-claims',
     * @param organizationId
     * @returns success message
     */
    create: (organizationId: string) => `${FAUNA_API}organizations/${organizationId}/sale-claims`,
    /**
     * @GET /sale-claims
     * @returns full sale claim entity
     */
    query: (query: QuerySaleClaimsInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sale-claims', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    /**
     * @GET /sale-claims
     * @param saleClaimId
     * @returns full sale claim entity
     */
    getById: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}`,
    /**
     * @PATCH /sale-claims/:id
     * @param saleClaimId
     * @returns full sale claim entity
     */
    update: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}`,
    /**
     * @PATCH /sale-claims/:id/full
     * @param saleClaimId
     * @returns full sale claim entity
     */
    updateFull: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/full`,
    /**
     * @DELETE /sale-claims/:id
     * @param saleClaimId
     * @returns success message
     */
    delete: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}`,
    /**
     * @PATCH /sale-claims/:id/status
     * @param saleClaimId
     * @returns success message
     */
    updateStatus: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/status`,

    /**
     * @GET /comments
     * @param saleClaimId
     * @returns List of sale claim comments
     */
    getComments: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/comments`,

    /**
     * @Post saleClaims/:saleClaimId/comments
     * @param saleClaimId
     * @returns full comment entity
     */
    createComment: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/comments`,

    /**
     * @Patch sale-claims/:saleClaimId/comments/:commentId
     * @param saleClaimId
     * @param commentId
     * @body CreateCommentInterface
     * @returns full sale claim entity
     */
    updateComment: (saleClaimId: string, commentId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/comments/${commentId}`,

    /**
     * @Delete /comments/:commentId
     * @param saleClaimId
     * @param commentId
     * @returns nothing
     */
    deleteComment: (saleClaimId: string, commentId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/comments/${commentId}`,

    /**
     * @Patch /sale-claims/:id/commission-type
     * @param saleClaimId
     * @Body UpdateSaleClaimCommissionTypeInterface
     * @returns SaleClaimCommissionType
     * */

    updateCommissionType: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/commission-type`,

    /**
     * @GET /buyers
     * @returns full buyer entity
     */
    queryBuyers: (query: QueryBuyersInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'buyers', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    },
    /**
     * @Patch /sale-claims/:id/buyers
     * @param saleClaimId
     * @Body UpdateSaleClaimBuyerInterface
     * @returns SaleClaimBuyer
     */
    updateSaleClaimBuyer: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/buyers`,

    /**
     * @Patch /sale-claims/:id/properties
     * @param saleClaimId
     * @Body UpdateSaleClaimPropertyInterface
     * @returns SaleClaimProperty
     * @deprecated use update sale claim
     */
    updateSaleClaimProperty: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/properties`,

    /**
     * @deprecated
     * @Patch /sale-claims/:id/approveSaleClaim
     * @param saleClaimId
     * @returns void
     */
    approveSaleClaim: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/approveSaleClaim`,

    /**
     * @deprecated
     * @Patch /sale-claims/:id/rejectSaleClaim
     * @param saleClaimId
     * @returns void
     */
    rejectSaleClaim: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/rejectSaleClaim`,

    /**
     * @Patch /sale-claims/:id/sale-claim-documents
     * @param saleClaimId
     * @Body SaleClaimDocumentInterface
     * @returns void
     */
    uploadSaleClaimDocument: (saleClaimId: string) => `${FAUNA_API}sale-claims/${saleClaimId}/sale-claim-documents`,

    /**
     * @Patch /sale-claims/:saleClaimId/sale-claim-documents/:documentId
     * @param saleClaimId
     * @param documentId
     * @Body SaleClaimDocumentInterface
     * @returns void
     */

    deleteSaleClaimDocument: (saleClaimId: string, documentId: string) =>
        `${FAUNA_API}sale-claims/${saleClaimId}/sale-claim-documents/${documentId}`,
    /**
     * @GET
     * @description get `Sale Claims` analytics
     * @returns SaleClaimsAnalytica[]
     */
    // analytica: () => `${FAUNA_API}sale-claims/analytica`,
    analytica: (query: QuerySaleClaimsInterface) => {
        const formateQuery: string[] = [FAUNA_API, 'sale-claims', '/analytica', '?'];
        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            formateQuery.push(queryData(queryKey, queryValues[i]), i === queryValues.length - 1 ? '' : '&');
        });
        return formateQuery.join('');
    }
};
export default SALE_CLAIMS_APIS;
