import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import BROKERS_APIS from './apis';
import {
    BrokerFullDataInterface,
    BrokersListInterface,
    OrganizationRole,
    QueryBrokersInterface,
    QueryBrokersMinimalInterface
} from './interfaces';

export const queryFullBrokers = createAsyncThunk(
    'brokers/query',
    async (
        requestData: {
            queryData: QueryBrokersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokersListInterface | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(BROKERS_APIS.query(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const queryMinimalBrokers = createAsyncThunk(
    'brokers/query/minimal',
    async (
        requestData: {
            queryData: QueryBrokersMinimalInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokersListInterface | any> => {
        const { queryData, runSuccess } = requestData;

        try {
            const successRes = await axios.get(BROKERS_APIS.queryMinimal(queryData));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getBrokerByCId = createAsyncThunk(
    'brokers/get-by-id',
    async (
        requestData: {
            brokerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokerFullDataInterface | any> => {
        const { brokerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(BROKERS_APIS.getById(brokerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const changeBrokerRole = createAsyncThunk(
    'brokers/change-broker-role',
    async (
        requestData: {
            brokerCId: string;
            newRole: {
                role: OrganizationRole;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokerFullDataInterface | any> => {
        const { brokerCId, newRole, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(BROKERS_APIS.changeRole(brokerCId), newRole);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateBrokerSalesAgent = createAsyncThunk(
    'brokers/change-broker-sales-agent',
    async (
        requestData: {
            id: string;
            salesAgentId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokerFullDataInterface | any> => {
        const { id, salesAgentId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(BROKERS_APIS.updateBrokerSalesAgent(id), { salesAgentId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const recoverBroker = createAsyncThunk(
    'brokers/recover-broker',
    async (
        requestData: {
            brokerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<BrokerFullDataInterface | any> => {
        const { brokerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(BROKERS_APIS.recoverBroker(brokerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const hardDelete = createAsyncThunk(
    'brokers/hard-delete-broker',
    async (
        requestData: {
            brokerCId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<String | any> => {
        const { brokerCId, runSuccess } = requestData;
        try {
            const successRes = await axios.delete(BROKERS_APIS.recoverBroker(brokerCId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createBrokerFromExistingUser = createAsyncThunk(
    'brokers/create-broker-from-existing-user',
    async (
        requestData: {
            brokerCId: string;
            organizationId: string;
            userData: {
                name: string;
                phone: string;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<String | any> => {
        const { brokerCId, organizationId, userData, runSuccess } = requestData;
        try {
            const successRes = await axios.post(BROKERS_APIS.createBrokerFromExistingUser(organizationId, brokerCId), userData);
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const moveBroker = createAsyncThunk(
    'brokers/move',
    async (
        requestData: {
            brokerCId: string;
            organizationId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<String | any> => {
        const { brokerCId, organizationId, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(BROKERS_APIS.moveBroker(brokerCId), { organizationId });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
