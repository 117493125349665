import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { NavItemType } from 'types';
import { NotebookText } from 'lucide-react';
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';

const crmItem: NavItemType = {
    id: 'crm-funnel-report',
    title: <FormattedMessage id="crm-funnel-report" />,
    type: 'item',
    url: '/crm-funnel-report',
    icon: GroupOutlinedIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'CRM Funnel Report'
    },
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers, roles: [AccountManagersRole.head] }]
};

const competitionItem: NavItemType = {
    id: 'leaderboard-external',
    title: <FormattedMessage id="partners-competition" />,
    type: 'item',
    target: true,
    icon: LeaderboardIcon,
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }]
};

const partnersItem: NavItemType = {
    id: 'dashboard',
    title: <FormattedMessage id="partners-dashboard" />,
    type: 'item',
    target: false,
    url: '/dashboard',
    icon: DonutSmallIcon,
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'Dashboard'
    }
};
const reportsCollapse: NavItemType = {
    id: 'reports-items-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }],
    children: [
        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'collapse',
            icon: NotebookText,
            breadcrumbs: false,
            children: [crmItem, partnersItem]
        }
    ]
};

export default reportsCollapse;
