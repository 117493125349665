import { Autocomplete, Grid, TextField, Typography } from '@mui/material';
import useMixPanel from 'hooks/useMixPanel';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { dispatch, useSelector } from 'store';
import { queryCRMs } from 'store/slices/crmControl/actions';

import { PerfectFilterValueInterface } from 'store/slices/filters/interfaces';

interface CRMsFilterProps {
    defaultValueId?: number | '';
    value?: number | '';
    size?: 'small' | 'medium';
    handleSearch: (newValue?: number) => void;
    handleSelected?: (crm?: PerfectFilterValueInterface | null | undefined) => void;
}

const CRMsFilter = ({ defaultValueId = '', value = '', size = 'medium', handleSearch, handleSelected }: CRMsFilterProps) => (
    <Filter defaultValueId={defaultValueId} size={size} value={value} handleSearch={handleSearch} handleSelected={handleSelected} />
);

const Filter = ({ defaultValueId, value, size, handleSearch, handleSelected }: CRMsFilterProps) => {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const { crms, listLoading } = useSelector((state) => state.crmControl);

    const [newValue, setNewValue] = useState<string>('');
    const firstRender = useRef(true);

    const handleInputChange = (event: React.SyntheticEvent<Element, Event>, name: string) => {
        dispatch(queryCRMs({ query: { name, disabled: false } }));
        setNewValue(name);
    };

    const handleOnChange = (entity: PerfectFilterValueInterface | null | undefined) => {
        handleSearch(entity?.id);
        if (handleSelected) handleSelected(entity);
    };

    useEffect(() => {
        if (defaultValueId) {
            dispatch(queryCRMs({ query: { id: defaultValueId } }));
        } else {
            dispatch(queryCRMs({ query: { name: newValue } }));
        }
    }, [defaultValueId, newValue]);

    useEffect(() => {
        if (!firstRender.current) {
            return;
        }
        if (defaultValueId && crms.count > 0) {
            firstRender.current = false;
            const crm = crms.data.find((c) => c.id === defaultValueId) ?? null;

            if (handleSelected) handleSelected(crm);
        }
    }, [crms, defaultValueId, handleSelected]);
    const handleFilterClick = () => {
        emitEvent('Click developer filter');
    };
    return (
        <Autocomplete
            id="autocomplete-generic-filter-crms"
            fullWidth
            onOpen={handleFilterClick}
            size={size}
            options={crms.data}
            loading={listLoading}
            value={crms.data.find((v) => v.id === value) || null}
            onChange={(event, option) => handleOnChange(option)}
            inputValue={newValue}
            onInputChange={handleInputChange}
            isOptionEqualToValue={(option) => option?.id === value}
            getOptionLabel={(option) => `${option.name}`}
            renderOption={(props, option) => (
                <li {...props}>
                    <Grid item xs={12} pl={1}>
                        <Typography textTransform="capitalize">{option.name}</Typography>
                    </Grid>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="search-filter-autocomplete-crms"
                    label={intl.formatMessage({ id: 'crms' })}
                    inputProps={{
                        ...params.inputProps
                    }}
                />
            )}
        />
    );
};
export default CRMsFilter;
