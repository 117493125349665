import { createAsyncThunk } from '@reduxjs/toolkit';
import SALE_CLAIMS_APIS from '../saleClaims/apis';
import axios from 'utils/axios';
import { CreateSaleBuyerInterface, CreateSaleFinancePropertyInterface, CreateSaleInfoInterface } from './interfaces';

export const createSaleClaim = createAsyncThunk(
    'createSaleClaims',
    async (
        requestData: {
            organizationId: string;
            saleInfo: CreateSaleInfoInterface;
            property: CreateSaleFinancePropertyInterface;
            buyer: CreateSaleBuyerInterface;
            brokerId: string;
            documents?: Array<any>;
            runSuccess?: (saleClaimId: string) => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { documents, saleInfo, property, buyer, organizationId, brokerId, runSuccess } = requestData;
        const formData = new FormData();

        if (documents) {
            for (const document of documents) {
                formData.append('files', document);
            }
        }
        formData.append(
            'json',
            JSON.stringify({
                ...saleInfo,
                ...property,
                ...buyer,
                brokerId
            })
        );

        try {
            const successRes = await axios.post(SALE_CLAIMS_APIS.create(organizationId), formData, {
                headers: { 'Content-Type': `multipart/form-data;`, Accept: 'application/json' }
            });

            if (runSuccess) runSuccess(successRes.data.id);
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default createSaleClaim;
