import { FAUNA_API, formattedQuery } from 'store/apis';
import { QueryBanksInterface } from './interfaces';

const BANKS_APIS = {
    query: (query: QueryBanksInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'banks/all-banks', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default BANKS_APIS;
