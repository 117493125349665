import { createSlice } from '@reduxjs/toolkit';
import getInventoryManagerById from './actions';

// initial state
const initialState = {
    loading: false,
    error: false,
    inventoryManager: null,
    inventoryManagers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - ACCOUNT MANAGERS ||============================== //

const inventoryManagers = createSlice({
    name: 'inventoryManagers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getInventoryManagerById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getInventoryManagerById.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.inventoryManager = action.payload;
            });
    }
});

export default inventoryManagers.reducer;
