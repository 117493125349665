import { FAUNA_API, formattedQuery } from 'store/apis';
import { PerfectFilterQueryInterface } from './interfaces';

const FILTER_APIS = {
    propertyTypesFilter: (query: PerfectFilterQueryInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'property-types/filter', '?'];
        return formattedQuery(formatQuery, query);
    },
    developers: (query: PerfectFilterQueryInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'developers/filter', '?'];
        return formattedQuery(formatQuery, query);
    },
    compounds: (query: PerfectFilterQueryInterface) => {
        const formatQuery: string[] = [FAUNA_API, 'compounds/filter', '?'];
        return formattedQuery(formatQuery, query);
    }
};

export default FILTER_APIS;
