import { Typography, Grid, Container } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';
import bulletPointWhite from 'assets/images/bulletPointWhite.svg';
import bulletPointBlue from 'assets/images/bulletPointBlue.svg';

function PartnersBenefitsContent() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { locale } = useIntl();

    return (
        <div>
            {isSmallScreen ? (
                <Grid
                    container
                    justifyContent="center"
                    sx={{ background: theme.palette.primary[800], borderColor: theme.palette.primary[800] }}
                >
                    <Grid
                        item
                        width="100%"
                        marginBottom={2}
                        marginTop={2}
                        marginRight={2}
                        marginLeft={2}
                        height="75px"
                        style={{
                            borderRadius: '60px',
                            backgroundImage: `linear-gradient(71deg, #00234C 68.06%, #0052B2 117.95%)`,
                            boxShadow: `0px 2px 8px 0px rgba(22, 93, 178, 0.50)`
                        }}
                    >
                        <Typography
                            variant="h2"
                            fontSize="18px"
                            fontWeight={700}
                            color="white"
                            align="center"
                            marginBottom={3}
                            marginTop={3}
                        >
                            <FormattedMessage id="why-partner-title" />
                        </Typography>
                    </Grid>
                    <Grid item width="100%" marginRight={4} marginLeft={4}>
                        <Typography variant="h2" fontSize="18px" fontWeight={700} color="white" marginBottom={3} marginTop={3}>
                            <FormattedMessage id="why-partner-text-1" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-2" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-3" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-4" />
                        </Typography>
                    </Grid>
                    <Grid item width="100%" marginRight={4} marginLeft={4}>
                        <Typography variant="h2" fontSize="18px" fontWeight={700} color="white" marginBottom={3} marginTop={3}>
                            <FormattedMessage id="why-partner-text-5" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-7" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-8" />
                        </Typography>
                        <Typography variant="h5" fontSize="16px" fontWeight={400} color="white" marginBottom={2}>
                            <img
                                src={bulletPointWhite}
                                alt="icons"
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    marginRight: locale === 'en' ? '10px' : '0px',
                                    marginLeft: locale === 'ar' ? '10px' : '0px',
                                    transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                    filter: locale === 'ar' ? 'FlipH' : 'none'
                                }}
                            />
                            <FormattedMessage id="why-partner-text-9" />
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <Grid container justifyContent="center">
                    <Container maxWidth={false} sx={{ maxWidth: '1200px', width: '100%' }}>
                        <Grid container md={12} marginBottom={5} justifyContent="top" alignItems="top" width="100%">
                            <Grid
                                item
                                md={12}
                                marginBottom={2}
                                style={{
                                    borderRadius: '24px',
                                    backgroundImage: `radial-gradient(894.33% 57.35% at 50% 50.77%, rgba(226, 233, 241, 0.50) 0%, rgba(167, 184, 204, 0.00) 100%)`
                                }}
                            >
                                <Typography
                                    variant="h2"
                                    fontSize="18px"
                                    fontWeight={700}
                                    color="black"
                                    align="center"
                                    marginBottom={3}
                                    marginTop={3}
                                >
                                    <FormattedMessage id="why-partner-title" />
                                </Typography>
                            </Grid>
                            <Grid item md={6} marginTop={4} marginBottom={2}>
                                <Typography variant="h2" fontSize="18px" fontWeight={700} color="black" marginBottom={3} marginTop={3}>
                                    <FormattedMessage id="why-partner-text-1" />
                                </Typography>

                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-2" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-3" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-4" />
                                </Typography>
                            </Grid>
                            <Grid item md={6} marginTop={4} marginBottom={2}>
                                <Typography variant="h2" fontSize="18px" fontWeight={700} color="black" marginBottom={3} marginTop={3}>
                                    <FormattedMessage id="why-partner-text-5" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-7" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-8" />
                                </Typography>
                                <Typography variant="h5" fontSize="16px" fontWeight={400} color="black" marginBottom={2}>
                                    <img
                                        src={bulletPointBlue}
                                        alt="icons"
                                        style={{
                                            width: '10px',
                                            height: '10px',
                                            marginRight: locale === 'en' ? '10px' : '0px',
                                            marginLeft: locale === 'ar' ? '10px' : '0px',
                                            transform: locale === 'ar' ? 'scaleX(-1)' : 'none',
                                            filter: locale === 'ar' ? 'FlipH' : 'none'
                                        }}
                                    />
                                    <FormattedMessage id="why-partner-text-9" />
                                </Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
            )}
        </div>
    );
}
export default PartnersBenefitsContent;
