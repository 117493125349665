// types
import { createSlice } from '@reduxjs/toolkit';

import { createBatchCommission, createCommission, deleteCommission, queryCommission, updateCommission } from './actions';
import { InitialCommissionsInterface } from './interfaces';

// initial state
const initialState: InitialCommissionsInterface = {
    loading: false,
    refresh: false,
    error: false,
    commissions: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

const commissions = createSlice({
    name: 'commissions',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryCommission.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryCommission.fulfilled, (state, action) => {
                state.loading = false;
                state.commissions = action.payload;
            })
            .addCase(queryCommission.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createCommission.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createCommission.fulfilled, (state) => {
                state.refresh = true;
                state.loading = false;
            })
            .addCase(createCommission.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(createBatchCommission.pending, (state) => {
                state.error = false;
                state.loading = true;
            })
            .addCase(createBatchCommission.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(createBatchCommission.rejected, (state, action) => {
                state.error = action.payload;
            })

            .addCase(updateCommission.pending, (state) => {
                state.error = false;
            })
            .addCase(updateCommission.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateCommission.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteCommission.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteCommission.fulfilled, (state) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteCommission.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default commissions.reducer;
