// hooks
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';

// material ui
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';

// constants
import { gridSpacing } from 'store/constant';

// action
import { openSnackbar } from 'store/slices/snackbar';

// third party libraries
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';

import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import useMixPanel from 'hooks/useMixPanel';
import useOverlay from 'hooks/useOverlay';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { MuiTelInput } from 'mui-tel-input';
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { queryFullBrokers } from 'store/slices/brokers/actions';
import { BrokerFullDataInterface } from 'store/slices/brokers/interfaces';
import { createCils } from 'store/slices/cils/actions';
import selectableAreas from 'types/selectableAreas';
import ComponentRoleGuard from 'utils/route-guard/ComponentRoleGuard';
import { ar } from 'date-fns/locale';
import { areDayPropsEqual } from '@mui/x-date-pickers/internals';

interface CreateFinancingCilDialogProps {
    open?: boolean;
    location: string;
    fullWidth?: boolean;
    variant?: 'contained' | 'outlined';
    onClose?: () => void;
}

export default function CreateFinancingCilDialog({
    open = false,
    fullWidth,
    variant,
    location,
    onClose
}: Partial<CreateFinancingCilDialogProps>) {
    const intl = useIntl();
    const { emitEvent } = useMixPanel();
    const CreateCilSchema = yup.object().shape({
        name: yup.string().required(intl.formatMessage({ id: 'name-required' })),
        phone: yup
            .number()
            .typeError(intl.formatMessage({ id: 'phone-invalid' }))
            .required(intl.formatMessage({ id: 'phone-required' })),
        location: yup.string(),
        comment: yup.string(),
        brokerId: yup.string()
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [brokersSearchQuery, setBrokersSearchQuery] = useState('');
    const [selectBroker, setSelectBroker] = useState<BrokerFullDataInterface | null>(null);
    const [autocompleteVisible, setAutocompleteVisible] = useState(false);

    const { error } = useSelector((state) => state.cils);
    const { listLoading, brokers } = useSelector((state) => state.brokers);

    const {
        isCurrentOpened,
        open: openOverlay,
        close,
        state: { payload }
    } = useOverlay<CreateFinancingCilDialogProps>('CreateFinancingCil');

    useEffect(() => {
        if (autocompleteVisible) dispatch(queryFullBrokers({ queryData: { globalSearch: brokersSearchQuery } }));
    }, [autocompleteVisible, brokersSearchQuery, dispatch]);

    const handleClickOpen = () => {
        emitEvent('Click Add Financing CIL', { location: payload?.location ?? location });
        openOverlay();
    };

    const handleClose = (closeFrom: string) => {
        if (!formik.isSubmitting) {
            emitEvent('Click Cancel Fin CIL Creation', { location: closeFrom });
            formik.resetForm();
        }
        close();
        onClose?.();
    };
    const handleAutocompleteOpen = () => {
        setAutocompleteVisible(true);
    };

    const descriptionElementRef = useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
            handleClickOpen();
        }
    }, [open]);

    const runSuccess = (cilId: number) => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'cil-created-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        formik.setSubmitting(false);

        navigate(`../cils/${cilId}`, { replace: false });
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            location: '',
            comment: '',
            brokerId: '',
            areaName: ''
        },
        onSubmit(values, formikHelpers) {
            formikHelpers.setSubmitting(true);
            const isPhoneValid = parsePhoneNumber(values.phone, 'EG')?.isValid();
            if (!isPhoneValid) {
                formik.setFieldError('phone', intl.formatMessage({ id: 'phone-invalid' }));
                formik.setSubmitting(false);
                return;
            }
            dispatch(
                createCils({
                    name: values.name,
                    phone: values.phone,
                    location: values.location,
                    areaName: values.location,
                    comment: values.comment,
                    brokerId: values.brokerId || undefined,
                    runSuccess
                })
            );
            handleClose('Submit Button');
        },
        validationSchema: CreateCilSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return (
        <>
            {variant === 'contained' ? (
                <Button
                    fullWidth={fullWidth}
                    variant={variant || 'outlined'}
                    startIcon={<AddCircleTwoToneIcon />}
                    onClick={handleClickOpen}
                >
                    <FormattedMessage id="add-fin-cil" />
                </Button>
            ) : null}

            <Dialog
                open={isCurrentOpened}
                onClose={() => handleClose('Out Dialog')}
                scroll="paper"
                maxWidth="sm"
                fullWidth
                aria-labelledby="Create-CIL"
                aria-describedby="create cil to the crm"
            >
                <DialogTitle id="create-CIL-dialog">
                    <FormattedMessage id="add-financing-cil" />
                </DialogTitle>
                <DialogContent>
                    <Grid container py={1} spacing={gridSpacing}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'name' })}
                                name="name"
                                id="add-cil-name"
                                type="text"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.name && formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiTelInput
                                dir="ltr"
                                fullWidth
                                disabled={formik.isSubmitting}
                                id="invite-partner-phone"
                                name="phone"
                                excludedCountries={['IL']}
                                forceCallingCode
                                focusOnSelectCountry
                                disableFormatting
                                defaultCountry="EG"
                                label={`${intl.formatMessage({ id: 'client-phone' })} *`}
                                value={formik.values.phone}
                                onChange={(v) => formik.setFieldValue('phone', v)}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.phone && formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="add-cil-location-label">
                                    <FormattedMessage id="location" />
                                </InputLabel>
                                <Select
                                    labelId="add-cil-location-label"
                                    id="add-cil-location"
                                    value={formik.values.location}
                                    label={intl.formatMessage({ id: 'location' })}
                                    name="location"
                                    onChange={formik.handleChange}
                                >
                                    <MenuItem value="">
                                        <FormattedMessage id="none" />
                                    </MenuItem>

                                    {selectableAreas.map((area, index) => (
                                        <MenuItem value={area.name} key={`${area.id} ${index}`}>
                                            {intl.formatMessage({ id: area.name })}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <ComponentRoleGuard
                            allowedAudience={[AccountManagersRole.member, AccountManagersRole.supervisor, AccountManagersRole.head]}
                        >
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id="select-broker"
                                        loading={listLoading}
                                        onOpen={handleAutocompleteOpen}
                                        loadingText={intl.formatMessage({ id: 'loading-brokers' })}
                                        options={brokers.data || []}
                                        getOptionLabel={(option) => option.user?.name || ''}
                                        onInputChange={(event, value) => setBrokersSearchQuery(value)}
                                        filterOptions={(x) => x}
                                        onChange={(event, newValue) => {
                                            setSelectBroker(newValue);
                                            formik.setFieldValue('brokerId', newValue?.id);
                                        }}
                                        isOptionEqualToValue={(option: BrokerFullDataInterface, value) =>
                                            option.user?.name === value?.user?.name
                                        }
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                <Grid item xs={12} pl={1}>
                                                    <Typography>{option.user?.name}</Typography>
                                                    <Typography variant="subtitle2">{option.organization.name}</Typography>
                                                </Grid>
                                            </li>
                                        )}
                                        value={selectBroker}
                                        renderInput={(params) => (
                                            <TextField {...params} label={`${intl.formatMessage({ id: 'broker' })} *`} name="broker" />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </ComponentRoleGuard>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={intl.formatMessage({ id: 'comment' })}
                                name="comment"
                                id="add-cil-comment"
                                type="text"
                                multiline
                                rows={4}
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={Boolean(formik.touched.comment && formik.errors.comment)}
                                helperText={formik.touched.comment && formik.errors.comment}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button disabled={formik.isSubmitting} onClick={() => handleClose('Cancel Button')} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton
                        onClick={() => {
                            emitEvent('Click Confirm Fin CIL Creation', { ...formik.values, error: Boolean(formik.errors) });
                            formik.submitForm();
                        }}
                        loading={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                        size="small"
                        variant="contained"
                    >
                        <FormattedMessage id="create" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}
