/* eslint-disable react/destructuring-assignment */
import { Card, Grid, GridTypeMap, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { gridSpacingMedium, gridSpacingSmall } from 'store/constant';
import { FlowStagesEnum } from 'store/slices/partnersDashboard/interfaces';
import ExpandableCard from 'ui-component/cards/ExpandableCard';
import TotalsWidgetCard from 'ui-component/cards/TotalsWidgetCard';
import WidgetCard from 'ui-component/cards/WidgetCard';
import RoleGuard, { RoleGuardGroupProps } from 'utils/RoleGuard';
import EnBackground from '../../cards/background-images/ltr.svg';
import ArBackground from '../../cards/background-images/rtl.svg';

export enum WidgetTypeEnum {
    count = 'count',
    money = 'money',
    custom = 'custom',
    default = 'default'
}

export interface AnalyticsWidgetsType {
    total: AnalyticsWidgetsData[];
    count: AnalyticsWidgetsData[];
}

export interface WidgetSectionProps {
    analyticsWidgets: AnalyticsWidgetsData[];
}

export interface AnalyticsWidgetsData {
    expandable?: boolean;
    flowType?: FlowStagesEnum;
    icon?: ReactNode;
    title?: string;
    children?: AnalyticsWidgetsData[];
    type: WidgetTypeEnum;
    label: string;
    value?: number;
    count?: number;
    allowedAudience?: RoleGuardGroupProps[];
    loading?: boolean;
    renderCustom?: React.ReactNode;
    props?: {
        custom?: GridTypeMap['props'];
    };
}

const WidgetSection = ({ analyticsWidgets }: WidgetSectionProps) => {
    const totalsMoneyT: AnalyticsWidgetsData[] = [];
    const totalsCountsT: AnalyticsWidgetsData[] = [];
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
    const intl = useIntl();
    const pageDirection = intl.locale;

    analyticsWidgets?.forEach((widget) => {
        if (widget.expandable && !matchDownSM) {
            widget.children?.forEach((child) => {
                switch (child.type) {
                    case WidgetTypeEnum.count:
                        totalsCountsT.push(child);
                        break;
                    case WidgetTypeEnum.money:
                        totalsMoneyT.push(child);
                        break;
                    default:
                        break;
                }
            });
        }
    });

    const GuardedWidgetCard = (widget: AnalyticsWidgetsData, isMain?: boolean, icon?: ReactNode) =>
        widget.allowedAudience ? (
            <Grid key={`${widget.title} ${widget.value}`} item xs={4}>
                <RoleGuard allowAdminByDefault={false} groups={widget.allowedAudience}>
                    <WidgetCard
                        label={widget.label}
                        money={widget.value}
                        upperTotal={widget.count}
                        loading={widget.loading}
                        icon={icon}
                        isMain={isMain}
                    />
                </RoleGuard>
            </Grid>
        ) : (
            <Grid key={`${widget.title} ${widget.value}`} item xs={4}>
                <WidgetCard
                    label={widget.label}
                    money={widget.value}
                    upperTotal={widget.count}
                    loading={widget.loading}
                    icon={icon}
                    isMain={isMain}
                />
            </Grid>
        );

    return (
        <Grid container position="relative" zIndex={1} spacing={gridSpacingMedium}>
            {analyticsWidgets.map((analyticsWidget, i) =>
                analyticsWidget.expandable && !matchDownSM ? (
                    <Grid item key={i} maxHeight="85px" xs={12} md={4}>
                        <ExpandableCard
                            expandedChildren={[
                                <TotalsWidgetCard data={totalsCountsT} />,
                                <Grid container spacing={gridSpacingSmall}>
                                    {totalsMoneyT.map((widget) => GuardedWidgetCard(widget, false))}
                                </Grid>
                            ]}
                        >
                            {GuardedWidgetCard(analyticsWidget, true, analyticsWidget.icon)}
                        </ExpandableCard>
                    </Grid>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        key={i}
                        item
                        xs={matchDownSM && analyticsWidgets.some((widget) => widget.renderCustom) && analyticsWidgets.length > 1 ? 12 : 4}
                        {...analyticsWidget.props?.custom}
                    >
                        {analyticsWidget.renderCustom ?? (
                            <Card
                                sx={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
                                style={{
                                    borderRadius: 4,
                                    padding: matchDownSM ? 5 : 12,
                                    background:
                                        pageDirection === 'en' && matchDownSM
                                            ? `linear-gradient(90deg, rgba(0, 52, 174, 0.40) -126.91%, rgba(0, 52, 174, 0.00) 53.11%), linear-gradient(0deg, #E8EAF6 0%, #E8EAF6 100%), #FFF`
                                            : pageDirection === 'ar' && matchDownSM
                                            ? `linear-gradient(270deg, rgba(0, 52, 174, 0.40) -98.84%, rgba(0, 52, 174, 0.00) 53.96%), linear-gradient(0deg, #E8EAF6 0%, #E8EAF6 100%), #FFF`
                                            : pageDirection === 'en' && !matchDownSM
                                            ? `url("${EnBackground}") no-repeat right top, linear-gradient(90deg, rgba(0, 52, 174, 0.40) -126.91%, rgba(0, 52, 174, 0.00) 53.11%), linear-gradient(0deg, ${theme.palette.primary[200]} 0%, ${theme.palette.primary[100]} 100%), #FFF`
                                            : `url("${ArBackground}") no-repeat left top, linear-gradient(270deg, rgba(0, 52, 174, 0.40) -98.84%, rgba(0, 52, 174, 0.00) 53.96%), linear-gradient(0deg, ${theme.palette.primary[200]} 0%, ${theme.palette.primary[100]} 100%), #FFF`,
                                    backgroundSize: 'contain'
                                }}
                            >
                                {GuardedWidgetCard(
                                    analyticsWidget,
                                    true,
                                    (!matchDownSM && !matchDownMD) || analyticsWidgets.length === 1 ? analyticsWidget.icon : undefined
                                )}
                            </Card>
                        )}
                    </Grid>
                )
            )}
        </Grid>
    );
};

export default WidgetSection;
