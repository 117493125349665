/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import GENERAL_APIS from './apis';
import { IArea, ICategory, ICompound, IDeveloper, IFilterOptions } from './interfaces';

export const getAllCompounds = createAsyncThunk(
    'general/get-compounds',
    async (
        requestData: {
            developerId?: string;
            language?: string;
        },
        { rejectWithValue }
    ): Promise<ICompound[] | any> => {
        const { developerId, language } = requestData;
        try {
            const successRes = await axios.get(GENERAL_APIS.getCompounds(developerId, language));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAllAreas = createAsyncThunk(
    'general/get-all-areas',
    async (requestData: { language?: string }, { rejectWithValue }): Promise<IArea[] | any> => {
        const { language } = requestData;
        try {
            const successRes = await axios.get(GENERAL_APIS.getAreas(language));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getPropertyTypes = createAsyncThunk(
    'general/get-property-types',
    async (requestData: { language?: string }, { rejectWithValue }): Promise<IArea[] | any> => {
        const { language } = requestData;
        try {
            const successRes = await axios.get(GENERAL_APIS.getPropertyTypes(language));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getAmenities = createAsyncThunk(
    'general/get-amenities',
    async (requestData: { language?: string }, { rejectWithValue }): Promise<IArea[] | any> => {
        const { language } = requestData;
        try {
            const successRes = await axios.get(GENERAL_APIS.getAmenities(language));
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getCategories = createAsyncThunk(
    'general/get-categories',
    async (requestData: { runSuccess?: () => void }, { rejectWithValue }): Promise<ICategory[] | any> => {
        const { runSuccess } = requestData;

        try {
            const successRes = await axios.get(GENERAL_APIS.getCategories());
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getFilterOptions = createAsyncThunk(
    'general/get-filter-options',
    async (requestData: { runSuccess?: () => void; language?: string }, { rejectWithValue }): Promise<IFilterOptions | any> => {
        const { runSuccess, language } = requestData;

        try {
            const successRes = await axios.get(GENERAL_APIS.getFilterOptions(language));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDevelopers = createAsyncThunk(
    'general/get-developers',
    async (requestData: { runSuccess?: () => void; language?: string }, { rejectWithValue }): Promise<IDeveloper[] | any> => {
        const { runSuccess, language } = requestData;

        try {
            const successRes = await axios.get(GENERAL_APIS.getDevelopers(language));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
