// material-ui
import { Badge, Divider, Grid, ListItemButton, ListItemText, Typography } from '@mui/material';
import { USER_NOTIFICATIONS_APIS, UserNotificationsResponse } from '.';
import { formatDistance } from 'date-fns';
import { enUS, arSA } from 'date-fns/locale';
import axios from 'utils/axios';
import useAuth from 'hooks/useAuth';
import useMixPanel from 'hooks/useMixPanel';
import { useIntl } from 'react-intl';

// ==============================|| NOTIFICATION LIST ITEM ||============================== //
const NotificationListItem = ({
    notification,
    handleClose,
    setError
}: {
    notification: UserNotificationsResponse;
    handleClose: (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => void;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { user } = useAuth();
    const { emitEvent } = useMixPanel();
    const intl = useIntl();

    const handleListItemClick = async (event: React.MouseEvent<HTMLDivElement>, route: string = '') => {
        handleClose(event);
        await readNotifications();

        if (route && route !== '') {
            window.location.href = route;
        }
    };

    const readNotifications = async () => {
        try {
            setError(false);
            await axios.patch(USER_NOTIFICATIONS_APIS.readNotification(user?.cognitoId as string), {
                createdAt: notification.createdAt
            });
        } catch (err) {
            emitEvent('Errors - notification', { title: 'readNotifications', location: 'notification bell list' });
            setError(true);
        }
    };

    return (
        <>
            <Divider />
            <ListItemButton
                sx={{ mt: '0 !important', minHeight: '85px' }}
                onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, notification.link)}
            >
                <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid xs={11} item>
                        <ListItemText
                            sx={{ wordWrap: 'break-word' }}
                            primary={<Typography color="textPrimary">{notification.body}</Typography>}
                            secondary={formatDistance(new Date(notification.createdAt), new Date(), {
                                includeSeconds: true,
                                locale: intl.locale === 'ar' ? arSA : enUS
                            })}
                        />
                    </Grid>
                    <Grid xs={1} item>
                        <Badge color="secondary" variant="dot" invisible={notification.isRead} />
                    </Grid>
                </Grid>
            </ListItemButton>
        </>
    );
};

export default NotificationListItem;
