// assets
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkAsUnreadTwoToneIcon from '@mui/icons-material/MarkAsUnreadTwoTone';
import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import LocalLibraryTwoToneIcon from '@mui/icons-material/LocalLibraryTwoTone';

const OrganizationsActivityItem: NavItemType = {
    id: 'activities',
    title: <FormattedMessage id="activities" />,
    type: 'item',
    url: '/activities',
    icon: LocalLibraryTwoToneIcon,
    breadcrumbs: false,
    allowedAudience: [{ name: InvitationTypeEnum.admins }, { name: InvitationTypeEnum.accountManagers }],
    mixpanelEvent: {
        tab_name: 'Activities'
    }
};

const ActivitiesItem: NavItemType = {
    id: 'organizations-side-menu-items-group',
    type: 'group',
    children: [OrganizationsActivityItem]
};

export default ActivitiesItem;
