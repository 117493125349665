import { CommonQueryInterface } from 'store/interfaces';

import { InvitationsLimitsInterface } from '../invitationsLimits/interfaces';
import { DocumentTypes } from '../organizations/interfaces';

export interface TiersInitialState {
    loading: boolean;
    error: any;
    refreshTier: boolean;
    listLoading: boolean;
    listError: any;
    refreshList: boolean;
    tiers: TiersListInterface;
    tier?: TierInterface;
}
export interface TierInterface {
    id: string;
    name: string;
    brokersLimit: number | null;
    cilsLimit: number | null;
    listingLimit: number | null;
    saleClaimsLimit: number | null;
    reservationLimit: number | null;
    leadsLimit: number | null;
    primaryCilsLimit: number | null;
    initialValidityTime: number;
    saleClaimValidityTime: number;
    cilValidityTime: number;
    leadValidityTime: number;
    invitationValidityTime: number;
    listingValidityTime: number;
    primaryCilValidityTime: number;
    eoiValidityTime: number;
    reservationValidityTime: number;
    onlyAdmin: boolean;
    termsEn?: string;
    termsAr?: string;
    invitationsLimit: InvitationsLimitsInterface[];
    viewInventory: boolean | null;
    cilMethodOfContact?: MethodOfContactEnum | null;
    leadsMethodOfContact?: MethodOfContactEnum | null;
    createdAt: string;
    updatedAt: string;
    requirements?: TierRequirementsEntity[];
    _count?: TierOrganizationsCount;
    view: TierViewsEnum;
}

export enum MethodOfContactEnum {
    ambassador = 'Ambassador App',
    broker = 'Broker App'
}

export enum TierViewsEnum {
    brokerView = 'BrokerView',
    ambassadorView = 'AmbassadorView'
}

interface TierOrganizationsCount {
    organizations: number;
}

export interface CreateTierInterface {
    name: string;
    brokersLimit: number;
    cilsLimit: number;
    listingLimit: number;
    saleClaimsLimit: number;
    reservationLimit: number;
    viewInventory: boolean;
    cilMethodOfContact: string;
    leadsMethodOfContact: string;
    onlyAdmin?: boolean;
    termsEn?: string;
    termsAr?: string;
}

export interface UpdateTierInterface extends Partial<CreateTierInterface> {
    initialValidityTime?: number;
    saleClaimValidityTime?: number;
    cilValidityTime?: number;
    leadValidityTime?: number;
    invitationValidityTime?: number;
    listingValidityTime?: number;
}

export interface TierRequirementsEntity {
    id: string;
    documentType: DocumentTypes;
    tierId: string;
}

export interface TierInfo {
    id: string;
    name: string;
}

export interface TiersListInterface {
    page: number;
    pageSize: number;
    pages: number;
    count: number;
    data: TierInterface[];
}

export interface QueryTiersInterface extends CommonQueryInterface {
    id?: string;
    name?: string;
    brokersLimit?: number;
    createdAtMin?: string;
    createdAtMax?: string;
    view?: TierViewsEnum;
}
