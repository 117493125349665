// types
import { createSlice } from '@reduxjs/toolkit';
import {
    addTierRequirement,
    createTier,
    createTierInvitationLimits,
    deleteTier,
    deleteTierInvitationLimits,
    getBrokerTier,
    getTierById,
    queryTiers,
    removeTierRequirement,
    updateTier,
    updateTierInvitationLimits
} from './actions';
import { TiersInitialState } from './interfaces';

// initial state
const initialState: TiersInitialState = {
    loading: false,
    listLoading: false,
    error: false,
    listError: false,
    refreshTier: false,
    refreshList: false,
    tiers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 1,
        data: []
    }
};

// ==============================|| SLICE - TIERS ||============================== //

const tiers = createSlice({
    name: 'tiers',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryTiers.pending, (state) => {
                state.listLoading = true;
                state.listError = false;
            })
            .addCase(queryTiers.fulfilled, (state, action) => {
                state.listLoading = false;
                state.tiers = action.payload;
            })
            .addCase(queryTiers.rejected, (state, action) => {
                state.listLoading = false;
                state.listError = action.payload;
            })
            .addCase(createTier.pending, (state) => {
                state.error = false;
            })
            .addCase(createTier.fulfilled, (state, action) => {
                state.tier = action.payload;
            })
            .addCase(createTier.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getTierById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getTierById.fulfilled, (state, action) => {
                state.loading = false;
                state.tier = action.payload;
            })
            .addCase(getTierById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getBrokerTier.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getBrokerTier.fulfilled, (state, action) => {
                state.loading = false;
                state.tier = action.payload;
            })
            .addCase(getBrokerTier.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateTier.pending, (state) => {
                state.error = false;
            })
            .addCase(updateTier.fulfilled, (state, action) => {
                state.tier = action.payload;
            })
            .addCase(updateTier.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(addTierRequirement.pending, (state) => {
                state.error = false;
            })
            .addCase(addTierRequirement.fulfilled, (state) => {
                state.refreshTier = !state.refreshTier;
            })
            .addCase(addTierRequirement.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(removeTierRequirement.pending, (state) => {
                state.error = false;
            })
            .addCase(removeTierRequirement.fulfilled, (state, action) => {
                state.refreshTier = !state.refreshTier;
            })
            .addCase(removeTierRequirement.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteTier.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteTier.fulfilled, (state, action) => {
                // state.tier = action.payload;
            })
            .addCase(deleteTier.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(createTierInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(createTierInvitationLimits.fulfilled, (state, action) => {
                if (state.tier?.invitationsLimit) state.tier.invitationsLimit.push(action.payload);
            })
            .addCase(createTierInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateTierInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(updateTierInvitationLimits.fulfilled, (state, action) => {
                let updatedIndex: number;
                if (state.tier?.invitationsLimit) {
                    updatedIndex = state.tier?.invitationsLimit.findIndex((limit) => limit.id.toString() === action.payload.id.toString());
                    if (updatedIndex?.toString() && updatedIndex !== -1) {
                        state.tier.invitationsLimit[updatedIndex] = action.payload;
                    } else state.tier.invitationsLimit.push(action.payload);
                }
            })
            .addCase(updateTierInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteTierInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteTierInvitationLimits.fulfilled, (state, action) => {
                let deletedIndex: number;
                if (state.tier?.invitationsLimit) {
                    deletedIndex = state.tier?.invitationsLimit.findIndex((limit) => limit.id.toString() === action.payload.id.toString());
                    if (deletedIndex?.toString() && deletedIndex !== -1) state.tier.invitationsLimit.splice(deletedIndex, 1);
                }
            })
            .addCase(deleteTierInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default tiers.reducer;

// export const {} = tiers.actions;
