// theme constant
export const gridSpacing = 3;
export const gridSpacingMedium = 2;
export const gridSpacingSmall = 1;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const requestDelay = 500;

export const saleClaimDialogWidth = 680;
export const saleClaimDialogHeight = 500;
