import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'utils/axios';

import FLOWS_APIS from '../flows/apis';
import { FlowStagesEnum, QueryFlowsInterface } from '../flows/interfaces';
import SALE_CLAIMS_APIS from './apis';
import {
    QueryBuyersInterface,
    QuerySaleClaimsInterface,
    SaleClaimDocumentTypes,
    SaleClaimStatus,
    UpdateSaleClaimAdminInterface,
    UpdateSaleClaimBuyerInterface,
    UpdateSaleClaimInterface,
    UpdateSaleClaimPropertyInterface
} from './interfaces';

export const querySaleClaim = createAsyncThunk(
    'saleClaims/query',
    async (
        requestData: {
            query: QuerySaleClaimsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALE_CLAIMS_APIS.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getSaleClaimById = createAsyncThunk(
    'saleClaims/get-by-id',
    async (
        requestData: {
            saleClaimId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALE_CLAIMS_APIS.getById(saleClaimId));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaim = createAsyncThunk(
    'saleClaims/updateSaleClaim',
    async (
        requestData: {
            saleClaimId: string;
            updateData: UpdateSaleClaimInterface;
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.update(saleClaimId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaimAdmin = createAsyncThunk(
    'saleClaims/update-admin',
    async (
        requestData: {
            saleClaimId: string;
            updateData: UpdateSaleClaimAdminInterface;
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.updateFull(saleClaimId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaimLinks = createAsyncThunk(
    'saleClaims/updateSaleClaimLinks',
    async (
        requestData: {
            saleClaimId: string;
            updateData: { reservationId?: number; saleClaimId?: string };
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.update(saleClaimId), updateData);
            if (runSuccess) {
                runSuccess();
            }
            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const getSaleClaimComments = createAsyncThunk(
    'saleClaims/get-comments',
    async (
        requestData: {
            saleClaimId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALE_CLAIMS_APIS.getComments(saleClaimId));

            if (runSuccess) runSuccess();
            return successRes.data.comments;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const createSaleClaimComment = createAsyncThunk(
    'saleClaims/create-comment',
    async (
        requestData: {
            saleClaimId: string;
            text: string;
            isPrivate: boolean;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, text, isPrivate, runSuccess } = requestData;
        try {
            const successRes = await axios.post(SALE_CLAIMS_APIS.createComment(saleClaimId), {
                text,
                isPrivate
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaimComment = createAsyncThunk(
    'saleClaims/update-comment',
    async (
        requestData: {
            saleClaimId: string;
            commentId: string;
            isPrivate?: boolean;
            text?: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, commentId, text, isPrivate, runSuccess } = requestData;
        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.updateComment(saleClaimId, commentId), {
                text,
                isPrivate
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteSaleClaimComment = createAsyncThunk(
    'saleClaims/delete-comment',
    async (
        requestData: {
            saleClaimId: string;
            commentId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, commentId, runSuccess } = requestData;

        try {
            const successRes = await axios.delete(SALE_CLAIMS_APIS.deleteComment(saleClaimId, commentId));

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const queryBuyers = createAsyncThunk(
    'saleClaims/queryBuyers',
    async (
        requestData: {
            query: QueryBuyersInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALE_CLAIMS_APIS.queryBuyers(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaimBuyer = createAsyncThunk(
    'saleClaims/updateSaleClaimBuyer',
    async (
        requestData: {
            saleClaimId: string;
            updateData: UpdateSaleClaimBuyerInterface;
            runSuccess: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;

        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.updateSaleClaimBuyer(saleClaimId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleClaimProperty = createAsyncThunk(
    'saleClaims/updateSaleClaimProperty',
    async (
        requestData: { saleClaimId: string; updateData: UpdateSaleClaimPropertyInterface; runSuccess: () => void },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;

        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.update(saleClaimId), updateData);

            if (runSuccess) runSuccess();

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

/**
 * @deprecated
 */
export const approveSaleClaim = createAsyncThunk(
    'saleClaims/approveSaleClaim',
    async (
        requestData: {
            saleClaimId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, runSuccess } = requestData;

        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.approveSaleClaim(saleClaimId));

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

/**
 * @deprecated
 */
export const rejectSaleClaim = createAsyncThunk(
    'saleClaims/rejectSaleClaim',
    async (
        requestData: {
            saleClaimId: string;
            runSuccess?: () => void;
            rejectionReason: string;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, runSuccess, rejectionReason } = requestData;

        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.rejectSaleClaim(saleClaimId), { rejectionReason });

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);
export const updateSaleClaimStatus = createAsyncThunk(
    'saleClaims/update-status',
    async (
        requestData: {
            saleClaimId: string;
            runSuccess?: () => void;
            body: {
                status: SaleClaimStatus;
                rejectionReason?: string;
            };
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, runSuccess, body } = requestData;

        try {
            // TODO
            // if (body?.status === SaleClaimStatus.rejected && !body.rejectionReason) {
            //     // throw validation error

            // }
            const successRes = await axios.patch(SALE_CLAIMS_APIS.updateStatus(saleClaimId), body);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const updateSaleCommissionType = createAsyncThunk(
    'saleClaims/updateCommissionType',
    async (
        requestData: {
            saleClaimId: string;
            updateData: { type: string };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, updateData, runSuccess } = requestData;

        try {
            const successRes = await axios.patch(SALE_CLAIMS_APIS.updateCommissionType(saleClaimId), updateData);

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const uploadSaleClaimDocument = createAsyncThunk(
    'saleClaims/uploadSaleClaimDocuments',
    async (
        requestData: {
            saleClaimId: string;
            document: {
                name: string;
                type: SaleClaimDocumentTypes;
                document: any;
            };
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { saleClaimId, document, runSuccess } = requestData;

        const formData = new FormData();
        formData.append('name', document.name);
        formData.append('type', document.type);
        formData.append('document', document.document);
        try {
            const successRes = await axios.post(SALE_CLAIMS_APIS.uploadSaleClaimDocument(saleClaimId), formData, {
                headers: { 'Content-Type': `multipart/form-data;` }
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deleteSaleClaimDocument = createAsyncThunk(
    'saleClaims/deleteSaleClaimDocument',
    async (
        requestData: {
            saleClaimId: string;
            documentId: string;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { saleClaimId, documentId, runSuccess } = requestData;

        try {
            const successRes = await axios.delete(SALE_CLAIMS_APIS.deleteSaleClaimDocument(saleClaimId, documentId));

            if (runSuccess) {
                runSuccess();
            }

            return successRes.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// export const getSaleClaimsAnalyticsOld = createAsyncThunk(
//     'sale-claims/analytica',
//     async (
//         requestData: {
//             query: QuerySaleClaimsInterface;
//             runSuccess?: () => void;
//         },
//         { rejectWithValue }
//     ): Promise<any> => {
//         const { query, runSuccess } = requestData;

//         try {
//             const successRes = await axios.get(SALE_CLAIMS_APIS.analytica(query));
//             if (runSuccess) runSuccess();
//             return successRes.data;
//         } catch (error: any) {
//             return rejectWithValue(error);
//         }
//     }
// );
export const getSaleClaimsAnalytics = createAsyncThunk(
    'sale-claims/analytica',
    async (
        requestData: {
            query: QueryFlowsInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ): Promise<any> => {
        const { query, runSuccess } = requestData;

        try {
            const successRes = await axios.get(FLOWS_APIS.queryAnalyticaFull({ ...query, stage: FlowStagesEnum.SaleClaim }));
            if (runSuccess) runSuccess();

            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
