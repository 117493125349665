import { Link } from 'react-router-dom';

// material-ui
import { Grid } from '@mui/material';
// project imports
import LoginRightPanelV2 from 'components/Auth/UI/LoginRightPanelV2';
import Logo from 'ui-component/Logo';
import AuthCardWrapper from '../../../components/Auth/UI/AuthCardWrapper';
import AuthWrapper from '../../../components/Auth/UI/AuthWrapper';

const LoginV2 = () => (
    <AuthWrapper>
        <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                    <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                        <AuthCardWrapper>
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item sx={{ mb: 3 }}>
                                    <Link to="#">
                                        <Logo />
                                    </Link>
                                </Grid>

                                <Grid item xs={12}>
                                    <LoginRightPanelV2 />
                                </Grid>
                            </Grid>
                        </AuthCardWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </AuthWrapper>
);

export default LoginV2;
