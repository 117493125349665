import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { InvitationTypeEnum } from 'store/interfaces';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
interface AllowedAudienceGuardProps extends GuardProps {
    groups: string[];
}

const AllowedAudienceGuard = ({ groups, children }: AllowedAudienceGuardProps) => {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    groups.push(InvitationTypeEnum.admins);
    useEffect(() => {
        if (!isLoggedIn) {
            navigate(`../auth?redirectTo=${window.location.pathname}`, { replace: true });
        }
        // if (!isLoggedIn) navigate(`../auth`, { replace: true });
        else if (!user?.groups?.some((group) => groups?.includes(group))) {
            navigate('/home', { replace: true });
        }
    }, [groups, isLoggedIn, navigate, user]);

    return children;
};

export default AllowedAudienceGuard;
