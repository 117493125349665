import useAuth from 'hooks/useAuth';
import { InvitationTypeEnum } from 'store/interfaces';
import { AccountManagersRole } from 'store/slices/accountManagers/interfaces';
import { OrganizationRole } from 'store/slices/brokers/interfaces';
import { GuardProps } from 'types';

interface ComponentRoleGuardProps extends GuardProps {
    allowedAudience: (OrganizationRole | AccountManagersRole)[];
    allowCurrentUserIfId?: string;
}

const ComponentRoleGuard = ({ allowedAudience, allowCurrentUserIfId, children }: ComponentRoleGuardProps) => {
    const { user } = useAuth();
    if (allowCurrentUserIfId && allowCurrentUserIfId === user?.cognitoId) return children;
    const allowedAdmin = user?.groups?.includes(InvitationTypeEnum.admins);
    const allowedManager = user?.accountManagerRole ? allowedAudience.includes(user.accountManagerRole) : false;
    const allowedBroker = user?.brokerAuth?.role ? allowedAudience.includes(user.brokerAuth.role) : false;
    if (allowedAdmin || allowedManager || allowedBroker) return children;
    return null;
};

export default ComponentRoleGuard;
