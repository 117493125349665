import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router';

// Pages
const AccountManagersPage = Loadable(lazy(() => import('views/users/AccountManagers')));
const BrokersPage = Loadable(lazy(() => import('views/users/Brokers')));

// ==============================|| Account Manager Routes GUARD ROUTING ||============================== //

const AccountManagerRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.accountManagers }]}>
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: '/sales-agents',
            element: <BrokersPage />
        },
        {
            path: '/account-managers',
            element: <AccountManagersPage />
        }
    ]
};

export default AccountManagerRoutes;
