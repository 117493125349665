import { FormattedMessage } from 'react-intl';
import { InvitationTypeEnum } from 'store/interfaces';
import { NavItemType } from 'types';
import PersonIcon from '@mui/icons-material/Person';
import BurstModeOutlinedIcon from '@mui/icons-material/BurstModeOutlined';
import { CircleEllipsis } from 'lucide-react';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import ContactsTwoToneIcon from '@mui/icons-material/ContactsTwoTone';

const bannerItem: NavItemType = {
    id: 'banners',
    title: <FormattedMessage id="banners" />,
    type: 'item',
    url: '/banners',
    icon: BurstModeOutlinedIcon,
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    mixpanelEvent: {
        tab_name: 'Banners'
    }
};

const CrmControlItem: NavItemType = {
    id: 'crms',
    title: <FormattedMessage id="crm-control" />,
    type: 'item',
    url: '/crms',
    icon: PersonIcon,
    allowedAudience: [{ name: InvitationTypeEnum.admins }]
};

const inventoryControlItem: NavItemType = {
    id: 'inventory-control',
    title: <FormattedMessage id="inventory-control" />,
    type: 'item',
    url: '/inventory/control',
    icon: AutoFixHighTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Inventory Control'
    }
};

const leadRequestItems: NavItemType = {
    id: 'requests',
    title: <FormattedMessage id="lead-request" />,
    type: 'item',
    url: '/leads/requests',
    icon: ContactsTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Lead Requests'
    },
    allowedAudience: [{ name: InvitationTypeEnum.admins }]
};

const leadRequestItemsInternalAgents: NavItemType = {
    id: 'requests',
    title: <FormattedMessage id="lead-request" />,
    type: 'item',
    url: '/leads/requests',
    icon: ContactsTwoToneIcon,
    breadcrumbs: false,
    mixpanelEvent: {
        tab_name: 'Lead Requests'
    },
    allowedAudience: [{ name: InvitationTypeEnum.internalAgents }]
};

const leadRequestItemsInternalAgentsGroup: NavItemType = {
    id: 'internal-agent-leads',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.internalAgents }],
    children: [leadRequestItemsInternalAgents]
};

const moreCollapse: NavItemType = {
    id: 'reports-items-group',
    type: 'group',
    allowedAudience: [{ name: InvitationTypeEnum.admins }],
    children: [
        {
            id: 'reports',
            title: <FormattedMessage id="more" />,
            type: 'collapse',
            icon: CircleEllipsis,
            breadcrumbs: false,
            children: [bannerItem, CrmControlItem, inventoryControlItem, leadRequestItems]
        }
    ]
};

export { leadRequestItemsInternalAgentsGroup };
export default moreCollapse;
