import SpeedIcon from '@mui/icons-material/Speed';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { gridSpacing } from 'store/constant';
import { DepartmentRole, InvitationTypeEnum } from 'store/interfaces';
import AccountManagersFilter from 'ui-component/Filters/AccountManagersFilter';
import SubCard from 'ui-component/cards/SubCard';
import RoleGuard from 'utils/RoleGuard';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Chip from 'ui-component/extended/Chip';
import { Stack } from '@mui/system';
import NumericFormatCustom from 'ui-component/MoneyInput';
import { LoadingButton } from '@mui/lab';
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import CloseIcon from '@mui/icons-material/Close';
import monthStartAndEndDate from 'components/AccountManagers/Utils/date';
import { setAccountManagersTarget } from 'store/slices/accountManagersTargets/actions';
import useOverlay from 'hooks/useOverlay';
import { UploadCloudIcon } from 'lucide-react';
import { utils, writeFile } from 'xlsx';
import * as XLSX from 'xlsx';

interface SetAccountManagerTargetsProps {
    showTrigger?: boolean;
    defaultSelectedAms?: {
        name: string;
        cognitoId: string;
    }[];
}

interface SheetRow {
    accountManagerId: string;
    from: string;
    to: string;
    targetNumber: number;
    sellingBrokerNumber: number;
}

const SetAccountManagerTargets = ({ showTrigger = false, defaultSelectedAms = [] }: SetAccountManagerTargetsProps) => {
    const intl = useIntl();
    const theme = useTheme();
    const { error, accountManagers } = useSelector((state) => state.accountManagerTargets);
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    // eslint-disable-next-line
    const [openTarget, setOpenTarget] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);

    const {
        isCurrentOpened,
        open,
        close,
        state: { payload }
    } = useOverlay<SetAccountManagerTargetsProps>('SetAccountManagerTargets');

    const runSuccess = () => {
        dispatch(
            openSnackbar({
                open: true,
                message: intl.formatMessage({ id: 'target-set-success' }),
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        handleClose();
    };
    const SetTargetsSchema = yup.object().shape({
        value: yup.number(),
        organizationsValue: yup.number(),
        date: yup.date().required(`${intl.formatMessage({ id: 'date' })} ${intl.formatMessage({ id: 'is-required' })}`),
        accountManagers: yup
            .array()
            .of(
                yup.object().shape({
                    name: yup.string(),
                    cognitoId: yup.string(),
                    role: yup.mixed<DepartmentRole>()
                })
            )
            .min(1, `${intl.formatMessage({ id: 'account-manager' })} ${intl.formatMessage({ id: 'is-required' })}`)
    });

    const handleClickOpen = () => {
        formik.resetForm();
        setOpenTarget(true);
        open();
    };

    const handleClose = () => {
        formik.resetForm();
        setOpenTarget(false);
        close();
    };

    const handleUploadClickOpen = () => {
        setOpenUpload(true);
    };

    const handleUploadClose = () => {
        setOpenUpload(false);
        setFile(null);
        setFileName('');
        setFailedRecords([]);
        setLoading(false);

        const fileInput = document.getElementById('contained-button-file') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const handleDownloadTemplate = () => {
        const accountManagerData = accountManagers.data.map((am) => ({
            accountManagerId: am.cognitoId,
            name: am.name,
            from: '2025-05-01T00:00:00.000Z',
            to: '2025-05-31T00:00:00.000Z',
            targetNumber: 0,
            sellingBrokerNumber: 0
        }));

        const worksheet = utils.json_to_sheet(accountManagerData || []);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'account-managers-target');
        writeFile(workbook, `account-managers-target-${intl.formatDate(new Date(), { dateStyle: 'medium' })}.xlsx`);
        handleClose();
    };

    const initialValues: {
        value: number | undefined;
        organizationsValue: number | undefined;
        accountManagers: { name: string; cognitoId: string }[];
        date: string | null;
    } = {
        value: undefined,
        organizationsValue: undefined,
        accountManagers: [...(payload?.defaultSelectedAms ?? defaultSelectedAms)],
        date: null
    };

    const formik = useFormik({
        initialValues,
        onSubmit(values, formikHelpers) {
            const { startDate, endDate } = monthStartAndEndDate(values.date);
            dispatch(
                setAccountManagersTarget({
                    body: {
                        value: values.value || 0,
                        organizationsValue: values.organizationsValue || 0,
                        from: startDate,
                        to: endDate,
                        accountManagersIds: values.accountManagers.map((am) => am.cognitoId)
                    },
                    runSuccess
                })
            );
        },
        validationSchema: SetTargetsSchema,
        enableReinitialize: true
    });

    useEffect(() => {
        if (error?.validationErrors) formik.setErrors(error?.validationErrors);
        formik.setSubmitting(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const isSubmitDisabled = !formik.values.value && !formik.values.organizationsValue;

    const [failedRecords, setFailedRecords] = useState<SheetRow[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [fileName, setFileName] = useState<string | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);
            setFileName(selectedFile.name);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            return;
        }

        setLoading(true);

        try {
            const reader = new FileReader();

            reader.onerror = () => {
                setLoading(false);
            };

            reader.onload = async () => {
                if (!(reader.result instanceof ArrayBuffer)) {
                    setLoading(false);
                    return;
                }

                const data = new Uint8Array(reader.result);

                try {
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];

                    const jsonData: SheetRow[] = XLSX.utils.sheet_to_json(sheet);

                    const promises = jsonData.map(async (row) => {
                        const { accountManagerId, from, to, targetNumber, sellingBrokerNumber } = row;

                        if (!targetNumber && !sellingBrokerNumber) {
                            return null;
                        }

                        try {
                            const result = await dispatch(
                                setAccountManagersTarget({
                                    body: {
                                        value: targetNumber || 0,
                                        organizationsValue: sellingBrokerNumber || 0,
                                        from: from || '',
                                        to: to || '',
                                        accountManagersIds: [accountManagerId]
                                    },
                                    runSuccess
                                })
                            );

                            if (result.meta.requestStatus !== 'fulfilled') {
                                return row;
                            }

                            return null;
                        } catch (apiError) {
                            return row;
                        }
                    });

                    const failed = (await Promise.all(promises)).filter((result) => result !== null);

                    if (failed.length > 0) {
                        setFailedRecords(failed as SheetRow[]);
                    }
                } catch (parseError) {
                    console.error('Error parsing the Excel file:', parseError);
                } finally {
                    setLoading(false);
                }
            };

            reader.readAsArrayBuffer(file);
        } catch (uploadError) {
            setLoading(false);
        }
    };

    return (
        <RoleGuard groups={[{ name: InvitationTypeEnum.admins }]}>
            {showTrigger && (
                <Button
                    variant="outlined"
                    onClick={handleUploadClickOpen}
                    startIcon={<UploadCloudIcon />}
                    sx={{ marginRight: '10px', marginLeft: '10px' }}
                >
                    <FormattedMessage id="upload-targets" />
                </Button>
            )}

            <Dialog
                open={openUpload}
                onClose={handleUploadClose}
                scroll="paper"
                maxWidth="md"
                fullWidth
                aria-labelledby="upload-file-dialog"
            >
                <DialogTitle id="upload-file-dialog">
                    <Typography variant="h3">
                        <FormattedMessage id="upload-targets" />
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container py={4} spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ textDecoration: 'underline' }}>
                                Sheet Format :
                            </Typography>
                            <Typography>
                                <strong>from : </strong> 2025-05-01T00:00:00.000Z
                            </Typography>
                            <Typography>
                                <strong>to :</strong> 2025-05-31T00:00:00.000Z
                            </Typography>
                            <Typography>
                                <strong>targetNumber :</strong> 3000000
                            </Typography>
                            <Typography>
                                <strong>sellingBrokerNumber :</strong> 10
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={handleDownloadTemplate} color="primary" fullWidth>
                                <FormattedMessage id="download-sample-template" />
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                <label htmlFor="contained-button-file" style={{ display: 'block', width: '100%' }}>
                                    <Input id="contained-button-file" type="file" onChange={handleFileChange} className="visually-hidden" />
                                    <Button color="primary" component="span" fullWidth size="small">
                                        <FormattedMessage id="upload-excel-sheet" />
                                    </Button>
                                </label>
                                {fileName && <Typography variant="body2">{fileName}</Typography>} {/* Display the file name */}
                            </FormControl>
                        </Grid>
                        {failedRecords.length > 0 && (
                            <Grid item xs={12}>
                                <Typography variant="h6" color="error">
                                    <FormattedMessage id="failed-records" />
                                </Typography>
                                {failedRecords.map((record) => (
                                    <Chip
                                        key={record.accountManagerId}
                                        label={`Failed Account Manager ID: ${record.accountManagerId}`}
                                        color="error"
                                    />
                                ))}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadClose} size="small" color="error">
                        <FormattedMessage id="cancel" />
                    </Button>
                    <LoadingButton onClick={handleUpload} loading={loading} size="small" variant="contained" disabled={!file}>
                        <FormattedMessage id="upload" />
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            {showTrigger && (
                <Button variant="contained" onClick={handleClickOpen} startIcon={<SpeedIcon />}>
                    <FormattedMessage id="set-target" />
                </Button>
            )}

            <Drawer
                anchor={matchDownSM ? 'bottom' : 'right'}
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isCurrentOpened}
            >
                <Grid
                    container
                    spacing={gridSpacing}
                    padding={3}
                    flexWrap="nowrap"
                    paddingRight={{ xs: 0, md: 3 }}
                    direction="column"
                    maxWidth={{ xs: '100vw', md: '30vw' }}
                    height={{ xs: '80vh', md: '100vh' }}
                    maxHeight={{ xs: '80vh', md: '100vh' }}
                >
                    <Grid container alignItems="center" justifyContent="space-between" marginTop={2} marginLeft={2}>
                        <Grid item>
                            <Typography variant="h3">
                                <FormattedMessage id="set-target" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton aria-label="close" onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <AccountManagersFilter
                            value=""
                            handleSearch={() => {}}
                            handleSelectedAccountManager={(am) => {
                                if (am && !formik.values.accountManagers.find((exAm) => exAm.cognitoId === am.cognitoId)) {
                                    formik.setFieldValue('accountManagers', [
                                        ...formik.values.accountManagers,
                                        { name: am.user.name, cognitoId: am.cognitoId, role: am.role }
                                    ]);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bold">
                            {formik.values.accountManagers.length} <FormattedMessage id="selected-account-managers" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item flexGrow={1}>
                        <Stack overflow="auto" spacing={gridSpacing} maxHeight={140}>
                            {formik.values.accountManagers.map((selectedAm, i) => (
                                <div key={selectedAm.cognitoId}>
                                    <Chip
                                        key={selectedAm.cognitoId}
                                        label={selectedAm.name}
                                        onDelete={() => {
                                            const list = formik.values.accountManagers;
                                            list.splice(i, 1);
                                            formik.setFieldValue('accountManagers', list);
                                        }}
                                    />
                                </div>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Divider />
                    </Grid>
                    <Grid item>
                        <SubCard
                            sx={{
                                bgcolor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
                            }}
                        >
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={8}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            disablePast
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="date"
                                                    variant="standard"
                                                    onBlur={formik.handleBlur}
                                                    error={Boolean(formik.touched.date && formik.errors.date)}
                                                    helperText={formik.touched.date && formik.errors.date && 'Date is required'}
                                                />
                                            )}
                                            value={formik.values.date}
                                            onChange={(newValue: Date | null) => {
                                                if (newValue && newValue?.getDate()) formik.setFieldValue('date', newValue.toISOString());
                                                else formik.setFieldValue('date', null);
                                            }}
                                            openTo="month"
                                            label={intl.formatMessage({ id: 'month' })}
                                            views={['month', 'year']}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'target' })}
                                        name="value"
                                        id="set-am-target-form-am-value"
                                        value={formik.values.value}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FormattedMessage id="egp" />
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.value && formik.errors.value)}
                                        helperText={formik.touched.value && formik.errors.value}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        label={intl.formatMessage({ id: 'selling-brokers' })}
                                        name="organizationsValue"
                                        id="set-am-organization-target-form-am-value"
                                        value={formik.values.organizationsValue}
                                        InputProps={{
                                            inputComponent: NumericFormatCustom as any
                                        }}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={Boolean(formik.touched.organizationsValue && formik.errors.organizationsValue)}
                                        helperText={formik.touched.organizationsValue && formik.errors.organizationsValue}
                                    />
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                    <Grid item>
                        <Grid container spacing={gridSpacing} justifyContent="flex-end">
                            <Grid item>
                                <Button disabled={formik.isSubmitting} onClick={handleClose}>
                                    <FormattedMessage id="cancel" />
                                </Button>
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    onClick={formik.submitForm}
                                    loading={formik.isSubmitting}
                                    variant="contained"
                                    disabled={isSubmitDisabled}
                                >
                                    <FormattedMessage id="confirm" />
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Drawer>
        </RoleGuard>
    );
};

export default SetAccountManagerTargets;
