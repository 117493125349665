// material ui
import { DialogContent, DialogContentText, Grid } from '@mui/material';
// third party libraries
import { useFormik } from 'formik';
import * as yup from 'yup';
// actions
import { FormattedMessage, useIntl } from 'react-intl';

// hooks
import { useDispatch, useSelector } from 'store';
// constants
import { saleClaimDialogHeight } from 'store/constant';
import ImageUploadDragAndDrop from 'ui-component/ImageUpload';

// interface
import { CreateSaleClaimSectionProps } from '.';
import StepperDialogActions from './StepperDialogActions';
import useMixPanel from 'hooks/useMixPanel';
import { setDocuments } from 'store/slices/createSaleClaim';
import { SaleClaimDocumentTypes } from 'store/slices/saleClaims/interfaces';

const DocumentsSection = ({ handleNext, handleBack }: CreateSaleClaimSectionProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const { documents } = useSelector((state) => state.createSaleClaimSlice);
    const contractDocuments = documents?.filter((d) => d.name.split('-')[0] === SaleClaimDocumentTypes.DeveloperContract);
    const otherDocuments = documents?.filter((d) => d.name.split('-')[0] !== SaleClaimDocumentTypes.DeveloperContract);

    const changeFileName = (file: File, name: string) => {
        const blob = file.slice(0, file.size, file.type);
        return new File([blob], name, { type: file.type });
    };

    const SetDocumentsSchema = yup.object().shape({
        contractDocuments: yup
            .array()
            .required(`${intl.formatMessage({ id: 'signed-contract' })} ${intl.formatMessage({ id: 'is-required' })}`),
        otherDocuments: yup.array()
    });

    const handleDeleteImage = (name: string) => {
        if (name.split('-')[0] === SaleClaimDocumentTypes.DeveloperContract) {
            const newDocuments = formik.values.contractDocuments.filter((file: File) => file.name !== name);
            formik.setFieldValue(`contractDocuments`, newDocuments);
        } else {
            const newDocuments = formik.values.otherDocuments.filter((file: File) => file.name !== name);
            formik.setFieldValue(`otherDocuments`, newDocuments);
        }
    };

    const formik = useFormik({
        initialValues: {
            contractDocuments: contractDocuments || [],
            otherDocuments: otherDocuments || []
        },
        onSubmit(values) {
            dispatch(setDocuments({ documents: [...values.contractDocuments, ...values.otherDocuments] }));
            handleNext();
        },
        validationSchema: SetDocumentsSchema,
        enableReinitialize: true
    });

    const handleAddContractImages = (files: FileList) => {
        const newFiles: File[] = [];
        for (const file of files) newFiles.push(changeFileName(file, `${SaleClaimDocumentTypes.DeveloperContract}-${file.name}`));
        const newDocuments = [...formik.values.contractDocuments, ...newFiles];
        formik.setFieldValue(`contractDocuments`, newDocuments);
    };

    const handleAddOtherDocumentImages = (files: FileList) => {
        const newFiles: File[] = [];
        for (const file of files) newFiles.push(changeFileName(file, file.name));
        const newDocuments = [...formik.values.contractDocuments, ...newFiles];
        formik.setFieldValue(`contractDocuments`, newDocuments);
    };

    const { emitEvent } = useMixPanel();

    return (
        <DialogContent
            sx={{
                height: saleClaimDialogHeight
            }}
        >
            <Grid container>
                <Grid item>
                    <DialogContentText variant="h4" color="black">
                        <FormattedMessage id="documents" />
                    </DialogContentText>
                </Grid>
                <Grid item container padding={1}>
                    <Grid item xs={12}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DialogContentText variant="h4">
                                        <FormattedMessage id="signed-contract" />
                                    </DialogContentText>
                                </Grid>

                                <Grid item xs={12}>
                                    <ImageUploadDragAndDrop
                                        id="contract"
                                        documents={formik.values.contractDocuments}
                                        handleAddImages={handleAddContractImages}
                                        handleDeleteImage={handleDeleteImage}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Grid>
                    <Grid item xs={12}>
                        <DialogContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DialogContentText variant="h4">
                                        <FormattedMessage id="other-documents" /> (<FormattedMessage id="optional" />)
                                    </DialogContentText>
                                </Grid>

                                <Grid item xs={12}>
                                    <ImageUploadDragAndDrop
                                        id="other"
                                        documents={formik.values.otherDocuments}
                                        handleAddImages={handleAddOtherDocumentImages}
                                        handleDeleteImage={handleDeleteImage}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Grid>

                    <Grid item xs={12}>
                        <StepperDialogActions
                            handleBack={handleBack}
                            handleNext={() => {
                                emitEvent('Click 3rd Next Sale Claim Creation', { ...formik.values, error: Boolean(formik.errors) });
                                formik.submitForm();
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </DialogContent>
    );
};

export default DocumentsSection;
