import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import { InvitationTypeEnum } from 'store/interfaces';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import RoleGuard from 'utils/RoleGuard';
import { Navigate } from 'react-router';

// Admin routing
const TiersPage = Loadable(lazy(() => import('views/settings/Tiers')));
const TierDetailsPage = Loadable(lazy(() => import('views/settings/Tier')));
const EventsPage = Loadable(lazy(() => import('views/settings/Events')));
const EventDetailsPage = Loadable(lazy(() => import('views/settings/Event')));
const InvitationTypesPage = Loadable(lazy(() => import('views/settings/Types')));
const OrganizationActivitiesConfigPage = Loadable(lazy(() => import('views/settings/Activities')));
const Settings = Loadable(lazy(() => import('views/settings/Groups')));
const RejectionReasonsPage = Loadable(lazy(() => import('views/settings/ListingsRejectionReasons')));
const AccountManagersTeams = Loadable(lazy(() => import('views/settings/Teams')));
// ==============================|| Settings ROUTING ||============================== //

const SettingsRoutes = {
    path: '/settings',
    element: (
        <AuthGuard>
            <RoleGuard ifNotAllowed={<Navigate to="/home" replace />} groups={[{ name: InvitationTypeEnum.admins }]}>
                <MainLayout />
            </RoleGuard>
        </AuthGuard>
    ),
    children: [
        {
            path: 'tiers',
            element: <TiersPage />
        },
        {
            path: 'tiers/:id/details',
            element: <TierDetailsPage />
        },
        {
            path: 'events',
            element: <EventsPage />
        },
        {
            path: 'events/:id',
            element: <EventDetailsPage />
        },
        {
            path: 'groups',
            element: <Settings />
        },
        {
            path: 'types',
            element: <InvitationTypesPage />
        },
        {
            path: 'rejection-reasons',
            element: <RejectionReasonsPage />
        },
        {
            path: 'teams/account-managers',
            element: <AccountManagersTeams />
        },

        {
            path: 'activities',
            element: <OrganizationActivitiesConfigPage />
        }
    ]
};

export default SettingsRoutes;
