import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { InvitationTypeEnum } from 'store/interfaces';
import RoleGuard from 'utils/RoleGuard';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { Navigate } from 'react-router';

// Pages

const BannersPage = Loadable(lazy(() => import('views/banners')));

// ==============================|| Banners Routes GUARD ROUTING ||============================== //

const ActivitiesRoutes = {
    path: '/banners',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '',
            element: (
                <RoleGuard
                    ifNotAllowed={<Navigate to="/home" replace />}
                    groups={[{ name: InvitationTypeEnum.accountManagers }, { name: InvitationTypeEnum.admins }]}
                >
                    <BannersPage />
                </RoleGuard>
            )
        }
    ]
};

export default ActivitiesRoutes;
