import { createSlice } from '@reduxjs/toolkit';
import {
    sirensBatchInvitation,
    checkUser,
    createInvitation,
    deleteInvitation,
    queryInvitationsAdmin,
    queryInvitationsUser,
    resendInvitation,
    resendInvitationHourly
} from './actions';
import { InvitationInitialStateInterface } from './interfaces';

// initial state
const initialState: InvitationInitialStateInterface = {
    loading: false,
    error: false,
    errorList: false,
    loadingList: false,
    refresh: false,
    invitations: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    userState: 'initial',
    message: ''
};

// ==============================|| SLICE -invitations ||============================== //

const invitations = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        resetUserState: (state) => {
            state.error = initialState.error;
            state.loading = initialState.loading;
            state.userState = initialState.userState;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(queryInvitationsUser.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryInvitationsUser.fulfilled, (state, action) => {
                state.loadingList = false;
                state.invitations = action.payload;
            })
            .addCase(queryInvitationsUser.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(queryInvitationsAdmin.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryInvitationsAdmin.fulfilled, (state, action) => {
                state.loadingList = false;
                state.invitations = action.payload;
            })
            .addCase(queryInvitationsAdmin.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })

            .addCase(sirensBatchInvitation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(sirensBatchInvitation.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(sirensBatchInvitation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(createInvitation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createInvitation.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(createInvitation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(deleteInvitation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteInvitation.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteInvitation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resendInvitation.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(resendInvitation.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(resendInvitation.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(checkUser.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(checkUser.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.userState = action.payload;
            })
            .addCase(checkUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resendInvitationHourly.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(resendInvitationHourly.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.error = false;
                state.message = action.payload.message;
            })
            .addCase(resendInvitationHourly.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.message = '';
            })
            .addDefaultCase((state, action) => {});
    }
});

export default invitations.reducer;

export const { resetUserState } = invitations.actions;
