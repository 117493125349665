import { queryData, WEBSITE_API } from 'store/apis';
import { InventoryQueryInterface } from '../inventoryTable/interfaces';

const NawyInventoryAPIs = {
    getCompoundDetails: (query: InventoryQueryInterface) => {
        const formattedQuery: string[] = [WEBSITE_API, 'compounds/search_with_details', '?'];

        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i]) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formattedQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });

        return formattedQuery.join('');
    },
    getPropertyTypesDetails: (compound_id: number, query: InventoryQueryInterface) => {
        const formattedQuery: string[] = [WEBSITE_API, `compounds/${compound_id}/property_types/search_with_details`, '?'];

        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i]) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formattedQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });
        return formattedQuery.join('');
    },
    getProperties: (compound_id: number, property_type_id: number, query: InventoryQueryInterface) => {
        const formattedQuery: string[] = [
            WEBSITE_API,
            `compounds/${compound_id}/property_types/${property_type_id}/properties/search_with_details`,
            '?'
        ];

        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i]) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formattedQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });
        return formattedQuery.join('');
    },
    getPropertyDetails: (property_id: number, query: InventoryQueryInterface) => {
        const formattedQuery: string[] = [
            WEBSITE_API,
            `properties/${property_id}/detailed_properties/search_with_details`,
            '?order_by=installments&'
        ];

        const queryValues = Object.values(query);
        Object.keys(query).forEach((queryKey, i) => {
            if (queryValues[i]) {
                let value = queryValues[i];
                if (Array.isArray(value)) {
                    value = value.map((val) => `array${queryKey}[]=${val}`).join('&');
                }

                formattedQuery.push(queryData(queryKey, value), i === queryValues.length - 1 ? '' : '&');
            }
        });
        return formattedQuery.join('');
    }
};

export default NawyInventoryAPIs;
