import { createSlice } from '@reduxjs/toolkit';

import {
    addAreaToOrganization,
    addOrganizationDevelopersRelations,
    assignAccountManager,
    cancelOrganizationBrokerInvitation,
    getDocumentsByType,
    createOrganization,
    createOrganizationContactInfo,
    createOrganizationInvitationLimits,
    createOrganizationLocation,
    createOrganizationPaymentInfo,
    deleteOrganization,
    deleteOrganizationBroker,
    deleteOrganizationContactInfo,
    deleteOrganizationDocuments,
    deleteOrganizationInvitationLimits,
    deleteOrganizationLocation,
    deleteOrganizationPaymentInfo,
    editDocumentsState,
    getOrganizationById,
    getOrganizationLeaderboard,
    getOrganizationMegaCompetitionSales,
    inviteOrganizationBroker,
    moveBrokerFromOrganization,
    queryOrganizationBrokers,
    queryOrganizationDocuments,
    queryOrganizations,
    removeAreaFromOrganization,
    removeOrganizationDevelopersRelations,
    softDeleteOrganizationBroker,
    subscribeToTier,
    unassignAccountManager,
    unsubscribeFromTier,
    updateDocuments,
    updateOrganizationBrokerRole,
    updateOrganizationInfoById,
    updateOrganizationInvitationLimits,
    updateOrganizationLimitsById,
    updateOrganizationPaymentInfo,
    uploadOrganizationDocuments
} from './actions';
import { OrganizationsInitialState } from './interfaces';

// initial state
const initialState: OrganizationsInitialState = {
    loading: false,
    error: false,
    loadingList: false,
    errorList: false,
    refresh: false,
    organizations: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    uploadDocuments: {
        commercialRegister: [],
        taxCard: [],
        nationalId: [],
        contract: []
    },
    brokersListError: false,
    brokersListLoading: false,
    refreshBrokers: false,
    brokers: {
        page: 1,
        pageSize: 10,
        pages: 1,
        count: 0,
        data: []
    },
    contactInfoError: false,
    locationsError: false,
    areaError: false,
    paymentInfoError: false,
    megaCompetitionSalesValue: { megaCompetitionSalesValue: 0 },
    leaderboard: [],
    allDocumentsVerified: false
};

// ==============================|| SLICE - ORGANIZATIONS ||============================== //

const organizations = createSlice({
    name: 'organizations',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(queryOrganizations.pending, (state) => {
                state.loadingList = true;
                state.errorList = false;
            })
            .addCase(queryOrganizations.fulfilled, (state, action) => {
                state.loadingList = false;
                state.organizations = action.payload;
            })
            .addCase(queryOrganizations.rejected, (state, action) => {
                state.loadingList = false;
                state.errorList = action.payload;
            })
            .addCase(createOrganization.pending, (state) => {
                state.loading = true;
                state.error = false;
                state.organization = undefined;
            })
            .addCase(createOrganization.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(createOrganization.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getOrganizationById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getOrganizationById.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(getOrganizationById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateOrganizationInfoById.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateOrganizationInfoById.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(updateOrganizationInfoById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(addOrganizationDevelopersRelations.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(addOrganizationDevelopersRelations.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(addOrganizationDevelopersRelations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(removeOrganizationDevelopersRelations.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(removeOrganizationDevelopersRelations.fulfilled, (state, action) => {
                state.loading = false;
                state.organization = action.payload;
            })
            .addCase(removeOrganizationDevelopersRelations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateOrganizationLimitsById.pending, (state) => {
                state.error = false;
            })
            .addCase(updateOrganizationLimitsById.fulfilled, (state, action) => {
                state.organization = action.payload;
            })
            .addCase(updateOrganizationLimitsById.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteOrganization.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteOrganization.fulfilled, (state) => {
                state.loading = false;
                state.organization = undefined;
            })
            .addCase(deleteOrganization.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(queryOrganizationDocuments.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(queryOrganizationDocuments.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
                state.documents = action.payload;
            })
            .addCase(queryOrganizationDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(uploadOrganizationDocuments.pending, (state) => {
                state.error = false;
            })

            .addCase(uploadOrganizationDocuments.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteOrganizationDocuments.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(deleteOrganizationDocuments.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
                state.refresh = !state.refresh;
            })
            .addCase(deleteOrganizationDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(editDocumentsState.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(editDocumentsState.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(editDocumentsState.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(updateDocuments.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(updateDocuments.fulfilled, (state, action) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(updateDocuments.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getDocumentsByType.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getDocumentsByType.fulfilled, (state, action) => {
                state.loading = false;
                state.allDocumentsVerified = action.payload.isVerified;
            })
            .addCase(getDocumentsByType.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(subscribeToTier.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(subscribeToTier.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(subscribeToTier.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(unsubscribeFromTier.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(unsubscribeFromTier.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(unsubscribeFromTier.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(assignAccountManager.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(assignAccountManager.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(assignAccountManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(unassignAccountManager.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(unassignAccountManager.fulfilled, (state) => {
                state.loading = false;
                state.refresh = !state.refresh;
            })
            .addCase(unassignAccountManager.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(queryOrganizationBrokers.pending, (state) => {
                state.brokersListLoading = true;
                state.brokersListError = false;
            })
            .addCase(queryOrganizationBrokers.fulfilled, (state, action) => {
                state.brokersListLoading = false;
                state.brokers = action.payload;
            })
            .addCase(queryOrganizationBrokers.rejected, (state, action) => {
                state.brokersListLoading = false;
                state.brokersListError = action.payload;
            })
            .addCase(inviteOrganizationBroker.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(inviteOrganizationBroker.fulfilled, (state) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(inviteOrganizationBroker.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })
            .addCase(updateOrganizationBrokerRole.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(updateOrganizationBrokerRole.fulfilled, (state, action) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(updateOrganizationBrokerRole.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })
            .addCase(cancelOrganizationBrokerInvitation.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(cancelOrganizationBrokerInvitation.fulfilled, (state, action) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(cancelOrganizationBrokerInvitation.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })
            .addCase(softDeleteOrganizationBroker.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(softDeleteOrganizationBroker.fulfilled, (state, action) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(softDeleteOrganizationBroker.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })

            .addCase(moveBrokerFromOrganization.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(moveBrokerFromOrganization.fulfilled, (state, action) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(moveBrokerFromOrganization.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })

            .addCase(deleteOrganizationBroker.pending, (state) => {
                // * use formik loading
                state.brokersListError = false;
            })
            .addCase(deleteOrganizationBroker.fulfilled, (state, action) => {
                state.refreshBrokers = !state.refreshBrokers;
            })
            .addCase(deleteOrganizationBroker.rejected, (state, action) => {
                state.brokersListError = action.payload;
            })
            .addCase(createOrganizationContactInfo.pending, (state) => {
                state.contactInfoError = false;
            })
            .addCase(createOrganizationContactInfo.fulfilled, (state, action) => {
                state.organization?.contactInfo?.push(action.payload);
            })
            .addCase(createOrganizationContactInfo.rejected, (state, action) => {
                state.contactInfoError = action.payload;
            })
            .addCase(deleteOrganizationContactInfo.pending, (state) => {
                state.contactInfoError = false;
            })
            .addCase(deleteOrganizationContactInfo.fulfilled, (state, action) => {
                state.organization?.contactInfo?.splice(
                    state.organization?.contactInfo.findIndex((info) => info.id === action.payload),
                    1
                );
            })
            .addCase(deleteOrganizationContactInfo.rejected, (state, action) => {
                state.contactInfoError = action.payload;
            })
            .addCase(createOrganizationLocation.pending, (state) => {
                state.locationsError = false;
            })
            .addCase(createOrganizationLocation.fulfilled, (state, action) => {
                state.organization?.locations?.push(action.payload);
            })
            .addCase(createOrganizationLocation.rejected, (state, action) => {
                state.locationsError = action.payload;
            })
            .addCase(deleteOrganizationLocation.pending, (state) => {
                state.locationsError = false;
            })
            .addCase(deleteOrganizationLocation.fulfilled, (state, action) => {
                state.organization?.locations?.splice(
                    state.organization?.locations.findIndex((loc) => loc.id === action.payload),
                    1
                );
            })
            .addCase(deleteOrganizationLocation.rejected, (state, action) => {
                state.locationsError = action.payload;
            })
            .addCase(addAreaToOrganization.pending, (state) => {
                state.areaError = false;
            })
            .addCase(addAreaToOrganization.fulfilled, (state, action) => {
                state.organization?.areas?.push(action.payload);
            })
            .addCase(addAreaToOrganization.rejected, (state, action) => {
                state.areaError = action.payload;
            })
            .addCase(removeAreaFromOrganization.pending, (state) => {
                state.areaError = false;
            })
            .addCase(removeAreaFromOrganization.fulfilled, (state, action) => {
                state.organization?.areas?.splice(
                    state.organization?.areas.findIndex((loc) => loc.name === action.payload),
                    1
                );
            })
            .addCase(removeAreaFromOrganization.rejected, (state, action) => {
                state.areaError = action.payload;
            })
            .addCase(createOrganizationPaymentInfo.pending, (state) => {
                state.paymentInfoError = false;
            })
            .addCase(createOrganizationPaymentInfo.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(createOrganizationPaymentInfo.rejected, (state, action) => {
                state.paymentInfoError = action.payload;
            })
            .addCase(deleteOrganizationPaymentInfo.pending, (state) => {
                state.paymentInfoError = false;
            })
            .addCase(deleteOrganizationPaymentInfo.fulfilled, (state, action) => {
                state.organization?.paymentInformation?.splice(
                    state.organization?.paymentInformation.findIndex((info) => info.id === action.payload),
                    1
                );
            })
            .addCase(deleteOrganizationPaymentInfo.rejected, (state, action) => {
                state.paymentInfoError = action.payload;
            })
            .addCase(updateOrganizationPaymentInfo.pending, (state) => {
                state.paymentInfoError = false;
            })
            .addCase(updateOrganizationPaymentInfo.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateOrganizationPaymentInfo.rejected, (state, action) => {
                state.paymentInfoError = action.payload;
            })
            .addCase(createOrganizationInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(createOrganizationInvitationLimits.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(createOrganizationInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(updateOrganizationInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(updateOrganizationInvitationLimits.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(updateOrganizationInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(deleteOrganizationInvitationLimits.pending, (state) => {
                state.error = false;
            })
            .addCase(deleteOrganizationInvitationLimits.fulfilled, (state, action) => {
                state.refresh = !state.refresh;
            })
            .addCase(deleteOrganizationInvitationLimits.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(getOrganizationMegaCompetitionSales.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getOrganizationMegaCompetitionSales.fulfilled, (state, action) => {
                state.loading = false;
                state.megaCompetitionSalesValue = action.payload;
            })
            .addCase(getOrganizationMegaCompetitionSales.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(getOrganizationLeaderboard.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(getOrganizationLeaderboard.fulfilled, (state, action) => {
                state.loading = false;
                state.leaderboard = action.payload;
            })
            .addCase(getOrganizationLeaderboard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default organizations.reducer;
