import { InvitationTypeEnum } from 'store/interfaces';

const WHO_CAN_INVITE = [
    { name: InvitationTypeEnum.accountManagers },
    { name: InvitationTypeEnum.admins },
    { name: InvitationTypeEnum.inventoryManagers },
    { name: InvitationTypeEnum.operationManager },
    { name: InvitationTypeEnum.internalAgents }
];
export default WHO_CAN_INVITE;
