// types
import { createSlice } from '@reduxjs/toolkit';
import { NameAndIdInterface } from 'store/interfaces';
import { createSaleClaim } from './actions';
import {
    CreateSaleBuyerInterface,
    CreateSaleClaimInitialInterface,
    CreateSaleFinancePropertyInterface,
    CreateSaleInfoInterface,
    StepperSectionsEnums
} from './interfaces';

// initial state
const initialState: CreateSaleClaimInitialInterface = {
    loading: false,
    error: false,
    refresh: false,
    listLoading: false,
    listError: undefined,
    listRefresh: false,
    stepper: StepperSectionsEnums.mainInfo,
    dialogOpen: false
};

// ==============================|| SLICE - SALE CLAIMS ||============================== //

const createSaleClaimSlice = createSlice({
    name: 'createSaleClaim',
    initialState,
    reducers: {
        setSaleInfo: (state, action: { payload: CreateSaleInfoInterface }) => {
            state.saleInfo = action.payload;
        },
        setBuyerInfo: (state, action: { payload: CreateSaleBuyerInterface }) => {
            state.buyer = action.payload;
        },
        setPropertyInfo: (state, action: { payload: CreateSaleFinancePropertyInterface }) => {
            state.property = action.payload;
        },
        setBrokerDetails: (
            state,
            action: { payload: { organization?: { id?: string; name?: string; tierId?: string }; broker?: NameAndIdInterface } }
        ) => {
            state.broker = action.payload.broker;
            state.organization = action.payload.organization;
        },
        setBroker: (state, action: { payload: NameAndIdInterface }) => {
            state.broker = action.payload;
        },
        setDocuments: (state, action: { payload: { documents: Array<any> } }) => {
            state.documents = action.payload.documents;
        },
        setOrganization: (state, action: { payload: { id?: string; name?: string; tierId?: string } }) => {
            state.organization = action.payload;
        },
        setStepper: (state, action: { payload: StepperSectionsEnums }) => {
            state.stepper = action.payload;
        },
        resetCreateSaleClaimState: (state) => {
            state.error = initialState.error;
            state.saleInfo = initialState.saleInfo;
            state.property = initialState.property;
            state.buyer = initialState.buyer;
            state.stepper = initialState.stepper;
            state.broker = initialState.broker;
            state.documents = initialState.documents;
            state.organization = initialState.organization;
        },
        openDialog: (state) => {
            state.dialogOpen = true;
        },
        closesDialog: (state) => {
            state.dialogOpen = false;
            state.error = initialState.error;
            state.saleInfo = initialState.saleInfo;
            state.property = initialState.property;
            state.buyer = initialState.buyer;
            state.documents = initialState.documents;
            state.stepper = initialState.stepper;
            state.broker = initialState.broker;
            state.organization = initialState.organization;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(createSaleClaim.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(createSaleClaim.fulfilled, (state) => {
                state.loading = false;
                state.error = false;
            })
            .addCase(createSaleClaim.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addDefaultCase((state, action) => {});
    }
});

export default createSaleClaimSlice.reducer;

export const {
    setSaleInfo,
    setBuyerInfo,
    setPropertyInfo,
    setStepper,
    setDocuments,
    resetCreateSaleClaimState,
    setBrokerDetails,
    closesDialog,
    openDialog
} = createSaleClaimSlice.actions;
