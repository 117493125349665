import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import { AddInvoiceInterface, QuerySalesInterface } from './interfaces';
import SALES_PORTAL_API from './apis';

const querySales = createAsyncThunk(
    'sales/query',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.query(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const querySalesSummary = createAsyncThunk(
    'sales/sales-summary',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.querySalesSummary(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const queryTopDevelopers = createAsyncThunk(
    'sales/top-developers',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.queryTopDevelopers(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const queryTopCompounds = createAsyncThunk(
    'sales/top-compounds',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.queryTopCompounds(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const queryTopPropertyTypes = createAsyncThunk(
    'sales/top-property-types',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.queryTopPropertyTypes(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const queryTopAgents = createAsyncThunk(
    'sales/top-agents',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.queryTopAgents(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const queryTopOrganizations = createAsyncThunk(
    'sales/top-organizations',
    async (
        requestData: {
            query: QuerySalesInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { query, runSuccess } = requestData;
        try {
            const successRes = await axios.get(SALES_PORTAL_API.queryTopOrganizations(query));
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
export const addInvoice = createAsyncThunk(
    'sales/add-invoice',
    async (
        requestData: {
            body: AddInvoiceInterface;
            runSuccess?: () => void;
        },
        { rejectWithValue }
    ) => {
        const { body, runSuccess } = requestData;
        try {
            const successRes = await axios.post(SALES_PORTAL_API.addInvoiceId(), {
                saleId: body.saleId,
                eInvoiceSerialNumber: body.eInvoiceSerialNumber,
                oldEInvoiceSerialNumber: body.oldEInvoiceSerialNumber
            });
            if (runSuccess) runSuccess();
            return successRes.data;
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export default querySales;
