interface IPropertyTypeName {
    [key: string]: number;
}

export interface ICompoundDetails {
    id: number;
    name: string;
    last_update: number;
    area_name: string;
    developer_id: number;
    developer_name: string;
    finishing: string;
    min_delivery_date: string;
    max_delivery_date: string;
    financing_eligibility: number;
    min_installments: string;
    max_installments: string;
    min_down_payment: string;
    max_down_payment: string;
    max_price: string;
    min_price: string;
    min_area: number;
    max_area: number;
    min_bedrooms: number;
    max_bedrooms: number;
    min_bathrooms: number;
    max_bathrooms: number;
    property_types_names: IPropertyTypeName;
    property_types_count: number;
    business_types: IPropertyTypeName;
    bruchure?: string[];
    on_hold: boolean;
}

export interface ICompoundDetailsResult {
    number_of_compounds: number;
    compounds: ICompoundDetails[];
}

export interface IPropertyTypeDetails {
    property_type_id: number;
    business_types: {
        resale?: number;
        move_now_pay_later?: number;
        developer_sale?: number;
    };
    name: string;
    property_types_count: number;
    last_update: string;
    finishing: {
        finished?: string;
        furnished?: string;
        not_finished?: string;
        semi_finished?: string;
    };
    min_delivery_date: string;
    max_delivery_date: string;
    financing_eligibility: number;
    min_installments: string;
    max_installments: string;
    min_down_payment: string;
    max_down_payment: string;
    max_price: string;
    min_price: string;
    min_area: number;
    max_area: number;
    min_bedrooms: number;
    max_bedrooms: number;
    min_bathrooms: number;
    max_bathrooms: number;
}

export interface IProperty {
    property_id: number;
    last_update: number;
    finishing: number;
    min_delivery_date: string;
    max_delivery_date: string;
    financing_eligibility: number;
    min_installments: string;
    max_installments: string;
    min_down_payment: string;
    max_down_payment: string;
    min_price: string;
    max_price: string;
    min_area: number;
    max_area: number;
    min_garden_area: number;
    max_garden_area: number;
    garden_area: number;
    min_bedrooms: number;
    max_bedrooms: number;
    min_bathrooms: number;
    max_bathrooms: number;
    min_land_area: number;
    max_land_area: number;
    business_type: string;
}

export interface IPropertyDetails {
    detailed_property_id: number;
    unit_id: string;
    unit_area: number;
    price: string;
    payment_plans: {
        id: string;
        installments: number;
        down_payment: number;
        years: number;
    }[];
    financing_eligibility: number;
    delivery_date: string;
    finishing: string;
    garden_area: number;
    land_area: number;
    last_update: string;
    bedrooms: number;
    bathrooms: number;
    business_type: string;
}

export interface NawyInventoryInitialStateInterface {
    compoundDetails: ICompoundDetails[];
    numberOfCompounds: number;
    propertyTypesDetails: IPropertyTypeDetails[];
    properties: IProperty[];
    propertyDetails: IPropertyDetails[];
    loadingCompoundsDetails: boolean;
    loadingPropertyTypesDetails: boolean;
    loadingProperties: boolean;
    loadingPropertyDetails: boolean;
    inventoryError: any;
}

export const businessTypes = [
    { name: 'Primary', value: 'developer_sale' },
    { name: 'Resale', value: 'resale' },
    { name: 'Nawy Now', value: 'move_now_pay_later' }
];

export const finishingTypes = [
    { name: 'Not finished', value: 'not_finished' },
    { name: 'Semi finished', value: 'semi_finished' },
    { name: 'Finished', value: 'finished' },
    { name: 'Furnished', value: 'furnished' }
];
